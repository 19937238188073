import { useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Trans, useTranslation } from 'react-i18next';
import { GET_SERVICE_REQUEST_QUERY } from '@graphql/serviceRequests';
import { GET_APPOINTMENT_REQUESTS_QUERY, GET_ACTIVITY_TIMELINE_QUERY } from '@graphql/activities';
import {
  SortOrder,
  Conversation,
  GraphqlInternalNote,
  GraphqlServiceRequest,
  GraphqlServiceRequestPayment,
  GraphqlStripeTransaction,
  ServiceRequestStatus,
  useGetActivityTimelineQuery,
  useGetConversationByIdLazyQuery,
  useGetConversationsQuery,
  useGetServiceRequestQuery,
  useGetStripeTransactionsQuery,
  useUpdateServiceRequestMutation,
} from '@graphql/generated';
import { ApolloError } from '@apollo/client';

import ROUTES from '@constants/routes';
import { ActivitiesTypes } from '@constants/activities';
import { renderCurrency, calculateServiceRequestPaymentTotal } from '@utils/currency';
import dayjs from 'dayjs';

import { Space, Table, Timeline, Typography } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { EditOutlined, PlusOutlined } from '@ant-design/icons';
import Masonry, { ResponsiveMasonry } from 'react-responsive-masonry';
import Link from '@components/Link';
import Modal from '@components/Modal';
import Button from '@components/Button';
import Card from '@components/Card/Card';
import Loading from '@components/Loading';
import GenericApolloError from '@components/GenericApolloError';
import RecordDetailCard from '@components/Card/RecordDetailCard';
import AddEditNoteModal from '@components/Activities/AddEditNoteModal';
import ActivityVitalsCard from '@components/Activities/ActivityVitalsCard';
import ActivityStatusCard from '@components/Activities/ActivityStatusCard';
import ActivityDetailCard from '@components/Activities/ActivityDetailCard';
import AppointmentRequestsCard from '@components/Activities/AppointmentRequestsCard';
import StripeTransactionStatusTag, {
  StripeTransactionStatusEnum,
} from '@components/Activities/StripeTransactionStatusTag';

function ServiceRequest() {
  const i18n = useTranslation();
  const [chatMode, setChatMode] = useState<boolean>(false);
  const [showConfirmAppointmentRequest, setShowConfirmAppointmentRequest] =
    useState<boolean>(false);
  const [noteModalIsOpen, setNoteModalIsOpen] = useState<boolean | GraphqlInternalNote>(false);

  const { activityId } = useParams<{ activityId: string }>();
  const {
    data: serviceRequestData,
    loading: serviceRequestLoading,
    error: serviceRequestError,
  } = useGetServiceRequestQuery({
    variables: { getServiceRequestId: parseInt(activityId as string, 10) },
  });
  const {
    data: stripeTransactionsData,
    loading: stripeTransactionsLoading,
    error: stripeTransactionsError,
  } = useGetStripeTransactionsQuery({
    variables: { filter: { activityId: [parseInt(activityId as string, 10)] } },
  });
  const {
    data: activityTimelineData,
    loading: activityTimelineLoading,
    error: activityTimelineError,
  } = useGetActivityTimelineQuery({
    variables: {
      orderIn: 'asc' as SortOrder,
      activityId: parseInt(activityId as string, 10),
    },
  });
  const {
    data: getConversationsQuery,
    loading: loadingConversationsQuery,
    error: conversationsError,
  } = useGetConversationsQuery({
    variables: {
      filter: {
        custom: {
          serviceRequestId: activityId as string,
        },
      },
    },
  });
  const [updateServiceRequestMutation] = useUpdateServiceRequestMutation({
    refetchQueries: [
      GET_SERVICE_REQUEST_QUERY,
      GET_APPOINTMENT_REQUESTS_QUERY,
      GET_ACTIVITY_TIMELINE_QUERY,
    ],
  });

  const [getConversationById, { data: conversationById, loading: loadingConversationById }] =
    useGetConversationByIdLazyQuery();

  const serviceRequest = useMemo(() => {
    return serviceRequestData?.getServiceRequest || ({} as GraphqlServiceRequest);
  }, [serviceRequestData]);

  const stripeTransactions = useMemo(() => {
    return (stripeTransactionsData?.getStripeTransactions?.transactions ||
      []) as GraphqlStripeTransaction[];
  }, [stripeTransactionsData]);

  const timelineItems = activityTimelineData?.getTimeline?.map((timelineItem) => ({
    label: `${dayjs(timelineItem.createdAt).format('MM/DD/YY')} (${dayjs(
      timelineItem.createdAt,
    ).fromNow()})`,
    children: <div>{timelineItem.description}</div>,
  }));

  const conversationColumns: ColumnsType<Conversation> = useMemo(
    () => [
      {
        title: i18n.t('manage.customer.table.columns.participants') as string,
        dataIndex: 'participants',
        key: 'participants',
        render: (_: any, record: Conversation) => {
          const participants = record?.custom?.participants?.split(',') || [];
          return participants.map((participant, index) => (
            <Link
              // eslint-disable-next-line react/no-array-index-key
              key={`user-${index}`}
              to={ROUTES.MANAGE_USER.replace(
                ':userId',
                String(record?.custom?.userIds?.split(',')?.[index]),
              )}
            >
              {participant}
              {index < participants.length - 1 ? ', ' : ''}
            </Link>
          ));
        },
      },
      {
        title: i18n.t('manage.customer.table.columns.lastMessage') as string,
        dataIndex: 'lastMessage',
        key: 'lastMessage',
        render: (_: any, record: Conversation) => dayjs(record?.lastMessage?.createdAt).fromNow(),
      },
      {
        title: null,
        dataIndex: 'id',
        key: 'id',
        render: (_: any, record: Conversation) => (
          <button
            type="button"
            onClick={() => {
              setChatMode(true);
              getConversationById({ variables: { conversationId: record?.id } });
            }}
          >
            view
          </button>
        ),
      },
    ],
    [i18n, getConversationById],
  );

  const onEdit = useMemo(
    () => async (values: any) => {
      await updateServiceRequestMutation({
        variables: {
          updateServiceRequestId: serviceRequest.id,
          data: values,
        },
      });
      setShowConfirmAppointmentRequest(true);
    },
    [serviceRequest.id, updateServiceRequestMutation],
  );

  if (
    serviceRequestLoading ||
    stripeTransactionsLoading ||
    activityTimelineLoading ||
    loadingConversationsQuery
  ) {
    return <Loading />;
  }

  if (
    serviceRequestError ||
    stripeTransactionsError ||
    activityTimelineError ||
    conversationsError
  ) {
    return (
      <GenericApolloError
        error={
          (serviceRequestError ||
            stripeTransactionsError ||
            activityTimelineError ||
            conversationsError) as ApolloError
        }
      />
    );
  }

  return (
    <div style={{ maxWidth: 1800, margin: '0 auto' }}>
      <Typography.Title
        level={2}
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
        }}
      >
        <Trans
          i18nKey="manage.serviceRequest.headers.id"
          values={{ id: String(serviceRequest?.id).padStart(5, '0') }}
        />
      </Typography.Title>
      <ResponsiveMasonry columnsCountBreakPoints={{ 350: 1, 1000: 2, 1750: 3 }}>
        <Masonry gutter="20px">
          <ActivityVitalsCard
            isServiceRequest
            activity={serviceRequest as GraphqlServiceRequest}
            onEdit={onEdit}
          />
          <ActivityStatusCard
            activity={serviceRequest as GraphqlServiceRequest}
            isServiceRequest
            onEdit={async (values) => {
              await onEdit(values);
              setShowConfirmAppointmentRequest(true);
            }}
          />

          {!!timelineItems?.length && (
            <Card title={i18n.t('manage.serviceRequest.headers.timeline')}>
              <div>
                <Timeline mode="left" items={timelineItems} />
              </div>
            </Card>
          )}

          <ActivityDetailCard
            activity={serviceRequest as GraphqlServiceRequest}
            onEdit={async (values) => {
              await onEdit({
                ...values,
                scheduledAt: dayjs(values.scheduledAt).toDate(),
              });
              setShowConfirmAppointmentRequest(true);
            }}
          />

          {!!serviceRequest?.ServiceRequestPayments?.length && (
            <RecordDetailCard
              title={<Trans i18nKey="manage.serviceRequest.headers.payment" />}
              cardProps={{
                style: { minWidth: '350px' },
              }}
              withDividers
              dividerMod={4}
              fields={(
                serviceRequest.ServiceRequestPayments.map((serviceRequestPayment, i) => [
                  {
                    name: `payment-${i}-type`,
                    label: <Trans i18nKey="manage.serviceRequest.fields.paymentType" />,
                    value: <Trans i18nKey={`paymentTypes.${serviceRequestPayment?.type}`} />,
                  },
                  {
                    name: `payment-${i}-amount`,
                    label: <Trans i18nKey="manage.serviceRequest.fields.paymentAmount" />,
                    value: renderCurrency(
                      calculateServiceRequestPaymentTotal(
                        serviceRequestPayment as GraphqlServiceRequestPayment,
                      ),
                    ),
                  },
                  {
                    name: `payment-${i}-transaction-id`,
                    label: <Trans i18nKey="manage.serviceRequest.fields.paymentTransactionId" />,
                    value: serviceRequestPayment?.stripeTransactionId || i18n.t('fallbacks.N/A'),
                  },
                  {
                    name: `payment-${i}-payment-method-id`,
                    label: <Trans i18nKey="manage.serviceRequest.fields.paymentMethodId" />,
                    value: serviceRequestPayment?.stripePaymentMethodId,
                  },
                ]) || []
              ).flat()}
            />
          )}

          {!!serviceRequest?.ServiceRequestPayments?.length &&
            serviceRequest?.status === ServiceRequestStatus.Completed && (
              <Card title={<Trans i18nKey="manage.serviceRequest.postVisitSummary.title" />}>
                <ResponsiveMasonry columnsCountBreakPoints={{ 1: 1 }}>
                  <Masonry gutter="20px">
                    <Typography.Title level={5} style={{ marginTop: 0 }}>
                      Total Customer Cost:{' '}
                      {renderCurrency(
                        serviceRequest?.ServiceRequestPayments?.reduce(
                          (acc, curr) =>
                            acc +
                            calculateServiceRequestPaymentTotal(
                              curr as GraphqlServiceRequestPayment,
                            ),
                          0,
                        ),
                      )}
                    </Typography.Title>
                    {(serviceRequest?.commentForBrya || serviceRequest?.commentForCustomer) && (
                      <Card
                        title={
                          <Trans
                            i18nKey="manage.serviceRequest.postVisitSummary.agentNote"
                            values={{ fullName: serviceRequest?.Agent?.User?.fullName }}
                          />
                        }
                      >
                        {serviceRequest?.commentForBrya && (
                          <div>
                            <Typography.Text strong>
                              <Trans i18nKey="manage.serviceRequest.postVisitSummary.commentForBrya" />
                            </Typography.Text>
                            {serviceRequest?.commentForBrya}
                          </div>
                        )}
                        {serviceRequest?.commentForCustomer && (
                          <div>
                            <Typography.Text strong>
                              <Trans i18nKey="manage.serviceRequest.postVisitSummary.commentForCustomer" />
                            </Typography.Text>
                            {serviceRequest?.commentForCustomer}
                          </div>
                        )}
                      </Card>
                    )}
                    {[...(serviceRequest?.ServiceRequestPayments || [])]
                      ?.sort((a, b) => +a.createdAt - +b.createdAt)
                      .map((serviceRequestPayment, i) => {
                        const paymentStripeTransaction = stripeTransactions.find(
                          (stripeTransaction) =>
                            stripeTransaction.stripeTransactionId ===
                            serviceRequestPayment.stripeTransactionId,
                        );
                        return (
                          <RecordDetailCard
                            title={
                              <>
                                <Trans
                                  i18nKey={`manage.serviceRequest.postVisitSummary.${
                                    i === 0 ? 'initialCosts' : 'postVisitCosts'
                                  }`}
                                />{' '}
                                <StripeTransactionStatusTag
                                  status={
                                    paymentStripeTransaction?.status as StripeTransactionStatusEnum
                                  }
                                />
                              </>
                            }
                            fields={[
                              {
                                name: `payment-${i}-cost`,
                                label: i18n.t('manage.serviceRequest.postVisitSummary.serviceCost'),
                                value: renderCurrency(serviceRequestPayment?.amount),
                              },
                              {
                                name: `payment-${i}-fee`,
                                label: i18n.t('manage.serviceRequest.postVisitSummary.safetyFee'),
                                value: renderCurrency(serviceRequestPayment?.fee),
                              },
                              {
                                name: `payment-${i}-tax`,
                                label: i18n.t('manage.serviceRequest.postVisitSummary.tax'),
                                value: renderCurrency(serviceRequestPayment?.tax),
                              },
                              {
                                name: `payment-${i}-total`,
                                label: i18n.t('manage.serviceRequest.postVisitSummary.total'),
                                value: (
                                  <Typography.Text strong>
                                    {renderCurrency(
                                      calculateServiceRequestPaymentTotal(
                                        serviceRequestPayment as GraphqlServiceRequestPayment,
                                      ),
                                    )}
                                  </Typography.Text>
                                ),
                              },
                            ]}
                          />
                        );
                      })}
                  </Masonry>
                </ResponsiveMasonry>
              </Card>
            )}

          <Card
            title={i18n.t('manage.serviceRequest.headers.notes')}
            actions={[
              <Button
                type="text"
                onClick={() =>
                  setNoteModalIsOpen({
                    serviceRequestId: serviceRequest?.id,
                  } as GraphqlInternalNote)
                }
              >
                <Space size="small">
                  <PlusOutlined />
                  <Trans i18nKey="manage.serviceRequest.addNote" />
                </Space>
              </Button>,
            ]}
          >
            {serviceRequest?.InternalNotes?.length ? (
              <Table
                dataSource={serviceRequest?.InternalNotes}
                columns={[
                  {
                    title: <Trans i18nKey="manage.internalNotes.headers.addedBy" />,
                    dataIndex: 'Users.fullName',
                    key: 'Users.fullName',
                    render: (_: any, record: GraphqlInternalNote) => record?.Users?.fullName,
                  },
                  {
                    title: '',
                    dataIndex: 'note',
                    key: 'note',
                  },
                  {
                    title: <Trans i18nKey="manage.internalNotes.headers.edit" />,
                    key: 'edit',
                    render: (_: any, record: GraphqlInternalNote) => (
                      <EditOutlined onClick={() => setNoteModalIsOpen(record)} />
                    ),
                  },
                ]}
                pagination={false}
              />
            ) : (
              <Trans i18nKey="manage.serviceRequest.noNotes" />
            )}
          </Card>

          <Card title={i18n.t('manage.serviceRequest.headers.inbox')}>
            <div>
              {chatMode && !loadingConversationById ? (
                <>
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'flex-end',
                      marginTop: -60,
                      marginBottom: 23,
                    }}
                  >
                    <button type="button" onClick={() => setChatMode(false)}>
                      back to list
                    </button>
                  </div>
                  <iframe
                    style={{
                      height: 300,
                      width: '100%',
                      border: 0,
                      marginTop: 10,
                    }}
                    title="embedded talkjs conversation"
                    src={conversationById?.getConversationSignature?.embedUrl}
                  />
                </>
              ) : (
                <Table
                  loading={loadingConversationsQuery}
                  columns={conversationColumns}
                  dataSource={
                    getConversationsQuery?.getConversations?.conversations as Conversation[]
                  }
                  pagination={false}
                />
              )}
            </div>
          </Card>
        </Masonry>
        <br />
        <AppointmentRequestsCard
          type={ActivitiesTypes.SERVICE_REQUEST}
          serviceRequestId={parseInt(activityId as string, 10)}
        />
      </ResponsiveMasonry>
      {!!noteModalIsOpen && (
        <AddEditNoteModal
          onCancel={() => setNoteModalIsOpen(!noteModalIsOpen)}
          note={noteModalIsOpen as GraphqlInternalNote}
        />
      )}
      {!!showConfirmAppointmentRequest && (
        <Modal
          width="max-content"
          title={i18n.t('manage.serviceRequest.headers.appointmentRequests.update')}
          cancelText={i18n.t('manage.serviceRequest.headers.appointmentRequests.done') as string}
          onCancel={() => setShowConfirmAppointmentRequest(false)}
          open
        >
          <AppointmentRequestsCard
            type={ActivitiesTypes.SERVICE_REQUEST}
            serviceRequestId={parseInt(activityId as string, 10)}
          />
        </Modal>
      )}
    </div>
  );
}

export default ServiceRequest;
