import { useCallback, useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { ApolloError, ServerError } from '@apollo/client';

import { Typography, Form, message } from 'antd';
import { Link, useNavigate } from 'react-router-dom';
import { FormItem } from '@components/Form';
import Input, { InputEmail, InputNewPassword } from '@components/Input';
import Button from '@components/Button';
import Divider from '@components/Divider';
import AuthLayout from '@components/AuthLayout';
import Modal from '@components/Modal';
import ROUTES from '@constants/routes';
import { useCreateUserMutation, useLoginMutation } from '@graphql/generated';
import useAuth from '@hooks/useAuth';
import { FEATURE_FLAGS } from '@constants/featureFlags';
import { useIsFeatureEnabled } from '@hooks/useIsFeatureEnabled';

const Disclaimer = styled(Typography.Text)`
  display: block;
  margin: 16px 0px;
  font-size: ${({ theme }) => theme.fontSizes.xsmall};
  line-height: ${({ theme }) => theme.lineHeights.xsmall};
  a {
    font-size: ${({ theme }) => theme.fontSizes.xsmall};
    line-height: ${({ theme }) => theme.lineHeights.xsmall};
  }
`;

export interface SignupFormValues {
  firstName: string;
  lastName: string;
  email: string;
  password: string;
}

function SignUp() {
  const i18n = useTranslation();
  const [signUp, { loading }] = useCreateUserMutation();
  const [loginMutation] = useLoginMutation();
  const { setAccessToken } = useAuth();
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const [isInvalidFieldsModalOpen, setIsInvalidFieldsModalOpen] = useState(false);
  const [isEmailInUseModalOpen, setIsEmailInUseModalOpen] = useState(false);
  const params = new URLSearchParams(window.location.search);
  const facebookSSOEnabled = useIsFeatureEnabled(FEATURE_FLAGS.FACEBOOK_SSO);
  const googleSSOEnabled = useIsFeatureEnabled(FEATURE_FLAGS.GOOGLE_SSO);
  const ssoEnabled = facebookSSOEnabled || googleSSOEnabled;

  useEffect(() => {
    const authId = params.get('authId');
    if (authId) {
      localStorage.setItem('authId', authId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onFinish = useCallback(
    async (values: SignupFormValues) => {
      try {
        await signUp({
          variables: { createUserInput: values, inviteToken: params.get('inviteToken') },
        });

        const authId = localStorage.getItem('authId');
        message.success(i18n.t('signUp.success'));
        if (authId) {
          const { data } = await loginMutation({
            variables: {
              email: values.email,
              password: values.password,
              inviteToken: params.get('inviteToken'),
            },
          });
          if (data?.login?.accessToken) {
            setAccessToken(data.login.accessToken);
          }
        } else {
          navigate(ROUTES.SIGN_UP_VERIFY_EMAIL, { state: { email: values.email } });
        }
      } catch (err) {
        // TODO: Fix this way of finding the error when BE return better errors
        const error = (err as ApolloError)?.networkError;
        if (
          (error as ServerError)?.result?.errors[0]?.message === i18n.t('signUp.errors.emailInUse')
        ) {
          setIsEmailInUseModalOpen(true);
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [signUp, message, i18n],
  );

  const confirmButtonWrapperFn = (children: JSX.Element) => (
    <Link key="modal-confirm-link" to={ROUTES.LOG_IN}>
      {children}
    </Link>
  );

  return (
    <>
      <AuthLayout
        bryaHeaderProps={{
          title: 'signUp.title',
          subtitle: 'signUp.subtitle',
          googleSSOTitle: 'signUp.cta.googleSignUp',
          facebookSSOTitle: 'signUp.cta.facebookSignUp',
        }}
      >
        <>
          {ssoEnabled && <Divider>{i18n.t('signUp.cta.divider')}</Divider>}
          <Form
            name="signUp"
            autoComplete="off"
            onFinish={onFinish}
            onFinishFailed={() => setIsInvalidFieldsModalOpen(true)}
            form={form}
          >
            <FormItem
              label={i18n.t('forms.input.firstName.placeholder')}
              name="firstName"
              rules={[
                { required: true, message: i18n.t('forms.input.firstName.errorMessage') as string },
              ]}
            >
              <Input
                name="firstName"
                placeholder={i18n.t('forms.input.firstName.placeholder') as string}
              />
            </FormItem>
            <FormItem
              label={i18n.t('forms.input.lastName.placeholder')}
              name="lastName"
              rules={[
                { required: true, message: i18n.t('forms.input.lastName.errorMessage') as string },
              ]}
            >
              <Input
                name="lastName"
                placeholder={i18n.t('forms.input.lastName.placeholder') as string}
              />
            </FormItem>
            <InputEmail placeholder={i18n.t('forms.input.email.placeholder') as string} />
            <InputNewPassword placeholder={i18n.t('forms.input.password.placeholder') as string} />
            <Disclaimer>
              <Trans i18nKey="signUp.disclaimer">
                By signing up, you agree to our{' '}
                <Link to={ROUTES.TERMS_OF_SERVICE}>Terms of Use</Link> and{' '}
                <Link to={ROUTES.PRIVACY_POLICY}>Privacy Policy</Link>.
              </Trans>
            </Disclaimer>
            <FormItem>
              <Button loading={loading} htmlType="submit" disabled={loading}>
                {i18n.t('signUp.cta.submit')}
              </Button>
            </FormItem>
            <Divider>{i18n.t('signUp.cta.divider2')}</Divider>
            <FormItem>
              <Link to={ROUTES.LOG_IN}>
                <Button type="link">{i18n.t('signUp.cta.logIn')}</Button>
              </Link>
            </FormItem>
          </Form>
        </>
      </AuthLayout>
      <Modal
        title={i18n.t('signUp.modals.invalidFields.title')}
        open={isInvalidFieldsModalOpen}
        width={400}
        onConfirm={() => setIsInvalidFieldsModalOpen(false)}
        onCancel={() => setIsInvalidFieldsModalOpen(false)}
        showCancelButton={false}
      >
        <ul>
          <li>{i18n.t('signUp.modals.invalidFields.errors.firstNameRequired')}</li>
          <li>{i18n.t('signUp.modals.invalidFields.errors.lastNameRequired')}</li>
          <li>{i18n.t('signUp.modals.invalidFields.errors.emailRequired')}</li>
          <li>{i18n.t('signUp.modals.invalidFields.errors.passwordRequired')}</li>
        </ul>
      </Modal>
      <Modal
        title={i18n.t('signUp.modals.accountAlreadyExists.title')}
        open={isEmailInUseModalOpen}
        width={400}
        confirmText={i18n.t('signUp.modals.accountAlreadyExists.cta.logIn') as string}
        onConfirm={() => setIsEmailInUseModalOpen(false)}
        confirmLinkWrapper={confirmButtonWrapperFn}
        cancelText={i18n.t('signUp.modals.accountAlreadyExists.cta.retry') as string}
        onCancel={() => setIsEmailInUseModalOpen(false)}
      >
        <p>{i18n.t('signUp.modals.accountAlreadyExists.errors.emailAlreadyExists')}</p>
      </Modal>
    </>
  );
}

export default SignUp;
