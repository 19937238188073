import actions from '@constants/actions';
import { GraphqlUser } from '@graphql/generated';
import { useAppContext } from '@providers/appContextProvider';

export const useSetUser = () => {
  const { dispatch } = useAppContext();
  return (user: GraphqlUser | null) =>
    dispatch({
      type: actions.SET_USER,
      payload: user,
    });
};
