import { useEffect, useMemo, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import {
  InputMaybe,
  GraphqlSuggestedActivities,
  PaginationMeta,
  SortOrder,
  SuggestedActivitiesFilter,
  SuggestedActivitiesRoleEnum,
  SuggestedActivitiesSortInput,
  SuggestedActivitiesSortableFields,
  useGetSuggestedActivitiesQuery,
  useUpdateSuggestedActivityMutation,
} from '@graphql/generated';
import { GET_SUGGESTED_ACTIVITIES_QUERY } from '@graphql/suggestedActivities';

import ROUTES from '@constants/routes';
import { truncate } from '@utils/string';
import { formatISOtoMMDDYYYYandTime } from '@utils/date';
import { DEFAULT_SEARCH_DEBOUNCE_TIME } from '@constants/input';
import { DEFAULT_PAGE_SIZE, PAGE_SIZES } from '@components/Table/Table';

import { Row, Select, Table, Typography } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { FilterValue, SorterResult, TablePaginationConfig } from 'antd/es/table/interface';
import Link from '@components/Link';
import Input, { InputTextarea } from '@components/Input';
import RecordDetailCard from '@components/Card/RecordDetailCard';

const ALL_SUGGESTED_ACTIVITIES_ROLES = Object.values(SuggestedActivitiesRoleEnum);

function SuggestedActivities() {
  const i18n = useTranslation();
  const [searchName, setSearchName] = useState('');
  const [filters, setFilters] = useState<SuggestedActivitiesFilter>({
    name: searchName,
    roles: ALL_SUGGESTED_ACTIVITIES_ROLES,
  });
  const [sortOptions, setSortOptions] = useState<InputMaybe<SuggestedActivitiesSortInput>>({
    field: SuggestedActivitiesSortableFields.Id,
    direction: SortOrder.Desc,
  });
  const [pageSize, setPageSize] = useState(DEFAULT_PAGE_SIZE);
  const [page, setPage] = useState(1);
  const {
    data: getSuggestedActivitiesQuery,
    previousData,
    loading,
  } = useGetSuggestedActivitiesQuery({
    variables: { filter: filters, sort: sortOptions, page, pageSize },
  });
  const [updateSuggestedActivityMutation] = useUpdateSuggestedActivityMutation({
    refetchQueries: [GET_SUGGESTED_ACTIVITIES_QUERY],
  });
  const suggestedActivities =
    ((getSuggestedActivitiesQuery || previousData)?.getSuggestedActivities
      .suggestedActivities as GraphqlSuggestedActivities[]) || [];
  const pagination =
    ((getSuggestedActivitiesQuery || previousData)?.getSuggestedActivities
      .meta as PaginationMeta) || {};

  useEffect(() => {
    const timer = setTimeout(() => {
      setFilters((previousFilters) => ({ ...previousFilters, name: searchName }));
    }, DEFAULT_SEARCH_DEBOUNCE_TIME);
    return () => clearTimeout(timer);
  }, [searchName]);

  const columns: ColumnsType<GraphqlSuggestedActivities> = useMemo(
    () => [
      {
        title: i18n.t('manage.suggestedActivities.columns.suggestedBy') as string,
        key: 'suggestedBy',
        render: (_: any, record: any) => (
          <Link to={ROUTES.MANAGE_USER.replace(':userId', String(record?.User?.id))}>
            {record?.User?.fullName}
          </Link>
        ),
      },
      {
        title: i18n.t('manage.suggestedActivities.columns.name') as string,
        dataIndex: 'name',
        key: 'name',
        sorter: true,
      },
      {
        title: i18n.t('manage.suggestedActivities.columns.description') as string,
        key: 'description',
        render: (_: any, record: any) => truncate(record?.description),
      },
      {
        title: i18n.t('manage.suggestedActivities.columns.role') as string,
        key: 'role',
        render: (_: any, record: any) => {
          return i18n.t(`roles.${record.role}`) as string;
        },
        filters: ALL_SUGGESTED_ACTIVITIES_ROLES.map((role) => {
          const roleText = i18n.t(`roles.${role}`) as string;
          return { text: roleText, value: role };
        }),
      },
      {
        title: i18n.t('manage.suggestedActivities.columns.createdAt') as string,
        dataIndex: 'createdAt',
        key: 'createdAt',
        width: 115,
        sorter: true,
        render: (_: any, record: any) => formatISOtoMMDDYYYYandTime(record.createdAt),
      },
      {
        title: i18n.t('manage.suggestedActivities.columns.notes') as string,
        key: 'notes',
        render: (_: any, record: any) => truncate(record?.notes),
      },
    ],
    [i18n],
  );

  function renderExpandedRow(record: any) {
    return (
      <RecordDetailCard
        editable
        onEdit={async (variables: any) => {
          await updateSuggestedActivityMutation({ variables });
        }}
        cardProps={{
          style: { minWidth: '350px' },
        }}
        fields={[
          {
            name: 'suggestedBy',
            label: <Trans i18nKey="manage.suggestedActivities.columns.suggestedBy" />,
            value: record?.User?.id,
            render: () => (
              <Link to={ROUTES.MANAGE_USER.replace(':userId', String(record?.User?.id))}>
                {record?.User?.fullName}
              </Link>
            ),
            editable: false,
          },
          {
            name: 'updateSuggestedActivityInput.name',
            label: <Trans i18nKey="manage.suggestedActivities.columns.name" />,
            value: record?.name,
            editable: true,
            formElement: <Input style={{ minWidth: 600 }} />,
          },
          {
            name: 'updateSuggestedActivityInput.role',
            label: <Trans i18nKey="manage.suggestedActivities.columns.role" />,
            value: record?.role,
            render: () => <Trans i18nKey={`roles.${record?.role}`} />,
            editable: true,
            formElement: (
              <Select
                style={{ minWidth: 600 }}
                options={ALL_SUGGESTED_ACTIVITIES_ROLES.map((role) => ({
                  value: role,
                  label: i18n.t(`roles.${role}`),
                }))}
              />
            ),
          },
          {
            name: 'updateSuggestedActivityInput.description',
            label: <Trans i18nKey="manage.suggestedActivities.columns.description" />,
            value: record?.description,
            editable: true,
            formElement: <InputTextarea style={{ minWidth: 600 }} autoSize />,
          },
          {
            name: 'updateSuggestedActivityInput.notes',
            label: <Trans i18nKey="manage.suggestedActivities.columns.notes" />,
            value: record?.notes || '',
            render: () => record?.notes || i18n.t('fallbacks.N/A'),
            editable: true,
            formElement: <InputTextarea style={{ minWidth: 600 }} autoSize />,
          },
          {
            name: 'suggestedActivityId',
            label: <Trans i18nKey="manage.suggestedActivities.columns.suggestedActivityId" />,
            hidden: true,
            editable: true,
            value: record?.id,
          },
        ]}
      />
    );
  }

  const onTableChange = (
    tablePagination: TablePaginationConfig,
    tableFilters: Record<string, FilterValue | null>,
    tableSorter:
      | SorterResult<GraphqlSuggestedActivities>
      | SorterResult<GraphqlSuggestedActivities>[],
  ) => {
    const { role } = tableFilters;
    const uniqueSorter = tableSorter as SorterResult<GraphqlSuggestedActivities>;

    // Pagination
    setPage(tablePagination.current as number);
    setPageSize(tablePagination.pageSize as number);

    // Filter
    // If no role is selected, we need to filter by all management roles in the backend
    const rolesFilter = !role || role?.length === 0 ? ALL_SUGGESTED_ACTIVITIES_ROLES : role;
    setFilters((prevFilters) => ({
      ...prevFilters,
      roles: rolesFilter as SuggestedActivitiesRoleEnum[],
    }));

    // Order
    if (uniqueSorter.order) {
      const direction = uniqueSorter.order === 'ascend' ? 'asc' : 'desc';
      setSortOptions({
        field: uniqueSorter.field as SuggestedActivitiesSortableFields,
        direction: direction as SortOrder,
      });
    } else {
      setSortOptions({
        field: SuggestedActivitiesSortableFields.Id,
        direction: SortOrder.Desc,
      });
    }
  };

  return (
    <>
      <Row justify="space-between" align="middle" style={{ marginBottom: '20px' }}>
        <Typography.Title level={2}>
          <Trans i18nKey="manage.suggestedActivities.title" />
        </Typography.Title>
      </Row>
      <Row style={{ marginBottom: '20px' }}>
        <Input
          placeholder={i18n.t('manage.suggestedActivities.search') as string}
          value={searchName}
          onChange={(e) => setSearchName(e.target.value)}
        />
      </Row>
      <Table
        loading={loading}
        columns={columns}
        dataSource={suggestedActivities.map((suggestedActivity) => ({
          ...suggestedActivity,
          key: suggestedActivity.id,
        }))}
        onChange={onTableChange}
        expandable={{
          expandedRowRender: renderExpandedRow,
        }}
        pagination={{
          pageSize,
          pageSizeOptions: PAGE_SIZES,
          showSizeChanger: true,
          defaultCurrent: 1,
          current: pagination?.currentPage,
          total: pagination?.totalCount,
        }}
      />
    </>
  );
}

export default SuggestedActivities;
