import styled from 'styled-components';
import { Button as AntdButton } from 'antd';
import { ButtonProps } from 'antd/lib/button';
import React from 'react';

const ButtonLinkAttrs = ({ type = 'link' }: React.ComponentProps<typeof AntdButton>) => ({
  type,
});

export const ButtonLink: React.FC<ButtonProps> = styled(AntdButton).attrs(ButtonLinkAttrs)`
  color: ${({ theme }) => theme.colors.backgroundCTA};
  font-size: ${({ theme }) => theme.fontSizes.large};
  font-weight: ${({ theme }) => theme.fontWeights.normal};
  text-decoration: none;
  padding: 0;
  margin: 0;
  border: 0;
`;

const ButtonPrimaryAttrs = ({ type = 'primary' }: React.ComponentProps<typeof AntdButton>) => ({
  type,
});
export const ButtonStyled: React.FC<ButtonProps> = styled(AntdButton).attrs(ButtonPrimaryAttrs)`
  height: 48px;
  width: 100%;
  border-radius: 50px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-size: ${({ theme }) => theme.fontSizes.large};
  line-height: ${({ theme }) => theme.lineHeights.large};
  font-weight: ${({ theme }) => theme.fontWeights.bold};

  &.ant-btn-primary {
    background-color: ${({ theme, danger }) => (danger ? '' : theme.colors.backgroundCTA)};
    color: ${({ theme }) => theme.colors.foregroundCTA};
  }

  &.ant-btn-ghost {
    background-color: ${({ theme }) => theme.colors.backgroundPrimary};
    color: ${({ theme }) => theme.colors.foregroundPrimary};
    border: 0.5px solid ${({ theme }) => theme.colors.foregroundPrimary};
    font-size: ${({ theme }) => theme.fontSizes.medium};
    line-height: ${({ theme }) => theme.lineHeights.medium};
    font-weight: ${({ theme }) => theme.fontWeights.bold};
  }

  &.ant-btn-link {
    background-color: ${({ theme }) => theme.colors.backgroundPrimary};
    color: ${({ theme }) => theme.colors.backgroundCTA};
    border: 1px solid ${({ theme }) => theme.colors.backgroundCTA};
  }

  &:disabled {
    background-color: ${({ theme }) => theme.colors.disabled};
  }
`;

export default ButtonStyled;
