import { DATE_FORMAT, TIME_FORMAT } from '@constants/input';
import { ServiceRequestAvailabilityInput, useGetDefaultTimezoneQuery } from '@graphql/generated';
import dayjs, { extend } from 'dayjs';
import utc from 'dayjs/plugin/utc';
import tz from 'dayjs/plugin/timezone';
import { DayjsDateInput } from '@utils/date';

extend(utc);
extend(tz);

export const useDateWithTimezone = () => {
  const { data, previousData } = useGetDefaultTimezoneQuery();
  const defaultTimezone = (previousData || data)?.getDefaultTimezone.timezone || 'America/New_York';

  // IMPORTANT: remove timezone default once the backend is ready to send the timezone information.
  // Once the backend is ready, removing the default will make the frontend fail to compile, so
  // we need to pass the correct timezone where these functions are being used.
  // NOTE: America/New is IANA timezone identifier for Dallas, TX.
  const formatAvailabilityInTimezone = (
    availability: ServiceRequestAvailabilityInput | undefined,
    timezone: string = defaultTimezone,
  ): string => {
    if (!availability) {
      return '';
    }

    const dateFrom = dayjs(`${availability.date} ${availability.from}`).tz(timezone);
    const dateUntil = dayjs(`${availability.date} ${availability.until}`).tz(timezone);

    return `${dateFrom.format(`[On] MM/DD/YYYY, [from] h:mma`)} until ${dateUntil.format(
      'h:mma z',
    )}`;
  };

  const formatDateAndTimeInTimezone = (
    date: DayjsDateInput,
    format: string = 'MM/DD/YYYY @ h:mma z',
    timezone: string = defaultTimezone,
  ) => {
    return dayjs(date).tz(timezone).format(format);
  };

  const KEEP_LOCAL_TIME_WHEN_CONVERTING_TO_OTHER_TIMEZONE = true;
  /**
   *
   * @param date Date to use for reference when converting to another timezone
   * @param timezone Standard TZ identifier
   * @returns {Date} date transformed in the timezone provided
   * @example if current timezone is GMT+01:00
   * transformDateToTimezone(new Date("2020-01-01T09:00:00.000Z"), 'America/Toronto')
   *  -> 2023-08-07T14:00:00.000Z if America/Toronto is in GMT-04:00
   */
  const transformDateToTimezone = (date: DayjsDateInput, timezone: string = defaultTimezone) => {
    return dayjs(date).tz(timezone, KEEP_LOCAL_TIME_WHEN_CONVERTING_TO_OTHER_TIMEZONE).toDate();
  };

  const transformAvailabilityToTimezone = (
    availability: ServiceRequestAvailabilityInput,
    timezone: string = defaultTimezone,
  ) => {
    const dateFrom = transformDateToTimezone(`${availability.date} ${availability.from}`, timezone);
    const dateUntil = transformDateToTimezone(
      `${availability.date} ${availability.until}`,
      timezone,
    );
    const dateFormatted = dayjs(dateFrom).tz('UTC').format(DATE_FORMAT);
    const fromFormatted = dayjs(dateFrom).tz('UTC').format(TIME_FORMAT);
    const untilFormatted = dayjs(dateUntil).tz('UTC').format(TIME_FORMAT);
    return { date: dateFormatted, from: fromFormatted, until: untilFormatted };
  };

  const createDateInTimezone = (
    year: number,
    month: number,
    day: number,
    hour: number,
    minute: number,
    timezone: string = defaultTimezone,
  ) => {
    return dayjs(`${year}-${month}-${day} ${hour}:${minute}`)
      .tz(timezone, KEEP_LOCAL_TIME_WHEN_CONVERTING_TO_OTHER_TIMEZONE)
      .toDate();
  };

  const transformToTimezone = (date: DayjsDateInput, timezone: string = defaultTimezone) => {
    return dayjs(date).tz(timezone);
  };

  return {
    formatAvailabilityInTimezone,
    formatDateAndTimeInTimezone,
    transformDateToTimezone,
    transformAvailabilityToTimezone,
    transformToTimezone,
    createDateInTimezone,
  };
};
