import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';

export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = {
  [_ in K]?: never;
};
export type Incremental<T> =
  | T
  | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string };
  String: { input: string; output: string };
  Boolean: { input: boolean; output: boolean };
  Int: { input: number; output: number };
  Float: { input: number; output: number };
  /** The javascript `Date` as string. Type represents date and time as the ISO Date string. */
  DateTime: { input: Date; output: Date };
  /** JsonValue custom scalar type */
  JsonValue: { input: any; output: any };
  /** Plain date in the format YYYY-MM-DD */
  PlainDate: { input: Date; output: Date };
  /** Zoned Plain time in the format 15:30Z+00:00 */
  ZonedPlainTime: { input: Date; output: Date };
};

export type Activities =
  | GraphqlCircleRequest
  | GraphqlCoachVisit
  | GraphqlCustomerSocialEvent
  | GraphqlServiceRequest;

export type ActivitiesFilter = {
  /** Ids of customer to return activities */
  customerIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  /** Filter by status */
  status?: InputMaybe<ActivitiesStatus>;
  /** Filter by type options */
  type?: InputMaybe<ActivitiesOptions>;
};

export type ActivitiesGroupedByDateFilter = {
  /** Ids of customer to return activities */
  customerIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  /** Filter by status */
  status?: InputMaybe<ActivitiesGroupedByDateStatus>;
  /** Filter by type options */
  type?: InputMaybe<ActivitiesGroupedByDateOptions>;
};

export type ActivitiesGroupedByDateMeta = {
  __typename?: 'ActivitiesGroupedByDateMeta';
  /** The date information */
  isLastPage: Scalars['Boolean']['output'];
  lastValue?: Maybe<Scalars['DateTime']['output']>;
};

/** The different options for filtering when getting activities grouped by date */
export enum ActivitiesGroupedByDateOptions {
  ActionNeeded = 'actionNeeded',
  All = 'all',
  Cancelled = 'cancelled',
  Completed = 'completed',
  ThisWeek = 'thisWeek',
  Today = 'today',
}

export type ActivitiesGroupedByDateOutput = {
  __typename?: 'ActivitiesGroupedByDateOutput';
  /** The list of circle, service requests and social events for specific customers in a specific date */
  activities?: Maybe<Array<Activities>>;
  id: Scalars['Int']['output'];
  /** The date information */
  timeline: TimelineOutput;
};

/** The different status to get activities grouped by date */
export enum ActivitiesGroupedByDateStatus {
  History = 'history',
  Scheduled = 'scheduled',
}

/** The different options for filtering when getting activities */
export enum ActivitiesOptions {
  ActionNeeded = 'actionNeeded',
  All = 'all',
  Cancelled = 'cancelled',
  Completed = 'completed',
  ConfirmationNeeded = 'confirmationNeeded',
  Pending = 'pending',
  ThisWeek = 'thisWeek',
  Today = 'today',
}

/** The different status to get activities */
export enum ActivitiesStatus {
  History = 'history',
  Open = 'open',
  Scheduled = 'scheduled',
}

/** The fields for creating an admin user */
export type AdminCreateUserInput = {
  /** The user email */
  email: Scalars['String']['input'];
  /** The emergency contact */
  emergencyContact?: InputMaybe<UserEmergencyContactInput>;
  /** The user first name */
  firstName: Scalars['String']['input'];
  /** The user last name */
  lastName: Scalars['String']['input'];
  /** The user management role */
  managementRole?: InputMaybe<ManagementRole>;
};

/** The fields to be updated for an adming user */
export type AdminUpdateUserInput = {
  /** The user bio */
  bio?: InputMaybe<Scalars['String']['input']>;
  /** Wheather the user is disabled or not */
  disabled?: InputMaybe<Scalars['Boolean']['input']>;
  /** The emergency contact */
  emergencyContact?: InputMaybe<UserEmergencyContactInput>;
  /** The user first name */
  firstName?: InputMaybe<Scalars['String']['input']>;
  /** The user last name */
  lastName?: InputMaybe<Scalars['String']['input']>;
  managementRole?: InputMaybe<ManagementRole>;
  /** The user phone number */
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
};

export enum AgentPreferedContactMethodEnum {
  BryaApp = 'bryaApp',
  Email = 'email',
  Phone = 'phone',
  Sms = 'sms',
  Whatsapp = 'whatsapp',
}

export enum AgentRole {
  AdminAgent = 'adminAgent',
  Agent = 'agent',
}

/** The options to filter agents */
export type AgentsFilter = {
  /** Matches company ID */
  companyId?: InputMaybe<Scalars['Float']['input']>;
  /** Retrieve agents with the specified service types */
  serviceTypes?: InputMaybe<Array<ServiceRequestCategoriesEnum>>;
  /** Matches company name, agent name, phone number or email */
  text?: InputMaybe<Scalars['String']['input']>;
};

/** The sorting configuration */
export type AgentsSort = {
  /** The direction to order by */
  direction: SortOrder;
  /** The fields to sort by */
  field: AgentsSortableFields;
};

/** The possible fields that agents can be sorted */
export enum AgentsSortableFields {
  CompanyName = 'companyName',
  CompanyRating = 'companyRating',
  CreatedAt = 'createdAt',
  FullName = 'fullName',
  Id = 'id',
  Rating = 'rating',
  UpdatedAt = 'updatedAt',
}

/** The roles related to the mobile application */
export enum AppUser {
  CircleMember = 'circleMember',
  FamilyPartner = 'familyPartner',
  SeniorMember = 'seniorMember',
}

export type AppointmentActivitiesFilter = {
  /** Filter by activities statuses */
  activityStatuses?: InputMaybe<Array<AppointmentActivitiesStatus>>;
  /** Filter by appointment statuses */
  appointmentStatuses?: InputMaybe<Array<AppointmentRequestStatus>>;
  /** Filter starting from date */
  fromDate?: InputMaybe<Scalars['DateTime']['input']>;
  /** Filter by tab status */
  status?: InputMaybe<AppointmentRequestActivitiesStatus>;
  /** Filter ending until date */
  untilDate?: InputMaybe<Scalars['DateTime']['input']>;
};

/** The different status that activities might have when getting them for circle members or agents */
export enum AppointmentActivitiesStatus {
  Completed = 'completed',
  ConfirmationNeeded = 'confirmationNeeded',
  Confirmed = 'confirmed',
  Pending = 'pending',
}

/** The different status to get appointment requests activities */
export enum AppointmentRequestActivitiesStatus {
  Confirmed = 'confirmed',
  Requested = 'requested',
}

export type AppointmentRequestFilter = {
  /** Filter by agentId */
  agentId?: InputMaybe<Array<Scalars['Int']['input']>>;
  /** Filter by category */
  category?: InputMaybe<Array<ServiceRequestCategoriesEnum>>;
  /** Filter by createdAt after date */
  createdAtFrom?: InputMaybe<Scalars['DateTime']['input']>;
  /** Filter by createdAt before date */
  createdAtUntil?: InputMaybe<Scalars['DateTime']['input']>;
  /** Filter by createdBy */
  createdBy?: InputMaybe<Array<Scalars['Int']['input']>>;
  /** Filter by requested after date */
  requestedAtFrom?: InputMaybe<Scalars['DateTime']['input']>;
  /** Filter by requested before date */
  requestedAtUntil?: InputMaybe<Scalars['DateTime']['input']>;
  /** Filter by requires action */
  requiresAction?: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by scheduledAt after date */
  scheduledAtFrom?: InputMaybe<Scalars['DateTime']['input']>;
  /** Filter by scheduledAt before date */
  scheduledAtUntil?: InputMaybe<Scalars['DateTime']['input']>;
  /** Filter by status */
  status?: InputMaybe<Array<AppointmentRequestStatus>>;
  /** Filters by agent, customer, user that requested the service or ID */
  text?: InputMaybe<Scalars['String']['input']>;
};

/** The fields for sorting */
export type AppointmentRequestSortInput = {
  /** The direction to order by */
  direction: SortOrder;
  /** The fields to sort by */
  field: AppointmentRequestsSortableFields;
};

/** The status of an appointment request */
export enum AppointmentRequestStatus {
  Accepted = 'accepted',
  Assigned = 'assigned',
  Closed = 'closed',
  Confirmed = 'confirmed',
  Declined = 'declined',
  NotChosen = 'notChosen',
  Open = 'open',
}

export enum AppointmentRequestsSortableFields {
  CircleRequestId = 'circleRequestId',
  CreatedAt = 'createdAt',
  CreatedBy = 'createdBy',
  Id = 'id',
  ScheduledAt = 'scheduledAt',
  ServiceRequestId = 'serviceRequestId',
  Status = 'status',
  UpdatedAt = 'updatedAt',
  UserId = 'userId',
}

/** The available options for a user Service Request approval */
export enum ApprovalServiceRequestStatus {
  Accept = 'accept',
  Decline = 'decline',
}

export type AutomaticPaymentMethods = {
  __typename?: 'AutomaticPaymentMethods';
  allow_redirects: Scalars['String']['output'];
  enabled: Scalars['Boolean']['output'];
};

/** The possible chat types that can be used to filtering conversations based on custom data */
export enum ChatTypeOptions {
  Community = 'community',
  Social = 'social',
}

export enum CircleRequestCategoriesEnum {
  Appointment = 'appointment',
  Errands = 'errands',
  Exercise = 'exercise',
  HouseCare = 'house_care',
  Meals = 'meals',
  Other = 'other',
  PetCare = 'pet_care',
  Ride = 'ride',
  Visit = 'visit',
}

export type CircleRequestFilter = {
  /** Filter by category */
  category?: InputMaybe<Array<CircleRequestCategoriesEnum>>;
  /** Filter by customerId */
  customerId?: InputMaybe<Array<Scalars['Int']['input']>>;
  /** Filter by requested after date */
  requestedAtFrom?: InputMaybe<Scalars['DateTime']['input']>;
  /** Filter by requested before date */
  requestedAtUntil?: InputMaybe<Scalars['DateTime']['input']>;
  /** Filter by requestedBy */
  requestedBy?: InputMaybe<Array<Scalars['Int']['input']>>;
  /** Filter by scheduledAt after date */
  scheduledAtFrom?: InputMaybe<Scalars['DateTime']['input']>;
  /** Filter by scheduledAt before date */
  scheduledAtUntil?: InputMaybe<Scalars['DateTime']['input']>;
  /** Filter by status */
  status?: InputMaybe<Array<ServiceRequestStatus>>;
  /** Filters by customer, user that made or accepted the request or ID */
  text?: InputMaybe<Scalars['String']['input']>;
  /** Filter by updatedAt after date */
  updatedAtFrom?: InputMaybe<Scalars['DateTime']['input']>;
  /** Filter by updatedAt before date */
  updatedAtUntil?: InputMaybe<Scalars['DateTime']['input']>;
};

export type CircleRequestInterpretResponse = {
  __typename?: 'CircleRequestInterpretResponse';
  category: Scalars['String']['output'];
  recurring: Scalars['Boolean']['output'];
};

/** The fields for sorting */
export type CircleRequestSortInput = {
  /** The direction to order by */
  direction: SortOrder;
  /** The fields to sort by */
  field: CircleRequestsSortableFields;
};

export enum CircleRequestsSortableFields {
  AcceptedBy = 'acceptedBy',
  AddressId = 'addressId',
  AskTo = 'askTo',
  Availabilities = 'availabilities',
  CancellationReason = 'cancellationReason',
  CancelledBy = 'cancelledBy',
  Category = 'category',
  CreatedAt = 'createdAt',
  CreatedBy = 'createdBy',
  Description = 'description',
  Frequency = 'frequency',
  Id = 'id',
  IsAvailabilityFlexible = 'isAvailabilityFlexible',
  OtherCategory = 'otherCategory',
  Recurring = 'recurring',
  RequestType = 'requestType',
  RequestedBy = 'requestedBy',
  Ride = 'ride',
  ScheduledAt = 'scheduledAt',
  Status = 'status',
  TentativeSchedule = 'tentativeSchedule',
  UpdatedAt = 'updatedAt',
}

/** The roles related to the relationship of users in the application */
export enum CircleRoles {
  CircleMember = 'circleMember',
  FamilyPartner = 'familyPartner',
}

/** Fields to update for a coach */
export type CoachInfoInput = {
  /** Bio to update */
  bio?: InputMaybe<Scalars['String']['input']>;
  /** First Name to update */
  firstName?: InputMaybe<Scalars['String']['input']>;
  /** Last Name to update */
  lastName?: InputMaybe<Scalars['String']['input']>;
};

export type CoachVisitFilter = {
  /** Filter by city */
  city?: InputMaybe<Scalars['String']['input']>;
  /** Filter by coach id */
  coachId?: InputMaybe<Array<Scalars['Int']['input']>>;
  /** Filter by createdAt after date */
  createdAtFrom?: InputMaybe<Scalars['DateTime']['input']>;
  /** Filter by createdAt before date */
  createdAtUntil?: InputMaybe<Scalars['DateTime']['input']>;
  /** Filter by customer id */
  customerId?: InputMaybe<Array<Scalars['Int']['input']>>;
  /** Filter by whether or not the coach visit has had any visit summaries sent */
  hasVisitSummary?: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by whether the meeting should be conducted via phone */
  isPhoneCall?: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by requestedBy */
  requestedBy?: InputMaybe<Array<Scalars['Int']['input']>>;
  /** Filter by scheduledAt after date */
  scheduledAtFrom?: InputMaybe<Scalars['DateTime']['input']>;
  /** Filter by scheduledAt before date */
  scheduledAtUntil?: InputMaybe<Scalars['DateTime']['input']>;
  /** Filter by status */
  status?: InputMaybe<Array<SocialEventStatus>>;
  /** Filter by zipCode */
  zipCode?: InputMaybe<Scalars['String']['input']>;
};

/** The fields for sorting */
export type CoachVisitSortInput = {
  /** The direction to order by */
  direction: SortOrder;
  /** The fields to sort by */
  field: CoachVisitsSortableFields;
};

export enum CoachVisitsSortableFields {
  AddressId = 'addressId',
  CancellationReason = 'cancellationReason',
  CancelledBy = 'cancelledBy',
  CreatedAt = 'createdAt',
  CreatedBy = 'createdBy',
  Description = 'description',
  Frequency = 'frequency',
  Id = 'id',
  Recurring = 'recurring',
  RequestType = 'requestType',
  RequestedBy = 'requestedBy',
  ScheduledAt = 'scheduledAt',
  Status = 'status',
  UpdatedAt = 'updatedAt',
}

/** The fields to filter companies */
export type CompanyFilter = {
  /** Retrieve companies that has agents with the specified service types */
  serviceTypes?: InputMaybe<Array<ServiceRequestCategoriesEnum>>;
  /** The company name or companies with agent name to filter by */
  text?: InputMaybe<Scalars['String']['input']>;
};

/** The fields for sorting */
export type CompanySortInput = {
  /** The direction to order by */
  direction: SortOrder;
  /** The fields to sort by */
  field: CompanySortableFields;
};

/** The possible fields that companies can be sorted */
export enum CompanySortableFields {
  CreatedAt = 'createdAt',
  Id = 'id',
  Name = 'name',
  Rating = 'rating',
  UpdatedAt = 'updatedAt',
}

export type ConfirmStripeTransactionOutput = {
  __typename?: 'ConfirmStripeTransactionOutput';
  success: Scalars['Boolean']['output'];
};

export enum ContactRequestStatus {
  Answered = 'answered',
  Pending = 'pending',
}

export type Conversation = {
  __typename?: 'Conversation';
  /** User information that created the conversation */
  CreatedBy?: Maybe<GraphqlUser>;
  /** List of users that are part of the conversation */
  Participants?: Maybe<Array<GraphqlUser>>;
  createdAt: Scalars['Float']['output'];
  custom: ConversationCustom;
  id: Scalars['String']['output'];
  lastMessage?: Maybe<Message>;
  participants: Array<Participant>;
  photoUrl?: Maybe<Scalars['String']['output']>;
  subject?: Maybe<Scalars['String']['output']>;
  topicId?: Maybe<Scalars['String']['output']>;
  welcomeMessages?: Maybe<Array<Scalars['String']['output']>>;
};

export type ConversationCustom = {
  __typename?: 'ConversationCustom';
  chatType?: Maybe<ChatTypeOptions>;
  city?: Maybe<Scalars['String']['output']>;
  /** User ID that created the conversation */
  createdBy?: Maybe<Scalars['String']['output']>;
  customerId?: Maybe<Scalars['String']['output']>;
  environment: Scalars['String']['output'];
  participants?: Maybe<Scalars['String']['output']>;
  /** The role of participants, used for Community chats */
  role?: Maybe<GlobalRole>;
  serviceRequestId?: Maybe<Scalars['String']['output']>;
  userIds?: Maybe<Scalars['String']['output']>;
  zipCode?: Maybe<Scalars['String']['output']>;
};

/** The fields for the custom data of a conversation */
export type ConversationCustomInput = {
  chatType?: InputMaybe<ChatTypeOptions>;
  city?: InputMaybe<Scalars['String']['input']>;
  customerId?: InputMaybe<Scalars['String']['input']>;
  participants?: InputMaybe<Scalars['String']['input']>;
  /** The role of participants, used for Community chats */
  role?: InputMaybe<Scalars['String']['input']>;
  serviceRequestId?: InputMaybe<Scalars['String']['input']>;
  zipCode?: InputMaybe<Scalars['String']['input']>;
};

/** The filters for the custom data of a conversation */
export type ConversationCustomInputFilter = {
  chatTypes?: InputMaybe<Array<Scalars['String']['input']>>;
  city?: InputMaybe<Scalars['String']['input']>;
  customerId?: InputMaybe<Scalars['String']['input']>;
  participants?: InputMaybe<Scalars['String']['input']>;
  /** The role of participants, used for Community chats */
  role?: InputMaybe<Scalars['String']['input']>;
  serviceRequestId?: InputMaybe<Scalars['String']['input']>;
  zipCode?: InputMaybe<Scalars['String']['input']>;
};

/** The possible fields that conversations can be sorted */
export enum ConversationsSortableFields {
  CreatedAt = 'createdAt',
  LastActivity = 'lastActivity',
}

/** The fields to create an address */
export type CreateAddressInput = {
  /** First address line */
  addressLine1: Scalars['String']['input'];
  /** The additional address information */
  addressLine2?: InputMaybe<Scalars['String']['input']>;
  /** The address city */
  city: Scalars['String']['input'];
  /** The address country code */
  country: Scalars['String']['input'];
  /** Customer relation ID */
  customerId: Scalars['Int']['input'];
  /** Whether the address is the default one */
  isDefault?: InputMaybe<Scalars['Boolean']['input']>;
  /** Whether the address was created using the Google Maps API */
  isFromAPI?: InputMaybe<Scalars['Boolean']['input']>;
  /** Alias for the address */
  place?: InputMaybe<Scalars['String']['input']>;
  /** The address state */
  state: Scalars['String']['input'];
  /** The address zip code */
  zipCode: Scalars['String']['input'];
};

/** The fields for creating an agent */
export type CreateAgentInput = {
  /** The ID of the company that the agent belongs to */
  companyId: Scalars['Int']['input'];
  /** The agent's email */
  email: Scalars['String']['input'];
  /** The agent's first name */
  firstName: Scalars['String']['input'];
  /** The agent's internal (hidden) rating */
  internalRating?: InputMaybe<Scalars['Int']['input']>;
  /** The agent's last name */
  lastName: Scalars['String']['input'];
  /** Optional password to be set on agent creation */
  password?: InputMaybe<Scalars['String']['input']>;
  /** The agent's phone number */
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
  /** The prefered contact methods of the agent for being reach by Brya */
  preferedContactMethods?: InputMaybe<Array<AgentPreferedContactMethodEnum>>;
  /** The agent's rating */
  rating?: InputMaybe<Scalars['Float']['input']>;
  /** The agent role (agent admin or agent) */
  role: AgentRole;
  /** The types of services that the agent can provide */
  serviceTypes?: InputMaybe<Array<ServiceRequestCategoriesEnum>>;
};

export type CreateAppointmentPaymentInput = {
  /** The amount that is being charged for the service */
  amount: Scalars['Float']['input'];
  appointmentRequestId: Scalars['Int']['input'];
  /** How many hours it should take to complete the service */
  estimateHours?: InputMaybe<Scalars['Float']['input']>;
  /** The payment type */
  type: ServicePaymentType;
};

export type CreateCircleRequestInput = {
  /** Address id related to where the request was made */
  addressId?: InputMaybe<Scalars['Int']['input']>;
  /** The IDs of the user that will be asked for attend this request */
  askTo?: InputMaybe<Array<Scalars['Int']['input']>>;
  /** The availabilities set by the user on when the request can be scheduled */
  availabilities: Array<ServiceRequestAvailabilityInput>;
  /** The category of this request */
  category: CircleRequestCategoriesEnum;
  /** The customer id related to this request */
  customerId: Scalars['Int']['input'];
  /** Description of the request made by the user */
  description: Scalars['String']['input'];
  /** Recurring Frequency */
  frequency?: InputMaybe<RecurringFrequencyType>;
  /** Specifies if the user has flexible availability for scheduling the request */
  isAvailabilityFlexible: Scalars['Boolean']['input'];
  /** If 'other' specified in 'category', this is the user's input on the category */
  otherCategory?: InputMaybe<Scalars['String']['input']>;
  /** Marks request as recurring */
  recurring: Scalars['Boolean']['input'];
  /** The user who is requesting the circle request (it should be a non management user) */
  requestedBy?: InputMaybe<Scalars['Int']['input']>;
  /** The ride details of the request provided by the user */
  ride?: InputMaybe<RideInput>;
  /** The tentative schedule of the request provided by the user */
  tentativeSchedule: ServiceRequestTentativeSchedule;
};

export type CreateCoachReviewInput = {
  coachId: Scalars['Int']['input'];
  comment?: InputMaybe<Scalars['String']['input']>;
  rating: Scalars['Int']['input'];
};

export type CreateCoachVisitInput = {
  /** Address id related to where the visit was requested */
  addressId?: InputMaybe<Scalars['Int']['input']>;
  /** The id of coach related to the visit */
  coachId?: InputMaybe<Scalars['Int']['input']>;
  /** The id of customer related to the visit */
  customerId: Scalars['Int']['input'];
  /** Description of the visit */
  description?: InputMaybe<Scalars['String']['input']>;
  /** Recurring Frequency */
  frequency?: InputMaybe<RecurringFrequencyType>;
  /** Whether the meeting should be conducted via phone */
  isPhoneCall?: InputMaybe<Scalars['Boolean']['input']>;
  /** Whether the visit is recurring or not */
  recurring?: InputMaybe<Scalars['Boolean']['input']>;
  /** When the visit is going to occur */
  scheduledAt: Scalars['DateTime']['input'];
};

/** Fields to create a company */
export type CreateCompanyInput = {
  addressLine1: Scalars['String']['input'];
  addressLine2?: InputMaybe<Scalars['String']['input']>;
  city: Scalars['String']['input'];
  hours?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
  serviceZipCodes: Array<Scalars['String']['input']>;
  state: Scalars['String']['input'];
  website?: InputMaybe<Scalars['String']['input']>;
  zipCode: Scalars['String']['input'];
};

export type CreateContactRequestInput = {
  bryaInternalNotes?: InputMaybe<Scalars['String']['input']>;
  bryaMessage?: InputMaybe<Scalars['String']['input']>;
  customerActivityId?: InputMaybe<Scalars['Int']['input']>;
  status?: InputMaybe<ContactRequestStatus>;
  userMessage?: InputMaybe<Scalars['String']['input']>;
};

/** The fields to create a customer */
export type CreateCustomerInput = {
  /** The customer birthday */
  birthday?: InputMaybe<Scalars['DateTime']['input']>;
  /** The customer courtesy title (Mr., Ms., etc.) */
  courtesyTitle?: InputMaybe<Scalars['String']['input']>;
  /** The customer email */
  email: Scalars['String']['input'];
  /** The customer first name */
  firstName: Scalars['String']['input'];
  /** The customer last name */
  lastName: Scalars['String']['input'];
  /** The customer phone number */
  phoneNumber: Scalars['String']['input'];
};

/** The fields to create a customer with additional information */
export type CreateCustomerWithAdditionalInformationInput = {
  /** The customer age */
  age?: InputMaybe<Scalars['Int']['input']>;
  /** The customer birthday */
  birthday?: InputMaybe<Scalars['DateTime']['input']>;
  /** Other important information of the customer */
  city?: InputMaybe<Scalars['String']['input']>;
  /** The customer courtesy title (Mr., Ms., etc.) */
  courtesyTitle?: InputMaybe<Scalars['String']['input']>;
  /** The educational background of the customer */
  educationalBackground?: InputMaybe<Scalars['String']['input']>;
  /** The customer email */
  email: Scalars['String']['input'];
  /** The customer first name */
  firstName: Scalars['String']['input'];
  /** The customer gender */
  gender?: InputMaybe<Scalars['String']['input']>;
  /** The hobbies and interests of the customer */
  hobbies?: InputMaybe<Scalars['String']['input']>;
  /** The customer last name */
  lastName: Scalars['String']['input'];
  /** Other important information of the customer */
  otherInformation?: InputMaybe<Scalars['String']['input']>;
  /** The customer phone number */
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
  /** The professional background of the customer */
  professionalBackground?: InputMaybe<Scalars['String']['input']>;
  /** The relationship status of the customer */
  relationshipStatus?: InputMaybe<Scalars['String']['input']>;
  /** The number of years the customer has been retired */
  retiredTime?: InputMaybe<Scalars['Int']['input']>;
  /** Other important information of the customer */
  state?: InputMaybe<Scalars['String']['input']>;
  /** Other important information of the customer */
  zipCode: Scalars['String']['input'];
};

export type CreateExpiringTokenOutput = {
  __typename?: 'CreateExpiringTokenOutput';
  /** The expiring token to use to login in the mobile app */
  token: Scalars['String']['output'];
};

/** The fields for creating a feature flag */
export type CreateFeatureFlagInput = {
  /** The feature flag human readable description */
  description?: InputMaybe<Scalars['String']['input']>;
  /** Whether if the flag is enabled or not */
  enabled?: InputMaybe<Scalars['Boolean']['input']>;
  /** The feature flag identifier */
  feature: Scalars['String']['input'];
};

export type CreateInternalNoteInput = {
  /** Optional associated coach visit id */
  coachVisitId?: InputMaybe<Scalars['Int']['input']>;
  /** Optional associated service request id */
  employeeId?: InputMaybe<Scalars['Int']['input']>;
  /** Internal note text */
  note: Scalars['String']['input'];
  /** Optional associated service request id */
  serviceRequestId?: InputMaybe<Scalars['Int']['input']>;
};

export type CreateNotificationInput = {
  /** The deeplink related to the notification */
  deeplink?: InputMaybe<Scalars['String']['input']>;
  /** The payload of the action */
  payload?: InputMaybe<Scalars['JsonValue']['input']>;
  /** The text */
  text: Scalars['String']['input'];
  /** The title */
  title?: InputMaybe<Scalars['String']['input']>;
  /** The notification type */
  type: Scalars['String']['input'];
  /** The user id related to this notification */
  userId: Scalars['Int']['input'];
};

export type CreateRequestCategoryInput = {
  circleRequest: Scalars['Boolean']['input'];
  circleRequestOrder?: InputMaybe<Scalars['Int']['input']>;
  id: Scalars['String']['input'];
  name: Scalars['String']['input'];
  serviceRequest: Scalars['Boolean']['input'];
  serviceRequestOrder?: InputMaybe<Scalars['Int']['input']>;
};

export type CreateServicePaymentInput = {
  /** The amount that is being charged for the service */
  amount: Scalars['Float']['input'];
  /** How many hours it should take to complete the service */
  estimateHours?: InputMaybe<Scalars['Float']['input']>;
  serviceRequestId: Scalars['Int']['input'];
  /** Payment method id that is being used to pay for the service */
  stripePaymentMethodId?: InputMaybe<Scalars['String']['input']>;
  /** The payment type */
  type: ServicePaymentType;
};

export type CreateServiceRequestInput = {
  /** Address id related to where the service was requested */
  addressId?: InputMaybe<Scalars['Int']['input']>;
  /** The availabilities set by the user on when the service can be scheduled */
  availabilities: Array<ServiceRequestAvailabilityInput>;
  /** The category of this service request */
  category: ServiceRequestCategoriesEnum;
  /** The customer id related to this service request */
  customerId: Scalars['Int']['input'];
  /** Description of the request made by the user */
  description: Scalars['String']['input'];
  /** Recurring Frequency */
  frequency?: InputMaybe<RecurringFrequencyType>;
  /** Specifies if the user has flexible availability for scheduling the request */
  isAvailabilityFlexible: Scalars['Boolean']['input'];
  /** If 'other' specified in 'category', this is the user's input on the category */
  otherCategory?: InputMaybe<Scalars['String']['input']>;
  /** Marks service request as recurring */
  recurring: Scalars['Boolean']['input'];
  /** The user who is requesting the service (it should be a non management user) */
  requestedBy?: InputMaybe<Scalars['Int']['input']>;
  /** The ride details of the service request provided by the user */
  ride?: InputMaybe<RideInput>;
  /** The tentative schedule of the service request provided by the user */
  tentativeSchedule: ServiceRequestTentativeSchedule;
};

export type CreateServiceRequestReviewInput = {
  comment?: InputMaybe<Scalars['String']['input']>;
  /** Whether if the reviewer is marking the agent as favorite or not */
  markAsFavorite?: InputMaybe<Scalars['Boolean']['input']>;
  /** The negative tags for the review */
  negativeTags?: InputMaybe<Array<PositiveTags>>;
  /** The positive tags for the review */
  positiveTags?: InputMaybe<Array<PositiveTags>>;
  rating: Scalars['Int']['input'];
  serviceRequestId: Scalars['Int']['input'];
};

export type CreateSocialEventInput = {
  /** Location address line 1 */
  addressLine1: Scalars['String']['input'];
  /** Location address line 2 */
  addressLine2?: InputMaybe<Scalars['String']['input']>;
  /** Location address city name */
  city: Scalars['String']['input'];
  /** The ids of coaches related to the event */
  coachesIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  /** Location address country name */
  country: Scalars['String']['input'];
  /** Description of the event */
  description?: InputMaybe<Scalars['String']['input']>;
  /** The event name */
  eventName: Scalars['String']['input'];
  /** Frequency at which the event recurs */
  frequency?: InputMaybe<RecurringFrequencyType>;
  /** The ids of participants related to the event */
  participantsIds: Array<Scalars['Int']['input']>;
  /** Location place name */
  place?: InputMaybe<Scalars['String']['input']>;
  /** Whether the event is recurring or not */
  recurring?: InputMaybe<Scalars['Boolean']['input']>;
  /** When the event is going to occur */
  scheduledAt: Scalars['DateTime']['input'];
  /** Location address state name */
  state: Scalars['String']['input'];
  /** Location address ZIP code */
  zipCode: Scalars['String']['input'];
};

export type CreateStripeSubscriptionOutput = {
  __typename?: 'CreateStripeSubscriptionOutput';
  /** The client secret to allow the user to pay for the subscription, if undefined, it is the free plan */
  clientSecret?: Maybe<Scalars['String']['output']>;
  subscriptionId: Scalars['String']['output'];
};

/** The available fileds to create a timeline entry for a user, a customer and a service request */
export type CreateStripeSubscriptionPrice = {
  name: Scalars['String']['input'];
  price: Scalars['Float']['input'];
  /** The id of the stripe product (in case you are adding a new price to an existing product) */
  productId?: InputMaybe<Scalars['String']['input']>;
  /** The name of the stripe product (in case you are adding a new price with a new product) */
  productName?: InputMaybe<Scalars['String']['input']>;
  /** The recurring pattern for a new price */
  recurring?: InputMaybe<StripeSubscriptionPricesRecurringEnum>;
  type?: InputMaybe<Scalars['String']['input']>;
};

/** The fields for creating a suggested activity */
export type CreateSuggestedActivityInput = {
  /** The suggested activity description */
  description: Scalars['String']['input'];
  /** The suggested activity name */
  name: Scalars['String']['input'];
  /** The role the user wants to have */
  role: SuggestedActivitiesRoleEnum;
  /** The user that is creating the suggested activity */
  userId: Scalars['Int']['input'];
};

/** The available fileds to create a timeline entry for a user, a customer and a service request */
export type CreateTimelineInput = {
  activityId?: InputMaybe<Scalars['Int']['input']>;
  customerId?: InputMaybe<Scalars['Int']['input']>;
  description: Scalars['String']['input'];
  title: Scalars['String']['input'];
  userId?: InputMaybe<Scalars['Int']['input']>;
};

/** The fields for creating an user */
export type CreateUserInput = {
  /** The user email */
  email: Scalars['String']['input'];
  /** The user first name */
  firstName: Scalars['String']['input'];
  /** The user last name */
  lastName: Scalars['String']['input'];
  /** The user password */
  password: Scalars['String']['input'];
};

/** The available fileds to create a circle invites for a customer */
export type CreateUserInviteInput = {
  /** The invites to send the invitation */
  invites: Array<UserInviteInput>;
};

export type CreateUserInvitesOutput = {
  __typename?: 'CreateUserInvitesOutput';
  sentVia: Array<SentViaOutput>;
  success: Scalars['Boolean']['output'];
};

/** The available fileds to create a user requests can be to circle or family group */
export type CreateUserRequestInput = {
  /** The customer whom will recieve the request */
  customerId: Scalars['Int']['input'];
  /** The email to sent the request */
  email?: InputMaybe<Scalars['String']['input']>;
  /** The person name to whom the invite was sent */
  name: Scalars['String']['input'];
  /** The phone number to sent the request */
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
  /** The type of the request, familyPartner or circleMember */
  requestType: UserInvitesType;
  /** The user whom will send the request */
  requestedBy?: InputMaybe<Scalars['Int']['input']>;
};

export type CreateVisitSummaryInput = {
  /** Visit summary body text */
  body: Scalars['String']['input'];
  /** Associated coach visit id */
  coachVisitId?: InputMaybe<Scalars['Int']['input']>;
  /** List of user IDs to whom to send summary */
  sentTo: Array<Scalars['Int']['input']>;
};

/** The possible fields that the customers can be sorted by */
export enum CustomerSortableFields {
  Birthday = 'birthday',
  CreatedAt = 'createdAt',
  FullName = 'fullName',
  Id = 'id',
  UpdatedAt = 'updatedAt',
}

/** The mobile tabs that can be the default one that should be opened for an user */
export enum DefaultTab {
  Chat = 'chat',
  Help = 'help',
  Schedule = 'schedule',
}

export type DeleteAccountOutput = {
  __typename?: 'DeleteAccountOutput';
  success: Scalars['Boolean']['output'];
};

export type DeleteAddressOutput = {
  __typename?: 'DeleteAddressOutput';
  success: Scalars['Boolean']['output'];
};

export type DeleteAgentOutput = {
  __typename?: 'DeleteAgentOutput';
  success: Scalars['Boolean']['output'];
};

export type DeleteConversationOutput = {
  __typename?: 'DeleteConversationOutput';
  /** The id of the conversation that was deleted */
  conversationId: Scalars['String']['output'];
  /** If the conversation was deleted successfully */
  success: Scalars['Boolean']['output'];
};

export type DeleteReviewOutput = {
  __typename?: 'DeleteReviewOutput';
  reviewId: Scalars['Int']['output'];
  success: Scalars['Boolean']['output'];
};

export type DeleteSuggestedActivityOutput = {
  __typename?: 'DeleteSuggestedActivityOutput';
  success: Scalars['Boolean']['output'];
};

export type DeleteUserInvitesOutput = {
  __typename?: 'DeleteUserInvitesOutput';
  success: Scalars['Boolean']['output'];
};

export type ExchangeAuthIdOutput = {
  __typename?: 'ExchangeAuthIdOutput';
  accessToken?: Maybe<Scalars['String']['output']>;
  finished: Scalars['Boolean']['output'];
};

export type ExchangeExpiringTokenOutput = {
  __typename?: 'ExchangeExpiringTokenOutput';
  accessToken: Scalars['String']['output'];
};

/** The possible fields that files can be sorted */
export enum FileSortableFields {
  CreatedAt = 'createdAt',
  Name = 'name',
  UpdatedAt = 'updatedAt',
}

export type FilesFilter = {
  agentId?: InputMaybe<Scalars['Int']['input']>;
  customerId?: InputMaybe<Scalars['Int']['input']>;
  uploadBy?: InputMaybe<Scalars['Int']['input']>;
  userId?: InputMaybe<Scalars['Int']['input']>;
};

/** The sorting configuration */
export type FilesSort = {
  /** The direction to order by */
  direction: SortOrder;
  /** The fields to sort by */
  field: FileSortableFields;
};

/** The fields to filter a customer */
export type FiltersInput = {
  /** The filter based on full name */
  fullName?: InputMaybe<Scalars['String']['input']>;
  /** Whether or not the customer has a coach assigned */
  hasCoach?: InputMaybe<Scalars['Boolean']['input']>;
  /** The field to sorter by */
  sortField: CustomerSortableFields;
  /** The value to order by */
  sortValue: SortOrder;
  /** Filters customers based on a user that is associated with them */
  userId?: InputMaybe<Scalars['Float']['input']>;
};

export type FinishAuthIdOutput = {
  __typename?: 'FinishAuthIdOutput';
  success: Scalars['Boolean']['output'];
};

export type FrontAppChannel = {
  __typename?: 'FrontAppChannel';
  /** The channel chat id */
  channelChatId: Scalars['String']['output'];
  /** The current user hash for this channel */
  userHash: Scalars['String']['output'];
};

export type GenerateAuthIdOutput = {
  __typename?: 'GenerateAuthIdOutput';
  /** Genereated auth id to use to log in in the mobile app */
  authId: Scalars['String']['output'];
  /** Genereated secure token to use to be able to log in in the mobile app */
  secureToken: Scalars['String']['output'];
};

export type GetActivitiesAlertsOutput = {
  __typename?: 'GetActivitiesAlertsOutput';
  id: Scalars['Int']['output'];
  status: ServiceRequestStatus;
};

export type GetActivitiesGroupedByDateOutput = {
  __typename?: 'GetActivitiesGroupedByDateOutput';
  /** The list of circle, service requests and social events for specific customers in a specific date grouped by date */
  meta: ActivitiesGroupedByDateMeta;
  /** The list of circle, service requests and social events for specific customers in a specific date grouped by date */
  requests: Array<ActivitiesGroupedByDateOutput>;
};

export type GetActivitiesOutput = {
  __typename?: 'GetActivitiesOutput';
  /** The pagination information */
  meta: PaginationMeta;
  /** The list of circle, service requests and social events for specific customers */
  requests: Array<Activities>;
};

export type GetAgentReviewsOutput = {
  __typename?: 'GetAgentReviewsOutput';
  meta: PaginationMeta;
  reviews: Array<GraphqlServiceRequestReview>;
};

export type GetAgentsByServiceRequestOutput = {
  __typename?: 'GetAgentsByServiceRequestOutput';
  /** List of agents */
  agents: Array<GetTopAgentsOutput>;
};

export type GetAgentsOutput = {
  __typename?: 'GetAgentsOutput';
  /** List of agents */
  agents: Array<GraphqlAgent>;
  /** Pagination information */
  meta: PaginationMeta;
};

export type GetAppointmentRequestsActivitiesOutput = {
  __typename?: 'GetAppointmentRequestsActivitiesOutput';
  /** The list of activities */
  activities: Array<GraphqlAppointmentRequest>;
  /** The pagination information */
  meta: PaginationMeta;
};

export type GetAppointmentRequestsOutput = {
  __typename?: 'GetAppointmentRequestsOutput';
  /** The list of appointment requests */
  appointmentRequests: Array<GraphqlAppointmentRequest>;
  /** The pagination information */
  meta: PaginationMeta;
};

export type GetCircleRequestsOutput = {
  __typename?: 'GetCircleRequestsOutput';
  /** The list of requests */
  circleRequests: Array<GraphqlCircleRequest>;
  /** The pagination information */
  meta: PaginationMeta;
};

export type GetCoachProfileOutput = {
  __typename?: 'GetCoachProfileOutput';
  /** The bio description */
  bio?: Maybe<Scalars['String']['output']>;
  /** The first name */
  firstName: Scalars['String']['output'];
  /** The full name */
  fullName: Scalars['String']['output'];
  id: Scalars['Float']['output'];
  /** The last name */
  lastName: Scalars['String']['output'];
  /** The profile picture */
  profilePicture?: Maybe<Scalars['String']['output']>;
};

export type GetCoachReviewsOutput = {
  __typename?: 'GetCoachReviewsOutput';
  meta: PaginationMeta;
  reviews: Array<GraphqlCoachReview>;
};

export type GetCoachVisitsOutput = {
  __typename?: 'GetCoachVisitsOutput';
  /** The list of coach visits */
  coachVisits: Array<GraphqlCoachVisit>;
  /** The pagination information */
  meta: PaginationMeta;
};

export type GetCompaniesOutput = {
  __typename?: 'GetCompaniesOutput';
  /** List of companies */
  companies: Array<GraphqlCompany>;
  /** Pagination information */
  meta: PaginationMeta;
};

export type GetConversationSignatureOutput = {
  __typename?: 'GetConversationSignatureOutput';
  /** Generated embedUrl to create an iframe of a read-only chat history */
  embedUrl: Scalars['String']['output'];
};

/** The options to filter conversations */
export type GetConversationsFilter = {
  /** Retrieves conversation with the specified custom fields */
  custom?: InputMaybe<ConversationCustomInputFilter>;
  /** Retrieves conversations with last message after the specified date */
  lastMessageAfter?: InputMaybe<Scalars['DateTime']['input']>;
  /** Retrieves conversations with last message before the specified date */
  lastMessageBefore?: InputMaybe<Scalars['DateTime']['input']>;
};

export type GetConversationsOutput = {
  __typename?: 'GetConversationsOutput';
  /** List of conversations */
  conversations: Array<Conversation>;
};

/** Conversations sorting configuration */
export type GetConversationsSort = {
  /** The direction to order by */
  direction: Scalars['String']['input'];
  /** The field to sort by */
  field: ConversationsSortableFields;
};

export type GetCustomerOutput = {
  __typename?: 'GetCustomerOutput';
  /** The user who create the customer */
  Creator?: Maybe<GraphqlUser>;
  /** More information related to the customer */
  CustomerAdditionalInformation?: Maybe<GraphqlCustomerAdditionalInformation>;
  /** The users and roles associated to the customer */
  UserCustomerRoles?: Maybe<Array<GraphqlUserRole>>;
  /** The addresses of a customer */
  addresses?: Maybe<Array<GraphqlAddresses>>;
  /** The customer birthday date */
  birthday?: Maybe<Scalars['DateTime']['output']>;
  /** The date the customer has been created */
  createdAt: Scalars['DateTime']['output'];
  /** The id of the user that created the customer */
  createdBy: Scalars['Int']['output'];
  /** The customer email */
  email?: Maybe<Scalars['String']['output']>;
  /** The customer first name */
  firstName?: Maybe<Scalars['String']['output']>;
  /** The customer full name */
  fullName?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  /** The customer last name */
  lastName?: Maybe<Scalars['String']['output']>;
  /** The customer phone number */
  phoneNumber?: Maybe<Scalars['String']['output']>;
  /** The last date the customer has been updated */
  updatedAt: Scalars['DateTime']['output'];
  /** The list of users related to the customer */
  users?: Maybe<Array<UserGetCustomerOutput>>;
};

export type GetDefaultTimezone = {
  __typename?: 'GetDefaultTimezone';
  /** The default timezone in the app */
  timezone: Scalars['String']['output'];
};

export type GetFilesOutput = {
  __typename?: 'GetFilesOutput';
  /** List of files */
  files: Array<GraphqlFile>;
  /** Pagination information */
  meta: PaginationMeta;
};

export type GetFrontAppChannels = {
  __typename?: 'GetFrontAppChannels';
  /** Coach channel */
  coachChannel: FrontAppChannel;
  /** Support channel */
  supportChannel: FrontAppChannel;
};

export type GetGooglePlacesApiKeyOutput = {
  __typename?: 'GetGooglePlacesApiKeyOutput';
  /** google api key */
  apiKey?: Maybe<Scalars['String']['output']>;
};

export type GetInternalNotesOutput = {
  __typename?: 'GetInternalNotesOutput';
  meta: PaginationMeta;
  results: Array<GraphqlInternalNote>;
};

export type GetNotificationsByUserIdOutput = {
  __typename?: 'GetNotificationsByUserIdOutput';
  /** The pagination information */
  meta: PaginationMeta;
  /** The list of notifications */
  notifications: Array<GraphqlNotification>;
};

export type GetRecentActivityOuput = {
  __typename?: 'GetRecentActivityOuput';
  /** Latest coach reviews */
  coachReviews?: Maybe<Array<GraphqlCoachReview>>;
  /** Latest service requests reviews */
  serviceRequestReviews?: Maybe<Array<GraphqlServiceRequestReview>>;
  /** Latest service requests */
  serviceRequests?: Maybe<Array<GraphqlServiceRequest>>;
  /** Latest social events */
  socialEvents?: Maybe<Array<GraphqlSocialEvent>>;
};

export type GetServiceRequestsOutput = {
  __typename?: 'GetServiceRequestsOutput';
  /** The pagination information */
  meta: PaginationMeta;
  /** The list of service requests */
  serviceRequests: Array<GraphqlServiceRequest>;
};

export type GetSignatureOutput = {
  __typename?: 'GetSignatureOutput';
  /** Generated signature to use in the chat */
  signature: Scalars['String']['output'];
  talkJsAppId: Scalars['String']['output'];
  talkJsId: Scalars['String']['output'];
};

export type GetSocialEventsOutput = {
  __typename?: 'GetSocialEventsOutput';
  /** The pagination information */
  meta: PaginationMeta;
  /** The list of social events */
  socialEvents: Array<GraphqlSocialEvent>;
};

export type GetStripeTransactionOutput = {
  __typename?: 'GetStripeTransactionOutput';
  /** The pagination information */
  meta: PaginationMeta;
  /** The list of transactions */
  transactions: Array<GraphqlStripeTransaction>;
};

export type GetTopAgentsOutput = {
  __typename?: 'GetTopAgentsOutput';
  /** The company that the agent belongs to */
  Company?: Maybe<GraphqlCompany>;
  /** The user information related to the favorite agent */
  CustomerFavoriteAgents?: Maybe<Array<GraphqlCustomerFavoriteAgent>>;
  /** The service request reviews related to this agent */
  ServiceRequestReviews?: Maybe<Array<GraphqlServiceRequestReview>>;
  /** The User information related to the agent */
  User: GraphqlUser;
  /** Whether an appointment request has been sent to the agent or not */
  appointmentRequestSent: Scalars['Boolean']['output'];
  /** The ID of the company that the agent belongs to */
  companyId: Scalars['Int']['output'];
  /** Whether the agent is a favorite of the customer or not */
  customerFavorite?: Maybe<Scalars['Boolean']['output']>;
  /** The datetime at which the agent was soft-deleted (if ever) */
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  /** if the customer logged in has marked the agent as favorite */
  hasMarkedAgentAsFavorite?: Maybe<Scalars['Boolean']['output']>;
  /** The agent's internal (hidden) rating */
  internalRating?: Maybe<Scalars['Int']['output']>;
  /** The prefered contact methods of the agent for being reach by Brya */
  preferedContactMethods: Array<AgentPreferedContactMethodEnum>;
  /** The agent's rating */
  rating?: Maybe<Scalars['Float']['output']>;
  /** The agent role */
  role: AgentRole;
  /** The types of services that the agent can provide given in a object structure */
  serviceTypeInfo?: Maybe<Array<ServiceType>>;
  /** The types of services that the agent can provide */
  serviceTypes: Array<ServiceRequestCategoriesEnum>;
  /** The position of the top list */
  top: Scalars['Int']['output'];
  /** Whether the agent is trusted favorite or not */
  trustedFavorite?: Maybe<Scalars['Boolean']['output']>;
  /** Whether the agent is trusted reviewed or not */
  trustedReviewed?: Maybe<Scalars['Boolean']['output']>;
  userId: Scalars['Int']['output'];
};

/** The fields for filter in the GetUserParticipants query  */
export type GetUserParticipantsFilter = {
  /** The user name to filter by */
  fullName?: InputMaybe<Scalars['String']['input']>;
  /** The user type to filter by */
  type?: InputMaybe<TrustedUserTypeOptions>;
};

export type GetUserParticipantsOutput = {
  __typename?: 'GetUserParticipantsOutput';
  /** The list of users from the database */
  users: Array<UserWithTrustedFlag>;
  /** The list of users from TalkJs */
  usersFromTalkJs: Array<UserWithTrustedFlag>;
};

export type GetUsersOutput = {
  __typename?: 'GetUsersOutput';
  /** The pagination information */
  meta: PaginationMeta;
  /** The list of users */
  users: Array<GraphqlUser>;
};

export type GetVisitSummariesOutput = {
  __typename?: 'GetVisitSummariesOutput';
  meta: PaginationMeta;
  results: Array<GraphqlVisitSummary>;
};

export type GetVisitSummaryByCoachVisitIdOutput = {
  __typename?: 'GetVisitSummaryByCoachVisitIdOutput';
  results: Array<GraphqlVisitSummary>;
};

export type GetZipCodeMatchesBusinessRules = {
  __typename?: 'GetZipCodeMatchesBusinessRules';
  message: Scalars['String']['output'];
  success: Scalars['Boolean']['output'];
};

/** All the roles available in the application */
export enum GlobalRole {
  Admin = 'admin',
  AdminAgent = 'adminAgent',
  Agent = 'agent',
  CircleMember = 'circleMember',
  Coach = 'coach',
  FamilyPartner = 'familyPartner',
  SeniorMember = 'seniorMember',
  Support = 'support',
}

export type GraphqlAddresses = {
  __typename?: 'GraphqlAddresses';
  /** The address of the customer */
  addressLine1: Scalars['String']['output'];
  /** The additional address information  */
  addressLine2?: Maybe<Scalars['String']['output']>;
  /** The customer address city */
  city: Scalars['String']['output'];
  /** The customer address country code */
  country: Scalars['String']['output'];
  /** The date the customer address has been created */
  createdAt: Scalars['DateTime']['output'];
  customerId: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  /** Whether the address is the default one or not */
  isDefault: Scalars['Boolean']['output'];
  /** Whether the address was created using Google Maps API */
  isFromAPI: Scalars['Boolean']['output'];
  /** The customer place */
  place?: Maybe<Scalars['String']['output']>;
  /** The customer address state */
  state: Scalars['String']['output'];
  /** The last date the customer address has been updated */
  updatedAt: Scalars['DateTime']['output'];
  /** The customer address zip code */
  zipCode: Scalars['String']['output'];
};

export type GraphqlAgent = {
  __typename?: 'GraphqlAgent';
  /** The company that the agent belongs to */
  Company?: Maybe<GraphqlCompany>;
  /** The user information related to the favorite agent */
  CustomerFavoriteAgents?: Maybe<Array<GraphqlCustomerFavoriteAgent>>;
  /** The service request reviews related to this agent */
  ServiceRequestReviews?: Maybe<Array<GraphqlServiceRequestReview>>;
  /** The User information related to the agent */
  User: GraphqlUser;
  /** The ID of the company that the agent belongs to */
  companyId: Scalars['Int']['output'];
  /** The datetime at which the agent was soft-deleted (if ever) */
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  /** if the customer logged in has marked the agent as favorite */
  hasMarkedAgentAsFavorite?: Maybe<Scalars['Boolean']['output']>;
  /** The agent's internal (hidden) rating */
  internalRating?: Maybe<Scalars['Int']['output']>;
  /** The prefered contact methods of the agent for being reach by Brya */
  preferedContactMethods: Array<AgentPreferedContactMethodEnum>;
  /** The agent's rating */
  rating?: Maybe<Scalars['Float']['output']>;
  /** The agent role */
  role: AgentRole;
  /** The types of services that the agent can provide given in a object structure */
  serviceTypeInfo?: Maybe<Array<ServiceType>>;
  /** The types of services that the agent can provide */
  serviceTypes: Array<ServiceRequestCategoriesEnum>;
  userId: Scalars['Int']['output'];
};

export type GraphqlAppFeaturesSelected = {
  __typename?: 'GraphqlAppFeaturesSelected';
  /** The User information related to the app features selected */
  User: GraphqlUser;
  /** Whether the user includes circle member features */
  circleMember: Scalars['Boolean']['output'];
  createdAt: Scalars['DateTime']['output'];
  /** Whether the user includes family partner features */
  familyPartner: Scalars['Boolean']['output'];
  id: Scalars['Int']['output'];
  /** Whether the user includes senior member features */
  seniorMember: Scalars['Boolean']['output'];
  updatedAt: Scalars['DateTime']['output'];
  userId: Scalars['Int']['output'];
};

export type GraphqlAppointmentRequest = {
  __typename?: 'GraphqlAppointmentRequest';
  /** The payment information related to the appointment request */
  AppointmentRequestPayments?: Maybe<Array<GraphqlAppointmentRequestPayment>>;
  CircleRequest?: Maybe<GraphqlCircleRequest>;
  /** The user who sent the request */
  CreatedBy?: Maybe<GraphqlUser>;
  /** The agent or circle member assigned to the request */
  SentTo?: Maybe<GraphqlUser>;
  ServiceRequest?: Maybe<GraphqlServiceRequest>;
  /** The id of the circle request */
  circleRequestId?: Maybe<Scalars['Int']['output']>;
  createdAt: Scalars['DateTime']['output'];
  createdBy: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  /** The datetime the recipient has chosen from within availability */
  scheduledAt?: Maybe<Scalars['DateTime']['output']>;
  /** The id of the service request */
  serviceRequestId?: Maybe<Scalars['Int']['output']>;
  status: AppointmentRequestStatus;
  updatedAt: Scalars['DateTime']['output'];
  userId: Scalars['Int']['output'];
};

export type GraphqlAppointmentRequestPayment = {
  __typename?: 'GraphqlAppointmentRequestPayment';
  /** The amount that is being used as base to calculate who much the service will cost */
  amount: Scalars['Float']['output'];
  appointmentRequestId: Scalars['Int']['output'];
  /** The date the account was created */
  createdAt: Scalars['DateTime']['output'];
  /** The estimate hours the service should take to be completed */
  estimateHours?: Maybe<Scalars['Float']['output']>;
  /** The amount that is being charged as a service fee */
  fee: Scalars['Float']['output'];
  id: Scalars['Int']['output'];
  /** The amount that is being charged as state tax */
  tax: Scalars['Float']['output'];
  /** The type used to charge the service */
  type: ServicePaymentType;
  /** The date the account was last updated */
  updatedAt: Scalars['DateTime']['output'];
};

export type GraphqlBlockedUsers = {
  __typename?: 'GraphqlBlockedUsers';
  /** The user information related to the user that was blocked */
  BlockedUser: GraphqlUser;
  /** The user information related to the user that blocked another */
  BlockerUser: GraphqlUser;
  /** The user id of the user that was blocked */
  blockedUserId: Scalars['Int']['output'];
  /** The user id of the user that blocked another user */
  blockerUserId: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
};

export type GraphqlCircleRequest = {
  __typename?: 'GraphqlCircleRequest';
  /** The user that accepted the request */
  AcceptedBy?: Maybe<GraphqlUser>;
  /** The address related to where the service was requested */
  Address?: Maybe<GraphqlAddresses>;
  /** The user that accepted the request */
  AskTo: Array<GraphqlUser>;
  /** The user that cancelled the service */
  CancelledBy?: Maybe<GraphqlUser>;
  /** The user that created the request */
  CreatedBy: GraphqlUser;
  /** The customer related to the request */
  Customer: GetCustomerOutput;
  /** The category associated to the request */
  RequestCategory?: Maybe<GraphqlRequestCategory>;
  /** The user that asked for the request */
  RequestedBy: GraphqlUser;
  /** The timeline events related to the requested */
  Timeline?: Maybe<Array<GraphqlTimeline>>;
  /** The date at which someone accepted the request */
  acceptedAt?: Maybe<Scalars['DateTime']['output']>;
  /** The user id that accepted the request */
  acceptedBy?: Maybe<Scalars['Int']['output']>;
  /** Address ID related to the request */
  addressId?: Maybe<Scalars['Int']['output']>;
  /** The user IDs that are asked to attend the request by the user */
  askTo: Array<Scalars['Int']['output']>;
  /** The availabilities set by the user on when the request can be scheduled */
  availabilities: Array<ServiceRequestAvailability>;
  /** The category of this request */
  category: CircleRequestCategoriesEnum;
  /** The date the request was created */
  createdAt: Scalars['DateTime']['output'];
  /** The user id that created the request */
  createdBy: Scalars['Int']['output'];
  /** The customer id related to this request */
  customerId: Scalars['Int']['output'];
  /** Description of the request made by the user */
  description?: Maybe<Scalars['String']['output']>;
  /** Recurring Frequency */
  frequency?: Maybe<RecurringFrequencyType>;
  id: Scalars['Int']['output'];
  /** Specifies if the user has flexible availability for scheduling the request */
  isAvailabilityFlexible: Scalars['Boolean']['output'];
  /** The last time the status was updated */
  lastStatusUpdate?: Maybe<Scalars['DateTime']['output']>;
  /** If 'other' specified in 'category', this is the user's input on the category */
  otherCategory?: Maybe<Scalars['String']['output']>;
  /** Marks request as recurring */
  recurring: Scalars['Boolean']['output'];
  /** The type of the service request */
  requestType: ServiceRequestType;
  /** The user id that asked for the request */
  requestedBy: Scalars['Int']['output'];
  /** The ride information for the request */
  ride?: Maybe<Ride>;
  /** The date at which someone will attend the request */
  scheduledAt?: Maybe<Scalars['DateTime']['output']>;
  /** The current status of the service request */
  status: ServiceRequestStatus;
  /** Specifies the tentative schedule that the user proposed for the request */
  tentativeSchedule: ServiceRequestTentativeSchedule;
  /** The date the request was last updated */
  updatedAt: Scalars['DateTime']['output'];
};

export type GraphqlCoachReview = {
  __typename?: 'GraphqlCoachReview';
  /** The user that made the review */
  ReviewedBy: GraphqlUser;
  coachId: Scalars['Int']['output'];
  comment?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['Int']['output'];
  rating: Scalars['Int']['output'];
  reviewedBy: Scalars['Int']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type GraphqlCoachVisit = {
  __typename?: 'GraphqlCoachVisit';
  /** The address where the visit is going to occur */
  Address?: Maybe<GraphqlAddresses>;
  /** The user that cancelled the visit */
  CancelledBy?: Maybe<GraphqlUser>;
  /** The coach information */
  Coach: GraphqlUser;
  /** The user that created the event */
  CreatedBy: GraphqlUser;
  /** The customer information */
  Customer: GraphqlCustomer;
  /** Notes related to the Coach Visit */
  InternalNotes?: Maybe<Array<GraphqlInternalNote>>;
  /** The user that asked for the event */
  RequestedBy: GraphqlUser;
  /** Visit Summaries related to the Coach Visit */
  VisitSummaries?: Maybe<Array<GraphqlVisitSummary>>;
  /** Address id related to where the event was requested */
  addressId?: Maybe<Scalars['Int']['output']>;
  /** The reason the event was cancelled */
  cancellationReason?: Maybe<Scalars['String']['output']>;
  /** The date the event was created */
  createdAt: Scalars['DateTime']['output'];
  /** The user id that created the event */
  createdBy: Scalars['Int']['output'];
  /** Description of the visit made by the user */
  description?: Maybe<Scalars['String']['output']>;
  /** Recurring Frequency */
  frequency?: Maybe<RecurringFrequencyType>;
  id: Scalars['Int']['output'];
  /** The last time the status was updated */
  lastStatusUpdate?: Maybe<Scalars['DateTime']['output']>;
  /** Whether the visit is a recurring visit or not */
  recurring: Scalars['Boolean']['output'];
  /** The type of the event */
  requestType: ServiceRequestType;
  /** The user id that asked for the event */
  requestedBy: Scalars['Int']['output'];
  /** The date where the event will occur */
  scheduledAt?: Maybe<Scalars['DateTime']['output']>;
  /** The current status of the event */
  status: SocialEventStatus;
  /** The date the event was last updated */
  updatedAt: Scalars['DateTime']['output'];
};

export type GraphqlCoaches = {
  __typename?: 'GraphqlCoaches';
  /** The user information of the coach */
  Coach?: Maybe<GraphqlUser>;
  /** The id of the event */
  activityId?: Maybe<Scalars['Int']['output']>;
  /** The id of the coach that was invited to the event */
  coachId: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
};

export type GraphqlCompany = {
  __typename?: 'GraphqlCompany';
  Agents: Array<GraphqlAgent>;
  StripeConnectAccounts?: Maybe<GraphqlStripeConnectAccount>;
  addressLine1: Scalars['String']['output'];
  addressLine2?: Maybe<Scalars['String']['output']>;
  city: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  hours?: Maybe<Scalars['String']['output']>;
  id: Scalars['Float']['output'];
  name: Scalars['String']['output'];
  phoneNumber?: Maybe<Scalars['String']['output']>;
  rating?: Maybe<Scalars['Float']['output']>;
  serviceZipCodes?: Maybe<Array<Scalars['String']['output']>>;
  state: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
  website?: Maybe<Scalars['String']['output']>;
  zipCode: Scalars['String']['output'];
};

export type GraphqlContactRequest = {
  __typename?: 'GraphqlContactRequest';
  bryaInternalNotes?: Maybe<Scalars['String']['output']>;
  bryaMessage?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['DateTime']['output'];
  customerActivityId?: Maybe<Scalars['Int']['output']>;
  id: Scalars['Int']['output'];
  status: ContactRequestStatus;
  updatedAt: Scalars['DateTime']['output'];
  userId: Scalars['Int']['output'];
  userMessage?: Maybe<Scalars['String']['output']>;
};

export type GraphqlCustomer = {
  __typename?: 'GraphqlCustomer';
  /** The user who create the customer */
  Creator?: Maybe<GraphqlUser>;
  /** More information related to the customer */
  CustomerAdditionalInformation?: Maybe<GraphqlCustomerAdditionalInformation>;
  /** The users and roles associated to the customer */
  UserCustomerRoles?: Maybe<Array<GraphqlUserRole>>;
  /** The addresses of a customer */
  addresses?: Maybe<Array<GraphqlAddresses>>;
  /** The customer birthday date */
  birthday?: Maybe<Scalars['DateTime']['output']>;
  /** The date the customer has been created */
  createdAt: Scalars['DateTime']['output'];
  /** The id of the user that created the customer */
  createdBy: Scalars['Int']['output'];
  /** The customer email */
  email?: Maybe<Scalars['String']['output']>;
  /** The customer first name */
  firstName?: Maybe<Scalars['String']['output']>;
  /** The customer full name */
  fullName?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  /** The customer last name */
  lastName?: Maybe<Scalars['String']['output']>;
  /** The customer phone number */
  phoneNumber?: Maybe<Scalars['String']['output']>;
  /** The last date the customer has been updated */
  updatedAt: Scalars['DateTime']['output'];
};

export type GraphqlCustomerAdditionalInformation = {
  __typename?: 'GraphqlCustomerAdditionalInformation';
  /** The customer age */
  age?: Maybe<Scalars['Int']['output']>;
  city?: Maybe<Scalars['String']['output']>;
  customerId: Scalars['Int']['output'];
  /** The educational background of the customer */
  educationalBackground?: Maybe<Scalars['String']['output']>;
  /** The customer gender */
  gender?: Maybe<Scalars['String']['output']>;
  /** The hobbies and interests of the customer */
  hobbies?: Maybe<Scalars['String']['output']>;
  /** Other important information of the customer */
  otherInformation?: Maybe<Scalars['String']['output']>;
  /** The professional background of the customer */
  professionalBackground?: Maybe<Scalars['String']['output']>;
  /** The relationship status of the customer */
  relationshipStatus?: Maybe<Scalars['String']['output']>;
  /** The number of years the customer has been retired */
  retiredTime?: Maybe<Scalars['Int']['output']>;
  state?: Maybe<Scalars['String']['output']>;
  zipCode: Scalars['String']['output'];
};

export type GraphqlCustomerAgent = {
  __typename?: 'GraphqlCustomerAgent';
  /** The company that the agent belongs to */
  Company?: Maybe<GraphqlCompany>;
  /** The user information related to the favorite agent */
  CustomerFavoriteAgents?: Maybe<Array<GraphqlCustomerFavoriteAgent>>;
  /** The service request reviews related to this agent */
  ServiceRequestReviews?: Maybe<Array<GraphqlServiceRequestReview>>;
  /** The User information related to the agent */
  User: GraphqlUser;
  /** The ID of the company that the agent belongs to */
  companyId: Scalars['Int']['output'];
  /** The datetime at which the agent was soft-deleted (if ever) */
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  /** Service categories customer marked this agent as favorite */
  favorite: Array<ServiceRequestCategoriesEnum>;
  /** if the customer logged in has marked the agent as favorite */
  hasMarkedAgentAsFavorite?: Maybe<Scalars['Boolean']['output']>;
  /** The agent's internal (hidden) rating */
  internalRating?: Maybe<Scalars['Int']['output']>;
  /** The prefered contact methods of the agent for being reach by Brya */
  preferedContactMethods: Array<AgentPreferedContactMethodEnum>;
  /** The agent's rating */
  rating?: Maybe<Scalars['Float']['output']>;
  /** The agent role */
  role: AgentRole;
  /** The types of services that the agent can provide given in a object structure */
  serviceTypeInfo?: Maybe<Array<ServiceType>>;
  /** The types of services that the agent can provide */
  serviceTypes: Array<ServiceRequestCategoriesEnum>;
  userId: Scalars['Int']['output'];
};

export type GraphqlCustomerFavoriteAgent = {
  __typename?: 'GraphqlCustomerFavoriteAgent';
  /** The Customer information related to the favorite agent */
  Customer?: Maybe<GraphqlCustomer>;
  agentId: Scalars['Int']['output'];
  customerId: Scalars['Int']['output'];
  /** The types of services that the agent can provide */
  serviceType: ServiceRequestCategoriesEnum;
};

export type GraphqlCustomerSocialEvent = {
  __typename?: 'GraphqlCustomerSocialEvent';
  /** The address where the event is going to occur */
  Address?: Maybe<GraphqlAddresses>;
  /** The user that cancelled the visit */
  CancelledBy?: Maybe<GraphqlUser>;
  /** The contact requests of the current customer */
  ContactRequests: Array<GraphqlContactRequest>;
  /** The user that created the event */
  CreatedBy: GraphqlUser;
  /** The customer information of the participant */
  Customer: GraphqlCustomer;
  /** The user that asked for the event */
  RequestedBy: GraphqlUser;
  /** Address id related to where the event was requested */
  addressId?: Maybe<Scalars['Int']['output']>;
  /** Location address line 1 */
  addressLine1: Scalars['String']['output'];
  /** Location address line 2 */
  addressLine2?: Maybe<Scalars['String']['output']>;
  /** The reason the event was cancelled */
  cancellationReason?: Maybe<Scalars['String']['output']>;
  /** Location address city name */
  city: Scalars['String']['output'];
  /** Location address country name */
  country: Scalars['String']['output'];
  /** The date the event was created */
  createdAt: Scalars['DateTime']['output'];
  /** The user id that created the event */
  createdBy: Scalars['Int']['output'];
  /** The id of the customer activity */
  customerActivityId: Scalars['Int']['output'];
  /** Description of the event made by the user */
  description?: Maybe<Scalars['String']['output']>;
  /** The event name */
  eventName: Scalars['String']['output'];
  /** Recurring Frequency */
  frequency?: Maybe<RecurringFrequencyType>;
  id: Scalars['Int']['output'];
  /** The last time the status was updated */
  lastStatusUpdate?: Maybe<Scalars['DateTime']['output']>;
  /** Location place name */
  place?: Maybe<Scalars['String']['output']>;
  /** Whether the event is a recurring event or not */
  recurring: Scalars['Boolean']['output'];
  /** The type of the event */
  requestType: ServiceRequestType;
  /** The user id that asked for the event */
  requestedBy: Scalars['Int']['output'];
  /** The date where the event will occur */
  scheduledAt?: Maybe<Scalars['DateTime']['output']>;
  /** Location address state name */
  state: Scalars['String']['output'];
  /** The status of the participant for the event */
  status: ServiceRequestStatus;
  /** The date the event was last updated */
  updatedAt: Scalars['DateTime']['output'];
  /** Location address ZIP code */
  zipCode: Scalars['String']['output'];
};

export type GraphqlFeatureFlag = {
  __typename?: 'GraphqlFeatureFlag';
  /** Human readable feature description */
  description?: Maybe<Scalars['String']['output']>;
  /** Whether if the feature is enabled or not */
  enabled: Scalars['Boolean']['output'];
  /** The feature identifier */
  feature: Scalars['String']['output'];
  id: Scalars['Int']['output'];
};

export type GraphqlFile = {
  __typename?: 'GraphqlFile';
  Agent?: Maybe<GraphqlAgent>;
  Customer?: Maybe<GraphqlCustomer>;
  UploadBy?: Maybe<GraphqlUser>;
  User?: Maybe<GraphqlUser>;
  agentId?: Maybe<Scalars['Float']['output']>;
  createdAt: Scalars['DateTime']['output'];
  customerId?: Maybe<Scalars['Float']['output']>;
  downloadUrl?: Maybe<Scalars['String']['output']>;
  id: Scalars['Float']['output'];
  key: Scalars['String']['output'];
  name: Scalars['String']['output'];
  size: Scalars['Float']['output'];
  type: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
  uploadBy?: Maybe<Scalars['Float']['output']>;
  userId?: Maybe<Scalars['Float']['output']>;
};

export type GraphqlFlaggedMessages = {
  __typename?: 'GraphqlFlaggedMessages';
  /** The user information related to the user that blocked another */
  User: GraphqlUser;
  /** The conversation id */
  conversationId: Scalars['String']['output'];
  id: Scalars['Int']['output'];
  /** The text message */
  messageBody: Scalars['String']['output'];
  /** The message id */
  messageId: Scalars['String']['output'];
  /** The user that send the message */
  userId: Scalars['Int']['output'];
};

export type GraphqlInternalNote = {
  __typename?: 'GraphqlInternalNote';
  CoachVisit?: Maybe<GraphqlCoachVisit>;
  Employee?: Maybe<GraphqlUser>;
  ServiceRequest?: Maybe<GraphqlServiceRequest>;
  /** The user that made the note */
  Users: GraphqlUser;
  /** Optional associated coach visit id */
  coachVisitId?: Maybe<Scalars['Int']['output']>;
  createdAt: Scalars['DateTime']['output'];
  /** Optional associated employee (user) to the note */
  employeeId?: Maybe<Scalars['Int']['output']>;
  id: Scalars['Int']['output'];
  /** Internal note text */
  note: Scalars['String']['output'];
  /** Optional associated service request id */
  serviceRequestId?: Maybe<Scalars['Int']['output']>;
  updatedAt: Scalars['DateTime']['output'];
  /** User id owner of the note */
  userId: Scalars['Int']['output'];
};

export type GraphqlNotification = {
  __typename?: 'GraphqlNotification';
  /** The user related to this notification */
  User?: Maybe<GraphqlUser>;
  /** The date the notification has been created */
  createdAt: Scalars['DateTime']['output'];
  /** The deeplink of the notification */
  deeplink?: Maybe<Scalars['String']['output']>;
  /** The date when the nofitication was sent via email */
  emailSentAt?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['Int']['output'];
  /** The payload of the action */
  payload?: Maybe<Scalars['JsonValue']['output']>;
  /** The date when the nofitication was sent via push */
  pushSentAt?: Maybe<Scalars['DateTime']['output']>;
  /** The date where the notification was read */
  readAt?: Maybe<Scalars['DateTime']['output']>;
  /** The date when the nofitication was sent via sms */
  smsSentAt?: Maybe<Scalars['DateTime']['output']>;
  /** The text */
  text: Scalars['String']['output'];
  /** The title */
  title?: Maybe<Scalars['String']['output']>;
  /** The notification type */
  type: Scalars['String']['output'];
  /** The date the notification has been updated */
  updatedAt: Scalars['DateTime']['output'];
  /** The user id related to this notification */
  userId: Scalars['Int']['output'];
};

export type GraphqlNotificationSettings = {
  __typename?: 'GraphqlNotificationSettings';
  /** The date the timeline has been created */
  createdAt: Scalars['DateTime']['output'];
  /** whether notifications will be sent via email or not */
  email: Scalars['Boolean']['output'];
  id: Scalars['Int']['output'];
  /** the notification type */
  notificationType: Scalars['String']['output'];
  /** whether notifications will be sent via push or not */
  push: Scalars['Boolean']['output'];
  /** whether notifications will be sent via push web or not */
  pushWeb: Scalars['Boolean']['output'];
  /** whether notifications will be sent via sms or not */
  sms: Scalars['Boolean']['output'];
  /** The user id related to settings */
  userId: Scalars['Int']['output'];
};

export type GraphqlParticipants = {
  __typename?: 'GraphqlParticipants';
  /** The contact requests of the current customer */
  ContactRequests: Array<GraphqlContactRequest>;
  /** The customer information of the participant */
  Customer?: Maybe<GraphqlCustomer>;
  /** The date the event was accepted */
  acceptedAt?: Maybe<Scalars['DateTime']['output']>;
  /** The id of the event */
  activityId?: Maybe<Scalars['Int']['output']>;
  /** The id of the customer that was invited to the event */
  customerId: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  /** The last time the status was updated */
  lastStatusUpdate?: Maybe<Scalars['DateTime']['output']>;
  /** The reason the event was rejected by the user */
  rejectedReason?: Maybe<Scalars['String']['output']>;
  /** The status of the participant for the event */
  status: ServiceRequestStatus;
};

export type GraphqlRequestCategory = {
  __typename?: 'GraphqlRequestCategory';
  /** whether the category is for circle requests or not  */
  circleRequest: Scalars['Boolean']['output'];
  /** the number of the category order when it is listed */
  circleRequestOrder?: Maybe<Scalars['Int']['output']>;
  createdAt: Scalars['DateTime']['output'];
  /** the id of the category */
  id: Scalars['String']['output'];
  /** the name of the category */
  name: Scalars['String']['output'];
  /** whether the category is for service requests or not  */
  serviceRequest: Scalars['Boolean']['output'];
  /** the number of the category order when it is listed */
  serviceRequestOrder?: Maybe<Scalars['Int']['output']>;
  updatedAt: Scalars['DateTime']['output'];
};

export type GraphqlRideAddress = {
  __typename?: 'GraphqlRideAddress';
  /** First address line */
  addressLine1: Scalars['String']['output'];
  /** The additional address information  */
  addressLine2?: Maybe<Scalars['String']['output']>;
  /** The address city */
  city: Scalars['String']['output'];
  /** The address country code */
  country: Scalars['String']['output'];
  /** Whether the address is the default one */
  isDefault?: Maybe<Scalars['Boolean']['output']>;
  /** Whether the address was created using the Google Maps API */
  isFromAPI?: Maybe<Scalars['Boolean']['output']>;
  /** Alias for the address */
  place?: Maybe<Scalars['String']['output']>;
  /** The address state */
  state: Scalars['String']['output'];
  /** The address zip code */
  zipCode: Scalars['String']['output'];
};

export type GraphqlServiceRequest = {
  __typename?: 'GraphqlServiceRequest';
  /** The address related to where the service was requested */
  Address?: Maybe<GraphqlAddresses>;
  /** The agent assigned to the request */
  Agent?: Maybe<GraphqlAgent>;
  /** Appointment requests associated with this service request */
  AppointmentRequests?: Maybe<Array<GraphqlAppointmentRequest>>;
  /** The user that cancelled the service  */
  CancelledBy?: Maybe<GraphqlUser>;
  /** The user that created the request */
  CreatedBy: GraphqlUser;
  /** The customer related to the request */
  Customer: GraphqlCustomer;
  /** Internal notes associated with this service request */
  InternalNotes?: Maybe<Array<GraphqlInternalNote>>;
  /** The category associated to the request */
  RequestCategory?: Maybe<GraphqlRequestCategory>;
  /** The user that asked for the request */
  RequestedBy: GraphqlUser;
  /** The payment information related to the service */
  ServiceRequestPayments?: Maybe<Array<GraphqlServiceRequestPayment>>;
  /** The timeline events related to the requested */
  Timeline?: Maybe<Array<GraphqlTimeline>>;
  /** The date at which the agent was assigned to the request */
  acceptedAt?: Maybe<Scalars['DateTime']['output']>;
  actionNeeded?: Maybe<Scalars['String']['output']>;
  /** Address id related to where the service was requested */
  addressId?: Maybe<Scalars['Int']['output']>;
  /** The agent id that will attend the request */
  agentId?: Maybe<Scalars['Int']['output']>;
  /** The availabilities set by the user on when the service can be scheduled */
  availabilities: Array<ServiceRequestAvailability>;
  /** The reason the service request was cancelled */
  cancellationReason?: Maybe<Scalars['String']['output']>;
  /** The category of this service request */
  category: ServiceRequestCategoriesEnum;
  /** Pro comment that the only brya can see */
  commentForBrya?: Maybe<Scalars['String']['output']>;
  /** Pro comment that the customer can see */
  commentForCustomer?: Maybe<Scalars['String']['output']>;
  /** The date the request was created */
  createdAt: Scalars['DateTime']['output'];
  /** The user id that created the request */
  createdBy: Scalars['Int']['output'];
  /** The customer id related to this service request */
  customerId: Scalars['Int']['output'];
  /** Description of the request made by the user */
  description?: Maybe<Scalars['String']['output']>;
  /** Recurring Frequency */
  frequency?: Maybe<RecurringFrequencyType>;
  id: Scalars['Int']['output'];
  /** Specifies if the user has flexible availability for scheduling the request */
  isAvailabilityFlexible: Scalars['Boolean']['output'];
  /** The last time the status was updated */
  lastStatusUpdate?: Maybe<Scalars['DateTime']['output']>;
  /** If 'other' specified in 'category', this is the user's input on the category */
  otherCategory?: Maybe<Scalars['String']['output']>;
  /** Wheter or not is the PVS was sent or not */
  postVisitSummarySent?: Maybe<Scalars['Boolean']['output']>;
  /** Marks service request as recurring */
  recurring: Scalars['Boolean']['output'];
  /** The reason the service request was rejected */
  rejectedReason?: Maybe<Scalars['String']['output']>;
  /** The type of the service request */
  requestType: ServiceRequestType;
  /** The user id that asked for the request */
  requestedBy: Scalars['Int']['output'];
  requiresAction?: Maybe<Scalars['Boolean']['output']>;
  /** The ride information for the service request */
  ride?: Maybe<Ride>;
  /** The date at which the agent will attend the request */
  scheduledAt?: Maybe<Scalars['DateTime']['output']>;
  /** Service fee percentage */
  serviceFee?: Maybe<Scalars['Float']['output']>;
  /** The current status of the service request */
  status: ServiceRequestStatus;
  /** Tax fee percentage */
  taxFee?: Maybe<Scalars['Float']['output']>;
  tentativeSchedule: ServiceRequestTentativeSchedule;
  /** The date the request was last updated */
  updatedAt: Scalars['DateTime']['output'];
};

export type GraphqlServiceRequestPayment = {
  __typename?: 'GraphqlServiceRequestPayment';
  StripeTransaction?: Maybe<GraphqlStripeTransaction>;
  /** The amount that is being used as base to calculate who much the service will cost */
  amount: Scalars['Float']['output'];
  /** The date the account was created */
  createdAt: Scalars['DateTime']['output'];
  /** The estimate hours the service should take to be completed */
  estimateHours?: Maybe<Scalars['Float']['output']>;
  /** The amount that is being charged as a service fee */
  fee: Scalars['Float']['output'];
  id: Scalars['Int']['output'];
  notes?: Maybe<Scalars['String']['output']>;
  serviceRequestId: Scalars['Int']['output'];
  stripePaymentMethodId?: Maybe<Scalars['String']['output']>;
  stripeTransactionId?: Maybe<Scalars['String']['output']>;
  /** The amount that is being charged as state tax */
  tax: Scalars['Float']['output'];
  /** The type used to charge the service */
  type: ServicePaymentType;
  /** The date the account was last updated */
  updatedAt: Scalars['DateTime']['output'];
};

export type GraphqlServiceRequestReview = {
  __typename?: 'GraphqlServiceRequestReview';
  /** The user that made the review */
  ReviewedBy: GraphqlUser;
  comment?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['DateTime']['output'];
  /** The negative tags of the review */
  negativeTags: Array<PositiveTags>;
  /** The positive tags of the review */
  positiveTags: Array<PositiveTags>;
  rating: Scalars['Int']['output'];
  reviewedBy: Scalars['Int']['output'];
  serviceRequestCategory?: Maybe<Scalars['String']['output']>;
  serviceRequestCategoryId?: Maybe<Scalars['String']['output']>;
  serviceRequestId: Scalars['Int']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type GraphqlSocialEvent = {
  __typename?: 'GraphqlSocialEvent';
  /** The address where the event is going to occur */
  Address?: Maybe<GraphqlAddresses>;
  /** The user that cancelled the event */
  CancelledBy?: Maybe<GraphqlUser>;
  /** The participants of the event who represent Brya */
  Coaches: Array<GraphqlCoaches>;
  /** The user that created the event */
  CreatedBy: GraphqlUser;
  /** The participants of the event */
  Participants: Array<GraphqlParticipants>;
  /** The user that asked for the event */
  RequestedBy: GraphqlUser;
  /** Address id related to where the event was requested */
  addressId?: Maybe<Scalars['Int']['output']>;
  /** Location address line 1 */
  addressLine1: Scalars['String']['output'];
  /** Location address line 2 */
  addressLine2?: Maybe<Scalars['String']['output']>;
  /** The reason the event was cancelled */
  cancellationReason?: Maybe<Scalars['String']['output']>;
  /** Location address city name */
  city: Scalars['String']['output'];
  /** Location address country name */
  country: Scalars['String']['output'];
  /** The date the event was created */
  createdAt: Scalars['DateTime']['output'];
  /** The user id that created the event */
  createdBy: Scalars['Int']['output'];
  /** Description of the event made by the user */
  description?: Maybe<Scalars['String']['output']>;
  /** The event name */
  eventName: Scalars['String']['output'];
  /** Recurring Frequency */
  frequency?: Maybe<RecurringFrequencyType>;
  id: Scalars['Int']['output'];
  /** The last time the status was updated */
  lastStatusUpdate?: Maybe<Scalars['DateTime']['output']>;
  /** Location place name */
  place?: Maybe<Scalars['String']['output']>;
  /** Whether the event is a recurring event or not */
  recurring: Scalars['Boolean']['output'];
  /** The type of the event */
  requestType: ServiceRequestType;
  /** The user id that asked for the event */
  requestedBy: Scalars['Int']['output'];
  /** The date where the event will occur */
  scheduledAt?: Maybe<Scalars['DateTime']['output']>;
  /** Location address state name */
  state: Scalars['String']['output'];
  /** The current status of the event */
  status: SocialEventStatus;
  /** The date the event was last updated */
  updatedAt: Scalars['DateTime']['output'];
  /** Location address ZIP code */
  zipCode: Scalars['String']['output'];
};

export type GraphqlStripeCard = {
  __typename?: 'GraphqlStripeCard';
  brand: Scalars['String']['output'];
  country?: Maybe<Scalars['String']['output']>;
  exp_month: Scalars['Int']['output'];
  exp_year: Scalars['Int']['output'];
  funding: Scalars['String']['output'];
  last4: Scalars['String']['output'];
};

export type GraphqlStripeConnectAccount = {
  __typename?: 'GraphqlStripeConnectAccount';
  /** The ID of the company that the stripe account belongs to */
  companyId: Scalars['Int']['output'];
  /** The date the account was created */
  createdAt: Scalars['DateTime']['output'];
  /** The account status */
  status: StripeConnectAccountStatus;
  stripeAccountId: Scalars['String']['output'];
  /** The date the account was last updated */
  updatedAt: Scalars['DateTime']['output'];
};

export type GraphqlStripeCustomerAccount = {
  __typename?: 'GraphqlStripeCustomerAccount';
  /** The date the account was created */
  createdAt: Scalars['DateTime']['output'];
  /** The date the account was removed */
  removedAt?: Maybe<Scalars['DateTime']['output']>;
  stripeCustomerId: Scalars['String']['output'];
  /** The date the account was last updated */
  updatedAt: Scalars['DateTime']['output'];
  /** The ID of the user that the stripe customer account belongs to */
  userId: Scalars['Int']['output'];
};

export type GraphqlStripeSubscription = {
  __typename?: 'GraphqlStripeSubscription';
  /** The customer information */
  Customer: GraphqlCustomer;
  cancelAtPeriodEnd: Scalars['Boolean']['output'];
  /** The date the subscription was created */
  createdAt: Scalars['DateTime']['output'];
  customerId: Scalars['Int']['output'];
  status: Scalars['String']['output'];
  stripeCustomerId: Scalars['String']['output'];
  stripeSubscriptionId: Scalars['String']['output'];
  stripeSubscriptionItemId: Scalars['String']['output'];
  stripeSubscriptionPriceId: Scalars['String']['output'];
  /** The date the subscription was last updated */
  updatedAt: Scalars['DateTime']['output'];
};

export type GraphqlStripeSubscriptionPrice = {
  __typename?: 'GraphqlStripeSubscriptionPrice';
  active: Scalars['Boolean']['output'];
  /** The date the price price was created */
  createdAt: Scalars['DateTime']['output'];
  name: Scalars['String']['output'];
  price: Scalars['Float']['output'];
  /** Id of price related to a Stripe Subscription product */
  stripePriceId: Scalars['String']['output'];
  stripeProductId: Scalars['String']['output'];
  type: Scalars['String']['output'];
  /** The date the product price was last updated */
  updatedAt: Scalars['DateTime']['output'];
};

export type GraphqlStripeTransaction = {
  __typename?: 'GraphqlStripeTransaction';
  /** The Service Request information */
  Activity?: Maybe<GraphqlServiceRequest>;
  /** The stripe payment intent information */
  StripeTransaction?: Maybe<StripePaymentIntent>;
  activityId: Scalars['Int']['output'];
  amount: Scalars['Float']['output'];
  /** The date the transaction was created */
  createdAt: Scalars['DateTime']['output'];
  status: Scalars['String']['output'];
  stripeConnectAccountId?: Maybe<Scalars['String']['output']>;
  stripeCustomerId: Scalars['String']['output'];
  stripeTransactionId: Scalars['String']['output'];
  /** The date the transaction was last updated */
  updatedAt: Scalars['DateTime']['output'];
};

export type GraphqlSuggestedActivities = {
  __typename?: 'GraphqlSuggestedActivities';
  /** The user that created the suggested activity */
  User: GraphqlUser;
  /** The date the suggestion was created */
  createdAt: Scalars['DateTime']['output'];
  description: Scalars['String']['output'];
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  /** Internal notes related to the suggested activity */
  notes?: Maybe<Scalars['String']['output']>;
  /** Whether the user wants to be a host or a participant */
  role: SuggestedActivitiesRoleEnum;
  userId: Scalars['Int']['output'];
};

export type GraphqlTimeline = {
  __typename?: 'GraphqlTimeline';
  /** The customer information related to the timeline event */
  Customer?: Maybe<GraphqlCustomer>;
  /** The user information related to the timeline event */
  User?: Maybe<GraphqlUser>;
  /** The activity id related to this timeline */
  activityId?: Maybe<Scalars['Int']['output']>;
  /** The date the timeline has been created */
  createdAt: Scalars['DateTime']['output'];
  /** The customer id related to this timeline */
  customerId?: Maybe<Scalars['Int']['output']>;
  description: Scalars['String']['output'];
  id: Scalars['Int']['output'];
  /** True if needs to be shown in the app */
  isAppVisible: Scalars['Boolean']['output'];
  title: Scalars['String']['output'];
  /** The user id related to this timeline */
  userId?: Maybe<Scalars['Int']['output']>;
};

export type GraphqlUser = {
  __typename?: 'GraphqlUser';
  /** The last date the user logged in */
  Agent?: Maybe<GraphqlAgent>;
  /** The app features available for this user */
  AppFeaturesSelected?: Maybe<GraphqlAppFeaturesSelected>;
  /** The customer that represents this user */
  OwnCustomer?: Maybe<GraphqlCustomer>;
  /** The current customer selected by the user */
  SelectedCustomer?: Maybe<GraphqlCustomer>;
  /** The roles related for this user */
  UserCustomerRoles?: Maybe<Array<GraphqlUserRole>>;
  /** User bio */
  bio?: Maybe<Scalars['String']['output']>;
  /** The date the user account has been created */
  createdAt: Scalars['DateTime']['output'];
  /** The default tab that should be opened when the user logs in in the mobile application */
  defaultTab?: Maybe<DefaultTab>;
  /** The user account status, whether it's disabled or not */
  disabled?: Maybe<Scalars['Boolean']['output']>;
  /** The user email address */
  email?: Maybe<Scalars['String']['output']>;
  /** The user email varified status */
  emailVerified?: Maybe<Scalars['Boolean']['output']>;
  /** The emergency contact, available for brya employees (coaches and support) */
  emergencyContact?: Maybe<UserEmergencyContact>;
  /** The firebase token associated with this user */
  firebaseToken?: Maybe<Scalars['String']['output']>;
  /** The user first name */
  firstName?: Maybe<Scalars['String']['output']>;
  /** The user full name */
  fullName?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  /** If the user is marked as a circle member or not */
  isCircleMember?: Maybe<Scalars['Boolean']['output']>;
  /** The user language */
  language?: Maybe<Scalars['String']['output']>;
  /** The last date the user logged in */
  lastLogin?: Maybe<Scalars['DateTime']['output']>;
  /** The user last name */
  lastName?: Maybe<Scalars['String']['output']>;
  /** The customer id associated with the user */
  ownCustomerId?: Maybe<Scalars['Int']['output']>;
  /** The user phone number */
  phoneNumber?: Maybe<Scalars['String']['output']>;
  /** The user profile picture */
  profilePicture?: Maybe<Scalars['String']['output']>;
  /** The role of the user */
  role?: Maybe<GlobalRole>;
  /** The user current selected customer */
  selectedCustomerId?: Maybe<Scalars['Int']['output']>;
  /** The user talkJs id for the chat */
  talkJsId?: Maybe<Scalars['String']['output']>;
  /** The last date the user account has been updated */
  updatedAt: Scalars['DateTime']['output'];
  /** Push notification subscription object */
  webPushSubscription?: Maybe<Scalars['String']['output']>;
};

export type GraphqlUserDevice = {
  __typename?: 'GraphqlUserDevice';
  /** The date the user device has been created */
  createdAt: Scalars['DateTime']['output'];
  /** The firebase token */
  firebaseToken?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  /** The date the user device has been updated */
  updatedAt: Scalars['DateTime']['output'];
  /** The user id */
  userId: Scalars['Float']['output'];
  /** The user device unique id */
  uuid: Scalars['String']['output'];
};

export type GraphqlUserInvite = {
  __typename?: 'GraphqlUserInvite';
  /** The customer information */
  Customer: GraphqlCustomer;
  /** The date the user invitation has been created */
  createdAt: Scalars['DateTime']['output'];
  /** The customer id */
  customerId: Scalars['Int']['output'];
  /** The person email to whom the invite was sent */
  email?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  /** The type of invite, familyPartner or circleMember */
  inviteType: UserInvitesType;
  /** The person who sent the invitation */
  invitedBy?: Maybe<Scalars['Int']['output']>;
  /** The person name to whom the invite was sent */
  name?: Maybe<Scalars['String']['output']>;
  /** The phone number lto whom the invite was sent */
  phoneNumber?: Maybe<Scalars['String']['output']>;
  sentVia: Array<Scalars['String']['output']>;
  status: UserInvitesStatus;
  /** The user id to whom the invite was sent */
  userId?: Maybe<Scalars['Int']['output']>;
};

export type GraphqlUserRequest = {
  __typename?: 'GraphqlUserRequest';
  /** The customer related to the request */
  Customer: GraphqlCustomer;
  /** The date the use request was created */
  createdAt: Scalars['DateTime']['output'];
  /** The customer that received the request */
  customerId?: Maybe<Scalars['Int']['output']>;
  /** The person email to whom the invite was sent */
  email?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  /** The person name to whom the invite was sent */
  name?: Maybe<Scalars['String']['output']>;
  /** The phone number to whom the invite was sent */
  phoneNumber?: Maybe<Scalars['String']['output']>;
  /** The type of invite, familyPartner or circleMember */
  requestType: UserInvitesType;
  /** The person who sent the invitation */
  requestedBy: Scalars['Int']['output'];
  sentVia: Array<Scalars['String']['output']>;
  status: UserInvitesStatus;
  /** The user that received the request */
  userId?: Maybe<Scalars['Int']['output']>;
};

export type GraphqlUserRole = {
  __typename?: 'GraphqlUserRole';
  /** The user associated */
  Users?: Maybe<GraphqlUser>;
  /** The date the user role has been created */
  createdAt: Scalars['DateTime']['output'];
  /** The customer id related to this role */
  customerId?: Maybe<Scalars['Int']['output']>;
  id: Scalars['Int']['output'];
  /** The user role */
  role: Role;
  /** The last date the user role has been updated */
  updatedAt: Scalars['DateTime']['output'];
  /** The user id related to this role */
  userId: Scalars['Int']['output'];
};

export type GraphqlVisitSummary = {
  __typename?: 'GraphqlVisitSummary';
  /** Associated Coach Visit */
  CoachVisit?: Maybe<GraphqlCoachVisit>;
  /** The user who created the Visit Summary */
  CreatedBy?: Maybe<GraphqlUser>;
  /** The users to whom the summary was sent */
  SentTo?: Maybe<Array<GraphqlUser>>;
  /** The user who most recently modified the Visit Summary */
  UpdatedBy?: Maybe<GraphqlUser>;
  /** Visit summary body text */
  body: Scalars['String']['output'];
  /** Associated coach visit id */
  coachVisitId: Scalars['Int']['output'];
  createdAt: Scalars['DateTime']['output'];
  createdBy: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  updatedAt: Scalars['DateTime']['output'];
  updatedBy: Scalars['Int']['output'];
};

/** The options to filter internal notes */
export type InternalNotesFilterOptions = {
  /** Matches coach visit */
  coachVisitId?: InputMaybe<Scalars['Float']['input']>;
  /** Matches employee */
  employeeId?: InputMaybe<Scalars['Float']['input']>;
  /** Matches service request */
  serviceRequestId?: InputMaybe<Scalars['Float']['input']>;
};

export type InternalNotesSortOptions = {
  /** The direction to order by */
  direction: SortOrder;
  /** The fields to sort by */
  field: InternalNotesSortableFields;
};

/** The possible fields that internal notes can be sorted */
export enum InternalNotesSortableFields {
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt',
}

export type LoginUserOutput = {
  __typename?: 'LoginUserOutput';
  accessToken: Scalars['String']['output'];
};

export type LogoutUserOutput = {
  __typename?: 'LogoutUserOutput';
  success: Scalars['Boolean']['output'];
};

/** The management roles */
export enum ManagementRole {
  Admin = 'admin',
  Coach = 'coach',
  Support = 'support',
}

export type Message = {
  __typename?: 'Message';
  attachment?: Maybe<MessageAttachment>;
  conversationId: Scalars['String']['output'];
  createdAt: Scalars['Float']['output'];
  editedAt?: Maybe<Scalars['Float']['output']>;
  id: Scalars['String']['output'];
  location?: Maybe<Array<Scalars['Float']['output']>>;
  origin: Scalars['String']['output'];
  readBy: Array<Scalars['String']['output']>;
  referencedMessageId?: Maybe<Scalars['String']['output']>;
  senderId?: Maybe<Scalars['String']['output']>;
  text: Scalars['String']['output'];
  type: Scalars['String']['output'];
};

export type MessageAttachment = {
  __typename?: 'MessageAttachment';
  size: Scalars['Float']['output'];
  url: Scalars['String']['output'];
};

export type Mutation = {
  __typename?: 'Mutation';
  /** Create a new management user in the application */
  adminCreateUser: GraphqlUser;
  /** Update the details of a specific admin user */
  adminUpdateUser: GraphqlUser;
  /** answer user request (change status) */
  answerRequest: ResponseUserRequestOutput;
  /** Assign a coach to a customer */
  assignCoach: GraphqlUserRole;
  /** Blocks a user */
  blockUser: GraphqlBlockedUsers;
  /** Cancel a specific transaction that is on hold */
  cancelStripeTransaction: GraphqlStripeTransaction;
  /** Confirms a stripe transaction with a payment method. This triggers the transaction to be charged or to authorize the amount and be later captured. It can also be used to retry to pay/hold failed transactions */
  confirmStripeTransaction: ConfirmStripeTransactionOutput;
  /** Cancel any scheduled job for extra charge and change status of service to paymentWaitingForSupport */
  contactSupportForExtraCharge: GraphqlServiceRequest;
  /** Create a new address for a customer */
  createAddress: GraphqlAddresses;
  /** Creates a new agent in the application */
  createAgent: GraphqlAgent;
  createAppointmentRequest: Array<GraphqlAppointmentRequest>;
  /** Create a appointment request payment */
  createAppointmentRequestPayment: GraphqlAppointmentRequestPayment;
  /** Creates a circle request */
  createCircleRequest: GraphqlCircleRequest;
  /** Create a coach visit */
  createCoachVisit: GraphqlCoachVisit;
  /** Create a new company */
  createCompany: GraphqlCompany;
  /** Create and get the stripe link to create a connect account or generate a login link */
  createConnectAccountOrLoginLink: Scalars['String']['output'];
  /** Create a new contact request */
  createContactRequest: GraphqlContactRequest;
  /** Create a new customer in the application */
  createCustomer: GraphqlCustomer;
  /** Creates a customer and the additional information associated with it, if there is something wrong nothing is created */
  createCustomerWithAdditionalInformation: GraphqlCustomer;
  /** Creates a customer and the address associated with it, if there is something wrong nothing is created */
  createCustomerWithAddress: GraphqlCustomer;
  /** Generate an expiring token to be used to login the user in the mobile app */
  createExpiringToken: CreateExpiringTokenOutput;
  /** Creates a new feature flag */
  createFeatureFlag: GraphqlFeatureFlag;
  createInternalNote: GraphqlInternalNote;
  /** Create a new, generic, notification to be sent */
  createNotification: NotificationOperationOutput;
  createRequestCategory: GraphqlRequestCategory;
  /** Create a service request */
  createServiceRequest: GraphqlServiceRequest;
  /** Create a service request payment */
  createServiceRequestPayment: GraphqlServiceRequestPayment;
  /** Allow agent to create the summary of a completed service request */
  createServiceRequestSummary: GraphqlServiceRequest;
  /** Create a social event */
  createSocialEvent: GraphqlSocialEvent;
  /** Create a customer stripe account */
  createStripeCustomerAccount: GraphqlStripeCustomerAccount;
  /** Create a customer stripe account */
  createStripeSubscription: CreateStripeSubscriptionOutput;
  /** Create a customer stripe account */
  createStripeSubscriptionPrice: GraphqlStripeSubscriptionPrice;
  /** Create a new suggested activities for a user */
  createSuggestedActivity: GraphqlSuggestedActivities;
  /** Creates a new timeline event in the application */
  createTimelineEvent: GraphqlTimeline;
  /** Create a new user in the application */
  createUser: GraphqlUser;
  /** Assign a coach to a customer */
  createUserCustomerRoleConnection: GraphqlUserRole;
  /** Creates a new circle invite and send email to the user by email or phone number */
  createUserInvites: CreateUserInvitesOutput;
  /** Creates a new user request and sent it by push notification */
  createUserRequest: CreateUserInvitesOutput;
  createVisitSummary: GraphqlVisitSummary;
  /** Subscribe to web push notifications based on your role */
  createWebPushSubscription: Scalars['Boolean']['output'];
  /** Deletes the user account and all related information */
  deleteAccount: DeleteAccountOutput;
  /** Delete an address */
  deleteAddress: DeleteAddressOutput;
  /** Soft-delete an agent */
  deleteAgent: DeleteAgentOutput;
  deleteCoachReview: DeleteReviewOutput;
  /** Delete a conversation by id */
  deleteConversation: DeleteConversationOutput;
  deleteInternalNote: Scalars['Boolean']['output'];
  /** Delete a notification for a user */
  deleteNotification: NotificationOperationOutput;
  deleteRequestCategory: Scalars['Boolean']['output'];
  /** Delete a suggested activity */
  deleteSuggestedActivity: DeleteSuggestedActivityOutput;
  /** Delete a user invite by id */
  deleteUserInvite: DeleteUserInvitesOutput;
  /** Delete a user request by id */
  deleteUserRequest: ResponseUserRequestOutput;
  deleteVisitSummary: Scalars['Boolean']['output'];
  /** Unsubscribe from web push notifications */
  destroyWebPushSubscription: Scalars['Boolean']['output'];
  /** Disable a specific user account */
  disableUser: GraphqlUser;
  /** Enable a specific user account */
  enableUser: GraphqlUser;
  /** Exchange the authId to an accessToken, to login the user in the mobile app */
  exchangeAuthId: ExchangeAuthIdOutput;
  /** Exchange the expiring token to an access token, and login the user in the mobile app */
  exchangeExpiringToken: ExchangeExpiringTokenOutput;
  /** Marks agent as favorite */
  favoriteAgent: GraphqlCustomerAgent;
  /** Set the generated authId to finished, as the login flow has been completed in the web application */
  finishAuthId: FinishAuthIdOutput;
  /** Send an email to the user to reset the account password */
  forgotPassword: ResetPasswordOutput;
  /** Generate an authId and secureToken to be used in the mobile app to login the user */
  generateAuthId: GenerateAuthIdOutput;
  /** The list of roles of the logged in user */
  getRoles: GraphqlUserRole;
  /** Join a circle */
  joinCircle: GraphqlUserInvite;
  /** Joins the current logged in management user to the conversation specified */
  joinConversation: Conversation;
  /** Joins the specified user to the conversation */
  joinUserToConversation: Conversation;
  /** Leave circle as a member */
  leaveCircle: DeleteUserInvitesOutput;
  /** Leave circle as a family partner */
  leaveCircleAsFamilyPartner: DeleteUserInvitesOutput;
  /** Log the user in the application */
  login: LoginUserOutput;
  /** Logout the user */
  logout: LogoutUserOutput;
  /** Add the email to the list of users that want to be notified when the Brya app is available */
  notifyMe: NotifyMeOutput;
  /** Mark all user notifications as read */
  readAllUserNotifications: NotificationOperationOutput;
  /** Mark notifications as read */
  readNotifications: NotificationOperationOutput;
  /** Returns a new token for the logged in user */
  refreshAccessToken: LoginUserOutput;
  /** Remove members from circle */
  removeMembersFromCircle: DeleteUserInvitesOutput;
  /** Removes a participant from a conversation */
  removeParticipant: Conversation;
  /** Assign a coach to a customer */
  removeUserCustomerRoleConnection: GraphqlUserRole;
  /** Creates a request to join somebody circle if the customer exists */
  requestToJoinCircle: RequestToJoinCircleOutput;
  /** Tries to request to join a family group if there is a existing customer with information provided */
  requestToJoinFamilyGroup: RequestToJoinFamilyGroupOutput;
  /** Resend a user invite */
  resendUserInvite: DeleteUserInvitesOutput;
  /** Send a new verification account email to the user */
  resendVerifyEmail: ResendVerifyEmailOutput;
  /** Update the user account password */
  resetPassword: LoginUserOutput;
  reviewCoach: GraphqlCoachReview;
  reviewServiceRequest: GraphqlServiceRequestReview;
  /** Save user firebase token for a device */
  saveFirebaseToken: GraphqlUserDevice;
  /** Save a notification setting for the user logged in */
  saveUserNotificationSettings: GraphqlNotificationSettings;
  /** Creates a request to a not existing user */
  sendRequestToNotExistingUser: SendRequestToNotExistingUserOutput;
  /** Creates or updates the app features selected for the given user */
  setAppFeaturesSelected: GraphqlAppFeaturesSelected;
  /** Sets the user current selected customer */
  setUserSelectedCustomer: GraphqlUser;
  /** Creates a conversation between the logged in user and users provided */
  startConversation: Conversation;
  /** Switch the role of the logged in user */
  switchUserRole: LoginUserOutput;
  /** Syncs the chat of a specific customer */
  syncCustomerChat: SyncUserChatOutput;
  /** Add or remove a specific user to the list of members a user trusts */
  trustUntrustMembers: TrustUntrustMembersOutput;
  /** Unblocks a user */
  unblockUser: GraphqlBlockedUsers;
  /** Unmarks agent as favorite */
  unfavoriteAgent: GraphqlCustomerAgent;
  /** Mark all user notifications as unread */
  unreadAllUserNotifications: NotificationOperationOutput;
  /** Mark notifications as unread */
  unreadNotifications: NotificationOperationOutput;
  /** Update the details of an address */
  updateAddress: GraphqlAddresses;
  /** Update the details of a specific agent */
  updateAgent: GraphqlAgent;
  updateAppointmentRequest: GraphqlAppointmentRequest;
  /** Update a appointment request payment */
  updateAppointmentRequestPayment: GraphqlAppointmentRequestPayment;
  /** Update request by id */
  updateCircleRequest: GraphqlCircleRequest;
  /** Allow user to update the schedule time of a circle request */
  updateCircleRequestTime: GraphqlCircleRequest;
  /** Change coach detail information  */
  updateCoachProfile: GraphqlUser;
  /** Update a coach visit by id */
  updateCoachVisit: GraphqlCoachVisit;
  /** Update a coach visit status by id */
  updateCoachVisitStatus: GraphqlCoachVisit;
  /** Update company */
  updateCompany: GraphqlCompany;
  /** Update a given contact request */
  updateContactRequest: GraphqlContactRequest;
  /** Update the details of the current user */
  updateCurrentUser: GraphqlUser;
  /** Update the details of a specific customer */
  updateCustomer: GraphqlCustomer;
  updateInternalNote: GraphqlInternalNote;
  updateRequestCategory: GraphqlRequestCategory;
  /** Update service request by id */
  updateServiceRequest: GraphqlServiceRequest;
  /** Update a service request payment */
  updateServiceRequestPayment: GraphqlServiceRequestPayment;
  /** Allow user to update the schedule time of a service request */
  updateServiceRequestTime: GraphqlServiceRequest;
  /** Update a social event by id */
  updateSocialEvent: GraphqlSocialEvent;
  /** Update a social event status by id */
  updateSocialEventStatus: GraphqlSocialEvent;
  /** Update the customer default card in stripe */
  updateStripeCustomerDefaultCard: UpdateStripeCustomerDefaultCardOutput;
  /** Update the details of a suggested activity */
  updateSuggestedActivity: GraphqlSuggestedActivities;
  updateVisitSummary: GraphqlVisitSummary;
  /** Allow user to approve or reject a service request */
  userApprovalServiceRequest: GraphqlServiceRequest;
  /** Allow user to approve or reject a social event */
  userApprovalSocialEvent: GraphqlSocialEvent;
  /** Allow user to cancel a circle request */
  userCancelCircleRequest: GraphqlCircleRequest;
  /** Allow user to cancel a service request */
  userCancelServiceRequest: GraphqlServiceRequest;
  /** Verify the user account email */
  verifyEmail: VerifyEmailOutput;
};

export type MutationAdminCreateUserArgs = {
  adminCreateUserInput: AdminCreateUserInput;
};

export type MutationAdminUpdateUserArgs = {
  adminUpdateUserInput: AdminUpdateUserInput;
  id: Scalars['Int']['input'];
};

export type MutationAnswerRequestArgs = {
  status: UserInvitesStatus;
  userRequestId: Scalars['Int']['input'];
};

export type MutationAssignCoachArgs = {
  customerId: Scalars['Int']['input'];
  userId: Scalars['Int']['input'];
};

export type MutationBlockUserArgs = {
  blockedUserId: Scalars['Int']['input'];
};

export type MutationCancelStripeTransactionArgs = {
  stripeTransactionId: Scalars['String']['input'];
};

export type MutationConfirmStripeTransactionArgs = {
  stripeTransactionId: Scalars['String']['input'];
};

export type MutationContactSupportForExtraChargeArgs = {
  stripeTransactionId: Scalars['String']['input'];
};

export type MutationCreateAddressArgs = {
  createAddressInput: CreateAddressInput;
};

export type MutationCreateAgentArgs = {
  createAgentInput: CreateAgentInput;
};

export type MutationCreateAppointmentRequestArgs = {
  circleRequestId?: InputMaybe<Scalars['Int']['input']>;
  serviceRequestId?: InputMaybe<Scalars['Int']['input']>;
  userIds: Array<Scalars['Int']['input']>;
};

export type MutationCreateAppointmentRequestPaymentArgs = {
  appointmentPaymentInput: CreateAppointmentPaymentInput;
};

export type MutationCreateCircleRequestArgs = {
  data: CreateCircleRequestInput;
};

export type MutationCreateCoachVisitArgs = {
  data: CreateCoachVisitInput;
};

export type MutationCreateCompanyArgs = {
  input: CreateCompanyInput;
};

export type MutationCreateContactRequestArgs = {
  createContactRequestInput: CreateContactRequestInput;
};

export type MutationCreateCustomerArgs = {
  createCustomerInput: CreateCustomerInput;
  role: AppUser;
};

export type MutationCreateCustomerWithAdditionalInformationArgs = {
  customer: CreateCustomerWithAdditionalInformationInput;
  role: AppUser;
};

export type MutationCreateCustomerWithAddressArgs = {
  address: RideAddressInput;
  customer: CreateCustomerInput;
  role: AppUser;
};

export type MutationCreateExpiringTokenArgs = {
  seconds: Scalars['Int']['input'];
};

export type MutationCreateFeatureFlagArgs = {
  createFeatureFlagInput: CreateFeatureFlagInput;
};

export type MutationCreateInternalNoteArgs = {
  input: CreateInternalNoteInput;
};

export type MutationCreateNotificationArgs = {
  notification: CreateNotificationInput;
  sendEmail: Scalars['Boolean']['input'];
  sendPush: Scalars['Boolean']['input'];
  sendSms: Scalars['Boolean']['input'];
  sendWebpush: Scalars['Boolean']['input'];
};

export type MutationCreateRequestCategoryArgs = {
  data: CreateRequestCategoryInput;
};

export type MutationCreateServiceRequestArgs = {
  data: CreateServiceRequestInput;
};

export type MutationCreateServiceRequestPaymentArgs = {
  servicePaymentInput: CreateServicePaymentInput;
};

export type MutationCreateServiceRequestSummaryArgs = {
  amount?: InputMaybe<Scalars['Float']['input']>;
  commentForBrya: Scalars['String']['input'];
  commentForCustomer: Scalars['String']['input'];
  hours?: InputMaybe<Scalars['Float']['input']>;
  notes?: InputMaybe<Scalars['String']['input']>;
  serviceRequestId: Scalars['Int']['input'];
  visitPaymentType?: InputMaybe<ServiceRequestPaymentSummaryVisitType>;
};

export type MutationCreateSocialEventArgs = {
  data: CreateSocialEventInput;
};

export type MutationCreateStripeSubscriptionArgs = {
  stripeSubscriptionPriceId: Scalars['String']['input'];
};

export type MutationCreateStripeSubscriptionPriceArgs = {
  createSubscriptionPriceInput: CreateStripeSubscriptionPrice;
};

export type MutationCreateSuggestedActivityArgs = {
  createSuggestedActivityInput: CreateSuggestedActivityInput;
};

export type MutationCreateTimelineEventArgs = {
  createTimelineInput: CreateTimelineInput;
};

export type MutationCreateUserArgs = {
  createUserInput: CreateUserInput;
  inviteToken?: InputMaybe<Scalars['String']['input']>;
};

export type MutationCreateUserCustomerRoleConnectionArgs = {
  customerId: Scalars['Int']['input'];
  role: CircleRoles;
  userId: Scalars['Int']['input'];
};

export type MutationCreateUserInvitesArgs = {
  userInvitesInput: CreateUserInviteInput;
};

export type MutationCreateUserRequestArgs = {
  userInvitesInput: CreateUserRequestInput;
};

export type MutationCreateVisitSummaryArgs = {
  input: CreateVisitSummaryInput;
};

export type MutationCreateWebPushSubscriptionArgs = {
  webPushSubscription: WebPushSubscription;
};

export type MutationDeleteAddressArgs = {
  id: Scalars['Int']['input'];
};

export type MutationDeleteAgentArgs = {
  id: Scalars['Int']['input'];
};

export type MutationDeleteCoachReviewArgs = {
  reviewId: Scalars['Int']['input'];
};

export type MutationDeleteConversationArgs = {
  conversationId: Scalars['String']['input'];
};

export type MutationDeleteInternalNoteArgs = {
  id: Scalars['Float']['input'];
};

export type MutationDeleteNotificationArgs = {
  notificationId: Scalars['Float']['input'];
};

export type MutationDeleteRequestCategoryArgs = {
  name: Scalars['String']['input'];
};

export type MutationDeleteSuggestedActivityArgs = {
  suggestedActivityId: Scalars['Int']['input'];
};

export type MutationDeleteUserInviteArgs = {
  userInviteId: Scalars['Int']['input'];
};

export type MutationDeleteUserRequestArgs = {
  userRequestId: Scalars['Int']['input'];
};

export type MutationDeleteVisitSummaryArgs = {
  id: Scalars['Float']['input'];
};

export type MutationDestroyWebPushSubscriptionArgs = {
  data: UnsubscribeInput;
};

export type MutationDisableUserArgs = {
  id: Scalars['Int']['input'];
};

export type MutationEnableUserArgs = {
  id: Scalars['Int']['input'];
};

export type MutationExchangeAuthIdArgs = {
  authId: Scalars['String']['input'];
  secureToken: Scalars['String']['input'];
};

export type MutationExchangeExpiringTokenArgs = {
  token: Scalars['String']['input'];
};

export type MutationFavoriteAgentArgs = {
  agentId: Scalars['Int']['input'];
  serviceType: ServiceRequestCategoriesEnum;
};

export type MutationFinishAuthIdArgs = {
  authId: Scalars['String']['input'];
};

export type MutationForgotPasswordArgs = {
  email: Scalars['String']['input'];
};

export type MutationJoinCircleArgs = {
  id: Scalars['Int']['input'];
  status: UserInvitesStatus;
};

export type MutationJoinConversationArgs = {
  conversationId: Scalars['String']['input'];
};

export type MutationJoinUserToConversationArgs = {
  conversationId: Scalars['String']['input'];
  userId: Scalars['Int']['input'];
};

export type MutationLoginArgs = {
  email: Scalars['String']['input'];
  inviteToken?: InputMaybe<Scalars['String']['input']>;
  password: Scalars['String']['input'];
};

export type MutationLogoutArgs = {
  deviceId: Scalars['String']['input'];
};

export type MutationNotifyMeArgs = {
  email: Scalars['String']['input'];
};

export type MutationReadAllUserNotificationsArgs = {
  userId?: InputMaybe<Scalars['Int']['input']>;
};

export type MutationReadNotificationsArgs = {
  notificationsIds: Array<Scalars['Int']['input']>;
};

export type MutationRemoveMembersFromCircleArgs = {
  customerId?: InputMaybe<Scalars['Int']['input']>;
  userIds: Array<Scalars['Int']['input']>;
};

export type MutationRemoveParticipantArgs = {
  conversationId: Scalars['String']['input'];
  talkJsId: Scalars['String']['input'];
};

export type MutationRemoveUserCustomerRoleConnectionArgs = {
  customerId: Scalars['Int']['input'];
  role: CircleRoles;
  userId: Scalars['Int']['input'];
};

export type MutationRequestToJoinCircleArgs = {
  requestToJoinCircleInput: RequestToJoinCircleInput;
};

export type MutationRequestToJoinFamilyGroupArgs = {
  requestToJoinFamilyGroupInput: RequestToJoinFamilyGroupInput;
};

export type MutationResendUserInviteArgs = {
  userInviteData: UserInviteResendInput;
};

export type MutationResendVerifyEmailArgs = {
  email: Scalars['String']['input'];
};

export type MutationResetPasswordArgs = {
  password: Scalars['String']['input'];
  resetPasswordToken: Scalars['String']['input'];
};

export type MutationReviewCoachArgs = {
  data: CreateCoachReviewInput;
};

export type MutationReviewServiceRequestArgs = {
  data: CreateServiceRequestReviewInput;
};

export type MutationSaveFirebaseTokenArgs = {
  firebaseToken: Scalars['String']['input'];
  uuid: Scalars['String']['input'];
};

export type MutationSaveUserNotificationSettingsArgs = {
  saveNotificationSettingsInput: SaveNotificationSettingsInput;
  userId?: InputMaybe<Scalars['Int']['input']>;
};

export type MutationSendRequestToNotExistingUserArgs = {
  requestToNotExistingUserInput: RequestToNotExistingUserInput;
};

export type MutationSetAppFeaturesSelectedArgs = {
  appFeaturesSelected: SelectAppFeaturesInput;
};

export type MutationSetUserSelectedCustomerArgs = {
  customerId?: InputMaybe<Scalars['Int']['input']>;
};

export type MutationStartConversationArgs = {
  startConversationInput: StartConversationInput;
};

export type MutationSwitchUserRoleArgs = {
  customerId: Scalars['Int']['input'];
  role: Role;
};

export type MutationSyncCustomerChatArgs = {
  customerId: Scalars['Float']['input'];
};

export type MutationTrustUntrustMembersArgs = {
  action: TrustUntrustActionType;
  trustedMembersIds: Array<Scalars['Int']['input']>;
};

export type MutationUnblockUserArgs = {
  id: Scalars['Int']['input'];
};

export type MutationUnfavoriteAgentArgs = {
  agentId: Scalars['Int']['input'];
  serviceType: ServiceRequestCategoriesEnum;
};

export type MutationUnreadAllUserNotificationsArgs = {
  userId?: InputMaybe<Scalars['Int']['input']>;
};

export type MutationUnreadNotificationsArgs = {
  notificationsIds: Array<Scalars['Int']['input']>;
};

export type MutationUpdateAddressArgs = {
  id: Scalars['Int']['input'];
  updateAddressInput: UpdateAddressInput;
};

export type MutationUpdateAgentArgs = {
  id: Scalars['Int']['input'];
  updateAgentInput: UpdateAgentInput;
};

export type MutationUpdateAppointmentRequestArgs = {
  updateAppointmentRequestInput: UpdateAppointmentRequestInput;
};

export type MutationUpdateAppointmentRequestPaymentArgs = {
  appointmentPaymentInput: UpdateAppointmentPaymentInput;
  appointmentRequestPaymentId: Scalars['Int']['input'];
};

export type MutationUpdateCircleRequestArgs = {
  data: UpdateCircleRequestInput;
  id: Scalars['Int']['input'];
};

export type MutationUpdateCircleRequestTimeArgs = {
  circleRequestId: Scalars['Int']['input'];
  newTime: Scalars['DateTime']['input'];
  untilTime?: InputMaybe<Scalars['DateTime']['input']>;
};

export type MutationUpdateCoachProfileArgs = {
  coachInfo: CoachInfoInput;
};

export type MutationUpdateCoachVisitArgs = {
  coachVisitId: Scalars['Int']['input'];
  data: UpdateCoachVisitInput;
};

export type MutationUpdateCoachVisitStatusArgs = {
  action: SocialEventStatus;
  cancellationReason?: InputMaybe<Scalars['String']['input']>;
  coachVisitId: Scalars['Int']['input'];
};

export type MutationUpdateCompanyArgs = {
  data: UpdateCompanyInput;
  id: Scalars['Float']['input'];
};

export type MutationUpdateContactRequestArgs = {
  contactRequestId: Scalars['Int']['input'];
  updateContactRequestInput: UpdateContactRequestInput;
};

export type MutationUpdateCurrentUserArgs = {
  updateUserInput: UpdateCurrentUserInput;
};

export type MutationUpdateCustomerArgs = {
  customerId: Scalars['Int']['input'];
  updateCustomerInput: UpdateCustomerInput;
};

export type MutationUpdateInternalNoteArgs = {
  id: Scalars['Float']['input'];
  input: CreateInternalNoteInput;
};

export type MutationUpdateRequestCategoryArgs = {
  data: UpdateRequestCategoryInput;
};

export type MutationUpdateServiceRequestArgs = {
  data: UpdateServiceRequestInput;
  id: Scalars['Int']['input'];
};

export type MutationUpdateServiceRequestPaymentArgs = {
  servicePaymentInput: UpdateServicePaymentInput;
  serviceRequestPaymentId: Scalars['Int']['input'];
};

export type MutationUpdateServiceRequestTimeArgs = {
  newTime: Scalars['DateTime']['input'];
  serviceRequestId: Scalars['Int']['input'];
  untilTime?: InputMaybe<Scalars['DateTime']['input']>;
};

export type MutationUpdateSocialEventArgs = {
  data: UpdateSocialEventInput;
  socialEventId: Scalars['Int']['input'];
};

export type MutationUpdateSocialEventStatusArgs = {
  action: SocialEventStatus;
  cancellationReason?: InputMaybe<Scalars['String']['input']>;
  socialEventId: Scalars['Int']['input'];
};

export type MutationUpdateStripeCustomerDefaultCardArgs = {
  paymentMethodId: Scalars['String']['input'];
};

export type MutationUpdateSuggestedActivityArgs = {
  suggestedActivityId: Scalars['Int']['input'];
  updateSuggestedActivityInput: UpdateSuggestedActivityInput;
};

export type MutationUpdateVisitSummaryArgs = {
  body: Scalars['String']['input'];
  id: Scalars['Float']['input'];
};

export type MutationUserApprovalServiceRequestArgs = {
  action: ApprovalServiceRequestStatus;
  rejectedReason?: InputMaybe<Scalars['String']['input']>;
  serviceRequestId: Scalars['Int']['input'];
};

export type MutationUserApprovalSocialEventArgs = {
  action: ApprovalServiceRequestStatus;
  customerId: Scalars['Int']['input'];
  socialEventId: Scalars['Int']['input'];
};

export type MutationUserCancelCircleRequestArgs = {
  cancellationReason?: InputMaybe<Scalars['String']['input']>;
  circleRequestId: Scalars['Int']['input'];
};

export type MutationUserCancelServiceRequestArgs = {
  cancellationReason: Scalars['String']['input'];
  serviceRequestId: Scalars['Int']['input'];
};

export type MutationVerifyEmailArgs = {
  token: Scalars['String']['input'];
};

/** All notifications categories in the application */
export enum NotificationCategories {
  AppointmentCirclesPrior30MinReminder = 'appointmentCirclesPrior30MinReminder',
  AppointmentServicesPrior30MinReminder = 'appointmentServicesPrior30MinReminder',
  CircleMember = 'circleMember',
  CircleMemberInvite = 'circleMemberInvite',
  CircleMemberInviteAccepted = 'circleMemberInviteAccepted',
  CircleMemberRequest = 'circleMemberRequest',
  CircleRequest = 'circleRequest',
  CircleRequestAccepted = 'circleRequestAccepted',
  CircleRequestAllMembersDeclined = 'circleRequestAllMembersDeclined',
  CircleRequestCancelled = 'circleRequestCancelled',
  CircleRequestConfirmationNeededReminder = 'circleRequestConfirmationNeededReminder',
  CircleRequestMemberUnavailable = 'circleRequestMemberUnavailable',
  CircleRequestNew = 'circleRequestNew',
  CircleRequestTimeChanged = 'circleRequestTimeChanged',
  CoachVisit = 'coachVisit',
  CoachVisitCancelled = 'coachVisitCancelled',
  CoachVisitNoteCreated = 'coachVisitNoteCreated',
  CoachVisitScheduled = 'coachVisitScheduled',
  CoachVisitUpdated = 'coachVisitUpdated',
  FamilyPartnerInvite = 'familyPartnerInvite',
  FamilyPartnerInviteAccepted = 'familyPartnerInviteAccepted',
  FamilyPartnerRequest = 'familyPartnerRequest',
  General = 'general',
  PostVisitSummaryProReminder = 'postVisitSummaryProReminder',
  ServiceRequest = 'serviceRequest',
  ServiceRequestAuthorizationFailed = 'serviceRequestAuthorizationFailed',
  ServiceRequestCancelled = 'serviceRequestCancelled',
  ServiceRequestCancelledByPro = 'serviceRequestCancelledByPro',
  ServiceRequestCancelledPaymentFailed = 'serviceRequestCancelledPaymentFailed',
  ServiceRequestConfirmationNeeded = 'serviceRequestConfirmationNeeded',
  ServiceRequestConfirmationNeededReminder = 'serviceRequestConfirmationNeededReminder',
  ServiceRequestConfirmedBySm = 'serviceRequestConfirmedBySM',
  ServiceRequestExtraCharge = 'serviceRequestExtraCharge',
  ServiceRequestFinished = 'serviceRequestFinished',
  ServiceRequestNewPro = 'serviceRequestNewPro',
  ServiceRequestNewTimeRequested = 'serviceRequestNewTimeRequested',
  ServiceRequestPaymentFailed = 'serviceRequestPaymentFailed',
  ServiceRequestPostVisitSummaryCreated = 'serviceRequestPostVisitSummaryCreated',
  SocialEvent = 'socialEvent',
  SocialEventCancelled = 'socialEventCancelled',
  SocialEventScheduled = 'socialEventScheduled',
  SocialEventUpdated = 'socialEventUpdated',
  StripeConnectAccountRequirements = 'stripeConnectAccountRequirements',
  TalkjsNewMessage = 'talkjsNewMessage',
}

export type NotificationOperationOutput = {
  __typename?: 'NotificationOperationOutput';
  /** Whether if the operation was success or not */
  success: Scalars['Boolean']['output'];
};

/** All notifications types in the application */
export enum NotificationTypes {
  AppointmentsCirclesPrior_30MinReminder = 'APPOINTMENTS_CIRCLES_PRIOR_30_MIN_REMINDER',
  AppointmentsServicesPrior_30MinReminder = 'APPOINTMENTS_SERVICES_PRIOR_30_MIN_REMINDER',
  CircleMember = 'CIRCLE_MEMBER',
  CircleMemberInvite = 'CIRCLE_MEMBER_INVITE',
  CircleMemberInviteAccepted = 'CIRCLE_MEMBER_INVITE_ACCEPTED',
  CircleMemberRequest = 'CIRCLE_MEMBER_REQUEST',
  CircleRequest = 'CIRCLE_REQUEST',
  CircleRequestAccepted = 'CIRCLE_REQUEST_ACCEPTED',
  CircleRequestAllMembersDeclined = 'CIRCLE_REQUEST_ALL_MEMBERS_DECLINED',
  CircleRequestCancelled = 'CIRCLE_REQUEST_CANCELLED',
  CircleRequestCmChangesTime = 'CIRCLE_REQUEST_CM_CHANGES_TIME',
  CircleRequestConfirmationNeededReminder = 'CIRCLE_REQUEST_CONFIRMATION_NEEDED_REMINDER',
  CircleRequestMemberUnavailable = 'CIRCLE_REQUEST_MEMBER_UNAVAILABLE',
  CircleRequestNew = 'CIRCLE_REQUEST_NEW',
  CoachVisit = 'COACH_VISIT',
  CoachVisitCancelled = 'COACH_VISIT_CANCELLED',
  CoachVisitNoteCreated = 'COACH_VISIT_NOTE_CREATED',
  CoachVisitScheduled = 'COACH_VISIT_SCHEDULED',
  CoachVisitUpdated = 'COACH_VISIT_UPDATED',
  FamilyPartnerInvite = 'FAMILY_PARTNER_INVITE',
  FamilyPartnerInviteAccepted = 'FAMILY_PARTNER_INVITE_ACCEPTED',
  FamilyPartnerRequest = 'FAMILY_PARTNER_REQUEST',
  General = 'GENERAL',
  PvsProReminder = 'PVS_PRO_REMINDER',
  ServiceRequest = 'SERVICE_REQUEST',
  ServiceRequestAuthorizationFailed = 'SERVICE_REQUEST_AUTHORIZATION_FAILED',
  ServiceRequestCancelled = 'SERVICE_REQUEST_CANCELLED',
  ServiceRequestCancelledByPro = 'SERVICE_REQUEST_CANCELLED_BY_PRO',
  ServiceRequestCancelledPaymentFailed = 'SERVICE_REQUEST_CANCELLED_PAYMENT_FAILED',
  ServiceRequestConfirmationNeeded = 'SERVICE_REQUEST_CONFIRMATION_NEEDED',
  ServiceRequestConfirmationNeededReminder = 'SERVICE_REQUEST_CONFIRMATION_NEEDED_REMINDER',
  ServiceRequestConfirmedBySm = 'SERVICE_REQUEST_CONFIRMED_BY_SM',
  ServiceRequestExtraCharge = 'SERVICE_REQUEST_EXTRA_CHARGE',
  ServiceRequestFinished = 'SERVICE_REQUEST_FINISHED',
  ServiceRequestNewPro = 'SERVICE_REQUEST_NEW_PRO',
  ServiceRequestNewTimeRequested = 'SERVICE_REQUEST_NEW_TIME_REQUESTED',
  ServiceRequestPaymentFailed = 'SERVICE_REQUEST_PAYMENT_FAILED',
  ServiceRequestPostVisitSummaryCreated = 'SERVICE_REQUEST_POST_VISIT_SUMMARY_CREATED',
  SocialEvent = 'SOCIAL_EVENT',
  SocialEventCancelled = 'SOCIAL_EVENT_CANCELLED',
  SocialEventScheduled = 'SOCIAL_EVENT_SCHEDULED',
  SocialEventUpdated = 'SOCIAL_EVENT_UPDATED',
  StripeConnectAccountRequirements = 'STRIPE_CONNECT_ACCOUNT_REQUIREMENTS',
  TalkjsNewMessage = 'TALKJS_NEW_MESSAGE',
}

export type NotificationTypesEnums = {
  /** All notifications types in the application */
  notificationCategories: NotificationCategories;
  /** All notifications types in the application */
  notificationTypes: NotificationTypes;
};

export type NotificationsFilter = {
  /** Filter by notification type */
  types?: InputMaybe<Array<Scalars['String']['input']>>;
  /** Filter by unread field */
  unread?: InputMaybe<Scalars['Boolean']['input']>;
};

export type NotifyMeOutput = {
  __typename?: 'NotifyMeOutput';
  success: Scalars['Boolean']['output'];
};

export type PaginationInput = {
  /** The number of elements per page */
  limit: Scalars['Float']['input'];
  /** The page that should be returned */
  page: Scalars['Float']['input'];
};

export type PaginationMeta = {
  __typename?: 'PaginationMeta';
  /** The page that should be returned */
  currentPage: Scalars['Float']['output'];
  /** Whether the returned page is the first page or not */
  isFirstPage: Scalars['Boolean']['output'];
  /** Whether the returned page is the last page or not */
  isLastPage: Scalars['Boolean']['output'];
  /** The number of the next page */
  nextPage?: Maybe<Scalars['Float']['output']>;
  /** The total number of pages */
  pageCount: Scalars['Float']['output'];
  /** The number of the previous page */
  previousPage?: Maybe<Scalars['Float']['output']>;
  /** The total number of elements */
  totalCount: Scalars['Float']['output'];
};

export type Participant = {
  __typename?: 'Participant';
  access: Scalars['String']['output'];
  id: Scalars['String']['output'];
  notify: Scalars['Boolean']['output'];
};

/** The positive tags of the review */
export enum PositiveTags {
  Cleanliness = 'cleanliness',
  CustomerService = 'customer_service',
  OverallSatisfaction = 'overall_satisfaction',
  Professionalism = 'professionalism',
  Punctuality = 'punctuality',
  QualityOfWork = 'quality_of_work',
}

export type Query = {
  __typename?: 'Query';
  /** Continue the stripe connect account onboarding flow */
  continueConnectAccountOnboarding: Scalars['String']['output'];
  /** Get Circle, Service Requests and Social Events with sorting and filtering for specific customers */
  getActivities: GetActivitiesOutput;
  getActivitiesAlerts: Array<GetActivitiesAlertsOutput>;
  /** Get Circle, Service Requests and Social Events with sorting and filtering for specific customers grouped by date */
  getActivitiesGroupedByDate: GetActivitiesGroupedByDateOutput;
  /** Gets address by ID */
  getAddress: GraphqlAddresses;
  /** The addresses information of a specific customer */
  getAddresses: Array<GraphqlAddresses>;
  /** The information of a specific agent */
  getAgent: GraphqlAgent;
  /** Get service activities with sorting and filtering for a specific agent */
  getAgentActivities: GetAppointmentRequestsActivitiesOutput;
  /** Get a service activity with appointment information */
  getAgentActivityByAppointmentId: GraphqlAppointmentRequest;
  getAgentReviews: GetAgentReviewsOutput;
  /** Retrieves paginated agents */
  getAgents: GetAgentsOutput;
  /** Retrieves agents by trusted members and high reviews and ratings  */
  getAgentsByServiceRequest: GetAgentsByServiceRequestOutput;
  /** Returns all the feature flags */
  getAllFeatureFlags: Array<GraphqlFeatureFlag>;
  getAppointmentRequests: Array<GraphqlAppointmentRequest>;
  /** Get appointment requests with pagination, filtering and sorting */
  getAppointmentRequestsFiltered: GetAppointmentRequestsOutput;
  /** Gets list of users that were blocked by logged user */
  getBlockedUsersByBlockerUserId: Array<GraphqlBlockedUsers>;
  /** Gets list of users that blocked logged user */
  getBlockerUsersByBlockedUserId: Array<GraphqlBlockedUsers>;
  /** Returns a chat conversation between the logged user and coach */
  getBryaPartnerChatId: Conversation;
  /** Returns a chat conversation between the logged user and all support users */
  getBryaSupportChatId: Conversation;
  /** Returns a chat conversation between the logged user and all support and coach users */
  getBryaTeamChatId: Conversation;
  /** Get circle activities with sorting and filtering for a specific circle member */
  getCircleMemberActivities: GetAppointmentRequestsActivitiesOutput;
  /** Get a circle activity with circle member information */
  getCircleMemberActivityByAppointmentId: GraphqlAppointmentRequest;
  /** Get a single circle request */
  getCircleRequest: GraphqlCircleRequest;
  /** Gets requests with pagination, filtering and sorting */
  getCircleRequests: GetCircleRequestsOutput;
  /** The profile of a coach for the authorized user */
  getCoachProfile?: Maybe<GetCoachProfileOutput>;
  getCoachReviews: GetCoachReviewsOutput;
  /** Get a single coach visit */
  getCoachVisit: GraphqlCoachVisit;
  /** Get coach visits with pagination, filtering and sorting */
  getCoachVisits: GetCoachVisitsOutput;
  /** Retrieve paginated companies */
  getCompanies: GetCompaniesOutput;
  /** Retrieve company by id */
  getCompany: GraphqlCompany;
  /** Returns a chat conversation by its ID */
  getConversation: Conversation;
  /** The talkJS signature for the specified conversation */
  getConversationSignature: GetConversationSignatureOutput;
  /** Returns paginated chat conversations */
  getConversations: GetConversationsOutput;
  /** Returns the chat conversations for a specific customer */
  getConversationsByCustomer: Array<Conversation>;
  /** Returns the chat conversations for a specific user */
  getConversationsByUserId: Array<Conversation>;
  /** The information of the logged in user */
  getCurrentUser: GraphqlUser;
  /** The information of a specific customer */
  getCustomer: GetCustomerOutput;
  /** Retrieves an agent for a customer */
  getCustomerAgent: GraphqlCustomerAgent;
  /** Return whether a customer has a subscription plan or not */
  getCustomerHasStripeSubscription?: Maybe<Scalars['Boolean']['output']>;
  getCustomerInternalNotes: GetInternalNotesOutput;
  /** The list of customers in the application */
  getCustomers: Array<GetCustomerOutput>;
  /** Get the default timezone in the app */
  getDefaultTimezone: GetDefaultTimezone;
  getFiles: GetFilesOutput;
  /** Gets list of flagged messages */
  getFlaggedMessages: Array<GraphqlFlaggedMessages>;
  /** The FrontApp channels with the logged in user hash */
  getFrontAppChannels: GetFrontAppChannels;
  /** get google places api key */
  getGooglePlacesApiKey: GetGooglePlacesApiKeyOutput;
  getInternalNotes: GetInternalNotesOutput;
  /** Get a single social event for mobile application users */
  getMobileSocialEvent: GraphqlCustomerSocialEvent;
  /** All notifications related to a user. */
  getNotificationsByUserId: GetNotificationsByUserIdOutput;
  /** All timeline entries for a user or a customer */
  getRecentActivity: GetRecentActivityOuput;
  /** Get a single service request */
  getServiceRequest: GraphqlServiceRequest;
  /** Retrieves all events related to the service request that the user is allowed to see */
  getServiceRequestHistory: Array<GraphqlTimeline>;
  getServiceRequestReview?: Maybe<GraphqlServiceRequestReview>;
  /** Get service request with pagination, filtering and sorting */
  getServiceRequests: GetServiceRequestsOutput;
  /** The talkJS signature of the logged user to use the chat in the application */
  getSignature: GetSignatureOutput;
  /** Get a single social event */
  getSocialEvent: GraphqlSocialEvent;
  /** Get social events with pagination, filtering and sorting */
  getSocialEvents: GetSocialEventsOutput;
  /** Get the Stripe Connect Account information for a company */
  getStripeConnectAccount?: Maybe<GraphqlStripeConnectAccount>;
  /** Get the Stripe Customer Account information for an user */
  getStripeCustomerAccount?: Maybe<GraphqlStripeCustomerAccount>;
  /** Triggers the flow to allow a customer to add a new credit card in their stripe account */
  getStripeCustomerCreditCardSecret: Scalars['String']['output'];
  /** Returns if user has at least one payment method saved or not */
  getStripeCustomerHasSavedPaymentMethod: Scalars['Boolean']['output'];
  /** Returns a list of saved payment methods for a user */
  getStripeCustomerPaymentMethod: StripeCardPaymentMethod;
  /** Returns a list of saved payment methods for the logged-in user */
  getStripeCustomerPaymentMethods: Array<StripeCardPaymentMethod>;
  /** Returns a list of saved payment methods for a specified user */
  getStripePaymentMethodsByUser: Array<StripeCardPaymentMethod>;
  /** Get a specific Stripe subscription for a customer user */
  getStripeSubscription?: Maybe<GraphqlStripeSubscription>;
  /** Get a specific Stripe subscription product from brya */
  getStripeSubscriptionPrice?: Maybe<GraphqlStripeSubscriptionPrice>;
  /** Get the Stripe subscription products from brya */
  getStripeSubscriptionPrices?: Maybe<Array<GraphqlStripeSubscriptionPrice>>;
  /** Get subscriptions information of users */
  getStripeSubscriptions?: Maybe<Array<GraphqlStripeSubscription>>;
  /** Get a specific Stripe transaction */
  getStripeTransaction: GraphqlStripeTransaction;
  /** Get list of transactions */
  getStripeTransactions: GetStripeTransactionOutput;
  /** Get a list of suggested activities */
  getSuggestedActivities: SuggestedActivitiesOutput;
  /** Gets suggested activity by ID */
  getSuggestedActivity: GraphqlSuggestedActivities;
  /** All timeline entries for a user or a customer */
  getTimeline: Array<GraphqlTimeline>;
  /** The information of a specific user */
  getUser: GraphqlUser;
  /** The information of a specific user, searching by chat provider id */
  getUserByChatProviderId: GraphqlUser;
  getUserCoachReviews: GetCoachReviewsOutput;
  /** List of user invites that a user sent */
  getUserInviteById: GraphqlUserInvite;
  /** List of user invites that a user sent */
  getUserInvitesByCustomer: Array<GraphqlUserInvite>;
  /** List of user invites by user id */
  getUserInvitesByUserId: Array<GraphqlUserInvite>;
  /** Get notifications settings for the logged in user */
  getUserNotificationSettings: GraphqlNotificationSettings;
  /** Get notifications settings for a specific user */
  getUserNotificationSettingsByUserId: GraphqlNotificationSettings;
  getUserParticipants: GetUserParticipantsOutput;
  /** The list of customers in the application */
  getUserRelatedCustomers: Array<GraphqlCustomer>;
  /** Return a user request */
  getUserRequestById: GraphqlUserRequest;
  /** List of user requests by an specific customer */
  getUserRequestsByCustomer: Array<GraphqlUserRequest>;
  /** List of user requests by requested by */
  getUserRequestsByRequestedBy: Array<GraphqlUserRequest>;
  /** Get the talkJS signature for an arbitrary user */
  getUserSignature: GetSignatureOutput;
  /** The list of users in the application */
  getUsers: GetUsersOutput;
  /** The list of users related to a customer */
  getUsersByCustomerId: Array<GraphqlUser>;
  /** The list of full names of users */
  getUsersFullNames: Array<UserWithFullName>;
  getVisitSummaries: GetVisitSummariesOutput;
  getVisitSummariesByCoachId: GetVisitSummaryByCoachVisitIdOutput;
  /** Return zip code business rules */
  getZipCodeMatchesBusinessRules: GetZipCodeMatchesBusinessRules;
  /** Returns the completion of the circle request from OpenAI */
  interpretCircleRequest: CircleRequestInterpretResponse;
  /** Returns the completion of the service request from OpenAI */
  interpretServiceRequest: ServiceRequestInterpretResponse;
  listRequestCategories: Array<GraphqlRequestCategory>;
};

export type QueryGetActivitiesArgs = {
  filter: ActivitiesFilter;
  page?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
};

export type QueryGetActivitiesGroupedByDateArgs = {
  filter: ActivitiesGroupedByDateFilter;
  lastValue?: InputMaybe<Scalars['DateTime']['input']>;
};

export type QueryGetAddressArgs = {
  addressId: Scalars['Float']['input'];
};

export type QueryGetAddressesArgs = {
  customerId: Scalars['Float']['input'];
};

export type QueryGetAgentArgs = {
  id: Scalars['Int']['input'];
};

export type QueryGetAgentActivitiesArgs = {
  filter: AppointmentActivitiesFilter;
  page?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
};

export type QueryGetAgentActivityByAppointmentIdArgs = {
  appointmentRequestId: Scalars['Int']['input'];
};

export type QueryGetAgentReviewsArgs = {
  agentId: Scalars['Int']['input'];
  category?: InputMaybe<ServiceRequestCategoriesEnum>;
  page?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<ReviewSortInput>;
};

export type QueryGetAgentsArgs = {
  filter?: InputMaybe<AgentsFilter>;
  page?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<AgentsSort>;
};

export type QueryGetAgentsByServiceRequestArgs = {
  serviceRequestId: Scalars['Int']['input'];
};

export type QueryGetAppointmentRequestsArgs = {
  circleRequestId?: InputMaybe<Scalars['Int']['input']>;
  serviceRequestId?: InputMaybe<Scalars['Int']['input']>;
};

export type QueryGetAppointmentRequestsFilteredArgs = {
  filter: AppointmentRequestFilter;
  page?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<AppointmentRequestSortInput>;
};

export type QueryGetCircleMemberActivitiesArgs = {
  filter: AppointmentActivitiesFilter;
  page?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
};

export type QueryGetCircleMemberActivityByAppointmentIdArgs = {
  appointmentRequestId: Scalars['Int']['input'];
};

export type QueryGetCircleRequestArgs = {
  id: Scalars['Float']['input'];
};

export type QueryGetCircleRequestsArgs = {
  filter: CircleRequestFilter;
  page?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<CircleRequestSortInput>;
};

export type QueryGetCoachReviewsArgs = {
  coachId: Scalars['Int']['input'];
  page?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<ReviewSortInput>;
};

export type QueryGetCoachVisitArgs = {
  coachVisitId: Scalars['Int']['input'];
};

export type QueryGetCoachVisitsArgs = {
  filter: CoachVisitFilter;
  page?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<CoachVisitSortInput>;
};

export type QueryGetCompaniesArgs = {
  filter?: InputMaybe<CompanyFilter>;
  page?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<CompanySortInput>;
};

export type QueryGetCompanyArgs = {
  id: Scalars['Float']['input'];
};

export type QueryGetConversationArgs = {
  conversationId: Scalars['String']['input'];
};

export type QueryGetConversationSignatureArgs = {
  conversationId: Scalars['String']['input'];
};

export type QueryGetConversationsArgs = {
  filter?: InputMaybe<GetConversationsFilter>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<GetConversationsSort>;
  startingAfter?: InputMaybe<Scalars['String']['input']>;
};

export type QueryGetConversationsByCustomerArgs = {
  customerId: Scalars['Int']['input'];
};

export type QueryGetConversationsByUserIdArgs = {
  userId: Scalars['Float']['input'];
};

export type QueryGetCustomerArgs = {
  customerId: Scalars['Float']['input'];
};

export type QueryGetCustomerAgentArgs = {
  agentId: Scalars['Int']['input'];
};

export type QueryGetCustomerInternalNotesArgs = {
  customerId: Scalars['Int']['input'];
  pagination: PaginationInput;
};

export type QueryGetCustomersArgs = {
  filters: FiltersInput;
};

export type QueryGetFilesArgs = {
  filter?: InputMaybe<FilesFilter>;
  page?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<FilesSort>;
};

export type QueryGetInternalNotesArgs = {
  filter?: InputMaybe<InternalNotesFilterOptions>;
  pagination: PaginationInput;
  sort?: InputMaybe<InternalNotesSortOptions>;
};

export type QueryGetMobileSocialEventArgs = {
  customerId: Scalars['Int']['input'];
  socialEventId: Scalars['Int']['input'];
};

export type QueryGetNotificationsByUserIdArgs = {
  filter: NotificationsFilter;
  page?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  userId?: InputMaybe<Scalars['Int']['input']>;
};

export type QueryGetRecentActivityArgs = {
  filters: RecentActivityFilter;
};

export type QueryGetServiceRequestArgs = {
  id: Scalars['Float']['input'];
};

export type QueryGetServiceRequestHistoryArgs = {
  serviceRequestId: Scalars['Int']['input'];
};

export type QueryGetServiceRequestReviewArgs = {
  serviceRequestId: Scalars['Int']['input'];
};

export type QueryGetServiceRequestsArgs = {
  filter: ServiceRequestFilter;
  page?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<ServiceRequestSortInput>;
};

export type QueryGetSocialEventArgs = {
  socialEventId: Scalars['Int']['input'];
};

export type QueryGetSocialEventsArgs = {
  filter: SocialEventFilter;
  page?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<SocialEventSortInput>;
};

export type QueryGetStripeCustomerPaymentMethodArgs = {
  paymentMethodId: Scalars['String']['input'];
};

export type QueryGetStripePaymentMethodsByUserArgs = {
  userId: Scalars['Int']['input'];
};

export type QueryGetStripeSubscriptionPriceArgs = {
  stripeSubscriptionPriceId: Scalars['String']['input'];
};

export type QueryGetStripeTransactionArgs = {
  stripeTransactionId: Scalars['String']['input'];
};

export type QueryGetStripeTransactionsArgs = {
  filter: StripeTransactionFilter;
  page?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  sortBy?: InputMaybe<StripeTransactionFilterSortInput>;
};

export type QueryGetSuggestedActivitiesArgs = {
  filter?: InputMaybe<SuggestedActivitiesFilter>;
  page?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<SuggestedActivitiesSortInput>;
};

export type QueryGetSuggestedActivityArgs = {
  suggestedActivityId: Scalars['Float']['input'];
};

export type QueryGetTimelineArgs = {
  activityId?: InputMaybe<Scalars['Int']['input']>;
  customerId?: InputMaybe<Scalars['Int']['input']>;
  orderIn: SortOrder;
  userId?: InputMaybe<Scalars['Int']['input']>;
};

export type QueryGetUserArgs = {
  id: Scalars['Int']['input'];
};

export type QueryGetUserByChatProviderIdArgs = {
  chatProviderId: Scalars['String']['input'];
};

export type QueryGetUserCoachReviewsArgs = {
  page?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<ReviewSortInput>;
};

export type QueryGetUserInviteByIdArgs = {
  userInviteId: Scalars['Int']['input'];
};

export type QueryGetUserInvitesByCustomerArgs = {
  customerId: Scalars['Int']['input'];
};

export type QueryGetUserInvitesByUserIdArgs = {
  status?: InputMaybe<Array<UserInvitesStatus>>;
  type?: InputMaybe<Array<UserInvitesType>>;
  userId: Scalars['Int']['input'];
};

export type QueryGetUserNotificationSettingsArgs = {
  notificationType: Scalars['String']['input'];
};

export type QueryGetUserNotificationSettingsByUserIdArgs = {
  notificationType: Scalars['String']['input'];
  userId: Scalars['Int']['input'];
};

export type QueryGetUserParticipantsArgs = {
  conversationId?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<GetUserParticipantsFilter>;
};

export type QueryGetUserRelatedCustomersArgs = {
  roles?: InputMaybe<Array<AppUser>>;
  userId?: InputMaybe<Scalars['Int']['input']>;
};

export type QueryGetUserRequestByIdArgs = {
  id: Scalars['Int']['input'];
};

export type QueryGetUserRequestsByCustomerArgs = {
  customerId: Scalars['Int']['input'];
};

export type QueryGetUserRequestsByRequestedByArgs = {
  requestedBy: Scalars['Int']['input'];
  status?: InputMaybe<Array<UserInvitesStatus>>;
  type?: InputMaybe<Array<UserInvitesType>>;
};

export type QueryGetUserSignatureArgs = {
  userId: Scalars['Int']['input'];
};

export type QueryGetUsersArgs = {
  filter?: InputMaybe<UserFilter>;
  page?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<UserSortInput>;
};

export type QueryGetUsersByCustomerIdArgs = {
  customerId: Scalars['Int']['input'];
};

export type QueryGetUsersFullNamesArgs = {
  userIds?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
};

export type QueryGetVisitSummariesArgs = {
  filter?: InputMaybe<VisitSummariesFilterOptions>;
  pagination: PaginationInput;
  sort?: InputMaybe<VisitSummariesSortOptions>;
};

export type QueryGetVisitSummariesByCoachIdArgs = {
  coachVisitId: Scalars['Float']['input'];
};

export type QueryGetZipCodeMatchesBusinessRulesArgs = {
  zipCode: Scalars['String']['input'];
};

export type QueryInterpretCircleRequestArgs = {
  text: Scalars['String']['input'];
};

export type QueryInterpretServiceRequestArgs = {
  text: Scalars['String']['input'];
};

export type QueryListRequestCategoriesArgs = {
  circleRequest?: InputMaybe<Scalars['Boolean']['input']>;
  serviceRequest?: InputMaybe<Scalars['Boolean']['input']>;
};

export type RecentActivityFilter = {
  createdAtFrom?: InputMaybe<Scalars['DateTime']['input']>;
  createdAtUntil?: InputMaybe<Scalars['DateTime']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
};

/** The different types for a recurring event */
export enum RecurringFrequencyType {
  BiWeekly = 'biWeekly',
  Monthly = 'monthly',
  Weekly = 'weekly',
}

export type RequestToJoinCircleInput = {
  /** The customer email */
  email: Scalars['String']['input'];
  /** The customer phone number */
  phoneNumber: Scalars['String']['input'];
};

export type RequestToJoinCircleOutput = {
  __typename?: 'RequestToJoinCircleOutput';
  /** Wheter if there is a match or not with the customer info provided */
  match: Scalars['Boolean']['output'];
};

export type RequestToJoinFamilyGroupInput = {
  /** The customer email */
  email: Scalars['String']['input'];
  /** The customer phone number */
  phoneNumber: Scalars['String']['input'];
  /** The relationship between the customer and the request receiver */
  relationship?: InputMaybe<Scalars['String']['input']>;
};

export type RequestToJoinFamilyGroupOutput = {
  __typename?: 'RequestToJoinFamilyGroupOutput';
  /** Wheter if there is a match or not with the customer info provided */
  match: Scalars['Boolean']['output'];
};

export type RequestToNotExistingUserInput = {
  /** The not existing user email */
  email: Scalars['String']['input'];
  /** The not existing user first name */
  firstName: Scalars['String']['input'];
  /** The not existing user last name */
  lastName: Scalars['String']['input'];
  /** The not existing user phone number */
  phoneNumber: Scalars['String']['input'];
  /** The type of the request */
  requestType: UserInvitesType;
};

export type ResendVerifyEmailOutput = {
  __typename?: 'ResendVerifyEmailOutput';
  success: Scalars['Boolean']['output'];
};

export type ResetPasswordOutput = {
  __typename?: 'ResetPasswordOutput';
  success: Scalars['Boolean']['output'];
};

export type ResponseUserRequestOutput = {
  __typename?: 'ResponseUserRequestOutput';
  success: Scalars['Boolean']['output'];
};

export type ReviewSortInput = {
  direction: SortOrder;
  field: ReviewSortableFields;
};

/** The possible fields that the reviews can be sorted by */
export enum ReviewSortableFields {
  CreatedAt = 'createdAt',
  Id = 'id',
  Rating = 'rating',
  UpdatedAt = 'updatedAt',
}

export type Ride = {
  __typename?: 'Ride';
  /** The addresses for the ride */
  dropoffAddress: GraphqlRideAddress;
  /** The addresses for the ride */
  pickupAddress: GraphqlRideAddress;
  /** The addresses for the ride */
  stops?: Maybe<Array<GraphqlRideAddress>>;
  /** The type of the ride */
  type: RideType;
  /** The wait time information provided by the user */
  waitTime?: Maybe<Scalars['String']['output']>;
};

/** The fields to add an address to a Ride Request */
export type RideAddressInput = {
  /** First address line */
  addressLine1: Scalars['String']['input'];
  /** The additional address information */
  addressLine2?: InputMaybe<Scalars['String']['input']>;
  /** The address city */
  city: Scalars['String']['input'];
  /** The address country code */
  country: Scalars['String']['input'];
  /** Whether the address is the default one */
  isDefault?: InputMaybe<Scalars['Boolean']['input']>;
  /** Whether the address was created using the Google Maps API */
  isFromAPI?: InputMaybe<Scalars['Boolean']['input']>;
  /** Alias for the address */
  place?: InputMaybe<Scalars['String']['input']>;
  /** The address state */
  state: Scalars['String']['input'];
  /** The address zip code */
  zipCode: Scalars['String']['input'];
};

export type RideInput = {
  /** The addresses for the ride */
  dropoffAddress: RideAddressInput;
  /** The addresses for the ride */
  pickupAddress: RideAddressInput;
  /** The addresses for the ride */
  stops?: InputMaybe<Array<RideAddressInput>>;
  /** The type of the ride */
  type: RideType;
  /** The wait time information provided by the user */
  waitTime?: InputMaybe<Scalars['String']['input']>;
};

/** The different types of rides for a service request of this kind */
export enum RideType {
  OneWay = 'oneWay',
  RoundTrip = 'roundTrip',
}

/** The roles related to application users and customers */
export enum Role {
  CircleMember = 'circleMember',
  Coach = 'coach',
  FamilyPartner = 'familyPartner',
  SeniorMember = 'seniorMember',
  Support = 'support',
}

/** The available fileds to create a notification settings entry for a user */
export type SaveNotificationSettingsInput = {
  /** whether send the notification via email or not */
  email?: InputMaybe<Scalars['Boolean']['input']>;
  notificationType: Scalars['String']['input'];
  /** whether send the notification via push or not */
  push?: InputMaybe<Scalars['Boolean']['input']>;
  /** whether send the notification via pushWeb or not */
  pushWeb?: InputMaybe<Scalars['Boolean']['input']>;
  /** whether send the notification via sms or not */
  sms?: InputMaybe<Scalars['Boolean']['input']>;
};

/** The app features selection for a user */
export type SelectAppFeaturesInput = {
  /** Whether the user includes circle member features */
  circleMember?: InputMaybe<Scalars['Boolean']['input']>;
  /** Whether the user includes family partner features */
  familyPartner?: InputMaybe<Scalars['Boolean']['input']>;
  /** Whether the user includes senior member features */
  seniorMember?: InputMaybe<Scalars['Boolean']['input']>;
  /** The ID of the user */
  userId: Scalars['Int']['input'];
};

export type SendRequestToNotExistingUserOutput = {
  __typename?: 'SendRequestToNotExistingUserOutput';
  success: Scalars['Boolean']['output'];
};

export enum SentTypesOptions {
  Email = 'email',
  Push = 'push',
  Sms = 'sms',
}

export type SentViaOutput = {
  __typename?: 'SentViaOutput';
  fullName: Scalars['String']['output'];
  types: Array<SentTypesOptions>;
};

export enum ServicePaymentType {
  Fixed = 'fixed',
  Hour = 'hour',
  Visit = 'visit',
}

export type ServiceRequestAvailability = {
  __typename?: 'ServiceRequestAvailability';
  /** Date in the format YYYY-MM-DD */
  date: Scalars['PlainDate']['output'];
  /** From hours in the format 15:30Z+00:00 */
  from: Scalars['ZonedPlainTime']['output'];
  /** Until hours in the format 15:30Z+00:00 */
  until: Scalars['ZonedPlainTime']['output'];
};

export type ServiceRequestAvailabilityInput = {
  /** Date in the format YYYY-MM-DD */
  date: Scalars['PlainDate']['input'];
  /** From hours in the format 15:30Z+00:00 */
  from: Scalars['ZonedPlainTime']['input'];
  /** Until hours in the format 15:30Z+00:00 */
  until: Scalars['ZonedPlainTime']['input'];
};

export enum ServiceRequestCategoriesEnum {
  ApplianceRepair = 'appliance_repair',
  BeautyHomeCare = 'beauty_home_care',
  Cleaner = 'cleaner',
  Companionship = 'companionship',
  Electrical = 'electrical',
  Errands = 'errands',
  GroceryShopping = 'grocery_shopping',
  Handyman = 'handyman',
  HomeSafety = 'home_safety',
  LaundryService = 'laundry_service',
  MealDelivery = 'meal_delivery',
  NonMedicalHomeCare = 'non_medical_home_care',
  Other = 'other',
  PersonalShopping = 'personal_shopping',
  PersonalTraining = 'personal_training',
  PetCare = 'pet_care',
  Plumbing = 'plumbing',
  Ride = 'ride',
  TechAssistance = 'tech_assistance',
  Yardwork = 'yardwork',
}

export type ServiceRequestFilter = {
  /** Filter by agentId */
  agentId?: InputMaybe<Array<Scalars['Int']['input']>>;
  /** Filter by category */
  category?: InputMaybe<Array<ServiceRequestCategoriesEnum>>;
  /** Filter by createdAt after date */
  createdAtFrom?: InputMaybe<Scalars['DateTime']['input']>;
  /** Filter by createdAt before date */
  createdAtUntil?: InputMaybe<Scalars['DateTime']['input']>;
  /** Filter by customerId */
  customerId?: InputMaybe<Array<Scalars['Int']['input']>>;
  /** Whether the service request has been sent to at least one agent */
  hasAppointmentRequests?: InputMaybe<Scalars['Boolean']['input']>;
  /** Whether the service request's appointment requests are awaiting attention */
  hasWaitingAppointmentRequests?: InputMaybe<Scalars['Boolean']['input']>;
  /** Whether the service request needs assignment */
  needsAssignment?: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by requested after date */
  requestedAtFrom?: InputMaybe<Scalars['DateTime']['input']>;
  /** Filter by requested before date */
  requestedAtUntil?: InputMaybe<Scalars['DateTime']['input']>;
  /** Filter by requestedBy */
  requestedBy?: InputMaybe<Array<Scalars['Int']['input']>>;
  /** Filter by requires action */
  requiresAction?: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by scheduledAt after date */
  scheduledAtFrom?: InputMaybe<Scalars['DateTime']['input']>;
  /** Filter by scheduledAt before date */
  scheduledAtUntil?: InputMaybe<Scalars['DateTime']['input']>;
  /** Filter by status */
  status?: InputMaybe<Array<ServiceRequestStatus>>;
  /** Filters by agent, customer, user that requested the service or ID */
  text?: InputMaybe<Scalars['String']['input']>;
  /** Whether the service request is upcoming and needs confirmation */
  upcomingUnconfirmed?: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by updatedAt after date */
  updatedAtFrom?: InputMaybe<Scalars['DateTime']['input']>;
  /** Filter by updatedAt before date */
  updatedAtUntil?: InputMaybe<Scalars['DateTime']['input']>;
};

export type ServiceRequestInterpretResponse = {
  __typename?: 'ServiceRequestInterpretResponse';
  category: Scalars['String']['output'];
  recurring: Scalars['Boolean']['output'];
};

/** The available options for updating the price when chargingby visit  */
export enum ServiceRequestPaymentSummaryVisitType {
  AddExtraCharge = 'addExtraCharge',
  KeepCost = 'keepCost',
  Refund = 'refund',
}

/** The fields for sorting */
export type ServiceRequestSortInput = {
  /** The direction to order by */
  direction: SortOrder;
  /** The fields to sort by */
  field: ServiceRequestsSortableFields;
};

/** The different status for a service request */
export enum ServiceRequestStatus {
  AttentionNeeded = 'attentionNeeded',
  Cancelled = 'cancelled',
  Completed = 'completed',
  ConfirmationNeeded = 'confirmationNeeded',
  Confirmed = 'confirmed',
  Inactive = 'inactive',
  PaymentAuthorizationFailed = 'paymentAuthorizationFailed',
  PaymentFailed = 'paymentFailed',
  PaymentWaitingForSupport = 'paymentWaitingForSupport',
  Pending = 'pending',
  UserUnavailable = 'userUnavailable',
}

/** The tentative schedule for a service request provided by the user */
export enum ServiceRequestTentativeSchedule {
  AsSoonAsPossible = 'asSoonAsPossible',
  ChooseTimeAndDate = 'chooseTimeAndDate',
  WithinDays = 'withinDays',
  WithinWeeks = 'withinWeeks',
}

/** The type of service request */
export enum ServiceRequestType {
  Circle = 'circle',
  CoachVisit = 'coachVisit',
  Service = 'service',
  Social = 'social',
}

export enum ServiceRequestsSortableFields {
  AddressId = 'addressId',
  AgentId = 'agentId',
  Availabilities = 'availabilities',
  CancellationReason = 'cancellationReason',
  CancelledBy = 'cancelledBy',
  Category = 'category',
  CommentForBrya = 'commentForBrya',
  CommentForCustomer = 'commentForCustomer',
  CreatedAt = 'createdAt',
  CreatedBy = 'createdBy',
  Description = 'description',
  Frequency = 'frequency',
  Id = 'id',
  IsAvailabilityFlexible = 'isAvailabilityFlexible',
  OtherCategory = 'otherCategory',
  PostVisitSummarySent = 'postVisitSummarySent',
  Recurring = 'recurring',
  RequestType = 'requestType',
  RequestedBy = 'requestedBy',
  Ride = 'ride',
  ScheduledAt = 'scheduledAt',
  Status = 'status',
  TentativeSchedule = 'tentativeSchedule',
  UpdatedAt = 'updatedAt',
}

export type ServiceType = {
  __typename?: 'ServiceType';
  id: ServiceRequestCategoriesEnum;
  name: Scalars['String']['output'];
};

export type SocialEventFilter = {
  /** Filter by city */
  city?: InputMaybe<Scalars['String']['input']>;
  /** Filter by coach id */
  coachId?: InputMaybe<Array<Scalars['Int']['input']>>;
  /** Filter by createdAt after date */
  createdAtFrom?: InputMaybe<Scalars['DateTime']['input']>;
  /** Filter by createdAt before date */
  createdAtUntil?: InputMaybe<Scalars['DateTime']['input']>;
  /** Filters by event name */
  eventName?: InputMaybe<Scalars['String']['input']>;
  /** Filter by whether the social event has pending contact requests */
  hasPendingContactRequests?: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by participantId */
  participantId?: InputMaybe<Array<Scalars['Int']['input']>>;
  /** Filter by requestedBy */
  requestedBy?: InputMaybe<Array<Scalars['Int']['input']>>;
  /** Filter by scheduledAt after date */
  scheduledAtFrom?: InputMaybe<Scalars['DateTime']['input']>;
  /** Filter by scheduledAt before date */
  scheduledAtUntil?: InputMaybe<Scalars['DateTime']['input']>;
  /** Filter by status */
  status?: InputMaybe<Array<SocialEventStatus>>;
  /** Filter by zipCode */
  zipCode?: InputMaybe<Scalars['String']['input']>;
};

/** The fields for sorting */
export type SocialEventSortInput = {
  /** The direction to order by */
  direction: SortOrder;
  /** The fields to sort by */
  field: SocialEventSortableFields;
};

export enum SocialEventSortableFields {
  AddressId = 'addressId',
  AddressLine1 = 'addressLine1',
  AddressLine2 = 'addressLine2',
  CancellationReason = 'cancellationReason',
  CancelledBy = 'cancelledBy',
  City = 'city',
  Country = 'country',
  CreatedAt = 'createdAt',
  CreatedBy = 'createdBy',
  Description = 'description',
  EventName = 'eventName',
  Frequency = 'frequency',
  Id = 'id',
  Place = 'place',
  Recurring = 'recurring',
  RequestType = 'requestType',
  RequestedBy = 'requestedBy',
  ScheduledAt = 'scheduledAt',
  State = 'state',
  Status = 'status',
  UpdatedAt = 'updatedAt',
  ZipCode = 'zipCode',
}

/** The different status for a social event */
export enum SocialEventStatus {
  Cancelled = 'cancelled',
  Completed = 'completed',
  Confirmed = 'confirmed',
}

/** The possible order records can be sorted by */
export enum SortOrder {
  Asc = 'asc',
  Desc = 'desc',
}

/** The fields for starting a conversation */
export type StartConversationInput = {
  /** Custom data for the conversation */
  custom?: InputMaybe<ConversationCustomInput>;
  /** Subject for the conversation */
  subject?: InputMaybe<Scalars['String']['input']>;
  /** Chat Provider IDs of the conversation participants to create */
  talkJsIds?: InputMaybe<Array<Scalars['String']['input']>>;
  /** User IDs for starting a new conversation, used when not all users have a Chat Provider ID */
  userIds?: InputMaybe<Array<Scalars['Int']['input']>>;
};

export type StipePaymentMethod = {
  __typename?: 'StipePaymentMethod';
  id: Scalars['String']['output'];
};

export type StripeAmountDetails = {
  __typename?: 'StripeAmountDetails';
  tip?: Maybe<StripeTip>;
};

export type StripeBillingDetails = {
  __typename?: 'StripeBillingDetails';
  name?: Maybe<Scalars['String']['output']>;
};

export type StripeCard = {
  __typename?: 'StripeCard';
  capture_method?: Maybe<Scalars['String']['output']>;
  network?: Maybe<Scalars['String']['output']>;
  request_three_d_secure?: Maybe<Scalars['String']['output']>;
  setup_future_usage?: Maybe<Scalars['String']['output']>;
  statement_descriptor_suffix_kana?: Maybe<Scalars['String']['output']>;
  statement_descriptor_suffix_kanji?: Maybe<Scalars['String']['output']>;
};

export type StripeCardPaymentMethod = {
  __typename?: 'StripeCardPaymentMethod';
  billing_details: StripeBillingDetails;
  card: GraphqlStripeCard;
  created: Scalars['Int']['output'];
  default?: Maybe<Scalars['Boolean']['output']>;
  id: Scalars['String']['output'];
  livemode: Scalars['Boolean']['output'];
  object: Scalars['String']['output'];
  type: Scalars['String']['output'];
};

export enum StripeConnectAccountStatus {
  Complete = 'complete',
  MissingRequirements = 'missingRequirements',
  RequirementsPastDue = 'requirementsPastDue',
}

export type StripeLastPaymentError = {
  __typename?: 'StripeLastPaymentError';
  message?: Maybe<Scalars['String']['output']>;
  payment_method?: Maybe<StipePaymentMethod>;
  type: Scalars['String']['output'];
};

export type StripePaymentIntent = {
  __typename?: 'StripePaymentIntent';
  amount: Scalars['Float']['output'];
  amount_capturable: Scalars['Int']['output'];
  amount_details?: Maybe<StripeAmountDetails>;
  amount_received: Scalars['Int']['output'];
  application?: Maybe<Scalars['String']['output']>;
  application_fee_amount?: Maybe<Scalars['String']['output']>;
  automatic_payment_methods?: Maybe<AutomaticPaymentMethods>;
  canceled_at?: Maybe<Scalars['Int']['output']>;
  cancellation_reason?: Maybe<Scalars['String']['output']>;
  capture_method: Scalars['String']['output'];
  client_secret?: Maybe<Scalars['String']['output']>;
  confirmation_method: Scalars['String']['output'];
  created: Scalars['Int']['output'];
  currency: Scalars['String']['output'];
  customer?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  invoice?: Maybe<Scalars['String']['output']>;
  last_payment_error?: Maybe<StripeLastPaymentError>;
  latest_charge?: Maybe<Scalars['String']['output']>;
  livemode: Scalars['Boolean']['output'];
  object: Scalars['String']['output'];
  on_behalf_of?: Maybe<Scalars['String']['output']>;
  payment_method?: Maybe<Scalars['String']['output']>;
  payment_method_options?: Maybe<StripePaymentMethodOptions>;
  payment_method_types: Array<Scalars['String']['output']>;
  receipt_email?: Maybe<Scalars['String']['output']>;
  review?: Maybe<Scalars['String']['output']>;
  setup_future_usage?: Maybe<Scalars['String']['output']>;
  statement_descriptor?: Maybe<Scalars['String']['output']>;
  statement_descriptor_suffix?: Maybe<Scalars['String']['output']>;
  status: Scalars['String']['output'];
  transfer_data?: Maybe<StripeTransferData>;
  transfer_group?: Maybe<Scalars['String']['output']>;
};

export type StripePaymentMethodOptions = {
  __typename?: 'StripePaymentMethodOptions';
  card?: Maybe<StripeCard>;
};

export enum StripeSubscriptionPricesRecurringEnum {
  Month = 'month',
  Year = 'year',
}

export type StripeTip = {
  __typename?: 'StripeTip';
  amount?: Maybe<Scalars['Float']['output']>;
};

export type StripeTransactionFilter = {
  /** Filter by activity id */
  activityId?: InputMaybe<Array<Scalars['Int']['input']>>;
  /** Filter by createdAt after date */
  createdAtFrom?: InputMaybe<Scalars['DateTime']['input']>;
  /** Filter by createdAt before date */
  createdAtUntil?: InputMaybe<Scalars['DateTime']['input']>;
  /** Filter by status */
  status?: InputMaybe<Array<StripeTransactionStatus>>;
  /** Filters by stripe connect account id */
  stripeAccountId?: InputMaybe<Scalars['String']['input']>;
  /** Filters by stripe customer id */
  stripeCustomerId?: InputMaybe<Scalars['String']['input']>;
  /** Filters by transaction id */
  stripeTransactionId?: InputMaybe<Scalars['String']['input']>;
};

/** The fields for sorting */
export type StripeTransactionFilterSortInput = {
  /** The direction to order by */
  direction: SortOrder;
  /** The fields to sort by */
  field: StripeTransactionSortableFields;
};

export enum StripeTransactionSortableFields {
  ActivityId = 'activityId',
  Amount = 'amount',
  AmountCaptured = 'amountCaptured',
  CreatedAt = 'createdAt',
  Status = 'status',
  StripeConnectAccountId = 'stripeConnectAccountId',
  StripeCustomerId = 'stripeCustomerId',
  StripeTransactionId = 'stripeTransactionId',
  UpdatedAt = 'updatedAt',
}

export enum StripeTransactionStatus {
  Canceled = 'canceled',
  Processing = 'processing',
  RequiresAction = 'requiresAction',
  RequiresCapture = 'requiresCapture',
  RequiresConfirmation = 'requiresConfirmation',
  RequiresPaymentMethod = 'requiresPaymentMethod',
  Succeeded = 'succeeded',
}

export type StripeTransferData = {
  __typename?: 'StripeTransferData';
  amount: Scalars['Int']['output'];
  destination: Scalars['String']['output'];
};

export type SubscriptionKeys = {
  /** auth key */
  auth: Scalars['String']['input'];
  /** p256dh key */
  p256dh: Scalars['String']['input'];
};

/** The fields for filter suggested activities */
export type SuggestedActivitiesFilter = {
  /** The suggested activity name to filter by */
  name?: InputMaybe<Scalars['String']['input']>;
  /** The suggested activities user roles to filter by */
  roles?: InputMaybe<Array<SuggestedActivitiesRoleEnum>>;
  /** The user ids to filter by */
  userIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  /** Filters by user name */
  userName?: InputMaybe<Scalars['String']['input']>;
};

export type SuggestedActivitiesOutput = {
  __typename?: 'SuggestedActivitiesOutput';
  /** The pagination information */
  meta: PaginationMeta;
  /** The list of suggested activities */
  suggestedActivities: Array<GraphqlSuggestedActivities>;
};

/** The available roles for when creating a suggested activity */
export enum SuggestedActivitiesRoleEnum {
  Host = 'host',
  Participant = 'participant',
}

/** The fields for sorting */
export type SuggestedActivitiesSortInput = {
  /** The direction to order by */
  direction: SortOrder;
  /** The fields to sort by */
  field: SuggestedActivitiesSortableFields;
};

/** The possible fields that the suggested activities can be sorted by */
export enum SuggestedActivitiesSortableFields {
  CreatedAt = 'createdAt',
  Id = 'id',
  Name = 'name',
  UserId = 'userId',
  UserRole = 'userRole',
}

export type SyncUserChatOutput = {
  __typename?: 'SyncUserChatOutput';
  /** If the sync was completed successfully */
  success: Scalars['Boolean']['output'];
};

export type TimelineOutput = {
  __typename?: 'TimelineOutput';
  /** The day the activity is schedule to happen */
  day: Scalars['String']['output'];
  /** The month the activity is schedule to happen */
  month: Scalars['String']['output'];
  /** The week day the activity is schedule to happen */
  weekDay: Scalars['String']['output'];
};

/** The possible actions the user can perform when trusting a member */
export enum TrustUntrustActionType {
  Trust = 'trust',
  Untrust = 'untrust',
}

export type TrustUntrustMembersOutput = {
  __typename?: 'TrustUntrustMembersOutput';
  /** The action to be performed */
  action: TrustUntrustActionType;
  /** Whether the action was successfull or not */
  success: Scalars['Boolean']['output'];
};

/** The possible types that can be filted by when getting the User Participants */
export enum TrustedUserTypeOptions {
  All = 'all',
  Trusted = 'trusted',
}

export type UnsubscribeInput = {
  /** Must have at least one field for a mutation */
  destroy: Scalars['Boolean']['input'];
};

/** The fields to update an address */
export type UpdateAddressInput = {
  /** First address line */
  addressLine1?: InputMaybe<Scalars['String']['input']>;
  /** Additional address information */
  addressLine2?: InputMaybe<Scalars['String']['input']>;
  /** The address city */
  city?: InputMaybe<Scalars['String']['input']>;
  /** The address country code */
  country?: InputMaybe<Scalars['String']['input']>;
  /** Whether the address is the default one */
  isDefault?: InputMaybe<Scalars['Boolean']['input']>;
  /** Alias for the address */
  place?: InputMaybe<Scalars['String']['input']>;
  /** The address state */
  state?: InputMaybe<Scalars['String']['input']>;
  /** The address zip code */
  zipCode?: InputMaybe<Scalars['String']['input']>;
};

/** The fields for updating an agent */
export type UpdateAgentInput = {
  /** The agent's first name */
  firstName?: InputMaybe<Scalars['String']['input']>;
  /** The agent's internal (hidden) rating */
  internalRating?: InputMaybe<Scalars['Int']['input']>;
  /** The agent's last name */
  lastName?: InputMaybe<Scalars['String']['input']>;
  /** Optional password to be set for the agent */
  password?: InputMaybe<Scalars['String']['input']>;
  /** The agent's phone number */
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
  /** The prefered contact methods of the agent for being reach by Brya */
  preferedContactMethods?: InputMaybe<Array<AgentPreferedContactMethodEnum>>;
  /** The types of services that the agent can provide */
  serviceTypes?: InputMaybe<Array<ServiceRequestCategoriesEnum>>;
};

export type UpdateAppointmentPaymentInput = {
  /** The amount that is being charged for the service */
  amount?: InputMaybe<Scalars['Float']['input']>;
  /** How many hours it should take to complete the service */
  estimateHours?: InputMaybe<Scalars['Float']['input']>;
  /** The payment type */
  type?: InputMaybe<ServicePaymentType>;
};

/** The fields to update an appointment request */
export type UpdateAppointmentRequestInput = {
  /** The appointment request ID */
  id: Scalars['Int']['input'];
  /** New scheduledAt datetime */
  scheduledAt?: InputMaybe<Scalars['DateTime']['input']>;
  /** New status */
  status?: InputMaybe<AppointmentRequestStatus>;
};

export type UpdateCircleRequestInput = {
  /** Address id related to where the request was made */
  addressId?: InputMaybe<Scalars['Int']['input']>;
  /** The availabilities set by the user on when the request can be scheduled */
  availabilities?: InputMaybe<Array<ServiceRequestAvailabilityInput>>;
  /** The category of this request */
  category?: InputMaybe<CircleRequestCategoriesEnum>;
  /** Description of the request made by the user */
  description?: InputMaybe<Scalars['String']['input']>;
  /** Recurring Frequency */
  frequency?: InputMaybe<RecurringFrequencyType>;
  /** If 'other' specified in 'category', this is the user's input on the category */
  otherCategory?: InputMaybe<Scalars['String']['input']>;
  /** Marks request as recurring */
  recurring?: InputMaybe<Scalars['Boolean']['input']>;
  /** The ride details of the request provided by the user */
  ride?: InputMaybe<RideInput>;
  /** New scheduledAt datetime */
  scheduledAt?: InputMaybe<Scalars['DateTime']['input']>;
  /** Status of the Circle Qequest */
  status?: InputMaybe<ServiceRequestStatus>;
};

export type UpdateCoachVisitInput = {
  /** Address id related to where the visit was requested */
  addressId?: InputMaybe<Scalars['Int']['input']>;
  /** Description of the visit */
  description?: InputMaybe<Scalars['String']['input']>;
  /** Recurring Frequency */
  frequency?: InputMaybe<RecurringFrequencyType>;
  /** Whether the meeting should be conducted via phone */
  isPhoneCall?: InputMaybe<Scalars['Boolean']['input']>;
  /** Whether the visit is recurring or not */
  recurring?: InputMaybe<Scalars['Boolean']['input']>;
  /** When the visit is going to occur */
  scheduledAt?: InputMaybe<Scalars['DateTime']['input']>;
  /** Status of the Coach Visit */
  status?: InputMaybe<SocialEventStatus>;
};

/** Fields that can be updated, fields with a set value will be updated */
export type UpdateCompanyInput = {
  addressLine1?: InputMaybe<Scalars['String']['input']>;
  addressLine2?: InputMaybe<Scalars['String']['input']>;
  city?: InputMaybe<Scalars['String']['input']>;
  hours?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
  serviceZipCodes?: InputMaybe<Array<Scalars['String']['input']>>;
  state?: InputMaybe<Scalars['String']['input']>;
  website?: InputMaybe<Scalars['String']['input']>;
  zipCode?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateContactRequestInput = {
  bryaInternalNotes?: InputMaybe<Scalars['String']['input']>;
  bryaMessage?: InputMaybe<Scalars['String']['input']>;
  customerActivityId?: InputMaybe<Scalars['Int']['input']>;
  status?: InputMaybe<ContactRequestStatus>;
  userMessage?: InputMaybe<Scalars['String']['input']>;
};

/** The fields to be updated for the current user */
export type UpdateCurrentUserInput = {
  /** The user mobile default tab */
  defaultTab?: InputMaybe<DefaultTab>;
  /** the firebase token */
  firebaseToken?: InputMaybe<Scalars['String']['input']>;
  /** The user first name */
  firstName?: InputMaybe<Scalars['String']['input']>;
  /** The user language */
  language?: InputMaybe<Scalars['String']['input']>;
  /** The user last name */
  lastName?: InputMaybe<Scalars['String']['input']>;
  /** The user phone number */
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
};

/** The fields to updaate a customer */
export type UpdateCustomerInput = {
  /** The customer birthday */
  birthday?: InputMaybe<Scalars['DateTime']['input']>;
  /** The customer email */
  email?: InputMaybe<Scalars['String']['input']>;
  /** The customer first name */
  firstName?: InputMaybe<Scalars['String']['input']>;
  /** The customer last name */
  lastName?: InputMaybe<Scalars['String']['input']>;
  /** The customer phone number */
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateRequestCategoryInput = {
  circleRequest?: InputMaybe<Scalars['Boolean']['input']>;
  circleRequestOrder?: InputMaybe<Scalars['Int']['input']>;
  id: Scalars['String']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
  newId?: InputMaybe<Scalars['String']['input']>;
  serviceRequest?: InputMaybe<Scalars['Boolean']['input']>;
  serviceRequestOrder?: InputMaybe<Scalars['Int']['input']>;
};

export type UpdateServicePaymentInput = {
  /** Payment method id that is being used to pay for the service */
  stripePaymentMethodId?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateServiceRequestInput = {
  /** Address id related to where the service was requested */
  addressId?: InputMaybe<Scalars['Int']['input']>;
  /** The availabilities set by the user on when the service can be scheduled */
  availabilities?: InputMaybe<Array<ServiceRequestAvailabilityInput>>;
  /** The category of this service request */
  category?: InputMaybe<Scalars['String']['input']>;
  /** Description of the request made by the user */
  description?: InputMaybe<Scalars['String']['input']>;
  /** Recurring Frequency */
  frequency?: InputMaybe<RecurringFrequencyType>;
  /** If 'other' specified in 'category', this is the user's input on the category */
  otherCategory?: InputMaybe<Scalars['String']['input']>;
  /** Marks service request as recurring */
  recurring?: InputMaybe<Scalars['Boolean']['input']>;
  /** The ride details of the request provided by the user */
  ride?: InputMaybe<RideInput>;
  /** New scheduledAt datetime */
  scheduledAt?: InputMaybe<Scalars['DateTime']['input']>;
  /** The status of the service request */
  status?: InputMaybe<ServiceRequestStatus>;
};

export type UpdateSocialEventInput = {
  /** Field for all of the nested Social Event -specific fields */
  SocialEvent?: InputMaybe<UpdateSocialEventInputNestedSocialEvent>;
  /** The ids of coaches related to the event */
  coachesIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  /** Description of the event */
  description?: InputMaybe<Scalars['String']['input']>;
  /** The name of the event */
  eventName?: InputMaybe<Scalars['String']['input']>;
  /** Frequency at which the event recurs */
  frequency?: InputMaybe<RecurringFrequencyType>;
  /** The ids of coaches related to the event */
  participantsIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  /** Whether the event is recurring or not */
  recurring?: InputMaybe<Scalars['Boolean']['input']>;
  /** When the event is going to occur */
  scheduledAt?: InputMaybe<Scalars['DateTime']['input']>;
  /** Status of the Circle Qequest */
  status?: InputMaybe<SocialEventStatus>;
};

export type UpdateSocialEventInputNestedSocialEvent = {
  /** The update object inside of this nested Social Event object */
  update?: InputMaybe<UpdateSocialEventInputNestedSocialEventUpdate>;
};

export type UpdateSocialEventInputNestedSocialEventUpdate = {
  /** Location address line 1 */
  addressLine1?: InputMaybe<Scalars['String']['input']>;
  /** Location address line 2 */
  addressLine2?: InputMaybe<Scalars['String']['input']>;
  /** Location address city name */
  city?: InputMaybe<Scalars['String']['input']>;
  /** Location address country name */
  country?: InputMaybe<Scalars['String']['input']>;
  /** Location place name */
  place?: InputMaybe<Scalars['String']['input']>;
  /** Location address state name */
  state?: InputMaybe<Scalars['String']['input']>;
  /** Location address ZIP code */
  zipCode?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateStripeCustomerDefaultCardOutput = {
  __typename?: 'UpdateStripeCustomerDefaultCardOutput';
  success: Scalars['Boolean']['output'];
};

/** The fields for updating a suggested activity */
export type UpdateSuggestedActivityInput = {
  /** The suggested activity description */
  description: Scalars['String']['input'];
  /** The suggested activity name */
  name: Scalars['String']['input'];
  /** The internal notes of the suggested activity */
  notes: Scalars['String']['input'];
  /** The role the user wants to have */
  role: SuggestedActivitiesRoleEnum;
};

export type UserEmergencyContact = {
  __typename?: 'UserEmergencyContact';
  name: Scalars['String']['output'];
  phoneNumber: Scalars['String']['output'];
  relation: Scalars['String']['output'];
};

export type UserEmergencyContactInput = {
  name?: InputMaybe<Scalars['String']['input']>;
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
  relation?: InputMaybe<Scalars['String']['input']>;
};

/** The fields for filter users */
export type UserFilter = {
  /** The user email to filter by */
  email?: InputMaybe<Scalars['String']['input']>;
  /** The user name to filter by */
  fullName?: InputMaybe<Scalars['String']['input']>;
  /** Whether we want to filter users with no role or not. By default we always return all users if no filter is specified */
  noRole?: InputMaybe<Scalars['Boolean']['input']>;
  /** The user roles to filter by */
  roles?: InputMaybe<Array<GlobalRole>>;
  /** Filters by text fields in OR mode: fullName, email */
  text?: InputMaybe<Scalars['String']['input']>;
};

export type UserGetCustomerOutput = {
  __typename?: 'UserGetCustomerOutput';
  /** The user role */
  role: Scalars['String']['output'];
  /** The details of the user */
  user?: Maybe<GraphqlUser>;
};

/** The available fileds to create a circle invites for a customer */
export type UserInviteInput = {
  /** The customer that will join the invitation receiver */
  customerId?: InputMaybe<Scalars['Int']['input']>;
  /** The email to sent the invitation */
  email?: InputMaybe<Scalars['String']['input']>;
  /** The person name to whom the invite was sent */
  firstName: Scalars['String']['input'];
  /** The type of invite, familyPartner or circleMember */
  inviteType: UserInvitesType;
  /** The user id who sent the invite */
  invitedBy?: InputMaybe<Scalars['Int']['input']>;
  /** The person name to whom the invite was sent */
  lastName: Scalars['String']['input'];
  /** The phone number to sent the invitation */
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
  /** The relationship between the customer and the invitation receiver */
  relationship?: InputMaybe<Scalars['String']['input']>;
};

/** The available fileds to create a circle invites for a customer */
export type UserInviteResendInput = {
  /** the options to resend the invitation */
  sentVia: Array<SentTypesOptions>;
  /** the user invite id */
  userInviteId: Scalars['Int']['input'];
};

export enum UserInvitesStatus {
  Accepted = 'accepted',
  Declined = 'declined',
  Sent = 'sent',
}

export enum UserInvitesType {
  CircleMember = 'circleMember',
  FamilyPartner = 'familyPartner',
}

/** The fields for sorting */
export type UserSortInput = {
  /** The direction to order by */
  direction: SortOrder;
  /** The fields to sort by */
  field: UserSortableFields;
};

/** The possible fields that the users can be sorted by */
export enum UserSortableFields {
  CreatedAt = 'createdAt',
  Email = 'email',
  FullName = 'fullName',
  Id = 'id',
  UpdatedAt = 'updatedAt',
}

export type UserWithFullName = {
  __typename?: 'UserWithFullName';
  fullName: Scalars['String']['output'];
  userId: Scalars['Int']['output'];
};

export type UserWithTrustedFlag = {
  __typename?: 'UserWithTrustedFlag';
  /** The last date the user logged in */
  Agent?: Maybe<GraphqlAgent>;
  /** The app features available for this user */
  AppFeaturesSelected?: Maybe<GraphqlAppFeaturesSelected>;
  /** The customer that represents this user */
  OwnCustomer?: Maybe<GraphqlCustomer>;
  /** The current customer selected by the user */
  SelectedCustomer?: Maybe<GraphqlCustomer>;
  /** The roles related for this user */
  UserCustomerRoles?: Maybe<Array<GraphqlUserRole>>;
  /** User bio */
  bio?: Maybe<Scalars['String']['output']>;
  /** The date the user account has been created */
  createdAt: Scalars['DateTime']['output'];
  /** The default tab that should be opened when the user logs in in the mobile application */
  defaultTab?: Maybe<DefaultTab>;
  /** The user account status, whether it's disabled or not */
  disabled?: Maybe<Scalars['Boolean']['output']>;
  /** The user email address */
  email?: Maybe<Scalars['String']['output']>;
  /** The user email varified status */
  emailVerified?: Maybe<Scalars['Boolean']['output']>;
  /** The emergency contact, available for brya employees (coaches and support) */
  emergencyContact?: Maybe<UserEmergencyContact>;
  /** The firebase token associated with this user */
  firebaseToken?: Maybe<Scalars['String']['output']>;
  /** The user first name */
  firstName?: Maybe<Scalars['String']['output']>;
  /** The user full name */
  fullName?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  /** If the user is marked as a circle member or not */
  isCircleMember?: Maybe<Scalars['Boolean']['output']>;
  /** The user language */
  language?: Maybe<Scalars['String']['output']>;
  /** The last date the user logged in */
  lastLogin?: Maybe<Scalars['DateTime']['output']>;
  /** The user last name */
  lastName?: Maybe<Scalars['String']['output']>;
  /** The customer id associated with the user */
  ownCustomerId?: Maybe<Scalars['Int']['output']>;
  /** The user phone number */
  phoneNumber?: Maybe<Scalars['String']['output']>;
  /** The user profile picture */
  profilePicture?: Maybe<Scalars['String']['output']>;
  /** The role of the user */
  role?: Maybe<GlobalRole>;
  /** The user current selected customer */
  selectedCustomerId?: Maybe<Scalars['Int']['output']>;
  /** The user talkJs id for the chat */
  talkJsId?: Maybe<Scalars['String']['output']>;
  /** Whether the user is a trusted member or not */
  trustedMember?: Maybe<Scalars['Boolean']['output']>;
  /** The last date the user account has been updated */
  updatedAt: Scalars['DateTime']['output'];
  /** Push notification subscription object */
  webPushSubscription?: Maybe<Scalars['String']['output']>;
};

export type VerifyEmailOutput = {
  __typename?: 'VerifyEmailOutput';
  accessToken: Scalars['String']['output'];
};

/** The options to filter visit summaries */
export type VisitSummariesFilterOptions = {
  /** Matches coach visit */
  coachVisitId?: InputMaybe<Scalars['Float']['input']>;
};

export type VisitSummariesSortOptions = {
  /** The direction to order by */
  direction: SortOrder;
  /** The fields to sort by */
  field: VisitSummariesSortableFields;
};

/** The possible fields that visit summaries can be sorted */
export enum VisitSummariesSortableFields {
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt',
}

/** The webpush-expected object to create a subscription */
export type WebPushSubscription = {
  /** FCM endpoint to which to send web pushes */
  endpoint: Scalars['String']['input'];
  /** Whether the subscription expires, and when */
  expirationTime?: InputMaybe<Scalars['String']['input']>;
  /** subscription keys */
  keys: SubscriptionKeys;
};

export type GetActivityTimelineQueryVariables = Exact<{
  orderIn: SortOrder;
  activityId: Scalars['Int']['input'];
}>;

export type GetActivityTimelineQuery = {
  __typename?: 'Query';
  getTimeline: Array<{ __typename?: 'GraphqlTimeline'; createdAt: Date; description: string }>;
};

export type GetAppointmentRequestsQueryVariables = Exact<{
  circleRequestId?: InputMaybe<Scalars['Int']['input']>;
  serviceRequestId?: InputMaybe<Scalars['Int']['input']>;
}>;

export type GetAppointmentRequestsQuery = {
  __typename?: 'Query';
  getAppointmentRequests: Array<{
    __typename?: 'GraphqlAppointmentRequest';
    id: number;
    userId: number;
    serviceRequestId?: number | null;
    circleRequestId?: number | null;
    status: AppointmentRequestStatus;
    createdAt: Date;
    updatedAt: Date;
    scheduledAt?: Date | null;
    CreatedBy?: { __typename?: 'GraphqlUser'; id: number; fullName?: string | null } | null;
    SentTo?: {
      __typename?: 'GraphqlUser';
      id: number;
      fullName?: string | null;
      Agent?: { __typename?: 'GraphqlAgent'; deletedAt?: Date | null } | null;
    } | null;
    ServiceRequest?: {
      __typename?: 'GraphqlServiceRequest';
      id: number;
      isAvailabilityFlexible: boolean;
      tentativeSchedule: ServiceRequestTentativeSchedule;
      availabilities: Array<{
        __typename?: 'ServiceRequestAvailability';
        date: Date;
        from: Date;
        until: Date;
      }>;
    } | null;
    CircleRequest?: {
      __typename?: 'GraphqlCircleRequest';
      id: number;
      isAvailabilityFlexible: boolean;
      tentativeSchedule: ServiceRequestTentativeSchedule;
      availabilities: Array<{
        __typename?: 'ServiceRequestAvailability';
        date: Date;
        from: Date;
        until: Date;
      }>;
    } | null;
    AppointmentRequestPayments?: Array<{
      __typename?: 'GraphqlAppointmentRequestPayment';
      id: number;
      type: ServicePaymentType;
      amount: number;
      estimateHours?: number | null;
      appointmentRequestId: number;
    }> | null;
  }>;
};

export type GetAppointmentRequestsFilteredQueryVariables = Exact<{
  filter: AppointmentRequestFilter;
  sort?: InputMaybe<AppointmentRequestSortInput>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
}>;

export type GetAppointmentRequestsFilteredQuery = {
  __typename?: 'Query';
  getAppointmentRequestsFiltered: {
    __typename?: 'GetAppointmentRequestsOutput';
    appointmentRequests: Array<{
      __typename?: 'GraphqlAppointmentRequest';
      id: number;
      userId: number;
      serviceRequestId?: number | null;
      circleRequestId?: number | null;
      status: AppointmentRequestStatus;
      createdAt: Date;
      updatedAt: Date;
      scheduledAt?: Date | null;
      CreatedBy?: { __typename?: 'GraphqlUser'; id: number; fullName?: string | null } | null;
      SentTo?: { __typename?: 'GraphqlUser'; id: number; fullName?: string | null } | null;
      ServiceRequest?: {
        __typename?: 'GraphqlServiceRequest';
        id: number;
        category: ServiceRequestCategoriesEnum;
        status: ServiceRequestStatus;
        scheduledAt?: Date | null;
        createdAt: Date;
        actionNeeded?: string | null;
        isAvailabilityFlexible: boolean;
        Customer: { __typename?: 'GraphqlCustomer'; id: number; fullName?: string | null };
        Agent?: {
          __typename?: 'GraphqlAgent';
          userId: number;
          deletedAt?: Date | null;
          User: { __typename?: 'GraphqlUser'; id: number; fullName?: string | null };
        } | null;
        RequestedBy: { __typename?: 'GraphqlUser'; id: number; fullName?: string | null };
        availabilities: Array<{
          __typename?: 'ServiceRequestAvailability';
          date: Date;
          from: Date;
          until: Date;
        }>;
      } | null;
      AppointmentRequestPayments?: Array<{
        __typename?: 'GraphqlAppointmentRequestPayment';
        id: number;
        type: ServicePaymentType;
        amount: number;
        estimateHours?: number | null;
        appointmentRequestId: number;
      }> | null;
    }>;
    meta: {
      __typename?: 'PaginationMeta';
      currentPage: number;
      isFirstPage: boolean;
      isLastPage: boolean;
      nextPage?: number | null;
      pageCount: number;
      previousPage?: number | null;
      totalCount: number;
    };
  };
};

export type UpdateAppointmentRequestMutationVariables = Exact<{
  updateAppointmentRequestInput: UpdateAppointmentRequestInput;
}>;

export type UpdateAppointmentRequestMutation = {
  __typename?: 'Mutation';
  updateAppointmentRequest: { __typename?: 'GraphqlAppointmentRequest'; id: number };
};

export type CreateInternalNoteMutationVariables = Exact<{
  input: CreateInternalNoteInput;
}>;

export type CreateInternalNoteMutation = {
  __typename?: 'Mutation';
  createInternalNote: {
    __typename?: 'GraphqlInternalNote';
    userId: number;
    updatedAt: Date;
    serviceRequestId?: number | null;
    note: string;
    id: number;
    createdAt: Date;
    Users: {
      __typename?: 'GraphqlUser';
      id: number;
      updatedAt: Date;
      createdAt: Date;
      fullName?: string | null;
    };
  };
};

export type UpdateInternalNoteMutationVariables = Exact<{
  input: CreateInternalNoteInput;
  updateInternalNoteId: Scalars['Float']['input'];
}>;

export type UpdateInternalNoteMutation = {
  __typename?: 'Mutation';
  updateInternalNote: {
    __typename?: 'GraphqlInternalNote';
    createdAt: Date;
    id: number;
    note: string;
    serviceRequestId?: number | null;
    updatedAt: Date;
    userId: number;
    Users: {
      __typename?: 'GraphqlUser';
      id: number;
      fullName?: string | null;
      createdAt: Date;
      updatedAt: Date;
    };
  };
};

export type ListRequestCategoriesQueryVariables = Exact<{
  serviceRequest?: InputMaybe<Scalars['Boolean']['input']>;
  circleRequest?: InputMaybe<Scalars['Boolean']['input']>;
}>;

export type ListRequestCategoriesQuery = {
  __typename?: 'Query';
  listRequestCategories: Array<{
    __typename?: 'GraphqlRequestCategory';
    id: string;
    name: string;
    circleRequest: boolean;
    serviceRequest: boolean;
    createdAt: Date;
    updatedAt: Date;
  }>;
};

export type CreateAppointmentRequestPaymentMutationVariables = Exact<{
  appointmentPaymentInput: CreateAppointmentPaymentInput;
}>;

export type CreateAppointmentRequestPaymentMutation = {
  __typename?: 'Mutation';
  createAppointmentRequestPayment: { __typename?: 'GraphqlAppointmentRequestPayment'; id: number };
};

export type UpdateAppointmentRequestPaymentMutationVariables = Exact<{
  appointmentRequestPaymentId: Scalars['Int']['input'];
  appointmentPaymentInput: UpdateAppointmentPaymentInput;
}>;

export type UpdateAppointmentRequestPaymentMutation = {
  __typename?: 'Mutation';
  updateAppointmentRequestPayment: { __typename?: 'GraphqlAppointmentRequestPayment'; id: number };
};

export type LoginMutationVariables = Exact<{
  password: Scalars['String']['input'];
  email: Scalars['String']['input'];
  inviteToken?: InputMaybe<Scalars['String']['input']>;
}>;

export type LoginMutation = {
  __typename?: 'Mutation';
  login: { __typename?: 'LoginUserOutput'; accessToken: string };
};

export type CreateUserMutationVariables = Exact<{
  createUserInput: CreateUserInput;
  inviteToken?: InputMaybe<Scalars['String']['input']>;
}>;

export type CreateUserMutation = {
  __typename?: 'Mutation';
  createUser: {
    __typename?: 'GraphqlUser';
    firstName?: string | null;
    lastName?: string | null;
    email?: string | null;
  };
};

export type ResendVerifyEmailMutationVariables = Exact<{
  email: Scalars['String']['input'];
}>;

export type ResendVerifyEmailMutation = {
  __typename?: 'Mutation';
  resendVerifyEmail: { __typename?: 'ResendVerifyEmailOutput'; success: boolean };
};

export type VerifyEmailMutationVariables = Exact<{
  token: Scalars['String']['input'];
}>;

export type VerifyEmailMutation = {
  __typename?: 'Mutation';
  verifyEmail: { __typename?: 'VerifyEmailOutput'; accessToken: string };
};

export type ForgotPasswordMutationVariables = Exact<{
  email: Scalars['String']['input'];
}>;

export type ForgotPasswordMutation = {
  __typename?: 'Mutation';
  forgotPassword: { __typename?: 'ResetPasswordOutput'; success: boolean };
};

export type ResetPasswordMutationVariables = Exact<{
  resetPasswordToken: Scalars['String']['input'];
  password: Scalars['String']['input'];
}>;

export type ResetPasswordMutation = {
  __typename?: 'Mutation';
  resetPassword: { __typename?: 'LoginUserOutput'; accessToken: string };
};

export type FinishAuthIdMutationVariables = Exact<{
  authId: Scalars['String']['input'];
}>;

export type FinishAuthIdMutation = {
  __typename?: 'Mutation';
  finishAuthId: { __typename?: 'FinishAuthIdOutput'; success: boolean };
};

export type RefreshAccessTokenMutationVariables = Exact<{ [key: string]: never }>;

export type RefreshAccessTokenMutation = {
  __typename?: 'Mutation';
  refreshAccessToken: { __typename?: 'LoginUserOutput'; accessToken: string };
};

export type GetSignatureQueryVariables = Exact<{ [key: string]: never }>;

export type GetSignatureQuery = {
  __typename?: 'Query';
  getSignature: {
    __typename?: 'GetSignatureOutput';
    signature: string;
    talkJsAppId: string;
    talkJsId: string;
  };
};

export type GetUserSignatureQueryVariables = Exact<{
  userId: Scalars['Int']['input'];
}>;

export type GetUserSignatureQuery = {
  __typename?: 'Query';
  getUserSignature: {
    __typename?: 'GetSignatureOutput';
    signature: string;
    talkJsAppId: string;
    talkJsId: string;
  };
};

export type GetConversationByIdQueryVariables = Exact<{
  conversationId: Scalars['String']['input'];
}>;

export type GetConversationByIdQuery = {
  __typename?: 'Query';
  getConversationSignature: { __typename?: 'GetConversationSignatureOutput'; embedUrl: string };
};

export type GetConversationsQueryVariables = Exact<{
  filter?: InputMaybe<GetConversationsFilter>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  startingAfter?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<GetConversationsSort>;
}>;

export type GetConversationsQuery = {
  __typename?: 'Query';
  getConversations: {
    __typename?: 'GetConversationsOutput';
    conversations: Array<{
      __typename?: 'Conversation';
      createdAt: number;
      id: string;
      photoUrl?: string | null;
      subject?: string | null;
      topicId?: string | null;
      welcomeMessages?: Array<string> | null;
      custom: {
        __typename?: 'ConversationCustom';
        customerId?: string | null;
        participants?: string | null;
        serviceRequestId?: string | null;
        userIds?: string | null;
        zipCode?: string | null;
        chatType?: ChatTypeOptions | null;
        city?: string | null;
        environment: string;
        createdBy?: string | null;
        role?: GlobalRole | null;
      };
      lastMessage?: {
        __typename?: 'Message';
        conversationId: string;
        createdAt: number;
        editedAt?: number | null;
        id: string;
        location?: Array<number> | null;
        origin: string;
        readBy: Array<string>;
        referencedMessageId?: string | null;
        senderId?: string | null;
        text: string;
        type: string;
        attachment?: { __typename?: 'MessageAttachment'; url: string; size: number } | null;
      } | null;
      participants: Array<{
        __typename?: 'Participant';
        access: string;
        id: string;
        notify: boolean;
      }>;
      Participants?: Array<{
        __typename?: 'GraphqlUser';
        id: number;
        fullName?: string | null;
        role?: GlobalRole | null;
        createdAt: Date;
        updatedAt: Date;
      }> | null;
      CreatedBy?: {
        __typename?: 'GraphqlUser';
        id: number;
        fullName?: string | null;
        role?: GlobalRole | null;
        createdAt: Date;
        updatedAt: Date;
      } | null;
    }>;
  };
};

export type GetConversationQueryVariables = Exact<{
  conversationId: Scalars['String']['input'];
}>;

export type GetConversationQuery = {
  __typename?: 'Query';
  getConversation: {
    __typename?: 'Conversation';
    id: string;
    subject?: string | null;
    topicId?: string | null;
    photoUrl?: string | null;
    welcomeMessages?: Array<string> | null;
    createdAt: number;
    custom: {
      __typename?: 'ConversationCustom';
      customerId?: string | null;
      participants?: string | null;
      userIds?: string | null;
      serviceRequestId?: string | null;
      chatType?: ChatTypeOptions | null;
      city?: string | null;
      zipCode?: string | null;
      createdBy?: string | null;
      role?: GlobalRole | null;
    };
    lastMessage?: {
      __typename?: 'Message';
      conversationId: string;
      createdAt: number;
      editedAt?: number | null;
      id: string;
      location?: Array<number> | null;
      origin: string;
      readBy: Array<string>;
      senderId?: string | null;
      text: string;
      type: string;
      referencedMessageId?: string | null;
    } | null;
    participants: Array<{
      __typename?: 'Participant';
      id: string;
      access: string;
      notify: boolean;
    }>;
    CreatedBy?: {
      __typename?: 'GraphqlUser';
      id: number;
      email?: string | null;
      fullName?: string | null;
      talkJsId?: string | null;
      role?: GlobalRole | null;
    } | null;
    Participants?: Array<{
      __typename?: 'GraphqlUser';
      id: number;
      email?: string | null;
      fullName?: string | null;
      talkJsId?: string | null;
      role?: GlobalRole | null;
    }> | null;
  };
};

export type JoinUserToConversationMutationVariables = Exact<{
  userId: Scalars['Int']['input'];
  conversationId: Scalars['String']['input'];
}>;

export type JoinUserToConversationMutation = {
  __typename?: 'Mutation';
  joinUserToConversation: { __typename?: 'Conversation'; id: string };
};

export type RemoveParticipantMutationVariables = Exact<{
  talkJsId: Scalars['String']['input'];
  conversationId: Scalars['String']['input'];
}>;

export type RemoveParticipantMutation = {
  __typename?: 'Mutation';
  removeParticipant: { __typename?: 'Conversation'; id: string };
};

export type DeleteConversationMutationVariables = Exact<{
  conversationId: Scalars['String']['input'];
}>;

export type DeleteConversationMutation = {
  __typename?: 'Mutation';
  deleteConversation: {
    __typename?: 'DeleteConversationOutput';
    success: boolean;
    conversationId: string;
  };
};

export type GetConversationsByCustomerQueryVariables = Exact<{
  customerId: Scalars['Int']['input'];
}>;

export type GetConversationsByCustomerQuery = {
  __typename?: 'Query';
  getConversationsByCustomer: Array<{
    __typename?: 'Conversation';
    id: string;
    createdAt: number;
    photoUrl?: string | null;
    subject?: string | null;
    topicId?: string | null;
    welcomeMessages?: Array<string> | null;
    lastMessage?: {
      __typename?: 'Message';
      conversationId: string;
      createdAt: number;
      editedAt?: number | null;
      id: string;
      location?: Array<number> | null;
      origin: string;
      readBy: Array<string>;
      referencedMessageId?: string | null;
      senderId?: string | null;
      type: string;
      text: string;
      attachment?: { __typename?: 'MessageAttachment'; size: number; url: string } | null;
    } | null;
    custom: {
      __typename?: 'ConversationCustom';
      customerId?: string | null;
      participants?: string | null;
      userIds?: string | null;
    };
    participants: Array<{
      __typename?: 'Participant';
      access: string;
      id: string;
      notify: boolean;
    }>;
  }>;
};

export type StartConversationMutationVariables = Exact<{
  input: StartConversationInput;
}>;

export type StartConversationMutation = {
  __typename?: 'Mutation';
  startConversation: {
    __typename?: 'Conversation';
    id: string;
    subject?: string | null;
    topicId?: string | null;
    photoUrl?: string | null;
    welcomeMessages?: Array<string> | null;
    createdAt: number;
    custom: {
      __typename?: 'ConversationCustom';
      customerId?: string | null;
      participants?: string | null;
      userIds?: string | null;
      serviceRequestId?: string | null;
      zipCode?: string | null;
      chatType?: ChatTypeOptions | null;
      city?: string | null;
      role?: GlobalRole | null;
    };
    lastMessage?: { __typename?: 'Message'; text: string } | null;
    participants: Array<{ __typename?: 'Participant'; id: string; access: string }>;
  };
};

export type SyncCustomerChatMutationVariables = Exact<{
  customerId: Scalars['Float']['input'];
}>;

export type SyncCustomerChatMutation = {
  __typename?: 'Mutation';
  syncCustomerChat: { __typename?: 'SyncUserChatOutput'; success: boolean };
};

export type GetFlaggedMessagesQueryVariables = Exact<{ [key: string]: never }>;

export type GetFlaggedMessagesQuery = {
  __typename?: 'Query';
  getFlaggedMessages: Array<{
    __typename?: 'GraphqlFlaggedMessages';
    id: number;
    messageId: string;
    conversationId: string;
    messageBody: string;
    userId: number;
    User: { __typename?: 'GraphqlUser'; fullName?: string | null };
  }>;
};

export type GetCircleRequestsQueryVariables = Exact<{
  filter: CircleRequestFilter;
  sort?: InputMaybe<CircleRequestSortInput>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
}>;

export type GetCircleRequestsQuery = {
  __typename?: 'Query';
  getCircleRequests: {
    __typename?: 'GetCircleRequestsOutput';
    meta: { __typename?: 'PaginationMeta'; currentPage: number; totalCount: number };
    circleRequests: Array<{
      __typename?: 'GraphqlCircleRequest';
      id: number;
      category: CircleRequestCategoriesEnum;
      status: ServiceRequestStatus;
      scheduledAt?: Date | null;
      createdAt: Date;
      askTo: Array<number>;
      acceptedBy?: number | null;
      Customer: { __typename?: 'GetCustomerOutput'; id: number; fullName?: string | null };
      RequestedBy: { __typename?: 'GraphqlUser'; id: number; fullName?: string | null };
    }>;
  };
};

export type GetCircleRequestQueryVariables = Exact<{
  getCircleRequestId: Scalars['Float']['input'];
}>;

export type GetCircleRequestQuery = {
  __typename?: 'Query';
  getCircleRequest: {
    __typename?: 'GraphqlCircleRequest';
    acceptedAt?: Date | null;
    acceptedBy?: number | null;
    addressId?: number | null;
    category: CircleRequestCategoriesEnum;
    createdAt: Date;
    customerId: number;
    description?: string | null;
    id: number;
    isAvailabilityFlexible: boolean;
    recurring: boolean;
    frequency?: RecurringFrequencyType | null;
    otherCategory?: string | null;
    requestType: ServiceRequestType;
    requestedBy: number;
    scheduledAt?: Date | null;
    status: ServiceRequestStatus;
    tentativeSchedule: ServiceRequestTentativeSchedule;
    updatedAt: Date;
    Address?: {
      __typename?: 'GraphqlAddresses';
      addressLine1: string;
      addressLine2?: string | null;
      city: string;
      country: string;
      createdAt: Date;
      customerId: number;
      isDefault: boolean;
      id: number;
      isFromAPI: boolean;
      place?: string | null;
      updatedAt: Date;
      state: string;
      zipCode: string;
    } | null;
    Customer: {
      __typename?: 'GetCustomerOutput';
      birthday?: Date | null;
      createdAt: Date;
      createdBy: number;
      firstName?: string | null;
      fullName?: string | null;
      id: number;
      lastName?: string | null;
      updatedAt: Date;
      addresses?: Array<{
        __typename?: 'GraphqlAddresses';
        addressLine1: string;
        addressLine2?: string | null;
        city: string;
        id: number;
        isDefault: boolean;
        isFromAPI: boolean;
        state: string;
        updatedAt: Date;
        zipCode: string;
        customerId: number;
        createdAt: Date;
        country: string;
        place?: string | null;
      }> | null;
      users?: Array<{
        __typename?: 'UserGetCustomerOutput';
        user?: {
          __typename?: 'GraphqlUser';
          role?: GlobalRole | null;
          id: number;
          createdAt: Date;
          updatedAt: Date;
          email?: string | null;
          fullName?: string | null;
          phoneNumber?: string | null;
        } | null;
      }> | null;
    };
    RequestedBy: {
      __typename?: 'GraphqlUser';
      id: number;
      firstName?: string | null;
      fullName?: string | null;
      email?: string | null;
      emailVerified?: boolean | null;
      createdAt: Date;
      language?: string | null;
      lastLogin?: Date | null;
      lastName?: string | null;
      phoneNumber?: string | null;
      role?: GlobalRole | null;
      updatedAt: Date;
      webPushSubscription?: string | null;
      talkJsId?: string | null;
    };
    CreatedBy: { __typename?: 'GraphqlUser'; id: number; fullName?: string | null };
    availabilities: Array<{
      __typename?: 'ServiceRequestAvailability';
      date: Date;
      from: Date;
      until: Date;
    }>;
    ride?: {
      __typename?: 'Ride';
      type: RideType;
      waitTime?: string | null;
      dropoffAddress: {
        __typename?: 'GraphqlRideAddress';
        addressLine1: string;
        addressLine2?: string | null;
        city: string;
        country: string;
        isDefault?: boolean | null;
        state: string;
        zipCode: string;
        place?: string | null;
      };
      pickupAddress: {
        __typename?: 'GraphqlRideAddress';
        addressLine1: string;
        addressLine2?: string | null;
        city: string;
        country: string;
        isDefault?: boolean | null;
        state: string;
        zipCode: string;
        place?: string | null;
      };
      stops?: Array<{
        __typename?: 'GraphqlRideAddress';
        addressLine1: string;
        addressLine2?: string | null;
        city: string;
        country: string;
        isDefault?: boolean | null;
        state: string;
        zipCode: string;
        place?: string | null;
      }> | null;
    } | null;
    AskTo: Array<{
      __typename?: 'GraphqlUser';
      id: number;
      fullName?: string | null;
      createdAt: Date;
      updatedAt: Date;
    }>;
    AcceptedBy?: { __typename?: 'GraphqlUser'; id: number; fullName?: string | null } | null;
  };
};

export type CreateCircleRequestMutationVariables = Exact<{
  data: CreateCircleRequestInput;
}>;

export type CreateCircleRequestMutation = {
  __typename?: 'Mutation';
  createCircleRequest: { __typename?: 'GraphqlCircleRequest'; id: number };
};

export type UpdateCircleRequestMutationVariables = Exact<{
  data: UpdateCircleRequestInput;
  updateCircleRequestId: Scalars['Int']['input'];
}>;

export type UpdateCircleRequestMutation = {
  __typename?: 'Mutation';
  updateCircleRequest: { __typename?: 'GraphqlCircleRequest'; id: number };
};

export type GetCoachVisitsQueryVariables = Exact<{
  filter: CoachVisitFilter;
  sort?: InputMaybe<CoachVisitSortInput>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
}>;

export type GetCoachVisitsQuery = {
  __typename?: 'Query';
  getCoachVisits: {
    __typename?: 'GetCoachVisitsOutput';
    coachVisits: Array<{
      __typename?: 'GraphqlCoachVisit';
      id: number;
      scheduledAt?: Date | null;
      status: SocialEventStatus;
      Customer: { __typename?: 'GraphqlCustomer'; fullName?: string | null; id: number };
      Coach: { __typename?: 'GraphqlUser'; id: number; fullName?: string | null };
    }>;
    meta: {
      __typename?: 'PaginationMeta';
      totalCount: number;
      previousPage?: number | null;
      pageCount: number;
      nextPage?: number | null;
      isLastPage: boolean;
      isFirstPage: boolean;
      currentPage: number;
    };
  };
};

export type GetCoachVisitQueryVariables = Exact<{
  coachVisitId: Scalars['Int']['input'];
}>;

export type GetCoachVisitQuery = {
  __typename?: 'Query';
  getCoachVisit: {
    __typename?: 'GraphqlCoachVisit';
    id: number;
    lastStatusUpdate?: Date | null;
    recurring: boolean;
    requestType: ServiceRequestType;
    requestedBy: number;
    scheduledAt?: Date | null;
    status: SocialEventStatus;
    updatedAt: Date;
    frequency?: RecurringFrequencyType | null;
    description?: string | null;
    createdAt: Date;
    cancellationReason?: string | null;
    addressId?: number | null;
    RequestedBy: { __typename?: 'GraphqlUser'; id: number; fullName?: string | null };
    Customer: { __typename?: 'GraphqlCustomer'; id: number; fullName?: string | null };
    Coach: { __typename?: 'GraphqlUser'; id: number; fullName?: string | null };
    Address?: {
      __typename?: 'GraphqlAddresses';
      zipCode: string;
      updatedAt: Date;
      state: string;
      place?: string | null;
      isFromAPI: boolean;
      isDefault: boolean;
      id: number;
      customerId: number;
      createdAt: Date;
      country: string;
      city: string;
      addressLine2?: string | null;
      addressLine1: string;
    } | null;
    InternalNotes?: Array<{
      __typename?: 'GraphqlInternalNote';
      id: number;
      note: string;
      createdAt: Date;
      updatedAt: Date;
      userId: number;
      Users: {
        __typename?: 'GraphqlUser';
        id: number;
        fullName?: string | null;
        createdAt: Date;
        updatedAt: Date;
      };
    }> | null;
    VisitSummaries?: Array<{
      __typename?: 'GraphqlVisitSummary';
      id: number;
      body: string;
      createdAt: Date;
      CreatedBy?: { __typename?: 'GraphqlUser'; id: number; fullName?: string | null } | null;
      SentTo?: Array<{ __typename?: 'GraphqlUser'; id: number; fullName?: string | null }> | null;
    }> | null;
  };
};

export type CreateCoachVisitMutationVariables = Exact<{
  data: CreateCoachVisitInput;
}>;

export type CreateCoachVisitMutation = {
  __typename?: 'Mutation';
  createCoachVisit: { __typename?: 'GraphqlCoachVisit'; id: number };
};

export type UpdateCoachVisitMutationVariables = Exact<{
  data: UpdateCoachVisitInput;
  coachVisitId: Scalars['Int']['input'];
}>;

export type UpdateCoachVisitMutation = {
  __typename?: 'Mutation';
  updateCoachVisit: { __typename?: 'GraphqlCoachVisit'; id: number };
};

export type CreateVisitSummaryMutationVariables = Exact<{
  input: CreateVisitSummaryInput;
}>;

export type CreateVisitSummaryMutation = {
  __typename?: 'Mutation';
  createVisitSummary: {
    __typename?: 'GraphqlVisitSummary';
    id: number;
    body: string;
    coachVisitId: number;
  };
};

export type GetAllFeatureFlagsQueryVariables = Exact<{ [key: string]: never }>;

export type GetAllFeatureFlagsQuery = {
  __typename?: 'Query';
  getAllFeatureFlags: Array<{
    __typename?: 'GraphqlFeatureFlag';
    id: number;
    feature: string;
    enabled: boolean;
    description?: string | null;
  }>;
};

export type GetNotificationsByUserIdQueryVariables = Exact<{
  filter: NotificationsFilter;
  page?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  userId?: InputMaybe<Scalars['Int']['input']>;
}>;

export type GetNotificationsByUserIdQuery = {
  __typename?: 'Query';
  getNotificationsByUserId: {
    __typename?: 'GetNotificationsByUserIdOutput';
    notifications: Array<{
      __typename?: 'GraphqlNotification';
      id: number;
      text: string;
      emailSentAt?: Date | null;
      pushSentAt?: Date | null;
      smsSentAt?: Date | null;
      readAt?: Date | null;
      payload?: any | null;
      deeplink?: string | null;
      createdAt: Date;
      title?: string | null;
      type: string;
      updatedAt: Date;
    }>;
    meta: {
      __typename?: 'PaginationMeta';
      currentPage: number;
      isFirstPage: boolean;
      isLastPage: boolean;
      pageCount: number;
      totalCount: number;
    };
  };
};

export type CreateNotificationMutationVariables = Exact<{
  notification: CreateNotificationInput;
}>;

export type CreateNotificationMutation = {
  __typename?: 'Mutation';
  createNotification: { __typename?: 'NotificationOperationOutput'; success: boolean };
};

export type GetStripePaymentMethodsByUserQueryVariables = Exact<{
  userId: Scalars['Int']['input'];
}>;

export type GetStripePaymentMethodsByUserQuery = {
  __typename?: 'Query';
  getStripePaymentMethodsByUser: Array<{
    __typename?: 'StripeCardPaymentMethod';
    id: string;
    default?: boolean | null;
    created: number;
    type: string;
    card: {
      __typename?: 'GraphqlStripeCard';
      brand: string;
      country?: string | null;
      exp_month: number;
      exp_year: number;
      funding: string;
      last4: string;
    };
    billing_details: { __typename?: 'StripeBillingDetails'; name?: string | null };
  }>;
};

export type GetCompaniesQueryVariables = Exact<{
  sort?: InputMaybe<CompanySortInput>;
  filter?: InputMaybe<CompanyFilter>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
}>;

export type GetCompaniesQuery = {
  __typename?: 'Query';
  getCompanies: {
    __typename?: 'GetCompaniesOutput';
    companies: Array<{
      __typename?: 'GraphqlCompany';
      id: number;
      rating?: number | null;
      phoneNumber?: string | null;
      addressLine1: string;
      addressLine2?: string | null;
      city: string;
      state: string;
      zipCode: string;
      name: string;
      Agents: Array<{
        __typename?: 'GraphqlAgent';
        userId: number;
        rating?: number | null;
        internalRating?: number | null;
        serviceTypes: Array<ServiceRequestCategoriesEnum>;
        User: { __typename?: 'GraphqlUser'; id: number; fullName?: string | null };
      }>;
    }>;
    meta: { __typename?: 'PaginationMeta'; currentPage: number; totalCount: number };
  };
};

export type CreateCompanyMutationVariables = Exact<{
  input: CreateCompanyInput;
}>;

export type CreateCompanyMutation = {
  __typename?: 'Mutation';
  createCompany: { __typename?: 'GraphqlCompany'; id: number };
};

export type GetAgentsQueryVariables = Exact<{
  sort?: InputMaybe<AgentsSort>;
  filter?: InputMaybe<AgentsFilter>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
}>;

export type GetAgentsQuery = {
  __typename?: 'Query';
  getAgents: {
    __typename?: 'GetAgentsOutput';
    meta: {
      __typename?: 'PaginationMeta';
      currentPage: number;
      isFirstPage: boolean;
      isLastPage: boolean;
      previousPage?: number | null;
      nextPage?: number | null;
      pageCount: number;
      totalCount: number;
    };
    agents: Array<{
      __typename?: 'GraphqlAgent';
      userId: number;
      rating?: number | null;
      internalRating?: number | null;
      serviceTypes: Array<ServiceRequestCategoriesEnum>;
      preferedContactMethods: Array<AgentPreferedContactMethodEnum>;
      role: AgentRole;
      Company?: {
        __typename?: 'GraphqlCompany';
        id: number;
        name: string;
        phoneNumber?: string | null;
        website?: string | null;
        addressLine1: string;
        addressLine2?: string | null;
        city: string;
        state: string;
        zipCode: string;
        rating?: number | null;
        createdAt: Date;
        updatedAt: Date;
      } | null;
      User: {
        __typename?: 'GraphqlUser';
        id: number;
        email?: string | null;
        firstName?: string | null;
        lastName?: string | null;
        fullName?: string | null;
        phoneNumber?: string | null;
        createdAt: Date;
        updatedAt: Date;
      };
    }>;
  };
};

export type GetAgentsByServiceRequestQueryVariables = Exact<{
  serviceRequestId: Scalars['Int']['input'];
}>;

export type GetAgentsByServiceRequestQuery = {
  __typename?: 'Query';
  getAgentsByServiceRequest: {
    __typename?: 'GetAgentsByServiceRequestOutput';
    agents: Array<{
      __typename?: 'GetTopAgentsOutput';
      userId: number;
      rating?: number | null;
      internalRating?: number | null;
      serviceTypes: Array<ServiceRequestCategoriesEnum>;
      customerFavorite?: boolean | null;
      trustedFavorite?: boolean | null;
      trustedReviewed?: boolean | null;
      appointmentRequestSent: boolean;
      Company?: {
        __typename?: 'GraphqlCompany';
        id: number;
        name: string;
        phoneNumber?: string | null;
        website?: string | null;
        addressLine1: string;
        addressLine2?: string | null;
        city: string;
        state: string;
        zipCode: string;
        rating?: number | null;
        createdAt: Date;
        updatedAt: Date;
      } | null;
      User: {
        __typename?: 'GraphqlUser';
        id: number;
        email?: string | null;
        fullName?: string | null;
        phoneNumber?: string | null;
        createdAt: Date;
        updatedAt: Date;
      };
      ServiceRequestReviews?: Array<{
        __typename?: 'GraphqlServiceRequestReview';
        rating: number;
      }> | null;
    }>;
  };
};

export type GetAgentQueryVariables = Exact<{
  agentId: Scalars['Int']['input'];
}>;

export type GetAgentQuery = {
  __typename?: 'Query';
  getAgent: {
    __typename?: 'GraphqlAgent';
    userId: number;
    rating?: number | null;
    internalRating?: number | null;
    serviceTypes: Array<ServiceRequestCategoriesEnum>;
    companyId: number;
    preferedContactMethods: Array<AgentPreferedContactMethodEnum>;
    role: AgentRole;
    Company?: { __typename?: 'GraphqlCompany'; id: number; name: string } | null;
    User: {
      __typename?: 'GraphqlUser';
      id: number;
      firstName?: string | null;
      lastName?: string | null;
      email?: string | null;
      fullName?: string | null;
      phoneNumber?: string | null;
      createdAt: Date;
      updatedAt: Date;
      disabled?: boolean | null;
      emailVerified?: boolean | null;
      lastLogin?: Date | null;
      bio?: string | null;
      profilePicture?: string | null;
    };
  };
};

export type GetAgentReviewsQueryVariables = Exact<{
  agentId: Scalars['Int']['input'];
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<ReviewSortInput>;
}>;

export type GetAgentReviewsQuery = {
  __typename?: 'Query';
  getAgentReviews: {
    __typename?: 'GetAgentReviewsOutput';
    reviews: Array<{
      __typename?: 'GraphqlServiceRequestReview';
      comment?: string | null;
      rating: number;
      serviceRequestId: number;
      reviewedBy: number;
      createdAt: Date;
      updatedAt: Date;
      ReviewedBy: { __typename?: 'GraphqlUser'; fullName?: string | null; id: number };
    }>;
    meta: { __typename?: 'PaginationMeta'; currentPage: number; totalCount: number };
  };
};

export type GetCompanyQueryVariables = Exact<{
  companyId: Scalars['Float']['input'];
}>;

export type GetCompanyQuery = {
  __typename?: 'Query';
  getCompany: {
    __typename?: 'GraphqlCompany';
    id: number;
    name: string;
    phoneNumber?: string | null;
    hours?: string | null;
    website?: string | null;
    addressLine1: string;
    addressLine2?: string | null;
    city: string;
    state: string;
    zipCode: string;
    rating?: number | null;
    createdAt: Date;
    updatedAt: Date;
    serviceZipCodes?: Array<string> | null;
    Agents: Array<{
      __typename?: 'GraphqlAgent';
      userId: number;
      rating?: number | null;
      internalRating?: number | null;
      serviceTypes: Array<ServiceRequestCategoriesEnum>;
      companyId: number;
      preferedContactMethods: Array<AgentPreferedContactMethodEnum>;
      User: {
        __typename?: 'GraphqlUser';
        id: number;
        email?: string | null;
        firstName?: string | null;
        lastName?: string | null;
        fullName?: string | null;
        phoneNumber?: string | null;
        language?: string | null;
        talkJsId?: string | null;
        disabled?: boolean | null;
        emailVerified?: boolean | null;
        createdAt: Date;
        updatedAt: Date;
      };
    }>;
    StripeConnectAccounts?: {
      __typename?: 'GraphqlStripeConnectAccount';
      stripeAccountId: string;
    } | null;
  };
};

export type UpdateCompanyMutationVariables = Exact<{
  input: UpdateCompanyInput;
  companyId: Scalars['Float']['input'];
}>;

export type UpdateCompanyMutation = {
  __typename?: 'Mutation';
  updateCompany: { __typename?: 'GraphqlCompany'; id: number };
};

export type CreateAgentMutationVariables = Exact<{
  input: CreateAgentInput;
}>;

export type CreateAgentMutation = {
  __typename?: 'Mutation';
  createAgent: { __typename?: 'GraphqlAgent'; userId: number };
};

export type UpdateAgentMutationVariables = Exact<{
  input: UpdateAgentInput;
  agentId: Scalars['Int']['input'];
}>;

export type UpdateAgentMutation = {
  __typename?: 'Mutation';
  updateAgent: { __typename?: 'GraphqlAgent'; userId: number };
};

export type DeleteAgentMutationVariables = Exact<{
  deleteAgentId: Scalars['Int']['input'];
}>;

export type DeleteAgentMutation = {
  __typename?: 'Mutation';
  deleteAgent: { __typename?: 'DeleteAgentOutput'; success: boolean };
};

export type GetStripeConnectAccountQueryVariables = Exact<{ [key: string]: never }>;

export type GetStripeConnectAccountQuery = {
  __typename?: 'Query';
  getStripeConnectAccount?: {
    __typename?: 'GraphqlStripeConnectAccount';
    status: StripeConnectAccountStatus;
  } | null;
};

export type CreateConnectAccountOrLoginLinkMutationVariables = Exact<{ [key: string]: never }>;

export type CreateConnectAccountOrLoginLinkMutation = {
  __typename?: 'Mutation';
  createConnectAccountOrLoginLink: string;
};

export type ContinueConnectAccountOnboardingQueryVariables = Exact<{ [key: string]: never }>;

export type ContinueConnectAccountOnboardingQuery = {
  __typename?: 'Query';
  continueConnectAccountOnboarding: string;
};

export type GetServiceRequestsQueryVariables = Exact<{
  filter: ServiceRequestFilter;
  sort?: InputMaybe<ServiceRequestSortInput>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
}>;

export type GetServiceRequestsQuery = {
  __typename?: 'Query';
  getServiceRequests: {
    __typename?: 'GetServiceRequestsOutput';
    meta: { __typename?: 'PaginationMeta'; currentPage: number; totalCount: number };
    serviceRequests: Array<{
      __typename?: 'GraphqlServiceRequest';
      id: number;
      category: ServiceRequestCategoriesEnum;
      status: ServiceRequestStatus;
      scheduledAt?: Date | null;
      createdAt: Date;
      actionNeeded?: string | null;
      Customer: { __typename?: 'GraphqlCustomer'; id: number; fullName?: string | null };
      Agent?: {
        __typename?: 'GraphqlAgent';
        userId: number;
        deletedAt?: Date | null;
        User: { __typename?: 'GraphqlUser'; id: number; fullName?: string | null };
      } | null;
      RequestedBy: { __typename?: 'GraphqlUser'; id: number; fullName?: string | null };
      AppointmentRequests?: Array<{
        __typename?: 'GraphqlAppointmentRequest';
        id: number;
        scheduledAt?: Date | null;
        status: AppointmentRequestStatus;
      }> | null;
    }>;
  };
};

export type GetServiceRequestQueryVariables = Exact<{
  getServiceRequestId: Scalars['Float']['input'];
}>;

export type GetServiceRequestQuery = {
  __typename?: 'Query';
  getServiceRequest: {
    __typename?: 'GraphqlServiceRequest';
    acceptedAt?: Date | null;
    actionNeeded?: string | null;
    addressId?: number | null;
    agentId?: number | null;
    category: ServiceRequestCategoriesEnum;
    createdAt: Date;
    customerId: number;
    description?: string | null;
    id: number;
    isAvailabilityFlexible: boolean;
    recurring: boolean;
    frequency?: RecurringFrequencyType | null;
    otherCategory?: string | null;
    requestType: ServiceRequestType;
    requestedBy: number;
    requiresAction?: boolean | null;
    scheduledAt?: Date | null;
    status: ServiceRequestStatus;
    tentativeSchedule: ServiceRequestTentativeSchedule;
    updatedAt: Date;
    commentForBrya?: string | null;
    commentForCustomer?: string | null;
    Address?: {
      __typename?: 'GraphqlAddresses';
      addressLine1: string;
      addressLine2?: string | null;
      city: string;
      country: string;
      createdAt: Date;
      customerId: number;
      isDefault: boolean;
      id: number;
      isFromAPI: boolean;
      place?: string | null;
      updatedAt: Date;
      state: string;
      zipCode: string;
    } | null;
    Agent?: {
      __typename?: 'GraphqlAgent';
      companyId: number;
      rating?: number | null;
      serviceTypes: Array<ServiceRequestCategoriesEnum>;
      deletedAt?: Date | null;
      Company?: {
        __typename?: 'GraphqlCompany';
        city: string;
        addressLine1: string;
        createdAt: Date;
        addressLine2?: string | null;
        hours?: string | null;
        id: number;
        name: string;
        phoneNumber?: string | null;
        rating?: number | null;
        state: string;
        updatedAt: Date;
        website?: string | null;
        zipCode: string;
      } | null;
      User: {
        __typename?: 'GraphqlUser';
        id: number;
        fullName?: string | null;
        lastLogin?: Date | null;
      };
    } | null;
    Customer: {
      __typename?: 'GraphqlCustomer';
      birthday?: Date | null;
      createdAt: Date;
      createdBy: number;
      firstName?: string | null;
      fullName?: string | null;
      id: number;
      lastName?: string | null;
      updatedAt: Date;
      addresses?: Array<{
        __typename?: 'GraphqlAddresses';
        addressLine1: string;
        addressLine2?: string | null;
        city: string;
        country: string;
        createdAt: Date;
        customerId: number;
        id: number;
        isDefault: boolean;
        isFromAPI: boolean;
        place?: string | null;
        state: string;
        updatedAt: Date;
        zipCode: string;
      }> | null;
    };
    RequestedBy: {
      __typename?: 'GraphqlUser';
      id: number;
      firstName?: string | null;
      fullName?: string | null;
      email?: string | null;
      emailVerified?: boolean | null;
      createdAt: Date;
      language?: string | null;
      lastLogin?: Date | null;
      lastName?: string | null;
      phoneNumber?: string | null;
      role?: GlobalRole | null;
      updatedAt: Date;
      webPushSubscription?: string | null;
      talkJsId?: string | null;
    };
    CreatedBy: { __typename?: 'GraphqlUser'; id: number; fullName?: string | null };
    availabilities: Array<{
      __typename?: 'ServiceRequestAvailability';
      date: Date;
      from: Date;
      until: Date;
    }>;
    ride?: {
      __typename?: 'Ride';
      type: RideType;
      waitTime?: string | null;
      dropoffAddress: {
        __typename?: 'GraphqlRideAddress';
        addressLine1: string;
        addressLine2?: string | null;
        city: string;
        country: string;
        isDefault?: boolean | null;
        state: string;
        zipCode: string;
        place?: string | null;
      };
      pickupAddress: {
        __typename?: 'GraphqlRideAddress';
        addressLine1: string;
        addressLine2?: string | null;
        city: string;
        country: string;
        isDefault?: boolean | null;
        state: string;
        zipCode: string;
        place?: string | null;
      };
      stops?: Array<{
        __typename?: 'GraphqlRideAddress';
        addressLine1: string;
        addressLine2?: string | null;
        city: string;
        country: string;
        isDefault?: boolean | null;
        state: string;
        zipCode: string;
        place?: string | null;
      }> | null;
    } | null;
    InternalNotes?: Array<{
      __typename?: 'GraphqlInternalNote';
      createdAt: Date;
      id: number;
      note: string;
      updatedAt: Date;
      userId: number;
      Users: {
        __typename?: 'GraphqlUser';
        fullName?: string | null;
        id: number;
        createdAt: Date;
        updatedAt: Date;
      };
    }> | null;
    ServiceRequestPayments?: Array<{
      __typename?: 'GraphqlServiceRequestPayment';
      id: number;
      type: ServicePaymentType;
      amount: number;
      stripePaymentMethodId?: string | null;
      stripeTransactionId?: string | null;
      fee: number;
      tax: number;
      estimateHours?: number | null;
      createdAt: Date;
      notes?: string | null;
      updatedAt: Date;
    }> | null;
  };
};

export type GetStripeTransactionsQueryVariables = Exact<{
  filter: StripeTransactionFilter;
}>;

export type GetStripeTransactionsQuery = {
  __typename?: 'Query';
  getStripeTransactions: {
    __typename?: 'GetStripeTransactionOutput';
    transactions: Array<{
      __typename?: 'GraphqlStripeTransaction';
      status: string;
      amount: number;
      activityId: number;
      stripeTransactionId: string;
    }>;
  };
};

export type CreateServiceRequestMutationVariables = Exact<{
  data: CreateServiceRequestInput;
}>;

export type CreateServiceRequestMutation = {
  __typename?: 'Mutation';
  createServiceRequest: { __typename?: 'GraphqlServiceRequest'; id: number };
};

export type CreateAppointmentRequestServiceRequestMutationVariables = Exact<{
  userIds: Array<Scalars['Int']['input']> | Scalars['Int']['input'];
  serviceRequestId?: InputMaybe<Scalars['Int']['input']>;
}>;

export type CreateAppointmentRequestServiceRequestMutation = {
  __typename?: 'Mutation';
  createAppointmentRequest: Array<{
    __typename?: 'GraphqlAppointmentRequest';
    id: number;
    userId: number;
    serviceRequestId?: number | null;
    circleRequestId?: number | null;
    status: AppointmentRequestStatus;
    createdAt: Date;
    updatedAt: Date;
  }>;
};

export type UpdateServiceRequestMutationVariables = Exact<{
  data: UpdateServiceRequestInput;
  updateServiceRequestId: Scalars['Int']['input'];
}>;

export type UpdateServiceRequestMutation = {
  __typename?: 'Mutation';
  updateServiceRequest: { __typename?: 'GraphqlServiceRequest'; id: number };
};

export type GetSocialEventsQueryVariables = Exact<{
  filter: SocialEventFilter;
  sort?: InputMaybe<SocialEventSortInput>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
}>;

export type GetSocialEventsQuery = {
  __typename?: 'Query';
  getSocialEvents: {
    __typename?: 'GetSocialEventsOutput';
    meta: { __typename?: 'PaginationMeta'; currentPage: number; totalCount: number };
    socialEvents: Array<{
      __typename?: 'GraphqlSocialEvent';
      id: number;
      status: SocialEventStatus;
      scheduledAt?: Date | null;
      createdAt: Date;
      eventName: string;
      place?: string | null;
      addressLine1: string;
      addressLine2?: string | null;
      city: string;
      state: string;
      zipCode: string;
      country: string;
      RequestedBy: { __typename?: 'GraphqlUser'; id: number; fullName?: string | null };
      Coaches: Array<{
        __typename?: 'GraphqlCoaches';
        Coach?: { __typename?: 'GraphqlUser'; id: number; fullName?: string | null } | null;
      }>;
      Participants: Array<{
        __typename?: 'GraphqlParticipants';
        status: ServiceRequestStatus;
        id: number;
        Customer?: { __typename?: 'GraphqlCustomer'; fullName?: string | null } | null;
        ContactRequests: Array<{
          __typename?: 'GraphqlContactRequest';
          id: number;
          userMessage?: string | null;
          createdAt: Date;
          status: ContactRequestStatus;
          bryaMessage?: string | null;
          bryaInternalNotes?: string | null;
          updatedAt: Date;
        }>;
      }>;
    }>;
  };
};

export type GetSocialEventQueryVariables = Exact<{
  socialEventId: Scalars['Int']['input'];
}>;

export type GetSocialEventQuery = {
  __typename?: 'Query';
  getSocialEvent: {
    __typename?: 'GraphqlSocialEvent';
    id: number;
    createdAt: Date;
    eventName: string;
    description?: string | null;
    place?: string | null;
    addressLine1: string;
    addressLine2?: string | null;
    city: string;
    state: string;
    country: string;
    zipCode: string;
    status: SocialEventStatus;
    recurring: boolean;
    frequency?: RecurringFrequencyType | null;
    requestType: ServiceRequestType;
    scheduledAt?: Date | null;
    cancellationReason?: string | null;
    updatedAt: Date;
    RequestedBy: { __typename?: 'GraphqlUser'; id: number; fullName?: string | null };
    Coaches: Array<{
      __typename?: 'GraphqlCoaches';
      Coach?: { __typename?: 'GraphqlUser'; id: number; fullName?: string | null } | null;
    }>;
    Participants: Array<{
      __typename?: 'GraphqlParticipants';
      status: ServiceRequestStatus;
      Customer?: { __typename?: 'GraphqlCustomer'; id: number; fullName?: string | null } | null;
      ContactRequests: Array<{
        __typename?: 'GraphqlContactRequest';
        id: number;
        userMessage?: string | null;
        createdAt: Date;
        status: ContactRequestStatus;
        bryaMessage?: string | null;
        bryaInternalNotes?: string | null;
        updatedAt: Date;
      }>;
    }>;
  };
};

export type CreateSocialEventMutationVariables = Exact<{
  data: CreateSocialEventInput;
}>;

export type CreateSocialEventMutation = {
  __typename?: 'Mutation';
  createSocialEvent: { __typename?: 'GraphqlSocialEvent'; id: number };
};

export type UpdateSocialEventMutationVariables = Exact<{
  data: UpdateSocialEventInput;
  socialEventId: Scalars['Int']['input'];
}>;

export type UpdateSocialEventMutation = {
  __typename?: 'Mutation';
  updateSocialEvent: { __typename?: 'GraphqlSocialEvent'; id: number };
};

export type UpdateContactRequestMutationVariables = Exact<{
  updateContactRequestInput: UpdateContactRequestInput;
  contactRequestId: Scalars['Int']['input'];
}>;

export type UpdateContactRequestMutation = {
  __typename?: 'Mutation';
  updateContactRequest: { __typename?: 'GraphqlContactRequest'; id: number };
};

export type GetSuggestedActivitiesQueryVariables = Exact<{
  filter?: InputMaybe<SuggestedActivitiesFilter>;
  sort?: InputMaybe<SuggestedActivitiesSortInput>;
  page?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
}>;

export type GetSuggestedActivitiesQuery = {
  __typename?: 'Query';
  getSuggestedActivities: {
    __typename?: 'SuggestedActivitiesOutput';
    suggestedActivities: Array<{
      __typename?: 'GraphqlSuggestedActivities';
      id: number;
      name: string;
      description: string;
      createdAt: Date;
      notes?: string | null;
      role: SuggestedActivitiesRoleEnum;
      User: { __typename?: 'GraphqlUser'; id: number; fullName?: string | null };
    }>;
    meta: {
      __typename?: 'PaginationMeta';
      currentPage: number;
      isFirstPage: boolean;
      isLastPage: boolean;
      pageCount: number;
      totalCount: number;
    };
  };
};

export type UpdateSuggestedActivityMutationVariables = Exact<{
  updateSuggestedActivityInput: UpdateSuggestedActivityInput;
  suggestedActivityId: Scalars['Int']['input'];
}>;

export type UpdateSuggestedActivityMutation = {
  __typename?: 'Mutation';
  updateSuggestedActivity: { __typename?: 'GraphqlSuggestedActivities'; id: number };
};

export type GetDefaultTimezoneQueryVariables = Exact<{ [key: string]: never }>;

export type GetDefaultTimezoneQuery = {
  __typename?: 'Query';
  getDefaultTimezone: { __typename?: 'GetDefaultTimezone'; timezone: string };
};

export type GetUsersQueryVariables = Exact<{
  filter?: InputMaybe<UserFilter>;
  sort?: InputMaybe<UserSortInput>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
}>;

export type GetUsersQuery = {
  __typename?: 'Query';
  getUsers: {
    __typename?: 'GetUsersOutput';
    users: Array<{
      __typename?: 'GraphqlUser';
      id: number;
      firstName?: string | null;
      lastName?: string | null;
      fullName?: string | null;
      email?: string | null;
      role?: GlobalRole | null;
      createdAt: Date;
      disabled?: boolean | null;
      talkJsId?: string | null;
    }>;
    meta: {
      __typename?: 'PaginationMeta';
      currentPage: number;
      isFirstPage: boolean;
      isLastPage: boolean;
      nextPage?: number | null;
      pageCount: number;
      previousPage?: number | null;
      totalCount: number;
    };
  };
};

export type UserFieldsFragment = {
  __typename?: 'GraphqlUser';
  id: number;
  firstName?: string | null;
  lastName?: string | null;
  email?: string | null;
  fullName?: string | null;
  phoneNumber?: string | null;
  createdAt: Date;
  updatedAt: Date;
  defaultTab?: DefaultTab | null;
  talkJsId?: string | null;
  language?: string | null;
  disabled?: boolean | null;
  emailVerified?: boolean | null;
  role?: GlobalRole | null;
  lastLogin?: Date | null;
  bio?: string | null;
  profilePicture?: string | null;
  emergencyContact?: {
    __typename?: 'UserEmergencyContact';
    name: string;
    relation: string;
    phoneNumber: string;
  } | null;
};

export type GetUserQueryVariables = Exact<{
  getUserId: Scalars['Int']['input'];
}>;

export type GetUserQuery = {
  __typename?: 'Query';
  getUser: {
    __typename?: 'GraphqlUser';
    id: number;
    firstName?: string | null;
    lastName?: string | null;
    email?: string | null;
    fullName?: string | null;
    phoneNumber?: string | null;
    createdAt: Date;
    updatedAt: Date;
    defaultTab?: DefaultTab | null;
    talkJsId?: string | null;
    language?: string | null;
    disabled?: boolean | null;
    emailVerified?: boolean | null;
    role?: GlobalRole | null;
    lastLogin?: Date | null;
    bio?: string | null;
    profilePicture?: string | null;
    emergencyContact?: {
      __typename?: 'UserEmergencyContact';
      name: string;
      relation: string;
      phoneNumber: string;
    } | null;
  };
};

export type GetUserByChatProviderIdQueryVariables = Exact<{
  chatProviderId: Scalars['String']['input'];
}>;

export type GetUserByChatProviderIdQuery = {
  __typename?: 'Query';
  getUserByChatProviderId: {
    __typename?: 'GraphqlUser';
    id: number;
    firstName?: string | null;
    lastName?: string | null;
    email?: string | null;
    fullName?: string | null;
    phoneNumber?: string | null;
    createdAt: Date;
    updatedAt: Date;
    defaultTab?: DefaultTab | null;
    talkJsId?: string | null;
    language?: string | null;
    disabled?: boolean | null;
    emailVerified?: boolean | null;
    role?: GlobalRole | null;
    lastLogin?: Date | null;
    bio?: string | null;
    profilePicture?: string | null;
    emergencyContact?: {
      __typename?: 'UserEmergencyContact';
      name: string;
      relation: string;
      phoneNumber: string;
    } | null;
  };
};

export type DisableUserMutationVariables = Exact<{
  disableUserId: Scalars['Int']['input'];
}>;

export type DisableUserMutation = {
  __typename?: 'Mutation';
  disableUser: { __typename?: 'GraphqlUser'; disabled?: boolean | null; id: number };
};

export type EnableUserMutationVariables = Exact<{
  enableUserId: Scalars['Int']['input'];
}>;

export type EnableUserMutation = {
  __typename?: 'Mutation';
  enableUser: { __typename?: 'GraphqlUser'; disabled?: boolean | null; id: number };
};

export type AdminCreateUserMutationVariables = Exact<{
  adminCreateUserInput: AdminCreateUserInput;
}>;

export type AdminCreateUserMutation = {
  __typename?: 'Mutation';
  adminCreateUser: {
    __typename?: 'GraphqlUser';
    id: number;
    firstName?: string | null;
    lastName?: string | null;
    email?: string | null;
    fullName?: string | null;
    createdAt: Date;
    updatedAt: Date;
    disabled?: boolean | null;
    role?: GlobalRole | null;
  };
};

export type AdminUpdateUserMutationVariables = Exact<{
  adminUpdateUserInput: AdminUpdateUserInput;
  adminUpdateUserId: Scalars['Int']['input'];
}>;

export type AdminUpdateUserMutation = {
  __typename?: 'Mutation';
  adminUpdateUser: {
    __typename?: 'GraphqlUser';
    id: number;
    firstName?: string | null;
    lastName?: string | null;
    email?: string | null;
    fullName?: string | null;
    phoneNumber?: string | null;
    createdAt: Date;
    updatedAt: Date;
    defaultTab?: DefaultTab | null;
    talkJsId?: string | null;
    language?: string | null;
    disabled?: boolean | null;
    emailVerified?: boolean | null;
    role?: GlobalRole | null;
  };
};

export type GetCustomersQueryVariables = Exact<{
  filters: FiltersInput;
}>;

export type GetCustomersQuery = {
  __typename?: 'Query';
  getCustomers: Array<{
    __typename?: 'GetCustomerOutput';
    id: number;
    updatedAt: Date;
    createdBy: number;
    createdAt: Date;
    firstName?: string | null;
    lastName?: string | null;
    fullName?: string | null;
    phoneNumber?: string | null;
    addresses?: Array<{
      __typename?: 'GraphqlAddresses';
      addressLine1: string;
      addressLine2?: string | null;
      city: string;
      id: number;
      isDefault: boolean;
      isFromAPI: boolean;
      state: string;
      updatedAt: Date;
      zipCode: string;
      customerId: number;
      createdAt: Date;
      country: string;
      place?: string | null;
    }> | null;
    users?: Array<{
      __typename?: 'UserGetCustomerOutput';
      role: string;
      user?: {
        __typename?: 'GraphqlUser';
        id: number;
        createdAt: Date;
        updatedAt: Date;
        email?: string | null;
        fullName?: string | null;
        phoneNumber?: string | null;
      } | null;
    }> | null;
  }>;
};

export type GetCustomerQueryVariables = Exact<{
  customerId: Scalars['Float']['input'];
}>;

export type GetCustomerQuery = {
  __typename?: 'Query';
  getCustomer: {
    __typename?: 'GetCustomerOutput';
    id: number;
    firstName?: string | null;
    lastName?: string | null;
    fullName?: string | null;
    createdBy: number;
    createdAt: Date;
    updatedAt: Date;
    Creator?: {
      __typename?: 'GraphqlUser';
      fullName?: string | null;
      id: number;
      firstName?: string | null;
      lastName?: string | null;
      AppFeaturesSelected?: {
        __typename?: 'GraphqlAppFeaturesSelected';
        circleMember: boolean;
        familyPartner: boolean;
        id: number;
        seniorMember: boolean;
        userId: number;
      } | null;
    } | null;
    addresses?: Array<{
      __typename?: 'GraphqlAddresses';
      id: number;
      addressLine1: string;
      addressLine2?: string | null;
      city: string;
      customerId: number;
      isDefault: boolean;
      state: string;
      zipCode: string;
    }> | null;
    users?: Array<{
      __typename?: 'UserGetCustomerOutput';
      role: string;
      user?: {
        __typename?: 'GraphqlUser';
        id: number;
        fullName?: string | null;
        createdAt: Date;
      } | null;
    }> | null;
    CustomerAdditionalInformation?: {
      __typename?: 'GraphqlCustomerAdditionalInformation';
      customerId: number;
      gender?: string | null;
      age?: number | null;
      retiredTime?: number | null;
      hobbies?: string | null;
      professionalBackground?: string | null;
      educationalBackground?: string | null;
      relationshipStatus?: string | null;
      otherInformation?: string | null;
      city?: string | null;
      state?: string | null;
      zipCode: string;
    } | null;
  };
};

export type UpdateCustomerMutationVariables = Exact<{
  updateCustomerInput: UpdateCustomerInput;
  customerId: Scalars['Int']['input'];
}>;

export type UpdateCustomerMutation = {
  __typename?: 'Mutation';
  updateCustomer: {
    __typename?: 'GraphqlCustomer';
    id: number;
    updatedAt: Date;
    createdAt: Date;
    createdBy: number;
    fullName?: string | null;
  };
};

export type GetCustomerTimelineQueryVariables = Exact<{
  orderIn: SortOrder;
  customerId: Scalars['Int']['input'];
}>;

export type GetCustomerTimelineQuery = {
  __typename?: 'Query';
  getTimeline: Array<{ __typename?: 'GraphqlTimeline'; createdAt: Date; description: string }>;
};

export type GetLeadsQueryVariables = Exact<{
  filter?: InputMaybe<UserFilter>;
  sort?: InputMaybe<UserSortInput>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
}>;

export type GetLeadsQuery = {
  __typename?: 'Query';
  getUsers: {
    __typename?: 'GetUsersOutput';
    users: Array<{
      __typename?: 'GraphqlUser';
      id: number;
      firstName?: string | null;
      lastName?: string | null;
      fullName?: string | null;
      email?: string | null;
      createdAt: Date;
      lastLogin?: Date | null;
    }>;
    meta: {
      __typename?: 'PaginationMeta';
      currentPage: number;
      isFirstPage: boolean;
      isLastPage: boolean;
      nextPage?: number | null;
      pageCount: number;
      previousPage?: number | null;
      totalCount: number;
    };
  };
};

export type CreateAddressMutationVariables = Exact<{
  createAddressInput: CreateAddressInput;
}>;

export type CreateAddressMutation = {
  __typename?: 'Mutation';
  createAddress: { __typename?: 'GraphqlAddresses'; id: number };
};

export type UpdateAddressMutationVariables = Exact<{
  updateAddressInput: UpdateAddressInput;
  updateAddressId: Scalars['Int']['input'];
}>;

export type UpdateAddressMutation = {
  __typename?: 'Mutation';
  updateAddress: { __typename?: 'GraphqlAddresses'; id: number };
};

export type DeleteAddressMutationVariables = Exact<{
  deleteAddressId: Scalars['Int']['input'];
}>;

export type DeleteAddressMutation = {
  __typename?: 'Mutation';
  deleteAddress: { __typename?: 'DeleteAddressOutput'; success: boolean };
};

export type GetCoachReviewsQueryVariables = Exact<{
  coachId: Scalars['Int']['input'];
  sort?: InputMaybe<ReviewSortInput>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
}>;

export type GetCoachReviewsQuery = {
  __typename?: 'Query';
  getCoachReviews: {
    __typename?: 'GetCoachReviewsOutput';
    reviews: Array<{
      __typename?: 'GraphqlCoachReview';
      id: number;
      comment?: string | null;
      rating: number;
      coachId: number;
      reviewedBy: number;
      createdAt: Date;
      updatedAt: Date;
      ReviewedBy: { __typename?: 'GraphqlUser'; fullName?: string | null; id: number };
    }>;
    meta: { __typename?: 'PaginationMeta'; currentPage: number; totalCount: number };
  };
};

export type DeleteCoachReviewMutationVariables = Exact<{
  reviewId: Scalars['Int']['input'];
}>;

export type DeleteCoachReviewMutation = {
  __typename?: 'Mutation';
  deleteCoachReview: { __typename?: 'DeleteReviewOutput'; success: boolean; reviewId: number };
};

export type GetEmployeeInternalNotesQueryVariables = Exact<{
  pagination: PaginationInput;
  sort?: InputMaybe<InternalNotesSortOptions>;
  filter?: InputMaybe<InternalNotesFilterOptions>;
}>;

export type GetEmployeeInternalNotesQuery = {
  __typename?: 'Query';
  getInternalNotes: {
    __typename?: 'GetInternalNotesOutput';
    results: Array<{
      __typename?: 'GraphqlInternalNote';
      id: number;
      note: string;
      createdAt: Date;
      updatedAt: Date;
      userId: number;
      employeeId?: number | null;
      Users: {
        __typename?: 'GraphqlUser';
        id: number;
        createdAt: Date;
        updatedAt: Date;
        fullName?: string | null;
      };
      Employee?: {
        __typename?: 'GraphqlUser';
        id: number;
        createdAt: Date;
        updatedAt: Date;
        fullName?: string | null;
      } | null;
    }>;
  };
};

export type GetFilesQueryVariables = Exact<{
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<FilesSort>;
  filter?: InputMaybe<FilesFilter>;
}>;

export type GetFilesQuery = {
  __typename?: 'Query';
  getFiles: {
    __typename?: 'GetFilesOutput';
    files: Array<{
      __typename?: 'GraphqlFile';
      id: number;
      name: string;
      type: string;
      size: number;
      key: string;
      uploadBy?: number | null;
      userId?: number | null;
      agentId?: number | null;
      customerId?: number | null;
      createdAt: Date;
      updatedAt: Date;
      downloadUrl?: string | null;
      UploadBy?: { __typename?: 'GraphqlUser'; fullName?: string | null } | null;
    }>;
    meta: { __typename?: 'PaginationMeta'; currentPage: number; totalCount: number };
  };
};

export type AssignCoachMutationVariables = Exact<{
  customerId: Scalars['Int']['input'];
  userId: Scalars['Int']['input'];
}>;

export type AssignCoachMutation = {
  __typename?: 'Mutation';
  assignCoach: {
    __typename?: 'GraphqlUserRole';
    id: number;
    userId: number;
    customerId?: number | null;
    role: Role;
    Users?: {
      __typename?: 'GraphqlUser';
      id: number;
      firstName?: string | null;
      lastName?: string | null;
      email?: string | null;
      fullName?: string | null;
      phoneNumber?: string | null;
      createdAt: Date;
      updatedAt: Date;
      defaultTab?: DefaultTab | null;
      talkJsId?: string | null;
      language?: string | null;
      disabled?: boolean | null;
      emailVerified?: boolean | null;
      role?: GlobalRole | null;
      lastLogin?: Date | null;
      webPushSubscription?: string | null;
      bio?: string | null;
      profilePicture?: string | null;
      firebaseToken?: string | null;
    } | null;
  };
};

export type GetCustomerInternalNotesQueryVariables = Exact<{
  customerId: Scalars['Int']['input'];
  pagination: PaginationInput;
}>;

export type GetCustomerInternalNotesQuery = {
  __typename?: 'Query';
  getCustomerInternalNotes: {
    __typename?: 'GetInternalNotesOutput';
    meta: {
      __typename?: 'PaginationMeta';
      currentPage: number;
      isFirstPage: boolean;
      isLastPage: boolean;
      previousPage?: number | null;
      nextPage?: number | null;
      pageCount: number;
      totalCount: number;
    };
    results: Array<{
      __typename?: 'GraphqlInternalNote';
      note: string;
      serviceRequestId?: number | null;
      coachVisitId?: number | null;
      userId: number;
      createdAt: Date;
      updatedAt: Date;
      id: number;
      Users: {
        __typename?: 'GraphqlUser';
        id: number;
        createdAt: Date;
        updatedAt: Date;
        fullName?: string | null;
      };
    }>;
  };
};

export type GetUsersFullNamesQueryVariables = Exact<{
  userIds: Array<Scalars['Int']['input']> | Scalars['Int']['input'];
}>;

export type GetUsersFullNamesQuery = {
  __typename?: 'Query';
  getUsersFullNames: Array<{ __typename?: 'UserWithFullName'; userId: number; fullName: string }>;
};

export type GetUserNotificationSettingsByUserIdQueryVariables = Exact<{
  userId: Scalars['Int']['input'];
  notificationType: Scalars['String']['input'];
}>;

export type GetUserNotificationSettingsByUserIdQuery = {
  __typename?: 'Query';
  getUserNotificationSettingsByUserId: {
    __typename?: 'GraphqlNotificationSettings';
    id: number;
    userId: number;
    notificationType: string;
    email: boolean;
    sms: boolean;
    push: boolean;
    pushWeb: boolean;
    createdAt: Date;
  };
};

export type CreateWebPushSubscriptionMutationVariables = Exact<{
  webPushSubscription: WebPushSubscription;
}>;

export type CreateWebPushSubscriptionMutation = {
  __typename?: 'Mutation';
  createWebPushSubscription: boolean;
};

export type DestroyWebPushSubscriptionMutationVariables = Exact<{
  data: UnsubscribeInput;
}>;

export type DestroyWebPushSubscriptionMutation = {
  __typename?: 'Mutation';
  destroyWebPushSubscription: boolean;
};

export const UserFieldsFragmentDoc = gql`
  fragment UserFields on GraphqlUser {
    id
    firstName
    lastName
    email
    fullName
    phoneNumber
    createdAt
    updatedAt
    defaultTab
    talkJsId
    language
    disabled
    emailVerified
    role
    lastLogin
    bio
    profilePicture
    emergencyContact {
      name
      relation
      phoneNumber
    }
  }
`;
export const GetActivityTimelineDocument = gql`
  query GetActivityTimeline($orderIn: SortOrder!, $activityId: Int!) {
    getTimeline(orderIn: $orderIn, activityId: $activityId) {
      createdAt
      description
    }
  }
`;

/**
 * __useGetActivityTimelineQuery__
 *
 * To run a query within a React component, call `useGetActivityTimelineQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetActivityTimelineQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetActivityTimelineQuery({
 *   variables: {
 *      orderIn: // value for 'orderIn'
 *      activityId: // value for 'activityId'
 *   },
 * });
 */
export function useGetActivityTimelineQuery(
  baseOptions: Apollo.QueryHookOptions<GetActivityTimelineQuery, GetActivityTimelineQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetActivityTimelineQuery, GetActivityTimelineQueryVariables>(
    GetActivityTimelineDocument,
    options,
  );
}
export function useGetActivityTimelineLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetActivityTimelineQuery,
    GetActivityTimelineQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetActivityTimelineQuery, GetActivityTimelineQueryVariables>(
    GetActivityTimelineDocument,
    options,
  );
}
export type GetActivityTimelineQueryHookResult = ReturnType<typeof useGetActivityTimelineQuery>;
export type GetActivityTimelineLazyQueryHookResult = ReturnType<
  typeof useGetActivityTimelineLazyQuery
>;
export type GetActivityTimelineQueryResult = Apollo.QueryResult<
  GetActivityTimelineQuery,
  GetActivityTimelineQueryVariables
>;
export const GetAppointmentRequestsDocument = gql`
  query GetAppointmentRequests($circleRequestId: Int, $serviceRequestId: Int) {
    getAppointmentRequests(circleRequestId: $circleRequestId, serviceRequestId: $serviceRequestId) {
      id
      userId
      serviceRequestId
      circleRequestId
      status
      createdAt
      updatedAt
      scheduledAt
      CreatedBy {
        id
        fullName
      }
      SentTo {
        id
        fullName
        Agent {
          deletedAt
        }
      }
      ServiceRequest {
        id
        availabilities {
          date
          from
          until
        }
        isAvailabilityFlexible
        tentativeSchedule
      }
      CircleRequest {
        id
        availabilities {
          date
          from
          until
        }
        isAvailabilityFlexible
        tentativeSchedule
      }
      AppointmentRequestPayments {
        id
        type
        amount
        estimateHours
        appointmentRequestId
      }
    }
  }
`;

/**
 * __useGetAppointmentRequestsQuery__
 *
 * To run a query within a React component, call `useGetAppointmentRequestsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAppointmentRequestsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAppointmentRequestsQuery({
 *   variables: {
 *      circleRequestId: // value for 'circleRequestId'
 *      serviceRequestId: // value for 'serviceRequestId'
 *   },
 * });
 */
export function useGetAppointmentRequestsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetAppointmentRequestsQuery,
    GetAppointmentRequestsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetAppointmentRequestsQuery, GetAppointmentRequestsQueryVariables>(
    GetAppointmentRequestsDocument,
    options,
  );
}
export function useGetAppointmentRequestsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetAppointmentRequestsQuery,
    GetAppointmentRequestsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetAppointmentRequestsQuery, GetAppointmentRequestsQueryVariables>(
    GetAppointmentRequestsDocument,
    options,
  );
}
export type GetAppointmentRequestsQueryHookResult = ReturnType<
  typeof useGetAppointmentRequestsQuery
>;
export type GetAppointmentRequestsLazyQueryHookResult = ReturnType<
  typeof useGetAppointmentRequestsLazyQuery
>;
export type GetAppointmentRequestsQueryResult = Apollo.QueryResult<
  GetAppointmentRequestsQuery,
  GetAppointmentRequestsQueryVariables
>;
export const GetAppointmentRequestsFilteredDocument = gql`
  query GetAppointmentRequestsFiltered(
    $filter: AppointmentRequestFilter!
    $sort: AppointmentRequestSortInput
    $pageSize: Int
    $page: Int
  ) {
    getAppointmentRequestsFiltered(filter: $filter, sort: $sort, pageSize: $pageSize, page: $page) {
      appointmentRequests {
        id
        userId
        serviceRequestId
        circleRequestId
        status
        createdAt
        updatedAt
        scheduledAt
        CreatedBy {
          id
          fullName
        }
        SentTo {
          id
          fullName
        }
        ServiceRequest {
          id
          category
          status
          scheduledAt
          createdAt
          actionNeeded
          Customer {
            id
            fullName
          }
          Agent {
            userId
            deletedAt
            User {
              id
              fullName
            }
          }
          RequestedBy {
            id
            fullName
          }
          availabilities {
            date
            from
            until
          }
          isAvailabilityFlexible
        }
        AppointmentRequestPayments {
          id
          type
          amount
          estimateHours
          appointmentRequestId
        }
      }
      meta {
        currentPage
        isFirstPage
        isLastPage
        nextPage
        pageCount
        previousPage
        totalCount
      }
    }
  }
`;

/**
 * __useGetAppointmentRequestsFilteredQuery__
 *
 * To run a query within a React component, call `useGetAppointmentRequestsFilteredQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAppointmentRequestsFilteredQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAppointmentRequestsFilteredQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      sort: // value for 'sort'
 *      pageSize: // value for 'pageSize'
 *      page: // value for 'page'
 *   },
 * });
 */
export function useGetAppointmentRequestsFilteredQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetAppointmentRequestsFilteredQuery,
    GetAppointmentRequestsFilteredQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetAppointmentRequestsFilteredQuery,
    GetAppointmentRequestsFilteredQueryVariables
  >(GetAppointmentRequestsFilteredDocument, options);
}
export function useGetAppointmentRequestsFilteredLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetAppointmentRequestsFilteredQuery,
    GetAppointmentRequestsFilteredQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetAppointmentRequestsFilteredQuery,
    GetAppointmentRequestsFilteredQueryVariables
  >(GetAppointmentRequestsFilteredDocument, options);
}
export type GetAppointmentRequestsFilteredQueryHookResult = ReturnType<
  typeof useGetAppointmentRequestsFilteredQuery
>;
export type GetAppointmentRequestsFilteredLazyQueryHookResult = ReturnType<
  typeof useGetAppointmentRequestsFilteredLazyQuery
>;
export type GetAppointmentRequestsFilteredQueryResult = Apollo.QueryResult<
  GetAppointmentRequestsFilteredQuery,
  GetAppointmentRequestsFilteredQueryVariables
>;
export const UpdateAppointmentRequestDocument = gql`
  mutation UpdateAppointmentRequest(
    $updateAppointmentRequestInput: UpdateAppointmentRequestInput!
  ) {
    updateAppointmentRequest(updateAppointmentRequestInput: $updateAppointmentRequestInput) {
      id
    }
  }
`;
export type UpdateAppointmentRequestMutationFn = Apollo.MutationFunction<
  UpdateAppointmentRequestMutation,
  UpdateAppointmentRequestMutationVariables
>;

/**
 * __useUpdateAppointmentRequestMutation__
 *
 * To run a mutation, you first call `useUpdateAppointmentRequestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAppointmentRequestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAppointmentRequestMutation, { data, loading, error }] = useUpdateAppointmentRequestMutation({
 *   variables: {
 *      updateAppointmentRequestInput: // value for 'updateAppointmentRequestInput'
 *   },
 * });
 */
export function useUpdateAppointmentRequestMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateAppointmentRequestMutation,
    UpdateAppointmentRequestMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateAppointmentRequestMutation,
    UpdateAppointmentRequestMutationVariables
  >(UpdateAppointmentRequestDocument, options);
}
export type UpdateAppointmentRequestMutationHookResult = ReturnType<
  typeof useUpdateAppointmentRequestMutation
>;
export type UpdateAppointmentRequestMutationResult =
  Apollo.MutationResult<UpdateAppointmentRequestMutation>;
export type UpdateAppointmentRequestMutationOptions = Apollo.BaseMutationOptions<
  UpdateAppointmentRequestMutation,
  UpdateAppointmentRequestMutationVariables
>;
export const CreateInternalNoteDocument = gql`
  mutation CreateInternalNote($input: CreateInternalNoteInput!) {
    createInternalNote(input: $input) {
      userId
      updatedAt
      serviceRequestId
      note
      id
      createdAt
      Users {
        id
        updatedAt
        createdAt
        fullName
      }
    }
  }
`;
export type CreateInternalNoteMutationFn = Apollo.MutationFunction<
  CreateInternalNoteMutation,
  CreateInternalNoteMutationVariables
>;

/**
 * __useCreateInternalNoteMutation__
 *
 * To run a mutation, you first call `useCreateInternalNoteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateInternalNoteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createInternalNoteMutation, { data, loading, error }] = useCreateInternalNoteMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateInternalNoteMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateInternalNoteMutation,
    CreateInternalNoteMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateInternalNoteMutation, CreateInternalNoteMutationVariables>(
    CreateInternalNoteDocument,
    options,
  );
}
export type CreateInternalNoteMutationHookResult = ReturnType<typeof useCreateInternalNoteMutation>;
export type CreateInternalNoteMutationResult = Apollo.MutationResult<CreateInternalNoteMutation>;
export type CreateInternalNoteMutationOptions = Apollo.BaseMutationOptions<
  CreateInternalNoteMutation,
  CreateInternalNoteMutationVariables
>;
export const UpdateInternalNoteDocument = gql`
  mutation UpdateInternalNote($input: CreateInternalNoteInput!, $updateInternalNoteId: Float!) {
    updateInternalNote(input: $input, id: $updateInternalNoteId) {
      createdAt
      id
      note
      serviceRequestId
      updatedAt
      userId
      Users {
        id
        fullName
        createdAt
        updatedAt
      }
    }
  }
`;
export type UpdateInternalNoteMutationFn = Apollo.MutationFunction<
  UpdateInternalNoteMutation,
  UpdateInternalNoteMutationVariables
>;

/**
 * __useUpdateInternalNoteMutation__
 *
 * To run a mutation, you first call `useUpdateInternalNoteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateInternalNoteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateInternalNoteMutation, { data, loading, error }] = useUpdateInternalNoteMutation({
 *   variables: {
 *      input: // value for 'input'
 *      updateInternalNoteId: // value for 'updateInternalNoteId'
 *   },
 * });
 */
export function useUpdateInternalNoteMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateInternalNoteMutation,
    UpdateInternalNoteMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateInternalNoteMutation, UpdateInternalNoteMutationVariables>(
    UpdateInternalNoteDocument,
    options,
  );
}
export type UpdateInternalNoteMutationHookResult = ReturnType<typeof useUpdateInternalNoteMutation>;
export type UpdateInternalNoteMutationResult = Apollo.MutationResult<UpdateInternalNoteMutation>;
export type UpdateInternalNoteMutationOptions = Apollo.BaseMutationOptions<
  UpdateInternalNoteMutation,
  UpdateInternalNoteMutationVariables
>;
export const ListRequestCategoriesDocument = gql`
  query ListRequestCategories($serviceRequest: Boolean, $circleRequest: Boolean) {
    listRequestCategories(serviceRequest: $serviceRequest, circleRequest: $circleRequest) {
      id
      name
      circleRequest
      serviceRequest
      createdAt
      updatedAt
    }
  }
`;

/**
 * __useListRequestCategoriesQuery__
 *
 * To run a query within a React component, call `useListRequestCategoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useListRequestCategoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListRequestCategoriesQuery({
 *   variables: {
 *      serviceRequest: // value for 'serviceRequest'
 *      circleRequest: // value for 'circleRequest'
 *   },
 * });
 */
export function useListRequestCategoriesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    ListRequestCategoriesQuery,
    ListRequestCategoriesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ListRequestCategoriesQuery, ListRequestCategoriesQueryVariables>(
    ListRequestCategoriesDocument,
    options,
  );
}
export function useListRequestCategoriesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ListRequestCategoriesQuery,
    ListRequestCategoriesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ListRequestCategoriesQuery, ListRequestCategoriesQueryVariables>(
    ListRequestCategoriesDocument,
    options,
  );
}
export type ListRequestCategoriesQueryHookResult = ReturnType<typeof useListRequestCategoriesQuery>;
export type ListRequestCategoriesLazyQueryHookResult = ReturnType<
  typeof useListRequestCategoriesLazyQuery
>;
export type ListRequestCategoriesQueryResult = Apollo.QueryResult<
  ListRequestCategoriesQuery,
  ListRequestCategoriesQueryVariables
>;
export const CreateAppointmentRequestPaymentDocument = gql`
  mutation CreateAppointmentRequestPayment(
    $appointmentPaymentInput: CreateAppointmentPaymentInput!
  ) {
    createAppointmentRequestPayment(appointmentPaymentInput: $appointmentPaymentInput) {
      id
    }
  }
`;
export type CreateAppointmentRequestPaymentMutationFn = Apollo.MutationFunction<
  CreateAppointmentRequestPaymentMutation,
  CreateAppointmentRequestPaymentMutationVariables
>;

/**
 * __useCreateAppointmentRequestPaymentMutation__
 *
 * To run a mutation, you first call `useCreateAppointmentRequestPaymentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateAppointmentRequestPaymentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createAppointmentRequestPaymentMutation, { data, loading, error }] = useCreateAppointmentRequestPaymentMutation({
 *   variables: {
 *      appointmentPaymentInput: // value for 'appointmentPaymentInput'
 *   },
 * });
 */
export function useCreateAppointmentRequestPaymentMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateAppointmentRequestPaymentMutation,
    CreateAppointmentRequestPaymentMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateAppointmentRequestPaymentMutation,
    CreateAppointmentRequestPaymentMutationVariables
  >(CreateAppointmentRequestPaymentDocument, options);
}
export type CreateAppointmentRequestPaymentMutationHookResult = ReturnType<
  typeof useCreateAppointmentRequestPaymentMutation
>;
export type CreateAppointmentRequestPaymentMutationResult =
  Apollo.MutationResult<CreateAppointmentRequestPaymentMutation>;
export type CreateAppointmentRequestPaymentMutationOptions = Apollo.BaseMutationOptions<
  CreateAppointmentRequestPaymentMutation,
  CreateAppointmentRequestPaymentMutationVariables
>;
export const UpdateAppointmentRequestPaymentDocument = gql`
  mutation UpdateAppointmentRequestPayment(
    $appointmentRequestPaymentId: Int!
    $appointmentPaymentInput: UpdateAppointmentPaymentInput!
  ) {
    updateAppointmentRequestPayment(
      appointmentRequestPaymentId: $appointmentRequestPaymentId
      appointmentPaymentInput: $appointmentPaymentInput
    ) {
      id
    }
  }
`;
export type UpdateAppointmentRequestPaymentMutationFn = Apollo.MutationFunction<
  UpdateAppointmentRequestPaymentMutation,
  UpdateAppointmentRequestPaymentMutationVariables
>;

/**
 * __useUpdateAppointmentRequestPaymentMutation__
 *
 * To run a mutation, you first call `useUpdateAppointmentRequestPaymentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAppointmentRequestPaymentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAppointmentRequestPaymentMutation, { data, loading, error }] = useUpdateAppointmentRequestPaymentMutation({
 *   variables: {
 *      appointmentRequestPaymentId: // value for 'appointmentRequestPaymentId'
 *      appointmentPaymentInput: // value for 'appointmentPaymentInput'
 *   },
 * });
 */
export function useUpdateAppointmentRequestPaymentMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateAppointmentRequestPaymentMutation,
    UpdateAppointmentRequestPaymentMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateAppointmentRequestPaymentMutation,
    UpdateAppointmentRequestPaymentMutationVariables
  >(UpdateAppointmentRequestPaymentDocument, options);
}
export type UpdateAppointmentRequestPaymentMutationHookResult = ReturnType<
  typeof useUpdateAppointmentRequestPaymentMutation
>;
export type UpdateAppointmentRequestPaymentMutationResult =
  Apollo.MutationResult<UpdateAppointmentRequestPaymentMutation>;
export type UpdateAppointmentRequestPaymentMutationOptions = Apollo.BaseMutationOptions<
  UpdateAppointmentRequestPaymentMutation,
  UpdateAppointmentRequestPaymentMutationVariables
>;
export const LoginDocument = gql`
  mutation Login($password: String!, $email: String!, $inviteToken: String) {
    login(password: $password, email: $email, inviteToken: $inviteToken) {
      accessToken
    }
  }
`;
export type LoginMutationFn = Apollo.MutationFunction<LoginMutation, LoginMutationVariables>;

/**
 * __useLoginMutation__
 *
 * To run a mutation, you first call `useLoginMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLoginMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loginMutation, { data, loading, error }] = useLoginMutation({
 *   variables: {
 *      password: // value for 'password'
 *      email: // value for 'email'
 *      inviteToken: // value for 'inviteToken'
 *   },
 * });
 */
export function useLoginMutation(
  baseOptions?: Apollo.MutationHookOptions<LoginMutation, LoginMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<LoginMutation, LoginMutationVariables>(LoginDocument, options);
}
export type LoginMutationHookResult = ReturnType<typeof useLoginMutation>;
export type LoginMutationResult = Apollo.MutationResult<LoginMutation>;
export type LoginMutationOptions = Apollo.BaseMutationOptions<
  LoginMutation,
  LoginMutationVariables
>;
export const CreateUserDocument = gql`
  mutation CreateUser($createUserInput: CreateUserInput!, $inviteToken: String) {
    createUser(createUserInput: $createUserInput, inviteToken: $inviteToken) {
      firstName
      lastName
      email
    }
  }
`;
export type CreateUserMutationFn = Apollo.MutationFunction<
  CreateUserMutation,
  CreateUserMutationVariables
>;

/**
 * __useCreateUserMutation__
 *
 * To run a mutation, you first call `useCreateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createUserMutation, { data, loading, error }] = useCreateUserMutation({
 *   variables: {
 *      createUserInput: // value for 'createUserInput'
 *      inviteToken: // value for 'inviteToken'
 *   },
 * });
 */
export function useCreateUserMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateUserMutation, CreateUserMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateUserMutation, CreateUserMutationVariables>(
    CreateUserDocument,
    options,
  );
}
export type CreateUserMutationHookResult = ReturnType<typeof useCreateUserMutation>;
export type CreateUserMutationResult = Apollo.MutationResult<CreateUserMutation>;
export type CreateUserMutationOptions = Apollo.BaseMutationOptions<
  CreateUserMutation,
  CreateUserMutationVariables
>;
export const ResendVerifyEmailDocument = gql`
  mutation ResendVerifyEmail($email: String!) {
    resendVerifyEmail(email: $email) {
      success
    }
  }
`;
export type ResendVerifyEmailMutationFn = Apollo.MutationFunction<
  ResendVerifyEmailMutation,
  ResendVerifyEmailMutationVariables
>;

/**
 * __useResendVerifyEmailMutation__
 *
 * To run a mutation, you first call `useResendVerifyEmailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResendVerifyEmailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resendVerifyEmailMutation, { data, loading, error }] = useResendVerifyEmailMutation({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useResendVerifyEmailMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ResendVerifyEmailMutation,
    ResendVerifyEmailMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<ResendVerifyEmailMutation, ResendVerifyEmailMutationVariables>(
    ResendVerifyEmailDocument,
    options,
  );
}
export type ResendVerifyEmailMutationHookResult = ReturnType<typeof useResendVerifyEmailMutation>;
export type ResendVerifyEmailMutationResult = Apollo.MutationResult<ResendVerifyEmailMutation>;
export type ResendVerifyEmailMutationOptions = Apollo.BaseMutationOptions<
  ResendVerifyEmailMutation,
  ResendVerifyEmailMutationVariables
>;
export const VerifyEmailDocument = gql`
  mutation VerifyEmail($token: String!) {
    verifyEmail(token: $token) {
      accessToken
    }
  }
`;
export type VerifyEmailMutationFn = Apollo.MutationFunction<
  VerifyEmailMutation,
  VerifyEmailMutationVariables
>;

/**
 * __useVerifyEmailMutation__
 *
 * To run a mutation, you first call `useVerifyEmailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useVerifyEmailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [verifyEmailMutation, { data, loading, error }] = useVerifyEmailMutation({
 *   variables: {
 *      token: // value for 'token'
 *   },
 * });
 */
export function useVerifyEmailMutation(
  baseOptions?: Apollo.MutationHookOptions<VerifyEmailMutation, VerifyEmailMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<VerifyEmailMutation, VerifyEmailMutationVariables>(
    VerifyEmailDocument,
    options,
  );
}
export type VerifyEmailMutationHookResult = ReturnType<typeof useVerifyEmailMutation>;
export type VerifyEmailMutationResult = Apollo.MutationResult<VerifyEmailMutation>;
export type VerifyEmailMutationOptions = Apollo.BaseMutationOptions<
  VerifyEmailMutation,
  VerifyEmailMutationVariables
>;
export const ForgotPasswordDocument = gql`
  mutation ForgotPassword($email: String!) {
    forgotPassword(email: $email) {
      success
    }
  }
`;
export type ForgotPasswordMutationFn = Apollo.MutationFunction<
  ForgotPasswordMutation,
  ForgotPasswordMutationVariables
>;

/**
 * __useForgotPasswordMutation__
 *
 * To run a mutation, you first call `useForgotPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useForgotPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [forgotPasswordMutation, { data, loading, error }] = useForgotPasswordMutation({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useForgotPasswordMutation(
  baseOptions?: Apollo.MutationHookOptions<ForgotPasswordMutation, ForgotPasswordMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<ForgotPasswordMutation, ForgotPasswordMutationVariables>(
    ForgotPasswordDocument,
    options,
  );
}
export type ForgotPasswordMutationHookResult = ReturnType<typeof useForgotPasswordMutation>;
export type ForgotPasswordMutationResult = Apollo.MutationResult<ForgotPasswordMutation>;
export type ForgotPasswordMutationOptions = Apollo.BaseMutationOptions<
  ForgotPasswordMutation,
  ForgotPasswordMutationVariables
>;
export const ResetPasswordDocument = gql`
  mutation ResetPassword($resetPasswordToken: String!, $password: String!) {
    resetPassword(resetPasswordToken: $resetPasswordToken, password: $password) {
      accessToken
    }
  }
`;
export type ResetPasswordMutationFn = Apollo.MutationFunction<
  ResetPasswordMutation,
  ResetPasswordMutationVariables
>;

/**
 * __useResetPasswordMutation__
 *
 * To run a mutation, you first call `useResetPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResetPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resetPasswordMutation, { data, loading, error }] = useResetPasswordMutation({
 *   variables: {
 *      resetPasswordToken: // value for 'resetPasswordToken'
 *      password: // value for 'password'
 *   },
 * });
 */
export function useResetPasswordMutation(
  baseOptions?: Apollo.MutationHookOptions<ResetPasswordMutation, ResetPasswordMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<ResetPasswordMutation, ResetPasswordMutationVariables>(
    ResetPasswordDocument,
    options,
  );
}
export type ResetPasswordMutationHookResult = ReturnType<typeof useResetPasswordMutation>;
export type ResetPasswordMutationResult = Apollo.MutationResult<ResetPasswordMutation>;
export type ResetPasswordMutationOptions = Apollo.BaseMutationOptions<
  ResetPasswordMutation,
  ResetPasswordMutationVariables
>;
export const FinishAuthIdDocument = gql`
  mutation FinishAuthId($authId: String!) {
    finishAuthId(authId: $authId) {
      success
    }
  }
`;
export type FinishAuthIdMutationFn = Apollo.MutationFunction<
  FinishAuthIdMutation,
  FinishAuthIdMutationVariables
>;

/**
 * __useFinishAuthIdMutation__
 *
 * To run a mutation, you first call `useFinishAuthIdMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useFinishAuthIdMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [finishAuthIdMutation, { data, loading, error }] = useFinishAuthIdMutation({
 *   variables: {
 *      authId: // value for 'authId'
 *   },
 * });
 */
export function useFinishAuthIdMutation(
  baseOptions?: Apollo.MutationHookOptions<FinishAuthIdMutation, FinishAuthIdMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<FinishAuthIdMutation, FinishAuthIdMutationVariables>(
    FinishAuthIdDocument,
    options,
  );
}
export type FinishAuthIdMutationHookResult = ReturnType<typeof useFinishAuthIdMutation>;
export type FinishAuthIdMutationResult = Apollo.MutationResult<FinishAuthIdMutation>;
export type FinishAuthIdMutationOptions = Apollo.BaseMutationOptions<
  FinishAuthIdMutation,
  FinishAuthIdMutationVariables
>;
export const RefreshAccessTokenDocument = gql`
  mutation RefreshAccessToken {
    refreshAccessToken {
      accessToken
    }
  }
`;
export type RefreshAccessTokenMutationFn = Apollo.MutationFunction<
  RefreshAccessTokenMutation,
  RefreshAccessTokenMutationVariables
>;

/**
 * __useRefreshAccessTokenMutation__
 *
 * To run a mutation, you first call `useRefreshAccessTokenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRefreshAccessTokenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [refreshAccessTokenMutation, { data, loading, error }] = useRefreshAccessTokenMutation({
 *   variables: {
 *   },
 * });
 */
export function useRefreshAccessTokenMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RefreshAccessTokenMutation,
    RefreshAccessTokenMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<RefreshAccessTokenMutation, RefreshAccessTokenMutationVariables>(
    RefreshAccessTokenDocument,
    options,
  );
}
export type RefreshAccessTokenMutationHookResult = ReturnType<typeof useRefreshAccessTokenMutation>;
export type RefreshAccessTokenMutationResult = Apollo.MutationResult<RefreshAccessTokenMutation>;
export type RefreshAccessTokenMutationOptions = Apollo.BaseMutationOptions<
  RefreshAccessTokenMutation,
  RefreshAccessTokenMutationVariables
>;
export const GetSignatureDocument = gql`
  query GetSignature {
    getSignature {
      signature
      talkJsAppId
      talkJsId
    }
  }
`;

/**
 * __useGetSignatureQuery__
 *
 * To run a query within a React component, call `useGetSignatureQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSignatureQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSignatureQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetSignatureQuery(
  baseOptions?: Apollo.QueryHookOptions<GetSignatureQuery, GetSignatureQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetSignatureQuery, GetSignatureQueryVariables>(
    GetSignatureDocument,
    options,
  );
}
export function useGetSignatureLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetSignatureQuery, GetSignatureQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetSignatureQuery, GetSignatureQueryVariables>(
    GetSignatureDocument,
    options,
  );
}
export type GetSignatureQueryHookResult = ReturnType<typeof useGetSignatureQuery>;
export type GetSignatureLazyQueryHookResult = ReturnType<typeof useGetSignatureLazyQuery>;
export type GetSignatureQueryResult = Apollo.QueryResult<
  GetSignatureQuery,
  GetSignatureQueryVariables
>;
export const GetUserSignatureDocument = gql`
  query GetUserSignature($userId: Int!) {
    getUserSignature(userId: $userId) {
      signature
      talkJsAppId
      talkJsId
    }
  }
`;

/**
 * __useGetUserSignatureQuery__
 *
 * To run a query within a React component, call `useGetUserSignatureQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserSignatureQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserSignatureQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useGetUserSignatureQuery(
  baseOptions: Apollo.QueryHookOptions<GetUserSignatureQuery, GetUserSignatureQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetUserSignatureQuery, GetUserSignatureQueryVariables>(
    GetUserSignatureDocument,
    options,
  );
}
export function useGetUserSignatureLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetUserSignatureQuery, GetUserSignatureQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetUserSignatureQuery, GetUserSignatureQueryVariables>(
    GetUserSignatureDocument,
    options,
  );
}
export type GetUserSignatureQueryHookResult = ReturnType<typeof useGetUserSignatureQuery>;
export type GetUserSignatureLazyQueryHookResult = ReturnType<typeof useGetUserSignatureLazyQuery>;
export type GetUserSignatureQueryResult = Apollo.QueryResult<
  GetUserSignatureQuery,
  GetUserSignatureQueryVariables
>;
export const GetConversationByIdDocument = gql`
  query GetConversationById($conversationId: String!) {
    getConversationSignature(conversationId: $conversationId) {
      embedUrl
    }
  }
`;

/**
 * __useGetConversationByIdQuery__
 *
 * To run a query within a React component, call `useGetConversationByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetConversationByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetConversationByIdQuery({
 *   variables: {
 *      conversationId: // value for 'conversationId'
 *   },
 * });
 */
export function useGetConversationByIdQuery(
  baseOptions: Apollo.QueryHookOptions<GetConversationByIdQuery, GetConversationByIdQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetConversationByIdQuery, GetConversationByIdQueryVariables>(
    GetConversationByIdDocument,
    options,
  );
}
export function useGetConversationByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetConversationByIdQuery,
    GetConversationByIdQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetConversationByIdQuery, GetConversationByIdQueryVariables>(
    GetConversationByIdDocument,
    options,
  );
}
export type GetConversationByIdQueryHookResult = ReturnType<typeof useGetConversationByIdQuery>;
export type GetConversationByIdLazyQueryHookResult = ReturnType<
  typeof useGetConversationByIdLazyQuery
>;
export type GetConversationByIdQueryResult = Apollo.QueryResult<
  GetConversationByIdQuery,
  GetConversationByIdQueryVariables
>;
export const GetConversationsDocument = gql`
  query GetConversations(
    $filter: GetConversationsFilter
    $pageSize: Int
    $startingAfter: String
    $sort: GetConversationsSort
  ) {
    getConversations(
      filter: $filter
      pageSize: $pageSize
      startingAfter: $startingAfter
      sort: $sort
    ) {
      conversations {
        createdAt
        custom {
          customerId
          participants
          serviceRequestId
          userIds
          zipCode
          chatType
          city
          environment
          createdBy
          role
        }
        id
        lastMessage {
          attachment {
            url
            size
          }
          conversationId
          createdAt
          editedAt
          id
          location
          origin
          readBy
          referencedMessageId
          senderId
          text
          type
        }
        participants {
          access
          id
          notify
        }
        photoUrl
        subject
        topicId
        welcomeMessages
        Participants {
          id
          fullName
          role
          createdAt
          updatedAt
        }
        CreatedBy {
          id
          fullName
          role
          createdAt
          updatedAt
        }
      }
    }
  }
`;

/**
 * __useGetConversationsQuery__
 *
 * To run a query within a React component, call `useGetConversationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetConversationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetConversationsQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      pageSize: // value for 'pageSize'
 *      startingAfter: // value for 'startingAfter'
 *      sort: // value for 'sort'
 *   },
 * });
 */
export function useGetConversationsQuery(
  baseOptions?: Apollo.QueryHookOptions<GetConversationsQuery, GetConversationsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetConversationsQuery, GetConversationsQueryVariables>(
    GetConversationsDocument,
    options,
  );
}
export function useGetConversationsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetConversationsQuery, GetConversationsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetConversationsQuery, GetConversationsQueryVariables>(
    GetConversationsDocument,
    options,
  );
}
export type GetConversationsQueryHookResult = ReturnType<typeof useGetConversationsQuery>;
export type GetConversationsLazyQueryHookResult = ReturnType<typeof useGetConversationsLazyQuery>;
export type GetConversationsQueryResult = Apollo.QueryResult<
  GetConversationsQuery,
  GetConversationsQueryVariables
>;
export const GetConversationDocument = gql`
  query GetConversation($conversationId: String!) {
    getConversation(conversationId: $conversationId) {
      id
      subject
      topicId
      photoUrl
      welcomeMessages
      custom {
        customerId
        participants
        userIds
        serviceRequestId
        chatType
        city
        zipCode
        createdBy
        role
      }
      lastMessage {
        conversationId
        createdAt
        editedAt
        id
        location
        origin
        readBy
        senderId
        text
        type
        referencedMessageId
      }
      participants {
        id
        access
        notify
      }
      createdAt
      CreatedBy {
        id
        email
        fullName
        talkJsId
        role
      }
      Participants {
        id
        email
        fullName
        talkJsId
        role
      }
    }
  }
`;

/**
 * __useGetConversationQuery__
 *
 * To run a query within a React component, call `useGetConversationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetConversationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetConversationQuery({
 *   variables: {
 *      conversationId: // value for 'conversationId'
 *   },
 * });
 */
export function useGetConversationQuery(
  baseOptions: Apollo.QueryHookOptions<GetConversationQuery, GetConversationQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetConversationQuery, GetConversationQueryVariables>(
    GetConversationDocument,
    options,
  );
}
export function useGetConversationLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetConversationQuery, GetConversationQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetConversationQuery, GetConversationQueryVariables>(
    GetConversationDocument,
    options,
  );
}
export type GetConversationQueryHookResult = ReturnType<typeof useGetConversationQuery>;
export type GetConversationLazyQueryHookResult = ReturnType<typeof useGetConversationLazyQuery>;
export type GetConversationQueryResult = Apollo.QueryResult<
  GetConversationQuery,
  GetConversationQueryVariables
>;
export const JoinUserToConversationDocument = gql`
  mutation JoinUserToConversation($userId: Int!, $conversationId: String!) {
    joinUserToConversation(userId: $userId, conversationId: $conversationId) {
      id
    }
  }
`;
export type JoinUserToConversationMutationFn = Apollo.MutationFunction<
  JoinUserToConversationMutation,
  JoinUserToConversationMutationVariables
>;

/**
 * __useJoinUserToConversationMutation__
 *
 * To run a mutation, you first call `useJoinUserToConversationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useJoinUserToConversationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [joinUserToConversationMutation, { data, loading, error }] = useJoinUserToConversationMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *      conversationId: // value for 'conversationId'
 *   },
 * });
 */
export function useJoinUserToConversationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    JoinUserToConversationMutation,
    JoinUserToConversationMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    JoinUserToConversationMutation,
    JoinUserToConversationMutationVariables
  >(JoinUserToConversationDocument, options);
}
export type JoinUserToConversationMutationHookResult = ReturnType<
  typeof useJoinUserToConversationMutation
>;
export type JoinUserToConversationMutationResult =
  Apollo.MutationResult<JoinUserToConversationMutation>;
export type JoinUserToConversationMutationOptions = Apollo.BaseMutationOptions<
  JoinUserToConversationMutation,
  JoinUserToConversationMutationVariables
>;
export const RemoveParticipantDocument = gql`
  mutation RemoveParticipant($talkJsId: String!, $conversationId: String!) {
    removeParticipant(talkJsId: $talkJsId, conversationId: $conversationId) {
      id
    }
  }
`;
export type RemoveParticipantMutationFn = Apollo.MutationFunction<
  RemoveParticipantMutation,
  RemoveParticipantMutationVariables
>;

/**
 * __useRemoveParticipantMutation__
 *
 * To run a mutation, you first call `useRemoveParticipantMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveParticipantMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeParticipantMutation, { data, loading, error }] = useRemoveParticipantMutation({
 *   variables: {
 *      talkJsId: // value for 'talkJsId'
 *      conversationId: // value for 'conversationId'
 *   },
 * });
 */
export function useRemoveParticipantMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RemoveParticipantMutation,
    RemoveParticipantMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<RemoveParticipantMutation, RemoveParticipantMutationVariables>(
    RemoveParticipantDocument,
    options,
  );
}
export type RemoveParticipantMutationHookResult = ReturnType<typeof useRemoveParticipantMutation>;
export type RemoveParticipantMutationResult = Apollo.MutationResult<RemoveParticipantMutation>;
export type RemoveParticipantMutationOptions = Apollo.BaseMutationOptions<
  RemoveParticipantMutation,
  RemoveParticipantMutationVariables
>;
export const DeleteConversationDocument = gql`
  mutation DeleteConversation($conversationId: String!) {
    deleteConversation(conversationId: $conversationId) {
      success
      conversationId
    }
  }
`;
export type DeleteConversationMutationFn = Apollo.MutationFunction<
  DeleteConversationMutation,
  DeleteConversationMutationVariables
>;

/**
 * __useDeleteConversationMutation__
 *
 * To run a mutation, you first call `useDeleteConversationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteConversationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteConversationMutation, { data, loading, error }] = useDeleteConversationMutation({
 *   variables: {
 *      conversationId: // value for 'conversationId'
 *   },
 * });
 */
export function useDeleteConversationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteConversationMutation,
    DeleteConversationMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteConversationMutation, DeleteConversationMutationVariables>(
    DeleteConversationDocument,
    options,
  );
}
export type DeleteConversationMutationHookResult = ReturnType<typeof useDeleteConversationMutation>;
export type DeleteConversationMutationResult = Apollo.MutationResult<DeleteConversationMutation>;
export type DeleteConversationMutationOptions = Apollo.BaseMutationOptions<
  DeleteConversationMutation,
  DeleteConversationMutationVariables
>;
export const GetConversationsByCustomerDocument = gql`
  query GetConversationsByCustomer($customerId: Int!) {
    getConversationsByCustomer(customerId: $customerId) {
      id
      createdAt
      lastMessage {
        attachment {
          size
          url
        }
        conversationId
        createdAt
        editedAt
        id
        location
        origin
        readBy
        referencedMessageId
        senderId
        type
        text
      }
      custom {
        customerId
        participants
        userIds
      }
      participants {
        access
        id
        notify
      }
      photoUrl
      subject
      topicId
      welcomeMessages
    }
  }
`;

/**
 * __useGetConversationsByCustomerQuery__
 *
 * To run a query within a React component, call `useGetConversationsByCustomerQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetConversationsByCustomerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetConversationsByCustomerQuery({
 *   variables: {
 *      customerId: // value for 'customerId'
 *   },
 * });
 */
export function useGetConversationsByCustomerQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetConversationsByCustomerQuery,
    GetConversationsByCustomerQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetConversationsByCustomerQuery, GetConversationsByCustomerQueryVariables>(
    GetConversationsByCustomerDocument,
    options,
  );
}
export function useGetConversationsByCustomerLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetConversationsByCustomerQuery,
    GetConversationsByCustomerQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetConversationsByCustomerQuery,
    GetConversationsByCustomerQueryVariables
  >(GetConversationsByCustomerDocument, options);
}
export type GetConversationsByCustomerQueryHookResult = ReturnType<
  typeof useGetConversationsByCustomerQuery
>;
export type GetConversationsByCustomerLazyQueryHookResult = ReturnType<
  typeof useGetConversationsByCustomerLazyQuery
>;
export type GetConversationsByCustomerQueryResult = Apollo.QueryResult<
  GetConversationsByCustomerQuery,
  GetConversationsByCustomerQueryVariables
>;
export const StartConversationDocument = gql`
  mutation StartConversation($input: StartConversationInput!) {
    startConversation(startConversationInput: $input) {
      id
      subject
      topicId
      photoUrl
      welcomeMessages
      custom {
        customerId
        participants
        userIds
        serviceRequestId
        zipCode
        chatType
        city
        role
      }
      lastMessage {
        text
      }
      participants {
        id
        access
      }
      createdAt
    }
  }
`;
export type StartConversationMutationFn = Apollo.MutationFunction<
  StartConversationMutation,
  StartConversationMutationVariables
>;

/**
 * __useStartConversationMutation__
 *
 * To run a mutation, you first call `useStartConversationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useStartConversationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [startConversationMutation, { data, loading, error }] = useStartConversationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useStartConversationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    StartConversationMutation,
    StartConversationMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<StartConversationMutation, StartConversationMutationVariables>(
    StartConversationDocument,
    options,
  );
}
export type StartConversationMutationHookResult = ReturnType<typeof useStartConversationMutation>;
export type StartConversationMutationResult = Apollo.MutationResult<StartConversationMutation>;
export type StartConversationMutationOptions = Apollo.BaseMutationOptions<
  StartConversationMutation,
  StartConversationMutationVariables
>;
export const SyncCustomerChatDocument = gql`
  mutation SyncCustomerChat($customerId: Float!) {
    syncCustomerChat(customerId: $customerId) {
      success
    }
  }
`;
export type SyncCustomerChatMutationFn = Apollo.MutationFunction<
  SyncCustomerChatMutation,
  SyncCustomerChatMutationVariables
>;

/**
 * __useSyncCustomerChatMutation__
 *
 * To run a mutation, you first call `useSyncCustomerChatMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSyncCustomerChatMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [syncCustomerChatMutation, { data, loading, error }] = useSyncCustomerChatMutation({
 *   variables: {
 *      customerId: // value for 'customerId'
 *   },
 * });
 */
export function useSyncCustomerChatMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SyncCustomerChatMutation,
    SyncCustomerChatMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<SyncCustomerChatMutation, SyncCustomerChatMutationVariables>(
    SyncCustomerChatDocument,
    options,
  );
}
export type SyncCustomerChatMutationHookResult = ReturnType<typeof useSyncCustomerChatMutation>;
export type SyncCustomerChatMutationResult = Apollo.MutationResult<SyncCustomerChatMutation>;
export type SyncCustomerChatMutationOptions = Apollo.BaseMutationOptions<
  SyncCustomerChatMutation,
  SyncCustomerChatMutationVariables
>;
export const GetFlaggedMessagesDocument = gql`
  query GetFlaggedMessages {
    getFlaggedMessages {
      id
      messageId
      conversationId
      messageBody
      userId
      User {
        fullName
      }
    }
  }
`;

/**
 * __useGetFlaggedMessagesQuery__
 *
 * To run a query within a React component, call `useGetFlaggedMessagesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFlaggedMessagesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFlaggedMessagesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetFlaggedMessagesQuery(
  baseOptions?: Apollo.QueryHookOptions<GetFlaggedMessagesQuery, GetFlaggedMessagesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetFlaggedMessagesQuery, GetFlaggedMessagesQueryVariables>(
    GetFlaggedMessagesDocument,
    options,
  );
}
export function useGetFlaggedMessagesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetFlaggedMessagesQuery,
    GetFlaggedMessagesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetFlaggedMessagesQuery, GetFlaggedMessagesQueryVariables>(
    GetFlaggedMessagesDocument,
    options,
  );
}
export type GetFlaggedMessagesQueryHookResult = ReturnType<typeof useGetFlaggedMessagesQuery>;
export type GetFlaggedMessagesLazyQueryHookResult = ReturnType<
  typeof useGetFlaggedMessagesLazyQuery
>;
export type GetFlaggedMessagesQueryResult = Apollo.QueryResult<
  GetFlaggedMessagesQuery,
  GetFlaggedMessagesQueryVariables
>;
export const GetCircleRequestsDocument = gql`
  query GetCircleRequests(
    $filter: CircleRequestFilter!
    $sort: CircleRequestSortInput
    $pageSize: Int
    $page: Int
  ) {
    getCircleRequests(filter: $filter, sort: $sort, pageSize: $pageSize, page: $page) {
      meta {
        currentPage
        totalCount
      }
      circleRequests {
        id
        category
        status
        scheduledAt
        createdAt
        Customer {
          id
          fullName
        }
        RequestedBy {
          id
          fullName
        }
        askTo
        acceptedBy
      }
    }
  }
`;

/**
 * __useGetCircleRequestsQuery__
 *
 * To run a query within a React component, call `useGetCircleRequestsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCircleRequestsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCircleRequestsQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      sort: // value for 'sort'
 *      pageSize: // value for 'pageSize'
 *      page: // value for 'page'
 *   },
 * });
 */
export function useGetCircleRequestsQuery(
  baseOptions: Apollo.QueryHookOptions<GetCircleRequestsQuery, GetCircleRequestsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetCircleRequestsQuery, GetCircleRequestsQueryVariables>(
    GetCircleRequestsDocument,
    options,
  );
}
export function useGetCircleRequestsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCircleRequestsQuery,
    GetCircleRequestsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetCircleRequestsQuery, GetCircleRequestsQueryVariables>(
    GetCircleRequestsDocument,
    options,
  );
}
export type GetCircleRequestsQueryHookResult = ReturnType<typeof useGetCircleRequestsQuery>;
export type GetCircleRequestsLazyQueryHookResult = ReturnType<typeof useGetCircleRequestsLazyQuery>;
export type GetCircleRequestsQueryResult = Apollo.QueryResult<
  GetCircleRequestsQuery,
  GetCircleRequestsQueryVariables
>;
export const GetCircleRequestDocument = gql`
  query GetCircleRequest($getCircleRequestId: Float!) {
    getCircleRequest(id: $getCircleRequestId) {
      Address {
        addressLine1
        addressLine2
        city
        country
        createdAt
        customerId
        isDefault
        id
        isFromAPI
        place
        updatedAt
        state
        zipCode
      }
      Customer {
        addresses {
          addressLine1
          addressLine2
          city
          id
          isDefault
          isFromAPI
          state
          updatedAt
          zipCode
          customerId
          createdAt
          country
          place
        }
        users {
          user {
            role
            id
            createdAt
            updatedAt
            email
            fullName
            phoneNumber
          }
        }
        birthday
        createdAt
        createdBy
        firstName
        fullName
        id
        lastName
        updatedAt
      }
      RequestedBy {
        id
        firstName
        fullName
        email
        emailVerified
        createdAt
        language
        lastLogin
        lastName
        phoneNumber
        role
        updatedAt
        webPushSubscription
        talkJsId
      }
      CreatedBy {
        id
        fullName
      }
      acceptedAt
      acceptedBy
      addressId
      availabilities {
        date
        from
        until
      }
      category
      createdAt
      customerId
      description
      id
      isAvailabilityFlexible
      recurring
      frequency
      otherCategory
      requestType
      requestedBy
      ride {
        dropoffAddress {
          addressLine1
          addressLine2
          city
          country
          isDefault
          state
          zipCode
          place
        }
        pickupAddress {
          addressLine1
          addressLine2
          city
          country
          isDefault
          state
          zipCode
          place
        }
        stops {
          addressLine1
          addressLine2
          city
          country
          isDefault
          state
          zipCode
          place
        }
        type
        waitTime
      }
      scheduledAt
      status
      tentativeSchedule
      updatedAt
      AskTo {
        id
        fullName
        createdAt
        updatedAt
      }
      AcceptedBy {
        id
        fullName
      }
    }
  }
`;

/**
 * __useGetCircleRequestQuery__
 *
 * To run a query within a React component, call `useGetCircleRequestQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCircleRequestQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCircleRequestQuery({
 *   variables: {
 *      getCircleRequestId: // value for 'getCircleRequestId'
 *   },
 * });
 */
export function useGetCircleRequestQuery(
  baseOptions: Apollo.QueryHookOptions<GetCircleRequestQuery, GetCircleRequestQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetCircleRequestQuery, GetCircleRequestQueryVariables>(
    GetCircleRequestDocument,
    options,
  );
}
export function useGetCircleRequestLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetCircleRequestQuery, GetCircleRequestQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetCircleRequestQuery, GetCircleRequestQueryVariables>(
    GetCircleRequestDocument,
    options,
  );
}
export type GetCircleRequestQueryHookResult = ReturnType<typeof useGetCircleRequestQuery>;
export type GetCircleRequestLazyQueryHookResult = ReturnType<typeof useGetCircleRequestLazyQuery>;
export type GetCircleRequestQueryResult = Apollo.QueryResult<
  GetCircleRequestQuery,
  GetCircleRequestQueryVariables
>;
export const CreateCircleRequestDocument = gql`
  mutation CreateCircleRequest($data: CreateCircleRequestInput!) {
    createCircleRequest(data: $data) {
      id
    }
  }
`;
export type CreateCircleRequestMutationFn = Apollo.MutationFunction<
  CreateCircleRequestMutation,
  CreateCircleRequestMutationVariables
>;

/**
 * __useCreateCircleRequestMutation__
 *
 * To run a mutation, you first call `useCreateCircleRequestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCircleRequestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCircleRequestMutation, { data, loading, error }] = useCreateCircleRequestMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateCircleRequestMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateCircleRequestMutation,
    CreateCircleRequestMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateCircleRequestMutation, CreateCircleRequestMutationVariables>(
    CreateCircleRequestDocument,
    options,
  );
}
export type CreateCircleRequestMutationHookResult = ReturnType<
  typeof useCreateCircleRequestMutation
>;
export type CreateCircleRequestMutationResult = Apollo.MutationResult<CreateCircleRequestMutation>;
export type CreateCircleRequestMutationOptions = Apollo.BaseMutationOptions<
  CreateCircleRequestMutation,
  CreateCircleRequestMutationVariables
>;
export const UpdateCircleRequestDocument = gql`
  mutation UpdateCircleRequest($data: UpdateCircleRequestInput!, $updateCircleRequestId: Int!) {
    updateCircleRequest(data: $data, id: $updateCircleRequestId) {
      id
    }
  }
`;
export type UpdateCircleRequestMutationFn = Apollo.MutationFunction<
  UpdateCircleRequestMutation,
  UpdateCircleRequestMutationVariables
>;

/**
 * __useUpdateCircleRequestMutation__
 *
 * To run a mutation, you first call `useUpdateCircleRequestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCircleRequestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCircleRequestMutation, { data, loading, error }] = useUpdateCircleRequestMutation({
 *   variables: {
 *      data: // value for 'data'
 *      updateCircleRequestId: // value for 'updateCircleRequestId'
 *   },
 * });
 */
export function useUpdateCircleRequestMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateCircleRequestMutation,
    UpdateCircleRequestMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateCircleRequestMutation, UpdateCircleRequestMutationVariables>(
    UpdateCircleRequestDocument,
    options,
  );
}
export type UpdateCircleRequestMutationHookResult = ReturnType<
  typeof useUpdateCircleRequestMutation
>;
export type UpdateCircleRequestMutationResult = Apollo.MutationResult<UpdateCircleRequestMutation>;
export type UpdateCircleRequestMutationOptions = Apollo.BaseMutationOptions<
  UpdateCircleRequestMutation,
  UpdateCircleRequestMutationVariables
>;
export const GetCoachVisitsDocument = gql`
  query GetCoachVisits(
    $filter: CoachVisitFilter!
    $sort: CoachVisitSortInput
    $pageSize: Int
    $page: Int
  ) {
    getCoachVisits(filter: $filter, sort: $sort, pageSize: $pageSize, page: $page) {
      coachVisits {
        id
        Customer {
          fullName
          id
        }
        Coach {
          id
          fullName
        }
        scheduledAt
        status
      }
      meta {
        totalCount
        previousPage
        pageCount
        nextPage
        isLastPage
        isFirstPage
        currentPage
      }
    }
  }
`;

/**
 * __useGetCoachVisitsQuery__
 *
 * To run a query within a React component, call `useGetCoachVisitsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCoachVisitsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCoachVisitsQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      sort: // value for 'sort'
 *      pageSize: // value for 'pageSize'
 *      page: // value for 'page'
 *   },
 * });
 */
export function useGetCoachVisitsQuery(
  baseOptions: Apollo.QueryHookOptions<GetCoachVisitsQuery, GetCoachVisitsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetCoachVisitsQuery, GetCoachVisitsQueryVariables>(
    GetCoachVisitsDocument,
    options,
  );
}
export function useGetCoachVisitsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetCoachVisitsQuery, GetCoachVisitsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetCoachVisitsQuery, GetCoachVisitsQueryVariables>(
    GetCoachVisitsDocument,
    options,
  );
}
export type GetCoachVisitsQueryHookResult = ReturnType<typeof useGetCoachVisitsQuery>;
export type GetCoachVisitsLazyQueryHookResult = ReturnType<typeof useGetCoachVisitsLazyQuery>;
export type GetCoachVisitsQueryResult = Apollo.QueryResult<
  GetCoachVisitsQuery,
  GetCoachVisitsQueryVariables
>;
export const GetCoachVisitDocument = gql`
  query GetCoachVisit($coachVisitId: Int!) {
    getCoachVisit(coachVisitId: $coachVisitId) {
      id
      lastStatusUpdate
      recurring
      requestType
      requestedBy
      scheduledAt
      status
      updatedAt
      frequency
      description
      createdAt
      cancellationReason
      addressId
      RequestedBy {
        id
        fullName
      }
      Customer {
        id
        fullName
      }
      Coach {
        id
        fullName
      }
      Address {
        zipCode
        updatedAt
        state
        place
        isFromAPI
        isDefault
        id
        customerId
        createdAt
        country
        city
        addressLine2
        addressLine1
      }
      InternalNotes {
        id
        note
        createdAt
        updatedAt
        userId
        Users {
          id
          fullName
          createdAt
          updatedAt
        }
      }
      VisitSummaries {
        id
        body
        createdAt
        CreatedBy {
          id
          fullName
        }
        SentTo {
          id
          fullName
        }
      }
    }
  }
`;

/**
 * __useGetCoachVisitQuery__
 *
 * To run a query within a React component, call `useGetCoachVisitQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCoachVisitQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCoachVisitQuery({
 *   variables: {
 *      coachVisitId: // value for 'coachVisitId'
 *   },
 * });
 */
export function useGetCoachVisitQuery(
  baseOptions: Apollo.QueryHookOptions<GetCoachVisitQuery, GetCoachVisitQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetCoachVisitQuery, GetCoachVisitQueryVariables>(
    GetCoachVisitDocument,
    options,
  );
}
export function useGetCoachVisitLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetCoachVisitQuery, GetCoachVisitQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetCoachVisitQuery, GetCoachVisitQueryVariables>(
    GetCoachVisitDocument,
    options,
  );
}
export type GetCoachVisitQueryHookResult = ReturnType<typeof useGetCoachVisitQuery>;
export type GetCoachVisitLazyQueryHookResult = ReturnType<typeof useGetCoachVisitLazyQuery>;
export type GetCoachVisitQueryResult = Apollo.QueryResult<
  GetCoachVisitQuery,
  GetCoachVisitQueryVariables
>;
export const CreateCoachVisitDocument = gql`
  mutation CreateCoachVisit($data: CreateCoachVisitInput!) {
    createCoachVisit(data: $data) {
      id
    }
  }
`;
export type CreateCoachVisitMutationFn = Apollo.MutationFunction<
  CreateCoachVisitMutation,
  CreateCoachVisitMutationVariables
>;

/**
 * __useCreateCoachVisitMutation__
 *
 * To run a mutation, you first call `useCreateCoachVisitMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCoachVisitMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCoachVisitMutation, { data, loading, error }] = useCreateCoachVisitMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateCoachVisitMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateCoachVisitMutation,
    CreateCoachVisitMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateCoachVisitMutation, CreateCoachVisitMutationVariables>(
    CreateCoachVisitDocument,
    options,
  );
}
export type CreateCoachVisitMutationHookResult = ReturnType<typeof useCreateCoachVisitMutation>;
export type CreateCoachVisitMutationResult = Apollo.MutationResult<CreateCoachVisitMutation>;
export type CreateCoachVisitMutationOptions = Apollo.BaseMutationOptions<
  CreateCoachVisitMutation,
  CreateCoachVisitMutationVariables
>;
export const UpdateCoachVisitDocument = gql`
  mutation UpdateCoachVisit($data: UpdateCoachVisitInput!, $coachVisitId: Int!) {
    updateCoachVisit(data: $data, coachVisitId: $coachVisitId) {
      id
    }
  }
`;
export type UpdateCoachVisitMutationFn = Apollo.MutationFunction<
  UpdateCoachVisitMutation,
  UpdateCoachVisitMutationVariables
>;

/**
 * __useUpdateCoachVisitMutation__
 *
 * To run a mutation, you first call `useUpdateCoachVisitMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCoachVisitMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCoachVisitMutation, { data, loading, error }] = useUpdateCoachVisitMutation({
 *   variables: {
 *      data: // value for 'data'
 *      coachVisitId: // value for 'coachVisitId'
 *   },
 * });
 */
export function useUpdateCoachVisitMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateCoachVisitMutation,
    UpdateCoachVisitMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateCoachVisitMutation, UpdateCoachVisitMutationVariables>(
    UpdateCoachVisitDocument,
    options,
  );
}
export type UpdateCoachVisitMutationHookResult = ReturnType<typeof useUpdateCoachVisitMutation>;
export type UpdateCoachVisitMutationResult = Apollo.MutationResult<UpdateCoachVisitMutation>;
export type UpdateCoachVisitMutationOptions = Apollo.BaseMutationOptions<
  UpdateCoachVisitMutation,
  UpdateCoachVisitMutationVariables
>;
export const CreateVisitSummaryDocument = gql`
  mutation CreateVisitSummary($input: CreateVisitSummaryInput!) {
    createVisitSummary(input: $input) {
      id
      body
      coachVisitId
    }
  }
`;
export type CreateVisitSummaryMutationFn = Apollo.MutationFunction<
  CreateVisitSummaryMutation,
  CreateVisitSummaryMutationVariables
>;

/**
 * __useCreateVisitSummaryMutation__
 *
 * To run a mutation, you first call `useCreateVisitSummaryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateVisitSummaryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createVisitSummaryMutation, { data, loading, error }] = useCreateVisitSummaryMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateVisitSummaryMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateVisitSummaryMutation,
    CreateVisitSummaryMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateVisitSummaryMutation, CreateVisitSummaryMutationVariables>(
    CreateVisitSummaryDocument,
    options,
  );
}
export type CreateVisitSummaryMutationHookResult = ReturnType<typeof useCreateVisitSummaryMutation>;
export type CreateVisitSummaryMutationResult = Apollo.MutationResult<CreateVisitSummaryMutation>;
export type CreateVisitSummaryMutationOptions = Apollo.BaseMutationOptions<
  CreateVisitSummaryMutation,
  CreateVisitSummaryMutationVariables
>;
export const GetAllFeatureFlagsDocument = gql`
  query GetAllFeatureFlags {
    getAllFeatureFlags {
      id
      feature
      enabled
      description
    }
  }
`;

/**
 * __useGetAllFeatureFlagsQuery__
 *
 * To run a query within a React component, call `useGetAllFeatureFlagsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllFeatureFlagsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllFeatureFlagsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAllFeatureFlagsQuery(
  baseOptions?: Apollo.QueryHookOptions<GetAllFeatureFlagsQuery, GetAllFeatureFlagsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetAllFeatureFlagsQuery, GetAllFeatureFlagsQueryVariables>(
    GetAllFeatureFlagsDocument,
    options,
  );
}
export function useGetAllFeatureFlagsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetAllFeatureFlagsQuery,
    GetAllFeatureFlagsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetAllFeatureFlagsQuery, GetAllFeatureFlagsQueryVariables>(
    GetAllFeatureFlagsDocument,
    options,
  );
}
export type GetAllFeatureFlagsQueryHookResult = ReturnType<typeof useGetAllFeatureFlagsQuery>;
export type GetAllFeatureFlagsLazyQueryHookResult = ReturnType<
  typeof useGetAllFeatureFlagsLazyQuery
>;
export type GetAllFeatureFlagsQueryResult = Apollo.QueryResult<
  GetAllFeatureFlagsQuery,
  GetAllFeatureFlagsQueryVariables
>;
export const GetNotificationsByUserIdDocument = gql`
  query GetNotificationsByUserId(
    $filter: NotificationsFilter!
    $page: Int
    $pageSize: Int
    $userId: Int
  ) {
    getNotificationsByUserId(filter: $filter, page: $page, pageSize: $pageSize, userId: $userId) {
      notifications {
        id
        text
        emailSentAt
        pushSentAt
        smsSentAt
        readAt
        payload
        deeplink
        createdAt
        title
        type
        updatedAt
      }
      meta {
        currentPage
        isFirstPage
        isLastPage
        pageCount
        totalCount
      }
    }
  }
`;

/**
 * __useGetNotificationsByUserIdQuery__
 *
 * To run a query within a React component, call `useGetNotificationsByUserIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetNotificationsByUserIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetNotificationsByUserIdQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      page: // value for 'page'
 *      pageSize: // value for 'pageSize'
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useGetNotificationsByUserIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetNotificationsByUserIdQuery,
    GetNotificationsByUserIdQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetNotificationsByUserIdQuery, GetNotificationsByUserIdQueryVariables>(
    GetNotificationsByUserIdDocument,
    options,
  );
}
export function useGetNotificationsByUserIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetNotificationsByUserIdQuery,
    GetNotificationsByUserIdQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetNotificationsByUserIdQuery, GetNotificationsByUserIdQueryVariables>(
    GetNotificationsByUserIdDocument,
    options,
  );
}
export type GetNotificationsByUserIdQueryHookResult = ReturnType<
  typeof useGetNotificationsByUserIdQuery
>;
export type GetNotificationsByUserIdLazyQueryHookResult = ReturnType<
  typeof useGetNotificationsByUserIdLazyQuery
>;
export type GetNotificationsByUserIdQueryResult = Apollo.QueryResult<
  GetNotificationsByUserIdQuery,
  GetNotificationsByUserIdQueryVariables
>;
export const CreateNotificationDocument = gql`
  mutation CreateNotification($notification: CreateNotificationInput!) {
    createNotification(
      notification: $notification
      sendPush: true
      sendEmail: true
      sendSms: true
      sendWebpush: true
    ) {
      success
    }
  }
`;
export type CreateNotificationMutationFn = Apollo.MutationFunction<
  CreateNotificationMutation,
  CreateNotificationMutationVariables
>;

/**
 * __useCreateNotificationMutation__
 *
 * To run a mutation, you first call `useCreateNotificationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateNotificationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createNotificationMutation, { data, loading, error }] = useCreateNotificationMutation({
 *   variables: {
 *      notification: // value for 'notification'
 *   },
 * });
 */
export function useCreateNotificationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateNotificationMutation,
    CreateNotificationMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateNotificationMutation, CreateNotificationMutationVariables>(
    CreateNotificationDocument,
    options,
  );
}
export type CreateNotificationMutationHookResult = ReturnType<typeof useCreateNotificationMutation>;
export type CreateNotificationMutationResult = Apollo.MutationResult<CreateNotificationMutation>;
export type CreateNotificationMutationOptions = Apollo.BaseMutationOptions<
  CreateNotificationMutation,
  CreateNotificationMutationVariables
>;
export const GetStripePaymentMethodsByUserDocument = gql`
  query GetStripePaymentMethodsByUser($userId: Int!) {
    getStripePaymentMethodsByUser(userId: $userId) {
      id
      default
      created
      type
      card {
        brand
        country
        exp_month
        exp_year
        funding
        last4
      }
      billing_details {
        name
      }
    }
  }
`;

/**
 * __useGetStripePaymentMethodsByUserQuery__
 *
 * To run a query within a React component, call `useGetStripePaymentMethodsByUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetStripePaymentMethodsByUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetStripePaymentMethodsByUserQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useGetStripePaymentMethodsByUserQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetStripePaymentMethodsByUserQuery,
    GetStripePaymentMethodsByUserQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetStripePaymentMethodsByUserQuery,
    GetStripePaymentMethodsByUserQueryVariables
  >(GetStripePaymentMethodsByUserDocument, options);
}
export function useGetStripePaymentMethodsByUserLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetStripePaymentMethodsByUserQuery,
    GetStripePaymentMethodsByUserQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetStripePaymentMethodsByUserQuery,
    GetStripePaymentMethodsByUserQueryVariables
  >(GetStripePaymentMethodsByUserDocument, options);
}
export type GetStripePaymentMethodsByUserQueryHookResult = ReturnType<
  typeof useGetStripePaymentMethodsByUserQuery
>;
export type GetStripePaymentMethodsByUserLazyQueryHookResult = ReturnType<
  typeof useGetStripePaymentMethodsByUserLazyQuery
>;
export type GetStripePaymentMethodsByUserQueryResult = Apollo.QueryResult<
  GetStripePaymentMethodsByUserQuery,
  GetStripePaymentMethodsByUserQueryVariables
>;
export const GetCompaniesDocument = gql`
  query GetCompanies($sort: CompanySortInput, $filter: CompanyFilter, $pageSize: Int, $page: Int) {
    getCompanies(sort: $sort, filter: $filter, pageSize: $pageSize, page: $page) {
      companies {
        Agents {
          userId
          rating
          internalRating
          serviceTypes
          User {
            id
            fullName
          }
        }
        id
        rating
        phoneNumber
        addressLine1
        addressLine2
        city
        state
        zipCode
        name
      }
      meta {
        currentPage
        totalCount
      }
    }
  }
`;

/**
 * __useGetCompaniesQuery__
 *
 * To run a query within a React component, call `useGetCompaniesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCompaniesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCompaniesQuery({
 *   variables: {
 *      sort: // value for 'sort'
 *      filter: // value for 'filter'
 *      pageSize: // value for 'pageSize'
 *      page: // value for 'page'
 *   },
 * });
 */
export function useGetCompaniesQuery(
  baseOptions?: Apollo.QueryHookOptions<GetCompaniesQuery, GetCompaniesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetCompaniesQuery, GetCompaniesQueryVariables>(
    GetCompaniesDocument,
    options,
  );
}
export function useGetCompaniesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetCompaniesQuery, GetCompaniesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetCompaniesQuery, GetCompaniesQueryVariables>(
    GetCompaniesDocument,
    options,
  );
}
export type GetCompaniesQueryHookResult = ReturnType<typeof useGetCompaniesQuery>;
export type GetCompaniesLazyQueryHookResult = ReturnType<typeof useGetCompaniesLazyQuery>;
export type GetCompaniesQueryResult = Apollo.QueryResult<
  GetCompaniesQuery,
  GetCompaniesQueryVariables
>;
export const CreateCompanyDocument = gql`
  mutation CreateCompany($input: CreateCompanyInput!) {
    createCompany(input: $input) {
      id
    }
  }
`;
export type CreateCompanyMutationFn = Apollo.MutationFunction<
  CreateCompanyMutation,
  CreateCompanyMutationVariables
>;

/**
 * __useCreateCompanyMutation__
 *
 * To run a mutation, you first call `useCreateCompanyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCompanyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCompanyMutation, { data, loading, error }] = useCreateCompanyMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateCompanyMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateCompanyMutation, CreateCompanyMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateCompanyMutation, CreateCompanyMutationVariables>(
    CreateCompanyDocument,
    options,
  );
}
export type CreateCompanyMutationHookResult = ReturnType<typeof useCreateCompanyMutation>;
export type CreateCompanyMutationResult = Apollo.MutationResult<CreateCompanyMutation>;
export type CreateCompanyMutationOptions = Apollo.BaseMutationOptions<
  CreateCompanyMutation,
  CreateCompanyMutationVariables
>;
export const GetAgentsDocument = gql`
  query GetAgents($sort: AgentsSort, $filter: AgentsFilter, $pageSize: Int, $page: Int) {
    getAgents(sort: $sort, filter: $filter, pageSize: $pageSize, page: $page) {
      meta {
        currentPage
        isFirstPage
        isLastPage
        previousPage
        nextPage
        pageCount
        totalCount
      }
      agents {
        userId
        rating
        internalRating
        serviceTypes
        preferedContactMethods
        role
        Company {
          id
          name
          phoneNumber
          website
          addressLine1
          addressLine2
          city
          state
          zipCode
          rating
          createdAt
          updatedAt
        }
        User {
          id
          email
          firstName
          lastName
          fullName
          phoneNumber
          createdAt
          updatedAt
        }
      }
    }
  }
`;

/**
 * __useGetAgentsQuery__
 *
 * To run a query within a React component, call `useGetAgentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAgentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAgentsQuery({
 *   variables: {
 *      sort: // value for 'sort'
 *      filter: // value for 'filter'
 *      pageSize: // value for 'pageSize'
 *      page: // value for 'page'
 *   },
 * });
 */
export function useGetAgentsQuery(
  baseOptions?: Apollo.QueryHookOptions<GetAgentsQuery, GetAgentsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetAgentsQuery, GetAgentsQueryVariables>(GetAgentsDocument, options);
}
export function useGetAgentsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetAgentsQuery, GetAgentsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetAgentsQuery, GetAgentsQueryVariables>(GetAgentsDocument, options);
}
export type GetAgentsQueryHookResult = ReturnType<typeof useGetAgentsQuery>;
export type GetAgentsLazyQueryHookResult = ReturnType<typeof useGetAgentsLazyQuery>;
export type GetAgentsQueryResult = Apollo.QueryResult<GetAgentsQuery, GetAgentsQueryVariables>;
export const GetAgentsByServiceRequestDocument = gql`
  query GetAgentsByServiceRequest($serviceRequestId: Int!) {
    getAgentsByServiceRequest(serviceRequestId: $serviceRequestId) {
      agents {
        userId
        rating
        internalRating
        serviceTypes
        Company {
          id
          name
          phoneNumber
          website
          addressLine1
          addressLine2
          city
          state
          zipCode
          rating
          createdAt
          updatedAt
        }
        User {
          id
          email
          fullName
          phoneNumber
          createdAt
          updatedAt
        }
        ServiceRequestReviews {
          rating
        }
        customerFavorite
        trustedFavorite
        trustedReviewed
        appointmentRequestSent
      }
    }
  }
`;

/**
 * __useGetAgentsByServiceRequestQuery__
 *
 * To run a query within a React component, call `useGetAgentsByServiceRequestQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAgentsByServiceRequestQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAgentsByServiceRequestQuery({
 *   variables: {
 *      serviceRequestId: // value for 'serviceRequestId'
 *   },
 * });
 */
export function useGetAgentsByServiceRequestQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetAgentsByServiceRequestQuery,
    GetAgentsByServiceRequestQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetAgentsByServiceRequestQuery, GetAgentsByServiceRequestQueryVariables>(
    GetAgentsByServiceRequestDocument,
    options,
  );
}
export function useGetAgentsByServiceRequestLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetAgentsByServiceRequestQuery,
    GetAgentsByServiceRequestQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetAgentsByServiceRequestQuery,
    GetAgentsByServiceRequestQueryVariables
  >(GetAgentsByServiceRequestDocument, options);
}
export type GetAgentsByServiceRequestQueryHookResult = ReturnType<
  typeof useGetAgentsByServiceRequestQuery
>;
export type GetAgentsByServiceRequestLazyQueryHookResult = ReturnType<
  typeof useGetAgentsByServiceRequestLazyQuery
>;
export type GetAgentsByServiceRequestQueryResult = Apollo.QueryResult<
  GetAgentsByServiceRequestQuery,
  GetAgentsByServiceRequestQueryVariables
>;
export const GetAgentDocument = gql`
  query GetAgent($agentId: Int!) {
    getAgent(id: $agentId) {
      userId
      rating
      internalRating
      serviceTypes
      companyId
      preferedContactMethods
      role
      Company {
        id
        name
      }
      User {
        id
        firstName
        lastName
        email
        fullName
        phoneNumber
        createdAt
        updatedAt
        disabled
        emailVerified
        lastLogin
        bio
        profilePicture
      }
    }
  }
`;

/**
 * __useGetAgentQuery__
 *
 * To run a query within a React component, call `useGetAgentQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAgentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAgentQuery({
 *   variables: {
 *      agentId: // value for 'agentId'
 *   },
 * });
 */
export function useGetAgentQuery(
  baseOptions: Apollo.QueryHookOptions<GetAgentQuery, GetAgentQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetAgentQuery, GetAgentQueryVariables>(GetAgentDocument, options);
}
export function useGetAgentLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetAgentQuery, GetAgentQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetAgentQuery, GetAgentQueryVariables>(GetAgentDocument, options);
}
export type GetAgentQueryHookResult = ReturnType<typeof useGetAgentQuery>;
export type GetAgentLazyQueryHookResult = ReturnType<typeof useGetAgentLazyQuery>;
export type GetAgentQueryResult = Apollo.QueryResult<GetAgentQuery, GetAgentQueryVariables>;
export const GetAgentReviewsDocument = gql`
  query GetAgentReviews($agentId: Int!, $pageSize: Int, $page: Int, $sort: ReviewSortInput) {
    getAgentReviews(agentId: $agentId, pageSize: $pageSize, page: $page, sort: $sort) {
      reviews {
        comment
        rating
        serviceRequestId
        reviewedBy
        createdAt
        updatedAt
        ReviewedBy {
          fullName
          id
        }
      }
      meta {
        currentPage
        totalCount
      }
    }
  }
`;

/**
 * __useGetAgentReviewsQuery__
 *
 * To run a query within a React component, call `useGetAgentReviewsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAgentReviewsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAgentReviewsQuery({
 *   variables: {
 *      agentId: // value for 'agentId'
 *      pageSize: // value for 'pageSize'
 *      page: // value for 'page'
 *      sort: // value for 'sort'
 *   },
 * });
 */
export function useGetAgentReviewsQuery(
  baseOptions: Apollo.QueryHookOptions<GetAgentReviewsQuery, GetAgentReviewsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetAgentReviewsQuery, GetAgentReviewsQueryVariables>(
    GetAgentReviewsDocument,
    options,
  );
}
export function useGetAgentReviewsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetAgentReviewsQuery, GetAgentReviewsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetAgentReviewsQuery, GetAgentReviewsQueryVariables>(
    GetAgentReviewsDocument,
    options,
  );
}
export type GetAgentReviewsQueryHookResult = ReturnType<typeof useGetAgentReviewsQuery>;
export type GetAgentReviewsLazyQueryHookResult = ReturnType<typeof useGetAgentReviewsLazyQuery>;
export type GetAgentReviewsQueryResult = Apollo.QueryResult<
  GetAgentReviewsQuery,
  GetAgentReviewsQueryVariables
>;
export const GetCompanyDocument = gql`
  query GetCompany($companyId: Float!) {
    getCompany(id: $companyId) {
      id
      name
      phoneNumber
      hours
      website
      addressLine1
      addressLine2
      city
      state
      zipCode
      rating
      createdAt
      updatedAt
      serviceZipCodes
      Agents {
        userId
        rating
        internalRating
        serviceTypes
        companyId
        preferedContactMethods
        User {
          id
          email
          firstName
          lastName
          fullName
          phoneNumber
          language
          talkJsId
          disabled
          emailVerified
          createdAt
          updatedAt
        }
      }
      StripeConnectAccounts {
        stripeAccountId
      }
    }
  }
`;

/**
 * __useGetCompanyQuery__
 *
 * To run a query within a React component, call `useGetCompanyQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCompanyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCompanyQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useGetCompanyQuery(
  baseOptions: Apollo.QueryHookOptions<GetCompanyQuery, GetCompanyQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetCompanyQuery, GetCompanyQueryVariables>(GetCompanyDocument, options);
}
export function useGetCompanyLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetCompanyQuery, GetCompanyQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetCompanyQuery, GetCompanyQueryVariables>(
    GetCompanyDocument,
    options,
  );
}
export type GetCompanyQueryHookResult = ReturnType<typeof useGetCompanyQuery>;
export type GetCompanyLazyQueryHookResult = ReturnType<typeof useGetCompanyLazyQuery>;
export type GetCompanyQueryResult = Apollo.QueryResult<GetCompanyQuery, GetCompanyQueryVariables>;
export const UpdateCompanyDocument = gql`
  mutation UpdateCompany($input: UpdateCompanyInput!, $companyId: Float!) {
    updateCompany(data: $input, id: $companyId) {
      id
    }
  }
`;
export type UpdateCompanyMutationFn = Apollo.MutationFunction<
  UpdateCompanyMutation,
  UpdateCompanyMutationVariables
>;

/**
 * __useUpdateCompanyMutation__
 *
 * To run a mutation, you first call `useUpdateCompanyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCompanyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCompanyMutation, { data, loading, error }] = useUpdateCompanyMutation({
 *   variables: {
 *      input: // value for 'input'
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useUpdateCompanyMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateCompanyMutation, UpdateCompanyMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateCompanyMutation, UpdateCompanyMutationVariables>(
    UpdateCompanyDocument,
    options,
  );
}
export type UpdateCompanyMutationHookResult = ReturnType<typeof useUpdateCompanyMutation>;
export type UpdateCompanyMutationResult = Apollo.MutationResult<UpdateCompanyMutation>;
export type UpdateCompanyMutationOptions = Apollo.BaseMutationOptions<
  UpdateCompanyMutation,
  UpdateCompanyMutationVariables
>;
export const CreateAgentDocument = gql`
  mutation CreateAgent($input: CreateAgentInput!) {
    createAgent(createAgentInput: $input) {
      userId
    }
  }
`;
export type CreateAgentMutationFn = Apollo.MutationFunction<
  CreateAgentMutation,
  CreateAgentMutationVariables
>;

/**
 * __useCreateAgentMutation__
 *
 * To run a mutation, you first call `useCreateAgentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateAgentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createAgentMutation, { data, loading, error }] = useCreateAgentMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateAgentMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateAgentMutation, CreateAgentMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateAgentMutation, CreateAgentMutationVariables>(
    CreateAgentDocument,
    options,
  );
}
export type CreateAgentMutationHookResult = ReturnType<typeof useCreateAgentMutation>;
export type CreateAgentMutationResult = Apollo.MutationResult<CreateAgentMutation>;
export type CreateAgentMutationOptions = Apollo.BaseMutationOptions<
  CreateAgentMutation,
  CreateAgentMutationVariables
>;
export const UpdateAgentDocument = gql`
  mutation UpdateAgent($input: UpdateAgentInput!, $agentId: Int!) {
    updateAgent(updateAgentInput: $input, id: $agentId) {
      userId
    }
  }
`;
export type UpdateAgentMutationFn = Apollo.MutationFunction<
  UpdateAgentMutation,
  UpdateAgentMutationVariables
>;

/**
 * __useUpdateAgentMutation__
 *
 * To run a mutation, you first call `useUpdateAgentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAgentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAgentMutation, { data, loading, error }] = useUpdateAgentMutation({
 *   variables: {
 *      input: // value for 'input'
 *      agentId: // value for 'agentId'
 *   },
 * });
 */
export function useUpdateAgentMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateAgentMutation, UpdateAgentMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateAgentMutation, UpdateAgentMutationVariables>(
    UpdateAgentDocument,
    options,
  );
}
export type UpdateAgentMutationHookResult = ReturnType<typeof useUpdateAgentMutation>;
export type UpdateAgentMutationResult = Apollo.MutationResult<UpdateAgentMutation>;
export type UpdateAgentMutationOptions = Apollo.BaseMutationOptions<
  UpdateAgentMutation,
  UpdateAgentMutationVariables
>;
export const DeleteAgentDocument = gql`
  mutation DeleteAgent($deleteAgentId: Int!) {
    deleteAgent(id: $deleteAgentId) {
      success
    }
  }
`;
export type DeleteAgentMutationFn = Apollo.MutationFunction<
  DeleteAgentMutation,
  DeleteAgentMutationVariables
>;

/**
 * __useDeleteAgentMutation__
 *
 * To run a mutation, you first call `useDeleteAgentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteAgentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteAgentMutation, { data, loading, error }] = useDeleteAgentMutation({
 *   variables: {
 *      deleteAgentId: // value for 'deleteAgentId'
 *   },
 * });
 */
export function useDeleteAgentMutation(
  baseOptions?: Apollo.MutationHookOptions<DeleteAgentMutation, DeleteAgentMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteAgentMutation, DeleteAgentMutationVariables>(
    DeleteAgentDocument,
    options,
  );
}
export type DeleteAgentMutationHookResult = ReturnType<typeof useDeleteAgentMutation>;
export type DeleteAgentMutationResult = Apollo.MutationResult<DeleteAgentMutation>;
export type DeleteAgentMutationOptions = Apollo.BaseMutationOptions<
  DeleteAgentMutation,
  DeleteAgentMutationVariables
>;
export const GetStripeConnectAccountDocument = gql`
  query GetStripeConnectAccount {
    getStripeConnectAccount {
      status
    }
  }
`;

/**
 * __useGetStripeConnectAccountQuery__
 *
 * To run a query within a React component, call `useGetStripeConnectAccountQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetStripeConnectAccountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetStripeConnectAccountQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetStripeConnectAccountQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetStripeConnectAccountQuery,
    GetStripeConnectAccountQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetStripeConnectAccountQuery, GetStripeConnectAccountQueryVariables>(
    GetStripeConnectAccountDocument,
    options,
  );
}
export function useGetStripeConnectAccountLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetStripeConnectAccountQuery,
    GetStripeConnectAccountQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetStripeConnectAccountQuery, GetStripeConnectAccountQueryVariables>(
    GetStripeConnectAccountDocument,
    options,
  );
}
export type GetStripeConnectAccountQueryHookResult = ReturnType<
  typeof useGetStripeConnectAccountQuery
>;
export type GetStripeConnectAccountLazyQueryHookResult = ReturnType<
  typeof useGetStripeConnectAccountLazyQuery
>;
export type GetStripeConnectAccountQueryResult = Apollo.QueryResult<
  GetStripeConnectAccountQuery,
  GetStripeConnectAccountQueryVariables
>;
export const CreateConnectAccountOrLoginLinkDocument = gql`
  mutation CreateConnectAccountOrLoginLink {
    createConnectAccountOrLoginLink
  }
`;
export type CreateConnectAccountOrLoginLinkMutationFn = Apollo.MutationFunction<
  CreateConnectAccountOrLoginLinkMutation,
  CreateConnectAccountOrLoginLinkMutationVariables
>;

/**
 * __useCreateConnectAccountOrLoginLinkMutation__
 *
 * To run a mutation, you first call `useCreateConnectAccountOrLoginLinkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateConnectAccountOrLoginLinkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createConnectAccountOrLoginLinkMutation, { data, loading, error }] = useCreateConnectAccountOrLoginLinkMutation({
 *   variables: {
 *   },
 * });
 */
export function useCreateConnectAccountOrLoginLinkMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateConnectAccountOrLoginLinkMutation,
    CreateConnectAccountOrLoginLinkMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateConnectAccountOrLoginLinkMutation,
    CreateConnectAccountOrLoginLinkMutationVariables
  >(CreateConnectAccountOrLoginLinkDocument, options);
}
export type CreateConnectAccountOrLoginLinkMutationHookResult = ReturnType<
  typeof useCreateConnectAccountOrLoginLinkMutation
>;
export type CreateConnectAccountOrLoginLinkMutationResult =
  Apollo.MutationResult<CreateConnectAccountOrLoginLinkMutation>;
export type CreateConnectAccountOrLoginLinkMutationOptions = Apollo.BaseMutationOptions<
  CreateConnectAccountOrLoginLinkMutation,
  CreateConnectAccountOrLoginLinkMutationVariables
>;
export const ContinueConnectAccountOnboardingDocument = gql`
  query ContinueConnectAccountOnboarding {
    continueConnectAccountOnboarding
  }
`;

/**
 * __useContinueConnectAccountOnboardingQuery__
 *
 * To run a query within a React component, call `useContinueConnectAccountOnboardingQuery` and pass it any options that fit your needs.
 * When your component renders, `useContinueConnectAccountOnboardingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useContinueConnectAccountOnboardingQuery({
 *   variables: {
 *   },
 * });
 */
export function useContinueConnectAccountOnboardingQuery(
  baseOptions?: Apollo.QueryHookOptions<
    ContinueConnectAccountOnboardingQuery,
    ContinueConnectAccountOnboardingQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    ContinueConnectAccountOnboardingQuery,
    ContinueConnectAccountOnboardingQueryVariables
  >(ContinueConnectAccountOnboardingDocument, options);
}
export function useContinueConnectAccountOnboardingLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ContinueConnectAccountOnboardingQuery,
    ContinueConnectAccountOnboardingQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ContinueConnectAccountOnboardingQuery,
    ContinueConnectAccountOnboardingQueryVariables
  >(ContinueConnectAccountOnboardingDocument, options);
}
export type ContinueConnectAccountOnboardingQueryHookResult = ReturnType<
  typeof useContinueConnectAccountOnboardingQuery
>;
export type ContinueConnectAccountOnboardingLazyQueryHookResult = ReturnType<
  typeof useContinueConnectAccountOnboardingLazyQuery
>;
export type ContinueConnectAccountOnboardingQueryResult = Apollo.QueryResult<
  ContinueConnectAccountOnboardingQuery,
  ContinueConnectAccountOnboardingQueryVariables
>;
export const GetServiceRequestsDocument = gql`
  query GetServiceRequests(
    $filter: ServiceRequestFilter!
    $sort: ServiceRequestSortInput
    $pageSize: Int
    $page: Int
  ) {
    getServiceRequests(filter: $filter, sort: $sort, pageSize: $pageSize, page: $page) {
      meta {
        currentPage
        totalCount
      }
      serviceRequests {
        id
        category
        status
        scheduledAt
        createdAt
        actionNeeded
        Customer {
          id
          fullName
        }
        Agent {
          userId
          deletedAt
          User {
            id
            fullName
          }
        }
        RequestedBy {
          id
          fullName
        }
        AppointmentRequests {
          id
          scheduledAt
          status
        }
      }
    }
  }
`;

/**
 * __useGetServiceRequestsQuery__
 *
 * To run a query within a React component, call `useGetServiceRequestsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetServiceRequestsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetServiceRequestsQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      sort: // value for 'sort'
 *      pageSize: // value for 'pageSize'
 *      page: // value for 'page'
 *   },
 * });
 */
export function useGetServiceRequestsQuery(
  baseOptions: Apollo.QueryHookOptions<GetServiceRequestsQuery, GetServiceRequestsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetServiceRequestsQuery, GetServiceRequestsQueryVariables>(
    GetServiceRequestsDocument,
    options,
  );
}
export function useGetServiceRequestsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetServiceRequestsQuery,
    GetServiceRequestsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetServiceRequestsQuery, GetServiceRequestsQueryVariables>(
    GetServiceRequestsDocument,
    options,
  );
}
export type GetServiceRequestsQueryHookResult = ReturnType<typeof useGetServiceRequestsQuery>;
export type GetServiceRequestsLazyQueryHookResult = ReturnType<
  typeof useGetServiceRequestsLazyQuery
>;
export type GetServiceRequestsQueryResult = Apollo.QueryResult<
  GetServiceRequestsQuery,
  GetServiceRequestsQueryVariables
>;
export const GetServiceRequestDocument = gql`
  query GetServiceRequest($getServiceRequestId: Float!) {
    getServiceRequest(id: $getServiceRequestId) {
      Address {
        addressLine1
        addressLine2
        city
        country
        createdAt
        customerId
        isDefault
        id
        isFromAPI
        place
        updatedAt
        state
        zipCode
      }
      Agent {
        Company {
          city
          addressLine1
          createdAt
          addressLine2
          hours
          id
          name
          phoneNumber
          rating
          state
          updatedAt
          website
          zipCode
        }
        User {
          id
          fullName
          lastLogin
        }
        companyId
        rating
        serviceTypes
        deletedAt
      }
      Customer {
        addresses {
          addressLine1
          addressLine2
          city
          country
          createdAt
          customerId
          id
          isDefault
          isFromAPI
          place
          state
          updatedAt
          zipCode
        }
        birthday
        createdAt
        createdBy
        firstName
        fullName
        id
        lastName
        updatedAt
      }
      RequestedBy {
        id
        firstName
        fullName
        email
        emailVerified
        createdAt
        language
        lastLogin
        lastName
        phoneNumber
        role
        updatedAt
        webPushSubscription
        talkJsId
      }
      CreatedBy {
        id
        fullName
      }
      acceptedAt
      actionNeeded
      addressId
      agentId
      availabilities {
        date
        from
        until
      }
      category
      createdAt
      customerId
      description
      id
      isAvailabilityFlexible
      recurring
      frequency
      otherCategory
      requestType
      requestedBy
      requiresAction
      ride {
        dropoffAddress {
          addressLine1
          addressLine2
          city
          country
          isDefault
          state
          zipCode
          place
        }
        pickupAddress {
          addressLine1
          addressLine2
          city
          country
          isDefault
          state
          zipCode
          place
        }
        stops {
          addressLine1
          addressLine2
          city
          country
          isDefault
          state
          zipCode
          place
        }
        type
        waitTime
      }
      scheduledAt
      status
      tentativeSchedule
      updatedAt
      InternalNotes {
        createdAt
        id
        note
        updatedAt
        userId
        Users {
          fullName
          id
          createdAt
          updatedAt
        }
      }
      ServiceRequestPayments {
        id
        type
        amount
        stripePaymentMethodId
        stripeTransactionId
        fee
        tax
        estimateHours
        createdAt
        notes
        updatedAt
      }
      commentForBrya
      commentForCustomer
    }
  }
`;

/**
 * __useGetServiceRequestQuery__
 *
 * To run a query within a React component, call `useGetServiceRequestQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetServiceRequestQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetServiceRequestQuery({
 *   variables: {
 *      getServiceRequestId: // value for 'getServiceRequestId'
 *   },
 * });
 */
export function useGetServiceRequestQuery(
  baseOptions: Apollo.QueryHookOptions<GetServiceRequestQuery, GetServiceRequestQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetServiceRequestQuery, GetServiceRequestQueryVariables>(
    GetServiceRequestDocument,
    options,
  );
}
export function useGetServiceRequestLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetServiceRequestQuery,
    GetServiceRequestQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetServiceRequestQuery, GetServiceRequestQueryVariables>(
    GetServiceRequestDocument,
    options,
  );
}
export type GetServiceRequestQueryHookResult = ReturnType<typeof useGetServiceRequestQuery>;
export type GetServiceRequestLazyQueryHookResult = ReturnType<typeof useGetServiceRequestLazyQuery>;
export type GetServiceRequestQueryResult = Apollo.QueryResult<
  GetServiceRequestQuery,
  GetServiceRequestQueryVariables
>;
export const GetStripeTransactionsDocument = gql`
  query GetStripeTransactions($filter: StripeTransactionFilter!) {
    getStripeTransactions(filter: $filter) {
      transactions {
        status
        amount
        activityId
        stripeTransactionId
      }
    }
  }
`;

/**
 * __useGetStripeTransactionsQuery__
 *
 * To run a query within a React component, call `useGetStripeTransactionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetStripeTransactionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetStripeTransactionsQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useGetStripeTransactionsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetStripeTransactionsQuery,
    GetStripeTransactionsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetStripeTransactionsQuery, GetStripeTransactionsQueryVariables>(
    GetStripeTransactionsDocument,
    options,
  );
}
export function useGetStripeTransactionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetStripeTransactionsQuery,
    GetStripeTransactionsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetStripeTransactionsQuery, GetStripeTransactionsQueryVariables>(
    GetStripeTransactionsDocument,
    options,
  );
}
export type GetStripeTransactionsQueryHookResult = ReturnType<typeof useGetStripeTransactionsQuery>;
export type GetStripeTransactionsLazyQueryHookResult = ReturnType<
  typeof useGetStripeTransactionsLazyQuery
>;
export type GetStripeTransactionsQueryResult = Apollo.QueryResult<
  GetStripeTransactionsQuery,
  GetStripeTransactionsQueryVariables
>;
export const CreateServiceRequestDocument = gql`
  mutation CreateServiceRequest($data: CreateServiceRequestInput!) {
    createServiceRequest(data: $data) {
      id
    }
  }
`;
export type CreateServiceRequestMutationFn = Apollo.MutationFunction<
  CreateServiceRequestMutation,
  CreateServiceRequestMutationVariables
>;

/**
 * __useCreateServiceRequestMutation__
 *
 * To run a mutation, you first call `useCreateServiceRequestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateServiceRequestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createServiceRequestMutation, { data, loading, error }] = useCreateServiceRequestMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateServiceRequestMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateServiceRequestMutation,
    CreateServiceRequestMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateServiceRequestMutation, CreateServiceRequestMutationVariables>(
    CreateServiceRequestDocument,
    options,
  );
}
export type CreateServiceRequestMutationHookResult = ReturnType<
  typeof useCreateServiceRequestMutation
>;
export type CreateServiceRequestMutationResult =
  Apollo.MutationResult<CreateServiceRequestMutation>;
export type CreateServiceRequestMutationOptions = Apollo.BaseMutationOptions<
  CreateServiceRequestMutation,
  CreateServiceRequestMutationVariables
>;
export const CreateAppointmentRequestServiceRequestDocument = gql`
  mutation CreateAppointmentRequestServiceRequest($userIds: [Int!]!, $serviceRequestId: Int) {
    createAppointmentRequest(userIds: $userIds, serviceRequestId: $serviceRequestId) {
      id
      userId
      serviceRequestId
      circleRequestId
      status
      createdAt
      updatedAt
    }
  }
`;
export type CreateAppointmentRequestServiceRequestMutationFn = Apollo.MutationFunction<
  CreateAppointmentRequestServiceRequestMutation,
  CreateAppointmentRequestServiceRequestMutationVariables
>;

/**
 * __useCreateAppointmentRequestServiceRequestMutation__
 *
 * To run a mutation, you first call `useCreateAppointmentRequestServiceRequestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateAppointmentRequestServiceRequestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createAppointmentRequestServiceRequestMutation, { data, loading, error }] = useCreateAppointmentRequestServiceRequestMutation({
 *   variables: {
 *      userIds: // value for 'userIds'
 *      serviceRequestId: // value for 'serviceRequestId'
 *   },
 * });
 */
export function useCreateAppointmentRequestServiceRequestMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateAppointmentRequestServiceRequestMutation,
    CreateAppointmentRequestServiceRequestMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateAppointmentRequestServiceRequestMutation,
    CreateAppointmentRequestServiceRequestMutationVariables
  >(CreateAppointmentRequestServiceRequestDocument, options);
}
export type CreateAppointmentRequestServiceRequestMutationHookResult = ReturnType<
  typeof useCreateAppointmentRequestServiceRequestMutation
>;
export type CreateAppointmentRequestServiceRequestMutationResult =
  Apollo.MutationResult<CreateAppointmentRequestServiceRequestMutation>;
export type CreateAppointmentRequestServiceRequestMutationOptions = Apollo.BaseMutationOptions<
  CreateAppointmentRequestServiceRequestMutation,
  CreateAppointmentRequestServiceRequestMutationVariables
>;
export const UpdateServiceRequestDocument = gql`
  mutation UpdateServiceRequest($data: UpdateServiceRequestInput!, $updateServiceRequestId: Int!) {
    updateServiceRequest(data: $data, id: $updateServiceRequestId) {
      id
    }
  }
`;
export type UpdateServiceRequestMutationFn = Apollo.MutationFunction<
  UpdateServiceRequestMutation,
  UpdateServiceRequestMutationVariables
>;

/**
 * __useUpdateServiceRequestMutation__
 *
 * To run a mutation, you first call `useUpdateServiceRequestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateServiceRequestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateServiceRequestMutation, { data, loading, error }] = useUpdateServiceRequestMutation({
 *   variables: {
 *      data: // value for 'data'
 *      updateServiceRequestId: // value for 'updateServiceRequestId'
 *   },
 * });
 */
export function useUpdateServiceRequestMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateServiceRequestMutation,
    UpdateServiceRequestMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateServiceRequestMutation, UpdateServiceRequestMutationVariables>(
    UpdateServiceRequestDocument,
    options,
  );
}
export type UpdateServiceRequestMutationHookResult = ReturnType<
  typeof useUpdateServiceRequestMutation
>;
export type UpdateServiceRequestMutationResult =
  Apollo.MutationResult<UpdateServiceRequestMutation>;
export type UpdateServiceRequestMutationOptions = Apollo.BaseMutationOptions<
  UpdateServiceRequestMutation,
  UpdateServiceRequestMutationVariables
>;
export const GetSocialEventsDocument = gql`
  query GetSocialEvents(
    $filter: SocialEventFilter!
    $sort: SocialEventSortInput
    $pageSize: Int
    $page: Int
  ) {
    getSocialEvents(filter: $filter, sort: $sort, pageSize: $pageSize, page: $page) {
      meta {
        currentPage
        totalCount
      }
      socialEvents {
        id
        status
        scheduledAt
        createdAt
        RequestedBy {
          id
          fullName
        }
        eventName
        Coaches {
          Coach {
            id
            fullName
          }
        }
        Participants {
          status
          id
          Customer {
            fullName
          }
          ContactRequests {
            id
            userMessage
            createdAt
            status
            bryaMessage
            bryaInternalNotes
            updatedAt
          }
        }
        place
        addressLine1
        addressLine2
        city
        state
        zipCode
        country
      }
    }
  }
`;

/**
 * __useGetSocialEventsQuery__
 *
 * To run a query within a React component, call `useGetSocialEventsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSocialEventsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSocialEventsQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      sort: // value for 'sort'
 *      pageSize: // value for 'pageSize'
 *      page: // value for 'page'
 *   },
 * });
 */
export function useGetSocialEventsQuery(
  baseOptions: Apollo.QueryHookOptions<GetSocialEventsQuery, GetSocialEventsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetSocialEventsQuery, GetSocialEventsQueryVariables>(
    GetSocialEventsDocument,
    options,
  );
}
export function useGetSocialEventsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetSocialEventsQuery, GetSocialEventsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetSocialEventsQuery, GetSocialEventsQueryVariables>(
    GetSocialEventsDocument,
    options,
  );
}
export type GetSocialEventsQueryHookResult = ReturnType<typeof useGetSocialEventsQuery>;
export type GetSocialEventsLazyQueryHookResult = ReturnType<typeof useGetSocialEventsLazyQuery>;
export type GetSocialEventsQueryResult = Apollo.QueryResult<
  GetSocialEventsQuery,
  GetSocialEventsQueryVariables
>;
export const GetSocialEventDocument = gql`
  query GetSocialEvent($socialEventId: Int!) {
    getSocialEvent(socialEventId: $socialEventId) {
      id
      createdAt
      eventName
      description
      place
      addressLine1
      addressLine2
      city
      state
      country
      zipCode
      status
      RequestedBy {
        id
        fullName
      }
      Coaches {
        Coach {
          id
          fullName
        }
      }
      Participants {
        Customer {
          id
          fullName
        }
        status
        ContactRequests {
          id
          userMessage
          createdAt
          status
          bryaMessage
          bryaInternalNotes
          updatedAt
        }
      }
      recurring
      frequency
      requestType
      scheduledAt
      cancellationReason
      updatedAt
    }
  }
`;

/**
 * __useGetSocialEventQuery__
 *
 * To run a query within a React component, call `useGetSocialEventQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSocialEventQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSocialEventQuery({
 *   variables: {
 *      socialEventId: // value for 'socialEventId'
 *   },
 * });
 */
export function useGetSocialEventQuery(
  baseOptions: Apollo.QueryHookOptions<GetSocialEventQuery, GetSocialEventQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetSocialEventQuery, GetSocialEventQueryVariables>(
    GetSocialEventDocument,
    options,
  );
}
export function useGetSocialEventLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetSocialEventQuery, GetSocialEventQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetSocialEventQuery, GetSocialEventQueryVariables>(
    GetSocialEventDocument,
    options,
  );
}
export type GetSocialEventQueryHookResult = ReturnType<typeof useGetSocialEventQuery>;
export type GetSocialEventLazyQueryHookResult = ReturnType<typeof useGetSocialEventLazyQuery>;
export type GetSocialEventQueryResult = Apollo.QueryResult<
  GetSocialEventQuery,
  GetSocialEventQueryVariables
>;
export const CreateSocialEventDocument = gql`
  mutation CreateSocialEvent($data: CreateSocialEventInput!) {
    createSocialEvent(data: $data) {
      id
    }
  }
`;
export type CreateSocialEventMutationFn = Apollo.MutationFunction<
  CreateSocialEventMutation,
  CreateSocialEventMutationVariables
>;

/**
 * __useCreateSocialEventMutation__
 *
 * To run a mutation, you first call `useCreateSocialEventMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSocialEventMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSocialEventMutation, { data, loading, error }] = useCreateSocialEventMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateSocialEventMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateSocialEventMutation,
    CreateSocialEventMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateSocialEventMutation, CreateSocialEventMutationVariables>(
    CreateSocialEventDocument,
    options,
  );
}
export type CreateSocialEventMutationHookResult = ReturnType<typeof useCreateSocialEventMutation>;
export type CreateSocialEventMutationResult = Apollo.MutationResult<CreateSocialEventMutation>;
export type CreateSocialEventMutationOptions = Apollo.BaseMutationOptions<
  CreateSocialEventMutation,
  CreateSocialEventMutationVariables
>;
export const UpdateSocialEventDocument = gql`
  mutation UpdateSocialEvent($data: UpdateSocialEventInput!, $socialEventId: Int!) {
    updateSocialEvent(data: $data, socialEventId: $socialEventId) {
      id
    }
  }
`;
export type UpdateSocialEventMutationFn = Apollo.MutationFunction<
  UpdateSocialEventMutation,
  UpdateSocialEventMutationVariables
>;

/**
 * __useUpdateSocialEventMutation__
 *
 * To run a mutation, you first call `useUpdateSocialEventMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSocialEventMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSocialEventMutation, { data, loading, error }] = useUpdateSocialEventMutation({
 *   variables: {
 *      data: // value for 'data'
 *      socialEventId: // value for 'socialEventId'
 *   },
 * });
 */
export function useUpdateSocialEventMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateSocialEventMutation,
    UpdateSocialEventMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateSocialEventMutation, UpdateSocialEventMutationVariables>(
    UpdateSocialEventDocument,
    options,
  );
}
export type UpdateSocialEventMutationHookResult = ReturnType<typeof useUpdateSocialEventMutation>;
export type UpdateSocialEventMutationResult = Apollo.MutationResult<UpdateSocialEventMutation>;
export type UpdateSocialEventMutationOptions = Apollo.BaseMutationOptions<
  UpdateSocialEventMutation,
  UpdateSocialEventMutationVariables
>;
export const UpdateContactRequestDocument = gql`
  mutation UpdateContactRequest(
    $updateContactRequestInput: UpdateContactRequestInput!
    $contactRequestId: Int!
  ) {
    updateContactRequest(
      updateContactRequestInput: $updateContactRequestInput
      contactRequestId: $contactRequestId
    ) {
      id
    }
  }
`;
export type UpdateContactRequestMutationFn = Apollo.MutationFunction<
  UpdateContactRequestMutation,
  UpdateContactRequestMutationVariables
>;

/**
 * __useUpdateContactRequestMutation__
 *
 * To run a mutation, you first call `useUpdateContactRequestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateContactRequestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateContactRequestMutation, { data, loading, error }] = useUpdateContactRequestMutation({
 *   variables: {
 *      updateContactRequestInput: // value for 'updateContactRequestInput'
 *      contactRequestId: // value for 'contactRequestId'
 *   },
 * });
 */
export function useUpdateContactRequestMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateContactRequestMutation,
    UpdateContactRequestMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateContactRequestMutation, UpdateContactRequestMutationVariables>(
    UpdateContactRequestDocument,
    options,
  );
}
export type UpdateContactRequestMutationHookResult = ReturnType<
  typeof useUpdateContactRequestMutation
>;
export type UpdateContactRequestMutationResult =
  Apollo.MutationResult<UpdateContactRequestMutation>;
export type UpdateContactRequestMutationOptions = Apollo.BaseMutationOptions<
  UpdateContactRequestMutation,
  UpdateContactRequestMutationVariables
>;
export const GetSuggestedActivitiesDocument = gql`
  query GetSuggestedActivities(
    $filter: SuggestedActivitiesFilter
    $sort: SuggestedActivitiesSortInput
    $page: Int
    $pageSize: Int
  ) {
    getSuggestedActivities(filter: $filter, sort: $sort, page: $page, pageSize: $pageSize) {
      suggestedActivities {
        id
        User {
          id
          fullName
        }
        name
        description
        createdAt
        notes
        role
      }
      meta {
        currentPage
        isFirstPage
        isLastPage
        pageCount
        totalCount
      }
    }
  }
`;

/**
 * __useGetSuggestedActivitiesQuery__
 *
 * To run a query within a React component, call `useGetSuggestedActivitiesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSuggestedActivitiesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSuggestedActivitiesQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      sort: // value for 'sort'
 *      page: // value for 'page'
 *      pageSize: // value for 'pageSize'
 *   },
 * });
 */
export function useGetSuggestedActivitiesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetSuggestedActivitiesQuery,
    GetSuggestedActivitiesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetSuggestedActivitiesQuery, GetSuggestedActivitiesQueryVariables>(
    GetSuggestedActivitiesDocument,
    options,
  );
}
export function useGetSuggestedActivitiesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetSuggestedActivitiesQuery,
    GetSuggestedActivitiesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetSuggestedActivitiesQuery, GetSuggestedActivitiesQueryVariables>(
    GetSuggestedActivitiesDocument,
    options,
  );
}
export type GetSuggestedActivitiesQueryHookResult = ReturnType<
  typeof useGetSuggestedActivitiesQuery
>;
export type GetSuggestedActivitiesLazyQueryHookResult = ReturnType<
  typeof useGetSuggestedActivitiesLazyQuery
>;
export type GetSuggestedActivitiesQueryResult = Apollo.QueryResult<
  GetSuggestedActivitiesQuery,
  GetSuggestedActivitiesQueryVariables
>;
export const UpdateSuggestedActivityDocument = gql`
  mutation UpdateSuggestedActivity(
    $updateSuggestedActivityInput: UpdateSuggestedActivityInput!
    $suggestedActivityId: Int!
  ) {
    updateSuggestedActivity(
      updateSuggestedActivityInput: $updateSuggestedActivityInput
      suggestedActivityId: $suggestedActivityId
    ) {
      id
    }
  }
`;
export type UpdateSuggestedActivityMutationFn = Apollo.MutationFunction<
  UpdateSuggestedActivityMutation,
  UpdateSuggestedActivityMutationVariables
>;

/**
 * __useUpdateSuggestedActivityMutation__
 *
 * To run a mutation, you first call `useUpdateSuggestedActivityMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSuggestedActivityMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSuggestedActivityMutation, { data, loading, error }] = useUpdateSuggestedActivityMutation({
 *   variables: {
 *      updateSuggestedActivityInput: // value for 'updateSuggestedActivityInput'
 *      suggestedActivityId: // value for 'suggestedActivityId'
 *   },
 * });
 */
export function useUpdateSuggestedActivityMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateSuggestedActivityMutation,
    UpdateSuggestedActivityMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateSuggestedActivityMutation,
    UpdateSuggestedActivityMutationVariables
  >(UpdateSuggestedActivityDocument, options);
}
export type UpdateSuggestedActivityMutationHookResult = ReturnType<
  typeof useUpdateSuggestedActivityMutation
>;
export type UpdateSuggestedActivityMutationResult =
  Apollo.MutationResult<UpdateSuggestedActivityMutation>;
export type UpdateSuggestedActivityMutationOptions = Apollo.BaseMutationOptions<
  UpdateSuggestedActivityMutation,
  UpdateSuggestedActivityMutationVariables
>;
export const GetDefaultTimezoneDocument = gql`
  query GetDefaultTimezone {
    getDefaultTimezone {
      timezone
    }
  }
`;

/**
 * __useGetDefaultTimezoneQuery__
 *
 * To run a query within a React component, call `useGetDefaultTimezoneQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDefaultTimezoneQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDefaultTimezoneQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetDefaultTimezoneQuery(
  baseOptions?: Apollo.QueryHookOptions<GetDefaultTimezoneQuery, GetDefaultTimezoneQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetDefaultTimezoneQuery, GetDefaultTimezoneQueryVariables>(
    GetDefaultTimezoneDocument,
    options,
  );
}
export function useGetDefaultTimezoneLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetDefaultTimezoneQuery,
    GetDefaultTimezoneQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetDefaultTimezoneQuery, GetDefaultTimezoneQueryVariables>(
    GetDefaultTimezoneDocument,
    options,
  );
}
export type GetDefaultTimezoneQueryHookResult = ReturnType<typeof useGetDefaultTimezoneQuery>;
export type GetDefaultTimezoneLazyQueryHookResult = ReturnType<
  typeof useGetDefaultTimezoneLazyQuery
>;
export type GetDefaultTimezoneQueryResult = Apollo.QueryResult<
  GetDefaultTimezoneQuery,
  GetDefaultTimezoneQueryVariables
>;
export const GetUsersDocument = gql`
  query GetUsers($filter: UserFilter, $sort: UserSortInput, $pageSize: Int, $page: Int) {
    getUsers(filter: $filter, sort: $sort, pageSize: $pageSize, page: $page) {
      users {
        id
        firstName
        lastName
        fullName
        email
        role
        createdAt
        disabled
        talkJsId
      }
      meta {
        currentPage
        isFirstPage
        isLastPage
        nextPage
        pageCount
        previousPage
        totalCount
      }
    }
  }
`;

/**
 * __useGetUsersQuery__
 *
 * To run a query within a React component, call `useGetUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUsersQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      sort: // value for 'sort'
 *      pageSize: // value for 'pageSize'
 *      page: // value for 'page'
 *   },
 * });
 */
export function useGetUsersQuery(
  baseOptions?: Apollo.QueryHookOptions<GetUsersQuery, GetUsersQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetUsersQuery, GetUsersQueryVariables>(GetUsersDocument, options);
}
export function useGetUsersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetUsersQuery, GetUsersQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetUsersQuery, GetUsersQueryVariables>(GetUsersDocument, options);
}
export type GetUsersQueryHookResult = ReturnType<typeof useGetUsersQuery>;
export type GetUsersLazyQueryHookResult = ReturnType<typeof useGetUsersLazyQuery>;
export type GetUsersQueryResult = Apollo.QueryResult<GetUsersQuery, GetUsersQueryVariables>;
export const GetUserDocument = gql`
  query GetUser($getUserId: Int!) {
    getUser(id: $getUserId) {
      ...UserFields
    }
  }
  ${UserFieldsFragmentDoc}
`;

/**
 * __useGetUserQuery__
 *
 * To run a query within a React component, call `useGetUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserQuery({
 *   variables: {
 *      getUserId: // value for 'getUserId'
 *   },
 * });
 */
export function useGetUserQuery(
  baseOptions: Apollo.QueryHookOptions<GetUserQuery, GetUserQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetUserQuery, GetUserQueryVariables>(GetUserDocument, options);
}
export function useGetUserLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetUserQuery, GetUserQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetUserQuery, GetUserQueryVariables>(GetUserDocument, options);
}
export type GetUserQueryHookResult = ReturnType<typeof useGetUserQuery>;
export type GetUserLazyQueryHookResult = ReturnType<typeof useGetUserLazyQuery>;
export type GetUserQueryResult = Apollo.QueryResult<GetUserQuery, GetUserQueryVariables>;
export const GetUserByChatProviderIdDocument = gql`
  query GetUserByChatProviderId($chatProviderId: String!) {
    getUserByChatProviderId(chatProviderId: $chatProviderId) {
      ...UserFields
    }
  }
  ${UserFieldsFragmentDoc}
`;

/**
 * __useGetUserByChatProviderIdQuery__
 *
 * To run a query within a React component, call `useGetUserByChatProviderIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserByChatProviderIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserByChatProviderIdQuery({
 *   variables: {
 *      chatProviderId: // value for 'chatProviderId'
 *   },
 * });
 */
export function useGetUserByChatProviderIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetUserByChatProviderIdQuery,
    GetUserByChatProviderIdQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetUserByChatProviderIdQuery, GetUserByChatProviderIdQueryVariables>(
    GetUserByChatProviderIdDocument,
    options,
  );
}
export function useGetUserByChatProviderIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetUserByChatProviderIdQuery,
    GetUserByChatProviderIdQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetUserByChatProviderIdQuery, GetUserByChatProviderIdQueryVariables>(
    GetUserByChatProviderIdDocument,
    options,
  );
}
export type GetUserByChatProviderIdQueryHookResult = ReturnType<
  typeof useGetUserByChatProviderIdQuery
>;
export type GetUserByChatProviderIdLazyQueryHookResult = ReturnType<
  typeof useGetUserByChatProviderIdLazyQuery
>;
export type GetUserByChatProviderIdQueryResult = Apollo.QueryResult<
  GetUserByChatProviderIdQuery,
  GetUserByChatProviderIdQueryVariables
>;
export const DisableUserDocument = gql`
  mutation DisableUser($disableUserId: Int!) {
    disableUser(id: $disableUserId) {
      disabled
      id
    }
  }
`;
export type DisableUserMutationFn = Apollo.MutationFunction<
  DisableUserMutation,
  DisableUserMutationVariables
>;

/**
 * __useDisableUserMutation__
 *
 * To run a mutation, you first call `useDisableUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDisableUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [disableUserMutation, { data, loading, error }] = useDisableUserMutation({
 *   variables: {
 *      disableUserId: // value for 'disableUserId'
 *   },
 * });
 */
export function useDisableUserMutation(
  baseOptions?: Apollo.MutationHookOptions<DisableUserMutation, DisableUserMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DisableUserMutation, DisableUserMutationVariables>(
    DisableUserDocument,
    options,
  );
}
export type DisableUserMutationHookResult = ReturnType<typeof useDisableUserMutation>;
export type DisableUserMutationResult = Apollo.MutationResult<DisableUserMutation>;
export type DisableUserMutationOptions = Apollo.BaseMutationOptions<
  DisableUserMutation,
  DisableUserMutationVariables
>;
export const EnableUserDocument = gql`
  mutation EnableUser($enableUserId: Int!) {
    enableUser(id: $enableUserId) {
      disabled
      id
    }
  }
`;
export type EnableUserMutationFn = Apollo.MutationFunction<
  EnableUserMutation,
  EnableUserMutationVariables
>;

/**
 * __useEnableUserMutation__
 *
 * To run a mutation, you first call `useEnableUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEnableUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [enableUserMutation, { data, loading, error }] = useEnableUserMutation({
 *   variables: {
 *      enableUserId: // value for 'enableUserId'
 *   },
 * });
 */
export function useEnableUserMutation(
  baseOptions?: Apollo.MutationHookOptions<EnableUserMutation, EnableUserMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<EnableUserMutation, EnableUserMutationVariables>(
    EnableUserDocument,
    options,
  );
}
export type EnableUserMutationHookResult = ReturnType<typeof useEnableUserMutation>;
export type EnableUserMutationResult = Apollo.MutationResult<EnableUserMutation>;
export type EnableUserMutationOptions = Apollo.BaseMutationOptions<
  EnableUserMutation,
  EnableUserMutationVariables
>;
export const AdminCreateUserDocument = gql`
  mutation AdminCreateUser($adminCreateUserInput: AdminCreateUserInput!) {
    adminCreateUser(adminCreateUserInput: $adminCreateUserInput) {
      id
      firstName
      lastName
      email
      fullName
      createdAt
      updatedAt
      disabled
      role
    }
  }
`;
export type AdminCreateUserMutationFn = Apollo.MutationFunction<
  AdminCreateUserMutation,
  AdminCreateUserMutationVariables
>;

/**
 * __useAdminCreateUserMutation__
 *
 * To run a mutation, you first call `useAdminCreateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdminCreateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [adminCreateUserMutation, { data, loading, error }] = useAdminCreateUserMutation({
 *   variables: {
 *      adminCreateUserInput: // value for 'adminCreateUserInput'
 *   },
 * });
 */
export function useAdminCreateUserMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AdminCreateUserMutation,
    AdminCreateUserMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<AdminCreateUserMutation, AdminCreateUserMutationVariables>(
    AdminCreateUserDocument,
    options,
  );
}
export type AdminCreateUserMutationHookResult = ReturnType<typeof useAdminCreateUserMutation>;
export type AdminCreateUserMutationResult = Apollo.MutationResult<AdminCreateUserMutation>;
export type AdminCreateUserMutationOptions = Apollo.BaseMutationOptions<
  AdminCreateUserMutation,
  AdminCreateUserMutationVariables
>;
export const AdminUpdateUserDocument = gql`
  mutation AdminUpdateUser($adminUpdateUserInput: AdminUpdateUserInput!, $adminUpdateUserId: Int!) {
    adminUpdateUser(adminUpdateUserInput: $adminUpdateUserInput, id: $adminUpdateUserId) {
      id
      firstName
      lastName
      email
      fullName
      phoneNumber
      createdAt
      updatedAt
      defaultTab
      talkJsId
      language
      disabled
      emailVerified
      role
    }
  }
`;
export type AdminUpdateUserMutationFn = Apollo.MutationFunction<
  AdminUpdateUserMutation,
  AdminUpdateUserMutationVariables
>;

/**
 * __useAdminUpdateUserMutation__
 *
 * To run a mutation, you first call `useAdminUpdateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdminUpdateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [adminUpdateUserMutation, { data, loading, error }] = useAdminUpdateUserMutation({
 *   variables: {
 *      adminUpdateUserInput: // value for 'adminUpdateUserInput'
 *      adminUpdateUserId: // value for 'adminUpdateUserId'
 *   },
 * });
 */
export function useAdminUpdateUserMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AdminUpdateUserMutation,
    AdminUpdateUserMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<AdminUpdateUserMutation, AdminUpdateUserMutationVariables>(
    AdminUpdateUserDocument,
    options,
  );
}
export type AdminUpdateUserMutationHookResult = ReturnType<typeof useAdminUpdateUserMutation>;
export type AdminUpdateUserMutationResult = Apollo.MutationResult<AdminUpdateUserMutation>;
export type AdminUpdateUserMutationOptions = Apollo.BaseMutationOptions<
  AdminUpdateUserMutation,
  AdminUpdateUserMutationVariables
>;
export const GetCustomersDocument = gql`
  query GetCustomers($filters: FiltersInput!) {
    getCustomers(filters: $filters) {
      id
      updatedAt
      createdBy
      createdAt
      firstName
      lastName
      fullName
      phoneNumber
      addresses {
        addressLine1
        addressLine2
        city
        id
        isDefault
        isFromAPI
        state
        updatedAt
        zipCode
        customerId
        createdAt
        country
        place
      }
      users {
        role
        user {
          id
          createdAt
          updatedAt
          email
          fullName
          phoneNumber
        }
      }
    }
  }
`;

/**
 * __useGetCustomersQuery__
 *
 * To run a query within a React component, call `useGetCustomersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCustomersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCustomersQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useGetCustomersQuery(
  baseOptions: Apollo.QueryHookOptions<GetCustomersQuery, GetCustomersQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetCustomersQuery, GetCustomersQueryVariables>(
    GetCustomersDocument,
    options,
  );
}
export function useGetCustomersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetCustomersQuery, GetCustomersQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetCustomersQuery, GetCustomersQueryVariables>(
    GetCustomersDocument,
    options,
  );
}
export type GetCustomersQueryHookResult = ReturnType<typeof useGetCustomersQuery>;
export type GetCustomersLazyQueryHookResult = ReturnType<typeof useGetCustomersLazyQuery>;
export type GetCustomersQueryResult = Apollo.QueryResult<
  GetCustomersQuery,
  GetCustomersQueryVariables
>;
export const GetCustomerDocument = gql`
  query GetCustomer($customerId: Float!) {
    getCustomer(customerId: $customerId) {
      id
      firstName
      lastName
      fullName
      Creator {
        fullName
        id
        firstName
        lastName
        AppFeaturesSelected {
          circleMember
          familyPartner
          id
          seniorMember
          userId
        }
      }
      addresses {
        id
        addressLine1
        addressLine2
        city
        customerId
        isDefault
        state
        zipCode
      }
      users {
        role
        user {
          id
          fullName
          createdAt
        }
      }
      createdBy
      createdAt
      updatedAt
      CustomerAdditionalInformation {
        customerId
        gender
        age
        retiredTime
        hobbies
        professionalBackground
        educationalBackground
        relationshipStatus
        otherInformation
        city
        state
        zipCode
      }
    }
  }
`;

/**
 * __useGetCustomerQuery__
 *
 * To run a query within a React component, call `useGetCustomerQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCustomerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCustomerQuery({
 *   variables: {
 *      customerId: // value for 'customerId'
 *   },
 * });
 */
export function useGetCustomerQuery(
  baseOptions: Apollo.QueryHookOptions<GetCustomerQuery, GetCustomerQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetCustomerQuery, GetCustomerQueryVariables>(GetCustomerDocument, options);
}
export function useGetCustomerLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetCustomerQuery, GetCustomerQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetCustomerQuery, GetCustomerQueryVariables>(
    GetCustomerDocument,
    options,
  );
}
export type GetCustomerQueryHookResult = ReturnType<typeof useGetCustomerQuery>;
export type GetCustomerLazyQueryHookResult = ReturnType<typeof useGetCustomerLazyQuery>;
export type GetCustomerQueryResult = Apollo.QueryResult<
  GetCustomerQuery,
  GetCustomerQueryVariables
>;
export const UpdateCustomerDocument = gql`
  mutation UpdateCustomer($updateCustomerInput: UpdateCustomerInput!, $customerId: Int!) {
    updateCustomer(updateCustomerInput: $updateCustomerInput, customerId: $customerId) {
      id
      updatedAt
      createdAt
      createdBy
      fullName
    }
  }
`;
export type UpdateCustomerMutationFn = Apollo.MutationFunction<
  UpdateCustomerMutation,
  UpdateCustomerMutationVariables
>;

/**
 * __useUpdateCustomerMutation__
 *
 * To run a mutation, you first call `useUpdateCustomerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCustomerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCustomerMutation, { data, loading, error }] = useUpdateCustomerMutation({
 *   variables: {
 *      updateCustomerInput: // value for 'updateCustomerInput'
 *      customerId: // value for 'customerId'
 *   },
 * });
 */
export function useUpdateCustomerMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateCustomerMutation, UpdateCustomerMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateCustomerMutation, UpdateCustomerMutationVariables>(
    UpdateCustomerDocument,
    options,
  );
}
export type UpdateCustomerMutationHookResult = ReturnType<typeof useUpdateCustomerMutation>;
export type UpdateCustomerMutationResult = Apollo.MutationResult<UpdateCustomerMutation>;
export type UpdateCustomerMutationOptions = Apollo.BaseMutationOptions<
  UpdateCustomerMutation,
  UpdateCustomerMutationVariables
>;
export const GetCustomerTimelineDocument = gql`
  query GetCustomerTimeline($orderIn: SortOrder!, $customerId: Int!) {
    getTimeline(orderIn: $orderIn, customerId: $customerId) {
      createdAt
      description
    }
  }
`;

/**
 * __useGetCustomerTimelineQuery__
 *
 * To run a query within a React component, call `useGetCustomerTimelineQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCustomerTimelineQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCustomerTimelineQuery({
 *   variables: {
 *      orderIn: // value for 'orderIn'
 *      customerId: // value for 'customerId'
 *   },
 * });
 */
export function useGetCustomerTimelineQuery(
  baseOptions: Apollo.QueryHookOptions<GetCustomerTimelineQuery, GetCustomerTimelineQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetCustomerTimelineQuery, GetCustomerTimelineQueryVariables>(
    GetCustomerTimelineDocument,
    options,
  );
}
export function useGetCustomerTimelineLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCustomerTimelineQuery,
    GetCustomerTimelineQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetCustomerTimelineQuery, GetCustomerTimelineQueryVariables>(
    GetCustomerTimelineDocument,
    options,
  );
}
export type GetCustomerTimelineQueryHookResult = ReturnType<typeof useGetCustomerTimelineQuery>;
export type GetCustomerTimelineLazyQueryHookResult = ReturnType<
  typeof useGetCustomerTimelineLazyQuery
>;
export type GetCustomerTimelineQueryResult = Apollo.QueryResult<
  GetCustomerTimelineQuery,
  GetCustomerTimelineQueryVariables
>;
export const GetLeadsDocument = gql`
  query GetLeads($filter: UserFilter, $sort: UserSortInput, $pageSize: Int, $page: Int) {
    getUsers(filter: $filter, sort: $sort, pageSize: $pageSize, page: $page) {
      users {
        id
        firstName
        lastName
        fullName
        email
        createdAt
        lastLogin
      }
      meta {
        currentPage
        isFirstPage
        isLastPage
        nextPage
        pageCount
        previousPage
        totalCount
      }
    }
  }
`;

/**
 * __useGetLeadsQuery__
 *
 * To run a query within a React component, call `useGetLeadsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLeadsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLeadsQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      sort: // value for 'sort'
 *      pageSize: // value for 'pageSize'
 *      page: // value for 'page'
 *   },
 * });
 */
export function useGetLeadsQuery(
  baseOptions?: Apollo.QueryHookOptions<GetLeadsQuery, GetLeadsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetLeadsQuery, GetLeadsQueryVariables>(GetLeadsDocument, options);
}
export function useGetLeadsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetLeadsQuery, GetLeadsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetLeadsQuery, GetLeadsQueryVariables>(GetLeadsDocument, options);
}
export type GetLeadsQueryHookResult = ReturnType<typeof useGetLeadsQuery>;
export type GetLeadsLazyQueryHookResult = ReturnType<typeof useGetLeadsLazyQuery>;
export type GetLeadsQueryResult = Apollo.QueryResult<GetLeadsQuery, GetLeadsQueryVariables>;
export const CreateAddressDocument = gql`
  mutation CreateAddress($createAddressInput: CreateAddressInput!) {
    createAddress(createAddressInput: $createAddressInput) {
      id
    }
  }
`;
export type CreateAddressMutationFn = Apollo.MutationFunction<
  CreateAddressMutation,
  CreateAddressMutationVariables
>;

/**
 * __useCreateAddressMutation__
 *
 * To run a mutation, you first call `useCreateAddressMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateAddressMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createAddressMutation, { data, loading, error }] = useCreateAddressMutation({
 *   variables: {
 *      createAddressInput: // value for 'createAddressInput'
 *   },
 * });
 */
export function useCreateAddressMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateAddressMutation, CreateAddressMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateAddressMutation, CreateAddressMutationVariables>(
    CreateAddressDocument,
    options,
  );
}
export type CreateAddressMutationHookResult = ReturnType<typeof useCreateAddressMutation>;
export type CreateAddressMutationResult = Apollo.MutationResult<CreateAddressMutation>;
export type CreateAddressMutationOptions = Apollo.BaseMutationOptions<
  CreateAddressMutation,
  CreateAddressMutationVariables
>;
export const UpdateAddressDocument = gql`
  mutation UpdateAddress($updateAddressInput: UpdateAddressInput!, $updateAddressId: Int!) {
    updateAddress(updateAddressInput: $updateAddressInput, id: $updateAddressId) {
      id
    }
  }
`;
export type UpdateAddressMutationFn = Apollo.MutationFunction<
  UpdateAddressMutation,
  UpdateAddressMutationVariables
>;

/**
 * __useUpdateAddressMutation__
 *
 * To run a mutation, you first call `useUpdateAddressMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAddressMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAddressMutation, { data, loading, error }] = useUpdateAddressMutation({
 *   variables: {
 *      updateAddressInput: // value for 'updateAddressInput'
 *      updateAddressId: // value for 'updateAddressId'
 *   },
 * });
 */
export function useUpdateAddressMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateAddressMutation, UpdateAddressMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateAddressMutation, UpdateAddressMutationVariables>(
    UpdateAddressDocument,
    options,
  );
}
export type UpdateAddressMutationHookResult = ReturnType<typeof useUpdateAddressMutation>;
export type UpdateAddressMutationResult = Apollo.MutationResult<UpdateAddressMutation>;
export type UpdateAddressMutationOptions = Apollo.BaseMutationOptions<
  UpdateAddressMutation,
  UpdateAddressMutationVariables
>;
export const DeleteAddressDocument = gql`
  mutation DeleteAddress($deleteAddressId: Int!) {
    deleteAddress(id: $deleteAddressId) {
      success
    }
  }
`;
export type DeleteAddressMutationFn = Apollo.MutationFunction<
  DeleteAddressMutation,
  DeleteAddressMutationVariables
>;

/**
 * __useDeleteAddressMutation__
 *
 * To run a mutation, you first call `useDeleteAddressMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteAddressMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteAddressMutation, { data, loading, error }] = useDeleteAddressMutation({
 *   variables: {
 *      deleteAddressId: // value for 'deleteAddressId'
 *   },
 * });
 */
export function useDeleteAddressMutation(
  baseOptions?: Apollo.MutationHookOptions<DeleteAddressMutation, DeleteAddressMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteAddressMutation, DeleteAddressMutationVariables>(
    DeleteAddressDocument,
    options,
  );
}
export type DeleteAddressMutationHookResult = ReturnType<typeof useDeleteAddressMutation>;
export type DeleteAddressMutationResult = Apollo.MutationResult<DeleteAddressMutation>;
export type DeleteAddressMutationOptions = Apollo.BaseMutationOptions<
  DeleteAddressMutation,
  DeleteAddressMutationVariables
>;
export const GetCoachReviewsDocument = gql`
  query GetCoachReviews($coachId: Int!, $sort: ReviewSortInput, $pageSize: Int, $page: Int) {
    getCoachReviews(coachId: $coachId, pageSize: $pageSize, page: $page, sort: $sort) {
      reviews {
        id
        comment
        rating
        coachId
        reviewedBy
        createdAt
        updatedAt
        ReviewedBy {
          fullName
          id
        }
      }
      meta {
        currentPage
        totalCount
      }
    }
  }
`;

/**
 * __useGetCoachReviewsQuery__
 *
 * To run a query within a React component, call `useGetCoachReviewsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCoachReviewsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCoachReviewsQuery({
 *   variables: {
 *      coachId: // value for 'coachId'
 *      sort: // value for 'sort'
 *      pageSize: // value for 'pageSize'
 *      page: // value for 'page'
 *   },
 * });
 */
export function useGetCoachReviewsQuery(
  baseOptions: Apollo.QueryHookOptions<GetCoachReviewsQuery, GetCoachReviewsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetCoachReviewsQuery, GetCoachReviewsQueryVariables>(
    GetCoachReviewsDocument,
    options,
  );
}
export function useGetCoachReviewsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetCoachReviewsQuery, GetCoachReviewsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetCoachReviewsQuery, GetCoachReviewsQueryVariables>(
    GetCoachReviewsDocument,
    options,
  );
}
export type GetCoachReviewsQueryHookResult = ReturnType<typeof useGetCoachReviewsQuery>;
export type GetCoachReviewsLazyQueryHookResult = ReturnType<typeof useGetCoachReviewsLazyQuery>;
export type GetCoachReviewsQueryResult = Apollo.QueryResult<
  GetCoachReviewsQuery,
  GetCoachReviewsQueryVariables
>;
export const DeleteCoachReviewDocument = gql`
  mutation DeleteCoachReview($reviewId: Int!) {
    deleteCoachReview(reviewId: $reviewId) {
      success
      reviewId
    }
  }
`;
export type DeleteCoachReviewMutationFn = Apollo.MutationFunction<
  DeleteCoachReviewMutation,
  DeleteCoachReviewMutationVariables
>;

/**
 * __useDeleteCoachReviewMutation__
 *
 * To run a mutation, you first call `useDeleteCoachReviewMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCoachReviewMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCoachReviewMutation, { data, loading, error }] = useDeleteCoachReviewMutation({
 *   variables: {
 *      reviewId: // value for 'reviewId'
 *   },
 * });
 */
export function useDeleteCoachReviewMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteCoachReviewMutation,
    DeleteCoachReviewMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteCoachReviewMutation, DeleteCoachReviewMutationVariables>(
    DeleteCoachReviewDocument,
    options,
  );
}
export type DeleteCoachReviewMutationHookResult = ReturnType<typeof useDeleteCoachReviewMutation>;
export type DeleteCoachReviewMutationResult = Apollo.MutationResult<DeleteCoachReviewMutation>;
export type DeleteCoachReviewMutationOptions = Apollo.BaseMutationOptions<
  DeleteCoachReviewMutation,
  DeleteCoachReviewMutationVariables
>;
export const GetEmployeeInternalNotesDocument = gql`
  query GetEmployeeInternalNotes(
    $pagination: PaginationInput!
    $sort: InternalNotesSortOptions
    $filter: InternalNotesFilterOptions
  ) {
    getInternalNotes(pagination: $pagination, sort: $sort, filter: $filter) {
      results {
        id
        note
        createdAt
        updatedAt
        userId
        Users {
          id
          createdAt
          updatedAt
          fullName
        }
        employeeId
        Employee {
          id
          createdAt
          updatedAt
          fullName
        }
      }
    }
  }
`;

/**
 * __useGetEmployeeInternalNotesQuery__
 *
 * To run a query within a React component, call `useGetEmployeeInternalNotesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEmployeeInternalNotesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEmployeeInternalNotesQuery({
 *   variables: {
 *      pagination: // value for 'pagination'
 *      sort: // value for 'sort'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useGetEmployeeInternalNotesQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetEmployeeInternalNotesQuery,
    GetEmployeeInternalNotesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetEmployeeInternalNotesQuery, GetEmployeeInternalNotesQueryVariables>(
    GetEmployeeInternalNotesDocument,
    options,
  );
}
export function useGetEmployeeInternalNotesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetEmployeeInternalNotesQuery,
    GetEmployeeInternalNotesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetEmployeeInternalNotesQuery, GetEmployeeInternalNotesQueryVariables>(
    GetEmployeeInternalNotesDocument,
    options,
  );
}
export type GetEmployeeInternalNotesQueryHookResult = ReturnType<
  typeof useGetEmployeeInternalNotesQuery
>;
export type GetEmployeeInternalNotesLazyQueryHookResult = ReturnType<
  typeof useGetEmployeeInternalNotesLazyQuery
>;
export type GetEmployeeInternalNotesQueryResult = Apollo.QueryResult<
  GetEmployeeInternalNotesQuery,
  GetEmployeeInternalNotesQueryVariables
>;
export const GetFilesDocument = gql`
  query GetFiles($pageSize: Int, $page: Int, $sort: FilesSort, $filter: FilesFilter) {
    getFiles(pageSize: $pageSize, page: $page, sort: $sort, filter: $filter) {
      files {
        id
        name
        type
        size
        key
        uploadBy
        userId
        agentId
        customerId
        createdAt
        updatedAt
        downloadUrl
        UploadBy {
          fullName
        }
      }
      meta {
        currentPage
        totalCount
      }
    }
  }
`;

/**
 * __useGetFilesQuery__
 *
 * To run a query within a React component, call `useGetFilesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFilesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFilesQuery({
 *   variables: {
 *      pageSize: // value for 'pageSize'
 *      page: // value for 'page'
 *      sort: // value for 'sort'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useGetFilesQuery(
  baseOptions?: Apollo.QueryHookOptions<GetFilesQuery, GetFilesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetFilesQuery, GetFilesQueryVariables>(GetFilesDocument, options);
}
export function useGetFilesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetFilesQuery, GetFilesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetFilesQuery, GetFilesQueryVariables>(GetFilesDocument, options);
}
export type GetFilesQueryHookResult = ReturnType<typeof useGetFilesQuery>;
export type GetFilesLazyQueryHookResult = ReturnType<typeof useGetFilesLazyQuery>;
export type GetFilesQueryResult = Apollo.QueryResult<GetFilesQuery, GetFilesQueryVariables>;
export const AssignCoachDocument = gql`
  mutation AssignCoach($customerId: Int!, $userId: Int!) {
    assignCoach(customerId: $customerId, userId: $userId) {
      id
      userId
      customerId
      role
      Users {
        id
        firstName
        lastName
        email
        fullName
        phoneNumber
        createdAt
        updatedAt
        defaultTab
        talkJsId
        language
        disabled
        emailVerified
        role
        lastLogin
        webPushSubscription
        bio
        profilePicture
        firebaseToken
      }
    }
  }
`;
export type AssignCoachMutationFn = Apollo.MutationFunction<
  AssignCoachMutation,
  AssignCoachMutationVariables
>;

/**
 * __useAssignCoachMutation__
 *
 * To run a mutation, you first call `useAssignCoachMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAssignCoachMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [assignCoachMutation, { data, loading, error }] = useAssignCoachMutation({
 *   variables: {
 *      customerId: // value for 'customerId'
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useAssignCoachMutation(
  baseOptions?: Apollo.MutationHookOptions<AssignCoachMutation, AssignCoachMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<AssignCoachMutation, AssignCoachMutationVariables>(
    AssignCoachDocument,
    options,
  );
}
export type AssignCoachMutationHookResult = ReturnType<typeof useAssignCoachMutation>;
export type AssignCoachMutationResult = Apollo.MutationResult<AssignCoachMutation>;
export type AssignCoachMutationOptions = Apollo.BaseMutationOptions<
  AssignCoachMutation,
  AssignCoachMutationVariables
>;
export const GetCustomerInternalNotesDocument = gql`
  query GetCustomerInternalNotes($customerId: Int!, $pagination: PaginationInput!) {
    getCustomerInternalNotes(customerId: $customerId, pagination: $pagination) {
      meta {
        currentPage
        isFirstPage
        isLastPage
        previousPage
        nextPage
        pageCount
        totalCount
      }
      results {
        note
        serviceRequestId
        coachVisitId
        userId
        createdAt
        updatedAt
        id
        Users {
          id
          createdAt
          updatedAt
          fullName
        }
      }
    }
  }
`;

/**
 * __useGetCustomerInternalNotesQuery__
 *
 * To run a query within a React component, call `useGetCustomerInternalNotesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCustomerInternalNotesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCustomerInternalNotesQuery({
 *   variables: {
 *      customerId: // value for 'customerId'
 *      pagination: // value for 'pagination'
 *   },
 * });
 */
export function useGetCustomerInternalNotesQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetCustomerInternalNotesQuery,
    GetCustomerInternalNotesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetCustomerInternalNotesQuery, GetCustomerInternalNotesQueryVariables>(
    GetCustomerInternalNotesDocument,
    options,
  );
}
export function useGetCustomerInternalNotesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCustomerInternalNotesQuery,
    GetCustomerInternalNotesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetCustomerInternalNotesQuery, GetCustomerInternalNotesQueryVariables>(
    GetCustomerInternalNotesDocument,
    options,
  );
}
export type GetCustomerInternalNotesQueryHookResult = ReturnType<
  typeof useGetCustomerInternalNotesQuery
>;
export type GetCustomerInternalNotesLazyQueryHookResult = ReturnType<
  typeof useGetCustomerInternalNotesLazyQuery
>;
export type GetCustomerInternalNotesQueryResult = Apollo.QueryResult<
  GetCustomerInternalNotesQuery,
  GetCustomerInternalNotesQueryVariables
>;
export const GetUsersFullNamesDocument = gql`
  query GetUsersFullNames($userIds: [Int!]!) {
    getUsersFullNames(userIds: $userIds) {
      userId
      fullName
    }
  }
`;

/**
 * __useGetUsersFullNamesQuery__
 *
 * To run a query within a React component, call `useGetUsersFullNamesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUsersFullNamesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUsersFullNamesQuery({
 *   variables: {
 *      userIds: // value for 'userIds'
 *   },
 * });
 */
export function useGetUsersFullNamesQuery(
  baseOptions: Apollo.QueryHookOptions<GetUsersFullNamesQuery, GetUsersFullNamesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetUsersFullNamesQuery, GetUsersFullNamesQueryVariables>(
    GetUsersFullNamesDocument,
    options,
  );
}
export function useGetUsersFullNamesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetUsersFullNamesQuery,
    GetUsersFullNamesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetUsersFullNamesQuery, GetUsersFullNamesQueryVariables>(
    GetUsersFullNamesDocument,
    options,
  );
}
export type GetUsersFullNamesQueryHookResult = ReturnType<typeof useGetUsersFullNamesQuery>;
export type GetUsersFullNamesLazyQueryHookResult = ReturnType<typeof useGetUsersFullNamesLazyQuery>;
export type GetUsersFullNamesQueryResult = Apollo.QueryResult<
  GetUsersFullNamesQuery,
  GetUsersFullNamesQueryVariables
>;
export const GetUserNotificationSettingsByUserIdDocument = gql`
  query GetUserNotificationSettingsByUserId($userId: Int!, $notificationType: String!) {
    getUserNotificationSettingsByUserId(userId: $userId, notificationType: $notificationType) {
      id
      userId
      notificationType
      email
      sms
      push
      pushWeb
      createdAt
    }
  }
`;

/**
 * __useGetUserNotificationSettingsByUserIdQuery__
 *
 * To run a query within a React component, call `useGetUserNotificationSettingsByUserIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserNotificationSettingsByUserIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserNotificationSettingsByUserIdQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *      notificationType: // value for 'notificationType'
 *   },
 * });
 */
export function useGetUserNotificationSettingsByUserIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetUserNotificationSettingsByUserIdQuery,
    GetUserNotificationSettingsByUserIdQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetUserNotificationSettingsByUserIdQuery,
    GetUserNotificationSettingsByUserIdQueryVariables
  >(GetUserNotificationSettingsByUserIdDocument, options);
}
export function useGetUserNotificationSettingsByUserIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetUserNotificationSettingsByUserIdQuery,
    GetUserNotificationSettingsByUserIdQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetUserNotificationSettingsByUserIdQuery,
    GetUserNotificationSettingsByUserIdQueryVariables
  >(GetUserNotificationSettingsByUserIdDocument, options);
}
export type GetUserNotificationSettingsByUserIdQueryHookResult = ReturnType<
  typeof useGetUserNotificationSettingsByUserIdQuery
>;
export type GetUserNotificationSettingsByUserIdLazyQueryHookResult = ReturnType<
  typeof useGetUserNotificationSettingsByUserIdLazyQuery
>;
export type GetUserNotificationSettingsByUserIdQueryResult = Apollo.QueryResult<
  GetUserNotificationSettingsByUserIdQuery,
  GetUserNotificationSettingsByUserIdQueryVariables
>;
export const CreateWebPushSubscriptionDocument = gql`
  mutation CreateWebPushSubscription($webPushSubscription: WebPushSubscription!) {
    createWebPushSubscription(webPushSubscription: $webPushSubscription)
  }
`;
export type CreateWebPushSubscriptionMutationFn = Apollo.MutationFunction<
  CreateWebPushSubscriptionMutation,
  CreateWebPushSubscriptionMutationVariables
>;

/**
 * __useCreateWebPushSubscriptionMutation__
 *
 * To run a mutation, you first call `useCreateWebPushSubscriptionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateWebPushSubscriptionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createWebPushSubscriptionMutation, { data, loading, error }] = useCreateWebPushSubscriptionMutation({
 *   variables: {
 *      webPushSubscription: // value for 'webPushSubscription'
 *   },
 * });
 */
export function useCreateWebPushSubscriptionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateWebPushSubscriptionMutation,
    CreateWebPushSubscriptionMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateWebPushSubscriptionMutation,
    CreateWebPushSubscriptionMutationVariables
  >(CreateWebPushSubscriptionDocument, options);
}
export type CreateWebPushSubscriptionMutationHookResult = ReturnType<
  typeof useCreateWebPushSubscriptionMutation
>;
export type CreateWebPushSubscriptionMutationResult =
  Apollo.MutationResult<CreateWebPushSubscriptionMutation>;
export type CreateWebPushSubscriptionMutationOptions = Apollo.BaseMutationOptions<
  CreateWebPushSubscriptionMutation,
  CreateWebPushSubscriptionMutationVariables
>;
export const DestroyWebPushSubscriptionDocument = gql`
  mutation DestroyWebPushSubscription($data: UnsubscribeInput!) {
    destroyWebPushSubscription(data: $data)
  }
`;
export type DestroyWebPushSubscriptionMutationFn = Apollo.MutationFunction<
  DestroyWebPushSubscriptionMutation,
  DestroyWebPushSubscriptionMutationVariables
>;

/**
 * __useDestroyWebPushSubscriptionMutation__
 *
 * To run a mutation, you first call `useDestroyWebPushSubscriptionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDestroyWebPushSubscriptionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [destroyWebPushSubscriptionMutation, { data, loading, error }] = useDestroyWebPushSubscriptionMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useDestroyWebPushSubscriptionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DestroyWebPushSubscriptionMutation,
    DestroyWebPushSubscriptionMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DestroyWebPushSubscriptionMutation,
    DestroyWebPushSubscriptionMutationVariables
  >(DestroyWebPushSubscriptionDocument, options);
}
export type DestroyWebPushSubscriptionMutationHookResult = ReturnType<
  typeof useDestroyWebPushSubscriptionMutation
>;
export type DestroyWebPushSubscriptionMutationResult =
  Apollo.MutationResult<DestroyWebPushSubscriptionMutation>;
export type DestroyWebPushSubscriptionMutationOptions = Apollo.BaseMutationOptions<
  DestroyWebPushSubscriptionMutation,
  DestroyWebPushSubscriptionMutationVariables
>;
