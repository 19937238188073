import { useCallback, useMemo, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import {
  Conversation,
  FileSortableFields,
  GlobalRole,
  GraphqlUser,
  SortOrder,
  UpdateCustomerInput,
  UserSortableFields,
  useGetCustomerQuery,
  useGetCustomerTimelineQuery,
  useGetConversationsByCustomerQuery,
  useGetConversationByIdLazyQuery,
  useUpdateCustomerMutation,
  useGetUsersQuery,
  useAssignCoachMutation,
  useGetCustomerInternalNotesQuery,
  GraphqlInternalNote,
  useSyncCustomerChatMutation,
  useGetUsersFullNamesQuery,
} from '@graphql/generated';
import { ApolloError } from '@apollo/client';
import { GET_CUSTOMER_QUERY } from '@graphql/users';

import ROUTES from '@constants/routes';

import { message, Select, Table, Timeline, Typography } from 'antd';
import { ColumnsType } from 'antd/es/table';
import Link from '@components/Link';
import Card from '@components/Card/Card';
import Loading from '@components/Loading';
import GenericApolloError from '@components/GenericApolloError';
import RecordDetailCard from '@components/Card/RecordDetailCard';
import Masonry, { ResponsiveMasonry } from 'react-responsive-masonry';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import CustomerAddressesCard from '@components/Customer/CustomerAddressesCard';
import { EmployeeInternalFiles } from '@routes/users/components/EmployeeInternalFiles';

import dayjs from 'dayjs';

function Customer() {
  const i18n = useTranslation();

  // const [editMode, setEditMode] = useState<boolean>(false);
  const [chatMode, setChatMode] = useState<string | null>(null);
  const { customerId } = useParams<{ customerId: string }>();
  const {
    data: customerData,
    loading: customerLoading,
    error: customerError,
  } = useGetCustomerQuery({
    variables: { customerId: parseInt(customerId as string, 10) },
  });
  const {
    data: customerConversationsData,
    loading: customerConversationsLoading,
    error: customerConversationsError,
  } = useGetConversationsByCustomerQuery({
    variables: { customerId: parseInt(customerId as string, 10) },
  });
  const {
    data: customerTimelineData,
    loading: customerTimelineLoading,
    error: customerTimelineError,
  } = useGetCustomerTimelineQuery({
    variables: {
      orderIn: 'asc' as SortOrder,
      customerId: parseInt(customerId as string, 10),
    },
  });
  const { data: coachesData, loading: coachesLoading } = useGetUsersQuery({
    variables: {
      filter: { roles: [GlobalRole.Coach] },
      page: 1,
      pageSize: 100,
      sort: { field: UserSortableFields.FullName, direction: SortOrder.Asc },
    },
  });
  const { data: notesData, loading: notesLoading } = useGetCustomerInternalNotesQuery({
    variables: {
      customerId: parseInt(customerId as string, 10),
      pagination: {
        page: 1,
        limit: 100,
      },
    },
  });
  const [getConversationById, { data: conversationById, loading: conversationByIdLoading }] =
    useGetConversationByIdLazyQuery();
  const [updateCustomer, { loading: isUpdatingCustomer }] = useUpdateCustomerMutation({
    refetchQueries: [GET_CUSTOMER_QUERY],
  });

  const [syncCustomerChat, { loading: isSyncChat }] = useSyncCustomerChatMutation();

  const [assignCoach, { loading: isUpdatingCoach }] = useAssignCoachMutation({
    refetchQueries: [GET_CUSTOMER_QUERY],
  });

  const customerFetched = customerData?.getCustomer;
  const conversations = customerConversationsData?.getConversationsByCustomer;
  let allUserIds: Array<number> = [];
  conversations?.forEach((conversation) => {
    const userIds = conversation?.custom?.userIds ? conversation?.custom?.userIds.split(',') : [];
    allUserIds = allUserIds.concat(userIds.map((id) => Number(id)));
  });
  const uniqueUserIds = Array.from(new Set(allUserIds));

  const { data } = useGetUsersFullNamesQuery({
    variables: { userIds: uniqueUserIds },
    skip: !uniqueUserIds || uniqueUserIds.length === 0, // This will skip the query if condition is true
  });

  const columns: ColumnsType<Conversation> = useMemo(
    () => [
      {
        title: i18n.t('manage.customer.table.columns.participants') as string,
        dataIndex: 'participants',
        key: 'participants',
        render: (_: any, record: Conversation) => {
          const participants = record?.custom?.participants?.split(',') || [];
          return participants.map((participant, index) => {
            const userId = record?.custom?.userIds?.split(',')?.[index];
            const participantUser = data?.getUsersFullNames?.find(
              (user) => user?.userId === Number(userId),
            );
            return (
              <Link
                // eslint-disable-next-line react/no-array-index-key
                key={`user-${index}`}
                to={ROUTES.MANAGE_USER.replace(':userId', String(userId))}
              >
                {participantUser?.fullName || i18n.t('fallbacks.bryaUser', { id: userId })}
                {index < participants.length - 1 ? ', ' : ''}
              </Link>
            );
          });
        },
      },
      {
        title: i18n.t('manage.customer.table.columns.lastMessage') as string,
        dataIndex: 'participants',
        key: 'lastMessage',
        render: (_: any, record: Conversation) => dayjs(record?.lastMessage?.createdAt).fromNow(),
      },
      {
        title: null,
        dataIndex: 'id',
        key: 'id',
        render: (_: any, record: Conversation) => (
          <button
            type="button"
            onClick={() => {
              setChatMode(record?.id);
              getConversationById({ variables: { conversationId: record?.id } });
            }}
          >
            <Trans i18nKey="manage.customer.chat.view" />
          </button>
        ),
      },
    ],
    [i18n, getConversationById, data?.getUsersFullNames],
  );

  const handleEditCustomer = useCallback(
    async (values: UpdateCustomerInput) => {
      await updateCustomer({
        variables: { updateCustomerInput: values, customerId: Number(customerId) },
      });
      message.success(<Trans i18nKey="forms.editUser.successMessage" />);
    },
    [updateCustomer, customerId],
  );

  const handleEditCoach = useCallback(
    async (values: any) => {
      await assignCoach({
        variables: { userId: Number(values.coach), customerId: Number(customerId) },
      });
      message.success(<Trans i18nKey="forms.editCoach.successMessage" />);
    },
    [assignCoach, customerId],
  );

  if (
    customerLoading ||
    customerTimelineLoading ||
    customerConversationsLoading ||
    coachesLoading ||
    notesLoading
  ) {
    return <Loading />;
  }

  if (customerError || customerTimelineError || customerConversationsError) {
    return (
      <GenericApolloError
        error={
          (customerError || customerTimelineError || customerConversationsError) as ApolloError
        }
      />
    );
  }

  const timelineItems = customerTimelineData?.getTimeline?.map((timelineItem) => ({
    label: `${dayjs(timelineItem.createdAt).format('MM/DD/YY')} (${dayjs(
      timelineItem.createdAt,
    ).fromNow()})`,
    children: <div>{timelineItem.description}</div>,
  }));

  return (
    <div style={{ maxWidth: 1800, margin: '0 auto' }}>
      <Typography.Title level={2}>
        <Trans
          i18nKey="manage.customer.title"
          values={{
            customerId: String(customerFetched?.id).padStart(5, '0'),
            fullName: customerFetched?.fullName,
          }}
        />
      </Typography.Title>
      <ResponsiveMasonry columnsCountBreakPoints={{ 350: 1, 1000: 2, 1400: 3 }}>
        <Masonry gutter="20px">
          <RecordDetailCard
            title={<Trans i18nKey="manage.customer.details.title" />}
            cardProps={{
              style: { minWidth: '350px' },
              loading: customerLoading || isUpdatingCustomer,
            }}
            editable
            onEdit={handleEditCustomer}
            fields={[
              {
                name: 'id',
                label: <Trans i18nKey="manage.customer.details.id" />,
                value: String(customerFetched?.id).padStart(5, '0'),
                editable: false,
              },
              {
                name: 'firstName',
                label: <Trans i18nKey="manage.customer.details.firstName" />,
                value: customerFetched?.firstName,
                editable: true,
                autoFocus: true,
                formItemProps: {
                  validateTrigger: 'onSubmit',
                  required: true,
                  rules: [
                    {
                      required: true,
                      message: i18n.t('forms.input.fullName.errorMessage') as string,
                      whitespace: true,
                    },
                  ],
                },
              },
              {
                name: 'lastName',
                label: <Trans i18nKey="manage.customer.details.lastName" />,
                value: customerFetched?.lastName,
                editable: true,
                autoFocus: true,
                formItemProps: {
                  validateTrigger: 'onSubmit',
                  required: true,
                  rules: [
                    {
                      required: true,
                      message: i18n.t('forms.input.fullName.errorMessage') as string,
                      whitespace: true,
                    },
                  ],
                },
              },
              {
                name: 'dateJoined',
                label: <Trans i18nKey="manage.customer.details.dateJoined" />,
                value: dayjs(customerFetched?.createdAt).format('MMMM D, YYYY'),
              },
              {
                name: 'lastUpdated',
                label: <Trans i18nKey="manage.customer.details.lastUpdated" />,
                value: dayjs(customerFetched?.updatedAt).format('MMMM D, YYYY @ hh:mm:ss'),
              },
            ]}
          />

          <RecordDetailCard
            title={<Trans i18nKey="manage.customer.associatedUsers.title" />}
            cardProps={{
              loading: isUpdatingCoach,
              style: { minWidth: '350px' },
            }}
            editable
            onEdit={handleEditCoach}
            fields={[
              {
                name: 'columns',
                label: <Trans i18nKey="manage.customer.associatedUsers.columns.name" />,
                value: (
                  <strong>
                    <Trans i18nKey="manage.customer.associatedUsers.columns.role" />
                  </strong>
                ),
                isColumn: true,
              },
              ...(customerFetched?.users
                ?.filter((user) => user?.role !== GlobalRole.Coach)
                ?.map((user) => {
                  return {
                    name: String(user?.user?.id),
                    label: (
                      <Link to={ROUTES.MANAGE_USER.replace(':userId', String(user?.user?.id))}>
                        {user?.user?.fullName}
                      </Link>
                    ),
                    value: (
                      <Typography.Paragraph style={{ marginBottom: 0 }}>
                        {i18n.t(`roles.${user?.role}`)}
                      </Typography.Paragraph>
                    ),
                  };
                }) || []),
              {
                name: 'coach',
                label: <Trans i18nKey="manage.customer.associatedUsers.columns.coach" />,
                // eslint-disable-next-line react/jsx-no-useless-fragment
                value: <></>,
                isColumn: true,
                editable: true,
                formElement: (
                  <Select style={{ minWidth: 200 }}>
                    {coachesData?.getUsers?.users.map((user) => (
                      <Select.Option value={user.id}>
                        {user.fullName}
                        {}{' '}
                      </Select.Option>
                    ))}
                  </Select>
                ),
              },
              ...(customerFetched?.users
                ?.filter((user) => user?.role === GlobalRole.Coach)
                ?.map((user) => {
                  return {
                    name: String(user?.user?.id),
                    label: (
                      <Link to={ROUTES.MANAGE_USER.replace(':userId', String(user?.user?.id))}>
                        {user?.user?.fullName}
                      </Link>
                    ),
                    value: (
                      <Typography.Paragraph style={{ marginBottom: 0 }}>
                        {i18n.t(`roles.${user?.role}`)}
                      </Typography.Paragraph>
                    ),
                  };
                }) || []),
            ]}
          />

          {/* This has been commented out as part of #2547 for the Pilot launch
          <RecordDetailCard
            title={<Trans i18nKey="manage.customer.appFeatures.title" />}
            cardProps={{
              style: { minWidth: '350px' },
            }}
            fields={[
              {
                name: 'seniorMember',
                label: <Trans i18nKey="manage.customer.appFeatures.columns.seniorMember" />,
                value: customerFetched?.Creator?.AppFeaturesSelected?.seniorMember
                  ? 'Upgraded'
                  : 'Not Upgraded',
              },
              {
                name: 'familyPartner',
                label: <Trans i18nKey="manage.customer.appFeatures.columns.familyPartner" />,
                value: customerFetched?.Creator?.AppFeaturesSelected?.familyPartner
                  ? 'Upgraded'
                  : 'Not Upgraded',
              },
              {
                name: 'circleMember',
                label: <Trans i18nKey="manage.customer.appFeatures.columns.circleMember" />,
                value: customerFetched?.Creator?.AppFeaturesSelected?.circleMember
                  ? 'Upgraded'
                  : 'Not Upgraded',
              },
            ]}
          />
          */}

          <CustomerAddressesCard
            addresses={customerFetched?.addresses}
            customerId={customerFetched?.id as number}
          />

          {/* TODO: Needs to update these two cards below to support multi languages */}
          <Card data-testid="block" title="Account History">
            <div>
              <Timeline mode="left" items={timelineItems} />
            </div>
          </Card>

          <Card
            data-testid="block"
            title={i18n.t('manage.customer.chat.title')}
            style={{ minHeight: 400 }}
          >
            <div>
              {chatMode && !conversationByIdLoading ? (
                <>
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'flex-end',
                      marginTop: -60,
                      marginBottom: 23,
                    }}
                  >
                    <button type="button" onClick={() => setChatMode(null)}>
                      <Trans i18nKey="manage.customer.chat.back" />
                    </button>
                    &nbsp;
                    <button type="button">
                      <Link
                        to={`${ROUTES.MANAGE_CHAT}?defaultConversationSelected=${chatMode}`}
                        style={{ fontSize: 13, color: 'black' }}
                      >
                        <Trans i18nKey="manage.customer.chat.open" />
                      </Link>
                    </button>
                  </div>
                  <iframe
                    style={{
                      height: 300,
                      width: '100%',
                      border: 0,
                      marginTop: 10,
                    }}
                    title="embedded talkjs conversation"
                    src={conversationById?.getConversationSignature?.embedUrl}
                  />
                </>
              ) : (
                <>
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'flex-end',
                      marginTop: -60,
                      marginBottom: 30,
                    }}
                  >
                    <button
                      type="button"
                      onClick={async () => {
                        try {
                          await syncCustomerChat({
                            variables: { customerId: parseInt(customerId as string, 10) },
                          });
                          message.success(<Trans i18nKey="manage.customer.chat.syncSuccess" />);
                        } catch (error) {
                          message.error(<Trans i18nKey="manage.customer.chat.syncError" />);
                        }
                      }}
                      disabled={isSyncChat}
                    >
                      <Trans i18nKey="manage.customer.chat.sync" />
                    </button>
                  </div>
                  <Table
                    loading={customerConversationsLoading}
                    columns={columns}
                    dataSource={conversations as Conversation[]}
                    pagination={false}
                  />
                </>
              )}
            </div>
          </Card>

          <Card title={i18n.t('manage.serviceRequest.headers.notes')}>
            {notesData?.getCustomerInternalNotes.results.length ? (
              <Table
                dataSource={notesData?.getCustomerInternalNotes.results}
                columns={[
                  {
                    title: <Trans i18nKey="manage.internalNotes.headers.activity" />,
                    dataIndex: 'activity',
                    key: 'activity',
                    render: (_: any, record: GraphqlInternalNote) => {
                      const isServiceRequest = !!record?.serviceRequestId;
                      const activityId = record?.serviceRequestId || record?.coachVisitId;
                      const link = isServiceRequest
                        ? ROUTES.MANAGE_SERVICE_REQUEST.replace(
                            ':activityId',
                            String(record?.serviceRequestId),
                          )
                        : ROUTES.MANAGE_COACH_VISIT.replace(
                            ':activityId',
                            String(record?.coachVisitId),
                          );
                      return (
                        <Link key={`note-${record?.id}`} to={link}>
                          {isServiceRequest ? 'SR: ' : 'CV: '}
                          {String(activityId).padStart(5, '0')}
                        </Link>
                      );
                    },
                  },
                  {
                    title: <Trans i18nKey="manage.internalNotes.headers.addedBy" />,
                    dataIndex: 'Users.fullName',
                    key: 'Users.fullName',
                    render: (_: any, record: GraphqlInternalNote) => record?.Users?.fullName,
                  },
                  {
                    title: '',
                    dataIndex: 'note',
                    key: 'note',
                  },
                ]}
                pagination={false}
              />
            ) : (
              <Trans i18nKey="manage.serviceRequest.noNotes" />
            )}
          </Card>

          <EmployeeInternalFiles
            employee={customerFetched as unknown as GraphqlUser}
            uploadRequestBody={{ customerId: Number(customerFetched?.id) }}
            filter={{ customerId: Number(customerFetched?.id) }}
            sort={{ field: FileSortableFields.CreatedAt, direction: SortOrder.Desc }}
            // NOTE: for now we don't implement pagination for internal files
            page={1}
            pageSize={1000}
          />

          <RecordDetailCard
            title={<Trans i18nKey="manage.customer.additionalInformation.title" />}
            cardProps={{
              style: { minWidth: '350px' },
              loading: customerLoading,
            }}
            fields={[
              {
                name: 'age',
                label: <Trans i18nKey="manage.customer.additionalInformation.age" />,
                value:
                  customerFetched?.CustomerAdditionalInformation?.age ||
                  i18n.t('fallbacks.notAnswered'),
                editable: false,
              },
              {
                name: 'gender',
                label: <Trans i18nKey="manage.customer.additionalInformation.gender" />,
                value:
                  customerFetched?.CustomerAdditionalInformation?.gender ||
                  i18n.t('fallbacks.notAnswered'),
                editable: false,
              },
              {
                name: 'retiredTime',
                label: <Trans i18nKey="manage.customer.additionalInformation.retiredTime" />,
                value:
                  customerFetched?.CustomerAdditionalInformation?.retiredTime ||
                  i18n.t('fallbacks.notAnswered'),
                editable: false,
              },
              {
                name: 'city',
                label: <Trans i18nKey="manage.customer.additionalInformation.city" />,
                value:
                  customerFetched?.CustomerAdditionalInformation?.city ||
                  i18n.t('fallbacks.notAnswered'),
                editable: false,
              },
              {
                name: 'state',
                label: <Trans i18nKey="manage.customer.additionalInformation.state" />,
                value:
                  customerFetched?.CustomerAdditionalInformation?.state ||
                  i18n.t('fallbacks.notAnswered'),
                editable: false,
              },
              {
                name: 'zipCode',
                label: <Trans i18nKey="manage.customer.additionalInformation.zipCode" />,
                value:
                  customerFetched?.CustomerAdditionalInformation?.zipCode ||
                  i18n.t('fallbacks.notAnswered'),
                editable: false,
              },
              {
                name: 'hobbies',
                label: <Trans i18nKey="manage.customer.additionalInformation.hobbies" />,
                value:
                  customerFetched?.CustomerAdditionalInformation?.hobbies ||
                  i18n.t('fallbacks.notAnswered'),
                editable: false,
              },
              {
                name: 'professionalBackground',
                label: (
                  <Trans i18nKey="manage.customer.additionalInformation.professionalBackground" />
                ),
                value:
                  customerFetched?.CustomerAdditionalInformation?.professionalBackground ||
                  i18n.t('fallbacks.notAnswered'),
                editable: false,
              },
              {
                name: 'educationalBackground',
                label: (
                  <Trans i18nKey="manage.customer.additionalInformation.educationalBackground" />
                ),
                value:
                  customerFetched?.CustomerAdditionalInformation?.educationalBackground ||
                  i18n.t('fallbacks.notAnswered'),
                editable: false,
              },
              {
                name: 'relationshipStatus',
                label: <Trans i18nKey="manage.customer.additionalInformation.relationshipStatus" />,
                value:
                  customerFetched?.CustomerAdditionalInformation?.relationshipStatus ||
                  i18n.t('fallbacks.notAnswered'),
                editable: false,
              },
              {
                name: 'otherInformation',
                label: <Trans i18nKey="manage.customer.additionalInformation.otherInformation" />,
                value:
                  customerFetched?.CustomerAdditionalInformation?.otherInformation ||
                  i18n.t('fallbacks.notAnswered'),
                editable: false,
              },
            ]}
          />
        </Masonry>
      </ResponsiveMasonry>
    </div>
  );
}

export default Customer;
