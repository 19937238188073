import { Form as AntdForm, ColProps, FormItemProps as AntdFormItemProps } from 'antd';
import styled from 'styled-components';

export type FormItemProps = AntdFormItemProps & {
  labelCol?: ColProps & { span: number };
  value?: any;
};

const FormItem = styled(AntdForm.Item).attrs(() => ({
  labelCol: { span: 24 },
}))`
  margin-bottom: 10px;
  .ant-form-item-label {
    font-size: ${({ theme }) => theme.fontSizes.medium};
    line-height: ${({ theme }) => theme.lineHeights.medium};
    label {
      height: ${({ theme }) => theme.lineHeights.medium};
    }
  }
  .ant-form-item-explain {
    margin-bottom: 10px;
  }
`;

const Form = AntdForm;
// TODO: it would be nice to override Form.Item
// with custom FormItem an export Form only as Antd API
// Form.Item = FormItem; but TS complains about miss type assignations.

export { Form, FormItem };
