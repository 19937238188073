/* eslint-disable jsx-a11y/anchor-is-valid */
import ROUTES from '@constants/routes';
import Modal from '@components/Modal';
import { message, Row, Space, Typography } from 'antd';
import { useEffect, useMemo, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Link, Outlet } from 'react-router-dom';
import Button from '@components/Button';
import Input from '@components/Input';
import { PlusOutlined } from '@ant-design/icons';
import { FilterValue, SorterResult, TablePaginationConfig } from 'antd/es/table/interface';
import { ColumnsType } from 'antd/es/table';
import {
  useGetUsersQuery,
  GraphqlUser,
  UserFilter,
  UserSortInput,
  InputMaybe,
  UserSortableFields,
  SortOrder,
  PaginationMeta,
  useDisableUserMutation,
  useEnableUserMutation,
  ManagementRole,
  GlobalRole,
} from '@graphql/generated';
import { GET_USERS_QUERY } from '@graphql/users';
import { formatISOtoMMDDYYYY } from '@utils/date';
import Table, { DEFAULT_PAGE_SIZE, PAGE_SIZES } from '@components/Table/Table';
import { DEFAULT_SEARCH_DEBOUNCE_TIME } from '@constants/input';

type ActivationUserModal = {
  open: boolean;
  title?: string;
  children?: string;
  confirmText?: string;
  cancelText?: string;
  onConfirm?: () => void;
};

type ManagementUsersProps = {
  searchDebounceTime?: number;
};

const ALL_MANAGEMENT_ROLES = Object.values(ManagementRole) as unknown as GlobalRole[];

function ManagementUsers({
  searchDebounceTime = DEFAULT_SEARCH_DEBOUNCE_TIME,
}: ManagementUsersProps) {
  const i18n = useTranslation();
  const [searchUserText, setSearchUserText] = useState('');
  const [filters, setFilters] = useState<UserFilter>({
    text: searchUserText,
    roles: ALL_MANAGEMENT_ROLES,
  });
  const [sortOptions, setSortOptions] = useState<InputMaybe<UserSortInput>>();
  const [pageSize, setPageSize] = useState(DEFAULT_PAGE_SIZE);
  const [page, setPage] = useState(1);
  const [activationUserModal, setActivationUserModal] = useState<ActivationUserModal>({
    open: false,
  });
  const {
    data: getUsersQuery,
    previousData,
    loading: isFetchingUsers,
  } = useGetUsersQuery({
    variables: { filter: filters, sort: sortOptions, page, pageSize },
  });
  const [disableUserMutation, { loading: isDisablingUser }] = useDisableUserMutation({
    refetchQueries: [GET_USERS_QUERY],
  });
  const [enableUserMutation, { loading: isEnablingUser }] = useEnableUserMutation({
    refetchQueries: [GET_USERS_QUERY],
  });
  const loading = isFetchingUsers || isDisablingUser || isEnablingUser;

  const users = ((getUsersQuery || previousData)?.getUsers.users as GraphqlUser[]) || [];
  const pagination = ((getUsersQuery || previousData)?.getUsers.meta as PaginationMeta) || {};

  useEffect(() => {
    const timer = setTimeout(() => {
      setFilters((previousFilters) => ({ ...previousFilters, text: searchUserText }));
    }, searchDebounceTime);
    return () => clearTimeout(timer);
  }, [searchUserText, searchDebounceTime]);

  const columns: ColumnsType<GraphqlUser> = useMemo(
    () => [
      {
        title: i18n.t('manage.managementUsers.table.columns.fullName') as string,
        dataIndex: 'fullName',
        key: 'fullName',
        sorter: true,
        render: (_: any, record: GraphqlUser) => (
          <Link to={ROUTES.MANAGE_USER.replace(':userId', String(record.id))}>
            {record.fullName}
          </Link>
        ),
      },
      {
        title: i18n.t('manage.managementUsers.table.columns.email') as string,
        dataIndex: 'email',
        key: 'email',
        sorter: true,
      },
      {
        title: i18n.t('manage.managementUsers.table.columns.role') as string,
        dataIndex: 'role',
        key: 'role',
        render: (_: any, record: any) => {
          return i18n.t(`roles.${record.role}`) as string;
        },
        filters: ALL_MANAGEMENT_ROLES.map((role) => {
          const roleText = i18n.t(`roles.${role}`) as string;
          return { text: roleText, value: role };
        }),
      },
      {
        title: i18n.t('manage.managementUsers.table.columns.createdAt') as string,
        dataIndex: 'createdAt',
        key: 'createdAt',
        sorter: true,
        render: (_: any, record: any) => formatISOtoMMDDYYYY(record.createdAt),
      },
      {
        title: i18n.t('manage.managementUsers.table.columns.actions') as string,
        key: 'actions',
        render: (_: any, record: any) => (
          <Space size="middle">
            <Link
              to={ROUTES.MANAGE_MANAGEMENT_USERS_EDIT.replace(':userId', String(record.id))}
              state={{ ...record, role: record.role }}
            >
              <Trans i18nKey="manage.managementUsers.table.actions.edit" />
            </Link>
            {record.disabled ? (
              <Link
                to=""
                onClick={() =>
                  setActivationUserModal({
                    open: true,
                    title: i18n.t(
                      'manage.managementUsers.activationUserModal.activate.title',
                    ) as string,
                    children: i18n.t('manage.managementUsers.activationUserModal.activate.body', {
                      fullName: record.fullName,
                    }) as string,
                    confirmText: i18n.t(
                      'manage.managementUsers.activationUserModal.activate.confirmText',
                    ) as string,
                    cancelText: i18n.t(
                      'manage.managementUsers.activationUserModal.activate.cancelText',
                    ) as string,
                    onConfirm: async () => {
                      setActivationUserModal({ open: false });
                      await enableUserMutation({ variables: { enableUserId: record.id } });
                      message.info(
                        i18n.t(
                          'manage.managementUsers.activationUserModal.activate.confirmationMessage',
                          {
                            fullName: record.fullName,
                          },
                        ) as string,
                      );
                    },
                  })
                }
              >
                <Trans i18nKey="manage.managementUsers.table.actions.activate" />
              </Link>
            ) : (
              <Link
                to=""
                onClick={() =>
                  setActivationUserModal({
                    open: true,
                    title: i18n.t(
                      'manage.managementUsers.activationUserModal.deactivate.title',
                    ) as string,
                    children: i18n.t('manage.managementUsers.activationUserModal.deactivate.body', {
                      fullName: record.fullName,
                    }) as string,
                    confirmText: i18n.t(
                      'manage.managementUsers.activationUserModal.deactivate.confirmText',
                    ) as string,
                    cancelText: i18n.t(
                      'manage.managementUsers.activationUserModal.deactivate.cancelText',
                    ) as string,
                    onConfirm: async () => {
                      setActivationUserModal({ open: false });
                      await disableUserMutation({ variables: { disableUserId: record.id } });
                      message.info(
                        i18n.t(
                          'manage.managementUsers.activationUserModal.deactivate.confirmationMessage',
                          { fullName: record.fullName },
                        ) as string,
                      );
                    },
                  })
                }
              >
                <Trans i18nKey="manage.managementUsers.table.actions.deactivate" />
              </Link>
            )}
          </Space>
        ),
      },
    ],
    [i18n, enableUserMutation, disableUserMutation],
  );

  const onTableChange = (
    tablePagination: TablePaginationConfig,
    tableFilters: Record<string, FilterValue | null>,
    tableSorter: SorterResult<GraphqlUser> | SorterResult<GraphqlUser>[],
  ) => {
    const { role } = tableFilters;
    const uniqueSorter = tableSorter as SorterResult<GraphqlUser>;

    // Pagination
    setPage(tablePagination.current as number);
    setPageSize(tablePagination.pageSize as number);

    // Order
    if (uniqueSorter.order) {
      const direction = uniqueSorter.order === 'ascend' ? 'asc' : 'desc';
      setSortOptions({
        field: uniqueSorter.field as UserSortableFields,
        direction: direction as SortOrder,
      });
    } else {
      setSortOptions(undefined);
    }

    // Filter
    // If no role is selected, we need to filter by all management roles in the backend
    const rolesFilter = !role || role?.length === 0 ? ALL_MANAGEMENT_ROLES : role;
    setFilters((prevFilters) => ({ ...prevFilters, roles: rolesFilter as Array<GlobalRole> }));
  };

  return (
    <>
      <Row justify="space-between" align="middle" style={{ marginBottom: '20px' }}>
        <Typography.Title level={2}>
          <Trans i18nKey="manage.managementUsers.title" />
        </Typography.Title>
        <Link to={ROUTES.MANAGE_MANAGEMENT_USERS_CREATE}>
          <Button type="primary">
            <PlusOutlined style={{ marginRight: '10px' }} />
            <Trans i18nKey="manage.managementUsers.createUser" />
          </Button>
        </Link>
      </Row>
      <Row style={{ marginBottom: '20px' }}>
        <Input
          placeholder={i18n.t('manage.managementUsers.searchUser') as string}
          value={searchUserText}
          onChange={(e) => setSearchUserText(e.target.value)}
        />
      </Row>
      <Table
        loading={loading}
        columns={columns}
        dataSource={users.map((user) => ({ ...user, key: user.id }))}
        onChange={onTableChange}
        pagination={{
          pageSize,
          pageSizeOptions: PAGE_SIZES,
          showSizeChanger: true,
          defaultCurrent: 1,
          current: pagination?.currentPage,
          total: pagination?.totalCount,
        }}
      />
      <Modal
        width={400}
        onCancel={() => setActivationUserModal({ open: false })}
        {...activationUserModal}
      />
      <Outlet />
    </>
  );
}

ManagementUsers.defaultProps = {
  searchDebounceTime: DEFAULT_SEARCH_DEBOUNCE_TIME,
};

export default ManagementUsers;
