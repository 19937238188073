import styled from 'styled-components';
import { Trans, useTranslation } from 'react-i18next';
import { GET_COACH_VISIT_QUERY } from '@graphql/coachVisits';
import {
  CreateVisitSummaryInput,
  GraphqlUser,
  useCreateVisitSummaryMutation,
} from '@graphql/generated';

import { Form, Select, Typography } from 'antd';
import Modal from '@components/Modal';
import Button from '@components/Button';
import { InputTextarea } from '@components/Input';

const FormItem = styled(Form.Item).attrs(() => ({
  labelCol: { span: 24 },
}))`
  .ant-form-item-label {
    padding: 0px !important;
  }
`;

function CreateVisitSummaryModal({
  onCancel,
  coachVisitId,
  users,
}: {
  onCancel: () => void;
  coachVisitId: number;
  users: GraphqlUser[];
}) {
  const i18n = useTranslation();
  const [form] = Form.useForm();
  const [createVisitSummaryMutation, { loading: createVisitSummaryLoading }] =
    useCreateVisitSummaryMutation({
      refetchQueries: [GET_COACH_VISIT_QUERY],
    });

  return (
    <Modal
      width={750}
      closable
      onCancel={onCancel}
      open
      title={
        <Typography.Title level={2}>
          <Trans i18nKey="manage.coachVisit.actions.newSummary" />
        </Typography.Title>
      }
    >
      <Form
        form={form}
        initialValues={{ recurring: false }}
        onFinish={async (input) => {
          await createVisitSummaryMutation({
            variables: {
              input: {
                ...(input as CreateVisitSummaryInput),
                coachVisitId,
              },
            },
          });
          onCancel();
        }}
      >
        <FormItem
          name="sentTo"
          label={i18n.t('forms.input.sentTo.label')}
          rules={[
            {
              required: true,
              message: i18n.t('forms.input.sentTo.errorMessage') as string,
            },
          ]}
        >
          <Select
            mode="multiple"
            placeholder={String(i18n.t('forms.input.sentTo.placeholder'))}
            optionFilterProp="label"
            options={users?.map((user) => ({
              label: user?.fullName,
              value: user?.id,
            }))}
          />
        </FormItem>
        <FormItem
          name="body"
          label={i18n.t('forms.input.body.label')}
          rules={[{ required: true, message: i18n.t('forms.input.body.errorMessage') as string }]}
        >
          <InputTextarea placeholder={String(i18n.t('forms.input.body.placeholder'))} />
        </FormItem>
        <FormItem>
          <Button type="primary" htmlType="submit" loading={createVisitSummaryLoading}>
            <Trans i18nKey="manage.coachVisit.actions.createSummary" />
          </Button>
        </FormItem>
      </Form>
    </Modal>
  );
}

export default CreateVisitSummaryModal;
