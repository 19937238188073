import { useCallback, useMemo } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import {
  AdminUpdateUserInput,
  CustomerSortableFields,
  FileSortableFields,
  GlobalRole,
  SortOrder,
  useAdminUpdateUserMutation,
  useGetCustomersQuery,
  useGetUserQuery,
  useGetStripePaymentMethodsByUserQuery,
} from '@graphql/generated';
import { GET_USER_QUERY } from '@graphql/users';

import { formatISOtoMMDDYYYY } from '@utils/date';
import { useUser } from '@hooks/appContext/useUser';
import GenericApolloError from '@components/GenericApolloError';

import { Row, Typography, message, Space } from 'antd';
import UserAccounts from '@components/Users/UserAccounts';
import RecordDetailCard from '@components/Card/RecordDetailCard';
import UserNotifications from '@components/Users/UserNotifications';
import { UserCustomerFacingDetails } from '@components/Users/UserCustomerFacingDetails';

import CoachReviews from './components/CoachReviews';
import { EmployeeInternalNotes } from './components/EmployeeInternalNotes';
import { EmployeeInternalFiles } from './components/EmployeeInternalFiles';

function UserDetail() {
  const { userId } = useParams<{ userId: string }>();
  const i18n = useTranslation();
  const {
    data: userData,
    loading: userLoading,
    error,
  } = useGetUserQuery({
    variables: { getUserId: Number(userId) },
  });
  const { data: customersData, loading: customersLoading } = useGetCustomersQuery({
    variables: {
      filters: {
        userId: Number(userId),
        sortField: CustomerSortableFields.CreatedAt,
        sortValue: SortOrder.Desc,
      },
    },
  });
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { data: paymentMethodsData, loading: paymentMethodsLoading } =
    useGetStripePaymentMethodsByUserQuery({ variables: { userId: Number(userId) } });
  const [adminUpdateUser, { loading: updateUserLoading }] = useAdminUpdateUserMutation({
    refetchQueries: [GET_USER_QUERY],
  });
  const loggedInUser = useUser();
  const user = userData?.getUser;
  const customers = customersData?.getCustomers || [];
  const seniorMemberCustomerData = customers?.find((customer) =>
    customer?.users?.find(
      (customerUser) =>
        customerUser?.role === GlobalRole.SeniorMember && customerUser?.user?.id === Number(userId),
    ),
  );
  const editable = useMemo(
    () => loggedInUser?.id === Number(userId) || loggedInUser?.role === GlobalRole.Admin,
    [loggedInUser, userId],
  );
  const isCoachAndHasAccess = useMemo(
    () => loggedInUser?.role === GlobalRole.Admin && user?.role === GlobalRole.Coach,
    [loggedInUser, user?.role],
  );
  const showAccounts = useMemo(
    () =>
      [
        GlobalRole.SeniorMember,
        GlobalRole.FamilyPartner,
        GlobalRole.CircleMember,
        GlobalRole.Coach,
      ].includes(user?.role as GlobalRole),
    [user?.role],
  );

  const showCustomerFacingDetails = useMemo(
    () =>
      user?.role &&
      ![GlobalRole.Admin, GlobalRole.Support, GlobalRole.SeniorMember].includes(user?.role),
    [user?.role],
  );
  const isEmployeeAndHasAccess = useMemo(
    () =>
      user?.role &&
      loggedInUser?.role === GlobalRole.Admin &&
      [GlobalRole.Coach, GlobalRole.Support].includes(user?.role),
    [loggedInUser, user?.role],
  );

  const handleEditUser = useCallback(
    async (values: AdminUpdateUserInput) => {
      await adminUpdateUser({
        variables: { adminUpdateUserInput: values, adminUpdateUserId: Number(userId) },
      });
      message.success(<Trans i18nKey="forms.editUser.successMessage" />);
    },
    [adminUpdateUser, userId],
  );

  if (error) return <GenericApolloError error={error} />;

  return (
    <>
      <Row justify="space-between" align="middle" style={{ marginBottom: '20px' }}>
        <Typography.Title level={2}>
          <Trans
            i18nKey="manage.users.detail.title"
            values={{ userId: String(user?.id).padStart(5, '0'), fullName: user?.fullName }}
          />
        </Typography.Title>
      </Row>
      <Row>
        <Space size="large" wrap align="start">
          {user && showCustomerFacingDetails ? (
            <UserCustomerFacingDetails
              user={user}
              editable={editable}
              onEdit={handleEditUser}
              loading={
                userLoading || paymentMethodsLoading || customersLoading || updateUserLoading
              }
              cardProps={{
                style: { width: '400px' },
              }}
            />
          ) : null}
          <RecordDetailCard
            title={<Trans i18nKey="manage.users.detail.personalInfo.title" />}
            cardProps={{
              style: { minWidth: '350px' },
              loading:
                userLoading || paymentMethodsLoading || customersLoading || updateUserLoading,
            }}
            editable={editable}
            onEdit={handleEditUser}
            fields={[
              {
                name: 'id',
                label: <Trans i18nKey="manage.users.detail.personalInfo.fields.id" />,
                value: String(user?.id).padStart(5, '0'),
              },
              {
                name: 'firstName',
                label: <Trans i18nKey="manage.users.detail.personalInfo.fields.firstName" />,
                value: user?.firstName,
                editable: true,
                autoFocus: true,
                formItemProps: {
                  validateTrigger: 'onSubmit',
                  required: true,
                  rules: [
                    {
                      required: true,
                      message: i18n.t('forms.input.firstName.errorMessage') as string,
                      whitespace: true,
                    },
                  ],
                },
              },
              {
                name: 'lastName',
                label: <Trans i18nKey="manage.users.detail.personalInfo.fields.lastName" />,
                value: user?.lastName,
                editable: true,
                formItemProps: {
                  validateTrigger: 'onSubmit',
                  required: true,
                  rules: [
                    {
                      required: true,
                      message: i18n.t('forms.input.lastName.errorMessage') as string,
                      whitespace: true,
                    },
                  ],
                },
              },
              {
                name: 'email',
                label: <Trans i18nKey="manage.users.detail.personalInfo.fields.email" />,
                value: user?.email,
              },
              {
                name: 'phoneNumber',
                label: <Trans i18nKey="manage.users.detail.personalInfo.fields.phoneNumber" />,
                value: seniorMemberCustomerData?.phoneNumber || user?.phoneNumber,
                editable: false,
              },
              {
                name: 'emailVerified',
                label: <Trans i18nKey="manage.users.detail.personalInfo.fields.emailVerified" />,
                value: user?.emailVerified,
                isBoolean: true,
              },
              {
                name: 'disabled',
                label: <Trans i18nKey="manage.users.detail.personalInfo.fields.disabled" />,
                value: !user?.disabled,
                isBoolean: true,
              },
              {
                name: 'createdAt',
                label: <Trans i18nKey="manage.users.detail.personalInfo.fields.createdAt" />,
                value: formatISOtoMMDDYYYY(user?.createdAt),
              },
              {
                name: 'lastLogin',
                label: <Trans i18nKey="manage.users.detail.personalInfo.fields.lastLogin" />,
                value: formatISOtoMMDDYYYY(user?.lastLogin),
              },
              ...(user?.role === GlobalRole.SeniorMember
                ? []
                : [
                    {
                      name: 'emergencyContact.name',
                      label: (
                        <Trans i18nKey="manage.users.detail.personalInfo.fields.emergencyContactName" />
                      ),
                      value: user?.emergencyContact?.name || '',
                      editable: true,
                      valueFallback: <Trans i18nKey="fallbacks.notSet" />,
                    },
                    {
                      name: 'emergencyContact.relation',
                      label: (
                        <Trans i18nKey="manage.users.detail.personalInfo.fields.emergencyContactRelationship" />
                      ),
                      value: user?.emergencyContact?.relation || '',
                      editable: true,
                      valueFallback: <Trans i18nKey="fallbacks.notSet" />,
                    },
                    {
                      name: 'emergencyContact.phoneNumber',
                      label: (
                        <Trans i18nKey="manage.users.detail.personalInfo.fields.emergencyContactPhone" />
                      ),
                      value: user?.emergencyContact?.phoneNumber || '',
                      editable: true,
                      valueFallback: <Trans i18nKey="fallbacks.notSet" />,
                    },
                  ]),
            ]}
          />

          {/* This has been commented out as part of #2547 for the Pilot launch
          <RecordDetailCard
            title={<Trans i18nKey="manage.users.detail.payment.title" />}
            cardProps={{
              style: { minWidth: '350px' },
              loading:
                userLoading || paymentMethodsLoading || customersLoading || updateUserLoading,
            }}
            withDividers
            dividerMod={4}
            fields={
              paymentMethodsData?.getStripePaymentMethodsByUser?.flatMap((stripePaymentMethod) => [
                {
                  name: 'nameOnCard',
                  label: <Trans i18nKey="manage.users.detail.payment.nameOnCard" />,
                  value: stripePaymentMethod?.billing_details?.name,
                },
                {
                  name: 'cardType',
                  label: <Trans i18nKey="manage.users.detail.payment.cardType" />,
                  value: <Trans i18nKey={`cardBrands.${stripePaymentMethod?.card?.brand}`} />,
                },
                {
                  name: 'cardNumber',
                  label: <Trans i18nKey="manage.users.detail.payment.cardNumber" />,
                  value: `**** **** **** ${stripePaymentMethod?.card?.last4}`,
                },
                {
                  name: 'expiration',
                  label: <Trans i18nKey="manage.users.detail.payment.expiration" />,
                  value:
                    stripePaymentMethod?.card?.exp_month && stripePaymentMethod?.card?.exp_year
                      ? // eslint-disable-next-line prettier/prettier
                      `${String(stripePaymentMethod?.card?.exp_month).padStart(2, '0')}/${String(stripePaymentMethod?.card?.exp_year).slice(-2)}`
                      : '--/--',
                },
              ]) || []
            }
          />
          */}

          {user ? <UserNotifications user={user} /> : null}
          {showAccounts ? <UserAccounts userId={Number(userId)} /> : null}
          {isCoachAndHasAccess && user ? <CoachReviews coachId={user.id} /> : null}
          {isEmployeeAndHasAccess && user ? <EmployeeInternalNotes employee={user} /> : null}
          {isEmployeeAndHasAccess && user ? (
            <EmployeeInternalFiles
              employee={user}
              uploadRequestBody={{ userId: user.id }}
              filter={{ userId: user.id }}
              sort={{ field: FileSortableFields.CreatedAt, direction: SortOrder.Desc }}
              // NOTE: for now we don't implement pagination for internal files
              page={1}
              pageSize={1000}
            />
          ) : null}
        </Space>
      </Row>
    </>
  );
}

export default UserDetail;
