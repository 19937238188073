import { Trans } from 'react-i18next';
import {
  GraphqlInternalNote,
  CreateInternalNoteInput,
  useCreateInternalNoteMutation,
  useUpdateInternalNoteMutation,
} from '@graphql/generated';
import { GET_SERVICE_REQUEST_QUERY } from '@graphql/serviceRequests';
import { GET_COACH_VISIT_QUERY } from '@graphql/coachVisits';

import { Typography } from 'antd';
import Modal from '@components/Modal';
import { InternalNoteForm } from '@components/InternalNotes/InternalNoteForm';
import { GET_CUSTOMER_INTERNAL_NOTES } from '@graphql/users';

type AddEditNoteModalProps = {
  onCancel: () => void;
  note?: GraphqlInternalNote;
};

function AddEditNoteModal({ onCancel, note }: AddEditNoteModalProps) {
  const [createNoteMutation, { loading: createNoteLoading }] = useCreateInternalNoteMutation({
    refetchQueries: [GET_SERVICE_REQUEST_QUERY, GET_COACH_VISIT_QUERY, GET_CUSTOMER_INTERNAL_NOTES],
  });
  const [updateNoteMutation, { loading: updateNoteLoading }] = useUpdateInternalNoteMutation({
    refetchQueries: [GET_SERVICE_REQUEST_QUERY, GET_COACH_VISIT_QUERY, GET_CUSTOMER_INTERNAL_NOTES],
  });

  const submit = async (input: CreateInternalNoteInput) => {
    if (note?.id) {
      await updateNoteMutation({
        variables: {
          input,
          updateInternalNoteId: note?.id,
        },
      });
    } else {
      await createNoteMutation({
        variables: {
          input: {
            ...note,
            ...input,
          },
        },
      });
    }
    onCancel();
  };

  return (
    <Modal
      width={750}
      closable
      onCancel={onCancel}
      open
      title={
        <Typography.Title level={2}>
          <Trans i18nKey={`forms.internalNote.title${note?.id ? 'Edit' : 'Add'}`} />
        </Typography.Title>
      }
    >
      <InternalNoteForm
        loading={createNoteLoading || updateNoteLoading}
        initialValues={note}
        onSubmit={submit}
        submitLabel={<Trans i18nKey="forms.internalNote.submitLabel" />}
      />
    </Modal>
  );
}

AddEditNoteModal.defaultProps = {
  note: {},
};

export default AddEditNoteModal;
