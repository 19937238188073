import { ServiceRequestStatus } from '@graphql/generated';

export const DEFAULT_SEARCH_DEBOUNCE_TIME = 500;

export const DATE_FORMAT = 'YYYY-MM-DD';

export const TIME_FORMAT = 'HH:mm[Z]Z';

export const DATE_TIME_FORMAT = 'YYYY-MM-DDTHH:mm:ssZ';

export const ServiceRequestStatusOptions = [
  ServiceRequestStatus.Pending,
  ServiceRequestStatus.ConfirmationNeeded,
  ServiceRequestStatus.Confirmed,
  ServiceRequestStatus.Cancelled,
  ServiceRequestStatus.Completed,
  ServiceRequestStatus.PaymentFailed,
  ServiceRequestStatus.PaymentAuthorizationFailed,
  ServiceRequestStatus.PaymentWaitingForSupport,
];

export const CircleRequestStatusOptions = [
  ServiceRequestStatus.Pending,
  ServiceRequestStatus.Confirmed,
  ServiceRequestStatus.Cancelled,
  ServiceRequestStatus.Completed,
];
