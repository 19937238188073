import { useEffect, useMemo, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import {
  CircleRequestCategoriesEnum,
  CircleRequestFilter,
  CircleRequestSortInput,
  CircleRequestsSortableFields,
  GraphqlCircleRequest,
  InputMaybe,
  PaginationMeta,
  ServiceRequestStatus,
  SortOrder,
  useGetCircleRequestsQuery,
} from '@graphql/generated';
import { FilterValue, SorterResult } from 'antd/es/table/interface';
import { ColumnsType, TablePaginationConfig } from 'antd/es/table';
import GenericApolloError from '@components/GenericApolloError';
import { ApolloError } from '@apollo/client/errors';

import ROUTES from '@constants/routes';
import { ID_MIN_LENGTH } from '@constants/output';
import { getActivityActionNeeded } from '@utils/activities';
import { formatISOtoMMDDYYYY } from '@utils/date';

import { Space, Typography } from 'antd';
import Input from '@components/Input';
import Button from '@components/Button';
import Table from '@components/Table/Table';
import { DEFAULT_SEARCH_DEBOUNCE_TIME } from '@constants/input';
import { TableDateFilter } from '@components/Table/TableDateFilter';
import { ServiceRequestStatusTag } from '@components/Activities/ServiceRequestStatusTag';
import { useDateWithTimezone } from '@hooks/useDateWithTimezone';

function CircleRequests() {
  const i18n = useTranslation();
  const [searchCircleRequestsText, setSearchCircleRequestsText] = useState('');
  const [filters, setFilters] = useState<CircleRequestFilter>({
    text: searchCircleRequestsText,
  });
  const [sortOptions, setSortOptions] = useState<InputMaybe<CircleRequestSortInput>>({
    field: CircleRequestsSortableFields.Id,
    direction: SortOrder.Desc,
  });
  const [pageSize, setPageSize] = useState(100);
  const [page, setPage] = useState(1);
  const { formatDateAndTimeInTimezone } = useDateWithTimezone();
  const {
    data: circleRequestsData,
    error: circleRequestsError,
    loading: circleRequestsLoading,
    previousData: circleRequestsPreviousData,
  } = useGetCircleRequestsQuery({
    variables: {
      filter: filters,
      sort: sortOptions,
      page,
      pageSize,
    },
  });

  useEffect(() => {
    const timer = setTimeout(() => {
      setFilters((previousFilters) => ({ ...previousFilters, text: searchCircleRequestsText }));
    }, DEFAULT_SEARCH_DEBOUNCE_TIME);
    return () => clearTimeout(timer);
  }, [searchCircleRequestsText]);

  const activities = useMemo(
    () =>
      ((circleRequestsData || circleRequestsPreviousData)?.getCircleRequests
        ?.circleRequests as GraphqlCircleRequest[]) || [],
    [circleRequestsData, circleRequestsPreviousData],
  );

  const pagination = (circleRequestsData || circleRequestsPreviousData)?.getCircleRequests
    .meta as PaginationMeta;

  const columns: ColumnsType<GraphqlCircleRequest> = useMemo(
    () => [
      {
        title: <Trans i18nKey="manage.serviceRequests.columns.id" />,
        dataIndex: 'id',
        key: 'id',
        render: (_: any, record: GraphqlCircleRequest) => (
          <Link to={ROUTES.MANAGE_CIRCLE_REQUEST.replace(':activityId', String(record.id))}>
            {String(record.id).padStart(ID_MIN_LENGTH, '0')}
          </Link>
        ),
        sorter: true,
        filteredValue: null,
      },
      {
        title: <Trans i18nKey="manage.serviceRequests.columns.requestedBy" />,
        key: 'requestedBy',
        render: (_: any, record: GraphqlCircleRequest) => (
          <Link to={ROUTES.MANAGE_USER.replace(':userId', String(record.RequestedBy.id))}>
            {record.RequestedBy.fullName}
          </Link>
        ),
        filteredValue: null,
      },
      {
        title: <Trans i18nKey="manage.serviceRequests.columns.account" />,
        key: 'account',
        render: (_: any, record: GraphqlCircleRequest) => (
          <Link to={ROUTES.MANAGE_CUSTOMER.replace(':customerId', String(record?.Customer?.id))}>
            {record?.Customer?.fullName}
          </Link>
        ),
        filteredValue: null,
      },
      {
        title: <Trans i18nKey="manage.circleRequests.columns.numberOfMembers" />,
        key: 'numberOfMembers',
        render: (_: any, record: GraphqlCircleRequest) =>
          (record as GraphqlCircleRequest)?.askTo?.length,
        filteredValue: null,
      },
      {
        title: <Trans i18nKey="manage.serviceRequests.columns.type" />,
        key: 'category',
        dataIndex: 'category',
        render: (_: any, record: GraphqlCircleRequest) =>
          i18n.t(`serviceTypes.${record.category}`) as string,
        sorter: true,
        filters: Object.values(CircleRequestCategoriesEnum).map((serviceType) => {
          return {
            text: <Trans i18nKey={`serviceTypes.${serviceType}`} />,
            value: serviceType,
          };
        }),
        filteredValue: (filters as CircleRequestFilter)?.category,
      },
      {
        title: <Trans i18nKey="manage.serviceRequests.columns.createdAt" />,
        key: 'createdAt',
        dataIndex: 'createdAt',
        render: (_: any, record: GraphqlCircleRequest) => formatISOtoMMDDYYYY(record.createdAt),
        sorter: true,
        // eslint-disable-next-line react/no-unstable-nested-components
        filterDropdown: (props: any) => (
          <TableDateFilter
            {...props}
            onRangeSelected={(from, until) =>
              setFilters((prevFilters) => ({
                ...prevFilters,
                requestedAtFrom: from,
                requestedAtUntil: until,
              }))
            }
          />
        ),
        filtered: Boolean(
          (filters as CircleRequestFilter).requestedAtFrom ||
            (filters as CircleRequestFilter).requestedAtUntil,
        ),
        filteredValue: null,
      },
      {
        title: <Trans i18nKey="manage.serviceRequests.columns.status" />,
        key: 'status',
        dataIndex: 'status',
        render: (_: any, record: GraphqlCircleRequest) => (
          <ServiceRequestStatusTag status={record.status as ServiceRequestStatus} />
        ),
        sorter: true,
        filters: Object.values(ServiceRequestStatus).map((serviceRequestStatus) => {
          return {
            text: <Trans i18nKey={`serviceRequestStatus.${serviceRequestStatus}`} />,
            value: serviceRequestStatus,
          };
        }),
        filtered: Boolean(Number(filters.status?.length) > 0),
        filteredValue: filters.status,
      },
      {
        title: <Trans i18nKey="manage.serviceRequests.columns.actionNeeded" />,
        key: 'actionNeeded',
        render: (_: any, record: GraphqlCircleRequest) =>
          getActivityActionNeeded(record as GraphqlCircleRequest),
        filteredValue: null,
      },
      {
        title: <Trans i18nKey="manage.circleRequests.columns.scheduledAt" />,
        key: 'scheduledAt',
        dataIndex: 'scheduledAt',
        render: (_: any, record: GraphqlCircleRequest) =>
          record.scheduledAt ? formatDateAndTimeInTimezone(record.scheduledAt) : '',
        sorter: true,
        // eslint-disable-next-line react/no-unstable-nested-components
        filterDropdown: (props: any) => (
          <TableDateFilter
            {...props}
            onRangeSelected={(from, until) =>
              setFilters((prevFilters) => ({
                ...prevFilters,
                scheduledAtFrom: from,
                scheduledAtUntil: until,
              }))
            }
          />
        ),
        filtered: Boolean(filters.scheduledAtFrom || filters.scheduledAtUntil),
        filteredValue: null,
      },
    ],
    [i18n, filters, formatDateAndTimeInTimezone],
  );

  const onTableChange = (
    tablePagination: TablePaginationConfig,
    tableFilters: Record<string, FilterValue | null>,
    tableSorter: SorterResult<GraphqlCircleRequest> | SorterResult<GraphqlCircleRequest>[],
  ) => {
    const uniqueSorter = tableSorter as SorterResult<GraphqlCircleRequest>;

    // Pagination
    setPage(tablePagination.current as number);
    setPageSize(tablePagination.pageSize as number);

    // Order
    if (uniqueSorter.order) {
      const direction = uniqueSorter.order === 'ascend' ? SortOrder.Asc : SortOrder.Desc;
      setSortOptions({
        field: uniqueSorter.field as CircleRequestsSortableFields,
        direction,
      });
    } else {
      setSortOptions({
        field: CircleRequestsSortableFields.Id,
        direction: SortOrder.Desc,
      });
    }

    // Filters
    const { status, category } = tableFilters;
    setFilters((prevFilters) => ({
      ...prevFilters,
      category: category as CircleRequestCategoriesEnum[],
      status: status as ServiceRequestStatus[],
    }));
  };

  if (circleRequestsError) return <GenericApolloError error={circleRequestsError as ApolloError} />;

  return (
    <Space direction="vertical" size="large" style={{ width: '100%' }}>
      <Typography.Title
        level={2}
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
        }}
      >
        <Trans i18nKey="manage.circleRequests.title" />
        <Link to={ROUTES.MANAGE_CIRCLE_REQUEST_CREATE}>
          <Button>
            <Trans i18nKey="manage.circleRequests.create.cta" />
          </Button>
        </Link>
      </Typography.Title>
      <Input
        placeholder={i18n.t('manage.circleRequests.search') as string}
        value={searchCircleRequestsText}
        onChange={(e) => setSearchCircleRequestsText(e.target.value)}
      />
      <Table
        loading={circleRequestsLoading}
        columns={columns}
        dataSource={(activities || []).map((activity) => ({
          ...activity,
          key: activity.id,
        }))}
        onChange={onTableChange}
        rowClassName={(record) =>
          record.status === ServiceRequestStatus.Inactive ? 'disabled-row' : ''
        }
        pagination={{
          pageSize,
          showSizeChanger: true,
          defaultCurrent: 1,
          current: pagination?.currentPage,
          total: pagination?.totalCount,
        }}
      />
    </Space>
  );
}

export default CircleRequests;
