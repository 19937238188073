import { useMemo, useState } from 'react';
import {
  PoweroffOutlined,
  CommentOutlined,
  DashboardOutlined,
  UserOutlined,
  RiseOutlined,
  BulbOutlined,
  ToolOutlined,
  LockOutlined,
  BankOutlined,
  InboxOutlined,
  TeamOutlined,
  CiCircleOutlined,
  CalendarOutlined,
  IssuesCloseOutlined,
} from '@ant-design/icons';
import { MenuProps, Layout, Menu, Grid, Switch } from 'antd';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { ReactComponent as BryaLogo } from '@assets/logo.svg';
import { useLogout, useRefreshAuth } from '@hooks/useAuth';
import ROUTES from '@constants/routes';
import { useUser } from '@hooks/appContext/useUser';
import { useSetUser } from '@hooks/appContext/useSetUser';
import {
  GlobalRole,
  ManagementRole,
  useCreateWebPushSubscriptionMutation,
  useDestroyWebPushSubscriptionMutation,
  GraphqlUser,
} from '@graphql/generated';
import { FEATURE_FLAGS } from '@constants/featureFlags';
import { useIsFeatureEnabled } from '@hooks/useIsFeatureEnabled';

const { Content, Sider } = Layout;

const StyledLayout = styled(Layout)`
  background: ${({ theme }) => theme.layout.backgroundColor};
`;

const StyledSider = styled(Sider)`
  @media (min-width: ${({ theme }) => theme.breakpoints.xxl}) {
    * {
      font-size: 1.05em;
    }
  }

  &[style] {
    background: ${({ theme }) => theme.layout.sidebar.backgroundColor};
  }

  .ant-menu-root {
    background: ${({ theme }) => theme.layout.sidebar.backgroundColor};
    color: ${({ theme }) => theme.layout.sidebar.color};
  }

  .ant-layout-sider-trigger {
    background: ${({ theme }) => theme.layout.sidebar.backgroundColor};
    color: ${({ theme }) => theme.layout.sidebar.color};
  }

  .ant-layout-sider-zero-width-trigger {
    background: ${({ theme }) => theme.layout.sidebar.backgroundColor};
    color: ${({ theme }) => theme.layout.sidebar.color};
  }

  &.ant-layout-sider-collapsed .ant-switch {
    display: none;
  }
`;

const StyledContent = styled(Content)`
  margin: 0 16px 32px;
`;

const LogoContainer = styled.div<{ hide: boolean }>`
  margin: 16px 4px 4px;
  padding: 0 16px 0 24px;
  width: ${({ hide }) => (hide ? '100px' : 'auto')};
  height: ${({ hide }) => (hide ? '61px' : 'auto')};

  svg {
    display: ${({ hide }) => (hide ? 'none' : 'inherit')};
    width: 75px;
    aspect-ratio: 1;
  }
  @media (min-width: ${({ theme }) => theme.breakpoints.xxl}) {
    svg {
      width: 100px;
    }
  }
`;

type MenuItem = Required<MenuProps>['items'][number] & { isSelected: () => boolean };
type AllowedRoles = (ManagementRole | GlobalRole | undefined | null)[];

function Manage() {
  const [isLogoHide, setIsLogoHide] = useState(false);
  const { pathname } = useLocation();
  const [createWebPushSubscriptionMutation] = useCreateWebPushSubscriptionMutation();
  const [destroyWebPushSubscriptionMutation] = useDestroyWebPushSubscriptionMutation();
  const screens = Grid.useBreakpoint();
  const user = useUser();
  const setUser = useSetUser();
  const logout = useLogout();
  const navigate = useNavigate();
  const i18n = useTranslation();
  const refreshAuth = useRefreshAuth();

  const serviceRequestEnabled = useIsFeatureEnabled(FEATURE_FLAGS.SERVICE_REQUEST);
  const circleRequestEnabled = useIsFeatureEnabled(FEATURE_FLAGS.CIRCLE_REQUEST);

  const items = useMemo(
    (): MenuItem[] =>
      [
        {
          label: i18n.t('manage.sidebar.dashboard') as string,
          key: 'dashboard',
          icon: <DashboardOutlined />,
          onClick: () => navigate(ROUTES.MANAGE),
          allowedRoles: Object.values(ManagementRole) as AllowedRoles,
          isSelected: () => pathname === ROUTES.MANAGE,
        },
        {
          label: i18n.t('manage.sidebar.profile') as string,
          key: 'profile',
          icon: <UserOutlined />,
          onClick: () => navigate(ROUTES.MANAGE_USER.replace(':userId', String(user?.id))),
          allowedRoles: Object.values(ManagementRole) as AllowedRoles,
          isSelected: () => pathname === ROUTES.MANAGE_USER.replace(':userId', String(user?.id)),
        },
        {
          label: i18n.t('manage.sidebar.users') as string,
          key: 'users',
          icon: <LockOutlined />,
          onClick: () => navigate(ROUTES.MANAGE_MANAGEMENT_USERS),
          allowedRoles: [GlobalRole.Admin] as AllowedRoles,
          isSelected: () => pathname === ROUTES.MANAGE_MANAGEMENT_USERS,
        },
        {
          label: i18n.t('manage.sidebar.customers') as string,
          key: 'customers',
          icon: <TeamOutlined />,
          onClick: () => navigate(ROUTES.MANAGE_CUSTOMERS),
          allowedRoles: Object.values(ManagementRole) as AllowedRoles,
          isSelected: () => pathname === ROUTES.MANAGE_CUSTOMERS,
        },
        {
          label: i18n.t('manage.sidebar.pros') as string,
          key: 'pros',
          icon: <BankOutlined />,
          onClick: () => navigate(ROUTES.MANAGE_PROS),
          allowedRoles: Object.values(ManagementRole) as AllowedRoles,
          isSelected: () => pathname === ROUTES.MANAGE_PROS,
          hide: !serviceRequestEnabled,
        },
        {
          label: i18n.t('manage.sidebar.serviceRequests') as string,
          key: 'serviceRequests',
          icon: <ToolOutlined />,
          onClick: () => navigate(ROUTES.MANAGE_SERVICE_REQUESTS),
          allowedRoles: Object.values(ManagementRole) as AllowedRoles,
          isSelected: () => pathname === ROUTES.MANAGE_SERVICE_REQUESTS,
          hide: !serviceRequestEnabled,
        },
        {
          label: i18n.t('manage.sidebar.circleRequests') as string,
          key: 'circleRequests',
          icon: <CiCircleOutlined />,
          onClick: () => navigate(ROUTES.MANAGE_CIRCLE_REQUESTS),
          allowedRoles: Object.values(ManagementRole) as AllowedRoles,
          isSelected: () => pathname === ROUTES.MANAGE_CIRCLE_REQUESTS,
          hide: !circleRequestEnabled,
        },
        {
          label: i18n.t('manage.sidebar.socialEvents') as string,
          key: 'socialEvents',
          icon: <CalendarOutlined />,
          onClick: () => navigate(ROUTES.MANAGE_SOCIAL_EVENTS),
          allowedRoles: Object.values(ManagementRole) as AllowedRoles,
          isSelected: () => pathname === ROUTES.MANAGE_SOCIAL_EVENTS,
        },
        {
          label: i18n.t('manage.sidebar.coachVisits') as string,
          key: 'coachVisits',
          icon: <CalendarOutlined />,
          onClick: () => navigate(ROUTES.MANAGE_COACH_VISITS),
          allowedRoles: Object.values(ManagementRole) as AllowedRoles,
          isSelected: () => pathname === ROUTES.MANAGE_COACH_VISITS,
        },
        {
          label: i18n.t('manage.sidebar.chat') as string,
          key: 'chat',
          icon: <InboxOutlined />,
          onClick: () => navigate(ROUTES.MANAGE_CHAT),
          allowedRoles: Object.values(ManagementRole) as AllowedRoles,
          isSelected: () => pathname === ROUTES.MANAGE_CHAT,
        },
        {
          label: i18n.t('manage.sidebar.community') as string,
          key: 'community',
          icon: <CommentOutlined />,
          onClick: () => navigate(ROUTES.MANAGE_COMMUNITY),
          allowedRoles: Object.values(ManagementRole) as AllowedRoles,
          isSelected: () => pathname === ROUTES.MANAGE_COMMUNITY,
        },
        {
          label: i18n.t('manage.sidebar.flaggedMessages') as string,
          key: 'flaggedMessages',
          icon: <IssuesCloseOutlined />,
          onClick: () => navigate(ROUTES.MANAGE_FLAGGED_MESSAGES),
          allowedRoles: Object.values(ManagementRole) as AllowedRoles,
          isSelected: () => pathname === ROUTES.MANAGE_FLAGGED_MESSAGES,
        },
        {
          label: i18n.t('manage.sidebar.leads') as string,
          key: 'leads',
          icon: <RiseOutlined />,
          onClick: () => navigate(ROUTES.MANAGE_LEADS),
          allowedRoles: Object.values(ManagementRole) as AllowedRoles,
          isSelected: () => pathname === ROUTES.MANAGE_LEADS,
        },
        {
          label: i18n.t('manage.sidebar.suggestedActivities') as string,
          key: 'suggestedActivities',
          icon: <BulbOutlined />,
          onClick: () => navigate(ROUTES.MANAGE_SUGGESTED_ACTIVITIES),
          allowedRoles: Object.values(ManagementRole) as AllowedRoles,
          isSelected: () => pathname === ROUTES.MANAGE_SUGGESTED_ACTIVITIES,
        },
        {
          label: i18n.t('manage.sidebar.logOut') as string,
          key: 'logout',
          icon: <PoweroffOutlined />,
          onClick: logout,
          isSelected: () => false,
        },
      ]
        // Show only items that are allowed for the current user role (or for all roles if allowedRoles is not defined)
        .filter(
          (item) => !item.hide && (!item.allowedRoles || item.allowedRoles.includes(user?.role)),
        )
        // Remove the allowedRoles property from the MenuItem
        .map(({ allowedRoles, hide, ...keep }) => ({ ...keep })),
    [user, navigate, i18n, logout, pathname, serviceRequestEnabled, circleRequestEnabled],
  );

  function urlBase64ToUint8Array(base64String: string) {
    const padding = '='.repeat((4 - (base64String.length % 4)) % 4);
    const base64 = (base64String + padding).replace(/-/g, '+').replace(/_/g, '/');

    const rawData = window.atob(base64);
    const outputArray = new Uint8Array(rawData.length);

    rawData.split('').forEach((a, i) => {
      outputArray[i] = a.charCodeAt(0);
    });

    return outputArray;
  }

  async function createOrDestroyWebPushSubscription() {
    if (user?.webPushSubscription && Notification.permission === 'granted') {
      await destroyWebPushSubscriptionMutation({ variables: { data: { destroy: true } } });
      await refreshAuth();
      return setUser({ ...user, webPushSubscription: null } as GraphqlUser);
    }

    // register service worker
    const register = await navigator.serviceWorker.register('/pushNotification.js', {
      scope: '/',
    });

    // Create push notification subscription
    const webPushSubscription = await register.pushManager.subscribe({
      userVisibleOnly: true,
      applicationServerKey: urlBase64ToUint8Array(process.env.REACT_APP_VAPID_PUBLIC_KEY as string),
    });

    // Send the subscription to the application server (graphql)
    await createWebPushSubscriptionMutation({
      variables: { webPushSubscription: JSON.parse(JSON.stringify(webPushSubscription)) },
    });

    await refreshAuth();

    return setUser({
      ...user,
      webPushSubscription: JSON.stringify(webPushSubscription),
    } as GraphqlUser);
  }

  return (
    <StyledLayout style={{ minHeight: '100vh', maxWidth: '100vw' }}>
      <StyledSider
        collapsible
        breakpoint="lg"
        width={screens.xxl ? 300 : undefined}
        collapsedWidth="80"
        onCollapse={(collapsed) => setIsLogoHide(collapsed)}
      >
        <LogoContainer hide={isLogoHide}>
          <BryaLogo />
        </LogoContainer>
        <Switch
          checkedChildren={i18n.t('manage.sidebar.disable-push-notifications')}
          unCheckedChildren={i18n.t('manage.sidebar.enable-push-notifications')}
          checked={!!user?.webPushSubscription && Notification.permission === 'granted'}
          onChange={() => createOrDestroyWebPushSubscription()}
          style={{ margin: '20px 24px 10px' }}
        />
        <Menu
          mode="inline"
          items={items}
          selectedKeys={items
            .map((item) => (item.isSelected() ? (item.key as string) : ''))
            .filter(Boolean)}
        />
      </StyledSider>
      <StyledLayout>
        <StyledContent>
          <Outlet />
        </StyledContent>
      </StyledLayout>
    </StyledLayout>
  );
}

export default Manage;
