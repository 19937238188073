import Modal from '@components/Modal';
import { ManagementUserForm, ManagementUserFormValues } from '@components/Users/ManagementUserForm';
import { AdminUpdateUserInput, useAdminUpdateUserMutation } from '@graphql/generated';
import { Typography, message } from 'antd';
import { Trans, useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

function EditManagementUser() {
  const { userId } = useParams<{ userId: string }>();
  const [adminUpdateUser, { loading }] = useAdminUpdateUserMutation();
  const { state } = useLocation();
  const navigate = useNavigate();
  const initialFormValues = state as ManagementUserFormValues;
  const i18n = useTranslation();

  const onUpdateUser = async ({
    firstName,
    lastName,
    managementRole,
  }: ManagementUserFormValues) => {
    // NOTE: email field is not editable
    const adminUpdateUserInput: AdminUpdateUserInput = { firstName, lastName, managementRole };
    await adminUpdateUser({
      variables: { adminUpdateUserInput, adminUpdateUserId: Number(userId) },
    });

    message.success(i18n.t('forms.editUser.successMessage' as string));
    navigate(-1);
  };

  return (
    <Modal
      width={400}
      closable
      onCancel={() => (loading ? null : navigate(-1))}
      open
      title={
        <Typography.Title level={2}>
          <Trans i18nKey="forms.editUser.title" />
        </Typography.Title>
      }
    >
      <ManagementUserForm
        loading={loading}
        onSubmit={onUpdateUser}
        submitLabel={<Trans i18nKey="forms.editUser.submitLabel" />}
        initialFormValues={{ ...initialFormValues, managementRole: state?.role }}
        editMode
      />
    </Modal>
  );
}

export default EditManagementUser;
