import { useCallback, useMemo } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Link, useParams } from 'react-router-dom';
import styled from 'styled-components';

import {
  AdminUpdateUserInput,
  AgentPreferedContactMethodEnum,
  AgentRole,
  FileSortableFields,
  GlobalRole,
  GraphqlUser,
  SortOrder,
  UpdateAgentInput,
  useAdminUpdateUserMutation,
  useGetAgentQuery,
  useListRequestCategoriesQuery,
  useUpdateAgentMutation,
} from '@graphql/generated';
import { GET_AGENT_QUERY } from '@graphql/pros';

import ROUTES from '@constants/routes';
import { formatISOtoMMDDYYYY } from '@utils/date';
import { useUser } from '@hooks/appContext/useUser';

import { InputNumber, Space, Tabs, Typography, message } from 'antd';
import Masonry, { ResponsiveMasonry } from 'react-responsive-masonry';
import { Select } from '@components/Input/Select';
import { InputNewPassword } from '@components/Input';
import EntityRating from '@components/Rate/EntityRating';
import AgentReviews from '@components/Pros/AgentReviews';
import GenericApolloError from '@components/GenericApolloError';
import RecordDetailCard from '@components/Card/RecordDetailCard';
import AgentsServiceRequests from '@components/Pros/AgentsServiceRequests';
import AgentsAppointmentRequests from '@components/Pros/AgentsAppointmentRequests';
import { EmployeeInternalFiles } from '@routes/users/components/EmployeeInternalFiles';
import { UserCustomerFacingDetails } from '@components/Users/UserCustomerFacingDetails';

const Ellipsis = styled.div`
  max-width: 300px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  text-align: right;
`;

function AgentDetailsPage() {
  const { agentId } = useParams<{ agentId: string }>();
  const i18n = useTranslation();
  const user = useUser();
  const isAdminOrSupport = user?.role === GlobalRole.Admin || user?.role === GlobalRole.Support;
  const {
    data: getAgentQuery,
    loading,
    error,
  } = useGetAgentQuery({
    variables: { agentId: Number(agentId) },
  });
  const [updateAgent, { loading: updateAgentLoading }] = useUpdateAgentMutation({
    refetchQueries: [GET_AGENT_QUERY],
  });
  const [adminUpdateUser, { loading: updateUserLoading }] = useAdminUpdateUserMutation({
    refetchQueries: [GET_AGENT_QUERY],
  });
  const { data: serviceRequestCategories } = useListRequestCategoriesQuery({
    variables: { serviceRequest: true },
  });

  const handleEditUser = useCallback(
    async (values: AdminUpdateUserInput) => {
      await adminUpdateUser({
        variables: { adminUpdateUserInput: values, adminUpdateUserId: Number(agentId) },
      });
      message.success(<Trans i18nKey="forms.editUser.successMessage" />);
    },
    [adminUpdateUser, agentId],
  );

  const handleEditAgent = useCallback(
    async (values: UpdateAgentInput) => {
      await updateAgent({
        variables: {
          input: {
            ...values,
            // If we have a falsy (empty) password, don't send anything
            password: values.password || undefined,
            confirmPassword: undefined,
          } as UpdateAgentInput,
          agentId: Number(agentId),
        },
      });
      message.success(i18n.t('forms.editAgent.successMessage' as string));
    },
    [i18n, updateAgent, agentId],
  );

  const agent = getAgentQuery?.getAgent;

  const preferedContactMethodsText = useMemo(() => {
    if (agent?.preferedContactMethods && agent.preferedContactMethods.length > 0) {
      return agent.preferedContactMethods
        .map((contactMethod) => i18n.t(`agentPreferedContactMethods.${contactMethod}`) as string)
        .join(', ');
    }

    return '';
  }, [agent?.preferedContactMethods, i18n]);

  const invalidServiceTypes = useMemo(
    () => new Error(i18n.t('forms.input.serviceTypes.errorMessage') as string),
    [i18n],
  );

  if (error) {
    return <GenericApolloError error={error} />;
  }

  return (
    <Space direction="vertical" style={{ width: '100%' }} size="large">
      <Typography.Title level={2}>
        <Trans i18nKey="manage.agentDetails.title" values={{ fullName: agent?.User.fullName }} />
      </Typography.Title>
      <Tabs
        defaultActiveKey="1"
        type="card"
        size="large"
        items={[
          {
            label: <Trans i18nKey="manage.agentDetails.tabs.details" />,
            key: 'details',
            children: (
              <ResponsiveMasonry columnsCountBreakPoints={{ 350: 1, 1000: 2, 1750: 3 }}>
                <Masonry gutter="20px">
                  <UserCustomerFacingDetails
                    user={agent?.User as Partial<GraphqlUser>}
                    editable
                    onEdit={handleEditUser}
                    loading={loading || updateAgentLoading || updateUserLoading}
                    cardProps={{
                      style: { width: '400px' },
                    }}
                  />
                  <RecordDetailCard
                    title={<Trans i18nKey="manage.agentDetails.details.title" />}
                    cardProps={{
                      style: { minWidth: '400px', maxWidth: 'fit-content' },
                      loading: loading || updateAgentLoading,
                    }}
                    editable={isAdminOrSupport}
                    withDividers
                    onEdit={handleEditAgent}
                    fields={[
                      {
                        name: 'id',
                        label: <Trans i18nKey="manage.users.detail.personalInfo.fields.id" />,
                        value: agent?.userId,
                      },
                      {
                        name: 'firstName',
                        label: (
                          <Trans i18nKey="manage.users.detail.personalInfo.fields.firstName" />
                        ),
                        value: agent?.User.firstName,
                        editable: true,
                        autoFocus: true,
                        formItemProps: {
                          validateTrigger: 'onSubmit',
                          required: true,
                          rules: [
                            {
                              required: true,
                              message: i18n.t('forms.input.firstName.errorMessage') as string,
                              whitespace: true,
                            },
                          ],
                        },
                      },
                      {
                        name: 'lastName',
                        label: <Trans i18nKey="manage.users.detail.personalInfo.fields.lastName" />,
                        value: agent?.User?.lastName,
                        editable: true,
                        formItemProps: {
                          validateTrigger: 'onSubmit',
                          required: true,
                          rules: [
                            {
                              required: true,
                              message: i18n.t('forms.input.lastName.errorMessage') as string,
                              whitespace: true,
                            },
                          ],
                        },
                      },
                      {
                        name: 'email',
                        label: <Trans i18nKey="manage.users.detail.personalInfo.fields.email" />,
                        value: agent?.User?.email,
                      },
                      {
                        name: 'phoneNumber',
                        label: (
                          <Trans i18nKey="manage.users.detail.personalInfo.fields.phoneNumber" />
                        ),
                        value: agent?.User?.phoneNumber,
                        editable: true,
                      },
                      {
                        name: 'password',
                        label: <Trans i18nKey="forms.input.password.label" />,
                        value: undefined,
                        editable: true,
                        onlyRenderEdit: true,
                        formElement: (
                          <InputNewPassword
                            withConfirmPassword
                            required={false}
                            placeholder={i18n.t('forms.input.password.placeholder') as string}
                            showLabels={false}
                          />
                        ),
                      },
                      {
                        name: 'preferedContactMethods',
                        label: (
                          <Trans i18nKey="manage.agentDetails.details.fields.preferedContactMethods" />
                        ),
                        valueFallback: (
                          <Trans i18nKey="manage.agentDetails.details.fields.preferedContactMethodsFallback" />
                        ),
                        value: agent?.preferedContactMethods,
                        render: () =>
                          preferedContactMethodsText ? (
                            <Ellipsis title={preferedContactMethodsText}>
                              {preferedContactMethodsText}
                            </Ellipsis>
                          ) : null,
                        editable: true,
                        formElement: (
                          <Select
                            mode="multiple"
                            placeholder={
                              i18n.t('forms.input.preferedContactMethods.placeholder') as string
                            }
                            style={{ width: '300px' }}
                            maxTagCount="responsive"
                            optionFilterProp="label"
                            options={Object.values(AgentPreferedContactMethodEnum).map(
                              (contactMethod) => ({
                                value: contactMethod,
                                label: i18n.t(`agentPreferedContactMethods.${contactMethod}`),
                              }),
                            )}
                          />
                        ),
                      },
                      {
                        name: 'role',
                        label: <Trans i18nKey="manage.users.detail.personalInfo.fields.role" />,
                        value: agent?.role === AgentRole.AdminAgent ? 'Admin' : 'Agent',
                      },
                      {
                        name: 'emailVerified',
                        label: (
                          <Trans i18nKey="manage.users.detail.personalInfo.fields.emailVerified" />
                        ),
                        value: agent?.User?.emailVerified,
                        isBoolean: true,
                      },
                      {
                        name: 'isBryaUser',
                        label: <Trans i18nKey="manage.agentDetails.details.fields.isBryaUser" />,
                        value: Boolean(agent?.User?.lastLogin),
                        isBoolean: true,
                      },
                      {
                        name: 'disabled',
                        label: <Trans i18nKey="manage.users.detail.personalInfo.fields.disabled" />,
                        value: !agent?.User?.disabled,
                        isBoolean: true,
                      },
                      {
                        name: 'createdAt',
                        label: (
                          <Trans i18nKey="manage.users.detail.personalInfo.fields.createdAt" />
                        ),
                        value: formatISOtoMMDDYYYY(agent?.User?.createdAt),
                      },
                      {
                        name: 'lastLogin',
                        label: (
                          <Trans i18nKey="manage.users.detail.personalInfo.fields.lastLogin" />
                        ),
                        value: formatISOtoMMDDYYYY(agent?.User?.lastLogin),
                      },
                      {
                        label: <Trans i18nKey="manage.agentDetails.details.fields.company" />,
                        name: 'company',
                        value: (
                          <Link
                            to={ROUTES.MANAGE_COMPANY.replace(
                              ':companyId',
                              String(agent?.Company?.id),
                            )}
                          >
                            {agent?.Company?.name}
                          </Link>
                        ),
                      },
                      {
                        label: <Trans i18nKey="manage.agentDetails.details.fields.rating" />,
                        name: 'rating',
                        value: <EntityRating value={agent?.rating} />,
                      },
                      {
                        label: (
                          <Trans i18nKey="manage.agentDetails.details.fields.internalRating" />
                        ),
                        name: 'internalRating',
                        value: agent?.internalRating,
                        render: () =>
                          agent?.internalRating
                            ? `${agent?.internalRating}/100`
                            : i18n.t('fallbacks.N/A'),
                        editable: true,
                        formElement: <InputNumber min={1} max={100} />,
                      },
                      {
                        label: <Trans i18nKey="manage.agentDetails.details.fields.serviceTypes" />,
                        name: 'serviceTypes',
                        value: agent?.serviceTypes,
                        render: () => (
                          <Ellipsis
                            title={agent?.serviceTypes
                              .map(
                                (serviceTypeEnum) =>
                                  i18n.t(`serviceTypes.${serviceTypeEnum}`) as string,
                              )
                              .join(', ')}
                          >
                            {agent?.serviceTypes
                              .map(
                                (serviceTypeEnum) =>
                                  i18n.t(`serviceTypes.${serviceTypeEnum}`) as string,
                              )
                              .join(', ')}
                          </Ellipsis>
                        ),
                        editable: true,
                        formItemProps: {
                          validateTrigger: 'onSubmit',
                          rules: [
                            {
                              validator: (_rule, serviceTypes) => {
                                if (serviceTypes?.length > 0) {
                                  return Promise.resolve();
                                }

                                return Promise.reject(invalidServiceTypes);
                              },
                            },
                          ],
                        },
                        formElement: (
                          <Select
                            mode="multiple"
                            placeholder={i18n.t('forms.input.serviceTypes.placeholder') as string}
                            style={{ width: '300px' }}
                            maxTagCount="responsive"
                            optionFilterProp="label"
                            options={serviceRequestCategories?.listRequestCategories?.map(
                              (category) => ({
                                value: category.id,
                                label: category.name,
                              }),
                            )}
                          />
                        ),
                      },
                      {
                        label: 'Visit Cost',
                        name: 'visitCost',
                        value: '$ 50.00', // TODO: integrate with backend
                      },
                      {
                        label: 'Payment Information',
                        name: 'paymentInformation',
                        value: '**** **** **** 8603', // TODO: integrate with backend
                      },
                      {
                        label: 'Requests Accepted',
                        name: 'requestsAccepted',
                        value: '10/14', // TODO: integrate with backend
                      },
                    ]}
                  />
                </Masonry>
              </ResponsiveMasonry>
            ),
          },
          {
            label: <Trans i18nKey="manage.agentDetails.tabs.reviews" />,
            key: 'reviews',
            children: <AgentReviews agentId={Number(agentId)} />,
          },
          {
            label: <Trans i18nKey="manage.agentDetails.tabs.appointmentRequests" />,
            key: 'appointmentRequests',
            children: (
              <AgentsAppointmentRequests
                agentIds={[Number(agentId)]}
                showPageSizeChanger
                defaultPageSize={10}
              />
            ),
          },
          {
            label: <Trans i18nKey="manage.agentDetails.tabs.services" />,
            key: 'services',
            children: (
              <AgentsServiceRequests
                agentIds={[Number(agentId)]}
                showPageSizeChanger
                defaultPageSize={10}
              />
            ),
          },
          {
            label: <Trans i18nKey="manage.agentDetails.tabs.files" />,
            key: 'files',
            children: agent ? (
              <EmployeeInternalFiles
                employee={agent.User}
                uploadRequestBody={{ agentId: Number(agentId) }}
                filter={{ agentId: Number(agentId) }}
                sort={{ field: FileSortableFields.CreatedAt, direction: SortOrder.Desc }}
                // NOTE: for now we don't implement pagination for internal files
                page={1}
                pageSize={1000}
              />
            ) : null,
          },
        ]}
      />
    </Space>
  );
}

export default AgentDetailsPage;
