import { Trans, useTranslation } from 'react-i18next';

import {
  GraphqlServiceRequest,
  GraphqlCircleRequest,
  GraphqlAddresses,
  GraphqlAgent,
  RecurringFrequencyType,
  ServiceRequestStatus,
} from '@graphql/generated';

import ROUTES from '@constants/routes';
import { formatAddressBlock } from '@utils/formatters/address';
import { availabilityCompareFn } from '@utils/date';

import { DatePicker } from 'antd';
import Link from '@components/Link';
import AgentLink from '@components/AgentLink';
import { Select } from '@components/Input/Select';
import RecordDetailCard from '@components/Card/RecordDetailCard';
import { useDateWithTimezone } from '@hooks/useDateWithTimezone';

type ActivityDetailCardProps = {
  isServiceRequest?: boolean | undefined;
  activity: GraphqlServiceRequest | GraphqlCircleRequest;
  onEdit: (values: any) => void;
};

function ActivityDetailCard({ isServiceRequest, activity, onEdit }: ActivityDetailCardProps) {
  const i18n = useTranslation();
  const { formatAvailabilityInTimezone, formatDateAndTimeInTimezone, transformToTimezone } =
    useDateWithTimezone();

  return (
    <RecordDetailCard
      title={<Trans i18nKey="manage.serviceRequest.headers.details" />}
      editable
      onEdit={onEdit}
      cardProps={{
        style: { minWidth: '350px' },
      }}
      fields={[
        {
          name: 'requestedBy',
          label: <Trans i18nKey="manage.serviceRequest.fields.requestedBy" />,
          value: (
            <Link to={ROUTES.MANAGE_USER.replace(':userId', String(activity?.RequestedBy?.id))}>
              {activity?.RequestedBy?.fullName}
            </Link>
          ),
        },
        {
          name: 'createdBy',
          label: <Trans i18nKey="manage.serviceRequest.fields.createdBy" />,
          value: (
            <Link to={ROUTES.MANAGE_USER.replace(':userId', String(activity?.CreatedBy?.id))}>
              {activity?.CreatedBy?.fullName}
            </Link>
          ),
        },
        {
          name: 'description',
          label: <Trans i18nKey="manage.serviceRequest.fields.description" />,
          value: activity?.description,
        },
        {
          name: 'category',
          label: <Trans i18nKey="manage.serviceRequest.fields.category" />,
          value: <Trans i18nKey={`serviceTypes.${activity?.category}`} />,
        },
        {
          name: 'location',
          label: <Trans i18nKey="manage.serviceRequest.fields.location" />,
          value: activity?.Address ? (
            formatAddressBlock(activity?.Address as GraphqlAddresses)
          ) : (
            <div>
              {formatAddressBlock(activity?.ride?.pickupAddress as GraphqlAddresses)}
              <hr />
              {activity?.ride?.stops?.map((stop) => (
                <>
                  {formatAddressBlock(stop as GraphqlAddresses)}
                  <hr />
                </>
              ))}
              {formatAddressBlock(activity?.ride?.dropoffAddress as GraphqlAddresses)}
            </div>
          ),
        },
        {
          name: 'availabilities',
          label: <Trans i18nKey="manage.serviceRequest.fields.availabilities" />,
          value: (
            <div>
              {activity?.isAvailabilityFlexible ? (
                <>
                  <Trans i18nKey="manage.serviceRequests.create.flexible" />
                  &nbsp;(
                  <Trans i18nKey={`tentativeSchedules.${activity?.tentativeSchedule}`} />)
                </>
              ) : (
                [...(activity?.availabilities || [])]
                  ?.sort(availabilityCompareFn)
                  ?.map((availability, index) => (
                    <>
                      {index !== 0 && <hr />}
                      {formatAvailabilityInTimezone(availability)}
                    </>
                  ))
              )}
            </div>
          ),
        },
        ...([
          ServiceRequestStatus.Confirmed,
          ServiceRequestStatus.Completed,
          ServiceRequestStatus.Cancelled,
        ].includes(activity?.status)
          ? [
              {
                name: 'scheduledAt',
                label: <Trans i18nKey="manage.serviceRequest.fields.serviceDate" />,
                // NOTE: the initial value when editing is in local timezone
                value: activity?.scheduledAt ? transformToTimezone(activity?.scheduledAt) : null,
                render: () =>
                  activity?.scheduledAt ? (
                    <div style={{ textAlign: 'right' }}>
                      <div>
                        {formatDateAndTimeInTimezone(activity.scheduledAt, 'MMMM Do, YYYY')}
                      </div>
                      <div>{formatDateAndTimeInTimezone(activity.scheduledAt, '@ h:mma z')}</div>
                    </div>
                  ) : (
                    'N/A'
                  ),
                editable: true,
                formElement: (
                  <DatePicker use12Hours showTime changeOnBlur format="MMMM DD, YYYY @ h:mma z" />
                ),
              },
              isServiceRequest
                ? {
                    name: 'assignedTo',
                    label: <Trans i18nKey="manage.serviceRequest.fields.assignedTo" />,
                    value: (activity as GraphqlServiceRequest)?.Agent?.User?.fullName,
                    render: () =>
                      (activity as GraphqlServiceRequest)?.Agent?.User?.id ? (
                        <AgentLink
                          agent={(activity as GraphqlServiceRequest)?.Agent as GraphqlAgent}
                        />
                      ) : (
                        'N/A'
                      ),
                  }
                : {
                    name: 'acceptedBy',
                    label: <Trans i18nKey="manage.circleRequests.columns.acceptedBy" />,
                    value: (
                      <Link
                        to={ROUTES.MANAGE_USER.replace(
                          ':userId',
                          String((activity as GraphqlCircleRequest)?.AcceptedBy?.id),
                        )}
                      >
                        {(activity as GraphqlCircleRequest)?.AcceptedBy?.fullName}
                      </Link>
                    ),
                  },
            ]
          : []),
        {
          name: 'recurring',
          label: <Trans i18nKey="manage.serviceRequest.fields.recurring" />,
          value: activity?.recurring,
          render: () =>
            activity?.recurring ? (
              <Trans i18nKey={`recurringFrequencyType.${activity?.frequency}`} />
            ) : (
              <Trans i18nKey="boolean.false" />
            ),
          editable: true,
          isBoolean: true,
        },
        {
          name: 'frequency',
          label: <Trans i18nKey="manage.serviceRequest.fields.frequency" />,
          value: activity?.frequency,
          editable: true,
          onlyRenderEdit: true,
          render: () => null,
          formElement: (
            <Select
              style={{ minWidth: 200 }}
              options={Object.values(RecurringFrequencyType).map((frequency) => ({
                label: i18n.t(`recurringFrequencyType.${frequency}`),
                value: frequency,
              }))}
            />
          ),
        },
      ]}
    />
  );
}

ActivityDetailCard.defaultProps = {
  isServiceRequest: undefined,
};

export default ActivityDetailCard;
