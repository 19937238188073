import { useCallback } from 'react';
import styled from 'styled-components';
import { useParams } from 'react-router-dom';
import { Trans, useTranslation } from 'react-i18next';

import {
  GlobalRole,
  UpdateCompanyInput,
  useGetCompanyQuery,
  useUpdateCompanyMutation,
} from '@graphql/generated';
import { GET_COMPANY_DETAIL_QUERY } from '@graphql/pros';
import { useUser } from '@hooks/appContext/useUser';
import { formatISOtoMMDDYYYY } from '@utils/date';

import { Row, Select, Space, Typography, message } from 'antd';
import Loading from '@components/Loading';
import EntityRating from '@components/Rate/EntityRating';
import GenericApolloError from '@components/GenericApolloError';
import RecordDetailCard from '@components/Card/RecordDetailCard';
import AgentsServiceRequests from '@components/Pros/AgentsServiceRequests';
import AgentsTable from '@components/Pros/AgentsTable';

const CardsFlex = styled.div`
  display: inline-flex;
  gap: 24px;
  flex-wrap: wrap;
  align-items: flex-start;
  max-width: 100%;
`;

const FIRST_COLUMN_WIDTH = '592px';

function CompanyDetail() {
  const i18n = useTranslation();
  const user = useUser();
  const hasEditPermission = user?.role === GlobalRole.Admin || user?.role === GlobalRole.Support;
  const { companyId } = useParams();
  const {
    data: getCompanyQuery,
    loading,
    error,
  } = useGetCompanyQuery({ variables: { companyId: Number(companyId) } });
  const [updateCompany, { loading: isUpdatingCompanyDetail }] = useUpdateCompanyMutation({
    refetchQueries: [GET_COMPANY_DETAIL_QUERY],
  });
  const company = getCompanyQuery?.getCompany;

  const handleEditCompanyDetails = useCallback(
    async (values: UpdateCompanyInput) => {
      await updateCompany({
        variables: { input: values, companyId: Number(companyId) },
      });
      message.success(<Trans i18nKey="forms.editCompanyDetail.successMessage" />);
    },
    [updateCompany, companyId],
  );

  if (error) return <GenericApolloError error={error} />;
  if (loading) return <Loading />;

  return (
    <>
      <Row justify="space-between" align="middle" style={{ marginBottom: '20px' }}>
        <Typography.Title level={2}>
          <Trans i18nKey="manage.companyDetail.title" values={{ companyName: company?.name }} />
        </Typography.Title>
      </Row>
      <Row>
        <CardsFlex>
          <Space size="large" align="start" direction="vertical">
            <RecordDetailCard
              title={<Trans i18nKey="manage.companyDetail.companyDetailsTitle" />}
              editable={hasEditPermission}
              onEdit={handleEditCompanyDetails}
              cardProps={{
                style: { width: FIRST_COLUMN_WIDTH },
                loading: loading || isUpdatingCompanyDetail,
              }}
              fields={[
                {
                  label: <Trans i18nKey="manage.companyDetail.fields.name" />,
                  name: 'name',
                  value: company?.name,
                  editable: true,
                  autoFocus: true,
                },

                {
                  label: <Trans i18nKey="manage.companyDetail.fields.addressLine1" />,
                  name: 'addressLine1',
                  value: company?.addressLine1,
                  editable: true,
                },
                {
                  label: <Trans i18nKey="manage.companyDetail.fields.addressLine2" />,
                  name: 'addressLine2',
                  value: company?.addressLine2,
                  editable: true,
                },
                {
                  label: <Trans i18nKey="manage.companyDetail.fields.city" />,
                  name: 'city',
                  value: company?.city,
                  editable: true,
                },
                {
                  label: <Trans i18nKey="manage.companyDetail.fields.state" />,
                  name: 'state',
                  value: company?.state,
                  editable: true,
                },
                {
                  label: <Trans i18nKey="manage.companyDetail.fields.zipCode" />,
                  name: 'zipCode',
                  value: company?.zipCode,
                  editable: true,
                },
                {
                  label: <Trans i18nKey="manage.companyDetail.fields.phoneNumber" />,
                  name: 'phoneNumber',
                  value: company?.phoneNumber,
                  editable: true,
                },
                {
                  label: <Trans i18nKey="manage.companyDetail.fields.website" />,
                  name: 'website',
                  render: () => (
                    <a
                      target="_blank"
                      href={
                        company?.website?.startsWith('http')
                          ? company?.website
                          : `https://${company?.website}`
                      }
                      style={{ fontSize: '14px' }}
                      rel="noreferrer"
                    >
                      {company?.website}
                    </a>
                  ),
                  value: company?.website,
                  editable: true,
                },
                {
                  label: <Trans i18nKey="manage.companyDetail.fields.hours" />,
                  name: 'hours',
                  value: company?.hours,
                  editable: true,
                },
                {
                  label: <Trans i18nKey="manage.companyDetail.fields.createdAt" />,
                  name: 'createdAt',
                  value: formatISOtoMMDDYYYY(company?.createdAt),
                },
                {
                  label: <Trans i18nKey="manage.companyDetail.fields.rating" />,
                  name: 'rating',
                  value: <EntityRating value={company?.rating} />,
                },
                {
                  label: <Trans i18nKey="manage.companyDetail.fields.serviceZipCodes" />,
                  name: 'serviceZipCodes',
                  value: company?.serviceZipCodes,
                  render: () => company?.serviceZipCodes?.join(', '),
                  editable: true,
                  formElement: (
                    <Select
                      mode="tags"
                      tokenSeparators={[',']}
                      placeholder={i18n.t('forms.input.serviceZipCodes.placeholder')}
                      style={{ minWidth: 280 }}
                    />
                  ),
                },
                {
                  label: <Trans i18nKey="manage.companyDetail.fields.stripeAccountId" />,
                  name: 'stripeAccountId',
                  value: company?.StripeConnectAccounts?.stripeAccountId || i18n.t('fallbacks.N/A'),
                },
              ]}
            />
            <AgentsTable
              companyId={Number(companyId)}
              cardProps={{ style: { width: FIRST_COLUMN_WIDTH } }}
              columns={['fullName', 'serviceTypes', 'rating', 'internalRating', 'edit']}
            />
          </Space>
          {!!company?.Agents?.length && (
            <AgentsServiceRequests
              agentIds={company?.Agents.map((agent) => agent.userId)}
              cardProps={{
                style: {
                  minWidth: FIRST_COLUMN_WIDTH,
                  flexBasis: 'min-content',
                  flexGrow: 1,
                },
              }}
            />
          )}
        </CardsFlex>
      </Row>
    </>
  );
}

export default CompanyDetail;
