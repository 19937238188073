import { useApolloClient } from '@apollo/client';
import RecordDetailCard from '@components/Card/RecordDetailCard';
import Input from '@components/Input';
import { ProfilePicture } from '@components/Users/ProfilePicture';
import { AdminUpdateUserInput, GraphqlUser } from '@graphql/generated';
import { GET_USER_QUERY } from '@graphql/users';
import { GET_AGENT_QUERY } from '@graphql/pros';
import { CardProps, message } from 'antd';
import { Trans, useTranslation } from 'react-i18next';
import styled from 'styled-components';

const Bio = styled.div`
  flex-basis: 100%;
`;

interface UserCustomerFacingDetailsProps {
  user: Partial<GraphqlUser>;
  onEdit?: (values: AdminUpdateUserInput) => void;
  loading: boolean;
  editable: boolean;
  cardProps?: CardProps;
}

export function UserCustomerFacingDetails({
  user,
  onEdit,
  loading,
  editable,
  cardProps,
}: UserCustomerFacingDetailsProps) {
  const i18n = useTranslation();
  const client = useApolloClient();

  return (
    <RecordDetailCard
      title={<Trans i18nKey="manage.users.detail.customerFacingInfo.title" />}
      cardProps={{
        ...cardProps,
        loading,
        cover: (
          <ProfilePicture
            alt={`${user?.fullName} Profile`}
            src={String(user?.profilePicture)}
            user={user}
            withUpload={editable}
            onUploadError={(errorMsg: string) => {
              message.error(
                i18n.t('manage.users.detail.customerFacingInfo.uploadProfilePictureError'),
              );
              if (errorMsg) {
                message.error(errorMsg);
              }
            }}
            onUploadSuccess={async () => {
              await client.refetchQueries({
                include: [GET_USER_QUERY, GET_AGENT_QUERY],
              });
            }}
          />
        ),
      }}
      editable={editable}
      onEdit={onEdit}
      fields={[
        {
          name: 'firstName',
          label: <Trans i18nKey="manage.users.detail.personalInfo.fields.firstName" />,
          value: user?.firstName,
          editable: true,
          autoFocus: true,
          formItemProps: {
            validateTrigger: 'onSubmit',
            required: true,
            rules: [
              {
                required: true,
                message: i18n.t('forms.input.firstName.errorMessage') as string,
                whitespace: true,
              },
            ],
          },
        },
        {
          name: 'lastName',
          label: <Trans i18nKey="manage.users.detail.personalInfo.fields.lastName" />,
          value: user?.lastName,
          editable: true,
          formItemProps: {
            validateTrigger: 'onSubmit',
            required: true,
            rules: [
              {
                required: true,
                message: i18n.t('forms.input.lastName.errorMessage') as string,
                whitespace: true,
              },
            ],
          },
        },
        {
          name: 'bio',
          label: <Trans i18nKey="manage.users.detail.personalInfo.fields.bio" />,
          formElement: (
            <Input.TextArea name="bio" value={user?.bio || ''} maxLength={240} showCount rows={5} />
          ),
          formItemProps: {
            style: {
              flexBasis: '100%',
            },
          },
          value: user?.bio,
          render: () => <Bio>{user?.bio}</Bio>,
          valueFallback: <Trans i18nKey="fallbacks.notSet" />,
          editable: true,
        },
      ]}
    />
  );
}

UserCustomerFacingDetails.defaultProps = {
  cardProps: {},
  onEdit: () => {},
};
