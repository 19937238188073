import { gql } from '@apollo/client';

export const GET_SOCIAL_EVENTS_QUERY = gql`
  query GetSocialEvents(
    $filter: SocialEventFilter!
    $sort: SocialEventSortInput
    $pageSize: Int
    $page: Int
  ) {
    getSocialEvents(filter: $filter, sort: $sort, pageSize: $pageSize, page: $page) {
      meta {
        currentPage
        totalCount
      }
      socialEvents {
        id
        status
        scheduledAt
        createdAt
        RequestedBy {
          id
          fullName
        }
        eventName
        Coaches {
          Coach {
            id
            fullName
          }
        }
        Participants {
          status
          id
          Customer {
            fullName
          }
          ContactRequests {
            id
            userMessage
            createdAt
            status
            bryaMessage
            bryaInternalNotes
            updatedAt
          }
        }
        place
        addressLine1
        addressLine2
        city
        state
        zipCode
        country
      }
    }
  }
`;

export const GET_SOCIAL_EVENT_QUERY = gql`
  query GetSocialEvent($socialEventId: Int!) {
    getSocialEvent(socialEventId: $socialEventId) {
      id
      createdAt
      eventName
      description
      place
      addressLine1
      addressLine2
      city
      state
      country
      zipCode
      status
      RequestedBy {
        id
        fullName
      }
      Coaches {
        Coach {
          id
          fullName
        }
      }
      Participants {
        Customer {
          id
          fullName
        }
        status
        ContactRequests {
          id
          userMessage
          createdAt
          status
          bryaMessage
          bryaInternalNotes
          updatedAt
        }
      }
      recurring
      frequency
      requestType
      scheduledAt
      cancellationReason
      updatedAt
    }
  }
`;

export const CREATE_SOCIAL_EVENT_MUTATION = gql`
  mutation CreateSocialEvent($data: CreateSocialEventInput!) {
    createSocialEvent(data: $data) {
      id
    }
  }
`;

export const UPDATE_SOCIAL_EVENT_MUTATION = gql`
  mutation UpdateSocialEvent($data: UpdateSocialEventInput!, $socialEventId: Int!) {
    updateSocialEvent(data: $data, socialEventId: $socialEventId) {
      id
    }
  }
`;

export const UPDATE_CONTACT_REQUEST_MUTATION = gql`
  mutation UpdateContactRequest(
    $updateContactRequestInput: UpdateContactRequestInput!
    $contactRequestId: Int!
  ) {
    updateContactRequest(
      updateContactRequestInput: $updateContactRequestInput
      contactRequestId: $contactRequestId
    ) {
      id
    }
  }
`;
