import { useEffect, useMemo, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import {
  GET_APPOINTMENT_REQUESTS_FILTERED_QUERY,
  GET_ACTIVITY_TIMELINE_QUERY,
} from '@graphql/activities';
import {
  GraphqlAgent,
  GraphqlAppointmentRequest,
  InputMaybe,
  PaginationMeta,
  AppointmentRequestFilter,
  AppointmentRequestSortInput,
  AppointmentRequestStatus,
  AppointmentRequestsSortableFields,
  SortOrder,
  ServiceRequestCategoriesEnum,
  UpdateAppointmentRequestInput,
  useGetAppointmentRequestsFilteredQuery,
  useListRequestCategoriesQuery,
  useUpdateAppointmentRequestMutation,
} from '@graphql/generated';

import ROUTES from '@constants/routes';
import { ID_MIN_LENGTH } from '@constants/output';
import { DEFAULT_SEARCH_DEBOUNCE_TIME } from '@constants/input';
import { formatISOtoMMDDYYYY /* formatISOtoMMDDYYYYandTime */ } from '@utils/date';

import { CardProps, Row } from 'antd';
import { ColumnsType, TablePaginationConfig } from 'antd/es/table';
import { FilterValue, SorterResult } from 'antd/es/table/interface';
import Input from '@components/Input';
import { Card } from '@components/Card';
import Table from '@components/Table/Table';
import AgentLink from '@components/AgentLink';
import GenericApolloError from '@components/GenericApolloError';
import { TableDateFilter } from '@components/Table/TableDateFilter';
import AppointmentRequestStatusDropdown from '@components/Pros/AppointmentRequestStatusDropdown';

function AgentsAppointmentRequests({
  agentIds,
  cardProps,
  showPageSizeChanger,
  defaultPageSize,
}: {
  agentIds: number[] | undefined;
  cardProps?: CardProps;
  defaultPageSize?: number;
  showPageSizeChanger?: boolean;
}) {
  const i18n = useTranslation();
  const [searchAppointmentRequestsText, setSearchAppointmentRequestsText] = useState('');
  const [filters, setFilters] = useState<AppointmentRequestFilter>({
    agentId: agentIds,
  });
  const { data: serviceRequestCategories } = useListRequestCategoriesQuery({
    variables: { serviceRequest: true },
  });
  const [sortOptions, setSortOptions] = useState<InputMaybe<AppointmentRequestSortInput>>();
  const [pageSize, setPageSize] = useState(defaultPageSize);
  const [page, setPage] = useState(1);
  const {
    data: appointmentRequestsData,
    error: appointmentRequestsError,
    loading: appointmentRequestsLoading,
    previousData,
  } = useGetAppointmentRequestsFilteredQuery({
    variables: { filter: filters, sort: sortOptions, page, pageSize },
  });
  const [updateAppointmentRequestMutation, { loading: updateAppointmentRequestLoading }] =
    useUpdateAppointmentRequestMutation({
      refetchQueries: [GET_APPOINTMENT_REQUESTS_FILTERED_QUERY, GET_ACTIVITY_TIMELINE_QUERY],
    });

  useEffect(() => {
    const timer = setTimeout(() => {
      setFilters((previousFilters) => ({
        ...previousFilters,
        text: searchAppointmentRequestsText,
      }));
    }, DEFAULT_SEARCH_DEBOUNCE_TIME);
    return () => clearTimeout(timer);
  }, [searchAppointmentRequestsText]);

  const appointmentRequests = useMemo(
    () =>
      ((appointmentRequestsData || previousData)?.getAppointmentRequestsFiltered
        .appointmentRequests as GraphqlAppointmentRequest[]) || [],
    [appointmentRequestsData, previousData],
  );

  const pagination =
    ((appointmentRequestsData || previousData)?.getAppointmentRequestsFiltered
      .meta as PaginationMeta) || {};

  const columns: ColumnsType<GraphqlAppointmentRequest> = useMemo(
    () => [
      {
        title: <Trans i18nKey="manage.appointmentRequests.columns.id" />,
        dataIndex: 'id',
        key: 'id',
        render: (_: any, record: GraphqlAppointmentRequest) =>
          String(record.id).padStart(ID_MIN_LENGTH, '0'),
        sorter: true,
      },
      {
        title: <Trans i18nKey="manage.appointmentRequests.columns.serviceId" />,
        dataIndex: 'id',
        key: 'id',
        render: (_: any, record: GraphqlAppointmentRequest) => (
          <Link
            to={ROUTES.MANAGE_SERVICE_REQUEST.replace(
              ':activityId',
              String(record?.ServiceRequest?.id),
            )}
          >
            {String(record?.ServiceRequest?.id).padStart(ID_MIN_LENGTH, '0')}
          </Link>
        ),
        sorter: true,
      },
      {
        title: <Trans i18nKey="manage.appointmentRequests.columns.createdBy" />,
        key: 'createdBy',
        render: (_: any, record: GraphqlAppointmentRequest) => (
          <Link to={ROUTES.MANAGE_USER.replace(':userId', String(record?.CreatedBy?.id))}>
            {record?.CreatedBy?.fullName}
          </Link>
        ),
      },
      {
        title: <Trans i18nKey="manage.appointmentRequests.columns.account" />,
        key: 'account',
        render: (_: any, record: GraphqlAppointmentRequest) => (
          <Link
            to={ROUTES.MANAGE_CUSTOMER.replace(
              ':customerId',
              String(record?.ServiceRequest?.Customer.id),
            )}
          >
            {record?.ServiceRequest?.Customer.fullName}
          </Link>
        ),
      },
      {
        title: <Trans i18nKey="manage.appointmentRequests.columns.agent" />,
        key: 'agentId',
        render: (_: any, record: GraphqlAppointmentRequest) => (
          <AgentLink agent={{ User: record?.SentTo } as GraphqlAgent} />
        ),
      },
      {
        title: <Trans i18nKey="manage.appointmentRequests.columns.type" />,
        key: 'category',
        dataIndex: 'category',
        render: (_: any, record: GraphqlAppointmentRequest) =>
          i18n.t(`serviceTypes.${record?.ServiceRequest?.category}`) as string,
        sorter: true,
        filters: serviceRequestCategories?.listRequestCategories?.map((category) => ({
          text: category.name,
          value: category.id,
        })),
      },
      {
        title: <Trans i18nKey="manage.appointmentRequests.columns.createdAt" />,
        key: 'createdAt',
        dataIndex: 'createdAt',
        render: (_: any, record: GraphqlAppointmentRequest) =>
          formatISOtoMMDDYYYY(record.createdAt),
        sorter: true,
        // eslint-disable-next-line react/no-unstable-nested-components
        filterDropdown: (props) => (
          <TableDateFilter
            {...props}
            onRangeSelected={(from, until) =>
              setFilters((prevFilters) => ({
                ...prevFilters,
                requestedAtFrom: from,
                requestedAtUntil: until,
              }))
            }
          />
        ),
        filtered: Boolean(filters.requestedAtFrom || filters.requestedAtUntil),
      },
      {
        title: <Trans i18nKey="manage.appointmentRequests.columns.status" />,
        key: 'status',
        dataIndex: 'status',
        render: (_, record) => {
          return (
            <AppointmentRequestStatusDropdown
              appointmentRequest={record}
              onChange={async (updateAppointmentRequestInput: UpdateAppointmentRequestInput) =>
                updateAppointmentRequestMutation({
                  variables: { updateAppointmentRequestInput },
                })
              }
            />
          );
        },
        sorter: true,
        filters: Object.values(AppointmentRequestStatus).map((appointmentRequestStatus) => {
          return {
            text: <Trans i18nKey={`appointmentRequestStatus.${appointmentRequestStatus}`} />,
            value: appointmentRequestStatus,
          };
        }),
      },
    ],
    [
      i18n,
      filters,
      updateAppointmentRequestMutation,
      serviceRequestCategories?.listRequestCategories,
    ],
  );

  const onTableChange = (
    tablePagination: TablePaginationConfig,
    tableFilters: Record<string, FilterValue | null>,
    tableSorter:
      | SorterResult<GraphqlAppointmentRequest>
      | SorterResult<GraphqlAppointmentRequest>[],
  ) => {
    const uniqueSorter = tableSorter as SorterResult<GraphqlAppointmentRequest>;

    // Pagination
    setPage(tablePagination.current as number);
    setPageSize(tablePagination.pageSize as number);

    // Order
    if (uniqueSorter.order) {
      const direction = uniqueSorter.order === 'ascend' ? SortOrder.Asc : SortOrder.Desc;
      setSortOptions({
        field: uniqueSorter.field as AppointmentRequestsSortableFields,
        direction,
      });
    } else {
      setSortOptions(undefined);
    }

    // Filters
    const { status, category } = tableFilters;
    setFilters((prevFilters) => ({
      ...prevFilters,
      category: category as ServiceRequestCategoriesEnum[],
      status: status as AppointmentRequestStatus[],
    }));
  };

  if (appointmentRequestsError) return <GenericApolloError error={appointmentRequestsError} />;

  return (
    <Card title={<Trans i18nKey="manage.appointmentRequests.title" />} {...cardProps}>
      <Row style={{ marginBottom: '20px' }}>
        <Input
          placeholder={i18n.t('manage.appointmentRequests.search') as string}
          value={searchAppointmentRequestsText}
          onChange={(e) => setSearchAppointmentRequestsText(e.target.value)}
        />
      </Row>
      <Table
        loading={appointmentRequestsLoading || updateAppointmentRequestLoading}
        columns={columns}
        dataSource={(appointmentRequests || []).map((appointmentRequest) => ({
          ...appointmentRequest,
          key: appointmentRequest.id,
        }))}
        style={{ overflowX: 'auto' }}
        onChange={onTableChange}
        rowClassName={(record) =>
          record.status === AppointmentRequestStatus.NotChosen ? 'disabled-row' : ''
        }
        pagination={{
          pageSize,
          showSizeChanger: showPageSizeChanger,
          defaultCurrent: 1,
          current: pagination?.currentPage,
          total: pagination?.totalCount,
        }}
      />
    </Card>
  );
}

AgentsAppointmentRequests.defaultProps = {
  cardProps: {},
  showPageSizeChanger: false,
  defaultPageSize: 100,
};

export default AgentsAppointmentRequests;
