import { ServiceRequestAvailabilityInput } from '@graphql/generated';

export enum ActivitiesTypes {
  SERVICE_REQUEST = 'serviceRequest',
  CIRCLE_REQUEST = 'circleRequest',
  SOCIAL_EVENT = 'socialEvent',
}

// This is kind of kludgy, but we need the ID to maintain proper UI state
export type ExtendedServiceRequestAvailabilityInput = ServiceRequestAvailabilityInput & {
  key: number;
};
