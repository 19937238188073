import { useState } from 'react';
import styled from 'styled-components';
import { Trans, useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { GET_CUSTOMERS_QUERY } from '@graphql/users';
import {
  CreateCoachVisitInput,
  GetCustomerOutput,
  GlobalRole,
  SortOrder,
  UserSortableFields,
  useGetUsersQuery,
  useCreateAddressMutation,
  useCreateCoachVisitMutation,
} from '@graphql/generated';

import { ROUTES } from '@constants/routes';
import { useDateWithTimezone } from '@hooks/useDateWithTimezone';

import { DatePicker, Form, Select, Spin, Typography } from 'antd';
import Modal from '@components/Modal';
import Button from '@components/Button';
import CustomerSearch from '@components/Activities/CustomerSearch';
import LocationDropdownOther from '@components/Activities/LocationDropdownOther';

const FormItem = styled(Form.Item).attrs(() => ({
  labelCol: { span: 24 },
}))`
  .ant-form-item-label {
    padding: 0px !important;
  }
`;

function CreateCoachVisitModal({ onCancel }: { onCancel: () => void }) {
  const i18n = useTranslation();
  const { transformDateToTimezone } = useDateWithTimezone();
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const [customer, setCustomer] = useState<GetCustomerOutput | null>(null);
  const { data: coachesData } = useGetUsersQuery({
    variables: {
      filter: { roles: [GlobalRole.Coach, GlobalRole.Support, GlobalRole.Admin] },
      page: 1,
      pageSize: 100,
      sort: { field: UserSortableFields.FullName, direction: SortOrder.Asc },
    },
  });
  const [createAddressMutation] = useCreateAddressMutation({
    refetchQueries: [GET_CUSTOMERS_QUERY],
  });
  const [createCoachVisitMutation, { loading: createCoachVisitLoading }] =
    useCreateCoachVisitMutation();

  if (!coachesData) {
    return (
      <Modal open width={750}>
        <div style={{ padding: 329 }}>
          <Spin size="large" />
        </div>
      </Modal>
    );
  }

  return (
    <Modal
      width={750}
      closable
      onCancel={onCancel}
      open
      title={
        <Typography.Title level={2}>
          <Trans i18nKey="manage.coachVisits.new" />
        </Typography.Title>
      }
    >
      <Form
        form={form}
        initialValues={{ recurring: false }}
        onFinish={async (values) => {
          const { contactMethod, ...payload } = values;
          const response = await createCoachVisitMutation({
            variables: {
              data: {
                ...(payload as CreateCoachVisitInput),
                scheduledAt: transformDateToTimezone(payload?.scheduledAt),
              },
            },
          });
          navigate(
            ROUTES.MANAGE_COACH_VISIT.replace(
              ':activityId',
              String(response?.data?.createCoachVisit?.id),
            ),
          );
        }}
      >
        <FormItem
          name="coachId"
          label={i18n.t('forms.input.coachId.label')}
          rules={[
            { required: true, message: i18n.t('forms.input.coachId.errorMessage') as string },
          ]}
        >
          <Select
            placeholder={String(i18n.t('forms.input.coachId.placeholder'))}
            options={coachesData?.getUsers?.users?.map((user) => ({
              label: user?.fullName,
              value: user?.id,
            }))}
          />
        </FormItem>
        <CustomerSearch
          label={i18n.t('forms.input.customerId.label')}
          rules={[
            {
              required: true,
              message: i18n.t('forms.input.customerId.errorMessage') as string,
            },
          ]}
          onChange={(value) => {
            setCustomer(value);
            form.setFieldsValue({
              addressId:
                value?.addresses?.find((address) => address?.isDefault)?.id ||
                value?.addresses?.[0]?.id ||
                null,
              customerId: value?.id,
            });
          }}
        />
        <FormItem dependencies={['customerId', 'contactMethod']} noStyle>
          {({ getFieldValue }) => {
            const contactMethod = getFieldValue('contactMethod');
            const customerId = getFieldValue('customerId');
            const formAddressId = getFieldValue('addressId');
            return (
              <>
                <FormItem name="contactMethod" label={i18n.t('forms.input.contactMethod.label')}>
                  <Select
                    placeholder={String(i18n.t('forms.input.contactMethod.placeholder'))}
                    defaultValue="address"
                    disabled={!customerId}
                    options={['address', 'phone'].map((method) => ({
                      label: i18n.t(`forms.input.contactMethod.${method}`),
                      value: method,
                    }))}
                    onChange={(value) => {
                      form.setFieldsValue({
                        addressId:
                          value === 'phone'
                            ? null
                            : customer?.addresses?.find((address) => address.isDefault)?.id ||
                              customer?.addresses?.[0]?.id ||
                              null,
                      });
                    }}
                  />
                </FormItem>
                {contactMethod === 'phone' ? null : (
                  <FormItem
                    name="addressId"
                    label={i18n.t('forms.input.addressId.label')}
                    key={customerId}
                    rules={[
                      {
                        required: true,
                        message: i18n.t('forms.input.addressId.errorMessage') as string,
                      },
                    ]}
                  >
                    <LocationDropdownOther
                      addresses={customer?.addresses || []}
                      value={formAddressId}
                      disabled={!customerId}
                      onChange={async (address) => {
                        let addressId = address;
                        // If address is an object instead of an ID, we need to create an address entry
                        if (typeof addressId === 'object') {
                          const response = await createAddressMutation({
                            variables: {
                              createAddressInput: {
                                country: 'US',
                                ...address,
                                customerId: customer?.id,
                                isFromAPI: true,
                              },
                            },
                          });
                          // So that we can use its id when creating the service request
                          addressId = response?.data?.createAddress?.id;
                        }
                        form.setFieldsValue({ addressId });
                      }}
                      defaultToDefaultAddress
                      placeholder={String(i18n.t('forms.input.addressId.placeholder'))}
                    />
                  </FormItem>
                )}
              </>
            );
          }}
        </FormItem>
        <FormItem
          name="scheduledAt"
          label={i18n.t('forms.input.scheduledAt.label')}
          rules={[
            { required: true, message: i18n.t('forms.input.scheduledAt.errorMessage') as string },
          ]}
        >
          <DatePicker
            format="MMMM DD, YYYY @ h:mma"
            showTime={{ format: 'h:mma' }}
            style={{ width: '100%' }}
          />
        </FormItem>
        <FormItem>
          <Button type="primary" htmlType="submit" loading={createCoachVisitLoading}>
            <Trans i18nKey="manage.coachVisits.create" />
          </Button>
        </FormItem>
      </Form>
    </Modal>
  );
}

export default CreateCoachVisitModal;
