import { message } from 'antd';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { EmptyUnauthorized } from '@components/GenericApolloError/EmptyUnauthorized';

const DONT_DISMISS = 0;

export function ForbiddenPage() {
  const i18n = useTranslation();

  useEffect(() => {
    message.error(i18n.t('errors.forbiddenPage'), DONT_DISMISS);
    return message.destroy;
  }, [i18n]);

  return <EmptyUnauthorized />;
}
