import { ReactNode, useMemo } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import { Input as InputAntd, Typography } from 'antd';
import { CheckOutlined } from '@ant-design/icons';
import { FormItem } from '@components/Form';
import { commonInputStyles } from './styles';

type InputNewPasswordProps = React.ComponentProps<typeof InputAntd> & {
  withConfirmPassword?: boolean;
  showLabels?: boolean;
  confirmPasswordPlaceholder?: string;
  passwordLabel?: string;
  required?: boolean;
};

const RulesContainer = styled.section`
  display: block;
  text-align: left;
  padding: 14px 22px;
  border-radius: 6px;
  background-color: ${({ theme }) => theme.colors.backgroundSecondary};
  font-size: ${({ theme }) => theme.fontSizes.small};
  line-height: ${({ theme }) => theme.lineHeights.small};
  color: ${({ theme }) => theme.colors.foregroundPrimary};
`;

type RuleValidator = (value: string) => boolean;

const MatchedRule = styled(Typography.Text)`
  display: block;
  svg {
    fill: ${({ theme }) => theme.colors.success};
  }
`;

const NotMatchedRule = styled(Typography.Text)`
  display: block;
`;

const StyledInputPassword = styled(InputAntd.Password)`
  ${commonInputStyles}
  // NOTE: Input.Password renders a root <span> element
  input {
    background-color: transparent;
    line-height: ${({ theme }) => theme.fontSizes.medium}; // large font-size, medium line-height?
  }
`;

const MIN_PASSWORD_LENGTH = 8;
const AT_LEAST_DIGIT_REGEX = /(?=.*\d)/; // use positive look ahead to see if at least one digit exists
const AT_LEAST_SYMBOL_REGEX = /(?=.*\W)/; // use positive look ahead to see if at least one non-word character exists
const isValidLength: RuleValidator = (v) => v.length >= MIN_PASSWORD_LENGTH;
const isValidSpecialCharacter: RuleValidator = (v) =>
  AT_LEAST_DIGIT_REGEX.test(v) || AT_LEAST_SYMBOL_REGEX.test(v);

type NewPasswordRuleProps = {
  value: string;
  isValid: RuleValidator;
  children: ReactNode;
};

function NewPasswordRule({ value, isValid, children }: NewPasswordRuleProps) {
  return isValid(value) ? (
    <MatchedRule>
      <CheckOutlined /> {children}
    </MatchedRule>
  ) : (
    <NotMatchedRule>
      <CheckOutlined /> {children}
    </NotMatchedRule>
  );
}

export function InputNewPassword({
  placeholder,
  withConfirmPassword,
  showLabels,
  confirmPasswordPlaceholder,
  passwordLabel,
  required,
}: InputNewPasswordProps) {
  const i18n = useTranslation();
  const invalidPasswordError = useMemo(
    () => new Error(i18n.t('forms.input.newPassword.errorMessage') as string),
    [i18n],
  );
  const passwordsMismatchError = useMemo(
    () => new Error(i18n.t('forms.input.newPassword.rulePasswordsMismatch') as string),
    [i18n],
  );

  return (
    <>
      <FormItem
        label={showLabels && (passwordLabel || i18n.t('forms.input.password.placeholder'))}
        name="password"
        rules={[
          {
            required,
            validator: (_rule, newPassword) => {
              const passwordToValidate = newPassword || '';
              if (
                (!required && !passwordToValidate?.length) ||
                (isValidLength(passwordToValidate) && isValidSpecialCharacter(passwordToValidate))
              ) {
                return Promise.resolve();
              }
              return Promise.reject(invalidPasswordError);
            },
          },
        ]}
      >
        <StyledInputPassword
          name="password"
          placeholder={placeholder}
          autoComplete="current-password"
        />
      </FormItem>
      {withConfirmPassword ? (
        <FormItem
          label={
            showLabels &&
            (confirmPasswordPlaceholder || i18n.t('forms.input.confirmNewPassword.placeholder'))
          }
          noStyle={!showLabels}
          name="confirmPassword"
          dependencies={['password']}
          rules={[
            {
              required,
              message: passwordsMismatchError.message,
            },
            ({ getFieldValue }) => ({
              validator(_rule, confirmPasswordValue) {
                const passwordFieldValue = getFieldValue('password');
                if (
                  (!required && !passwordFieldValue?.length && !confirmPasswordValue?.length) ||
                  !confirmPasswordValue ||
                  passwordFieldValue === confirmPasswordValue
                ) {
                  return Promise.resolve();
                }
                return Promise.reject(passwordsMismatchError);
              },
            }),
          ]}
        >
          <StyledInputPassword
            name="confirmPassword"
            placeholder={
              confirmPasswordPlaceholder ||
              (i18n.t('forms.input.confirmNewPassword.placeholder') as string)
            }
            autoComplete="new-password"
          />
        </FormItem>
      ) : null}
      <FormItem dependencies={['password']}>
        {({ getFieldValue, isFieldTouched }) => {
          const value = getFieldValue('password') || '';
          const isTouched = isFieldTouched('password');
          return value || isTouched ? (
            <RulesContainer>
              <NewPasswordRule value={value} isValid={isValidLength}>
                {i18n.t('forms.input.newPassword.ruleLength')}
              </NewPasswordRule>
              <NewPasswordRule value={value} isValid={isValidSpecialCharacter}>
                {i18n.t('forms.input.newPassword.ruleSpecialCharacter')}
              </NewPasswordRule>
            </RulesContainer>
          ) : null;
        }}
      </FormItem>
    </>
  );
}

InputNewPassword.defaultProps = {
  withConfirmPassword: false,
  showLabels: true,
  confirmPasswordPlaceholder: undefined,
  passwordLabel: undefined,
  required: true,
};

export default InputNewPassword;
