/* eslint-disable no-nested-ternary */
import { Form, Space, Typography, Button, CardProps, Checkbox, Divider } from 'antd';
import { Fragment, ReactNode, useMemo, useState } from 'react';
import {
  CheckCircleOutlined,
  CloseOutlined,
  EditOutlined,
  ExclamationCircleOutlined,
  SaveOutlined,
} from '@ant-design/icons';
import BaseInput from '@components/Input';
import styled from 'styled-components';
import { FormItem as BaseFormItem, FormItemProps } from '@components/Form';
import { Trans } from 'react-i18next';
import Tag from '@components/Tag';
import { set } from '@utils/object/set';
import Card from './Card';

const Input = styled(BaseInput)`
  height: 22px;
  font-size: 14px;
  line-height: 1.5714285714285714;
  text-align: right;
  padding: 0;
`;

const FormItem = styled(BaseFormItem)`
  margin-bottom: 0;

  .ant-form-item-control-input {
    min-height: unset;
  }
`;

const Row = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 0px 24px;
  flex-wrap: wrap;
`;

export type RecordField = {
  name: string;
  editable?: boolean;
  autoFocus?: boolean;
  isColumn?: boolean;
  label: ReactNode;
  onlyRenderEdit?: boolean;
  noRenderEdit?: boolean;
  hidden?: boolean;
  value: any;
  valueFallback?: ReactNode;
  isBoolean?: boolean;
  formItemProps?: FormItemProps;
  formElement?: ReactNode;
  render?: () => ReactNode;
};

type RecordDetailCardFieldRowProps = Omit<RecordField, 'name' | 'editable' | 'autoFocus'>;

function RecordDetailCardFieldRow({
  label,
  value,
  valueFallback,
  isColumn,
  render,
}: RecordDetailCardFieldRowProps) {
  return (
    <Row>
      <Typography.Text strong style={{ whiteSpace: 'nowrap' }}>
        {label}
        {isColumn ? '' : ':'}
      </Typography.Text>
      {render ? render() || valueFallback : value || valueFallback}
    </Row>
  );
}

RecordDetailCardFieldRow.defaultProps = {
  valueFallback: <Trans i18nKey="forms.recordDetailCard.defaultValueFallback" />,
};

type RecordDetailCardProps = {
  additionalActions?: JSX.Element[];
  title?: ReactNode;
  defaultEditing?: boolean;
  editable?: boolean;
  fields: RecordField[];
  onEdit?: (values: any) => void;
  cardProps?: CardProps;
  withDividers?: boolean;
  dividerMod?: number;
  hasData?: boolean;
  noDataWarning?: JSX.Element;
};

// NOTE: For now, this component only supports editing fields by <Input /> and <Checkbox />.
function RecordDetailCard({
  additionalActions = [],
  title,
  defaultEditing = false,
  editable,
  fields,
  onEdit,
  cardProps,
  withDividers,
  dividerMod = 1,
  hasData = true,
  noDataWarning,
}: RecordDetailCardProps) {
  const [isEditing, setIsEditing] = useState(defaultEditing);

  const actions = useMemo(() => {
    if (!editable) return [];
    return isEditing
      ? [
          <Button type="text" htmlType="reset" onClick={() => setIsEditing(false)}>
            <Space size="small">
              <CloseOutlined key="cancel" />
              <Trans i18nKey="forms.recordDetailCard.cancel" />
            </Space>
          </Button>,
          <Button type="text" htmlType="submit">
            <Space size="small">
              <SaveOutlined key="save" />
              <Trans i18nKey="forms.recordDetailCard.save" />
            </Space>
          </Button>,
        ]
      : [
          ...additionalActions,
          <Button disabled={cardProps?.loading} type="text" onClick={() => setIsEditing(true)}>
            <Space size="small">
              <EditOutlined key="edit" />
              <Trans i18nKey="forms.recordDetailCard.edit" />
            </Space>
          </Button>,
        ];
  }, [additionalActions, editable, isEditing, cardProps?.loading]);

  const initialValues = useMemo(() => {
    return fields
      ?.filter((field) => field.editable)
      .reduce((acc: { [key: string]: ReactNode }, field) => {
        acc[field.name] = field.value;
        return acc;
      }, {});
  }, [fields]);

  const Wrapper = isEditing
    ? ({ children }: { children: JSX.Element }) => (
        <Form
          initialValues={initialValues}
          onFinish={(payload) => {
            const values = Object.entries(payload).reduce((acc, [key, value]) => {
              return set(acc, key, value);
            }, {});
            // NOTE: support names for nested properties
            setIsEditing(false);
            if (onEdit) onEdit(values);
          }}
        >
          {children}
        </Form>
      )
    : ({ children }: { children: JSX.Element }) => children;

  return (
    <Wrapper>
      <Card title={title} actions={actions} {...cardProps}>
        <Space direction="vertical" size="small" style={{ width: '100%' }}>
          {hasData
            ? fields?.map((field, idx) =>
                (field.onlyRenderEdit && !isEditing) || (field.noRenderEdit && isEditing) ? null : (
                  <div key={field.name} style={{ display: field.hidden ? 'none' : 'block' }}>
                    <RecordDetailCardFieldRow
                      label={field.label}
                      isColumn={field.isColumn}
                      render={isEditing && field.editable ? undefined : field.render}
                      value={
                        isEditing && field.editable ? (
                          <FormItem
                            name={field.name}
                            valuePropName={field.isBoolean ? 'checked' : undefined}
                            {...field.formItemProps}
                          >
                            {field.isBoolean ? (
                              <Checkbox
                                defaultChecked={
                                  (typeof field.value === 'boolean' && field.value) || false
                                }
                              />
                            ) : field.formElement ? (
                              field.formElement
                            ) : (
                              <Input autoFocus={field.autoFocus} />
                            )}
                          </FormItem>
                        ) : field.isBoolean ? (
                          field.value ? (
                            <Tag icon={<CheckCircleOutlined />} color="success">
                              <Trans i18nKey="boolean.true" />
                            </Tag>
                          ) : (
                            <Tag icon={<ExclamationCircleOutlined />} color="error">
                              <Trans i18nKey="boolean.false" />
                            </Tag>
                          )
                        ) : (
                          field.value
                        )
                      }
                      valueFallback={field.valueFallback}
                    />
                    {idx !== fields.length - 1 && withDividers && (idx + 1) % dividerMod === 0 ? (
                      <Divider style={{ margin: 0 }} />
                    ) : null}
                  </div>
                ),
              )
            : noDataWarning}
        </Space>
      </Card>
    </Wrapper>
  );
}

RecordDetailCard.defaultProps = {
  additionalActions: [],
  title: undefined,
  defaultEditing: false,
  editable: false,
  cardProps: {},
  onEdit: undefined,
  withDividers: false,
  dividerMod: 1,
  hasData: true,
  noDataWarning: Fragment,
};

export default RecordDetailCard;
