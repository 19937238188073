import styled from 'styled-components';

import BryaHeader, { IBryaHeader } from '@components/BryaHeader';

const CenteringContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 50px 30px 40px 30px;
  text-align: center;
  width: 100%;
  box-sizing: border-box;
`;

const ContentContainer = styled.div`
  width: 100%;
  max-width: 400px;
`;

interface IAuthLayout {
  bryaHeaderProps?: IBryaHeader;
  children?: JSX.Element;
}

function AuthLayout({ bryaHeaderProps, children }: IAuthLayout) {
  return (
    <CenteringContainer>
      <ContentContainer>
        <BryaHeader {...bryaHeaderProps} />
        {children}
      </ContentContainer>
    </CenteringContainer>
  );
}

AuthLayout.defaultProps = {
  bryaHeaderProps: undefined,
  children: undefined,
};

export default AuthLayout;
