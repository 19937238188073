import {
  CalendarOutlined,
  CheckCircleOutlined,
  ClockCircleOutlined,
  CloseCircleOutlined,
  ExclamationCircleOutlined,
} from '@ant-design/icons';
import { ServiceRequestStatus as ServiceRequestStatusEnum } from '@graphql/generated';
import { Tag } from 'antd';
import { Trans } from 'react-i18next';

const renderServiceStatus = (status: ServiceRequestStatusEnum) => {
  switch (status) {
    case ServiceRequestStatusEnum.Pending:
    case ServiceRequestStatusEnum.AttentionNeeded:
    case ServiceRequestStatusEnum.PaymentWaitingForSupport:
      return (
        <Tag icon={<ExclamationCircleOutlined />} color="warning">
          <Trans i18nKey={`serviceRequestStatus.${status}`} />
        </Tag>
      );
    case ServiceRequestStatusEnum.PaymentFailed:
    case ServiceRequestStatusEnum.PaymentAuthorizationFailed:
      return (
        <Tag icon={<ExclamationCircleOutlined />} color="error">
          <Trans i18nKey={`serviceRequestStatus.${status}`} />
        </Tag>
      );
    case ServiceRequestStatusEnum.ConfirmationNeeded:
      return (
        <Tag icon={<ClockCircleOutlined />} color="default">
          <Trans i18nKey={`serviceRequestStatus.${status}`} />
        </Tag>
      );
    case ServiceRequestStatusEnum.Cancelled:
      return (
        <Tag icon={<CloseCircleOutlined />} color="error">
          <Trans i18nKey={`serviceRequestStatus.${status}`} />
        </Tag>
      );
    case ServiceRequestStatusEnum.Confirmed:
      return (
        <Tag icon={<CalendarOutlined />} color="processing">
          <Trans i18nKey={`serviceRequestStatus.${status}`} />
        </Tag>
      );
    case ServiceRequestStatusEnum.Completed:
      return (
        <Tag icon={<CheckCircleOutlined />} color="success">
          <Trans i18nKey={`serviceRequestStatus.${status}`} />
        </Tag>
      );
    case ServiceRequestStatusEnum.Inactive:
      return (
        <Tag icon={<CloseCircleOutlined />} color="default">
          <Trans i18nKey={`serviceRequestStatus.${status}`} />
        </Tag>
      );
    default:
      // eslint-disable-next-line no-console
      console.error('[ServiceRequestStatusTag][renderServiceStatus] Unknown status: ', status);
      return <span>{`Unknown status: ${status}`}</span>;
  }
};

export function ServiceRequestStatusTag({ status }: { status: ServiceRequestStatusEnum }) {
  return renderServiceStatus(status);
}
