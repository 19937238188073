import { useEffect } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Typography } from 'antd';

import useAuth, { useIsWebview } from '@hooks/useAuth';
import ROUTES from '@constants/routes';
import { useVerifyEmailMutation } from '@graphql/generated';
import AuthLayout from '@components/AuthLayout';
import Loading from '@components/Loading';

const Subtitle = styled.div`
  color: ${({ theme }) => theme.colors.foregroundTertiary};
`;

const NeedHelp = styled.div`
  font-weight: ${({ theme }) => theme.fontWeights.bold};
`;

function VerifyEmail() {
  const i18n = useTranslation();
  const navigate = useNavigate();
  const isWebview = useIsWebview();
  const { setAccessToken } = useAuth();
  const params = new URLSearchParams(window.location.search);
  const [verifyEmailMutation, { loading }] = useVerifyEmailMutation();

  useEffect(() => {
    const init = async () => {
      try {
        const { data } = await verifyEmailMutation({
          variables: { token: params.get('token') as string },
        });

        if (data?.verifyEmail?.accessToken) {
          if (!isWebview) {
            // We only automatically login the user again if it's a management user,
            // normal users need to click the button to go back to the Brya App
            setAccessToken(data.verifyEmail.accessToken);
          }
        }
      } catch (err) {
        navigate(isWebview ? ROUTES.LOG_IN : ROUTES.MANAGE_LOG_IN);
      }
    };
    init();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!isWebview || loading) {
    return <Loading />;
  }

  return (
    <AuthLayout
      bryaHeaderProps={{
        hideSSOLogin: true,
      }}
    >
      <div style={{ textAlign: 'left' }}>
        <Typography.Title>{i18n.t('verifyEmail.emailVerified')}</Typography.Title>
        <div style={{ fontSize: '24px', marginBottom: '1rem' }}>
          {i18n.t('verifyEmail.subtitle')}
        </div>
        <Subtitle>{i18n.t('verifyEmail.description')}</Subtitle>
        <NeedHelp style={{ marginTop: '1rem' }}>
          {i18n.t('signUpVerifyEmail.help.needHelp')}
        </NeedHelp>
        <div>{i18n.t('signUpVerifyEmail.help.contact')}</div>
        <a href="mailto:support@brya.com">support@brya.com</a>
      </div>
    </AuthLayout>
  );
}

export default VerifyEmail;
