import {
  CircleRequestCategoriesEnum,
  CreateCircleRequestInput,
  CreateServiceRequestInput,
  GraphqlCircleRequest,
  GraphqlServiceRequest,
  GraphqlCustomer,
  ServiceRequestCategoriesEnum,
  ServiceRequestStatus,
  RideAddressInput,
  RideType,
} from '@graphql/generated';
import { ExtendedServiceRequestAvailabilityInput } from '@constants/activities';
import { Trans } from 'react-i18next';

export type CombinedCreateActivityInput = (
  | Omit<CreateServiceRequestInput, 'category'>
  | Omit<CreateCircleRequestInput, 'category' | 'askTo'>
) & {
  category: CircleRequestCategoriesEnum | ServiceRequestCategoriesEnum;
  askTo: number[] | undefined;
};

export function getActivityActionNeeded(activity: GraphqlCircleRequest) {
  if ((activity as unknown as GraphqlServiceRequest).actionNeeded) {
    return (activity as unknown as GraphqlServiceRequest).actionNeeded;
  }

  if (activity?.status === ServiceRequestStatus.Inactive) {
    return <Trans i18nKey="manage.circleRequests.convertedToService" />;
  }

  if (activity.acceptedBy) {
    return <Trans i18nKey="manage.circleRequests.accepted" />;
  }

  if (
    [ServiceRequestStatus.PaymentFailed, ServiceRequestStatus.PaymentAuthorizationFailed].includes(
      activity.status,
    )
  ) {
    return <Trans i18nKey="serviceRequestStatus.paymentResolutionNeeded" />;
  }

  return <Trans i18nKey={`serviceRequestStatus.${activity.status}`} />;
}

export function convertCircleRequestToCreateServiceRequestInput(
  circleRequest: GraphqlCircleRequest,
  customer: GraphqlCustomer | null,
): CombinedCreateActivityInput {
  return {
    addressId:
      circleRequest?.category === CircleRequestCategoriesEnum.Ride
        ? null
        : circleRequest?.addressId ||
          customer?.addresses?.find((address) => address?.isDefault)?.id,
    availabilities: circleRequest?.isAvailabilityFlexible
      ? []
      : circleRequest?.availabilities?.map((availability) => ({
          date: availability?.date,
          from: availability?.from,
          until: availability?.until,
        })) || ([] as ExtendedServiceRequestAvailabilityInput[]),
    askTo: undefined,
    category: circleRequest?.category as unknown as ServiceRequestCategoriesEnum,
    customerId: circleRequest?.customerId,
    description: circleRequest?.description ?? '',
    frequency: circleRequest?.frequency ?? null,
    isAvailabilityFlexible: circleRequest?.isAvailabilityFlexible ?? true,
    otherCategory: circleRequest?.otherCategory,
    recurring: circleRequest?.recurring ?? false,
    ride:
      circleRequest?.category === CircleRequestCategoriesEnum.Ride
        ? {
            dropoffAddress: {
              addressLine1: circleRequest?.ride?.dropoffAddress?.addressLine1!,
              addressLine2: circleRequest?.ride?.dropoffAddress?.addressLine2,
              city: circleRequest?.ride?.dropoffAddress?.city!,
              country: circleRequest?.ride?.dropoffAddress?.country!,
              isDefault: circleRequest?.ride?.dropoffAddress?.isDefault,
              isFromAPI: circleRequest?.ride?.dropoffAddress?.isFromAPI,
              place: circleRequest?.ride?.dropoffAddress?.place,
              state: circleRequest?.ride?.dropoffAddress?.state!,
              zipCode: circleRequest?.ride?.dropoffAddress?.zipCode!,
            },
            pickupAddress: {
              addressLine1: circleRequest?.ride?.pickupAddress?.addressLine1!,
              addressLine2: circleRequest?.ride?.pickupAddress?.addressLine2,
              city: circleRequest?.ride?.pickupAddress?.city!,
              country: circleRequest?.ride?.pickupAddress?.country!,
              isDefault: circleRequest?.ride?.pickupAddress?.isDefault,
              isFromAPI: circleRequest?.ride?.pickupAddress?.isFromAPI,
              place: circleRequest?.ride?.pickupAddress?.place,
              state: circleRequest?.ride?.pickupAddress?.state!,
              zipCode: circleRequest?.ride?.pickupAddress?.zipCode!,
            },
            stops:
              circleRequest?.ride?.stops?.map((stop) => ({
                addressLine1: stop?.addressLine1,
                addressLine2: stop?.addressLine2,
                city: stop?.city,
                country: stop?.country,
                isDefault: stop?.isDefault,
                isFromAPI: stop?.isFromAPI,
                place: stop?.place,
                state: stop?.state,
                zipCode: stop?.zipCode,
              })) || ([] as RideAddressInput[]),
            type: circleRequest?.ride?.type || RideType.OneWay,
            waitTime: circleRequest?.ride?.waitTime,
          }
        : undefined,
    tentativeSchedule: circleRequest?.tentativeSchedule,
  };
}
