import { gql } from '@apollo/client';

export const GET_CIRCLE_REQUESTS_QUERY = gql`
  query GetCircleRequests(
    $filter: CircleRequestFilter!
    $sort: CircleRequestSortInput
    $pageSize: Int
    $page: Int
  ) {
    getCircleRequests(filter: $filter, sort: $sort, pageSize: $pageSize, page: $page) {
      meta {
        currentPage
        totalCount
      }
      circleRequests {
        id
        category
        status
        scheduledAt
        createdAt
        Customer {
          id
          fullName
        }
        RequestedBy {
          id
          fullName
        }
        askTo
        acceptedBy
      }
    }
  }
`;

export const GET_CIRCLE_REQUEST_QUERY = gql`
  query GetCircleRequest($getCircleRequestId: Float!) {
    getCircleRequest(id: $getCircleRequestId) {
      Address {
        addressLine1
        addressLine2
        city
        country
        createdAt
        customerId
        isDefault
        id
        isFromAPI
        place
        updatedAt
        state
        zipCode
      }
      Customer {
        addresses {
          addressLine1
          addressLine2
          city
          id
          isDefault
          isFromAPI
          state
          updatedAt
          zipCode
          customerId
          createdAt
          country
          place
        }
        users {
          user {
            role
            id
            createdAt
            updatedAt
            email
            fullName
            phoneNumber
          }
        }
        birthday
        createdAt
        createdBy
        firstName
        fullName
        id
        lastName
        updatedAt
      }
      RequestedBy {
        id
        firstName
        fullName
        email
        emailVerified
        createdAt
        language
        lastLogin
        lastName
        phoneNumber
        role
        updatedAt
        webPushSubscription
        talkJsId
      }
      CreatedBy {
        id
        fullName
      }
      acceptedAt
      acceptedBy
      addressId
      availabilities {
        date
        from
        until
      }
      category
      createdAt
      customerId
      description
      id
      isAvailabilityFlexible
      recurring
      frequency
      otherCategory
      requestType
      requestedBy
      ride {
        dropoffAddress {
          addressLine1
          addressLine2
          city
          country
          isDefault
          state
          zipCode
          place
        }
        pickupAddress {
          addressLine1
          addressLine2
          city
          country
          isDefault
          state
          zipCode
          place
        }
        stops {
          addressLine1
          addressLine2
          city
          country
          isDefault
          state
          zipCode
          place
        }
        type
        waitTime
      }
      scheduledAt
      status
      tentativeSchedule
      updatedAt
      AskTo {
        id
        fullName
        createdAt
        updatedAt
      }
      AcceptedBy {
        id
        fullName
      }
    }
  }
`;

export const CREATE_CIRCLE_REQUEST_MUTATION = gql`
  mutation CreateCircleRequest($data: CreateCircleRequestInput!) {
    createCircleRequest(data: $data) {
      id
    }
  }
`;

export const UPDATE_CIRCLE_REQUEST_MUTATION = gql`
  mutation UpdateCircleRequest($data: UpdateCircleRequestInput!, $updateCircleRequestId: Int!) {
    updateCircleRequest(data: $data, id: $updateCircleRequestId) {
      id
    }
  }
`;
