import styled from 'styled-components';
import { Select as SelectAntd } from 'antd';
import { commonInputStyles } from './styles';

export const Select = styled(SelectAntd)`
  .ant-select-selector {
    ${commonInputStyles}
    padding: 0 3px;
  }
`;
