import RecordDetailCard from '@components/Card/RecordDetailCard';
import GenericApolloError from '@components/GenericApolloError';
import ROUTES, { QUERY_PARAMS } from '@constants/routes';
import { useDeleteConversationMutation, useGetConversationQuery } from '@graphql/generated';
import { formatISOtoMMDDYYYY, formatISOtoMMDDYYYYandTime } from '@utils/date';
import { Row, Space, Typography, message } from 'antd';
import dayjs from 'dayjs';
import { useMemo, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { DeleteOutlined } from '@ant-design/icons';
import Button from '@components/Button';
import Modal from '@components/Modal';
import { GET_CONVERSATIONS } from '@graphql/chat';
import { Card } from '@components/Card';
import { useUser } from '@hooks/appContext/useUser';
import { ConversationParticipants } from './components/ConversationParticipants';
import { ChatHistory } from './components/ChatHistory';

const FIRST_COLUMN_WIDTH = '400px';

function ConversationDetail() {
  const navigate = useNavigate();
  const i18n = useTranslation();
  const { conversationId } = useParams<{ conversationId: string }>();
  const user = useUser();
  const [isDeleteConversationModalOpen, setIsDeleteConversationModalOpen] = useState(false);
  const {
    data: getConversationQuery,
    loading: loadingConversationQuery,
    error: conversationQueryError,
  } = useGetConversationQuery({
    variables: { conversationId: String(conversationId) },
  });
  const [deleteConversation, { loading: deletingConversation }] = useDeleteConversationMutation({
    refetchQueries: [GET_CONVERSATIONS],
  });

  const conversation = useMemo(() => getConversationQuery?.getConversation, [getConversationQuery]);
  const currentUserIsParticipantOfConversation = useMemo(
    () => conversation?.Participants?.some((participant) => participant.id === user?.id),
    [conversation, user],
  );

  if (conversationQueryError) {
    return <GenericApolloError error={conversationQueryError} />;
  }

  return (
    <Space direction="vertical" size="large" style={{ width: '100%' }}>
      <Row justify="space-between" align="middle">
        <Typography.Title level={2}>
          <Trans i18nKey="manage.conversationDetail.detailTitle" />
        </Typography.Title>
        <div>
          <Button danger type="primary" onClick={() => setIsDeleteConversationModalOpen(true)}>
            <Space size="small">
              <DeleteOutlined />
              <Trans i18nKey="manage.conversationDetail.actions.delete" />
            </Space>
          </Button>
        </div>
      </Row>
      <div style={{ display: 'flex', width: '100%', gap: '24px', flexWrap: 'wrap' }}>
        <Space direction="vertical" size="large">
          <RecordDetailCard
            title={
              <Typography.Title level={4} style={{ margin: 0 }}>
                {conversation?.subject}
              </Typography.Title>
            }
            fields={[
              {
                label: <Trans i18nKey="manage.conversationDetail.fields.chatName" />,
                name: 'chatName',
                value: conversation?.subject,
              },
              {
                label: <Trans i18nKey="manage.conversationDetail.fields.createdAt" />,
                name: 'createdAt',
                value: formatISOtoMMDDYYYY(dayjs(conversation?.createdAt).toISOString()),
              },
              {
                label: <Trans i18nKey="manage.conversationDetail.fields.lastActivity" />,
                name: 'lastActivity',
                value: formatISOtoMMDDYYYYandTime(
                  dayjs(conversation?.lastMessage?.createdAt).toISOString(),
                ),
              },
              {
                label: <Trans i18nKey="manage.conversationDetail.fields.createdBy" />,
                name: 'createdBy',
                value: conversation?.CreatedBy ? (
                  <div>
                    <Trans i18nKey={`roles.${conversation.CreatedBy.role}`} />
                    {' - '}
                    <Link
                      to={ROUTES.MANAGE_USER.replace(
                        ':userId',
                        String(conversation?.CreatedBy?.id),
                      )}
                    >
                      {conversation?.CreatedBy?.fullName}
                    </Link>
                  </div>
                ) : null,
              },
              {
                label: <Trans i18nKey="manage.conversationDetail.fields.chatType" />,
                name: 'chatType',
                value: conversation?.custom?.chatType ? (
                  <Trans i18nKey={`chatTypeOptions.${conversation?.custom?.chatType}`} />
                ) : null,
                valueFallback: <Trans i18nKey="fallbacks.notSet" />,
              },
              {
                label: <Trans i18nKey="manage.conversationDetail.fields.chatMembers" />,
                name: 'chatMembers',
                value: conversation?.custom?.role ? (
                  <Trans i18nKey={`roles.plural.${conversation?.custom?.role}`} />
                ) : null,
                valueFallback: <Trans i18nKey="fallbacks.notSet" />,
              },
              {
                label: <Trans i18nKey="manage.conversationDetail.fields.city" />,
                name: 'city',
                value: conversation?.custom?.city,
                valueFallback: <Trans i18nKey="fallbacks.notSet" />,
              },
              {
                label: <Trans i18nKey="manage.conversationDetail.fields.zipCode" />,
                name: 'zipCode',
                value: conversation?.custom?.zipCode,
                valueFallback: <Trans i18nKey="fallbacks.notSet" />,
              },
            ]}
            cardProps={{
              loading: loadingConversationQuery,
              style: { width: FIRST_COLUMN_WIDTH },
            }}
          />
          <Card
            title={
              <Typography.Title level={4} style={{ margin: 0 }}>
                <Trans i18nKey="manage.conversationDetail.chatHistory.title" />
              </Typography.Title>
            }
            loading={loadingConversationQuery}
            style={{ width: FIRST_COLUMN_WIDTH }}
          >
            <Row justify="center" style={{ width: '100%', whiteSpace: 'pre' }}>
              {currentUserIsParticipantOfConversation ? (
                <Trans
                  i18nKey="manage.conversationDetail.chatHistory.participantMessage"
                  components={{
                    linkComponent: (
                      <Link
                        to={`${ROUTES.MANAGE_CHAT}?${
                          QUERY_PARAMS.DEFAULT_CONVERSATION_SELECTED
                        }=${String(conversationId)}`}
                        style={{ fontSize: '14px' }}
                      />
                    ),
                  }}
                />
              ) : null}
            </Row>

            <ChatHistory
              conversationId={String(conversationId)}
              height={500}
              style={{ padding: '24px', border: 'none' }}
            />
          </Card>
        </Space>
        <div style={{ flexGrow: 1 }}>
          <ConversationParticipants
            conversationId={String(conversationId)}
            participants={conversation?.Participants || []}
            loading={loadingConversationQuery}
            chatType={conversation?.custom?.chatType}
            chatAllowedRole={conversation?.custom?.role}
            cardProps={{ style: { height: '100%' } }}
          />
        </div>
      </div>

      <Modal
        danger
        width={350}
        open={isDeleteConversationModalOpen}
        title={i18n.t('manage.conversationDetail.deleteConversationModal.title') as string}
        confirmText={i18n.t('manage.conversationDetail.deleteConversationModal.confirm') as string}
        cancelText={i18n.t('manage.conversationDetail.deleteConversationModal.cancel') as string}
        confirmButtonProps={{ loading: deletingConversation }}
        cancelButtonProps={{ loading: deletingConversation }}
        onCancel={() => setIsDeleteConversationModalOpen(false)}
        onConfirm={async () => {
          await deleteConversation({ variables: { conversationId: String(conversationId) } });
          setIsDeleteConversationModalOpen(false);
          message.info(
            <Trans
              i18nKey="manage.conversationDetail.deleteConversationModal.successMessage"
              values={{ chatName: conversation?.subject }}
            />,
          );
          navigate(ROUTES.MANAGE_COMMUNITY);
        }}
      >
        <Trans
          i18nKey="manage.conversationDetail.deleteConversationModal.description"
          values={{ chatName: conversation?.subject }}
          components={{ b: <b /> }}
        />
      </Modal>
    </Space>
  );
}

export default ConversationDetail;
