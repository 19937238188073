import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { Typography, message } from 'antd';
import { useLocation } from 'react-router-dom';
import Button from '@components/Button';
import AuthLayout from '@components/AuthLayout';
import { useResendVerifyEmailMutation } from '@graphql/generated';

const Subtitle = styled.div`
  color: ${({ theme }) => theme.colors.foregroundTertiary};
`;

const CTA = styled.div`
  margin-top: 40px;
`;

const NeedHelp = styled.div`
  font-weight: ${({ theme }) => theme.fontWeights.bold};
`;

function SignUpVerifyEmail() {
  const i18n = useTranslation();
  const [resendVerifyEmail, { loading }] = useResendVerifyEmailMutation();
  const { state } = useLocation();

  const resendEmail = useCallback(
    async () => {
      await resendVerifyEmail({ variables: { email: state.email } });
      message.success(i18n.t('signUpVerifyEmail.success'));
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [resendVerifyEmail, message, i18n],
  );

  return (
    <AuthLayout
      bryaHeaderProps={{
        showBackToLoginButton: true,
        hideSSOLogin: true,
      }}
    >
      <div style={{ textAlign: 'left' }}>
        <Typography.Title>{i18n.t('signUpVerifyEmail.title')}</Typography.Title>
        <Subtitle>{i18n.t('signUpVerifyEmail.subtitle')}</Subtitle>
        <CTA>{i18n.t('signUpVerifyEmail.cta.question')}</CTA>
        <Button loading={loading} onClick={resendEmail} style={{ margin: '24px 0px' }}>
          {i18n.t('signUpVerifyEmail.cta.button')}
        </Button>
        <NeedHelp>{i18n.t('signUpVerifyEmail.help.needHelp')}</NeedHelp>
        <div>{i18n.t('signUpVerifyEmail.help.contact')}</div>
        <a href="mailto:support@brya.com">support@brya.com</a>
      </div>
    </AuthLayout>
  );
}

export default SignUpVerifyEmail;
