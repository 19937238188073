import Modal from '@components/Modal';
import { ManagementUserForm, ManagementUserFormValues } from '@components/Users/ManagementUserForm';
import { useAdminCreateUserMutation } from '@graphql/generated';
import { GET_USERS_QUERY } from '@graphql/users';
import { Typography, message } from 'antd';
import { Trans, useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

function CreateManagementUser() {
  const i18n = useTranslation();
  const [adminCreateUser, { loading }] = useAdminCreateUserMutation({
    refetchQueries: [GET_USERS_QUERY],
  });
  const navigate = useNavigate();

  const onCreateUser = async (values: ManagementUserFormValues) => {
    await adminCreateUser({
      variables: { adminCreateUserInput: values },
    });
    message.success(i18n.t('forms.createUser.successMessage' as string));
    navigate(-1);
  };

  return (
    <Modal
      width={400}
      closable
      onCancel={() => (loading ? null : navigate(-1))}
      open
      title={
        <Typography.Title level={2}>
          <Trans i18nKey="forms.createUser.title" />
        </Typography.Title>
      }
    >
      <ManagementUserForm
        loading={loading}
        onSubmit={onCreateUser}
        submitLabel={<Trans i18nKey="forms.createUser.submitLabel" />}
      />
    </Modal>
  );
}

export default CreateManagementUser;
