import { useState } from 'react';
import { Trans } from 'react-i18next';
import { DEFAULT_SEARCH_DEBOUNCE_TIME } from '@constants/input';

import { Row, Space, Switch, Typography } from 'antd';
import Button from '@components/Button';
import CompaniesTable from '@components/Pros/CompaniesTable';
import AgentsTable from '@components/Pros/AgentsTable';
import CreateCompanyModal from '@components/Pros/CreateCompanyModal';

enum ProModeOptions {
  Company = 'company',
  Agent = 'agent',
}

function Pros() {
  const [mode, setMode] = useState<ProModeOptions>(ProModeOptions.Company);
  const [showCreateCompanyModal, setShowCreateCompanyModal] = useState<boolean>(false);

  return (
    <>
      <Space direction="vertical" size="large" style={{ width: '100%' }}>
        <Typography.Title
          level={2}
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
          }}
        >
          <Trans i18nKey="manage.companies.title" />
          <Space>
            <Button onClick={() => setShowCreateCompanyModal(true)}>
              <Trans i18nKey="manage.companies.new" />
            </Button>
          </Space>
        </Typography.Title>
      </Space>
      <Row
        style={{
          marginBottom: '20px',
        }}
      >
        <Space>
          <Trans i18nKey="manage.companies.viewBy" />
          <Switch
            checkedChildren={<Trans i18nKey="manage.companies.companyMode" />}
            unCheckedChildren={<Trans i18nKey="manage.companies.agentMode" />}
            checked={mode === ProModeOptions.Company}
            onChange={() =>
              setMode(
                mode === ProModeOptions.Company ? ProModeOptions.Agent : ProModeOptions.Company,
              )
            }
          />
        </Space>
      </Row>
      {mode === ProModeOptions.Company ? (
        <CompaniesTable />
      ) : (
        <AgentsTable
          showSearch
          cardWrapper={false}
          columns={[
            'fullName',
            'rating',
            'internalRating',
            'phoneNumber',
            'email',
            'companyName',
            'address',
            'serviceTypes',
          ]}
        />
      )}
      {showCreateCompanyModal && (
        <CreateCompanyModal onCancel={() => setShowCreateCompanyModal(false)} />
      )}
    </>
  );
}

Pros.defaultProps = {
  searchDebounceTime: DEFAULT_SEARCH_DEBOUNCE_TIME,
};

export default Pros;
