import { gql } from '@apollo/client';

export const GET_COACH_VISITS_QUERY = gql`
  query GetCoachVisits(
    $filter: CoachVisitFilter!
    $sort: CoachVisitSortInput
    $pageSize: Int
    $page: Int
  ) {
    getCoachVisits(filter: $filter, sort: $sort, pageSize: $pageSize, page: $page) {
      coachVisits {
        id
        Customer {
          fullName
          id
        }
        Coach {
          id
          fullName
        }
        scheduledAt
        status
      }
      meta {
        totalCount
        previousPage
        pageCount
        nextPage
        isLastPage
        isFirstPage
        currentPage
      }
    }
  }
`;

export const GET_COACH_VISIT_QUERY = gql`
  query GetCoachVisit($coachVisitId: Int!) {
    getCoachVisit(coachVisitId: $coachVisitId) {
      id
      lastStatusUpdate
      recurring
      requestType
      requestedBy
      scheduledAt
      status
      updatedAt
      frequency
      description
      createdAt
      cancellationReason
      addressId
      RequestedBy {
        id
        fullName
      }
      Customer {
        id
        fullName
      }
      Coach {
        id
        fullName
      }
      Address {
        zipCode
        updatedAt
        state
        place
        isFromAPI
        isDefault
        id
        customerId
        createdAt
        country
        city
        addressLine2
        addressLine1
      }
      InternalNotes {
        id
        note
        createdAt
        updatedAt
        userId
        Users {
          id
          fullName
          createdAt
          updatedAt
        }
      }
      VisitSummaries {
        id
        body
        createdAt
        CreatedBy {
          id
          fullName
        }
        SentTo {
          id
          fullName
        }
      }
    }
  }
`;

export const CREATE_COACH_VISIT_MUTATION = gql`
  mutation CreateCoachVisit($data: CreateCoachVisitInput!) {
    createCoachVisit(data: $data) {
      id
    }
  }
`;

export const UPDATE_COACH_VISIT_MUTATION = gql`
  mutation UpdateCoachVisit($data: UpdateCoachVisitInput!, $coachVisitId: Int!) {
    updateCoachVisit(data: $data, coachVisitId: $coachVisitId) {
      id
    }
  }
`;

export const CREATE_VISIT_SUMMARY_MUTATION = gql`
  mutation CreateVisitSummary($input: CreateVisitSummaryInput!) {
    createVisitSummary(input: $input) {
      id
      body
      coachVisitId
    }
  }
`;
