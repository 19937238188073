import { useEffect, useState } from 'react';
import { Trans } from 'react-i18next';

import {
  Scalars,
  ServiceRequestAvailabilityInput,
  ServiceRequestTentativeSchedule,
} from '@graphql/generated';
import { ExtendedServiceRequestAvailabilityInput } from '@constants/activities';

import { DatePicker, TimePicker, Typography } from 'antd';
import { FlexRow, CenterRow } from '@components/styled';
import Button from '@components/Button';
import Modal from '@components/Modal';

import dayjs, { Dayjs } from 'dayjs';
import { DATE_FORMAT, TIME_FORMAT } from '@constants/input';
import { useDateWithTimezone } from '@hooks/useDateWithTimezone';

type AvailabilityModalProps = {
  open: boolean;
  defaultValue: ServiceRequestAvailabilityInput[];
  onClose: () => void;
  onChange: (payload: any) => void;

  // TODO: Remove timezone default prop when
  // consuming timezon from backend
  // and mark this as required
  timezone?: string;
  tentativeSchedule: string;
};

const range = (start: number, end: number) => {
  const result = [];
  for (let i = start; i < end; i += 1) {
    result.push(i);
  }
  return result;
};

const getAvailabilitiesDefaultValue = (
  defaultValue: ServiceRequestAvailabilityInput[],
): ExtendedServiceRequestAvailabilityInput[] => {
  if (defaultValue?.length) {
    return defaultValue?.map((availability, index) => ({ ...availability, key: index }));
  }
  return [
    {
      key: 1,
      date: dayjs().add(1, 'day').format(DATE_FORMAT),
      from: dayjs().minute(0).add(1, 'hour').format(TIME_FORMAT),
      until: dayjs().minute(0).add(2, 'hour').format(TIME_FORMAT),
    },
  ] as unknown as ExtendedServiceRequestAvailabilityInput[];
};
function AvailabilityModal({
  open,
  defaultValue,
  onClose,
  onChange,
  timezone,
  tentativeSchedule,
}: AvailabilityModalProps) {
  const { transformAvailabilityToTimezone } = useDateWithTimezone();

  const [availabilities, setAvailabilities] = useState<ExtendedServiceRequestAvailabilityInput[]>(
    getAvailabilitiesDefaultValue(defaultValue),
  );

  let disabledDate = (current: Dayjs) => {
    return dayjs().add(-1, 'days') >= current;
  };

  if (tentativeSchedule === ServiceRequestTentativeSchedule.WithinDays) {
    disabledDate = (current: Dayjs) => {
      return dayjs().add(-1, 'days') >= current || dayjs().add(3, 'days') <= current;
    };
  } else if (tentativeSchedule === ServiceRequestTentativeSchedule.WithinWeeks) {
    disabledDate = (current: Dayjs) => {
      return dayjs().add(-1, 'days') >= current || dayjs().add(7, 'days') <= current;
    };
  }

  useEffect(() => {
    setAvailabilities(getAvailabilitiesDefaultValue(defaultValue));
  }, [defaultValue]);

  return (
    <Modal
      width={750}
      closable
      onCancel={onClose}
      open={open}
      onConfirm={() => {
        const filteredAvailabilities = availabilities.filter((a) => Object.keys(a)?.length);
        setAvailabilities(
          filteredAvailabilities.length === 0
            ? [{} as ExtendedServiceRequestAvailabilityInput]
            : filteredAvailabilities,
        );
        onChange(
          filteredAvailabilities.map(({ date, from, until }) => {
            return transformAvailabilityToTimezone({ date, from, until });
          }),
        );
        onClose();
      }}
      title={
        <Typography.Title level={2}>
          <Trans i18nKey="manage.serviceRequests.create.configureAvailability" />
        </Typography.Title>
      }
    >
      <Typography.Paragraph>
        <Trans
          i18nKey="manage.serviceRequests.create.timezoneDisclaimer"
          values={{ timezone, offset: dayjs().tz(timezone).format('Z') }}
        />
      </Typography.Paragraph>
      {availabilities.map((availability: any, index: number) => (
        // Key is required for deletion; otherwise, even though the underlying data
        // is still correct, the UI state reflects having removed the last element
        <FlexRow style={{ marginBottom: 10 }} key={availability.key}>
          <DatePicker
            value={dayjs(availability.date)}
            onChange={(__: any, date) => {
              const newAvailability = {
                ...availability,
                date: date || dayjs().add(1, 'day').format(DATE_FORMAT),
              };
              setAvailabilities([
                ...availabilities.slice(0, index),
                newAvailability,
                ...availabilities.slice(index + 1),
              ]);
            }}
            disabledDate={disabledDate}
          />
          <TimePicker.RangePicker
            use12Hours
            changeOnBlur
            value={[
              dayjs(`${availability.date} ${availability.from}`),
              dayjs(`${availability.date} ${availability.until}`),
            ]}
            onChange={(times) => {
              const newAvailability = {
                ...availability,
                from:
                  times?.[0]?.format(TIME_FORMAT) ||
                  dayjs().minute(0).add(1, 'hour').format(TIME_FORMAT),
                until:
                  times?.[1]?.format(TIME_FORMAT) ||
                  dayjs().minute(0).add(2, 'hour').format(TIME_FORMAT),
              };
              setAvailabilities([
                ...availabilities.slice(0, index),
                newAvailability,
                ...availabilities.slice(index + 1),
              ]);
            }}
            disabledTime={(current) => {
              return {
                disabledHours: () => {
                  if (dayjs(availability.date).isSame(dayjs(), 'date')) {
                    return range(
                      current?.startOf('day').hour() as number,
                      current?.hour() as number,
                    );
                  }

                  return [];
                },
                disabledMinutes: (hour) => {
                  if (dayjs(availability.date).isSame(dayjs(), 'date') && hour === dayjs().hour()) {
                    return range(
                      current?.startOf('day').minute() as number,
                      current?.minute() as number,
                    );
                  }

                  return [];
                },
              };
            }}
          />
          <button
            type="button"
            onClick={() =>
              setAvailabilities(
                availabilities.slice(0, index).concat(availabilities.slice(index + 1)),
              )
            }
          >
            🗑️
          </button>
        </FlexRow>
      ))}

      <CenterRow>
        <Button
          type="ghost"
          style={{ marginTop: 20, maxWidth: 400 }}
          onClick={() => {
            // Find the highest key among remaining availabilities, and increment
            const key = Math.max(...availabilities.map((a) => a.key), 0) + 1;
            setAvailabilities([
              ...availabilities,
              {
                key,
                date: dayjs()
                  .add(1, 'day')
                  .format(DATE_FORMAT) as unknown as Scalars['PlainDate']['input'],
                from: dayjs()
                  .minute(0)
                  .add(1, 'hour')
                  .format(TIME_FORMAT) as unknown as Scalars['ZonedPlainTime']['input'],
                until: dayjs()
                  .minute(0)
                  .add(2, 'hour')
                  .format(TIME_FORMAT) as unknown as Scalars['ZonedPlainTime']['input'],
              } as ExtendedServiceRequestAvailabilityInput,
            ]);
          }}
        >
          <Trans i18nKey="manage.serviceRequests.create.addDate" />
        </Button>
      </CenterRow>
    </Modal>
  );
}

AvailabilityModal.defaultProps = {
  timezone: 'America/New_York',
};

export default AvailabilityModal;
