import GenericApolloError from '@components/GenericApolloError';
import Loading from '@components/Loading';
import { useContinueConnectAccountOnboardingQuery } from '@graphql/generated';

function StripeOnboarding() {
  const { data, error } = useContinueConnectAccountOnboardingQuery();

  if (data) {
    window.location.replace(data.continueConnectAccountOnboarding);
  }

  if (error) {
    return <GenericApolloError error={error} />;
  }

  return <Loading />;
}

export default StripeOnboarding;
