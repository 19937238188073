import { ApolloClient, InMemoryCache, ApolloProvider, ApolloLink, HttpLink } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { RetryLink } from '@apollo/client/link/retry';
import { onError } from '@apollo/client/link/error';
import { COOKIES_KEYS } from '@constants/cookies';
import { useCookies } from 'react-cookie';
import { withScalars } from 'apollo-link-scalars';
import introspectionResult from '@graphql/graphql.schema.json';
import { buildClientSchema, type IntrospectionQuery } from 'graphql';
import { message } from 'antd';

const schema = buildClientSchema(introspectionResult as unknown as IntrospectionQuery);

const DateMap = {
  serialize: (parsed: unknown): string | null => {
    if (parsed instanceof Date) return parsed.toISOString();

    throw new Error('Must be date');
  },
  parseValue: (raw: unknown): Date | null => {
    if (!raw) return null;
    if (typeof raw === 'string') {
      return new Date(raw);
    }

    throw new Error('Invalid value to parse');
  },
};

const typesMap = {
  Date: DateMap,
  DateTime: DateMap,
};

const createApolloClient = (apiAuthToken?: string) => {
  const links: ApolloLink[] = [];
  const authLink = setContext((_, { headers }): Record<string, unknown> => {
    let augmentedHeaders: Record<string, unknown> = {
      ...(headers as Record<string, unknown>),
    };

    if (apiAuthToken) {
      augmentedHeaders = {
        ...augmentedHeaders,
        authorization: apiAuthToken,
      };
    }

    return {
      headers: {
        ...augmentedHeaders,
      },
    };
  });

  links.push(authLink);

  links.push(withScalars({ schema, typesMap }));

  const errorLink = onError(({ networkError }) => {
    if ((networkError as any)?.result?.errors) {
      (networkError as any)?.result?.errors?.forEach((error: any) => {
        // TODO: do report, Sentry maybe?
        // eslint-disable-next-line no-console
        console.error('Mutation error:', error);

        if (error?.message) {
          message.error({ content: error.message });
        }
      });
    }
  });
  links.push(errorLink);

  const retryLink = new RetryLink({
    attempts: {
      max: 3,
      retryIf: (error) => error.statusCode === 500,
    },
  });
  links.push(retryLink);

  const httpLink = new HttpLink({
    uri: `${process.env.REACT_APP_API_URI}/graphql`,
  });

  links.push(httpLink);

  return new ApolloClient({
    cache: new InMemoryCache(),
    link: ApolloLink.from(links),
    defaultOptions: {
      watchQuery: {
        // NOTE: this fetch policy is needed because on create user mutation,
        // the cache is not updated for all set of variables cached neither invalidated
        // the refetchQueries option in the mutation only refetches the query with the current set of variables.
        // It would be very useful if this query could be totally invalidated after that mutation
        fetchPolicy: 'cache-and-network',
        // pollInterval: 60 * 1000,
      },
    },
  });
};

interface IApollo {
  children?: JSX.Element;
}

function Apollo({ children }: IApollo) {
  const [cookies] = useCookies([COOKIES_KEYS.ACCESS_TOKEN]);

  return (
    <ApolloProvider client={createApolloClient(cookies?.accessToken)}>{children}</ApolloProvider>
  );
}

Apollo.defaultProps = {
  children: undefined,
};

export default Apollo;
