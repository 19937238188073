import { useEffect, useState, useRef, useMemo } from 'react';
import { useSearchParams, useNavigate } from 'react-router-dom';
import { Trans, useTranslation } from 'react-i18next';
import { useCookies } from 'react-cookie';
import styled from 'styled-components';
import Talk from 'talkjs';
import axios from 'axios';

import {
  CustomerSortableFields,
  UserSortableFields,
  SortOrder,
  GlobalRole,
  GraphqlUser,
  useGetUsersQuery,
  useGetCustomersLazyQuery,
  useGetUserSignatureLazyQuery,
  useJoinUserToConversationMutation,
  useGetUserByChatProviderIdLazyQuery,
} from '@graphql/generated';
import { GET_USER_SIGNATURE_QUERY } from '@graphql/chat';

import { useUser } from '@hooks/appContext/useUser';
import { COOKIES_KEYS } from '@constants/cookies';
import { ROUTES, QUERY_PARAMS } from '@constants/routes';

import { ApolloError } from '@apollo/client';
import GenericApolloError from '@components/GenericApolloError';

import { message as messageANTD, Select, Space, Switch, Typography } from 'antd';
import Input from '@components/Input';
import Modal from '@components/Modal';
import Button from '@components/Button';
import Loading from '@components/Loading';
import { FlexRow } from '@components/styled';
import Masonry, { ResponsiveMasonry } from 'react-responsive-masonry';
import { AddParticipantToConversationForm } from './components/AddParticipantToConversationForm';
import CreateChatModal from './components/CreateChatModal';

const ChatContainer = styled.div`
  height: calc(100% - 119px);
  position: relative;
`;

const ChatElement = styled.div`
  height: 100%;
`;

function ManageChat() {
  const user = useUser();
  const navigate = useNavigate();
  const chatboxEl = useRef<HTMLDivElement>(null);
  const [inboxHandle, setInboxHandle] = useState<any>(null);
  const [talkLoaded, setTalkLoaded] = useState(false);
  const [selectedUser, setSelectedUser] = useState(user?.id);
  const [searchCustomerText, setSearchCustomerText] = useState<string | undefined>(undefined);
  const [filterCoachCustomers, setFilterCoachCustomers] = useState(false);
  const [showCreateChatModal, setShowCreateChatModal] = useState(false);
  const [showAddParticipantModal, setShowAddParticipantModal] = useState<{
    conversationId: string;
    participants: Partial<GraphqlUser>[];
  } | null>(null);
  const i18nUnmemoized = useTranslation();
  const [params] = useSearchParams();
  const selectedConversation = params.get(QUERY_PARAMS.DEFAULT_CONVERSATION_SELECTED);

  const [getUserSignatureQuery, { data: getUserSignatureData, error: getUserSignatureError }] =
    useGetUserSignatureLazyQuery();
  const [getCustomersQuery, { data: getCustomersData, error: getCustomersError }] =
    useGetCustomersLazyQuery();
  const { data: getManagementUsersQuery } = useGetUsersQuery({
    variables: {
      filter: { roles: [GlobalRole.Admin, GlobalRole.Support, GlobalRole.Coach] },
      page: 1,
      pageSize: 100,
      sort: { field: UserSortableFields.FullName, direction: SortOrder.Asc },
    },
  });
  const [getUserByTalkjsIdQuery] = useGetUserByChatProviderIdLazyQuery();

  const [joinUserToConversation, { loading: joiningUser }] = useJoinUserToConversationMutation({
    refetchQueries: [GET_USER_SIGNATURE_QUERY],
  });

  const [cookies] = useCookies([COOKIES_KEYS.ACCESS_TOKEN]);
  const accessToken = cookies[COOKIES_KEYS.ACCESS_TOKEN];
  const signatureData = useMemo(
    () => getUserSignatureData?.getUserSignature,
    [getUserSignatureData],
  );
  const coachCustomerIds = useMemo(
    () => getCustomersData?.getCustomers?.map((customer) => String(customer?.id)) || [],
    [getCustomersData],
  );
  const managementUsersData = useMemo(
    () => getManagementUsersQuery?.getUsers?.users || [],
    [getManagementUsersQuery],
  );
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const i18n = useMemo(() => i18nUnmemoized, []);

  useEffect(() => {
    Talk.ready.then(() => setTalkLoaded(true));
  }, []);

  useEffect(() => {
    if (user?.id) {
      setSelectedUser(user?.id);
    }
  }, [user]);

  useEffect(() => {
    if (typeof selectedUser === 'number') {
      getUserSignatureQuery({ variables: { userId: selectedUser } });
      getCustomersQuery({
        variables: {
          filters: {
            userId: selectedUser,
            sortField: CustomerSortableFields.FullName,
            sortValue: SortOrder.Desc,
          },
        },
      });
      setSearchCustomerText(undefined);
      setFilterCoachCustomers(false);
    }
  }, [selectedUser, getCustomersQuery, getUserSignatureQuery]);

  useEffect(() => {
    inboxHandle?.setFeedFilter({
      subject: searchCustomerText ? ['==', searchCustomerText] : undefined,
      custom:
        filterCoachCustomers && coachCustomerIds?.length
          ? {
              customerId: ['oneOf', coachCustomerIds],
            }
          : undefined,
    });
  }, [searchCustomerText, filterCoachCustomers, coachCustomerIds, inboxHandle]);

  useEffect(() => {
    let displayUser;
    if (selectedUser && managementUsersData) {
      displayUser = managementUsersData?.find(
        (managementUser) => managementUser?.id === selectedUser,
      );
    }
    if (talkLoaded && displayUser && signatureData && user && accessToken && i18n) {
      const currentUser = new Talk.User({
        id: signatureData.talkJsId,
        name: `${displayUser?.fullName}`,
        email: displayUser?.email,
        role: displayUser?.role,
      });

      const session = new Talk.Session({
        appId: signatureData.talkJsAppId,
        me: currentUser,
        signature: signatureData.signature,
      });

      const inbox = session.createInbox();

      inbox.onCustomMessageAction('toggle_favorite', async ({ message }) => {
        const isFavorite = message.custom && message.custom.saved === 'true';

        const newStatus = isFavorite ? 'false' : 'true';

        await axios.post(
          `${process.env.REACT_APP_API_URI}/chat/update-favorite-message`,
          {
            conversationId: message.conversation.id,
            messageId: message.id,
            saved: newStatus,
          },
          {
            headers: {
              Authorization: accessToken,
            },
          },
        );
      });

      inbox.onCustomMessageAction('report_message', async ({ message }) => {
        const isReported = message.custom && message.custom.reported === 'true';

        const newStatus = isReported ? 'false' : 'true';

        await axios.post(
          `${process.env.REACT_APP_API_URI}/chat/report-message`,
          {
            conversationId: message.conversation.id,
            messageId: message.id,
            message: message.body,
            userId: message.senderId,
            reported: newStatus,
          },
          {
            headers: {
              Authorization: accessToken,
            },
          },
        );
      });

      inbox.onCustomMessageAction('pin', async ({ message }) => {
        const pin = message?.conversation?.custom?.pinnedMessageId !== message?.id;

        await axios.post(
          `${process.env.REACT_APP_API_URI}/chat/pin-unpin-message`,
          {
            conversationId: message.conversation.id,
            messageId: message.id,
            body: message.body,
            pin,
          },
          {
            headers: {
              Authorization: accessToken,
            },
          },
        );
      });

      inbox.onCustomConversationAction('goToProfile', async (event) => {
        const { data } = await getUserByTalkjsIdQuery({
          variables: {
            chatProviderId: (event as any)?.params?.userId,
          },
        });
        if (data?.getUserByChatProviderId?.id) {
          navigate(
            ROUTES.MANAGE_USER.replace(':userId', String(data?.getUserByChatProviderId?.id)),
          );
        }
      });

      inbox.onCustomConversationAction('add_participant', async (event) => {
        setShowAddParticipantModal({
          conversationId: event?.conversation?.id,
          participants: (event?.conversation?.custom?.userIds?.split(', ') || []).map(
            (participant) => ({ id: parseInt(participant as string, 10) }),
          ),
        });
      });

      inbox.onCustomConversationAction('favorite_messages', async () => {
        inbox.setMessageFilter({ custom: { saved: ['==', 'true'] } });
      });

      inbox.onCustomConversationAction('all_messages', async () => {
        inbox.setMessageFilter({});
      });

      if (selectedConversation) {
        inbox.select(selectedConversation);
      }
      inbox.mount(chatboxEl.current);
      setInboxHandle(inbox);
      return () => session.destroy();
    }

    return () => ({});
  }, [
    talkLoaded,
    selectedUser,
    managementUsersData,
    signatureData,
    user,
    accessToken,
    i18n,
    selectedConversation,
    getUserByTalkjsIdQuery,
    navigate,
  ]);

  if (!user) {
    return <Loading />;
  }

  if (getUserSignatureError || getCustomersError) {
    return (
      <GenericApolloError error={(getUserSignatureError || getCustomersError) as ApolloError} />
    );
  }

  return (
    <ChatContainer>
      <Typography.Title
        level={2}
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
        }}
      >
        <Trans i18nKey="manage.chat.title" />
        <Space>
          <Button onClick={() => setShowCreateChatModal(true)}>
            <Trans i18nKey="manage.chat.new" />
          </Button>
        </Space>
      </Typography.Title>
      <ResponsiveMasonry columnsCountBreakPoints={{ 350: 2, 700: 3 }} style={{ marginBottom: 15 }}>
        <Masonry gutter="20px">
          <FlexRow style={{ justifyContent: 'flex-start' }}>
            <div style={{ margin: 7 }}>
              <Trans i18nKey="manage.chat.chatAs" />
            </div>
            <Select
              style={{ minWidth: 200 }}
              defaultValue={user?.id}
              onChange={(value) => {
                setSelectedUser(value);
              }}
              options={managementUsersData.map((managementUser) => ({
                label: managementUser?.fullName,
                value: managementUser?.id,
              }))}
            />
          </FlexRow>
          <div>
            <FlexRow style={{ justifyContent: 'center' }}>
              <div style={{ margin: 7 }}>
                <Trans i18nKey="manage.chat.search" />
              </div>
              <Input
                value={searchCustomerText}
                onChange={(e) => setSearchCustomerText(e?.target?.value)}
              />
            </FlexRow>
            <FlexRow style={{ justifyContent: 'center', fontStyle: 'italic' }}>
              <Trans i18nKey="manage.chat.searchWarning" />
            </FlexRow>
          </div>
          <FlexRow style={{ justifyContent: 'flex-end' }}>
            {!!coachCustomerIds?.length && (
              <>
                <div style={{ margin: 7 }}>
                  <Trans i18nKey="manage.chat.coachCustomers" />
                </div>
                <Switch
                  style={{ margin: 4 }}
                  onChange={setFilterCoachCustomers}
                  checked={filterCoachCustomers}
                />
              </>
            )}
          </FlexRow>
        </Masonry>
      </ResponsiveMasonry>
      <ChatElement ref={chatboxEl} />
      {showCreateChatModal && <CreateChatModal onCancel={() => setShowCreateChatModal(false)} />}
      {showAddParticipantModal && (
        <Modal
          width={400}
          open={!!showAddParticipantModal}
          title={
            <Trans i18nKey="manage.conversationDetail.participants.addParticipantModal.title" />
          }
          cancelText={
            i18n.t('manage.conversationDetail.participants.addParticipantModal.cancel') as string
          }
          onCancel={() => setShowAddParticipantModal(null)}
        >
          <AddParticipantToConversationForm
            allowedRoles={[]}
            loading={joiningUser}
            currentParticipants={showAddParticipantModal.participants}
            onSubmit={async (values) => {
              try {
                await joinUserToConversation({
                  variables: {
                    conversationId: showAddParticipantModal.conversationId,
                    userId: values.chatParticipant.id,
                  },
                });
                setShowAddParticipantModal(null);
                messageANTD.success(
                  i18n.t('forms.addParticipantToConversation.successMessage' as string, {
                    fullName: values.chatParticipant.fullName,
                  }),
                );
                return await Promise.resolve();
              } catch (err) {
                return Promise.reject(err);
              }
            }}
            submitLabel={
              i18n.t('manage.conversationDetail.participants.addParticipantModal.confirm') as string
            }
          />
        </Modal>
      )}
    </ChatContainer>
  );
}

export default ManageChat;
