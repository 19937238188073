import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { useLoginMutation } from '@graphql/generated';
import useAuth, { useIsWebview } from '@hooks/useAuth';
import ROUTES from '@constants/routes';

import { message } from 'antd';
import { Form } from '@components/Form';
import Button from '@components/Button';
import Divider from '@components/Divider';
import AuthLayout from '@components/AuthLayout';
import { InputEmail, InputPassword } from '@components/Input';
import { FEATURE_FLAGS } from '@constants/featureFlags';
import { useIsFeatureEnabled } from '@hooks/useIsFeatureEnabled';

export interface LoginFormValues {
  email: string;
  password: string;
}

function Login() {
  const i18n = useTranslation();
  const isWebview = useIsWebview();
  const [form] = Form.useForm();
  const { setAccessToken } = useAuth();
  const [loginMutation, { loading, error }] = useLoginMutation();

  const params = new URLSearchParams(window.location.search);
  const authId = params.get('authId');

  useEffect(() => {
    if (authId) {
      localStorage.setItem('authId', authId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const submit = async (formValues: { email: string; password: string }) => {
    try {
      const { data } = await loginMutation({
        variables: { ...formValues, inviteToken: params.get('inviteToken') },
      });
      if (data?.login?.accessToken) {
        message.success(i18n.t('logIn.success'));
        setAccessToken(data.login.accessToken);
      }
    } catch (e) {
      // noop
    }
  };

  const hasFacebookSSO = useIsFeatureEnabled(FEATURE_FLAGS.FACEBOOK_SSO);
  const hasGoogleSSO = useIsFeatureEnabled(FEATURE_FLAGS.GOOGLE_SSO);

  const hasSSO = hasFacebookSSO || hasGoogleSSO;

  return (
    <AuthLayout
      bryaHeaderProps={{
        googleSSOTitle: 'logIn.cta.googleLogIn',
        facebookSSOTitle: 'logIn.cta.facebookLogIn',
        hideSSOLogin: !isWebview,
      }}
    >
      <Form form={form} onFinish={submit}>
        {isWebview && hasSSO ? <Divider>{i18n.t('logIn.cta.divider')}</Divider> : null}
        <InputEmail placeholder={i18n.t('forms.input.email.placeholder') as string} />
        <InputPassword placeholder={i18n.t('forms.input.password.placeholder') as string} />
        <Form.Item
          validateStatus={error ? 'error' : 'success'}
          help={error ? i18n.t('logIn.errors.invalidCredentials') : ''}
        />
        <Form.Item>
          <Button htmlType="submit" loading={loading}>
            {i18n.t('logIn.cta.submit')}
          </Button>
        </Form.Item>
        {isWebview ? (
          <>
            <Link to={ROUTES.FORGOT_PASSWORD}>{i18n.t('logIn.cta.forgotPassword')}</Link>
            <Divider>{i18n.t('logIn.cta.divider2')}</Divider>
            <Link to={`${ROUTES.SIGN_UP}${authId ? `?authId=${authId}` : ''}`}>
              <Button type="link">{i18n.t('logIn.cta.signUp')}</Button>
            </Link>
          </>
        ) : (
          <Link to={ROUTES.MANAGE_FORGOT_PASSWORD}>{i18n.t('logIn.cta.forgotPassword')}</Link>
        )}
      </Form>
    </AuthLayout>
  );
}

export default Login;
