import { DeleteOutlined } from '@ant-design/icons';
import { Card } from '@components/Card';
import EntityRating from '@components/Rate/EntityRating';
import ROUTES from '@constants/routes';
import { GraphqlCoachReview, GraphqlServiceRequestReview } from '@graphql/generated';
import { Button, Space } from 'antd';
import dayjs from 'dayjs';
import { Trans } from 'react-i18next';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

const Flex = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
`;

const ReviewComment = styled.div`
  position: relative;
  font-style: italic;
  height: 80px;
  overflow-y: scroll;

  // Hide scroll bar
  ::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
`;

type ReviewType = GraphqlServiceRequestReview | GraphqlCoachReview;
interface ReviewProps {
  review: ReviewType;
  onDelete?: (review: ReviewType) => void | undefined;
}

function Review({ review, onDelete }: ReviewProps) {
  return (
    <Card
      style={{
        width: '19%',
        minWidth: 250,
      }}
      actions={
        onDelete
          ? [
              <Button type="text" onClick={() => onDelete(review)}>
                <Space size="small">
                  <DeleteOutlined key="delete" />
                  <Trans i18nKey="manage.reviews.actions.delete" />
                </Space>
              </Button>,
            ]
          : undefined
      }
    >
      <Space size="large" direction="vertical" style={{ width: '100%' }}>
        <Flex>
          <EntityRating hideNumber value={review.rating} />
          <div>{dayjs(review.createdAt).fromNow()}</div>
        </Flex>
        <Space size="small" direction="vertical" style={{ flexGrow: 1 }}>
          <Link to={ROUTES.MANAGE_USER.replace(':userId', String(review.ReviewedBy.id))}>
            {review.ReviewedBy.fullName}:
          </Link>

          <ReviewComment>&ldquo;{review.comment}&rdquo;</ReviewComment>
        </Space>
      </Space>
    </Card>
  );
}

Review.defaultProps = {
  onDelete: undefined,
};

export default Review;
