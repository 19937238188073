import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import { Trans, useTranslation } from 'react-i18next';

import ROUTES from '@constants/routes';
import { DEFAULT_SEARCH_DEBOUNCE_TIME } from '@constants/input';

import { ColumnsType } from 'antd/es/table';
import {
  AgentsFilter,
  AgentsSort,
  AgentsSortableFields,
  GlobalRole,
  GraphqlAgent,
  InputMaybe,
  PaginationMeta,
  ServiceRequestCategoriesEnum,
  SortOrder,
  CreateAgentInput,
  UpdateAgentInput,
  useGetAgentsQuery,
  useListRequestCategoriesQuery,
  useCreateAgentMutation,
  useDeleteAgentMutation,
  useUpdateAgentMutation,
} from '@graphql/generated';
import GenericApolloError from '@components/GenericApolloError';
import { GET_COMPANY_DETAIL_QUERY, GET_AGENTS_QUERY } from '@graphql/pros';
import { GET_SERVICE_REQUESTS_QUERY } from '@graphql/serviceRequests';

import { useUser } from '@hooks/appContext/useUser';

import { Button, CardProps, Row, Space, Typography, message } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import Modal from '@components/Modal';
import Input from '@components/Input';
import { Card } from '@components/Card';
import AgentLink from '@components/AgentLink';
import { ButtonLink } from '@components/Button';
import ConfirmButton from '@components/ConfirmButton';
import EntityRating from '@components/Rate/EntityRating';
import Table, { DEFAULT_PAGE_SIZE, PAGE_SIZES } from '@components/Table/Table';
import { FilterValue, SorterResult, TablePaginationConfig } from 'antd/es/table/interface';
import { AgentForm, AgentFormValues } from './AgentForm';

interface UpdateAgentState {
  initialValues: AgentFormValues;
  agentId: number;
}

type AgentsTableProps = {
  showSearch?: boolean;
  searchDebounceTime?: number;
  companyId?: number;
  cardProps?: CardProps;
  cardWrapper?: boolean;
  columns: string[];
};

function AgentsTable({
  showSearch = false,
  searchDebounceTime = DEFAULT_SEARCH_DEBOUNCE_TIME,
  companyId,
  cardProps,
  cardWrapper,
  columns,
}: AgentsTableProps) {
  const user = useUser();
  const i18n = useTranslation();
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [searchAgentsText, setSearchAgentsText] = useState('');
  const [updateAgentState, setUpdateAgentState] = useState<UpdateAgentState | undefined>();
  const hasEditPermission = user?.role === GlobalRole.Admin || user?.role === GlobalRole.Support;
  const hasDeletePermission = user?.role === GlobalRole.Admin;
  const [filters, setFilters] = useState<AgentsFilter>({
    text: searchAgentsText,
    companyId,
  });
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(DEFAULT_PAGE_SIZE);
  const [sortOptions, setSortOptions] = useState<InputMaybe<AgentsSort>>();
  const { data: serviceRequestCategories } = useListRequestCategoriesQuery({
    variables: { serviceRequest: true },
  });

  const {
    data: getAgentsQuery,
    error,
    loading,
    previousData,
  } = useGetAgentsQuery({
    variables: { filter: filters, sort: sortOptions, page, pageSize },
  });
  const agents = ((getAgentsQuery || previousData)?.getAgents.agents as GraphqlAgent[]) || [];
  const pagination = ((getAgentsQuery || previousData)?.getAgents.meta as PaginationMeta) || {};

  const [createAgent, { loading: creatingAgent }] = useCreateAgentMutation({
    refetchQueries: [GET_AGENTS_QUERY, GET_COMPANY_DETAIL_QUERY],
  });
  // If we delete an agent, we need to refetch the service requests table because the deleted
  // agent's service requests need to reflect their deleted status
  const [deleteAgent] = useDeleteAgentMutation({
    refetchQueries: [GET_AGENTS_QUERY, GET_COMPANY_DETAIL_QUERY, GET_SERVICE_REQUESTS_QUERY],
  });
  const [updateAgent, { loading: updatingAgent }] = useUpdateAgentMutation({
    refetchQueries: [GET_AGENTS_QUERY, GET_COMPANY_DETAIL_QUERY],
  });

  const closeModal = useCallback(() => {
    setModalIsOpen(false);
    setUpdateAgentState(undefined);
  }, []);
  useEffect(() => {
    const timer = setTimeout(() => {
      setFilters((previousFilters) => ({ ...previousFilters, text: searchAgentsText }));
    }, searchDebounceTime);
    return () => clearTimeout(timer);
  }, [searchAgentsText, searchDebounceTime]);

  const onCreateAgent = useCallback(
    async (values: AgentFormValues) => {
      await createAgent({
        variables: {
          input: {
            ...values,
            // If we have a falsy (empty) password, don't send anything
            password: values.password || undefined,
            confirmPassword: undefined,
            companyId: companyId as number,
          } as CreateAgentInput,
        },
      });
      message.success(i18n.t('forms.createAgent.successMessage' as string));
      closeModal();
    },
    [closeModal, createAgent, i18n, companyId],
  );

  const onUpdateAgent = useCallback(
    async ({
      firstName,
      lastName,
      phoneNumber,
      internalRating,
      serviceTypes,
      password,
    }: AgentFormValues) => {
      // NOTE: email field is not editable
      const updateAgentInput: UpdateAgentInput = {
        firstName,
        lastName,
        phoneNumber,
        internalRating,
        serviceTypes,
        // If we have a falsy (empty) password, don't send anything
        password: password || undefined,
      };
      await updateAgent({
        variables: { input: updateAgentInput, agentId: Number(updateAgentState?.agentId) },
      });
      message.success(i18n.t('forms.editAgent.successMessage' as string));
      closeModal();
    },
    [closeModal, updateAgent, i18n, updateAgentState?.agentId],
  );

  const availableColumns: ColumnsType<GraphqlAgent> = useMemo(
    () => [
      {
        title: i18n.t('manage.companies.table.columns.fullName') as string,
        dataIndex: 'fullName',
        key: 'fullName',
        sorter: true,
        render: (_: any, record: GraphqlAgent) => <AgentLink agent={record} />,
      },
      {
        title: i18n.t(`manage.companies.table.columns.agentRating`) as string,
        dataIndex: 'rating',
        key: 'rating',
        sorter: true,
        render: (_: any, record: GraphqlAgent) => <EntityRating value={record.rating} />,
        width: 200,
      },
      {
        title: i18n.t(`manage.companies.table.columns.internalRating`) as string,
        dataIndex: 'internalRating',
        key: 'internalRating',
        render: (_: any, record: GraphqlAgent) =>
          record?.internalRating ? `${record?.internalRating}/100` : i18n.t('fallbacks.N/A'),
      },
      {
        title: i18n.t(`manage.companies.table.columns.agentPhone`) as string,
        dataIndex: 'phoneNumber',
        key: 'phoneNumber',
        render: (_: any, record: GraphqlAgent) => record?.User.phoneNumber,
      },
      {
        title: i18n.t('manage.companies.table.columns.email') as string,
        dataIndex: 'email',
        key: 'email',
        render: (_: any, record: GraphqlAgent) => record?.User.email,
      },
      {
        title: i18n.t('manage.companies.table.columns.name') as string,
        dataIndex: 'companyName',
        key: 'companyName',
        sorter: true,
        render: (_: any, record: GraphqlAgent) => (
          <Link to={ROUTES.MANAGE_COMPANY.replace(':companyId', String(record?.Company?.id))}>
            {record?.Company?.name}
          </Link>
        ),
      },
      {
        title: i18n.t('manage.companies.table.columns.address') as string,
        dataIndex: 'address',
        key: 'address',
        render: (_: any, record: GraphqlAgent) => (
          <div>
            {record?.Company?.addressLine1} {record?.Company?.addressLine2} {record?.Company?.city},{' '}
            {record?.Company?.state} {record?.Company?.zipCode}
          </div>
        ),
      },
      {
        title: i18n.t('manage.companies.table.columns.serviceTypes') as string,
        dataIndex: 'serviceTypes',
        key: 'serviceTypes',
        render: (_: any, record: GraphqlAgent) => {
          return (
            <div>
              {record?.serviceTypes
                ?.map(
                  (serviceTypeEnum: string) => i18n.t(`serviceTypes.${serviceTypeEnum}`) as string,
                )
                .join(', ')}
            </div>
          );
        },
        filters: serviceRequestCategories?.listRequestCategories?.map((category) => ({
          text: category.name,
          value: category.id,
        })),
      },
      {
        title: <Trans i18nKey="manage.agents.columns.actions" />,
        key: 'edit',
        render: (_: any, record: GraphqlAgent) => (
          <Space size="middle">
            <ButtonLink
              onClick={() => {
                setUpdateAgentState({
                  initialValues: {
                    ...record,
                    firstName: record.User.firstName,
                    lastName: record.User.lastName,
                    email: record.User.email,
                    phoneNumber: record.User.phoneNumber,
                  } as AgentFormValues,
                  agentId: record?.userId,
                });
                setModalIsOpen(true);
              }}
            >
              <Trans i18nKey="manage.agents.actions.edit" />
            </ButtonLink>
            {hasDeletePermission && (
              <ConfirmButton
                onConfirm={() => deleteAgent({ variables: { deleteAgentId: record?.userId } })}
                titleText={i18n.t('manage.agents.actions.deleteTitle', {
                  agentId: String(record?.userId).padStart(5, '0'),
                })}
                warningText={i18n.t('manage.agents.actions.deleteWarning', {
                  agentId: String(record?.userId).padStart(5, '0'),
                })}
              >
                <ButtonLink>
                  <Trans i18nKey="manage.agents.actions.delete" />
                </ButtonLink>
              </ConfirmButton>
            )}
          </Space>
        ),
      },
    ],
    [i18n, serviceRequestCategories?.listRequestCategories, deleteAgent, hasDeletePermission],
  );

  const onTableChange = (
    tablePagination: TablePaginationConfig,
    tableFilters: Record<string, FilterValue | null>,
    tableSorter: SorterResult<GraphqlAgent> | SorterResult<GraphqlAgent>[],
  ) => {
    const uniqueSorter = tableSorter as SorterResult<GraphqlAgent>;

    // Pagination
    setPage(tablePagination.current as number);
    setPageSize(tablePagination.pageSize as number);

    // Order
    if (uniqueSorter.order) {
      const direction = uniqueSorter.order === 'ascend' ? 'asc' : 'desc';
      setSortOptions({
        field: uniqueSorter.field as AgentsSortableFields,
        direction: direction as SortOrder,
      });
    } else {
      setSortOptions(undefined);
    }

    // Filters
    const { serviceTypes } = tableFilters;
    setFilters((prevFilters) => ({
      ...prevFilters,
      serviceTypes: serviceTypes?.length
        ? (serviceTypes as ServiceRequestCategoriesEnum[])
        : undefined,
    }));
  };

  if (error) return <GenericApolloError error={error} />;

  const Wrapper = cardWrapper ? Card : React.Fragment;

  return (
    <Wrapper
      title={<Trans i18nKey="manage.agents.title" />}
      actions={
        hasEditPermission
          ? [
              <Button
                type="text"
                onClick={() => {
                  setUpdateAgentState(undefined);
                  setModalIsOpen(true);
                }}
              >
                <Space size="small">
                  <PlusOutlined />
                  <Trans i18nKey="manage.agents.actions.add" />
                </Space>
              </Button>,
            ]
          : undefined
      }
      {...cardProps}
    >
      {!!showSearch && (
        <Row style={{ marginBottom: '20px' }}>
          <Input
            placeholder={i18n.t('manage.companies.search') as string}
            value={searchAgentsText}
            onChange={(e) => setSearchAgentsText(e.target.value)}
          />
        </Row>
      )}
      <Table
        loading={loading}
        columns={availableColumns.filter((availableColumn) =>
          columns.includes(availableColumn.key as string),
        )}
        dataSource={agents.map((agent) => ({ ...agent, key: agent.userId }))}
        onChange={onTableChange}
        pagination={{
          pageSize,
          pageSizeOptions: PAGE_SIZES,
          showSizeChanger: true,
          defaultCurrent: 1,
          current: pagination?.currentPage,
          total: pagination?.totalCount,
        }}
      />
      {modalIsOpen && (
        <Modal
          width={400}
          closable
          onCancel={closeModal}
          open
          title={
            <Typography.Title level={2}>
              {updateAgentState ? (
                <Trans i18nKey="forms.editAgent.title" />
              ) : (
                <Trans i18nKey="forms.createAgent.title" />
              )}
            </Typography.Title>
          }
        >
          <AgentForm
            editMode={Boolean(updateAgentState)}
            loading={creatingAgent || updatingAgent}
            onSubmit={updateAgentState ? onUpdateAgent : onCreateAgent}
            submitLabel={
              updateAgentState ? (
                <Trans i18nKey="forms.editAgent.submitLabel" />
              ) : (
                <Trans i18nKey="forms.createAgent.submitLabel" />
              )
            }
            initialFormValues={updateAgentState?.initialValues}
          />
        </Modal>
      )}
    </Wrapper>
  );
}

AgentsTable.defaultProps = {
  showSearch: false,
  searchDebounceTime: DEFAULT_SEARCH_DEBOUNCE_TIME,
  companyId: null,
  cardProps: {},
  cardWrapper: true,
};

export default AgentsTable;
