import actions from '@constants/actions';
import { GraphqlFeatureFlag } from '@graphql/generated';
import { useAppContext } from '@providers/appContextProvider';

export const useSetFeatureFlags = () => {
  const { dispatch } = useAppContext();
  return (featureFlags: GraphqlFeatureFlag[]) =>
    dispatch({
      type: actions.SET_FEATURE_FLAGS,
      payload: featureFlags,
    });
};
