import { createContext, Dispatch, useContext, useMemo, useReducer } from 'react';
import { DefaultTheme } from 'styled-components';

// Constants
import themes from '@constants/themes';
import actions from '@constants/actions';
import { useCookies } from 'react-cookie';
import { COOKIES_KEYS } from '@constants/cookies';
import { parseJWT } from '@utils/parseJWT';
import { GraphqlFeatureFlag, GraphqlUser } from '@graphql/generated';

export const AppContext = createContext({});

export type TAppContext = {
  theme: DefaultTheme;
  locale: string;
  user: GraphqlUser | null;
  featureFlags: GraphqlFeatureFlag[];
};

type Action = {
  type: string;
  payload: any;
};

export function AppContextProvider({
  locale,
  children,
}: {
  locale: string;
  children: JSX.Element;
}) {
  const [cookies] = useCookies([COOKIES_KEYS.ACCESS_TOKEN]);
  const accessToken = cookies[COOKIES_KEYS.ACCESS_TOKEN];

  const reducer = (state: TAppContext, action: Action) => {
    switch (action.type) {
      case actions.SET_USER:
        return {
          ...state,
          user: action.payload,
        };
      case actions.SET_FEATURE_FLAGS:
        return {
          ...state,
          featureFlags: action.payload,
        };
      default:
        return state;
    }
  };

  const initialState = {
    theme: themes.lightMode,
    locale,
    user: accessToken ? parseJWT(accessToken) : null,
    featureFlags: [] as GraphqlFeatureFlag[],
  };

  const [appContext, dispatch] = useReducer(reducer, initialState);
  const appContextValue = useMemo(() => ({ ...appContext, dispatch }), [appContext]);

  return <AppContext.Provider value={appContextValue}>{children}</AppContext.Provider>;
}

export type AppContextValue = TAppContext & { dispatch: Dispatch<Action> };
export const useAppContext = () => {
  return useContext(AppContext) as AppContextValue;
};

export default AppContextProvider;
