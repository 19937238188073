import { DefaultTheme } from 'styled-components';

const common: DefaultTheme = {
  breakpoints: {
    sm: '576px',
    md: '768px',
    lg: '992px',
    xl: '1200px',
    xxl: '1600px',
  },
  fontSizes: {
    title: '27px',
    large: '16px',
    medium: '14px',
    small: '12px',
    xsmall: '10px',
  },
  lineHeights: {
    title: '40px',
    large: '22px',
    medium: '18px',
    small: '14px',
    xsmall: '12px',
  },
  fontWeights: {
    bold: 700,
    normal: 400,
  },
  colors: {
    // Success/Warning/Error/etc
    success: '#49aa19',
    warning: '#d34041',
    dangerous: '#ff2222',
    filled: '#5a5a5a',
    disabled: '#0000004D',
    gray: '#888888',

    // Foreground (fonts, borders, etc) colors
    foregroundPrimary: '#ffffff',
    foregroundSecondary: '#dddddd',
    foregroundTertiary: '#bbbbbb',
    foregroundCTA: '#ffffff',

    // Background colors
    backgroundPrimary: '#000000',
    backgroundSecondary: '#222222',
    backgroundCTA: '#ea8231',
  },
  layout: {
    main: {
      backgroundColor: '#dddddd',
    },
    backgroundColor: '#ffffff',
    sidebar: {
      color: '#111111',
      backgroundColor: '#eeeeee',
    },
  },
};

const darkMode = {
  ...common,
  colors: {
    // Import common colors
    ...common.colors,

    // Foreground (fonts, borders, etc) colors
    foregroundPrimary: '#ffffff',
    foregroundSecondary: '#dddddd',

    // Background colors
    backgroundPrimary: '#000000',
    backgroundSecondary: '#222222',
  },
};

const lightMode = {
  ...common,
  colors: {
    // Import common colors
    ...common.colors,

    // Foreground (fonts, borders, etc) colors
    foregroundPrimary: '#262626',
    foregroundSecondary: '#d9d9d9',
    foregroundTertiary: '#616161',

    // Background colors
    backgroundPrimary: '#ffffff',
    backgroundSecondary: '#f5f5f5',
  },
};

export const themes: { darkMode: DefaultTheme; lightMode: DefaultTheme } = {
  darkMode,
  lightMode,
};

export default themes;
