import { useEffect } from 'react';
import axios from 'axios';
import styled from 'styled-components';
import { useNavigate, useParams } from 'react-router-dom';
import { Spin } from 'antd';

import useAuth from '@hooks/useAuth';
import ROUTES from '@constants/routes';

const Container = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
`;

function SSOAuth() {
  const params = new URLSearchParams(window.location.search);
  const { service } = useParams();
  const navigate = useNavigate();
  const { setAccessToken } = useAuth();

  useEffect(() => {
    const init = async () => {
      try {
        const { data } = await axios.get(
          `${process.env.REACT_APP_API_URI}/auth/login/redirect/${service}`,
          { params },
        );
        setAccessToken(data?.accessToken);
      } catch (e) {
        // eslint-disable-next-line no-console
        console.error(`${service} Auth Error:`, e);
        navigate(ROUTES.LOG_IN);
      }
    };
    init();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Container>
      <Spin size="large" />
    </Container>
  );
}

export default SSOAuth;
