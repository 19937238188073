import { useCallback, useMemo } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { GET_APPOINTMENT_REQUESTS_QUERY, GET_ACTIVITY_TIMELINE_QUERY } from '@graphql/activities';
import { GET_AGENTS_FOR_SERVICE_REQUEST_QUERY } from '@graphql/pros';
import {
  GraphqlAgent,
  GetTopAgentsOutput,
  useCreateAppointmentRequestServiceRequestMutation,
  useGetAgentsByServiceRequestQuery,
} from '@graphql/generated';

import { renderAgentConfidenceLevel } from '@utils/agents';

import { message } from 'antd';
import { ColumnsType } from 'antd/es/table';
import Table from '@components/Table/Table';
import AgentLink from '@components/AgentLink';
import { ButtonLink } from '@components/Button';
import EntityRating from '@components/Rate/EntityRating';
import GenericApolloError from '@components/GenericApolloError';

type ServiceRequestAgentsProps = {
  serviceRequestId?: number;
};

function ServiceRequestAgents({ serviceRequestId }: ServiceRequestAgentsProps) {
  const i18n = useTranslation();

  const {
    data: getAgentsQuery,
    error,
    loading,
    previousData,
  } = useGetAgentsByServiceRequestQuery({
    variables: { serviceRequestId: Number(serviceRequestId) },
  });

  const [createAppointmentRequest, { loading: createAppointmentRequestLoading }] =
    useCreateAppointmentRequestServiceRequestMutation({
      refetchQueries: [
        GET_AGENTS_FOR_SERVICE_REQUEST_QUERY,
        GET_APPOINTMENT_REQUESTS_QUERY,
        GET_ACTIVITY_TIMELINE_QUERY,
      ],
      awaitRefetchQueries: true,
    });

  const agents =
    ((getAgentsQuery || previousData)?.getAgentsByServiceRequest.agents as GetTopAgentsOutput[]) ||
    [];

  const onSendToAgent = useCallback(
    async (id: number) => {
      await createAppointmentRequest({
        variables: { serviceRequestId, userIds: [id] },
      });
      message.success(<Trans i18nKey="manage.agents.sendToAgentModal.successMessage" />);
    },
    [createAppointmentRequest, serviceRequestId],
  );

  const columns: ColumnsType<GetTopAgentsOutput> = useMemo(
    () => [
      {
        title: i18n.t('manage.companies.table.columns.fullName') as string,
        dataIndex: 'fullName',
        key: 'fullName',
        render: (_: any, record: GetTopAgentsOutput) => (
          <AgentLink agent={record as GraphqlAgent} />
        ),
      },
      {
        title: i18n.t(`manage.companies.table.columns.agentRating`) as string,
        dataIndex: 'rating',
        key: 'rating',
        render: (_: any, record: GetTopAgentsOutput) => (
          <EntityRating value={record.rating} count={record.ServiceRequestReviews?.length} />
        ),
        width: 200,
      },
      {
        title: i18n.t(`manage.companies.table.columns.internalRating`) as string,
        dataIndex: 'internalRating',
        key: 'internalRating',
        render: (_: any, record: GetTopAgentsOutput) =>
          record?.internalRating ? `${record?.internalRating}/100` : i18n.t('fallbacks.N/A'),
      },
      {
        title: <Trans i18nKey="manage.agents.columns.confidenceLevel" />,
        render: (_: any, record: GetTopAgentsOutput) => renderAgentConfidenceLevel(record),
      },
      {
        title: i18n.t(`manage.agents.actions.header`) as string,
        key: 'sendToAgent',
        render: (_: any, record: GetTopAgentsOutput) =>
          record.appointmentRequestSent ? (
            <ButtonLink disabled>{i18n.t('manage.agents.actions.sendToAgentSent')}</ButtonLink>
          ) : (
            <ButtonLink
              disabled={createAppointmentRequestLoading}
              onClick={() => onSendToAgent(record.userId)}
            >
              {i18n.t('manage.agents.actions.sendToAgent')}
            </ButtonLink>
          ),
      },
    ],
    [i18n, onSendToAgent, createAppointmentRequestLoading],
  );

  if (error) return <GenericApolloError error={error} />;

  return (
    <Table
      loading={loading}
      columns={columns}
      dataSource={agents.map((agent) => ({ ...agent, key: agent.userId }))}
      pagination={false}
    />
  );
}

ServiceRequestAgents.defaultProps = {
  serviceRequestId: null,
};

export default ServiceRequestAgents;
