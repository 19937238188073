import React, { useState } from 'react';
import styled from 'styled-components';

import Modal from './Modal';

const ButtonContainer = styled.span`
  cursor: pointer;
`;

const ModalContent = styled.div`
  margin-bottom: 16px;
`;

export type ConfirmButtonProps = {
  children: JSX.Element;
  disabled?: boolean;
  onConfirm: () => void;
  titleText: string;
  warningText: string;
};

export function ConfirmButton({
  children,
  disabled,
  onConfirm,
  titleText,
  warningText,
}: ConfirmButtonProps) {
  const [isModalOpen, setIsModalOpen] = useState(false);

  return (
    <>
      <ButtonContainer onClick={() => !disabled && setIsModalOpen(true)}>
        {children}
      </ButtonContainer>
      {isModalOpen && (
        <Modal
          title={titleText}
          visible
          onConfirm={onConfirm}
          onCancel={() => setIsModalOpen(false)}
        >
          <ModalContent>{warningText}</ModalContent>
        </Modal>
      )}
    </>
  );
}

ConfirmButton.defaultProps = {
  disabled: false,
};

export default ConfirmButton;
