import styled from 'styled-components';
import { Divider } from 'antd';
import { DividerProps } from 'antd/lib/divider';

export const DividerStyled: React.FC<DividerProps> = styled(Divider)`
  border-width: 0px;
  border-radius: 6px;
  padding: 0px 15px;
  margin: 32px 0px !important;
  .ant-divider-inner-text {
    color: ${({ theme }) => theme.colors.foregroundSecondary};
    font-size: ${({ theme }) => theme.fontSizes.medium};
    line-height: ${({ theme }) => theme.fontSizes.medium};
  }
`;

export default DividerStyled;
