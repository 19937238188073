import { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';

import { GET_CUSTOMERS_QUERY } from '@graphql/users';
import {
  CreateCircleRequestInput,
  GlobalRole,
  GraphqlAddresses,
  GraphqlUser,
  RecurringFrequencyType,
  ServiceRequestTentativeSchedule,
  ServiceRequestCategoriesEnum,
  ServiceRequestStatus,
  useCreateAddressMutation,
  useCreateServiceRequestMutation,
  useCreateCircleRequestMutation,
  useListRequestCategoriesQuery,
  useUpdateCircleRequestMutation,
} from '@graphql/generated';

import { Form, Radio, Space, Switch, Typography } from 'antd';
import Masonry, { ResponsiveMasonry } from 'react-responsive-masonry';

import ROUTES from '@constants/routes';
import { ActivitiesTypes } from '@constants/activities';
import { convertCircleRequestToCreateServiceRequestInput } from '@utils/activities';
import { stringifyAddress } from '@utils/formatters/address';

import { CenterRow } from '@components/styled';
import { InputTextarea } from '@components/Input';
import Button from '@components/Button';
import Card from '@components/Card/Card';
import { Select } from '@components/Input/Select';
import RecordDetailCard from '@components/Card/RecordDetailCard';
import CustomerSearch from '@components/Activities/CustomerSearch';
import RideLocationsModal from '@components/Activities/RideLocationsModal';
import AvailabilityModal from '@components/Activities/AvailabilityModal';
import LocationDropdownOther from '@components/Activities/LocationDropdownOther';

function CreateActivity({ type }: { type: ActivitiesTypes }) {
  const i18n = useTranslation();
  const navigate = useNavigate();
  const { state: historyState } = useLocation();

  const [customer, setCustomer] = useState<any>(historyState?.Customer || null);
  const [showRideLocationsModal, setShowRideLocationsModal] = useState<boolean>(false);
  const [showAvailabilityModal, setShowAvailabilityModal] = useState<boolean>(false);

  const [form] = Form.useForm();

  const [createAddressMutation] = useCreateAddressMutation({
    refetchQueries: [GET_CUSTOMERS_QUERY],
  });
  const { data: requestCategories } = useListRequestCategoriesQuery({
    variables: {
      serviceRequest: type === ActivitiesTypes.SERVICE_REQUEST ? true : undefined,
      circleRequest: type === ActivitiesTypes.CIRCLE_REQUEST ? true : undefined,
    },
  });
  const [createServiceRequestMutation, { loading: createServiceRequestLoading }] =
    useCreateServiceRequestMutation();
  const [createCircleRequestMutation, { loading: createCircleRequestLoading }] =
    useCreateCircleRequestMutation();
  const [updateCircleRequestMutation, { loading: updateCircleRequestLoading }] =
    useUpdateCircleRequestMutation();

  return (
    <Form
      form={form}
      initialValues={convertCircleRequestToCreateServiceRequestInput(historyState, customer)}
      style={{ maxWidth: 800 }}
      onFinish={async (formState) => {
        if (type === ActivitiesTypes.SERVICE_REQUEST) {
          const response = await createServiceRequestMutation({
            variables: {
              data: {
                // Default fields in case we are not exposing them based on logic and therefore they won't be in the formState object
                isAvailabilityFlexible: false,
                availabilities: [],
                ...formState,
                askTo: undefined,
              } as any,
            },
          });
          if (historyState?.id) {
            await updateCircleRequestMutation({
              variables: {
                updateCircleRequestId: historyState?.id,
                data: { status: ServiceRequestStatus.Inactive },
              },
            });
          }
          navigate(
            ROUTES.MANAGE_SERVICE_REQUEST.replace(
              ':activityId',
              String(response?.data?.createServiceRequest?.id),
            ),
          );
        } else if (type === ActivitiesTypes.CIRCLE_REQUEST) {
          const response = await createCircleRequestMutation({
            variables: {
              data: {
                // Default fields in case we are not exposing them based on logic and therefore they won't be in the formState object
                isAvailabilityFlexible: false,
                availabilities: [],
                ...formState,
                askTo:
                  formState.askTo ??
                  customer?.users
                    .filter((user: { role: string }) => user?.role === GlobalRole.CircleMember)
                    .map(({ user }: { user: GraphqlUser }) => user?.id),
              } as CreateCircleRequestInput,
            },
          });
          navigate(
            ROUTES.MANAGE_CIRCLE_REQUEST.replace(
              ':activityId',
              String(response?.data?.createCircleRequest?.id),
            ),
          );
        }
      }}
    >
      <Typography.Title level={2}>
        <Trans i18nKey={`manage.${type}s.${historyState?.id ? 'convert' : 'create'}.new`} />
      </Typography.Title>
      <ResponsiveMasonry columnsCountBreakPoints={{ 350: 1 }}>
        <Masonry gutter="20px">
          <RecordDetailCard
            title={
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}
              >
                <Trans i18nKey="manage.serviceRequests.create.customerInfo" />
                <div style={{ marginBottom: -24 }}>
                  <CustomerSearch
                    onChange={(value) => {
                      setCustomer(value);
                      form.setFieldsValue({
                        addressId: value?.addresses?.find((address) => address?.isDefault)?.id,
                        customerId: value?.id,
                        requestedBy: value?.users?.find(
                          (user) => user.role === GlobalRole.SeniorMember,
                        )?.user?.id,
                      });
                    }}
                    selectedCustomer={customer}
                  />
                </div>
              </div>
            }
            hasData={!!customer}
            noDataWarning={
              <div style={{ textAlign: 'center' }}>
                <Trans i18nKey="manage.serviceRequests.create.selectCustomerFirst" />
              </div>
            }
            fields={[
              {
                name: i18n.t('manage.customer.details.firstName'),
                label: i18n.t('manage.customer.details.firstName'),
                value: customer?.firstName,
              },
              {
                name: i18n.t('manage.customer.details.lastName'),
                label: i18n.t('manage.customer.details.lastName'),
                value: customer?.lastName,
              },
              {
                name: i18n.t('manage.customer.details.address'),
                label: i18n.t('manage.customer.details.address'),
                value: stringifyAddress(
                  customer?.addresses?.find(
                    (address: GraphqlAddresses) => address.isDefault,
                  ) as GraphqlAddresses,
                ),
              },
              {
                name: i18n.t('manage.customer.details.email'),
                label: i18n.t('manage.customer.details.email'),
                value: customer?.users?.find(
                  (user: { role: string; user: GraphqlUser }) =>
                    user?.role === GlobalRole.SeniorMember,
                )?.user?.email,
              },
              {
                name: i18n.t('manage.customer.details.phoneNumber'),
                label: i18n.t('manage.customer.details.phoneNumber'),
                value: customer?.users?.find(
                  (user: { role: string; user: GraphqlUser }) =>
                    user?.role === GlobalRole.SeniorMember,
                )?.user?.phoneNumber,
              },
              {
                name: i18n.t('forms.input.requestedBy.label'),
                label: i18n.t('forms.input.requestedBy.label'),
                value: (
                  <Form.Item
                    name="requestedBy"
                    rules={[
                      {
                        required: true,
                        message: i18n.t('forms.input.requestedBy.errorMessage') as string,
                      },
                    ]}
                  >
                    <Select
                      style={{ minWidth: 300 }}
                      placeholder={i18n.t('forms.input.requestedBy.placeholder')}
                      options={customer?.users
                        ?.filter((user: { role: string; user: GraphqlUser }) =>
                          [GlobalRole.SeniorMember, GlobalRole.FamilyPartner].includes(
                            user?.role as GlobalRole,
                          ),
                        )
                        .map(({ user }: { user: GraphqlUser }) => ({
                          label: user.fullName,
                          value: user.id,
                        }))}
                    />
                  </Form.Item>
                ),
              },
              {
                name: i18n.t('forms.input.askTo.label'),
                label: i18n.t('forms.input.askTo.label'),
                types: [ActivitiesTypes.CIRCLE_REQUEST],
                value: (
                  <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end' }}>
                    <div>
                      <Form.Item noStyle name="askTo">
                        <Radio.Group>
                          <Radio.Button value={undefined}>
                            <Trans i18nKey="manage.circleRequests.create.myCircle" />
                          </Radio.Button>
                          <Radio.Button style={{ borderRadius: '0px 8px 8px 0px' }} value={[]}>
                            <Trans i18nKey="manage.circleRequests.create.chooseMembers" />
                          </Radio.Button>
                        </Radio.Group>
                      </Form.Item>
                    </div>
                    <Form.Item noStyle dependencies={['askTo']}>
                      {({ getFieldValue }) => {
                        const askTo = getFieldValue('askTo');
                        return Array.isArray(askTo) ? (
                          <Form.Item
                            name="askTo"
                            rules={[
                              {
                                required: true,
                                message: i18n.t('forms.input.askTo.errorMessage') as string,
                              },
                            ]}
                          >
                            <Select
                              mode="multiple"
                              style={{ minWidth: 300, maxWidth: 500, marginTop: 10 }}
                              placeholder={i18n.t('forms.input.askTo.placeholder')}
                              optionFilterProp="label"
                              options={customer?.users
                                ?.filter(
                                  (user: { role: string }) =>
                                    user?.role === GlobalRole.CircleMember,
                                )
                                .map(({ user }: { user: GraphqlUser }) => ({
                                  label: user.fullName,
                                  value: user.id,
                                }))}
                            />
                          </Form.Item>
                        ) : null;
                      }}
                    </Form.Item>
                  </div>
                ),
              },
            ].filter((row) => row?.types?.includes(type) || !row?.types)}
          />
          {customer?.id && (
            <Card title={i18n.t(`manage.${type}s.create.details`)} key={customer?.id}>
              <>
                <Typography.Title level={5} style={{ marginTop: 0 }}>
                  <Trans i18nKey="forms.input.description.label" />
                </Typography.Title>
                <Form.Item
                  name="description"
                  rules={[
                    {
                      required: true,
                      message: i18n.t('forms.input.description.errorMessage') as string,
                    },
                  ]}
                >
                  <InputTextarea
                    name="description"
                    rows={6}
                    placeholder={i18n.t('forms.input.description.placeholder') as string}
                  />
                </Form.Item>
                <Typography.Title level={5}>
                  <Trans i18nKey="forms.input.category.label" />
                </Typography.Title>
                <Form.Item
                  name="category"
                  rules={[
                    {
                      required: true,
                      message: i18n.t('forms.input.category.errorMessage') as string,
                    },
                  ]}
                >
                  <Select
                    placeholder={i18n.t('forms.input.category.placeholder') as string}
                    style={{ width: '100%' }}
                    showSearch
                    optionFilterProp="label"
                    options={requestCategories?.listRequestCategories?.map((category) => ({
                      value: category.id,
                      label: category.name,
                    }))}
                    onChange={(value) => {
                      if (value === ServiceRequestCategoriesEnum.Ride) {
                        form.setFieldsValue({ addressId: undefined });
                      } else {
                        form.setFieldsValue({
                          ride: undefined,
                          addressId: customer?.addresses?.find(
                            (address: GraphqlAddresses) => address?.isDefault,
                          )?.id,
                        });
                      }
                    }}
                  />
                </Form.Item>
                <Form.Item noStyle dependencies={['category', 'addressId']}>
                  {({ getFieldValue }) => {
                    const category = getFieldValue('category');
                    const formAddressId = getFieldValue('addressId');
                    return category === ServiceRequestCategoriesEnum.Ride ? (
                      <CenterRow>
                        <Form.Item
                          name="ride"
                          style={{ minWidth: 400 }}
                          rules={[
                            {
                              required: true,
                              message: i18n.t('forms.input.ride.errorMessage') as string,
                            },
                          ]}
                        >
                          <Button
                            style={{ marginTop: 20, maxWidth: 400 }}
                            onClick={() => setShowRideLocationsModal(true)}
                          >
                            <Trans i18nKey="manage.serviceRequests.create.configureLocations" />
                          </Button>
                        </Form.Item>
                      </CenterRow>
                    ) : (
                      <>
                        <Typography.Title level={5}>
                          <Trans i18nKey={`manage.${type}s.create.confirmLocation`} />
                        </Typography.Title>
                        <Form.Item
                          name="addressId"
                          rules={[
                            {
                              required: true,
                              message: i18n.t('forms.input.addressId.errorMessage') as string,
                            },
                          ]}
                        >
                          <LocationDropdownOther
                            addresses={customer.addresses}
                            value={formAddressId}
                            onChange={async (address) => {
                              let addressId = address;
                              // If address is an object instead of an ID, we need to create an address entry
                              if (typeof addressId === 'object') {
                                const response = await createAddressMutation({
                                  variables: {
                                    createAddressInput: {
                                      country: 'US',
                                      ...address,
                                      customerId: customer.id,
                                      isFromAPI: true,
                                    },
                                  },
                                });
                                // So that we can use its id when creating the service request
                                addressId = response?.data?.createAddress?.id;
                              }
                              form.setFieldsValue({ addressId });
                            }}
                            defaultToDefaultAddress
                            placeholder={i18n.t('forms.input.pickupAddress.placeholder') as string}
                          />
                        </Form.Item>
                      </>
                    );
                  }}
                </Form.Item>
                <Typography.Title level={5}>
                  <Trans i18nKey={`manage.${type}s.create.when`} />
                </Typography.Title>
                <Form.Item
                  name="tentativeSchedule"
                  rules={[
                    {
                      required: true,
                      message: i18n.t('forms.input.tentativeSchedule.errorMessage') as string,
                    },
                  ]}
                >
                  <Select
                    style={{ width: '100%' }}
                    options={Object.values(ServiceRequestTentativeSchedule).map(
                      (tentativeSchedule) => ({
                        value: tentativeSchedule,
                        label: i18n.t(`tentativeSchedules.${tentativeSchedule}`),
                      }),
                    )}
                    onChange={(value) => {
                      if (value === ServiceRequestTentativeSchedule.ChooseTimeAndDate) {
                        form.setFieldsValue({ isAvailabilityFlexible: false });
                      }
                      if (
                        value === ServiceRequestTentativeSchedule.WithinDays ||
                        value === ServiceRequestTentativeSchedule.WithinWeeks
                      ) {
                        form.setFieldsValue({ availabilities: [] });
                      }
                    }}
                  />
                </Form.Item>
                <Form.Item noStyle dependencies={['tentativeSchedule']}>
                  {({ getFieldValue }) => {
                    const tentativeSchedule = getFieldValue('tentativeSchedule');
                    return !!tentativeSchedule &&
                      tentativeSchedule !== ServiceRequestTentativeSchedule.ChooseTimeAndDate ? (
                      <Form.Item noStyle name="isAvailabilityFlexible">
                        <Radio.Group style={{ marginTop: 10 }}>
                          <Space direction="vertical">
                            <Radio key="flexible" value>
                              <Trans i18nKey="manage.serviceRequests.create.flexible" />
                            </Radio>
                            <Radio key="inflexible" value={false}>
                              <Trans i18nKey="manage.serviceRequests.create.inflexible" />
                            </Radio>
                          </Space>
                        </Radio.Group>
                      </Form.Item>
                    ) : null;
                  }}
                </Form.Item>
                <Form.Item noStyle dependencies={['isAvailabilityFlexible']}>
                  {({ getFieldValue }) => {
                    const isAvailabilityFlexible = getFieldValue('isAvailabilityFlexible');
                    return !isAvailabilityFlexible ? (
                      <CenterRow>
                        <Form.Item
                          name="availabilities"
                          style={{ minWidth: 400 }}
                          rules={[
                            {
                              required: true,
                              message: i18n.t('forms.input.availabilities.errorMessage') as string,
                            },
                          ]}
                        >
                          <Button
                            onClick={() => setShowAvailabilityModal(true)}
                            style={{ marginTop: 20, maxWidth: 400 }}
                          >
                            <Trans i18nKey="manage.serviceRequests.create.enterAvailability" />
                          </Button>
                        </Form.Item>
                      </CenterRow>
                    ) : null;
                  }}
                </Form.Item>
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                  <Typography.Title level={5}>
                    <Trans i18nKey="manage.serviceRequests.create.recurring" />
                  </Typography.Title>
                  <Form.Item noStyle name="recurring">
                    <Switch
                      style={{ marginTop: 26.72, marginLeft: 20 }}
                      onChange={(value) => {
                        if (value === false) {
                          form.setFieldsValue({ frequency: undefined });
                        }
                      }}
                    />
                  </Form.Item>
                  <Form.Item noStyle dependencies={['recurring']}>
                    {({ getFieldValue }) => {
                      const recurring = getFieldValue('recurring');
                      return recurring ? (
                        <Form.Item
                          name="frequency"
                          style={{ minWidth: 200, margin: 23 }}
                          rules={[
                            {
                              required: true,
                              message: i18n.t('forms.input.frequency.errorMessage') as string,
                            },
                          ]}
                        >
                          <Select
                            placeholder={
                              i18n.t('manage.serviceRequests.create.frequency') as string
                            }
                            options={Object.values(RecurringFrequencyType).map((frequency) => ({
                              label: i18n.t(`recurringFrequencyType.${frequency}`),
                              value: frequency,
                            }))}
                          />
                        </Form.Item>
                      ) : null;
                    }}
                  </Form.Item>
                </div>
              </>
            </Card>
          )}
          <CenterRow>
            <Button
              htmlType="submit"
              disabled={!customer}
              loading={
                createServiceRequestLoading ||
                createCircleRequestLoading ||
                updateCircleRequestLoading
              }
              style={{ maxWidth: 400 }}
            >
              <Trans i18nKey={`manage.${type}s.${historyState?.id ? 'convert' : 'create'}.cta`} />
            </Button>
          </CenterRow>
        </Masonry>
      </ResponsiveMasonry>
      {showRideLocationsModal && (
        <Form.Item noStyle dependencies={['ride']}>
          {({ getFieldValue }) => {
            const ride = getFieldValue('ride');
            return (
              <RideLocationsModal
                ride={ride ?? undefined}
                onClose={() => setShowRideLocationsModal(false)}
                onChange={(payload) => {
                  form.setFieldsValue({ addressId: undefined, ride: payload });
                }}
                addresses={customer?.addresses}
              />
            );
          }}
        </Form.Item>
      )}
      {showAvailabilityModal && (
        <Form.Item noStyle dependencies={['availabilities']}>
          {({ getFieldValue }) => {
            const availabilities = getFieldValue('availabilities');
            return (
              <AvailabilityModal
                open
                defaultValue={availabilities}
                onClose={() => setShowAvailabilityModal(false)}
                onChange={(payload) => form.setFieldsValue({ availabilities: payload })}
                tentativeSchedule={form.getFieldValue('tentativeSchedule')}
              />
            );
          }}
        </Form.Item>
      )}
    </Form>
  );
}

export default CreateActivity;
