import GenericApolloError from '@components/GenericApolloError';
import {
  GraphqlCoachReview,
  PaginationMeta,
  ReviewSortableFields,
  SortOrder,
  useDeleteCoachReviewMutation,
  useGetCoachReviewsQuery,
} from '@graphql/generated';
import { useMemo, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import ReviewsList from '@components/Reviews/ReviewsList';
import Modal from '@components/Modal';
import { GET_COACH_REVIEWS } from '@graphql/users';
import { message } from 'antd';

type DeleteReviewModal = {
  open: boolean;
  review?: GraphqlCoachReview | undefined;
};

function CoachReviews({ coachId }: { coachId: number }) {
  const i18n = useTranslation();
  const [deleteReviewModal, setDeleteReviewModal] = useState<DeleteReviewModal>({ open: false });
  const [sortOrder, setSortOrder] = useState<SortOrder>(SortOrder.Desc);
  const [sortBy, setSortBy] = useState<ReviewSortableFields>(ReviewSortableFields.CreatedAt);
  const [page, setPage] = useState(1);

  const [pageSize, setPageSize] = useState(10);
  const {
    data: coachReviewsQuery,
    error,
    loading: loadingCoachReviews,
    previousData,
  } = useGetCoachReviewsQuery({
    variables: {
      coachId,
      pageSize,
      page,
      sort: {
        direction: sortOrder,
        field: sortBy,
      },
    },
  });

  const [deleteCoachReview, { loading: isDeletingCoachReview }] = useDeleteCoachReviewMutation({
    refetchQueries: [GET_COACH_REVIEWS],
  });

  const reviews = useMemo(
    () =>
      ((coachReviewsQuery || previousData)?.getCoachReviews.reviews as GraphqlCoachReview[]) || [],
    [coachReviewsQuery, previousData],
  );

  const pagination =
    ((coachReviewsQuery || previousData)?.getCoachReviews.meta as PaginationMeta) || {};

  if (error) return <GenericApolloError error={error} />;

  return (
    <>
      <ReviewsList
        title={
          pagination.totalCount === 0 ? (
            <Trans i18nKey="manage.reviews.noReviews" />
          ) : (
            <Trans
              i18nKey="manage.reviews.totalReviews"
              values={{ totalCount: pagination.totalCount ? pagination.totalCount : '' }}
            />
          )
        }
        body={<Trans i18nKey="manage.reviews.accessDisclaimer" components={{ b: <b /> }} />}
        loading={loadingCoachReviews}
        pagination={pagination}
        reviews={reviews}
        sortOrder={sortOrder}
        sortBy={sortBy}
        onSortOrderChange={setSortOrder}
        onSortByChange={setSortBy}
        pageSize={pageSize}
        onPageChange={setPage}
        onPageSizeChange={setPageSize}
        onReviewDelete={(review) =>
          setDeleteReviewModal({ open: true, review: review as GraphqlCoachReview })
        }
        cardProps={{ style: { maxWidth: 1100 } }}
      />
      <Modal
        danger
        width={350}
        open={deleteReviewModal.open}
        title={i18n.t('manage.reviews.deleteReviewModal.title') as string}
        confirmText={i18n.t('manage.reviews.deleteReviewModal.confirm') as string}
        cancelText={i18n.t('manage.reviews.deleteReviewModal.cancel') as string}
        confirmButtonProps={{ loading: isDeletingCoachReview }}
        cancelButtonProps={{ loading: isDeletingCoachReview }}
        onCancel={() => setDeleteReviewModal({ open: false })}
        onConfirm={async () => {
          await deleteCoachReview({
            variables: { reviewId: Number(deleteReviewModal?.review?.id) },
          });
          setDeleteReviewModal({ open: false });
          message.info(<Trans i18nKey="manage.reviews.deleteReviewModal.successMessage" />);
        }}
      >
        <Trans
          i18nKey="manage.reviews.deleteReviewModal.description"
          values={{ comment: deleteReviewModal.review?.comment }}
        />
      </Modal>
    </>
  );
}

export default CoachReviews;
