import { Trans } from 'react-i18next';

import {
  GraphqlServiceRequest,
  GraphqlCircleRequest,
  useListRequestCategoriesQuery,
} from '@graphql/generated';

import ROUTES from '@constants/routes';

import { Select } from 'antd';
import Link from '@components/Link';
import RecordDetailCard from '@components/Card/RecordDetailCard';

type ActivityVitalsCardProps = {
  isServiceRequest?: boolean | undefined;
  isCircleRequest?: boolean | undefined;
  activity: GraphqlServiceRequest | GraphqlCircleRequest;
  onEdit: (values: any) => void;
};

function ActivityVitalsCard({
  isServiceRequest,
  isCircleRequest,
  activity,
  onEdit,
}: ActivityVitalsCardProps) {
  const { data: activityCategories } = useListRequestCategoriesQuery({
    variables: {
      serviceRequest: isServiceRequest,
      circleRequest: isCircleRequest,
    },
  });

  return (
    <RecordDetailCard
      title={
        <Trans
          i18nKey="manage.serviceRequest.headers.id"
          values={{ id: String(activity?.id).padStart(5, '0') }}
        />
      }
      editable
      onEdit={onEdit}
      cardProps={{
        style: { minWidth: '350px' },
      }}
      fields={[
        {
          name: 'username',
          label: <Trans i18nKey="manage.serviceRequest.fields.user" />,
          value: (
            <Link to={ROUTES.MANAGE_USER.replace(':userId', String(activity?.RequestedBy?.id))}>
              {activity?.RequestedBy?.fullName}
            </Link>
          ),
        },
        {
          name: 'accountName',
          label: <Trans i18nKey="manage.serviceRequest.fields.account" />,
          value: (
            <Link
              to={ROUTES.MANAGE_CUSTOMER.replace(':customerId', String(activity?.Customer?.id))}
            >
              {activity?.Customer?.fullName}
            </Link>
          ),
        },
        {
          name: 'category',
          label: <Trans i18nKey="manage.serviceRequest.fields.category" />,
          value: activity?.category,
          render: () => <Trans i18nKey={`serviceTypes.${activity?.category}`} />,
          editable: true,
          formElement: (
            <Select
              style={{ minWidth: 200 }}
              showSearch
              optionFilterProp="label"
              options={activityCategories?.listRequestCategories?.map((category) => ({
                value: category.id,
                label: category.name,
              }))}
            />
          ),
        },
      ]}
    />
  );
}

ActivityVitalsCard.defaultProps = {
  isServiceRequest: undefined,
  isCircleRequest: undefined,
};

export default ActivityVitalsCard;
