import { css } from 'styled-components';

export const commonInputStyles = css`
  background-color: transparent;
  border-radius: 2px;
  border: 1px solid ${({ theme }) => theme.colors.foregroundSecondary};
  padding: 5px 12px;
  font-size: ${({ theme }) => theme.fontSizes.large};
  line-height: ${({ theme }) => theme.fontSizes.medium}; // large font-size, medium line-height?
`;
