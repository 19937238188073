export const FEATURE_FLAGS = {
  CREATE_AGENT_VERIFY_EMAIL: 'create-agent-verify-email',
  FACEBOOK_SSO: 'facebook-sso',
  GOOGLE_SSO: 'google-sso',
  MEMBERSHIP_FLOW: 'membership-flow',
  CONNECT_TAB_CONTACTS_BUTTON: 'connect-tab-contacts-button',
  INVITE_OTHERS_MEMBERSHIP_FLOW: 'invite-others-membership-flow',
  WHO_IS_THIS_FOR_ADD_INDIVIDUAL_BUTTON: 'who-is-this-for-add-individual-button',
  NOTIFICATIONS_SETTINGS_ENTRY_POINT: 'notification-settings-entry-point',
  ADD_ADDRESS_FOR_REQUEST_ENTRY_POINT: 'add-address-for-request-entry-point',
  REQUEST_RECURRING_INPUT: 'request-recurring-input',
  PROFILE_PAGE_LINK: 'profile-page-link',
  NEW_REQUEST_CHAT_PAGE_V2: 'new-request-chat-page-v2',
  CUSTOMER_ACTIVITY_CIRCLE_REQUEST_PAGE_V2: 'customer-activity-circle-request-page-v2',
  SERVICE_REQUEST: 'service-request',
  CIRCLE_REQUEST: 'circle-request',
};
