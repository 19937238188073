import { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import {
  AppointmentRequestStatus,
  GraphqlAppointmentRequest,
  ServicePaymentType,
  ServiceRequestAvailabilityInput,
  ServiceRequestTentativeSchedule,
  UpdateAppointmentRequestInput,
  useCreateAppointmentRequestPaymentMutation,
  useUpdateAppointmentRequestPaymentMutation,
} from '@graphql/generated';

import { DatePicker, Form, Select } from 'antd';
import Modal from '@components/Modal';
import Input from '@components/Input';
import AppointmentRequestStatusTag from '@components/Activities/AppointmentRequestStatusTag';

import dayjs, { Dayjs } from 'dayjs';
import { FormItem } from '@components/Form';
import { useDateWithTimezone } from '@hooks/useDateWithTimezone';

type AppointmentRequestStatusDropdownProps = {
  appointmentRequest: GraphqlAppointmentRequest;
  onChange: (updateAppointmentRequestInput: UpdateAppointmentRequestInput) => void;
};

function AppointmentRequestStatusDropdown({
  appointmentRequest,
  onChange,
}: AppointmentRequestStatusDropdownProps) {
  const i18n = useTranslation();
  const { formatAvailabilityInTimezone, transformDateToTimezone, transformToTimezone } =
    useDateWithTimezone();
  const [form] = Form.useForm();
  const [showScheduledAtModal, setShowScheduledAtModal] = useState<
    boolean | AppointmentRequestStatus
  >(false);

  // For now we will have only one payment type when assigning the agent to a service,
  // so we can get the first returned as the one created before to allow editing
  const initialPaymentType = appointmentRequest.AppointmentRequestPayments?.length
    ? appointmentRequest.AppointmentRequestPayments[0].type
    : undefined;
  const initialAmount = appointmentRequest.AppointmentRequestPayments?.length
    ? appointmentRequest.AppointmentRequestPayments[0].amount
    : undefined;

  const [createAppointmentRequestPayment] = useCreateAppointmentRequestPaymentMutation();
  const [updateAppointmentRequestPayment] = useUpdateAppointmentRequestPaymentMutation();

  const initialValues = {
    scheduledAt: appointmentRequest.scheduledAt
      ? transformToTimezone(appointmentRequest.scheduledAt)
      : undefined,
    amount: initialAmount,
    paymentType: initialPaymentType,
  };

  const tentativeSchedule =
    appointmentRequest?.CircleRequest?.tentativeSchedule ||
    appointmentRequest?.ServiceRequest?.tentativeSchedule;

  const availabilities =
    appointmentRequest?.CircleRequest?.availabilities ||
    appointmentRequest?.ServiceRequest?.availabilities ||
    [];

  let i18nTitleKey = 'flexible';
  if (availabilities?.length) {
    i18nTitleKey = 'within';
  }
  if (appointmentRequest?.scheduledAt) {
    i18nTitleKey = 'confirm';
  }

  let disabledDate = (current: Dayjs) => {
    return dayjs().add(-1, 'days') >= current;
  };

  if (tentativeSchedule === ServiceRequestTentativeSchedule.WithinDays) {
    disabledDate = (current: Dayjs) => {
      return dayjs().add(-1, 'days') >= current || dayjs().add(3, 'days') <= current;
    };
  } else if (tentativeSchedule === ServiceRequestTentativeSchedule.WithinWeeks) {
    disabledDate = (current: Dayjs) => {
      return dayjs().add(-1, 'days') >= current || dayjs().add(7, 'days') <= current;
    };
  }

  return (
    <>
      <Select
        value={appointmentRequest.status}
        style={{ width: '100%' }}
        onChange={(status) => {
          if (
            [
              AppointmentRequestStatus.Assigned,
              AppointmentRequestStatus.Accepted,
              AppointmentRequestStatus.Confirmed,
            ].includes(status)
          ) {
            setShowScheduledAtModal(status);
          } else {
            onChange({
              id: appointmentRequest.id,
              status,
              scheduledAt: initialValues.scheduledAt
                ? transformDateToTimezone(initialValues.scheduledAt)
                : null,
            });
          }
        }}
      >
        {Object.values(AppointmentRequestStatus).map((appointmentStatus) => {
          if (
            appointmentRequest?.circleRequestId &&
            (appointmentStatus === AppointmentRequestStatus.Assigned ||
              appointmentStatus === AppointmentRequestStatus.Confirmed ||
              appointmentStatus === AppointmentRequestStatus.NotChosen)
          ) {
            return null;
          }
          return (
            <Select.Option
              key={appointmentStatus}
              value={appointmentStatus}
              disabled={
                appointmentRequest?.circleRequestId &&
                appointmentStatus === AppointmentRequestStatus.NotChosen
              }
            >
              <AppointmentRequestStatusTag status={appointmentStatus} />
            </Select.Option>
          );
        })}
      </Select>
      {showScheduledAtModal && (
        <Modal
          open
          title={<Trans i18nKey={`manage.appointmentRequest.availabilities.${i18nTitleKey}`} />}
          onCancel={() => setShowScheduledAtModal(false)}
          confirmButtonProps={{ form: `appointment-request-${appointmentRequest.id}-form` }}
        >
          <Form
            name={`appointment-request-${appointmentRequest.id}-form`}
            form={form}
            initialValues={initialValues}
            onFinish={async (values) => {
              try {
                // We only have payment info for service requests
                if (appointmentRequest?.serviceRequestId) {
                  if (!initialPaymentType) {
                    await createAppointmentRequestPayment({
                      variables: {
                        appointmentPaymentInput: {
                          amount: values?.amount,
                          type: values?.paymentType,
                          appointmentRequestId: appointmentRequest.id,
                        },
                      },
                    });
                  } else if (
                    (values.paymentType !== initialPaymentType ||
                      values.amount !== initialAmount) &&
                    appointmentRequest.AppointmentRequestPayments?.length
                  ) {
                    // TODO: Only call this if changes were made
                    await updateAppointmentRequestPayment({
                      variables: {
                        appointmentPaymentInput: {
                          amount: values?.amount,
                          type: values?.paymentType,
                        },
                        appointmentRequestPaymentId:
                          appointmentRequest.AppointmentRequestPayments[0].id,
                      },
                    });
                  }
                }

                await onChange({
                  id: appointmentRequest.id,
                  status: showScheduledAtModal as AppointmentRequestStatus,
                  scheduledAt: values?.scheduledAt
                    ? transformDateToTimezone(values.scheduledAt)
                    : null,
                });
                setShowScheduledAtModal(false);
              } catch (e) {
                // do nothing with error
              }
            }}
          >
            {(availabilities?.length || tentativeSchedule) && (
              <ul>
                {tentativeSchedule && (
                  <li>
                    <Trans i18nKey={`tentativeSchedules.${tentativeSchedule}`} />
                  </li>
                )}
                {availabilities.map((availability: ServiceRequestAvailabilityInput) => (
                  <li>{formatAvailabilityInTimezone(availability)}</li>
                ))}
              </ul>
            )}
            <Form.Item name="scheduledAt">
              <DatePicker
                style={{ width: '100%', margin: '20px 0px 10px' }}
                use12Hours
                showTime
                changeOnBlur
                disabledDate={disabledDate}
                // TODO: For v1, we format to the Dallas timezone
                // It just hardcodes "CDT" in the render
                format={(date) => date.format('MMMM DD, YYYY @ h:mma [EST]')}
              />
            </Form.Item>
            {appointmentRequest?.serviceRequestId && (
              <>
                <FormItem
                  name="paymentType"
                  label={i18n.t('forms.input.paymentType.label')}
                  rules={[
                    {
                      required: true,
                      message: i18n.t('forms.input.paymentType.errorMessage') as string,
                      whitespace: true,
                    },
                  ]}
                >
                  <Select
                    placeholder={String(i18n.t('forms.input.paymentType.placeholder'))}
                    options={Object.values(ServicePaymentType).map((type) => ({
                      label: type.charAt(0).toUpperCase() + type.slice(1),
                      value: type,
                    }))}
                  />
                </FormItem>
                <FormItem
                  name="amount"
                  label={i18n.t('forms.input.amount.label')}
                  rules={[
                    {
                      required: true,
                      message: i18n.t('forms.input.amount.errorMessage') as string,
                    },
                  ]}
                >
                  <Input
                    placeholder={i18n.t('forms.input.amount.placeholder') as string}
                    type="number"
                  />
                </FormItem>
              </>
            )}
          </Form>
        </Modal>
      )}
    </>
  );
}

export default AppointmentRequestStatusDropdown;
