import { useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Trans, useTranslation } from 'react-i18next';
import { GET_CIRCLE_REQUEST_QUERY } from '@graphql/circleRequests';
import { GET_APPOINTMENT_REQUESTS_QUERY, GET_ACTIVITY_TIMELINE_QUERY } from '@graphql/activities';
import {
  GraphqlInternalNote,
  GraphqlCircleRequest,
  ServiceRequestStatus,
  SortOrder,
  useGetCircleRequestQuery,
  useGetActivityTimelineQuery,
  useUpdateCircleRequestMutation,
} from '@graphql/generated';
import { ApolloError } from '@apollo/client';

import ROUTES from '@constants/routes';
import { ActivitiesTypes } from '@constants/activities';
import dayjs from 'dayjs';

import { Timeline, Typography } from 'antd';
import Masonry, { ResponsiveMasonry } from 'react-responsive-masonry';
import Link from '@components/Link';
import Modal from '@components/Modal';
import Button from '@components/Button';
import Card from '@components/Card/Card';
import Loading from '@components/Loading';
import GenericApolloError from '@components/GenericApolloError';
import AddEditNoteModal from '@components/Activities/AddEditNoteModal';
import ActivityVitalsCard from '@components/Activities/ActivityVitalsCard';
import ActivityStatusCard from '@components/Activities/ActivityStatusCard';
import ActivityDetailCard from '@components/Activities/ActivityDetailCard';
import ServiceRequestAgents from '@components/Activities/ServiceRequestAgents';
import AppointmentRequestsCard from '@components/Activities/AppointmentRequestsCard';
import { FEATURE_FLAGS } from '@constants/featureFlags';
import { useIsFeatureEnabled } from '@hooks/useIsFeatureEnabled';

function CircleRequest() {
  const i18n = useTranslation();
  const [agentModalIsOpen, setAgentModalIsOpen] = useState<boolean>(false);
  const [noteModalIsOpen, setNoteModalIsOpen] = useState<boolean | GraphqlInternalNote>(false);
  const [showConfirmAppointmentRequest, setShowConfirmAppointmentRequest] =
    useState<boolean>(false);

  const { activityId } = useParams<{ activityId: string }>();
  const {
    data: circleRequestData,
    loading: circleRequestLoading,
    error: circleRequestError,
  } = useGetCircleRequestQuery({
    variables: { getCircleRequestId: parseInt(activityId as string, 10) },
  });
  const {
    data: activityTimelineData,
    loading: activityTimelineLoading,
    error: activityTimelineError,
  } = useGetActivityTimelineQuery({
    variables: {
      orderIn: 'asc' as SortOrder,
      activityId: parseInt(activityId as string, 10),
    },
  });
  const [updateCircleRequestMutation] = useUpdateCircleRequestMutation({
    refetchQueries: [
      GET_CIRCLE_REQUEST_QUERY,
      GET_APPOINTMENT_REQUESTS_QUERY,
      GET_ACTIVITY_TIMELINE_QUERY,
    ],
  });

  const circleRequest = useMemo(() => {
    return circleRequestData?.getCircleRequest || ({} as GraphqlCircleRequest);
  }, [circleRequestData]);

  const timelineItems = activityTimelineData?.getTimeline?.map((timelineItem) => ({
    label: `${dayjs(timelineItem.createdAt).format('MM/DD/YY')} (${dayjs(
      timelineItem.createdAt,
    ).fromNow()})`,
    children: <div>{timelineItem.description}</div>,
  }));

  const onEdit = useMemo(
    () => async (values: any) => {
      await updateCircleRequestMutation({
        variables: {
          updateCircleRequestId: circleRequest.id,
          data: values,
        },
      });
      setShowConfirmAppointmentRequest(true);
    },
    [circleRequest.id, updateCircleRequestMutation],
  );

  const serviceRequestEnabled = useIsFeatureEnabled(FEATURE_FLAGS.SERVICE_REQUEST);

  if (circleRequestLoading || activityTimelineLoading) {
    return <Loading />;
  }

  if (circleRequestError || activityTimelineError) {
    return (
      <GenericApolloError error={(circleRequestError || activityTimelineError) as ApolloError} />
    );
  }

  return (
    <div style={{ maxWidth: 1800, margin: '0 auto' }}>
      <Typography.Title
        level={2}
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
        }}
      >
        <Trans
          i18nKey="manage.circleRequest.headers.id"
          values={{ id: String(circleRequest?.id).padStart(5, '0') }}
        />
        {circleRequest?.status !== ServiceRequestStatus.Inactive &&
          circleRequest?.status !== ServiceRequestStatus.Completed &&
          serviceRequestEnabled && (
            <Link to={ROUTES.MANAGE_SERVICE_REQUEST_CREATE} state={circleRequest}>
              <Button>
                <Trans i18nKey="manage.circleRequests.convert.new" />
              </Button>
            </Link>
          )}
      </Typography.Title>
      <ResponsiveMasonry columnsCountBreakPoints={{ 350: 1, 1000: 2, 1750: 3 }}>
        <Masonry gutter="20px">
          <ActivityVitalsCard
            isCircleRequest
            activity={circleRequest as GraphqlCircleRequest}
            onEdit={onEdit}
          />
          <ActivityStatusCard activity={circleRequest as GraphqlCircleRequest} onEdit={onEdit} />

          {!!timelineItems?.length && (
            <Card title={i18n.t('manage.serviceRequest.headers.timeline')}>
              <div>
                <Timeline mode="left" items={timelineItems} />
              </div>
            </Card>
          )}

          <ActivityDetailCard
            activity={circleRequest as GraphqlCircleRequest}
            onEdit={async (values) => {
              await onEdit({
                ...values,
                scheduledAt: dayjs(values.scheduledAt).toDate(),
              });
              setShowConfirmAppointmentRequest(true);
            }}
          />

          <AppointmentRequestsCard
            type={ActivitiesTypes.CIRCLE_REQUEST}
            circleRequestId={parseInt(activityId as string, 10)}
          />
        </Masonry>
      </ResponsiveMasonry>
      <Modal
        width="max-content"
        closable
        onCancel={() => setAgentModalIsOpen(!agentModalIsOpen)}
        open={agentModalIsOpen}
        title={
          <Typography.Title level={2}>
            <Trans i18nKey="forms.sendToAgent.title" />
          </Typography.Title>
        }
      >
        <ServiceRequestAgents serviceRequestId={circleRequest?.id as number} />
      </Modal>
      {!!noteModalIsOpen && (
        <AddEditNoteModal
          onCancel={() => setNoteModalIsOpen(!noteModalIsOpen)}
          note={noteModalIsOpen as GraphqlInternalNote}
        />
      )}
      {!!showConfirmAppointmentRequest && (
        <Modal
          width={750}
          title={i18n.t('manage.serviceRequest.headers.appointmentRequests.update')}
          cancelText={i18n.t('manage.serviceRequest.headers.appointmentRequests.done') as string}
          onCancel={() => setShowConfirmAppointmentRequest(false)}
          open
        >
          <AppointmentRequestsCard
            type={ActivitiesTypes.CIRCLE_REQUEST}
            circleRequestId={parseInt(activityId as string, 10)}
          />
        </Modal>
      )}
    </div>
  );
}

export default CircleRequest;
