import GenericApolloError from '@components/GenericApolloError';
import { useGetConversationByIdQuery } from '@graphql/generated';
import { Spin } from 'antd';
import { useState } from 'react';
import styled from 'styled-components';

interface ChatHistoryProps {
  conversationId: string;
  style?: React.CSSProperties;
  width?: string | number;
  height?: string | number;
}

const CenteredConteiner = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export function ChatHistory({ conversationId, style, width, height }: ChatHistoryProps) {
  const {
    data: getConversationQuery,
    loading: loadingConversationQuery,
    error,
  } = useGetConversationByIdQuery({
    variables: { conversationId },
  });

  const [isLoading, setIsLoading] = useState(loadingConversationQuery);

  if (error) return <GenericApolloError error={error} />;

  return (
    <CenteredConteiner style={{ width, height }}>
      {isLoading ? <Spin style={{ zIndex: 1 }} /> : null}
      <iframe
        style={{ ...style, position: 'absolute', height, width }}
        onLoadCapture={() => setIsLoading(false)}
        title={`Chat History for ${conversationId}`}
        src={getConversationQuery?.getConversationSignature?.embedUrl}
      />
    </CenteredConteiner>
  );
}

ChatHistory.defaultProps = {
  width: '100%',
  height: 300,
  style: {
    width: '100%',
    border: 0,
  },
};
