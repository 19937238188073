import { Table as AntdTable } from 'antd';
import styled from 'styled-components';

export const DEFAULT_PAGE_SIZE = 10;
export const PAGE_SIZES = [5, 10, 20, 50, 100];

const Table: typeof AntdTable = styled(AntdTable)`
  a {
    line-height: inherit;
  }

  .disabled-row {
    opacity: 0.4;
  }
`;

export default Table;
