import {
  CloseOutlined,
  DeleteOutlined,
  PlusOutlined,
  SaveOutlined,
  HomeOutlined,
  TagOutlined,
} from '@ant-design/icons';
import { Button, Space as AntdSpace, message } from 'antd';
import { Trans, useTranslation, UseTranslationResponse } from 'react-i18next';
import { useState } from 'react';
import styled from 'styled-components';

import DefaultCard from '@components/Card/Card';
import {
  GetCustomerQuery,
  useCreateAddressMutation,
  useDeleteAddressMutation,
  useUpdateAddressMutation,
} from '@graphql/generated';
import RecordDetailCard, { RecordField } from '@components/Card/RecordDetailCard';
import { GET_CUSTOMER_QUERY } from '@graphql/users';
import Tag from '@components/Tag';

const Card = styled(DefaultCard)`
  .ant-card-body {
    padding: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

const HomeButton = styled(Button)`
  padding: 4px 4px;
`;

const Space = styled(AntdSpace)`
  .ant-space-item {
    width: 100%;
  }
`;

export interface AddressesFormProps {
  addressLine1: string;
  addressLine2?: string;
  city: string;
  state: string;
  zipCode: string;
}

export interface ICustomerAddressesCard {
  addresses?: GetCustomerQuery['getCustomer']['addresses'];
  customerId: number;
}

type ArrayElement<ArrayType extends readonly unknown[] | undefined | null> =
  ArrayType extends readonly (infer ElementType)[] ? ElementType : never;

type IAddress = ArrayElement<GetCustomerQuery['getCustomer']['addresses']>;

const getAddressFields = (
  i18n: UseTranslationResponse<'translation', undefined>,
  address?: IAddress,
  overrideProps?: Partial<RecordField>,
) => {
  return [
    {
      name: 'addressLine1',
      label: <Trans i18nKey="manage.customer.addresses.addressLine" />,
      value: address?.addressLine1 || '',
      editable: true,
      autoFocus: true,
      formItemProps: {
        validateTrigger: 'onSubmit',
        required: true,
        rules: [
          {
            required: true,
            message: i18n.t('forms.input.addressLine1.errorMessage') as string,
            whitespace: true,
          },
        ],
      },
      ...overrideProps,
    },
    {
      name: 'addressLine2',
      label: <Trans i18nKey="manage.customer.addresses.addressLine2" />,
      value: address?.addressLine2 || '',
      valueFallback: '',
      editable: true,
      ...overrideProps,
    },
    {
      name: 'city',
      label: <Trans i18nKey="manage.customer.addresses.city" />,
      value: address?.city || '',
      editable: true,
      formItemProps: {
        validateTrigger: 'onSubmit',
        required: true,
        rules: [
          {
            required: true,
            message: i18n.t('forms.input.city.errorMessage') as string,
            whitespace: true,
          },
        ],
      },
      ...overrideProps,
    },
    {
      name: 'state',
      label: <Trans i18nKey="manage.customer.addresses.state" />,
      value: address?.state || '',
      editable: true,
      formItemProps: {
        validateTrigger: 'onSubmit',
        required: true,
        rules: [
          {
            required: true,
            message: i18n.t('forms.input.state.errorMessage') as string,
            whitespace: true,
          },
        ],
      },
      ...overrideProps,
    },
    {
      name: 'zipCode',
      label: <Trans i18nKey="manage.customer.addresses.zipCode" />,
      value: address?.zipCode || '',
      editable: true,
      formItemProps: {
        validateTrigger: 'onSubmit',
        required: true,
        rules: [
          {
            required: true,
            message: i18n.t('forms.input.zipCode.errorMessage') as string,
            whitespace: true,
            len: 5,
            pattern: /^\d{5}(?:[-\s]\d{4})?$/,
          },
        ],
      },
      ...overrideProps,
    },
  ];
};

function CustomerAddressesCard({ addresses = [], customerId }: ICustomerAddressesCard) {
  const i18n = useTranslation();
  const [showCreateAddressCard, setShowCreateAddressCard] = useState(false);

  const [createAddressMutation, { loading: createAddressLoading }] = useCreateAddressMutation({
    refetchQueries: [GET_CUSTOMER_QUERY],
  });
  const [updateAddressMutation, { loading: updateAddressLoading }] = useUpdateAddressMutation({
    refetchQueries: [GET_CUSTOMER_QUERY],
  });
  const [deleteAddressMutation, { loading: deleteAddressLoading }] = useDeleteAddressMutation({
    refetchQueries: [GET_CUSTOMER_QUERY],
  });

  const onCreateAddress = async (values: AddressesFormProps) => {
    await createAddressMutation({
      // TODO: For now, we always create the address by hand so we set isFromAPI to false
      variables: {
        createAddressInput: { ...values, customerId, country: 'US', isFromAPI: false },
      },
    });
    message.success(<Trans i18nKey="forms.createAddress.successMessage" />);
  };

  const onEdit = async (id: number, values: AddressesFormProps) => {
    await updateAddressMutation({
      variables: { updateAddressInput: values, updateAddressId: id },
    });
    message.success(<Trans i18nKey="forms.editAddress.successMessage" />);
  };

  const onSetDefaultAddress = async (id: number) => {
    await updateAddressMutation({
      variables: { updateAddressInput: { isDefault: true }, updateAddressId: id },
    });
    message.success(<Trans i18nKey="forms.editAddress.successDefaultAddress" />);
  };

  const onDelete = async (id: number) => {
    await deleteAddressMutation({
      variables: { deleteAddressId: id },
    });
    message.success(<Trans i18nKey="forms.deleteAddress.successMessage" />);
  };

  const sortedAddresses = [...(addresses || [])]?.sort((a) => (!a.isDefault ? 1 : -1));

  return (
    <Card title={<Trans i18nKey="manage.customer.addresses.title" />} style={{ minWidth: '350px' }}>
      <Space size="large" wrap direction="vertical" align="center" style={{ width: '100%' }}>
        {sortedAddresses?.map((address) => {
          const additionalActions = [
            <Button
              disabled={deleteAddressLoading}
              type="text"
              onClick={() => {
                onDelete(address.id);
              }}
            >
              <Space size="small">
                <DeleteOutlined />
                <Trans i18nKey="forms.deleteAddress.delete" />
              </Space>
            </Button>,
          ];

          if (!address.isDefault) {
            additionalActions.push(
              <HomeButton
                disabled={deleteAddressLoading}
                type="text"
                onClick={() => {
                  onSetDefaultAddress(address.id);
                }}
              >
                <Space size="small">
                  <HomeOutlined />
                  <Trans i18nKey="forms.editAddress.home" />
                </Space>
              </HomeButton>,
            );
          }

          return (
            <RecordDetailCard
              additionalActions={additionalActions}
              key={address.id}
              cardProps={{
                style: { minWidth: '350px', width: '100%', boxShadow: 'none' },
                loading: updateAddressLoading,
              }}
              editable
              onEdit={(values) => {
                onEdit(address.id, values);
              }}
              fields={[
                ...getAddressFields(i18n, address),
                {
                  name: 'isDefault',
                  label: <Trans i18nKey="manage.customer.addresses.defaultAddress" />,
                  value: address.isDefault ? (
                    <Tag icon={<HomeOutlined />} color="success">
                      <Trans i18nKey="manage.customer.addresses.home" />
                    </Tag>
                  ) : (
                    <Tag icon={<TagOutlined />} color="blue">
                      <Trans i18nKey="manage.customer.addresses.other" />
                    </Tag>
                  ),
                },
              ]}
            />
          );
        })}
        {showCreateAddressCard && (
          <RecordDetailCard
            cardProps={{
              actions: [
                <Button
                  type="text"
                  htmlType="reset"
                  onClick={() => setShowCreateAddressCard(false)}
                >
                  <Space size="small">
                    <CloseOutlined key="cancel" />
                    <Trans i18nKey="forms.recordDetailCard.cancel" />
                  </Space>
                </Button>,
                <Button type="text" htmlType="submit">
                  <Space size="small">
                    <SaveOutlined key="save" />
                    <Trans i18nKey="forms.recordDetailCard.save" />
                  </Space>
                </Button>,
              ],
              style: { minWidth: '350px', boxShadow: 'none' },
              loading: createAddressLoading,
            }}
            defaultEditing
            editable
            onEdit={async (values) => {
              await onCreateAddress(values);
              setShowCreateAddressCard(false);
            }}
            fields={getAddressFields(i18n, undefined, { valueFallback: '' })}
          />
        )}
        <Button
          disabled={createAddressLoading || showCreateAddressCard}
          type="text"
          onClick={() => {
            setShowCreateAddressCard(true);
          }}
          style={{ textAlign: 'center' }}
        >
          <Space size="small" align="center">
            <PlusOutlined />
            <Trans i18nKey="forms.createAddress.create" />
          </Space>
        </Button>
      </Space>
    </Card>
  );
}

CustomerAddressesCard.defaultProps = {
  addresses: [],
};

export default CustomerAddressesCard;
