import { GraphqlAddresses } from '@graphql/generated';
import { Typography } from 'antd';

export const stringifyAddress = (address: GraphqlAddresses): string | undefined => {
  if (typeof address !== 'object' || address === null) {
    return undefined;
  }
  let stringAddress = address?.place ? `${address?.place}, ` : '';

  if (address?.addressLine1) {
    stringAddress += `${address?.addressLine1}`;
  }

  if (address?.addressLine2) {
    stringAddress += `, ${address?.addressLine2}`;
  }

  if (address?.city && address?.state) {
    stringAddress += `, ${address?.city}, ${address?.state}`;
  }

  if (address?.zipCode) {
    stringAddress += `  ${address?.zipCode}`;
  }

  return stringAddress;
};

export const formatAddressBlock = (address: GraphqlAddresses): JSX.Element => {
  return (
    <div style={{ textAlign: 'right' }}>
      {address?.place && <Typography.Text strong>{address?.place}</Typography.Text>}
      <div>{address?.addressLine1}</div>
      {address?.addressLine2 && <div>{address?.addressLine2}</div>}
      {address?.city && address?.state && (
        <div>
          {address?.city}, {address?.state}
        </div>
      )}
      {address?.zipCode && <div>{address?.zipCode}</div>}
    </div>
  );
};

export const getGraphqlAddressFromGooglePlaces = (placesAddress: any) => {
  function findAddressDetailPart(partName: string) {
    return placesAddress.address_components.find((addressComponent: any) =>
      addressComponent?.types?.includes(partName),
    );
  }

  const addressLine1a = findAddressDetailPart('street_number')?.long_name || '';
  const addressLine1b = findAddressDetailPart('route')?.short_name || '';

  return {
    addressLine1: `${addressLine1a} ${addressLine1b}`.trim(),
    addressLine2: findAddressDetailPart('subpremise')?.long_name,
    city: findAddressDetailPart('locality')?.long_name,
    state: findAddressDetailPart('administrative_area_level_1')?.short_name,
    zipCode: findAddressDetailPart('postal_code')?.long_name,
    country: findAddressDetailPart('country')?.short_name,
    place:
      placesAddress?.name !== `${addressLine1a} ${addressLine1b}` ? placesAddress?.name : undefined,
    isFromAPI: true,
  };
};
