import { gql } from '@apollo/client';

export const GET_SIGNATURE_QUERY = gql`
  query GetSignature {
    getSignature {
      signature
      talkJsAppId
      talkJsId
    }
  }
`;

export const GET_USER_SIGNATURE_QUERY = gql`
  query GetUserSignature($userId: Int!) {
    getUserSignature(userId: $userId) {
      signature
      talkJsAppId
      talkJsId
    }
  }
`;

export const GET_CONVERSATION_EMBED_QUERY = gql`
  query GetConversationById($conversationId: String!) {
    getConversationSignature(conversationId: $conversationId) {
      embedUrl
    }
  }
`;

export const GET_CONVERSATIONS = gql`
  query GetConversations(
    $filter: GetConversationsFilter
    $pageSize: Int
    $startingAfter: String
    $sort: GetConversationsSort
  ) {
    getConversations(
      filter: $filter
      pageSize: $pageSize
      startingAfter: $startingAfter
      sort: $sort
    ) {
      conversations {
        createdAt
        custom {
          customerId
          participants
          serviceRequestId
          userIds
          zipCode
          chatType
          city
          environment
          createdBy
          role
        }
        id
        lastMessage {
          attachment {
            url
            size
          }
          conversationId
          createdAt
          editedAt
          id
          location
          origin
          readBy
          referencedMessageId
          senderId
          text
          type
        }
        participants {
          access
          id
          notify
        }
        photoUrl
        subject
        topicId
        welcomeMessages
        Participants {
          id
          fullName
          role
          createdAt
          updatedAt
        }
        CreatedBy {
          id
          fullName
          role
          createdAt
          updatedAt
        }
      }
    }
  }
`;

export const GET_CONVERSATION = gql`
  query GetConversation($conversationId: String!) {
    getConversation(conversationId: $conversationId) {
      id
      subject
      topicId
      photoUrl
      welcomeMessages
      custom {
        customerId
        participants
        userIds
        serviceRequestId
        chatType
        city
        zipCode
        createdBy
        role
      }
      lastMessage {
        conversationId
        createdAt
        editedAt
        id
        location
        origin
        readBy
        senderId
        text
        type
        referencedMessageId
      }
      participants {
        id
        access
        notify
      }
      createdAt
      CreatedBy {
        id
        email
        fullName
        talkJsId
        role
      }
      Participants {
        id
        email
        fullName
        talkJsId
        role
      }
    }
  }
`;

export const JOIN_USER_TO_CONVERSATION_MUTATION = gql`
  mutation JoinUserToConversation($userId: Int!, $conversationId: String!) {
    joinUserToConversation(userId: $userId, conversationId: $conversationId) {
      id
    }
  }
`;

export const REMOVE_USER_FROM_CONVERSATION_MUTATION = gql`
  mutation RemoveParticipant($talkJsId: String!, $conversationId: String!) {
    removeParticipant(talkJsId: $talkJsId, conversationId: $conversationId) {
      id
    }
  }
`;

export const DELETE_CONVERSATION = gql`
  mutation DeleteConversation($conversationId: String!) {
    deleteConversation(conversationId: $conversationId) {
      success
      conversationId
    }
  }
`;

export const GET_CONVERSATIONS_BY_CUSTOMER_ID_QUERY = gql`
  query GetConversationsByCustomer($customerId: Int!) {
    getConversationsByCustomer(customerId: $customerId) {
      id
      createdAt
      lastMessage {
        attachment {
          size
          url
        }
        conversationId
        createdAt
        editedAt
        id
        location
        origin
        readBy
        referencedMessageId
        senderId
        type
        text
      }
      custom {
        customerId
        participants
        userIds
      }
      participants {
        access
        id
        notify
      }
      photoUrl
      subject
      topicId
      welcomeMessages
    }
  }
`;

export const START_CONVERSATION_MUTATION = gql`
  mutation StartConversation($input: StartConversationInput!) {
    startConversation(startConversationInput: $input) {
      id
      subject
      topicId
      photoUrl
      welcomeMessages
      custom {
        customerId
        participants
        userIds
        serviceRequestId
        zipCode
        chatType
        city
        role
      }
      lastMessage {
        text
      }
      participants {
        id
        access
      }
      createdAt
    }
  }
`;

export const SYNC_CUSTOMER_CHAT = gql`
  mutation SyncCustomerChat($customerId: Float!) {
    syncCustomerChat(customerId: $customerId) {
      success
    }
  }
`;

export const GET_FLAGGED_MESSAGES = gql`
  query GetFlaggedMessages {
    getFlaggedMessages {
      id
      messageId
      conversationId
      messageBody
      userId
      User {
        fullName
      }
    }
  }
`;
