import { WarningOutlined } from '@ant-design/icons';
import { Button, Empty } from 'antd';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

export function EmptyUnauthorized() {
  const i18n = useTranslation();
  const navigate = useNavigate();

  return (
    <Empty
      description={i18n.t('errors.forbiddenPage')}
      image={<WarningOutlined style={{ fontSize: '50px', height: 'inherit' }} />}
      style={{ marginTop: 20 }}
    >
      <Button
        onClick={() => {
          navigate(-1);
        }}
      >
        {i18n.t('cta.goBack')}
      </Button>
    </Empty>
  );
}

export default EmptyUnauthorized;
