import GenericApolloError from '@components/GenericApolloError';
import Table from '@components/Table/Table';
import ROUTES from '@constants/routes';
import { GetFlaggedMessagesQuery, useGetFlaggedMessagesQuery } from '@graphql/generated';
import { Row, Space, Typography } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { useMemo } from 'react';
import { Trans } from 'react-i18next';
import { Link } from 'react-router-dom';

type FlaggedMessage = GetFlaggedMessagesQuery['getFlaggedMessages'][number];

function FlaggedMessages() {
  const { data: flaggedData, error, loading } = useGetFlaggedMessagesQuery();

  const data = flaggedData?.getFlaggedMessages || [];

  const columns: ColumnsType<FlaggedMessage> = useMemo(
    () => [
      {
        title: <Trans i18nKey="manage.flaggedMessages.table.columns.conversationId" />,
        fixed: 'left',
        width: 300,
        ellipsis: true,
        render: (_: any, record: FlaggedMessage) => (
          <Link
            to={ROUTES.MANAGE_COMMUNITY_CONVERSATION_DETAIL.replace(
              ':conversationId',
              record.conversationId,
            )}
          >
            {record.conversationId}
          </Link>
        ),
      },
      {
        title: <Trans i18nKey="manage.flaggedMessages.table.columns.messageId" />,
        dataIndex: 'messageId',
      },
      {
        title: <Trans i18nKey="manage.flaggedMessages.table.columns.messageBody" />,
        dataIndex: 'messageBody',
      },
      {
        title: <Trans i18nKey="manage.flaggedMessages.table.columns.userName" />,
        render: (_: any, record: FlaggedMessage) => record.User.fullName,
      },
    ],
    [],
  );

  if (error) return <GenericApolloError error={error} />;

  return (
    <Space direction="vertical" size="large" style={{ width: '100%' }}>
      <Row justify="space-between" align="middle" style={{ marginBottom: '20px' }}>
        <Typography.Title level={2}>
          <Trans i18nKey="manage.flaggedMessages.title" />
        </Typography.Title>
      </Row>
      <Table
        scroll={{ x: 'auto' }}
        loading={loading}
        columns={columns}
        dataSource={data.map((conversation) => ({
          ...conversation,
          key: conversation.id,
        }))}
        pagination={false}
      />
    </Space>
  );
}

export default FlaggedMessages;
