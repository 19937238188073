import { FormItem } from '@components/Form';
import { InputProps } from 'antd';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Input } from './Input';

type InputEmailProps = InputProps & {
  label?: string;
};

// Src: https://stackoverflow.com/questions/201323/how-can-i-validate-an-email-address-using-a-regular-expression
const EMAIL_REGEX =
  // eslint-disable-next-line no-control-regex
  /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/;

export function InputEmail({ placeholder, label, ...inputProps }: InputEmailProps) {
  const i18n = useTranslation();
  const [emailValidateTrigger, setEmailValidateTrigger] = useState('onBlur');
  const invalidEmailError = useMemo(
    () => new Error(i18n.t('forms.input.email.errorMessage') as string),
    [i18n],
  );

  return (
    <FormItem
      label={label}
      name="email"
      validateTrigger={emailValidateTrigger}
      rules={[
        {
          required: true,
          validator: (_rule, email) => {
            const emailToValidate = email || '';
            if (EMAIL_REGEX.test(emailToValidate)) {
              return Promise.resolve();
            }
            return Promise.reject(invalidEmailError);
          },
        },
      ]}
    >
      <Input
        type="email"
        name="email"
        autoComplete="email"
        placeholder={placeholder}
        onBlur={() => setEmailValidateTrigger('onChange')}
        {...inputProps}
      />
    </FormItem>
  );
}

InputEmail.defaultProps = {
  label: 'Email',
};

export default InputEmail;
