import { FormItem } from '@components/Form';
import Input from '@components/Input';
import Button from '@components/Button';
import { Divider, Form, Radio, Space, Button as BaseButton, Row, Spin } from 'antd';
import { Trans, useTranslation } from 'react-i18next';
import {
  ChatTypeOptions,
  GlobalRole,
  GraphqlUser,
  SortOrder,
  UserFilter,
  UserSortableFields,
  useGetUsersQuery,
} from '@graphql/generated';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { CheckSquareOutlined, CloseOutlined } from '@ant-design/icons';
import { Select } from '@components/Input/Select';
import { DEFAULT_SEARCH_DEBOUNCE_TIME } from '@constants/input';

export interface ChatFormValues {
  chatType: ChatTypeOptions;
  chatName: string;
  chatParticipantsRole: GlobalRole;
  city: string;
  zipCode: string;
  chatParticipants: string[];
}

type ChatFormProps = {
  loading: boolean;
  onSubmit: (values: ChatFormValues) => Promise<void>;
  submitLabel: string | JSX.Element;
  initialFormValues?: ChatFormValues;
};

const MIN_CHAT_PARTICIPANTS = 2;

export function ChatForm({ onSubmit, submitLabel, loading, initialFormValues }: ChatFormProps) {
  const [form] = Form.useForm();
  const i18n = useTranslation();
  const invalidChatParticipants = useMemo(
    () => new Error(i18n.t('forms.input.chatParticipants.errorMessage') as string),
    [i18n],
  );
  const [searchUserText, setSearchUserText] = useState('');
  const [filters, setFilters] = useState<UserFilter>({
    text: searchUserText,
    roles: [GlobalRole.Coach, GlobalRole.FamilyPartner, GlobalRole.SeniorMember],
  });
  const {
    data: getUsersQuery,
    previousData,
    loading: isFetchingUsers,
  } = useGetUsersQuery({
    variables: {
      filter: filters,
      page: 1,
      pageSize: 100,
      sort: { field: UserSortableFields.FullName, direction: SortOrder.Asc },
    },
  });
  const [isCommunityChat, setIsCommunityChat] = useState<boolean>(false);
  const [isPartcipantsDropdownOpen, setIsPartcipantsDropdownOpen] = useState<boolean>(false);

  const users = ((getUsersQuery || previousData)?.getUsers.users as GraphqlUser[]) || [];

  useEffect(() => {
    const timer = setTimeout(() => {
      setFilters((previousFilters) => ({ ...previousFilters, text: searchUserText }));
    }, DEFAULT_SEARCH_DEBOUNCE_TIME);
    return () => clearTimeout(timer);
  }, [searchUserText]);

  const clearParticipantsSelect = useCallback(() => {
    form.setFieldValue('chatParticipants', []);
    form.validateFields(['chatParticipants']);
  }, [form]);

  return (
    <Form
      name="chat-form"
      autoComplete="off"
      form={form}
      initialValues={initialFormValues}
      onFinish={async (values) => {
        await onSubmit(values);
        form.resetFields();
      }}
    >
      <FormItem
        label={<Trans i18nKey="forms.input.chatType.label" />}
        name="chatType"
        rules={[{ required: true, message: <Trans i18nKey="forms.input.chatType.errorMessage" /> }]}
      >
        <Radio.Group
          onChange={(e) => {
            const isSocial = e.target.value === ChatTypeOptions.Social;
            setIsCommunityChat(e.target.value === ChatTypeOptions.Community);
            clearParticipantsSelect();
            if (isSocial) {
              form.setFieldValue('chatParticipantsRole', GlobalRole.SeniorMember);
              // NOTE: Social chats are only for senior members
              setFilters((prevFilters) => ({
                ...prevFilters,
                roles: [GlobalRole.SeniorMember, GlobalRole.Coach],
              }));
            }
          }}
        >
          <Space direction="horizontal">
            {Object.values(ChatTypeOptions).map((chatType) => (
              <Radio key={chatType} value={chatType}>
                <Trans i18nKey={`chatTypeOptions.${chatType}`} />
              </Radio>
            ))}
          </Space>
        </Radio.Group>
      </FormItem>

      <FormItem
        label={<Trans i18nKey="forms.input.chatParticipantsRole.label" />}
        name="chatParticipantsRole"
        hidden={!isCommunityChat}
        rules={[
          {
            required: isCommunityChat,
            message: <Trans i18nKey="forms.input.chatParticipantsRole.errorMessage" />,
          },
        ]}
      >
        <Radio.Group
          onChange={(e) => {
            clearParticipantsSelect();
            setFilters((prevFilters) => ({
              ...prevFilters,
              roles: [e.target.value, GlobalRole.Coach],
            }));
          }}
        >
          <Space direction="horizontal">
            {[GlobalRole.SeniorMember, GlobalRole.FamilyPartner].map((role) => (
              <Radio key={role} value={role}>
                <Trans i18nKey={`roles.plural.${role}`} />
              </Radio>
            ))}
          </Space>
        </Radio.Group>
      </FormItem>

      <FormItem
        label={<Trans i18nKey="forms.input.chatName.label" />}
        name="chatName"
        rules={[{ required: true, message: <Trans i18nKey="forms.input.chatName.errorMessage" /> }]}
      >
        <Input name="lastName" placeholder={i18n.t('forms.input.chatName.placeholder') as string} />
      </FormItem>

      <div style={{ width: '100%', display: 'flex', gap: '8px' }}>
        <FormItem
          name="city"
          label={<Trans i18nKey="forms.input.city.placeholder" />}
          style={{ flexBasis: 1, flexGrow: 1 }}
        >
          <Input name="city" placeholder={i18n.t('forms.input.city.placeholder') as string} />
        </FormItem>

        <FormItem
          name="zipCode"
          label={<Trans i18nKey="forms.input.zipCode.placeholder" />}
          style={{ flexBasis: 1, flexGrow: 1 }}
        >
          <Input name="zipCode" placeholder={i18n.t('forms.input.zipCode.placeholder') as string} />
        </FormItem>
      </div>

      <FormItem
        name="chatParticipants"
        label={<Trans i18nKey="forms.input.chatParticipants.placeholder" />}
        rules={[
          {
            validator: (_rule, chatParticipants) => {
              if (chatParticipants?.length < MIN_CHAT_PARTICIPANTS) {
                return Promise.reject(invalidChatParticipants);
              }
              return Promise.resolve();
            },
          },
        ]}
      >
        <Select
          mode="multiple"
          placeholder={i18n.t('forms.input.chatParticipants.placeholder') as string}
          options={users.map((user) => ({
            label: `${user.fullName} (${i18n.t(`roles.${user.role}`)})`,
            value: user.id,
          }))}
          loading={isFetchingUsers}
          onSearch={(search) => {
            setSearchUserText(search);
          }}
          filterOption={false}
          onBlur={() => setSearchUserText('')}
          notFoundContent={isFetchingUsers ? <Spin size="small" /> : null}
          onDropdownVisibleChange={(visible) => setIsPartcipantsDropdownOpen(visible)}
          open={isPartcipantsDropdownOpen}
          // eslint-disable-next-line react/no-unstable-nested-components
          dropdownRender={(menu) => (
            <>
              {menu}
              <Divider style={{ margin: '4px 0' }} />
              <Row justify="space-between" style={{ padding: '4px 8px' }}>
                <BaseButton type="text" onClick={clearParticipantsSelect}>
                  <Space>
                    <CloseOutlined />
                    <Trans i18nKey="forms.input.chatParticipants.clear" />
                  </Space>
                </BaseButton>
                <BaseButton
                  type="text"
                  onClick={() => {
                    form.setFieldValue(
                      'chatParticipants',
                      users.map((user) => user.id),
                    );
                    form.validateFields(['chatParticipants']);
                    setIsPartcipantsDropdownOpen(false);
                  }}
                >
                  <Space>
                    <CheckSquareOutlined />
                    <Trans i18nKey="forms.input.chatParticipants.selectAll" />
                  </Space>
                </BaseButton>
              </Row>
            </>
          )}
        />
      </FormItem>

      <FormItem style={{ marginTop: '30px' }}>
        <Button loading={loading} htmlType="submit">
          {submitLabel}
        </Button>
      </FormItem>
    </Form>
  );
}

ChatForm.defaultProps = {
  initialFormValues: {},
};
