import { useMemo, useState } from 'react';
import styled from 'styled-components';
import { Trans, useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import {
  SortOrder,
  StartConversationInput,
  UserSortableFields,
  useStartConversationMutation,
  useGetUsersQuery,
} from '@graphql/generated';

import { ROUTES, QUERY_PARAMS } from '@constants/routes';
import { useUser } from '@hooks/appContext/useUser';

import { Form, Select, Spin, Typography } from 'antd';
import Modal from '@components/Modal';
import Button from '@components/Button';
import Input from '@components/Input';

const FormItem = styled(Form.Item).attrs(() => ({
  labelCol: { span: 24 },
}))`
  .ant-form-item-label {
    padding: 0px !important;
  }
`;

function CreateChatModal({ onCancel }: { onCancel: () => void }) {
  const i18n = useTranslation();
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const loggedUser = useUser();
  const [searchUserText, setSearchUserText] = useState('');
  const {
    data: usersData,
    previousData: previousUsersData,
    loading: usersLoading,
  } = useGetUsersQuery({
    variables: {
      filter: { text: searchUserText },
      page: 1,
      pageSize: 100,
      sort: { field: UserSortableFields.FullName, direction: SortOrder.Asc },
    },
  });
  const [startConversationMutation, { loading: startConversationLoading }] =
    useStartConversationMutation();
  const userOptions = useMemo(() => {
    return (usersData || previousUsersData)?.getUsers?.users?.map((user) => ({
      label: user?.fullName,
      value: user?.id,
    }));
  }, [usersData, previousUsersData]);

  if (!loggedUser) {
    return (
      <Modal open width={750}>
        <div style={{ padding: 329 }}>
          <Spin size="large" />
        </div>
      </Modal>
    );
  }

  return (
    <Modal
      width={750}
      closable
      onCancel={onCancel}
      open
      title={
        <Typography.Title level={2}>
          <Trans i18nKey="manage.chat.new" />
        </Typography.Title>
      }
    >
      <Form
        form={form}
        initialValues={{ userIds: [loggedUser?.id] }}
        onFinish={async (values) => {
          const response = await startConversationMutation({
            variables: {
              input: values as StartConversationInput,
            },
          });
          navigate(
            `${ROUTES.MANAGE_CHAT}?${QUERY_PARAMS.DEFAULT_CONVERSATION_SELECTED}=${response?.data?.startConversation?.id}`,
          );
          onCancel();
        }}
      >
        <FormItem name="subject" label={i18n.t('forms.input.subject.label')}>
          <Input placeholder={String(i18n.t('forms.input.subject.placeholder'))} />
        </FormItem>
        <FormItem
          name="userIds"
          label={i18n.t('forms.input.participantsIds.label')}
          rules={[
            {
              required: true,
              message: i18n.t('forms.input.participantsIds.errorMessage') as string,
            },
          ]}
        >
          <Select
            mode="multiple"
            placeholder={String(i18n.t('forms.input.participantsIds.placeholder'))}
            loading={usersLoading}
            onBlur={() => setSearchUserText('')}
            notFoundContent={usersLoading ? <Spin size="small" /> : null}
            onSearch={setSearchUserText}
            filterOption={false}
            options={userOptions}
          />
        </FormItem>
        <FormItem>
          <Button type="primary" htmlType="submit" loading={startConversationLoading}>
            <Trans i18nKey="manage.chat.create" />
          </Button>
        </FormItem>
      </Form>
    </Modal>
  );
}

export default CreateChatModal;
