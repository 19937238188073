import GenericApolloError from '@components/GenericApolloError';
import {
  GraphqlServiceRequestReview,
  PaginationMeta,
  ReviewSortableFields,
  SortOrder,
  useGetAgentReviewsQuery,
} from '@graphql/generated';
import { useMemo, useState } from 'react';
import { Trans } from 'react-i18next';
import ReviewsList from '@components/Reviews/ReviewsList';

function AgentReviews({ agentId }: { agentId: number }) {
  const [sortOrder, setSortOrder] = useState<SortOrder>(SortOrder.Desc);
  const [sortBy, setSortBy] = useState<ReviewSortableFields>(ReviewSortableFields.CreatedAt);
  const [page, setPage] = useState(1);

  const [pageSize, setPageSize] = useState(10);
  const {
    data: agentReviewsQuery,
    error,
    loading: loadingAgentReviews,
    previousData,
  } = useGetAgentReviewsQuery({
    variables: {
      agentId,
      pageSize,
      page,
      sort: {
        direction: sortOrder,
        field: sortBy,
      },
    },
  });

  const reviews = useMemo(
    () =>
      ((agentReviewsQuery || previousData)?.getAgentReviews
        .reviews as GraphqlServiceRequestReview[]) || [],
    [agentReviewsQuery, previousData],
  );

  const pagination =
    ((agentReviewsQuery || previousData)?.getAgentReviews.meta as PaginationMeta) || {};

  if (error) return <GenericApolloError error={error} />;

  return (
    <ReviewsList
      title={
        pagination.totalCount === 0 ? (
          <Trans i18nKey="manage.reviews.noReviews" />
        ) : (
          <Trans
            i18nKey="manage.reviews.totalReviews"
            values={{ totalCount: pagination.totalCount ? pagination.totalCount : '' }}
          />
        )
      }
      loading={loadingAgentReviews}
      pagination={pagination}
      reviews={reviews}
      sortOrder={sortOrder}
      sortBy={sortBy}
      onSortOrderChange={setSortOrder}
      onSortByChange={setSortBy}
      pageSize={pageSize}
      onPageChange={setPage}
      onPageSizeChange={setPageSize}
    />
  );
}

export default AgentReviews;
