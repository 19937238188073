import { FormItem } from '@components/Form';
import Input, { InputEmail } from '@components/Input';
import Button from '@components/Button';
import { Form, Radio, Space } from 'antd';
import { useTranslation } from 'react-i18next';
import { ManagementRole } from '@graphql/generated';

export interface ManagementUserFormValues {
  firstName: string;
  lastName: string;
  email: string;
  managementRole: ManagementRole;
}

type ManagementUserFormProps = {
  loading: boolean;
  onSubmit: (values: ManagementUserFormValues) => void;
  submitLabel: string | JSX.Element;
  initialFormValues?: ManagementUserFormValues;
  editMode?: boolean;
};

export function ManagementUserForm({
  onSubmit,
  submitLabel,
  loading,
  initialFormValues,
  editMode,
}: ManagementUserFormProps) {
  const [form] = Form.useForm();
  const i18n = useTranslation();

  return (
    <Form
      name="user-form"
      autoComplete="off"
      onFinish={onSubmit}
      form={form}
      initialValues={initialFormValues}
    >
      <FormItem
        label={i18n.t('forms.input.firstName.placeholder')}
        name="firstName"
        rules={[
          { required: true, message: i18n.t('forms.input.firstName.errorMessage') as string },
        ]}
      >
        <Input
          name="firstName"
          placeholder={i18n.t('forms.input.firstName.placeholder') as string}
        />
      </FormItem>
      <FormItem
        label={i18n.t('forms.input.lastName.placeholder')}
        name="lastName"
        rules={[{ required: true, message: i18n.t('forms.input.lastName.errorMessage') as string }]}
      >
        <Input name="lastName" placeholder={i18n.t('forms.input.lastName.placeholder') as string} />
      </FormItem>
      <InputEmail
        placeholder={i18n.t('forms.input.email.placeholder') as string}
        readOnly={editMode} // NOTE: email field can not be updated
      />
      <FormItem
        name="managementRole"
        label={i18n.t('forms.input.role.label') as string}
        rules={[
          {
            required: true,
            message: i18n.t('forms.input.role.errorMessage') as string,
          },
        ]}
      >
        <Radio.Group>
          <Space direction="vertical">
            {Object.values(ManagementRole).map((role) => (
              <Radio key={role} value={role}>
                {i18n.t(`roles.${role}`)}
              </Radio>
            ))}
          </Space>
        </Radio.Group>
      </FormItem>
      <FormItem style={{ marginTop: '30px' }}>
        <Button loading={loading} htmlType="submit">
          {submitLabel}
        </Button>
      </FormItem>
    </Form>
  );
}

ManagementUserForm.defaultProps = {
  initialFormValues: {},
  editMode: false,
};
