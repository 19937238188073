// base
import { useContext } from 'react';
import { ThemeProvider as StyledThemeProvider, createGlobalStyle } from 'styled-components';
import { AppContext, TAppContext } from './appContextProvider';

const GlobalStyle = createGlobalStyle`
  html, body {
    height: 100%;
    font-family: 'SF Pro Display', 'Arial', sans-serif;
  }
  body {
    padding: 0;
    margin: 0;
  }

  a {
    // weird to use a background color as a font color; consider renaming CTA colors
    color: ${({ theme }) => theme.colors.backgroundCTA};
    font-size: ${({ theme }) => theme.fontSizes.large};
    line-height: ${({ theme }) => theme.lineHeights.large};
    font-weight: ${({ theme }) => theme.fontWeights.normal};
    text-decoration: none;
  }
  
  .ant-form-item-explain,
  .ant-form-item-explain-error {
    text-align: left;
  }
`;

function ThemeProvider({ children }: { children: JSX.Element }) {
  const { theme } = useContext(AppContext) as TAppContext;

  return (
    <StyledThemeProvider theme={theme}>
      <GlobalStyle />
      {children}
    </StyledThemeProvider>
  );
}

export default ThemeProvider;
