import AuthLayout from '@components/AuthLayout';
import {
  StripeConnectAccountStatus,
  useCreateConnectAccountOrLoginLinkMutation,
  useContinueConnectAccountOnboardingLazyQuery,
  useGetStripeConnectAccountQuery,
} from '@graphql/generated';
import { GET_STRIPE_CONNECT_ACCOUNT } from '@graphql/pros';
import i18n from '@locales/i18n';
import Button from '@components/Button';
import GenericApolloError from '@components/GenericApolloError';
import { ApolloError } from '@apollo/client';
import Loading from '@components/Loading';
import { Space } from 'antd';
import { useLogout } from '@hooks/useAuth';
import { useState } from 'react';

function StripeAccount() {
  const logout = useLogout();
  const [disableButton, setDisableButton] = useState(false);

  const {
    data: stripeAccountData,
    loading: stripeAccountLoading,
    error: stripeAccountError,
  } = useGetStripeConnectAccountQuery();
  const [createConnectAccount, { loading, error }] = useCreateConnectAccountOrLoginLinkMutation({
    refetchQueries: [GET_STRIPE_CONNECT_ACCOUNT],
  });
  const [
    continueConnectAccountOnboarding,
    { loading: continueOnboardingLoading, error: continueOnboardingError },
  ] = useContinueConnectAccountOnboardingLazyQuery();

  if (stripeAccountLoading) {
    return <Loading />;
  }

  if (error || stripeAccountError || continueOnboardingError) {
    return (
      <GenericApolloError
        error={(error || stripeAccountError || continueOnboardingError) as ApolloError}
      />
    );
  }

  if (
    stripeAccountData?.getStripeConnectAccount?.status ===
      StripeConnectAccountStatus.MissingRequirements ||
    stripeAccountData?.getStripeConnectAccount?.status ===
      StripeConnectAccountStatus.RequirementsPastDue
  ) {
    return (
      <AuthLayout
        bryaHeaderProps={{
          hideSSOLogin: true,
          title: 'stripe.missingRequirementsTitle',
          subtitle: 'stripe.missingRequirementsDescription',
        }}
      >
        <Space size="middle" direction="vertical" style={{ width: '100%' }}>
          <Button
            loading={continueOnboardingLoading || disableButton}
            onClick={async () => {
              try {
                setDisableButton(true);
                const { data } = await continueConnectAccountOnboarding();
                if (data) {
                  window.location.href = data.continueConnectAccountOnboarding;
                }
              } catch (err) {
                setDisableButton(false);
              }
            }}
          >
            {i18n.t('stripe.actions.missingRequirements')}
          </Button>
          <Button type="ghost" onClick={logout}>
            {i18n.t('stripe.actions.logout')}
          </Button>
        </Space>
      </AuthLayout>
    );
  }

  if (stripeAccountData?.getStripeConnectAccount?.status === StripeConnectAccountStatus.Complete) {
    return (
      <AuthLayout
        bryaHeaderProps={{
          hideSSOLogin: true,
          title: 'stripe.stripeAccountLoginTitle',
          subtitle: 'stripe.stripeAccountLoginDescription',
        }}
      >
        <Space size="middle" direction="vertical" style={{ width: '100%' }}>
          <Button
            loading={loading || disableButton}
            onClick={async () => {
              try {
                setDisableButton(true);
                const { data } = await createConnectAccount();
                if (data) {
                  window.location.href = data.createConnectAccountOrLoginLink;
                }
              } catch (err) {
                setDisableButton(false);
              }
            }}
          >
            {i18n.t('stripe.actions.login')}
          </Button>
          <Button type="ghost" onClick={logout}>
            {i18n.t('stripe.actions.logout')}
          </Button>
        </Space>
      </AuthLayout>
    );
  }

  return (
    <AuthLayout
      bryaHeaderProps={{
        hideSSOLogin: true,
        title: 'stripe.createConnectAccountTitle',
        subtitle: 'stripe.createConnectAccountDescription',
      }}
    >
      <Space size="middle" direction="vertical" style={{ width: '100%' }}>
        <Button
          loading={loading || disableButton}
          onClick={async () => {
            try {
              setDisableButton(true);
              const { data } = await createConnectAccount();
              if (data) {
                window.location.href = data.createConnectAccountOrLoginLink;
              }
            } catch (err) {
              setDisableButton(false);
            }
          }}
        >
          {i18n.t('stripe.actions.createAccount')}
        </Button>
        <Button type="ghost" onClick={logout}>
          {i18n.t('stripe.actions.logout')}
        </Button>
      </Space>
    </AuthLayout>
  );
}

export default StripeAccount;
