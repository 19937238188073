import ROUTES from '@constants/routes';
import { GlobalRole, ManagementRole } from '@graphql/generated';
import { useUser } from '@hooks/appContext/useUser';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

type ProtectedRouteProps = {
  allowedRoles?: (GlobalRole | ManagementRole | undefined | null)[];
  children: JSX.Element;
  navigateTo?: string;
};

export function ProtectedRoute({
  allowedRoles = [],
  children,
  navigateTo = ROUTES.MANAGE_LOG_IN,
}: ProtectedRouteProps) {
  const user = useUser();
  const navigate = useNavigate();

  const hasPermision = user && (allowedRoles.includes(user.role) || allowedRoles.length === 0);
  useEffect(() => {
    if (!hasPermision) {
      // NOTE: webview shouldn't access Protected Routes
      navigate(navigateTo, { replace: true });
    }
  }, [navigate, hasPermision, navigateTo]);

  return hasPermision ? children : null;
}

ProtectedRoute.defaultProps = {
  allowedRoles: [], // All roles are allowed
  navigateTo: undefined,
};
