import { ServiceRequestAvailability, ServiceRequestAvailabilityInput } from '@graphql/generated';
import dayjs from 'dayjs';

export type DayjsDateInput = string | number | Date | dayjs.Dayjs | null | undefined;

export const formatISOtoMMDDYYYY = (
  date: string | number | Date | null | undefined,
  separator: string = '/',
): string => {
  const dateInstance = dayjs(date);
  return dateInstance.isValid() && Boolean(date)
    ? dateInstance.format(`MM${separator}DD${separator}YYYY`)
    : '';
};

export const formatISOtoMMDDYYYYandTime = (
  date: string | number | Date | null | undefined,
  separator: string = '/',
): string => {
  const dateInstance = dayjs(date);
  return dateInstance.isValid() && Boolean(date)
    ? dateInstance.format(`MM${separator}DD${separator}YYYY @ h:mma`)
    : '';
};

export const formatAvailabilityToMMDDYYandTime = (
  availability: ServiceRequestAvailabilityInput | undefined,
  separator: string = '/',
): string => {
  if (!availability) {
    return '';
  }

  const dateFrom = dayjs(`${availability.date} ${availability.from}`);
  const dateUntil = dayjs(`${availability.date} ${availability.until}`);

  return `${dateFrom.format(
    `[On] MM${separator}DD${separator}YYYY, [from] h:mma`,
  )} until ${dateUntil.format('h:mma z')}`;
};

export const availabilityCompareFn = (
  a: ServiceRequestAvailability,
  b: ServiceRequestAvailability,
) => {
  return dayjs(`${a.date} ${a.from}`).isBefore(dayjs(`${b.date} ${b.from}`)) ? -1 : 1;
};
