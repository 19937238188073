import { Card } from '@components/Card';
import {
  GraphqlCoachReview,
  GraphqlServiceRequestReview,
  PaginationMeta,
  ReviewSortableFields,
  SortOrder,
} from '@graphql/generated';
import { Pagination as AntdPagination, Button, CardProps, Space } from 'antd';
import styled from 'styled-components';
import { ArrowDownOutlined, ArrowUpOutlined } from '@ant-design/icons';
import { Select } from '@components/Input/Select';
import { Trans } from 'react-i18next';
import { ReactNode } from 'react';
import Review from './Review';

const Pagination = styled(AntdPagination)`
  a {
    line-height: inherit;
  }
`;

const Flex = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  align-items: start;
  flex-wrap: wrap;
  gap: 1em;
`;

interface ReviewsListProps {
  title: ReactNode;
  body?: ReactNode;
  reviews: GraphqlServiceRequestReview[] | GraphqlCoachReview[];
  pagination: PaginationMeta;
  loading: boolean;
  sortOrder: SortOrder;
  sortBy: ReviewSortableFields;
  onSortOrderChange: (sortOrder: SortOrder) => void;
  onSortByChange: (sortBy: ReviewSortableFields) => void;
  pageSize: number;
  onPageChange: (page: number) => void;
  onPageSizeChange: (pageSize: number) => void;
  onReviewDelete?: (review: GraphqlServiceRequestReview | GraphqlCoachReview) => void | undefined;
  cardProps?: CardProps;
}

function ReviewsList({
  title,
  body,
  reviews,
  pagination,
  loading,
  sortOrder,
  sortBy,
  onSortByChange,
  onSortOrderChange,
  pageSize,
  onPageChange,
  onPageSizeChange,
  onReviewDelete,
  cardProps,
}: ReviewsListProps) {
  return (
    <Card
      title={title}
      loading={loading}
      actions={[
        <Space size="large">
          <Button
            type="text"
            onClick={() => {
              if (sortOrder === SortOrder.Desc) {
                onSortOrderChange(SortOrder.Asc);
                onPageChange(1);
              } else {
                onSortOrderChange(SortOrder.Desc);
                onPageChange(1);
              }
            }}
          >
            {sortOrder === SortOrder.Desc ? (
              <Space size="small">
                <ArrowDownOutlined />
                <Trans i18nKey="sortOrder.desc" />
              </Space>
            ) : (
              <Space size="small">
                <ArrowUpOutlined />
                <Trans i18nKey="sortOrder.asc" />
              </Space>
            )}
          </Button>
          <Select
            style={{ width: 120, textAlign: 'left' }}
            value={sortBy}
            onChange={(option) => {
              onSortByChange(option as ReviewSortableFields);
              onPageChange(1);
            }}
          >
            {[ReviewSortableFields.CreatedAt, ReviewSortableFields.Rating].map((field) => (
              <Select.Option key={field} value={field}>
                <Trans i18nKey={`manage.reviews.sortableFields.${field}`} />
              </Select.Option>
            ))}
          </Select>
        </Space>,
        <Pagination
          showSizeChanger
          pageSize={pageSize}
          onChange={(newPage, newPageSize) => {
            if (newPageSize !== pageSize) {
              onPageSizeChange(newPageSize);
              onPageChange(1);
            } else {
              onPageChange(newPage);
            }
          }}
          total={pagination.totalCount}
          current={pagination.currentPage}
        />,
      ]}
      {...cardProps}
    >
      <Space direction="vertical" size="large">
        {body}
        <Flex>
          {reviews.map((review, idx) => (
            <Review
              // eslint-disable-next-line react/no-array-index-key
              key={`${pagination.currentPage}-${idx}`}
              review={review}
              onDelete={onReviewDelete}
            />
          ))}
        </Flex>
      </Space>
    </Card>
  );
}

ReviewsList.defaultProps = {
  onReviewDelete: undefined,
  body: null,
  cardProps: {},
};

export default ReviewsList;
