import { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Trans, useTranslation } from 'react-i18next';
import { GET_COACH_VISIT_QUERY } from '@graphql/coachVisits';
import { GET_APPOINTMENT_REQUESTS_QUERY, GET_ACTIVITY_TIMELINE_QUERY } from '@graphql/activities';
import {
  FileSortableFields,
  GlobalRole,
  GraphqlAddresses,
  GraphqlInternalNote,
  GraphqlCoachVisit,
  GraphqlUser,
  SocialEventStatus,
  SortOrder,
  useGetCoachVisitQuery,
  useGetCustomerLazyQuery,
  useUpdateCoachVisitMutation,
} from '@graphql/generated';
import { ApolloError } from '@apollo/client';

import ROUTES from '@constants/routes';
import { formatAddressBlock } from '@utils/formatters/address';

import { DatePicker, Select, Space, Table, Typography } from 'antd';
import { EditOutlined, PlusOutlined } from '@ant-design/icons';
import Masonry, { ResponsiveMasonry } from 'react-responsive-masonry';

import Link from '@components/Link';
import Button from '@components/Button';
import Card from '@components/Card/Card';
import Loading from '@components/Loading';
import GenericApolloError from '@components/GenericApolloError';
import RecordDetailCard from '@components/Card/RecordDetailCard';
import AddEditNoteModal from '@components/Activities/AddEditNoteModal';
import CreateVisitSummaryModal from '@components/Activities/CreateVisitSummaryModal';
import { EmployeeInternalFiles } from '@routes/users/components/EmployeeInternalFiles';

import dayjs from 'dayjs';
import { useDateWithTimezone } from '@hooks/useDateWithTimezone';

function CoachVisit() {
  const i18n = useTranslation();
  const { formatDateAndTimeInTimezone, transformToTimezone } = useDateWithTimezone();
  const [visitSummaryModalIsOpen, setVisitSummaryModalIsOpen] = useState<boolean>(false);
  const [noteModalIsOpen, setNoteModalIsOpen] = useState<boolean | GraphqlInternalNote>(false);

  const { activityId } = useParams<{ activityId: string }>();
  const {
    data: coachVisitData,
    loading: coachVisitLoading,
    error: coachVisitError,
  } = useGetCoachVisitQuery({
    variables: { coachVisitId: parseInt(activityId as string, 10) },
  });
  const [getCustomer, { data: customerData, loading: customerLoading, error: customerError }] =
    useGetCustomerLazyQuery();
  const [updateCoachVisitMutation] = useUpdateCoachVisitMutation({
    refetchQueries: [
      GET_COACH_VISIT_QUERY,
      GET_APPOINTMENT_REQUESTS_QUERY,
      GET_ACTIVITY_TIMELINE_QUERY,
    ],
  });

  const coachVisit = useMemo(() => {
    return coachVisitData?.getCoachVisit || ({} as GraphqlCoachVisit);
  }, [coachVisitData]);

  const internalNotes = useMemo(() => {
    return [...(coachVisit?.InternalNotes || [])].sort((a, b) => +b.createdAt - +a.createdAt);
  }, [coachVisit]);

  useEffect(() => {
    if (coachVisit?.Customer?.id && !customerData && !customerLoading && !customerError) {
      getCustomer({ variables: { customerId: coachVisit?.Customer?.id } });
    }
  }, [
    getCustomer,
    coachVisitData,
    coachVisit?.Customer?.id,
    customerData,
    customerError,
    customerLoading,
  ]);

  if (coachVisitLoading || customerLoading) {
    return <Loading />;
  }

  if (coachVisitError || customerError) {
    return <GenericApolloError error={(coachVisitError || customerError) as ApolloError} />;
  }

  return (
    <div style={{ maxWidth: 1800, margin: '0 auto' }}>
      <Typography.Title
        level={2}
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
        }}
      >
        <Trans
          i18nKey="manage.coachVisit.headers.id"
          values={{ id: String(coachVisit?.id).padStart(5, '0') }}
        />
      </Typography.Title>
      <ResponsiveMasonry columnsCountBreakPoints={{ 350: 1 }}>
        <Masonry gutter="20px">
          <RecordDetailCard
            title={<Trans i18nKey="manage.coachVisit.headers.details" />}
            editable
            onEdit={async (values) => {
              await updateCoachVisitMutation({
                variables: {
                  coachVisitId: coachVisit.id,
                  data: {
                    ...values,
                    status: values?.status?.toLowerCase(),
                    scheduledAt: dayjs(values.scheduledAt).toDate(),
                  },
                },
              });
            }}
            cardProps={{
              style: { minWidth: '350px' },
            }}
            fields={[
              {
                name: 'accountName',
                label: <Trans i18nKey="manage.serviceRequest.fields.account" />,
                value: (
                  <Link
                    to={ROUTES.MANAGE_CUSTOMER.replace(
                      ':customerId',
                      String(coachVisit?.Customer?.id),
                    )}
                  >
                    {coachVisit?.Customer?.fullName}
                  </Link>
                ),
              },
              {
                name: 'familyPartners',
                label: <Trans i18nKey="manage.coachVisit.fields.familyPartners" />,
                value: (
                  <div>
                    {customerData?.getCustomer?.users
                      ?.filter((user) => user?.role === GlobalRole.FamilyPartner)
                      .map((user, i) => [
                        i > 0 && ', ',
                        <Link to={ROUTES.MANAGE_USER.replace(':userId', String(user?.user?.id))}>
                          {user?.user?.fullName}
                        </Link>,
                      ])}
                  </div>
                ),
              },
              {
                name: 'coach',
                label: <Trans i18nKey="manage.coachVisit.fields.coach" />,
                value: (
                  <Link to={ROUTES.MANAGE_USER.replace(':userId', String(coachVisit?.Coach?.id))}>
                    {coachVisit?.Coach?.fullName}
                  </Link>
                ),
              },
              {
                name: 'scheduledAt',
                label: <Trans i18nKey="manage.coachVisits.columns.scheduledAt" />,
                value: transformToTimezone(coachVisit?.scheduledAt),
                render: () =>
                  coachVisit?.scheduledAt ? (
                    <div style={{ textAlign: 'right' }}>
                      <div>
                        {formatDateAndTimeInTimezone(coachVisit.scheduledAt, 'MMMM Do, YYYY')}
                      </div>
                      <div>{formatDateAndTimeInTimezone(coachVisit.scheduledAt, '@ h:mma z')}</div>
                    </div>
                  ) : (
                    'N/A'
                  ),
                editable: true,
                formElement: (
                  <DatePicker use12Hours showTime changeOnBlur format="MMMM DD, YYYY @ h:mma z" />
                ),
              },
              {
                name: 'status',
                label: <Trans i18nKey="manage.coachVisits.columns.status" />,
                value: i18n.t(`serviceRequestStatus.${coachVisit?.status}`) as string,
                render: () => <Trans i18nKey={`serviceRequestStatus.${coachVisit?.status}`} />,
                editable: true,
                formElement: (
                  <Select style={{ minWidth: 200 }}>
                    {Object.values(SocialEventStatus).map((status) => (
                      <Select.Option
                        value={status}
                        disabled={status === SocialEventStatus.Completed}
                      >
                        {String(i18n.t(`serviceRequestStatus.${status}`))}
                      </Select.Option>
                    ))}
                  </Select>
                ),
              },
              {
                name: 'location',
                label: <Trans i18nKey="manage.coachVisits.columns.location" />,
                value: coachVisit?.Address
                  ? formatAddressBlock(coachVisit?.Address as GraphqlAddresses)
                  : i18n.t('forms.input.contactMethod.phone'),
              },
            ]}
          />

          <RecordDetailCard
            title={
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  width: '100%',
                }}
              >
                <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                  {i18n.t(`manage.coachVisit.headers.visitSummary`)}
                </div>
                <div>
                  <Button
                    type="link"
                    onClick={() => setVisitSummaryModalIsOpen(!visitSummaryModalIsOpen)}
                  >
                    {i18n.t('manage.coachVisit.actions.newSummary')}
                  </Button>
                </div>
              </div>
            }
            withDividers
            dividerMod={3}
            hasData={!!coachVisit?.VisitSummaries?.length}
            noDataWarning={<Trans i18nKey="manage.coachVisit.noSummaries" />}
            fields={(
              coachVisit?.VisitSummaries?.map((visitSummary, i) => [
                {
                  name: `sentTo-${i}`,
                  label: <Trans i18nKey="manage.coachVisit.fields.sentTo" />,
                  value: (
                    <div>
                      {visitSummary?.SentTo?.map((user, j) => [
                        j > 0 && ', ',
                        <Link to={ROUTES.MANAGE_USER.replace(':userId', String(user?.id))}>
                          {user?.fullName}
                        </Link>,
                      ])}
                    </div>
                  ),
                },
                {
                  name: `scheduledAt-${i}`,
                  label: <Trans i18nKey="manage.coachVisits.columns.scheduledAt" />,
                  value: formatDateAndTimeInTimezone(visitSummary?.createdAt),
                },
                {
                  name: `body-${i}`,
                  label: <Trans i18nKey="manage.coachVisit.fields.summary" />,
                  value: visitSummary?.body,
                },
              ]) || []
            ).flat()}
          />

          <Card
            title={i18n.t('manage.serviceRequest.headers.notes')}
            actions={[
              <Button
                type="text"
                onClick={() =>
                  setNoteModalIsOpen({
                    coachVisitId: coachVisit?.id,
                  } as GraphqlInternalNote)
                }
              >
                <Space size="small">
                  <PlusOutlined />
                  <Trans i18nKey="manage.serviceRequest.addNote" />
                </Space>
              </Button>,
            ]}
          >
            {internalNotes?.length ? (
              <Table
                dataSource={internalNotes}
                columns={[
                  {
                    title: <Trans i18nKey="manage.internalNotes.headers.addedBy" />,
                    dataIndex: 'Users.fullName',
                    key: 'Users.fullName',
                    render: (_: any, record: GraphqlInternalNote) => record?.Users?.fullName,
                  },
                  {
                    title: <Trans i18nKey="manage.internalNotes.headers.createdAt" />,
                    dataIndex: 'createdAt',
                    key: 'createdAt',
                    render: (_: any, record: GraphqlInternalNote) =>
                      formatDateAndTimeInTimezone(record?.createdAt),
                  },
                  {
                    title: '',
                    dataIndex: 'note',
                    key: 'note',
                  },
                  {
                    title: <Trans i18nKey="manage.internalNotes.headers.modified" />,
                    dataIndex: 'updatedAt',
                    key: 'updatedAt',
                    render: (_: any, record: GraphqlInternalNote) =>
                      record?.createdAt !== record?.updatedAt && (
                        <div>
                          <div>{record?.Users?.fullName}</div>
                          <Typography.Text italic>
                            {dayjs(record?.updatedAt).fromNow()}
                          </Typography.Text>
                        </div>
                      ),
                  },
                  {
                    title: <Trans i18nKey="manage.internalNotes.headers.edit" />,
                    key: 'edit',
                    render: (_: any, record: GraphqlInternalNote) => (
                      <EditOutlined onClick={() => setNoteModalIsOpen(record)} />
                    ),
                  },
                ]}
                pagination={false}
              />
            ) : (
              <Trans i18nKey="manage.serviceRequest.noNotes" />
            )}
          </Card>
          <EmployeeInternalFiles
            employee={coachVisit?.Customer as unknown as GraphqlUser}
            uploadRequestBody={{ customerId: Number(coachVisit?.Customer?.id) }}
            filter={{ customerId: Number(coachVisit?.Customer?.id) }}
            sort={{ field: FileSortableFields.CreatedAt, direction: SortOrder.Desc }}
            // NOTE: for now we don't implement pagination for internal files
            page={1}
            pageSize={1000}
          />
        </Masonry>
      </ResponsiveMasonry>
      {!!visitSummaryModalIsOpen && (
        <CreateVisitSummaryModal
          coachVisitId={coachVisit?.id}
          users={
            customerData?.getCustomer?.users
              ?.filter((user) =>
                [GlobalRole.FamilyPartner, GlobalRole.SeniorMember].includes(
                  user?.role as GlobalRole,
                ),
              )
              ?.map((user) => user.user as GraphqlUser) || []
          }
          onCancel={() => setVisitSummaryModalIsOpen(false)}
        />
      )}
      {!!noteModalIsOpen && (
        <AddEditNoteModal
          onCancel={() => setNoteModalIsOpen(!noteModalIsOpen)}
          note={noteModalIsOpen as GraphqlInternalNote}
        />
      )}
    </div>
  );
}

export default CoachVisit;
