import styled from 'styled-components';
import { Trans, useTranslation } from 'react-i18next';
import {
  GraphqlUser,
  CreateNotificationInput,
  useCreateNotificationMutation,
} from '@graphql/generated';
import { GET_NOTIFICATIONS_BY_USER_ID_QUERY } from '@graphql/notifications';

import { Form, Typography } from 'antd';
import Modal from '@components/Modal';
import Button from '@components/Button';

import Input, { InputTextarea } from '@components/Input';

const FormItem = styled(Form.Item).attrs(() => ({
  labelCol: { span: 24 },
}))`
  .ant-form-item-label {
    padding: 0px !important;
  }
`;

function CreatNotificationModal({ user, onCancel }: { user: GraphqlUser; onCancel: () => void }) {
  const i18n = useTranslation();
  const [form] = Form.useForm();

  const [createNotificationMutation, { loading: createNotificationLoading }] =
    useCreateNotificationMutation({
      refetchQueries: [GET_NOTIFICATIONS_BY_USER_ID_QUERY],
    });

  return (
    <Modal
      width={750}
      closable
      onCancel={onCancel}
      open
      title={
        <Typography.Title level={2}>
          <Trans
            i18nKey="manage.users.detail.notifications.sendTo"
            values={{ fullName: user.fullName }}
          />
        </Typography.Title>
      }
    >
      <Form
        form={form}
        initialValues={{ recurring: false }}
        onFinish={async (values) => {
          await createNotificationMutation({
            variables: {
              notification: {
                userId: user.id,
                type: 'general',
                title: values.title,
                text: values.text,
              } as CreateNotificationInput,
            },
          });
          onCancel();
        }}
      >
        <FormItem
          name="title"
          label={i18n.t('forms.input.title.label')}
          rules={[{ required: true, message: i18n.t('forms.input.title.errorMessage') as string }]}
        >
          <Input placeholder={String(i18n.t('forms.input.title.placeholder'))} />
        </FormItem>
        <FormItem
          name="text"
          label={i18n.t('forms.input.text.label')}
          rules={[{ required: true, message: i18n.t('forms.input.text.errorMessage') as string }]}
        >
          <InputTextarea placeholder={String(i18n.t('forms.input.text.placeholder'))} />
        </FormItem>
        <FormItem>
          <Button type="primary" htmlType="submit" loading={createNotificationLoading}>
            <Trans i18nKey="manage.users.detail.notifications.create" />
          </Button>
        </FormItem>
      </Form>
    </Modal>
  );
}

export default CreatNotificationModal;
