import { ApolloError, ServerError } from '@apollo/client';
import { HttpStatusCode } from 'axios';
import { EmptyUnauthorized } from './EmptyUnauthorized';

export interface IErrorMessage {
  [HttpStatusCode.BadRequest]?: string;
  [HttpStatusCode.Unauthorized]?: string;
  [HttpStatusCode.Forbidden]?: string;
  [HttpStatusCode.NotFound]?: string;
  [HttpStatusCode.InternalServerError]?: string;
}

export interface IGenericApolloErrorProps {
  error: ApolloError;
}

export function GenericApolloError({ error }: IGenericApolloErrorProps) {
  const statusCode: keyof IErrorMessage = ((error as ApolloError)?.networkError as ServerError)
    ?.statusCode;

  if (statusCode === HttpStatusCode.Unauthorized) {
    return <EmptyUnauthorized />;
  }

  return null;
}

export default GenericApolloError;
