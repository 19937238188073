import ROUTES from '@constants/routes';
import { Row, Table, Typography } from 'antd';
import { useEffect, useMemo, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import Input from '@components/Input';
import { FilterValue, SorterResult, TablePaginationConfig } from 'antd/es/table/interface';
import { ColumnsType } from 'antd/es/table';
import {
  useGetCustomersQuery,
  GraphqlCustomer,
  GraphqlUser,
  GlobalRole,
  FiltersInput,
  SortOrder,
  CustomerSortableFields,
} from '@graphql/generated';
import { formatISOtoMMDDYYYY } from '@utils/date';
import { useUser } from '@hooks/appContext/useUser';
import getCustomersQueryVariables from '@utils/getCustomersQueryVariables';
import GenericApolloError from '@components/GenericApolloError';

const StyledTable: typeof Table = styled(Table)`
  a {
    line-height: inherit;
  }
`;

function Customers() {
  const i18n = useTranslation();
  const loggedUser = useUser();

  const [searchCustomerText, setSearchCustomerText] = useState('');
  const [filters, setFilters] = useState<FiltersInput>(
    getCustomersQueryVariables(loggedUser, searchCustomerText),
  );

  const {
    data: getCustomersQuery,
    previousData,
    loading: isFetchingCustomers,
    error,
  } = useGetCustomersQuery({
    variables: { filters },
  });
  const loading = isFetchingCustomers;

  useEffect(() => {
    const timer = setTimeout(() => {
      setFilters((previousFilters) => ({ ...previousFilters, fullName: searchCustomerText }));
    }, 1000);
    return () => clearTimeout(timer);
  }, [searchCustomerText]);

  const columns: ColumnsType<GraphqlCustomer> = useMemo(
    () => [
      {
        title: i18n.t('manage.customers.table.columns.fullName') as string,
        dataIndex: 'fullName',
        key: 'fullName',
        sorter: true,
        render: (_: any, record: any) => (
          <Link to={ROUTES.MANAGE_CUSTOMER.replace(':customerId', String(record.id))}>
            {record.fullName}
          </Link>
        ),
      },
      {
        title: i18n.t('manage.customers.table.columns.userName') as string,
        dataIndex: 'userName',
        key: 'userName',
        sorter: false,
        render: (_: any, record: any) => (
          <div>
            {record?.users
              ?.filter((user: { user: GraphqlUser }) => user?.user?.role !== GlobalRole.Coach)
              .map?.((user: { user: GraphqlUser }) => (
                <div>
                  <Link to={ROUTES.MANAGE_USER.replace(':userId', String(user?.user.id))}>
                    {user?.user?.fullName}
                  </Link>
                </div>
              ))}
          </div>
        ),
      },
      {
        title: i18n.t('manage.customers.table.columns.role') as string,
        dataIndex: 'role',
        key: 'role',
        render: (_: any, record: any) => (
          <div>
            {record?.users
              ?.filter((user: { role: string }) => user?.role !== GlobalRole.Coach)
              .map?.((user: { role: string; user: GraphqlUser }) => (
                <div>{i18n.t(`roles.${user?.role}`) as string}</div>
              ))}
          </div>
        ),
      },
      {
        title: i18n.t('manage.customers.table.columns.paymentName') as string,
        dataIndex: 'createdBy',
        key: 'createdBy',
        sorter: true,
        render: (_: any, record: any) =>
          record?.users?.find((user: { user: GraphqlUser }) => user?.user?.id === record?.createdBy)
            ?.user?.fullName,
      },
      {
        title: i18n.t('manage.customers.table.columns.createdAt') as string,
        dataIndex: 'createdAt',
        key: 'createdAt',
        sorter: true,
        render: (_: any, record: any) => formatISOtoMMDDYYYY(record.createdAt),
      },
    ],
    [i18n],
  );

  const onTableChange = (
    tablePagination: TablePaginationConfig,
    tableFilters: Record<string, FilterValue | null>,
    tableSorter: SorterResult<GraphqlCustomer> | SorterResult<GraphqlCustomer>[],
  ) => {
    const uniqueSorter = tableSorter as SorterResult<GraphqlCustomer>;

    // Order
    if (uniqueSorter.order) {
      const direction = uniqueSorter.order === 'ascend' ? 'asc' : 'desc';
      setFilters((prevFilters) => ({
        ...prevFilters,
        sortField: uniqueSorter.field as CustomerSortableFields,
        sortValue: direction as SortOrder,
      }));
    } else {
      setFilters((prevFilters) => ({
        ...prevFilters,
        sortField: CustomerSortableFields.CreatedAt,
        sortValue: SortOrder.Desc,
      }));
    }
  };

  if (error) {
    return <GenericApolloError error={error} />;
  }

  return (
    <>
      <Row justify="space-between" align="middle" style={{ marginBottom: '20px' }}>
        <Typography.Title level={2}>
          <Trans i18nKey="manage.customers.title" />
        </Typography.Title>
      </Row>
      <Row style={{ marginBottom: '20px' }}>
        <Input
          placeholder={i18n.t('manage.customers.searchUser') as string}
          value={searchCustomerText}
          onChange={(e) => setSearchCustomerText(e.target.value)}
        />
      </Row>
      <StyledTable
        loading={loading}
        columns={columns}
        dataSource={(
          ((getCustomersQuery || previousData)?.getCustomers || []) as GraphqlCustomer[]
        ).map((user) => ({
          ...user,
          key: user.id,
        }))}
        onChange={onTableChange}
        pagination={false}
      />
    </>
  );
}

export default Customers;
