import { gql } from '@apollo/client';

export const GET_SUGGESTED_ACTIVITIES_QUERY = gql`
  query GetSuggestedActivities(
    $filter: SuggestedActivitiesFilter
    $sort: SuggestedActivitiesSortInput
    $page: Int
    $pageSize: Int
  ) {
    getSuggestedActivities(filter: $filter, sort: $sort, page: $page, pageSize: $pageSize) {
      suggestedActivities {
        id
        User {
          id
          fullName
        }
        name
        description
        createdAt
        notes
        role
      }
      meta {
        currentPage
        isFirstPage
        isLastPage
        pageCount
        totalCount
      }
    }
  }
`;

export const UPDATE_SUGGESTED_ACTIVITY_QUERY = gql`
  mutation UpdateSuggestedActivity(
    $updateSuggestedActivityInput: UpdateSuggestedActivityInput!
    $suggestedActivityId: Int!
  ) {
    updateSuggestedActivity(
      updateSuggestedActivityInput: $updateSuggestedActivityInput
      suggestedActivityId: $suggestedActivityId
    ) {
      id
    }
  }
`;
