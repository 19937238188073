import { useState, useMemo } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import {
  AppointmentRequestStatus,
  CoachVisitFilter,
  ContactRequestStatus,
  CustomerSortableFields,
  GlobalRole,
  GraphqlCircleRequest,
  GraphqlCoachVisit,
  GraphqlCustomer,
  GraphqlServiceRequest,
  GraphqlSocialEvent,
  GraphqlUser,
  ServiceRequestStatus,
  SocialEventStatus,
  SortOrder,
  useGetCoachVisitsQuery,
  useGetCustomersQuery,
  useGetCircleRequestsQuery,
  useGetServiceRequestsQuery,
  useGetSocialEventsQuery,
} from '@graphql/generated';

import dayjs from 'dayjs';
import ROUTES from '@constants/routes';
import { ApolloError } from '@apollo/client';
import { ID_MIN_LENGTH } from '@constants/output';
import { FEATURE_FLAGS } from '@constants/featureFlags';
import { useIsFeatureEnabled } from '@hooks/useIsFeatureEnabled';
import { formatISOtoMMDDYYYY } from '@utils/date';

import { Typography } from 'antd';
import { ColumnsType } from 'antd/es/table';
import Masonry, { ResponsiveMasonry } from 'react-responsive-masonry';
import Card from '@components/Card/Card';
import Loading from '@components/Loading';
import Table from '@components/Table/Table';
import GenericApolloError from '@components/GenericApolloError';
import { useDateWithTimezone } from '@hooks/useDateWithTimezone';
// import { ServiceRequestStatusTag } from '@components/Activities/ServiceRequestStatusTag';

export function Dashboard() {
  // We need useState here so we don't make a new datetime every rerender
  const [yesterdayDatetime] = useState(dayjs().subtract(1, 'day').toDate());
  const i18n = useTranslation();
  const { formatDateAndTimeInTimezone } = useDateWithTimezone();

  const serviceRequestEnabled = useIsFeatureEnabled(FEATURE_FLAGS.SERVICE_REQUEST);
  const circleRequestEnabled = useIsFeatureEnabled(FEATURE_FLAGS.CIRCLE_REQUEST);

  const {
    data: coachlessCustomersData,
    error: coachlessCustomersError,
    loading: coachlessCustomersLoading,
  } = useGetCustomersQuery({
    variables: {
      filters: {
        hasCoach: false,
        sortField: CustomerSortableFields.FullName,
        sortValue: SortOrder.Desc,
      },
    },
  });
  const {
    data: summarylessCoachVisitsData,
    error: summarylessCoachVisitsError,
    loading: summarylessCoachVisitsLoading,
  } = useGetCoachVisitsQuery({
    variables: {
      filter: {
        status: [SocialEventStatus.Completed],
        hasVisitSummary: false,
      } as CoachVisitFilter,
    },
  });
  const {
    data: unappointedServiceRequestsData,
    error: unappointedServiceRequestsError,
    loading: unappointedServiceRequestsLoading,
  } = useGetServiceRequestsQuery({
    variables: {
      filter: {
        status: [ServiceRequestStatus.Pending],
        hasAppointmentRequests: false,
      },
      pageSize: 100,
    },
  });
  const {
    data: pendingServiceRequestsData,
    error: pendingServiceRequestsError,
    loading: pendingServiceRequestsLoading,
  } = useGetServiceRequestsQuery({
    variables: {
      filter: {
        status: [ServiceRequestStatus.Pending],
        hasWaitingAppointmentRequests: true,
      },
      pageSize: 100,
    },
  });
  const {
    data: paymentFailedServiceRequestsData,
    error: paymentFailedServiceRequestsError,
    loading: paymentFailedServiceRequestsLoading,
  } = useGetServiceRequestsQuery({
    variables: {
      filter: {
        status: [
          ServiceRequestStatus.PaymentFailed,
          ServiceRequestStatus.PaymentAuthorizationFailed,
        ],
      },
      pageSize: 100,
    },
  });
  const {
    data: waitingForCustomerServiceRequestsData,
    error: waitingForCustomerServiceRequestsError,
    loading: waitingForCustomerServiceRequestsLoading,
  } = useGetServiceRequestsQuery({
    variables: {
      filter: {
        status: [ServiceRequestStatus.ConfirmationNeeded],
        updatedAtUntil: yesterdayDatetime,
      },
      pageSize: 100,
    },
  });
  const {
    data: upcomingUnconfirmedServiceRequestsData,
    error: upcomingUnconfirmedServiceRequestsError,
    loading: upcomingUnconfirmedServiceRequestsLoading,
  } = useGetServiceRequestsQuery({
    variables: {
      filter: {
        upcomingUnconfirmed: true,
      },
      pageSize: 100,
    },
  });
  const {
    data: needsAssignmentServiceRequestsData,
    error: needsAssignmentServiceRequestsError,
    loading: needsAssignmentServiceRequestsLoading,
  } = useGetServiceRequestsQuery({
    variables: {
      filter: {
        needsAssignment: true,
      },
      pageSize: 100,
    },
  });
  const {
    data: rejectedCircleRequestsData,
    error: rejectedCircleRequestsError,
    loading: rejectedCircleRequestsLoading,
  } = useGetCircleRequestsQuery({
    variables: {
      filter: {
        status: [ServiceRequestStatus.AttentionNeeded],
      },
      pageSize: 100,
    },
  });
  const {
    data: waitingForCircleMemberCircleRequestsData,
    error: waitingForCircleMemberCircleRequestsError,
    loading: waitingForCircleMemberCircleRequestsLoading,
  } = useGetCircleRequestsQuery({
    variables: {
      filter: {
        status: [ServiceRequestStatus.Pending],
        updatedAtUntil: yesterdayDatetime,
      },
      pageSize: 100,
    },
  });
  const {
    data: pendingContactRequestsSocialEventsData,
    error: pendingContactRequestsSocialEventsError,
    loading: pendingContactRequestsSocialEventsLoading,
  } = useGetSocialEventsQuery({
    variables: {
      filter: {
        hasPendingContactRequests: true,
      },
    },
  });

  // BEGIN MEMOIZED RESPONSE DATA
  const coachlessCustomers = useMemo(
    () => (coachlessCustomersData?.getCustomers || []) as GraphqlCustomer[],
    [coachlessCustomersData],
  );
  const summarylessCoachVisits = useMemo(
    () => (summarylessCoachVisitsData?.getCoachVisits?.coachVisits || []) as GraphqlCoachVisit[],
    [summarylessCoachVisitsData],
  );
  const unappointedServiceRequests = useMemo(
    () =>
      (unappointedServiceRequestsData?.getServiceRequests?.serviceRequests ||
        []) as GraphqlServiceRequest[],
    [unappointedServiceRequestsData],
  );
  const pendingServiceRequests = useMemo(
    () =>
      (pendingServiceRequestsData?.getServiceRequests?.serviceRequests ||
        []) as GraphqlServiceRequest[],
    [pendingServiceRequestsData],
  );
  const paymentFailedServiceRequests = useMemo(
    () =>
      (paymentFailedServiceRequestsData?.getServiceRequests?.serviceRequests ||
        []) as GraphqlServiceRequest[],
    [paymentFailedServiceRequestsData],
  );
  const waitingForCustomerServiceRequests = useMemo(
    () =>
      (waitingForCustomerServiceRequestsData?.getServiceRequests?.serviceRequests ||
        []) as GraphqlServiceRequest[],
    [waitingForCustomerServiceRequestsData],
  );
  const upcomingUnconfirmedServiceRequests = useMemo(
    () =>
      [...(upcomingUnconfirmedServiceRequestsData?.getServiceRequests?.serviceRequests || [])].sort(
        (serviceRequestA, serviceRequestB) => {
          const appointmentRequestA = serviceRequestA?.AppointmentRequests?.find(
            (appointmentRequest) => appointmentRequest.status === AppointmentRequestStatus.Assigned,
          );
          const appointmentRequestB = serviceRequestB?.AppointmentRequests?.find(
            (appointmentRequest) => appointmentRequest.status === AppointmentRequestStatus.Assigned,
          );
          return (
            +(appointmentRequestA?.scheduledAt || 0) - +(appointmentRequestB?.scheduledAt || 0)
          );
        },
      ) as GraphqlServiceRequest[],
    [upcomingUnconfirmedServiceRequestsData],
  );
  const needsAssignmentServiceRequests = useMemo(
    () =>
      (needsAssignmentServiceRequestsData?.getServiceRequests?.serviceRequests ||
        []) as GraphqlServiceRequest[],
    [needsAssignmentServiceRequestsData],
  );
  const rejectedCircleRequests = useMemo(
    () =>
      (rejectedCircleRequestsData?.getCircleRequests?.circleRequests ||
        []) as GraphqlCircleRequest[],
    [rejectedCircleRequestsData],
  );
  const waitingForCircleMemberCircleRequests = useMemo(
    () =>
      (waitingForCircleMemberCircleRequestsData?.getCircleRequests?.circleRequests ||
        []) as GraphqlCircleRequest[],
    [waitingForCircleMemberCircleRequestsData],
  );
  const pendingContactRequestsSocialEvents = useMemo(
    () =>
      (pendingContactRequestsSocialEventsData?.getSocialEvents?.socialEvents ||
        []) as GraphqlSocialEvent[],
    [pendingContactRequestsSocialEventsData],
  );
  // END MEMOIZED RESPONSE DATA

  // BEGIN MEMOIZED COLUMNS OBJECTS
  const coachlessCustomersColumns: ColumnsType<GraphqlCustomer> = useMemo(
    () => [
      {
        title: <Trans i18nKey="manage.customers.table.columns.fullName" />,
        key: 'fullName',
        render: (_: any, record: GraphqlCustomer) => (
          <Link to={ROUTES.MANAGE_CUSTOMER.replace(':customerId', String(record.id))}>
            {record.fullName}
          </Link>
        ),
      },
      {
        title: <Trans i18nKey="manage.customers.table.columns.userName" />,
        key: 'userName',
        render: (_: any, record: any) => (
          <div>
            {record?.users
              ?.filter((user: { user: GraphqlUser }) => user?.user?.role !== GlobalRole.Coach)
              .map?.((user: { user: GraphqlUser }) => (
                <div>
                  <Link to={ROUTES.MANAGE_USER.replace(':userId', String(user?.user.id))}>
                    {user?.user?.fullName}
                  </Link>
                </div>
              ))}
          </div>
        ),
      },
      {
        title: <Trans i18nKey="manage.customers.table.columns.createdAt" />,
        key: 'createdAt',
        render: (_: any, record: GraphqlCustomer) => formatISOtoMMDDYYYY(record.createdAt),
      },
    ],
    [],
  );
  const summarylessCoachVisitsColumns: ColumnsType<GraphqlCoachVisit> = useMemo(
    () => [
      {
        title: <Trans i18nKey="manage.coachVisits.columns.id" />,
        key: 'id',
        render: (_: any, record: GraphqlCoachVisit) => (
          <Link to={ROUTES.MANAGE_COACH_VISIT.replace(':activityId', String(record.id))}>
            {String(record.id).padStart(ID_MIN_LENGTH, '0')}
          </Link>
        ),
      },
      {
        title: <Trans i18nKey="manage.coachVisits.columns.account" />,
        key: 'requestedBy',
        render: (_: any, record: GraphqlCoachVisit) => (
          <Link to={ROUTES.MANAGE_CUSTOMER.replace(':customerId', String(record?.Customer?.id))}>
            {record?.Customer?.fullName}
          </Link>
        ),
      },
      {
        title: <Trans i18nKey="manage.coachVisits.columns.coach" />,
        key: 'coachId',
        render: (_: any, record: GraphqlCoachVisit) => (
          <Link to={ROUTES.MANAGE_USER.replace(':userId', String(record?.Coach?.id))}>
            {record?.Coach?.fullName}
          </Link>
        ),
      },
      /* I don't think we need a status column
      {
        title: <Trans i18nKey="manage.coachVisits.columns.status" />,
        key: 'status',
        render: (_: any, record: GraphqlCoachVisit) => (
          <ServiceRequestStatusTag status={record.status as unknown as ServiceRequestStatus} />
        ),
      },
      */
      {
        title: <Trans i18nKey="manage.coachVisits.columns.scheduledAt" />,
        key: 'scheduledAtFrom',
        render: (_: any, record: GraphqlCoachVisit) =>
          record.scheduledAt ? formatDateAndTimeInTimezone(record.scheduledAt) : '',
      },
    ],
    [formatDateAndTimeInTimezone],
  );
  const serviceRequestsColumns: ColumnsType<GraphqlServiceRequest> = useMemo(
    () => [
      {
        title: <Trans i18nKey="manage.serviceRequests.columns.id" />,
        key: 'id',
        render: (_: any, record: GraphqlServiceRequest) => (
          <Link to={ROUTES.MANAGE_SERVICE_REQUEST.replace(':activityId', String(record.id))}>
            {String(record.id).padStart(ID_MIN_LENGTH, '0')}
          </Link>
        ),
      },
      {
        title: <Trans i18nKey="manage.serviceRequests.columns.account" />,
        key: 'account',
        render: (_: any, record: GraphqlServiceRequest) => (
          <Link to={ROUTES.MANAGE_CUSTOMER.replace(':customerId', String(record?.Customer?.id))}>
            {record?.Customer?.fullName}
          </Link>
        ),
      },
      {
        title: <Trans i18nKey="manage.serviceRequests.columns.type" />,
        key: 'category',
        render: (_: any, record: GraphqlServiceRequest) => (
          <Trans i18nKey={`serviceTypes.${record.category}`} />
        ),
      },
      {
        title: <Trans i18nKey="manage.serviceRequests.columns.createdAt" />,
        key: 'createdAt',
        render: (_: any, record: GraphqlServiceRequest) => formatISOtoMMDDYYYY(record.createdAt),
      },
      /* I don't think we need a status column
      {
        title: <Trans i18nKey="manage.serviceRequests.columns.status" />,
        key: 'status',
        render: (_: any, record: GraphqlServiceRequest) => (
          <ServiceRequestStatusTag status={record.status as ServiceRequestStatus} />
        ),
      },
      */
    ],
    [],
  );
  const upcomingUnconfirmedServiceRequestsColumns: ColumnsType<GraphqlServiceRequest> = useMemo(
    () => [
      ...serviceRequestsColumns.slice(0, 3),
      {
        title: <Trans i18nKey="manage.serviceRequests.columns.scheduledAt" />,
        key: 'scheduledAt',
        render: (_: any, record: GraphqlServiceRequest) => {
          const scheduledAt = record?.AppointmentRequests?.find(
            (appointmentRequest) => appointmentRequest.status === AppointmentRequestStatus.Assigned,
          )?.scheduledAt;
          return (
            <>
              <div>{formatDateAndTimeInTimezone(scheduledAt)}</div>
              <div>({dayjs(scheduledAt).fromNow()})</div>
            </>
          );
        },
      },
    ],
    [serviceRequestsColumns, formatDateAndTimeInTimezone],
  );
  const needsAssignmentServiceRequestsColumns: ColumnsType<GraphqlServiceRequest> = useMemo(
    () => [
      ...serviceRequestsColumns.slice(0, 2),
      {
        title: <Trans i18nKey="manage.serviceRequests.columns.agentsRequested" />,
        key: 'agentsRequested',
        render: (_: any, record: GraphqlServiceRequest) => record?.AppointmentRequests?.length || 0,
      },
      {
        title: <Trans i18nKey="manage.serviceRequests.columns.agentsResponded" />,
        key: 'agentsResponded',
        render: (_: any, record: GraphqlServiceRequest) =>
          record?.AppointmentRequests?.filter((appointmentRequest) =>
            [AppointmentRequestStatus.Accepted, AppointmentRequestStatus.Declined].includes(
              appointmentRequest.status,
            ),
          )?.length || 0,
      },
    ],
    [serviceRequestsColumns],
  );
  const circleRequestsColumns: ColumnsType<GraphqlCircleRequest> = useMemo(
    () => [
      {
        title: <Trans i18nKey="manage.serviceRequests.columns.id" />,
        key: 'id',
        render: (_: any, record: GraphqlCircleRequest) => (
          <Link to={ROUTES.MANAGE_CIRCLE_REQUEST.replace(':activityId', String(record.id))}>
            {String(record.id).padStart(ID_MIN_LENGTH, '0')}
          </Link>
        ),
      },
      {
        title: <Trans i18nKey="manage.serviceRequests.columns.account" />,
        key: 'account',
        render: (_: any, record: GraphqlCircleRequest) => (
          <Link to={ROUTES.MANAGE_CUSTOMER.replace(':customerId', String(record?.Customer?.id))}>
            {record?.Customer?.fullName}
          </Link>
        ),
      },
      {
        title: <Trans i18nKey="manage.serviceRequests.columns.type" />,
        key: 'category',
        render: (_: any, record: GraphqlCircleRequest) => (
          <Trans i18nKey={`serviceTypes.${record.category}`} />
        ),
      },
      {
        title: <Trans i18nKey="manage.serviceRequests.columns.createdAt" />,
        key: 'createdAt',
        render: (_: any, record: GraphqlCircleRequest) => formatISOtoMMDDYYYY(record.createdAt),
      },
      /* I don't think we need a status column
      {
        title: <Trans i18nKey="manage.serviceRequests.columns.status" />,
        key: 'status',
        render: (_: any, record: GraphqlCircleRequest) => (
          <ServiceRequestStatusTag status={record.status as ServiceRequestStatus} />
        ),
      },
      */
    ],
    [],
  );
  const pendingContactRequestsColumns: ColumnsType<GraphqlSocialEvent> = useMemo(
    () => [
      {
        title: <Trans i18nKey="manage.socialEvents.columns.id" />,
        key: 'id',
        render: (_: any, record) => (
          <Link to={ROUTES.MANAGE_SOCIAL_EVENT.replace(':activityId', String(record.id))}>
            {String(record.id).padStart(ID_MIN_LENGTH, '0')}
          </Link>
        ),
      },
      {
        title: <Trans i18nKey="manage.socialEvents.columns.pendingRequests" />,
        key: 'pendingRequests',
        render: (_: any, record) =>
          record?.Participants?.map((participant) =>
            participant?.ContactRequests?.filter(
              (contactRequest) => contactRequest?.status === ContactRequestStatus.Pending,
            ),
          )?.flat()?.length,
      },
      {
        title: <Trans i18nKey="manage.socialEvents.columns.allRequests" />,
        key: 'allRequests',
        render: (_: any, record) =>
          record?.Participants?.map((participant) => participant?.ContactRequests)?.flat()?.length,
      },
      {
        title: <Trans i18nKey="manage.socialEvents.columns.oldestPending" />,
        key: 'oldestPending',
        render: (_: any, record) =>
          dayjs
            .min(
              record?.Participants?.map((participant) =>
                participant?.ContactRequests?.filter(
                  (contactRequest) => contactRequest?.status === ContactRequestStatus.Pending,
                ).map((contactRequest) => dayjs(contactRequest?.createdAt)),
              )?.flat(),
            )
            .fromNow(),
      },
    ],
    [],
  );
  // END MEMOIZED COLUMNS OBJECTS

  if (
    coachlessCustomersLoading ||
    summarylessCoachVisitsLoading ||
    unappointedServiceRequestsLoading ||
    pendingServiceRequestsLoading ||
    paymentFailedServiceRequestsLoading ||
    waitingForCustomerServiceRequestsLoading ||
    upcomingUnconfirmedServiceRequestsLoading ||
    needsAssignmentServiceRequestsLoading ||
    rejectedCircleRequestsLoading ||
    waitingForCircleMemberCircleRequestsLoading ||
    pendingContactRequestsSocialEventsLoading
  ) {
    return <Loading />;
  }

  if (
    coachlessCustomersError ||
    summarylessCoachVisitsError ||
    unappointedServiceRequestsError ||
    pendingServiceRequestsError ||
    paymentFailedServiceRequestsError ||
    waitingForCustomerServiceRequestsError ||
    upcomingUnconfirmedServiceRequestsError ||
    needsAssignmentServiceRequestsError ||
    rejectedCircleRequestsError ||
    waitingForCircleMemberCircleRequestsError ||
    pendingContactRequestsSocialEventsError
  ) {
    return (
      <GenericApolloError
        error={
          (coachlessCustomersError ||
            summarylessCoachVisitsError ||
            unappointedServiceRequestsError ||
            pendingServiceRequestsError ||
            paymentFailedServiceRequestsError ||
            waitingForCustomerServiceRequestsError ||
            upcomingUnconfirmedServiceRequestsError ||
            needsAssignmentServiceRequestsError ||
            rejectedCircleRequestsError ||
            waitingForCircleMemberCircleRequestsError ||
            pendingContactRequestsSocialEventsError) as ApolloError
        }
      />
    );
  }

  return (
    <div style={{ maxWidth: 1800, margin: '0 auto' }}>
      <Typography.Title>
        <Trans i18nKey="manage.dashboard.headers.page" />
      </Typography.Title>
      <ResponsiveMasonry columnsCountBreakPoints={{ 350: 1, 1361: 2 }}>
        <Masonry gutter="20px">
          <Card title={i18n.t('manage.dashboard.headers.coachlessCustomers')}>
            <Table
              scroll={{ y: 500 }}
              locale={{ emptyText: i18n.t('fallbacks.noData') as string }}
              pagination={false}
              dataSource={coachlessCustomers}
              columns={coachlessCustomersColumns}
            />
          </Card>
          <Card title={i18n.t('manage.dashboard.headers.summarylessCoachVisits')}>
            <Table
              scroll={{ y: 500 }}
              locale={{ emptyText: i18n.t('fallbacks.noData') as string }}
              pagination={false}
              dataSource={summarylessCoachVisits}
              columns={summarylessCoachVisitsColumns}
            />
          </Card>
          {serviceRequestEnabled && (
            <>
              <Card title={i18n.t('manage.dashboard.headers.unappointedServiceRequests')}>
                <Table
                  scroll={{ y: 500 }}
                  locale={{ emptyText: i18n.t('fallbacks.noData') as string }}
                  pagination={false}
                  dataSource={unappointedServiceRequests}
                  columns={serviceRequestsColumns}
                />
              </Card>
              <Card title={i18n.t('manage.dashboard.headers.pendingServiceRequests')}>
                <Table
                  scroll={{ y: 500 }}
                  locale={{ emptyText: i18n.t('fallbacks.noData') as string }}
                  pagination={false}
                  dataSource={pendingServiceRequests}
                  columns={serviceRequestsColumns}
                />
              </Card>
              <Card title={i18n.t('manage.dashboard.headers.paymentFailedServiceRequests')}>
                <Table
                  scroll={{ y: 500 }}
                  locale={{ emptyText: i18n.t('fallbacks.noData') as string }}
                  pagination={false}
                  dataSource={paymentFailedServiceRequests}
                  columns={serviceRequestsColumns}
                />
              </Card>
              <Card title={i18n.t('manage.dashboard.headers.waitingForCustomerServiceRequests')}>
                <Table
                  scroll={{ y: 500 }}
                  locale={{ emptyText: i18n.t('fallbacks.noData') as string }}
                  pagination={false}
                  dataSource={waitingForCustomerServiceRequests}
                  columns={serviceRequestsColumns}
                />
              </Card>
              <Card title={i18n.t('manage.dashboard.headers.upcomingUnconfirmedServiceRequests')}>
                <Table
                  scroll={{ y: 500 }}
                  locale={{ emptyText: i18n.t('fallbacks.noData') as string }}
                  pagination={false}
                  dataSource={upcomingUnconfirmedServiceRequests}
                  columns={upcomingUnconfirmedServiceRequestsColumns}
                />
              </Card>
              <Card title={i18n.t('manage.dashboard.headers.needsAssignmentServiceRequests')}>
                <Table
                  scroll={{ y: 500 }}
                  locale={{ emptyText: i18n.t('fallbacks.noData') as string }}
                  pagination={false}
                  dataSource={needsAssignmentServiceRequests}
                  columns={needsAssignmentServiceRequestsColumns}
                />
              </Card>
            </>
          )}
          {circleRequestEnabled && (
            <>
              <Card title={i18n.t('manage.dashboard.headers.rejectedCircleRequests')}>
                <Table
                  scroll={{ y: 500 }}
                  locale={{ emptyText: i18n.t('fallbacks.noData') as string }}
                  pagination={false}
                  dataSource={rejectedCircleRequests}
                  columns={circleRequestsColumns}
                />
              </Card>
              <Card title={i18n.t('manage.dashboard.headers.waitingForCircleMemberCircleRequests')}>
                <Table
                  scroll={{ y: 500 }}
                  locale={{ emptyText: i18n.t('fallbacks.noData') as string }}
                  pagination={false}
                  dataSource={waitingForCircleMemberCircleRequests}
                  columns={circleRequestsColumns}
                />
              </Card>
            </>
          )}
          <Card title={i18n.t('manage.dashboard.headers.pendingContactRequests')}>
            <Table
              scroll={{ y: 500 }}
              locale={{ emptyText: i18n.t('fallbacks.noData') as string }}
              pagination={false}
              dataSource={pendingContactRequestsSocialEvents}
              columns={pendingContactRequestsColumns}
            />
          </Card>
        </Masonry>
      </ResponsiveMasonry>
    </div>
  );
}

export default Dashboard;
