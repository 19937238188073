import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import {
  CompanyFilter,
  CompanySortInput,
  CompanySortableFields,
  GraphqlAgent,
  GraphqlCompany,
  InputMaybe,
  PaginationMeta,
  ServiceRequestCategoriesEnum,
  SortOrder,
  useGetCompaniesQuery,
  useListRequestCategoriesQuery,
} from '@graphql/generated';

import ROUTES from '@constants/routes';
import { DEFAULT_SEARCH_DEBOUNCE_TIME } from '@constants/input';

import { Row } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { FilterValue, SorterResult, TablePaginationConfig } from 'antd/es/table/interface';
import Input from '@components/Input';
import AgentLink from '@components/AgentLink';
import EntityRating from '@components/Rate/EntityRating';
import GenericApolloError from '@components/GenericApolloError';
import Table, { DEFAULT_PAGE_SIZE, PAGE_SIZES } from '@components/Table/Table';

type CompaniesTableProps = {
  searchDebounceTime?: number;
};

function CompaniesTable({
  searchDebounceTime = DEFAULT_SEARCH_DEBOUNCE_TIME,
}: CompaniesTableProps) {
  const i18n = useTranslation();
  const [searchCompaniesText, setSearchCompaniesText] = useState('');
  const [filters, setFilters] = useState<CompanyFilter>({
    text: searchCompaniesText,
  });
  const [sortOptions, setSortOptions] = useState<InputMaybe<CompanySortInput>>();
  const [pageSize, setPageSize] = useState(DEFAULT_PAGE_SIZE);
  const [page, setPage] = useState(1);
  const {
    data: getCompaniesQuery,
    error,
    loading,
    previousData: getCompaniesPreviousData,
  } = useGetCompaniesQuery({
    variables: { page, pageSize, filter: filters, sort: sortOptions },
  });
  const { data: serviceRequestCategories } = useListRequestCategoriesQuery({
    variables: { serviceRequest: true },
  });

  const companies =
    ((getCompaniesQuery || getCompaniesPreviousData)?.getCompanies.companies as GraphqlCompany[]) ||
    [];
  const companiesPagination =
    ((getCompaniesQuery || getCompaniesPreviousData)?.getCompanies.meta as PaginationMeta) || {};

  useEffect(() => {
    const timer = setTimeout(() => {
      setFilters((previousFilters) => ({ ...previousFilters, text: searchCompaniesText }));
    }, searchDebounceTime);
    return () => clearTimeout(timer);
  }, [searchCompaniesText, searchDebounceTime]);

  const columns: ColumnsType<GraphqlCompany> = useMemo(
    () => [
      {
        title: i18n.t('manage.companies.table.columns.name') as string,
        dataIndex: 'name',
        key: 'name',
        sorter: true,
        render: (_: any, record: GraphqlCompany) => (
          <Link to={ROUTES.MANAGE_COMPANY.replace(':companyId', String(record.id))}>
            {record.name}
          </Link>
        ),
      },
      {
        title: i18n.t(`manage.companies.table.columns.companyRating`) as string,
        dataIndex: 'rating',
        key: 'rating',
        sorter: true,
        render: (_: any, record: GraphqlCompany) => <EntityRating value={record.rating} />,
        width: 200,
      },
      {
        title: i18n.t(`manage.companies.table.columns.companyPhone`) as string,
        dataIndex: 'phoneNumber',
        key: 'phoneNumber',
        render: (_: any, record: GraphqlCompany) => record.phoneNumber,
      },
      {
        title: i18n.t('manage.companies.table.columns.fullName') as string,
        dataIndex: 'fullName',
        key: 'fullName',
        width: 200,
        render: (_: any, record: GraphqlCompany) =>
          record.Agents.map((agent: GraphqlAgent) => (
            <div key={agent.userId}>
              <AgentLink agent={agent} />
            </div>
          )),
      },
      {
        title: i18n.t('manage.companies.table.columns.address') as string,
        dataIndex: 'address',
        key: 'address',
        render: (_: any, record: GraphqlCompany) => (
          <div>
            {record?.addressLine1} {record?.addressLine2} {record?.city}, {record?.state}{' '}
            {record?.zipCode}
          </div>
        ),
      },
      {
        title: i18n.t('manage.companies.table.columns.serviceTypes') as string,
        dataIndex: 'serviceTypes',
        key: 'serviceTypes',
        render: (_: any, record: GraphqlCompany) => (
          <div>
            {Array.from(new Set(record.Agents.flatMap((agent) => agent.serviceTypes)))
              .map((serviceTypeEnum: string) => i18n.t(`serviceTypes.${serviceTypeEnum}`) as string)
              .join(', ')}
          </div>
        ),
        filters: serviceRequestCategories?.listRequestCategories?.map((category) => ({
          text: category.name,
          value: category.id,
        })),
      },
    ],
    [i18n, serviceRequestCategories?.listRequestCategories],
  );

  const onTableChange = (
    tablePagination: TablePaginationConfig,
    tableFilters: Record<string, FilterValue | null>,
    tableSorter: SorterResult<GraphqlCompany> | SorterResult<GraphqlCompany>[],
  ) => {
    const uniqueSorter = tableSorter as SorterResult<GraphqlCompany>;

    // Pagination
    setPage(tablePagination.current as number);
    setPageSize(tablePagination.pageSize as number);

    // Order
    if (uniqueSorter.order) {
      const direction = uniqueSorter.order === 'ascend' ? 'asc' : 'desc';
      setSortOptions({
        field: uniqueSorter.field as CompanySortableFields,
        direction: direction as SortOrder,
      });
    } else {
      setSortOptions(undefined);
    }

    // Filters
    const { serviceTypes } = tableFilters;
    setFilters((prevFilters) => ({
      ...prevFilters,
      serviceTypes: serviceTypes?.length
        ? (serviceTypes as ServiceRequestCategoriesEnum[])
        : undefined,
    }));
  };

  if (error) return <GenericApolloError error={error} />;

  return (
    <>
      <Row style={{ marginBottom: '20px' }}>
        <Input
          placeholder={i18n.t('manage.companies.search') as string}
          value={searchCompaniesText}
          onChange={(e) => setSearchCompaniesText(e.target.value)}
        />
      </Row>
      <Table
        loading={loading}
        columns={columns}
        dataSource={companies.map((company) => ({ ...company, key: company.id }))}
        onChange={onTableChange}
        pagination={{
          pageSize,
          pageSizeOptions: PAGE_SIZES,
          showSizeChanger: true,
          defaultCurrent: 1,
          current: companiesPagination?.currentPage,
          total: companiesPagination?.totalCount,
        }}
      />
    </>
  );
}

CompaniesTable.defaultProps = {
  searchDebounceTime: DEFAULT_SEARCH_DEBOUNCE_TIME,
};

export default CompaniesTable;
