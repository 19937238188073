/* eslint-disable react/no-unstable-nested-components */
import {
  FileImageOutlined,
  FileOutlined,
  FilePdfOutlined,
  FileZipOutlined,
  InboxOutlined,
  LoadingOutlined,
} from '@ant-design/icons';
import { useApolloClient } from '@apollo/client';
import { Card } from '@components/Card';
import { COOKIES_KEYS } from '@constants/cookies';
import {
  FilesFilter,
  FilesSort,
  GraphqlUser,
  InputMaybe,
  useGetFilesQuery,
} from '@graphql/generated';
import { GET_INTERNAL_FILES } from '@graphql/users';
import { CardProps, Space, Upload, UploadFile, message } from 'antd';
import { UploadFileStatus } from 'antd/es/upload/interface';
import axios, { isAxiosError } from 'axios';
import dayjs from 'dayjs';
import { ReactNode, useState } from 'react';
import { useCookies } from 'react-cookie';
import { Trans } from 'react-i18next';
import styled from 'styled-components';

const TitleFile = styled.div`
  font-weight: ${({ theme }) => theme.fontWeights.bold};
  font-size: ${({ theme }) => theme.fontSizes.small};
`;

const DescriptionFile = styled.span`
  font-weight: ${({ theme }) => theme.fontWeights.normal};
  font-size: ${({ theme }) => theme.fontSizes.small};
`;

const IMAGE_EXTENSIONS = [
  'apng',
  'avif',
  'gif',
  'jpg',
  'jpeg',
  'jfif',
  'pjpeg',
  'pjp',
  'png',
  'webp',
  'svg',
];

const COMPRESSED_EXTENSIONS = ['zip', 'rar', '7z', 'tar', 'gz', 'bz2', 'xz', 'sitx'];

const PDF_EXTENSIONS = ['pdf'];

interface UploadRequestBody {
  userId?: number;
  agentId?: number;
  customerId?: number;
}

interface EmployeeInternalFilesProps {
  employee: GraphqlUser;
  filter: InputMaybe<FilesFilter>;
  sort: InputMaybe<FilesSort>;
  page: number;
  pageSize: number;
  uploadRequestBody: UploadRequestBody;
  cardTitle?: ReactNode;
  cardBody?: ReactNode;
  cardProps?: CardProps;
}
export function EmployeeInternalFiles({
  employee,
  filter,
  sort,
  page,
  pageSize,
  uploadRequestBody,
  cardTitle,
  cardBody,
  cardProps,
}: EmployeeInternalFilesProps) {
  const client = useApolloClient();
  const [cookies] = useCookies([COOKIES_KEYS.ACCESS_TOKEN]);
  const accessToken = cookies[COOKIES_KEYS.ACCESS_TOKEN];
  const { data: getFilesQuery, loading } = useGetFilesQuery({
    variables: {
      filter,
      sort,
      page,
      pageSize,
    },
  });
  const [inProgressFiles, setInProgressFiles] = useState<UploadFile[]>([]);
  const [filesBeingDeleted, setFilesBeingDeleted] = useState<string[]>([]);
  const files = getFilesQuery?.getFiles?.files || [];

  const renderItem = (originNode: any, file: any) => {
    return (
      <div className="file-details-container">
        {originNode}
        <TitleFile>
          Uploaded by: <DescriptionFile> {file.uploadBy}</DescriptionFile>{' '}
        </TitleFile>

        <TitleFile>
          Date:{' '}
          <DescriptionFile>
            {dayjs(file.createdAt).format('MMMM D, YYYY @ hh:mm:ss')}
          </DescriptionFile>{' '}
        </TitleFile>
      </div>
    );
  };

  return (
    <Card
      title={
        cardTitle || (
          <Trans
            i18nKey="manage.employeeInternalFiles.title"
            values={{ fullName: employee.fullName }}
          />
        )
      }
      loading={loading}
      {...cardProps}
    >
      <Space direction="vertical" size="large">
        {cardBody || (
          <Trans i18nKey="manage.employeeInternalFiles.body" components={{ b: <b /> }} />
        )}
        <Upload.Dragger
          name="file"
          multiple={false}
          action={`${process.env.REACT_APP_API_URI}/file/upload`}
          headers={{
            Authorization: accessToken,
          }}
          data={uploadRequestBody as Record<string, unknown>}
          fileList={[
            ...inProgressFiles,
            ...files.map((file) => ({
              name: file.name,
              uid: String(file.id),
              status: filesBeingDeleted.includes(String(file.id))
                ? ('uploading' as UploadFileStatus)
                : ('done' as UploadFileStatus),
              url: String(file.downloadUrl),
              uploadBy: file.UploadBy?.fullName,
              createdAt: file.createdAt,
            })),
          ]}
          itemRender={renderItem}
          onChange={(info) => {
            const { status } = info.file;
            if (status !== 'uploading') {
              setInProgressFiles((prev) => prev.filter((file) => file.uid !== info.file.uid));
            }

            if (status === 'uploading') {
              setInProgressFiles((prev) => {
                if (!prev.find((inProgressFile) => inProgressFile.uid === info.file.uid)) {
                  return [...prev, info.file];
                }
                return prev;
              });
            }
            if (status === 'done') {
              message.success(
                <Trans
                  i18nKey="manage.employeeInternalFiles.files.successMessage"
                  values={{ fileName: info.file.name }}
                />,
              );
              client.refetchQueries({
                include: [GET_INTERNAL_FILES],
              });
            } else if (status === 'error') {
              message.error(
                <Trans
                  i18nKey="manage.employeeInternalFiles.files.errorMessage"
                  values={{ fileName: info.file.name, error: info.file.response.message }}
                />,
              );
            }
          }}
          onRemove={async (file) => {
            try {
              setFilesBeingDeleted((prev) => [...prev, file.uid]);
              await axios.delete(`${process.env.REACT_APP_API_URI}/file/${file.uid}`, {
                headers: {
                  Authorization: accessToken,
                },
              });
              client.refetchQueries({
                include: [GET_INTERNAL_FILES],
              });
            } catch (err) {
              if (isAxiosError(err)) {
                const axiosErr = err as any;
                message.error(
                  <Trans
                    i18nKey="manage.employeeInternalFiles.files.deleteFileErrorMessage"
                    values={{ fileName: file.name, error: axiosErr?.response?.data?.message }}
                  />,
                );
              }
            } finally {
              setFilesBeingDeleted((prev) => prev.filter((id) => id !== file.uid));
            }
          }}
          onDownload={async (file) => {
            try {
              // Fetch the file
              const response = await fetch(file.url as string);

              // Check if the request was successful
              if (response.status !== 200) {
                return await message.error(
                  <Trans
                    i18nKey="manage.employeeInternalFiles.files.downloadFileErrorMessage"
                    values={{ fileName: file.name }}
                  />,
                );
              }

              // Get the Blob data
              const blob = await response.blob();

              // Create a download link
              const downloadLink = document.createElement('a');
              downloadLink.href = URL.createObjectURL(blob);
              downloadLink.download = file.name;

              // Trigger the download
              document.body.appendChild(downloadLink);
              downloadLink.click();

              // Clean up
              setTimeout(() => {
                URL.revokeObjectURL(downloadLink.href);
                document.body.removeChild(downloadLink);
              }, 100);
              return await Promise.resolve();
            } catch {
              message.error(
                <Trans
                  i18nKey="manage.employeeInternalFiles.files.downloadErrorMessage"
                  values={{ fileName: file.name }}
                />,
              );
              return await Promise.resolve();
            }
          }}
          onPreview={(file) => {
            // Open in a new tab
            window.open(file.url, '_blank');
          }}
          showUploadList={{
            showDownloadIcon: true,
            showRemoveIcon: true,
          }}
          iconRender={(file) => {
            if (file.status === 'uploading') {
              return <LoadingOutlined />;
            }

            const extension = (file.name.split('.').pop() as string).toLowerCase();
            if (IMAGE_EXTENSIONS.includes(extension)) {
              return <FileImageOutlined />;
            }
            if (PDF_EXTENSIONS.includes(extension)) {
              return <FilePdfOutlined />;
            }
            if (COMPRESSED_EXTENSIONS.includes(extension)) {
              return <FileZipOutlined />;
            }

            return <FileOutlined />;
          }}
        >
          <p className="ant-upload-drag-icon">
            <InboxOutlined />
          </p>
          <p className="ant-upload-text">
            <Trans i18nKey="manage.employeeInternalFiles.files.body" />
          </p>
          <p className="ant-upload-hint" style={{ padding: '0 12px' }}>
            <Trans i18nKey="manage.employeeInternalFiles.files.hint" />
          </p>
        </Upload.Dragger>
      </Space>
    </Card>
  );
}

EmployeeInternalFiles.defaultProps = {
  cardTitle: null,
  cardBody: null,
  cardProps: {},
};
