export const QUERY_PARAMS = {
  DEFAULT_CONVERSATION_SELECTED: 'defaultConversationSelected',
};

export const ROUTES = {
  // External
  TERMS_OF_SERVICE: 'https://brya.com/terms-of-service',
  PRIVACY_POLICY: 'https://brya.com/privacy-policy',

  // Auth
  MANAGE_LOG_IN: '/manage/log-in',
  LOG_IN: '/log-in',
  SIGN_UP: '/sign-up',
  SIGN_UP_VERIFY_EMAIL: '/sign-up/verify-email',
  VERIFY_EMAIL: '/verify-email',
  MANAGE_VERIFY_EMAIL: '/manage/verify-email',
  MANAGE_RESET_PASSWORD: '/manage/reset-password',
  RESET_PASSWORD: '/reset-password',
  MANAGE_FORGOT_PASSWORD: '/manage/forgot-password',
  FORGOT_PASSWORD: '/forgot-password',
  SSO_LOGIN: '/login/redirect/:service',

  // Logged in
  LANDING: '/landing',
  PROS_STRIPE_ACCOUNT: '/pros/stripe-account',
  PROS_STRIPE_ONBOARDING: '/pros/stripe-onboarding',
  MANAGE: '/manage',
  MANAGE_USER: '/manage/users/:userId',
  MANAGE_MANAGEMENT_USERS: '/manage/management-users',
  MANAGE_MANAGEMENT_USERS_CREATE: '/manage/management-users/create',
  MANAGE_MANAGEMENT_USERS_EDIT: '/manage/management-users/:userId',
  MANAGE_CUSTOMERS: '/manage/customers',
  MANAGE_CUSTOMER: '/manage/customers/:customerId',
  MANAGE_PROS: '/manage/pros',
  MANAGE_AGENT: '/manage/agent/:agentId',
  MANAGE_COMPANY: '/manage/company/:companyId',
  MANAGE_SERVICE_REQUESTS: '/manage/service-requests',
  MANAGE_SERVICE_REQUEST_CREATE: '/manage/service-request/create',
  MANAGE_SERVICE_REQUEST: '/manage/service-request/:activityId',
  MANAGE_CIRCLE_REQUESTS: '/manage/circle-requests',
  MANAGE_CIRCLE_REQUEST_CREATE: '/manage/circle-request/create',
  MANAGE_CIRCLE_REQUEST: '/manage/circle-request/:activityId',
  MANAGE_SOCIAL_EVENTS: '/manage/social-events',
  MANAGE_SOCIAL_EVENT_CREATE: '/manage/social-event/create',
  MANAGE_SOCIAL_EVENT: '/manage/social-event/:activityId',
  MANAGE_COACH_VISITS: '/manage/coach-visits',
  MANAGE_COACH_VISIT_CREATE: '/manage/coach-visit/create',
  MANAGE_COACH_VISIT: '/manage/coach-visit/:activityId',
  MANAGE_CHAT: '/manage/chat',
  MANAGE_COMMUNITY: '/manage/community',
  MANAGE_FLAGGED_MESSAGES: '/manage/flagged-messages',
  MANAGE_COMMUNITY_CONVERSATION_DETAIL: '/manage/community/conversation/:conversationId',
  MANAGE_LEADS: '/manage/leads',
  MANAGE_SUGGESTED_ACTIVITIES: '/manage/suggested-activities',
};

export default ROUTES;
