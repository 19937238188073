import { gql } from '@apollo/client';

export const GET_NOTIFICATIONS_BY_USER_ID_QUERY = gql`
  query GetNotificationsByUserId(
    $filter: NotificationsFilter!
    $page: Int
    $pageSize: Int
    $userId: Int
  ) {
    getNotificationsByUserId(filter: $filter, page: $page, pageSize: $pageSize, userId: $userId) {
      notifications {
        id
        text
        emailSentAt
        pushSentAt
        smsSentAt
        readAt
        payload
        deeplink
        createdAt
        title
        type
        updatedAt
      }
      meta {
        currentPage
        isFirstPage
        isLastPage
        pageCount
        totalCount
      }
    }
  }
`;

export const CREATE_NOTIFICATION_MUTATION = gql`
  mutation CreateNotification($notification: CreateNotificationInput!) {
    createNotification(
      notification: $notification
      sendPush: true
      sendEmail: true
      sendSms: true
      sendWebpush: true
    ) {
      success
    }
  }
`;
