import { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import {
  AgentPreferedContactMethodEnum,
  AgentRole,
  ServiceRequestCategoriesEnum,
  useListRequestCategoriesQuery,
} from '@graphql/generated';

import { Form, InputNumber, Radio, Space } from 'antd';
import { FormItem } from '@components/Form';
import Button from '@components/Button';
import { Select } from '@components/Input/Select';
import Input, { InputEmail, InputNewPassword } from '@components/Input';

export interface AgentFormValues {
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string | null;
  internalRating: number | null;
  serviceTypes: ServiceRequestCategoriesEnum[];
  role: AgentRole;
  password?: string;
}

type AgentFormProps = {
  loading: boolean;
  onSubmit: (values: AgentFormValues) => Promise<void>;
  submitLabel: string | JSX.Element;
  initialFormValues?: AgentFormValues;
  editMode?: boolean;
};

export function AgentForm({
  onSubmit,
  submitLabel,
  loading,
  initialFormValues,
  editMode,
}: AgentFormProps) {
  const [form] = Form.useForm();
  const i18n = useTranslation();
  const { data: serviceRequestCategories } = useListRequestCategoriesQuery({
    variables: { serviceRequest: true },
  });
  const invalidServiceTypes = useMemo(
    () => new Error(i18n.t('forms.input.serviceTypes.errorMessage') as string),
    [i18n],
  );

  useEffect(() => {
    form.resetFields();
  }, [form, initialFormValues]);

  return (
    <Form
      name="agent-form"
      autoComplete="off"
      onFinish={onSubmit}
      form={form}
      initialValues={initialFormValues}
    >
      <FormItem
        label={i18n.t('forms.input.firstName.placeholder')}
        name="firstName"
        rules={[
          { required: true, message: i18n.t('forms.input.firstName.errorMessage') as string },
        ]}
      >
        <Input
          name="firstName"
          placeholder={i18n.t('forms.input.firstName.placeholder') as string}
        />
      </FormItem>
      <FormItem
        label={i18n.t('forms.input.lastName.placeholder')}
        name="lastName"
        rules={[{ required: true, message: i18n.t('forms.input.lastName.errorMessage') as string }]}
      >
        <Input name="lastName" placeholder={i18n.t('forms.input.lastName.placeholder') as string} />
      </FormItem>
      <InputEmail
        placeholder={i18n.t('forms.input.email.placeholder') as string}
        readOnly={editMode} // NOTE: email field can not be updated
      />
      <FormItem
        label={i18n.t('forms.input.phoneNumber.label')}
        name="phoneNumber"
        normalize={(value: string) => (!value ? null : value)} // NOTE: phone number as empty string is invalid for backend
      >
        <Input
          name="phoneNumber"
          placeholder={i18n.t('forms.input.phoneNumber.placeholder') as string}
        />
      </FormItem>
      <FormItem
        label={i18n.t('manage.agentDetails.details.fields.internalRating')}
        name="internalRating"
      >
        <InputNumber min={1} max={100} />
      </FormItem>
      <FormItem
        name="serviceTypes"
        label={i18n.t('forms.input.serviceTypes.label') as string}
        rules={[
          {
            validator: (_rule, serviceTypes) => {
              if (serviceTypes?.length > 0) {
                return Promise.resolve();
              }

              return Promise.reject(invalidServiceTypes);
            },
          },
        ]}
      >
        <Select
          mode="multiple"
          placeholder={i18n.t('forms.input.serviceTypes.placeholder') as string}
          optionFilterProp="label"
          options={serviceRequestCategories?.listRequestCategories?.map((category) => ({
            label: category.name,
            value: category.id,
          }))}
        />
      </FormItem>
      <FormItem
        name="preferedContactMethods"
        label={i18n.t('forms.input.preferedContactMethods.label') as string}
      >
        <Select
          mode="multiple"
          placeholder={i18n.t('forms.input.preferedContactMethods.placeholder') as string}
          optionFilterProp="label"
          options={Object.values(AgentPreferedContactMethodEnum).map((contactMethod) => ({
            value: contactMethod,
            label: i18n.t(`agentPreferedContactMethods.${contactMethod}`),
          }))}
        />
      </FormItem>
      <FormItem
        name="role"
        label={i18n.t('forms.input.role.label') as string}
        rules={[
          {
            required: true,
            message: i18n.t('forms.input.role.errorMessage') as string,
          },
        ]}
      >
        <Radio.Group disabled={editMode}>
          <Space direction="vertical">
            {Object.values(AgentRole).map((role) => (
              <Radio key={role} value={role}>
                {i18n.t(`roles.${role}`)}
              </Radio>
            ))}
          </Space>
        </Radio.Group>
      </FormItem>
      <InputNewPassword
        withConfirmPassword
        required={false}
        placeholder={i18n.t('forms.input.password.placeholder') as string}
      />
      <FormItem style={{ marginTop: '30px' }}>
        <Button loading={loading} htmlType="submit">
          {submitLabel}
        </Button>
      </FormItem>
    </Form>
  );
}

AgentForm.defaultProps = {
  initialFormValues: {},
  editMode: false,
};
