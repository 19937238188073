import { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { GraphqlAddresses, RideAddressInput, RideInput, RideType } from '@graphql/generated';

import { Typography, Switch } from 'antd';
import { CenterRow } from '@components/styled';
import Input from '@components/Input';
import Button from '@components/Button';
import Modal from '@components/Modal';

import LocationDropdownOther from './LocationDropdownOther';

type RideLocationsModalProps = {
  ride?: RideInput;
  onClose: () => void;
  onChange: (payload: any) => void;
  addresses: GraphqlAddresses[];
};

type ExpandedGraphqlAddress = (GraphqlAddresses | RideAddressInput) & {
  key: number;
};

function RideLocationsModal({ ride, onClose, onChange, addresses }: RideLocationsModalProps) {
  const i18n = useTranslation();
  const [waitTime, setWaitTime] = useState<string>('');
  const [locationIsRoundtrip, setLocationIsRoundtrip] = useState<boolean>(false);
  const [pickupAddress, setPickupAddress] = useState<
    GraphqlAddresses | RideAddressInput | undefined
  >(ride?.pickupAddress ?? addresses?.find((address) => address?.isDefault));
  const [dropoffAddress, setDropoffAddress] = useState<GraphqlAddresses | RideAddressInput | null>(
    ride?.dropoffAddress ?? null,
  );
  const [stops, setStops] = useState<ExpandedGraphqlAddress[]>(
    ride?.stops?.map((stop, index) => ({ ...stop, key: index })) || [],
  );

  const sanitizeAddress = ({
    addressLine1,
    addressLine2,
    city,
    state,
    zipCode,
    country,
    place,
    isFromAPI,
  }: GraphqlAddresses | RideAddressInput) => ({
    addressLine1,
    addressLine2,
    city,
    state,
    zipCode,
    country: country || 'US',
    place,
    isFromAPI: isFromAPI || false,
    isDefault: false,
  });

  return (
    <Modal
      width={750}
      closable
      onCancel={onClose}
      open
      onConfirm={() => {
        onChange({
          pickupAddress: pickupAddress ? sanitizeAddress(pickupAddress) : null,
          dropoffAddress: dropoffAddress ? sanitizeAddress(dropoffAddress) : null,
          type: locationIsRoundtrip ? RideType.RoundTrip : RideType.OneWay,
          stops: stops.map((stop) => sanitizeAddress(stop as GraphqlAddresses)),
          waitTime,
        });
        onClose();
      }}
      title={
        <Typography.Title level={2}>
          <Trans i18nKey="manage.serviceRequests.create.configureLocations" />
        </Typography.Title>
      }
    >
      <Switch
        unCheckedChildren={<Trans i18nKey="manage.serviceRequests.create.oneWay" />}
        checkedChildren={<Trans i18nKey="manage.serviceRequests.create.roundTrip" />}
        onChange={(val) => {
          setLocationIsRoundtrip(val);
          if (val === false) {
            setStops([]);
            setWaitTime('');
          }
        }}
        style={{ margin: '20px 0px' }}
      />

      <br />

      {locationIsRoundtrip && (
        <>
          <Typography.Text strong>
            <Trans i18nKey="forms.input.waitTime.label" />
          </Typography.Text>
          <Input
            placeholder={i18n.t('forms.input.waitTime.placeholder') as string}
            onChange={(e) => setWaitTime(e?.target?.value)}
            value={waitTime}
          />
        </>
      )}

      <LocationDropdownOther
        addresses={addresses}
        value={pickupAddress}
        onChange={(address) =>
          setPickupAddress(
            typeof address === 'number'
              ? (addresses?.find((a: GraphqlAddresses) => a.id === address) as GraphqlAddresses)
              : address,
          )
        }
        defaultToDefaultAddress
        title={i18n.t('forms.input.pickupAddress.label') as string}
        placeholder={i18n.t('forms.input.pickupAddress.placeholder') as string}
      />

      {stops.map((stop: any, index: number) => (
        <div key={stop.key}>
          <LocationDropdownOther
            addresses={addresses}
            value={stop}
            onChange={(address) => {
              const newAddress =
                typeof address === 'number'
                  ? (addresses?.find((a: GraphqlAddresses) => a.id === address) as GraphqlAddresses)
                  : address;
              // Make sure to copy the key over so we don't rerender
              newAddress.key = stop.key;
              setStops([...stops.slice(0, index), newAddress, ...stops.slice(index + 1)]);
            }}
            title={i18n.t('forms.input.stopAddress.label', { stopNumber: index + 1 }) as string}
            placeholder={
              i18n.t('forms.input.stopAddress.placeholder', { stopNumber: index + 1 }) as string
            }
            action={
              <button
                type="button"
                onClick={() => setStops(stops.slice(0, index).concat(stops.slice(index + 1)))}
              >
                🗑️
              </button>
            }
          />
        </div>
      ))}

      <LocationDropdownOther
        addresses={addresses}
        value={dropoffAddress}
        onChange={(address) =>
          setDropoffAddress(
            typeof address === 'number'
              ? (addresses?.find((a: GraphqlAddresses) => a.id === address) as GraphqlAddresses)
              : address,
          )
        }
        title={i18n.t('forms.input.dropoffAddress.label') as string}
        placeholder={i18n.t('forms.input.dropoffAddress.placeholder') as string}
      />

      {locationIsRoundtrip && (
        <CenterRow>
          <Button
            type="ghost"
            style={{ marginTop: 20, maxWidth: 400 }}
            onClick={() => {
              // Find the highest key among remaining stops, and increment
              const key = Math.max(...stops.map((stop) => stop.key), 0) + 1;
              setStops([...stops, { key } as ExpandedGraphqlAddress]);
            }}
          >
            <Trans i18nKey="manage.serviceRequests.create.addStop" />
          </Button>
        </CenterRow>
      )}
    </Modal>
  );
}

RideLocationsModal.defaultProps = {
  ride: null,
};

export default RideLocationsModal;
