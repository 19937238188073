import { EditOutlined, PlusOutlined } from '@ant-design/icons';
import { Card } from '@components/Card';
import GenericApolloError from '@components/GenericApolloError';
import {
  GraphqlInternalNote,
  GraphqlUser,
  InternalNotesSortableFields,
  SortOrder,
  useGetEmployeeInternalNotesQuery,
} from '@graphql/generated';
import { Button, Space } from 'antd';
import { useMemo, useState } from 'react';
import { Trans } from 'react-i18next';
import Table from '@components/Table/Table';
import ROUTES from '@constants/routes';
import { useUser } from '@hooks/appContext/useUser';
import { Link } from 'react-router-dom';
import EmployeeInternalNoteModal from './EmployeeInternalNoteModal';

interface EmployeeInternalNotesProps {
  employee: GraphqlUser;
}

interface InternalNoteModal {
  open: boolean;
  note?: GraphqlInternalNote | undefined;
}

export function EmployeeInternalNotes({ employee }: EmployeeInternalNotesProps) {
  const user = useUser();
  const [employeeInternalNoteModal, setEmployeeInternalNoteModal] = useState<InternalNoteModal>({
    open: false,
  });
  const {
    data: getEmployeeNotesQuery,
    loading,
    error,
  } = useGetEmployeeInternalNotesQuery({
    variables: {
      filter: {
        employeeId: employee.id,
      },
      sort: {
        direction: SortOrder.Asc,
        field: InternalNotesSortableFields.CreatedAt,
      },
      // TODO: implement pagination
      pagination: {
        limit: 1000,
        page: 1,
      },
    },
  });

  const notes = useMemo(
    () => getEmployeeNotesQuery?.getInternalNotes.results || ([] as GraphqlInternalNote[]),
    [getEmployeeNotesQuery],
  );

  if (error) return <GenericApolloError error={error} />;

  return (
    <>
      <Card
        title={
          <Trans
            i18nKey="manage.users.detail.employeeInternalNotes.title"
            values={{ fullName: employee.fullName }}
          />
        }
        loading={loading}
        actions={[
          <Button
            type="text"
            onClick={() => {
              setEmployeeInternalNoteModal({ open: true });
            }}
          >
            <Space size="small">
              <PlusOutlined />
              <Trans i18nKey="manage.users.detail.employeeInternalNotes.actions.add" />
            </Space>
          </Button>,
        ]}
        style={{
          maxWidth: 375,
        }}
      >
        <Space direction="vertical" size="large">
          <Trans
            i18nKey="manage.users.detail.employeeInternalNotes.body"
            components={{ b: <b /> }}
          />
          <Table
            size="small"
            dataSource={notes}
            columns={[
              {
                title: (
                  <Trans i18nKey="manage.users.detail.employeeInternalNotes.columns.addedBy" />
                ),
                key: 'addedBy',
                render: (_: any, record: GraphqlInternalNote) => (
                  <Link to={ROUTES.MANAGE_USER.replace(':userId', String(record?.Users?.id))}>
                    {record?.Users?.id === user?.id ? (
                      <Trans i18nKey="manage.users.detail.employeeInternalNotes.currentUserAuthor" />
                    ) : (
                      record?.Users?.fullName
                    )}
                  </Link>
                ),
              },
              {
                title: <Trans i18nKey="manage.users.detail.employeeInternalNotes.columns.note" />,
                dataIndex: 'note',
                key: 'note',
              },
              {
                title: <Trans i18nKey="manage.users.detail.employeeInternalNotes.columns.edit" />,
                key: 'edit',
                render: (_: any, record: GraphqlInternalNote) => (
                  <EditOutlined
                    onClick={() => setEmployeeInternalNoteModal({ open: true, note: record })}
                  />
                ),
              },
            ]}
            pagination={false}
          />
        </Space>
      </Card>
      <EmployeeInternalNoteModal
        employeeId={employee.id}
        open={employeeInternalNoteModal.open}
        note={employeeInternalNoteModal.note}
        onCancel={() => setEmployeeInternalNoteModal({ open: false })}
        onSuccess={() => setEmployeeInternalNoteModal({ open: false })}
      />
    </>
  );
}
