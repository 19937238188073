import React, { useMemo, useState } from 'react';

import { GraphqlAddresses, RideAddressInput } from '@graphql/generated';

import { Typography, Select, Space } from 'antd';
import { FlexRow } from '@components/styled';

import { stringifyAddress, getGraphqlAddressFromGooglePlaces } from '@utils/formatters/address';
import usePlacesService from 'react-google-autocomplete/lib/usePlacesAutocompleteService';

type LocationDropdownOtherProps = {
  addresses: GraphqlAddresses[];
  value?: GraphqlAddresses | RideAddressInput | number | null;
  containerStyle?: React.CSSProperties;
  onChange: (payload: any) => void;
  title?: string;
  placeholder: string;
  defaultToDefaultAddress?: boolean;
  action?: JSX.Element;
  disabled?: boolean;
};

function LocationDropdownOther({
  addresses,
  value,
  containerStyle,
  onChange,
  title,
  placeholder,
  defaultToDefaultAddress = false,
  action = <div />,
  disabled = false,
}: LocationDropdownOtherProps) {
  const { placesService, placePredictions, getPlacePredictions } = usePlacesService({
    apiKey: process.env.REACT_APP_GOOGLE_PLACES_KEY,
  });
  const [chosenAddress, setChosenAddress] = useState<string | number | undefined>(
    stringifyAddress(value as GraphqlAddresses) ??
      (defaultToDefaultAddress ? addresses.find((address) => address?.isDefault)?.id : undefined),
  );

  const addressOptions = useMemo(() => {
    return (
      placePredictions?.length
        ? placePredictions.map((address: any) => ({
            label: address.description,
            value: address.place_id,
          }))
        : addresses.map((address) => ({
            label: stringifyAddress(address),
            value: address.id,
          }))
    )
      .concat({
        label: stringifyAddress(value as GraphqlAddresses),
        value: stringifyAddress(value as GraphqlAddresses),
      })
      .filter((address) => address.label && address.value);
  }, [addresses, placePredictions, value]);

  return (
    <>
      {title && <Typography.Title level={5}>{title}</Typography.Title>}
      <FlexRow style={containerStyle}>
        <Select
          showSearch
          filterOption={false}
          onSearch={(val) => getPlacePredictions({ input: val as string })}
          placeholder={placeholder}
          style={{ width: '100%' }}
          disabled={disabled}
          // If we're reopening the dropdown, hide the previous autocompletes and show our saved addresses again
          onDropdownVisibleChange={(open) => open && getPlacePredictions({ input: '' })}
          onChange={async (val) => {
            setChosenAddress(val);
            if (typeof val === 'number') {
              onChange(val);
            } else {
              placesService?.getDetails(
                {
                  placeId: val,
                },
                (details: any) => onChange(getGraphqlAddressFromGooglePlaces(details)),
              );
            }
          }}
          options={addressOptions}
          value={chosenAddress}
          notFoundContent={<Space style={{ padding: '5px 12px' }}>Start Typing...</Space>}
        />
        {action}
      </FlexRow>
    </>
  );
}

LocationDropdownOther.defaultProps = {
  title: null,
  value: null,
  defaultToDefaultAddress: false,
  containerStyle: {},
  action: <div />,
  disabled: false,
};

export default LocationDropdownOther;
