import { useEffect } from 'react';
import { createBrowserRouter, json, redirect, RouterProvider } from 'react-router-dom';

// Get feature flags so some child components can render properly
import { GlobalRole, ManagementRole, useGetAllFeatureFlagsQuery } from '@graphql/generated';
import { useSetFeatureFlags } from '@hooks/appContext/useSetFeatureFlags';

// Import our Routes
import Login from '@routes/log-in';
import SignUp from '@routes/sign-up';
import SignUpVerifyEmail from '@routes/sign-up-verify-email';
import VerifyEmail from '@routes/verify-email';
import SSOAuth from '@routes/sso-auth';
import ResetPassword from '@routes/ResetPassword/reset-password';
import ForgotPassword from '@routes/ResetPassword/forgot-password';
import Landing from '@routes/landing';
import ROUTES from '@constants/routes';
import Manage from '@routes/manage';
import Dashboard from '@routes/dashboard';
import { ForbiddenPage, ProtectedComponent, ProtectedRoute } from '@components/ProtectedElements';
import ManagementUsers from '@routes/users/management-users';
import CreateManagementUser from '@routes/users/create-management-user';
import EditManagementUser from '@routes/users/edit-management-user';
import Customers from '@routes/customers';
import Customer from '@routes/customer';
import ManageChat from '@routes/manage/chat/chat';
import Pros from '@routes/pros/pros';
import Leads from '@routes/users/leads';
import SuggestedActivities from '@routes/suggestedActivities';
import UserDetail from '@routes/users/user-detail';
import CompanyDetail from '@routes/pros/company-detail';
import AgentDetailsPage from '@routes/pros/agent-details';
import ServiceRequests from '@routes/activities/service-requests';
import ServiceRequest from '@routes/activities/service-request';
import CircleRequests from '@routes/activities/circle-requests';
import CircleRequest from '@routes/activities/circle-request';
import SocialEvents from '@routes/activities/social-events';
import SocialEvent from '@routes/activities/social-event';
import CoachVisits from '@routes/activities/coach-visits';
import CoachVisit from '@routes/activities/coach-visit';
import CreateActivity from '@routes/activities/create-activity';
import Community from '@routes/manage/chat/community';
import ConversationDetail from '@routes/manage/chat/conversation-detail';
import StripeOnboarding from '@routes/pros/stripe-onboarding';
import StripeAccount from '@routes/pros/stripe-account';

import { ActivitiesTypes } from '@constants/activities';
import Loading from '@components/Loading';
import FlaggedMessages from '@routes/manage/chat/flagged-messages';

export type ResetPasswordLoaderData = {
  resetPasswordToken: string;
};

export function Router() {
  const { data: featureFlagsData, loading: loadingFeatureFlags } = useGetAllFeatureFlagsQuery();
  const setFeatureFlags = useSetFeatureFlags();

  useEffect(() => {
    if (featureFlagsData) {
      setFeatureFlags(featureFlagsData?.getAllFeatureFlags);
    }
    // Linter is complaining about not having setFeatureFlags in here, but adding it causes a loop
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [featureFlagsData]);

  if (loadingFeatureFlags) return <Loading />;

  const router = createBrowserRouter([
    {
      path: '/',
      loader: () => redirect('/log-in'),
      element: <Login />,
    },
    {
      path: ROUTES.LANDING,
      element: (
        <ProtectedRoute navigateTo={ROUTES.LOG_IN}>
          <Landing />
        </ProtectedRoute>
      ),
    },
    {
      path: ROUTES.PROS_STRIPE_ACCOUNT,
      element: (
        <ProtectedRoute allowedRoles={[GlobalRole.AdminAgent]} navigateTo={ROUTES.LOG_IN}>
          <StripeAccount />
        </ProtectedRoute>
      ),
    },
    {
      path: ROUTES.PROS_STRIPE_ONBOARDING,
      element: (
        <ProtectedRoute allowedRoles={[GlobalRole.AdminAgent]} navigateTo={ROUTES.LOG_IN}>
          <StripeOnboarding />
        </ProtectedRoute>
      ),
    },
    {
      path: ROUTES.LOG_IN,
      element: <Login />,
    },
    {
      path: ROUTES.SSO_LOGIN,
      element: <SSOAuth />,
    },
    {
      path: ROUTES.SIGN_UP,
      element: <SignUp />,
    },
    {
      path: ROUTES.SIGN_UP_VERIFY_EMAIL,
      element: <SignUpVerifyEmail />,
    },
    {
      path: ROUTES.VERIFY_EMAIL,
      element: <VerifyEmail />,
    },
    {
      path: ROUTES.RESET_PASSWORD,
      element: <ResetPassword />,
      loader: ({ request }) => {
        const resetPasswordToken = new URL(request.url).searchParams.get('token');
        if (!resetPasswordToken) {
          return redirect(ROUTES.LOG_IN);
        }

        return json({ resetPasswordToken }, 200);
      },
    },
    {
      path: ROUTES.FORGOT_PASSWORD,
      element: <ForgotPassword />,
    },
    {
      path: ROUTES.MANAGE_LOG_IN,
      element: <Login />,
    },
    {
      path: ROUTES.MANAGE_VERIFY_EMAIL,
      element: <VerifyEmail />,
    },
    {
      path: ROUTES.MANAGE_FORGOT_PASSWORD,
      element: <ForgotPassword />,
    },
    {
      path: ROUTES.MANAGE_RESET_PASSWORD,
      element: <ResetPassword />,
      loader: ({ request }) => {
        const resetPasswordToken = new URL(request.url).searchParams.get('token');
        if (!resetPasswordToken) {
          return redirect(ROUTES.MANAGE_LOG_IN);
        }

        return json({ resetPasswordToken }, 200);
      },
    },
    {
      path: ROUTES.MANAGE,
      element: (
        <ProtectedRoute>
          <Manage />
        </ProtectedRoute>
      ),
      children: [
        {
          path: ROUTES.MANAGE,
          element: (
            <ProtectedComponent
              allowedRoles={Object.values(ManagementRole)}
              fallback={<ForbiddenPage />}
            >
              <Dashboard />
            </ProtectedComponent>
          ),
        },
        {
          path: ROUTES.MANAGE_CHAT,
          element: (
            <ProtectedComponent
              allowedRoles={Object.values(ManagementRole)}
              fallback={<ForbiddenPage />}
            >
              <ManageChat />
            </ProtectedComponent>
          ),
        },
        {
          path: ROUTES.MANAGE_COMMUNITY,
          element: (
            <ProtectedComponent
              allowedRoles={Object.values(ManagementRole)}
              fallback={<ForbiddenPage />}
            >
              <Community />
            </ProtectedComponent>
          ),
        },
        {
          path: ROUTES.MANAGE_FLAGGED_MESSAGES,
          element: (
            <ProtectedComponent
              allowedRoles={Object.values(ManagementRole)}
              fallback={<ForbiddenPage />}
            >
              <FlaggedMessages />
            </ProtectedComponent>
          ),
        },
        {
          path: ROUTES.MANAGE_COMMUNITY_CONVERSATION_DETAIL,
          element: (
            <ProtectedComponent
              allowedRoles={Object.values(ManagementRole)}
              fallback={<ForbiddenPage />}
            >
              <ConversationDetail />
            </ProtectedComponent>
          ),
        },
        {
          path: ROUTES.MANAGE_MANAGEMENT_USERS,
          element: (
            <ProtectedComponent allowedRoles={[GlobalRole.Admin]} fallback={<ForbiddenPage />}>
              <ManagementUsers />
            </ProtectedComponent>
          ),
          children: [
            {
              path: ROUTES.MANAGE_MANAGEMENT_USERS_CREATE,
              element: (
                <ProtectedComponent allowedRoles={[GlobalRole.Admin]} fallback={<ForbiddenPage />}>
                  <CreateManagementUser />
                </ProtectedComponent>
              ),
            },
            {
              path: ROUTES.MANAGE_MANAGEMENT_USERS_EDIT,
              element: (
                <ProtectedComponent allowedRoles={[GlobalRole.Admin]} fallback={<ForbiddenPage />}>
                  <EditManagementUser />
                </ProtectedComponent>
              ),
            },
          ],
        },
        {
          path: ROUTES.MANAGE_CUSTOMERS,
          element: (
            <ProtectedComponent
              allowedRoles={[GlobalRole.Admin, GlobalRole.Coach, GlobalRole.Support]}
              fallback={<ForbiddenPage />}
            >
              <Customers />
            </ProtectedComponent>
          ),
        },
        {
          path: ROUTES.MANAGE_USER,
          element: (
            <ProtectedComponent
              allowedRoles={Object.values(ManagementRole)}
              fallback={<ForbiddenPage />}
            >
              <UserDetail />
            </ProtectedComponent>
          ),
        },
        {
          path: ROUTES.MANAGE_CUSTOMER,
          element: (
            <ProtectedComponent
              allowedRoles={Object.values(ManagementRole)}
              fallback={<ForbiddenPage />}
            >
              <Customer />
            </ProtectedComponent>
          ),
        },
        {
          path: ROUTES.MANAGE_PROS,
          element: (
            <ProtectedComponent
              allowedRoles={Object.values(ManagementRole)}
              fallback={<ForbiddenPage />}
            >
              <Pros />
            </ProtectedComponent>
          ),
        },
        {
          path: ROUTES.MANAGE_SERVICE_REQUESTS,
          element: (
            <ProtectedComponent
              allowedRoles={Object.values(ManagementRole)}
              fallback={<ForbiddenPage />}
            >
              <ServiceRequests />
            </ProtectedComponent>
          ),
        },
        {
          path: ROUTES.MANAGE_SERVICE_REQUEST,
          element: (
            <ProtectedComponent
              allowedRoles={Object.values(ManagementRole)}
              fallback={<ForbiddenPage />}
            >
              <ServiceRequest />
            </ProtectedComponent>
          ),
        },
        {
          path: ROUTES.MANAGE_SERVICE_REQUEST_CREATE,
          element: (
            <ProtectedComponent
              allowedRoles={Object.values(ManagementRole)}
              fallback={<ForbiddenPage />}
            >
              <CreateActivity type={ActivitiesTypes.SERVICE_REQUEST} />
            </ProtectedComponent>
          ),
        },
        {
          path: ROUTES.MANAGE_CIRCLE_REQUESTS,
          element: (
            <ProtectedComponent
              allowedRoles={Object.values(ManagementRole)}
              fallback={<ForbiddenPage />}
            >
              <CircleRequests />
            </ProtectedComponent>
          ),
        },
        {
          path: ROUTES.MANAGE_CIRCLE_REQUEST,
          element: (
            <ProtectedComponent
              allowedRoles={Object.values(ManagementRole)}
              fallback={<ForbiddenPage />}
            >
              <CircleRequest />
            </ProtectedComponent>
          ),
        },
        {
          path: ROUTES.MANAGE_CIRCLE_REQUEST_CREATE,
          element: (
            <ProtectedComponent
              allowedRoles={Object.values(ManagementRole)}
              fallback={<ForbiddenPage />}
            >
              <CreateActivity type={ActivitiesTypes.CIRCLE_REQUEST} />
            </ProtectedComponent>
          ),
        },
        {
          path: ROUTES.MANAGE_SOCIAL_EVENTS,
          element: (
            <ProtectedComponent
              allowedRoles={Object.values(ManagementRole)}
              fallback={<ForbiddenPage />}
            >
              <SocialEvents />
            </ProtectedComponent>
          ),
        },
        {
          path: ROUTES.MANAGE_SOCIAL_EVENT,
          element: (
            <ProtectedComponent
              allowedRoles={Object.values(ManagementRole)}
              fallback={<ForbiddenPage />}
            >
              <SocialEvent />
            </ProtectedComponent>
          ),
        },
        {
          path: ROUTES.MANAGE_COACH_VISITS,
          element: (
            <ProtectedComponent
              allowedRoles={Object.values(ManagementRole)}
              fallback={<ForbiddenPage />}
            >
              <CoachVisits />
            </ProtectedComponent>
          ),
        },
        {
          path: ROUTES.MANAGE_COACH_VISIT,
          element: (
            <ProtectedComponent
              allowedRoles={Object.values(ManagementRole)}
              fallback={<ForbiddenPage />}
            >
              <CoachVisit />
            </ProtectedComponent>
          ),
        },
        {
          path: ROUTES.MANAGE_AGENT,
          element: (
            <ProtectedComponent
              allowedRoles={Object.values(ManagementRole)}
              fallback={<ForbiddenPage />}
            >
              <AgentDetailsPage />
            </ProtectedComponent>
          ),
        },
        {
          path: ROUTES.MANAGE_COMPANY,
          element: (
            <ProtectedComponent
              allowedRoles={Object.values(ManagementRole)}
              fallback={<ForbiddenPage />}
            >
              <CompanyDetail />
            </ProtectedComponent>
          ),
        },
        {
          path: ROUTES.MANAGE_LEADS,
          element: (
            <ProtectedComponent
              allowedRoles={Object.values(ManagementRole)}
              fallback={<ForbiddenPage />}
            >
              <Leads />
            </ProtectedComponent>
          ),
        },
        {
          path: ROUTES.MANAGE_SUGGESTED_ACTIVITIES,
          element: (
            <ProtectedComponent
              allowedRoles={Object.values(ManagementRole)}
              fallback={<ForbiddenPage />}
            >
              <SuggestedActivities />
            </ProtectedComponent>
          ),
        },
      ],
    },
  ]);

  return <RouterProvider router={router} />;
}

export default Router;
