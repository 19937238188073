import Modal from '@components/Modal';
import { useStartConversationMutation } from '@graphql/generated';
import { Typography, message } from 'antd';
import { Trans, useTranslation } from 'react-i18next';
import { GET_CONVERSATIONS } from '@graphql/chat';
import { ChatForm, ChatFormValues } from './ChatForm';

interface CreateCommunityChatModalProps {
  open: boolean;
  onSuccessfulCreateChat: () => void;
  onCancel: () => void;
}
function CreateCommunityChatModal({
  onSuccessfulCreateChat,
  open,
  onCancel,
}: CreateCommunityChatModalProps) {
  const i18n = useTranslation();
  const [startConversation, { loading }] = useStartConversationMutation({
    refetchQueries: [GET_CONVERSATIONS],
  });

  const onCreateChat = async (values: ChatFormValues) => {
    try {
      await startConversation({
        variables: {
          input: {
            subject: values.chatName,
            userIds: values.chatParticipants.map((userId) => Number(userId)),
            custom: {
              role: values.chatParticipantsRole,
              chatType: values.chatType,
              city: values.city,
              zipCode: values.zipCode,
            },
          },
        },
      });
      message.success(i18n.t('forms.createChat.successMessage' as string));
      onSuccessfulCreateChat();
      return await Promise.resolve();
    } catch (err) {
      return Promise.reject(err);
    }
  };

  return (
    <Modal
      width={400}
      closable
      onCancel={() => (loading ? null : onCancel())}
      open={open}
      title={
        <Typography.Title level={2}>
          <Trans i18nKey="forms.createChat.title" />
        </Typography.Title>
      }
    >
      <ChatForm
        loading={loading}
        onSubmit={onCreateChat}
        submitLabel={<Trans i18nKey="forms.createChat.submitLabel" />}
      />
    </Modal>
  );
}

export default CreateCommunityChatModal;
