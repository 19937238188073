import { CustomerSortableFields, SortOrder, FiltersInput, GraphqlUser } from '@graphql/generated';

export const getCustomersQueryVariables = (
  user: GraphqlUser | null,
  searchCustomerText: string,
) => {
  return {
    fullName: searchCustomerText,
    sortField: CustomerSortableFields.CreatedAt,
    sortValue: SortOrder.Desc,
  } as FiltersInput;
};

export default getCustomersQueryVariables;
