import { ReactNode } from 'react';
import { Trans } from 'react-i18next';
import { Rate, Space } from 'antd';
import { HeartFilled } from '@ant-design/icons';
import { GetTopAgentsOutput } from '@graphql/generated';

function AgentConfidenceLevel({ value, text }: { value: 1 | 2 | 3; text: ReactNode }) {
  return (
    <Space size="small">
      <Rate style={{ color: 'red' }} count={3} value={value} character={<HeartFilled />} disabled />
      <span>{text}</span>
    </Space>
  );
}

export const renderAgentConfidenceLevel = (agent: GetTopAgentsOutput) => {
  if (agent?.customerFavorite)
    return (
      <AgentConfidenceLevel
        value={3}
        text={<Trans i18nKey="manage.agents.confidenceLevels.customerFavorite" />}
      />
    );

  if (agent?.trustedFavorite)
    return (
      <AgentConfidenceLevel
        value={2}
        text={<Trans i18nKey="manage.agents.confidenceLevels.trustedFavorite" />}
      />
    );

  if (agent?.trustedReviewed)
    return (
      <AgentConfidenceLevel
        value={1}
        text={<Trans i18nKey="manage.agents.confidenceLevels.trustedReviewed" />}
      />
    );

  return '';
};
