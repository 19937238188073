import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useFinishAuthIdMutation } from '@graphql/generated';
import Loading from '@components/Loading';

function Landing() {
  const navigate = useNavigate();
  const [finishAuthIdMutation] = useFinishAuthIdMutation();

  useEffect(() => {
    const finishAuthId = async () => {
      const authId = localStorage.getItem('authId');

      if (authId) {
        try {
          await finishAuthIdMutation({
            variables: { authId },
          });
        } catch (err) {
          /* Ignore as this shouldn't happen */
        }

        localStorage.removeItem('authId');
      }
    };

    finishAuthId();
  }, [navigate, finishAuthIdMutation]);

  return <Loading />;
}

export default Landing;
