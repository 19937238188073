import { FormItem } from '@components/Form';
import Button from '@components/Button';
import { Empty, Form, Spin } from 'antd';
import { Trans, useTranslation } from 'react-i18next';
import {
  GlobalRole,
  GraphqlUser,
  InputMaybe,
  SortOrder,
  UserFilter,
  UserSortableFields,
  useGetUsersQuery,
} from '@graphql/generated';
import { useEffect, useState } from 'react';
import { Select } from '@components/Input/Select';
import { DEFAULT_SEARCH_DEBOUNCE_TIME } from '@constants/input';

export interface AddParticipantToConversationFormValues {
  chatParticipant: GraphqlUser;
}

type AddParticipantToConversationFormProps = {
  loading: boolean;
  onSubmit: (values: AddParticipantToConversationFormValues) => Promise<void>;
  submitLabel: string | JSX.Element;
  allowedRoles: InputMaybe<GlobalRole[]>;
  currentParticipants: Partial<GraphqlUser>[];
};

export function AddParticipantToConversationForm({
  onSubmit,
  submitLabel,
  loading,
  allowedRoles,
  currentParticipants,
}: AddParticipantToConversationFormProps) {
  const [form] = Form.useForm();
  const i18n = useTranslation();
  const [searchUserText, setSearchUserText] = useState('');
  const [filters, setFilters] = useState<UserFilter>({
    text: searchUserText,
    roles: allowedRoles,
  });
  const {
    data: getUsersQuery,
    previousData,
    loading: isFetchingUsers,
  } = useGetUsersQuery({
    variables: {
      filter: filters,
      page: 1,
      pageSize: 100,
      sort: { field: UserSortableFields.FullName, direction: SortOrder.Asc },
    },
  });

  const users = (((getUsersQuery || previousData)?.getUsers.users as GraphqlUser[]) || []).filter(
    (user) => !currentParticipants.find((currentParticipant) => currentParticipant.id === user.id),
  );

  useEffect(() => {
    const timer = setTimeout(() => {
      setFilters((previousFilters) => ({ ...previousFilters, text: searchUserText }));
    }, DEFAULT_SEARCH_DEBOUNCE_TIME);
    return () => clearTimeout(timer);
  }, [searchUserText]);

  return (
    <Form
      name="add-participant-form"
      autoComplete="off"
      form={form}
      onFinish={async (values) => {
        await onSubmit({
          ...values,
          chatParticipant: users.find((user) => user.id === values.chatParticipant),
        });
        form.resetFields();
      }}
    >
      <FormItem
        name="chatParticipant"
        label={<Trans i18nKey="forms.input.chatParticipant.label" />}
        rules={[
          { required: true, message: i18n.t('forms.input.chatParticipant.errorMessage') as string },
        ]}
      >
        <Select
          placeholder={i18n.t('forms.input.chatParticipant.placeholder') as string}
          options={users.map((user) => ({
            label: `${user.fullName} (${i18n.t(`roles.${user.role}`)})`,
            value: user.id,
          }))}
          loading={isFetchingUsers}
          onSearch={(search) => {
            setSearchUserText(search);
          }}
          filterOption={false}
          showSearch
          onBlur={() => setSearchUserText('')}
          notFoundContent={
            isFetchingUsers ? (
              <Spin size="small" />
            ) : (
              <Empty
                image={Empty.PRESENTED_IMAGE_SIMPLE}
                description={
                  <Trans i18nKey="forms.addParticipantToConversation.noUsersFoundForAddingToThisConversation" />
                }
              />
            )
          }
        />
      </FormItem>

      <FormItem style={{ marginTop: '30px' }}>
        <Button loading={loading} htmlType="submit">
          {submitLabel}
        </Button>
      </FormItem>
    </Form>
  );
}
