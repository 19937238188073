import styled, { css } from 'styled-components';
import { Trans, TransProps, useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import ROUTES from '@constants/routes';

import { Row, Col } from 'antd';
import { LeftOutlined } from '@ant-design/icons';
import Button from '@components/Button';
import { ReactComponent as BryaLogo } from '@assets/logo.svg';
import { ReactComponent as GoogleLogo } from '@assets/google-logo.svg';
import { ReactComponent as FacebookLogo } from '@assets/facebook-logo.svg';
import { FEATURE_FLAGS } from '@constants/featureFlags';
import { useIsFeatureEnabled } from '@hooks/useIsFeatureEnabled';

export interface IBryaHeader {
  children?: JSX.Element;
  showBackToLoginButton?: boolean;
  hideSSOLogin?: boolean;
  googleSSOTitle?: string;
  facebookSSOTitle?: string;
  title?: string;
  subtitle?: string;
  subtitleProps?: TransProps<string>;
}

const BackToLogin = styled.div`
  text-align: left;
  margin-bottom: 72px;
  svg {
    margin-right: 4px;
  }
`;

const styledLogoCSS = css`
  height: 18px;
  width: 18px;
  margin-right: 5px;
`;

const StyledGoogleLogo = styled(GoogleLogo)`
  ${styledLogoCSS}
`;
const StyledFacebookLogo = styled(FacebookLogo)`
  ${styledLogoCSS}
`;

const Title = styled.h2`
  margin: 0;
  margin-bottom: 8px;
  font-weight: ${({ theme }) => theme.fontWeights.bold};
  font-size: 20px;
  line-height: 24px;
  color: #212121;
  text-align: left;
`;

const Subtitle = styled.p`
  margin: 0;
  margin-bottom: 25px;
  font-weight: ${({ theme }) => theme.fontWeights.normal};
  font-size: 14px;
  line-height: 20px;
  color: #616161;
  text-align: left;
`;

function BryaHeader({
  children,
  showBackToLoginButton = false,
  hideSSOLogin,
  googleSSOTitle = '',
  facebookSSOTitle = '',
  title = '',
  subtitle,
  subtitleProps,
}: IBryaHeader) {
  const i18n = useTranslation();

  const params = new URLSearchParams(window.location.search);
  const inviteToken = params.get('inviteToken');
  const inviteTokenParam = inviteToken ? `?inviteToken=${inviteToken}` : '';

  const facebookSSOEnabled = useIsFeatureEnabled(FEATURE_FLAGS.FACEBOOK_SSO);

  const googleSSOEnabled = useIsFeatureEnabled(FEATURE_FLAGS.GOOGLE_SSO);

  return (
    <div>
      {showBackToLoginButton ? (
        <BackToLogin>
          <Link to={ROUTES.LOG_IN}>
            <LeftOutlined />
            {i18n.t('logIn.cta.backToLogIn')}
          </Link>
        </BackToLogin>
      ) : (
        <BryaLogo style={{ margin: 50 }} />
      )}
      {title && (
        <Title>
          <Trans i18nKey={title} />
        </Title>
      )}
      {subtitle && (
        <Subtitle>
          <Trans i18nKey={subtitle} {...subtitleProps} />
        </Subtitle>
      )}
      {!hideSSOLogin && (facebookSSOEnabled || googleSSOEnabled) && (
        <Row gutter={[0, 10]}>
          {facebookSSOEnabled && (
            <Col span={24}>
              <Link to={`${process.env.REACT_APP_API_URI}/auth/facebook${inviteTokenParam}`}>
                <Button icon={<StyledFacebookLogo />} type="ghost">
                  {i18n.t(facebookSSOTitle)}
                </Button>
              </Link>
            </Col>
          )}
          {googleSSOEnabled && (
            <Col span={24}>
              <Link to={`${process.env.REACT_APP_API_URI}/auth/google${inviteTokenParam}`}>
                <Button icon={<StyledGoogleLogo />} type="ghost">
                  {i18n.t(googleSSOTitle)}
                </Button>
              </Link>
            </Col>
          )}
        </Row>
      )}
      {children}
    </div>
  );
}

BryaHeader.defaultProps = {
  children: undefined,
  showBackToLoginButton: false,
  hideSSOLogin: false,
  googleSSOTitle: undefined,
  facebookSSOTitle: undefined,
  title: '',
  subtitle: undefined,
  subtitleProps: undefined,
};

export default BryaHeader;
