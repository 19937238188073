import { Rate as AntdRate, Space } from 'antd';
import styled from 'styled-components';

const Rate = styled(AntdRate)`
  li {
    margin-inline-end: 0 !important;
  }
`;

type EntityRatingProps = {
  value: number | undefined | null;
  count?: number | undefined | null;
  hideNumber?: boolean;
};

function EntityRating({ value, count, hideNumber }: EntityRatingProps) {
  return (
    <Space>
      {hideNumber ? null : <span>{Number(value).toFixed(2)}</span>}
      <Rate value={value || undefined} allowHalf disabled />
      {hideNumber || !count ? null : <span>({Number(count)})</span>}
    </Space>
  );
}

EntityRating.defaultProps = {
  hideNumber: false,
  count: null,
};

export default EntityRating;
