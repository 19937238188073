import { useMemo } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { Input } from '@components/Input';
import { FormItem } from '@components/Form';
import { commonInputStyles } from './styles';

type InputPasswordProps = React.ComponentProps<typeof Input.Password>;

const StyledInputPassword = styled(Input.Password)`
  ${commonInputStyles}
  // NOTE: Input.Password renders a root <span> element
  input {
    background-color: transparent;
    line-height: ${({ theme }) => theme.fontSizes.medium}; // large font-size, medium line-height?
  }
`;

export function InputPassword(props: InputPasswordProps) {
  const { placeholder } = props;
  const i18n = useTranslation();
  const errorMessage = useMemo(() => i18n.t('forms.input.password.errorMessage'), [i18n]);

  return (
    <FormItem
      label="Password"
      name="password"
      rules={[
        {
          required: true,
          message: errorMessage,
        },
      ]}
    >
      <StyledInputPassword name="password" autoComplete="password" placeholder={placeholder} />
    </FormItem>
  );
}

export default InputPassword;
