import { useState } from 'react';
import styled from 'styled-components';
import { Trans, useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { CreateCompanyInput, useCreateCompanyMutation } from '@graphql/generated';
import { GET_COMPANIES_QUERY } from '@graphql/pros';

import { Form, Select, Typography } from 'antd';
import Modal from '@components/Modal';
import Button from '@components/Button';
import Input, { InputTextarea } from '@components/Input';
import LocationDropdownOther from '@components/Activities/LocationDropdownOther';

import { ROUTES } from '@constants/routes';

const FormItem = styled(Form.Item).attrs(() => ({
  labelCol: { span: 24 },
}))`
  .ant-form-item-label {
    padding: 0px !important;
  }
`;

function CreateCompanyModal({ onCancel }: { onCancel: () => void }) {
  const i18n = useTranslation();
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const [googleAutocompleteResult, setGoogleAutocompleteResult] = useState<any>(null);
  const [createCompanyMutation, { loading: createCompanyLoading }] = useCreateCompanyMutation({
    refetchQueries: [GET_COMPANIES_QUERY],
  });

  return (
    <Modal
      width={750}
      closable
      onCancel={onCancel}
      open
      title={
        <Typography.Title level={2}>
          <Trans i18nKey="manage.companies.new" />
        </Typography.Title>
      }
    >
      <Form
        form={form}
        initialValues={{ recurring: false }}
        onFinish={async (values) => {
          const response = await createCompanyMutation({
            variables: {
              input: {
                ...(values as CreateCompanyInput),
                ...googleAutocompleteResult,
                country: undefined,
                place: undefined,
                isFromAPI: undefined,
              },
            },
          });
          navigate(
            ROUTES.MANAGE_COMPANY.replace(':companyId', String(response?.data?.createCompany?.id)),
          );
        }}
      >
        <FormItem
          name="name"
          label={i18n.t('forms.input.fullName.label')}
          rules={[
            { required: true, message: i18n.t('forms.input.fullName.errorMessage') as string },
          ]}
        >
          <Input placeholder={String(i18n.t('forms.input.fullName.placeholder'))} />
        </FormItem>
        <FormItem name="hours" label={i18n.t('forms.input.hours.label')}>
          <InputTextarea placeholder={String(i18n.t('forms.input.hours.placeholder'))} />
        </FormItem>
        <FormItem
          name="phoneNumber"
          label={i18n.t('forms.input.phoneNumber.label')}
          rules={[
            { required: true, message: i18n.t('forms.input.phoneNumber.errorMessage') as string },
          ]}
        >
          <Input placeholder={String(i18n.t('forms.input.phoneNumber.placeholder'))} />
        </FormItem>
        <FormItem name="website" label={i18n.t('forms.input.website.label')}>
          <Input placeholder={String(i18n.t('forms.input.website.placeholder'))} />
        </FormItem>
        <FormItem
          name="isFromAPI"
          label={i18n.t('forms.input.addressId.label')}
          rules={[
            { required: true, message: i18n.t('forms.input.addressId.errorMessage') as string },
          ]}
        >
          <LocationDropdownOther
            placeholder={i18n.t('forms.input.addressId.placeholder')}
            addresses={[]}
            onChange={setGoogleAutocompleteResult}
          />
        </FormItem>
        <FormItem
          name="serviceZipCodes"
          label={i18n.t('forms.input.serviceZipCodes.label')}
          rules={[
            {
              required: true,
              message: i18n.t('forms.input.serviceZipCodes.errorMessage') as string,
            },
          ]}
        >
          <Select
            mode="tags"
            tokenSeparators={[',']}
            placeholder={i18n.t('forms.input.serviceZipCodes.placeholder')}
            style={{ minWidth: 280 }}
          />
        </FormItem>
        <FormItem>
          <Button type="primary" htmlType="submit" loading={createCompanyLoading}>
            <Trans i18nKey="manage.companies.create" />
          </Button>
        </FormItem>
      </Form>
    </Modal>
  );
}

export default CreateCompanyModal;
