import { gql } from '@apollo/client';

export const GET_ACTIVITY_TIMELINE_QUERY = gql`
  query GetActivityTimeline($orderIn: SortOrder!, $activityId: Int!) {
    getTimeline(orderIn: $orderIn, activityId: $activityId) {
      createdAt
      description
    }
  }
`;

export const GET_APPOINTMENT_REQUESTS_QUERY = gql`
  query GetAppointmentRequests($circleRequestId: Int, $serviceRequestId: Int) {
    getAppointmentRequests(circleRequestId: $circleRequestId, serviceRequestId: $serviceRequestId) {
      id
      userId
      serviceRequestId
      circleRequestId
      status
      createdAt
      updatedAt
      scheduledAt
      CreatedBy {
        id
        fullName
      }
      SentTo {
        id
        fullName
        Agent {
          deletedAt
        }
      }
      ServiceRequest {
        id
        availabilities {
          date
          from
          until
        }
        isAvailabilityFlexible
        tentativeSchedule
      }
      CircleRequest {
        id
        availabilities {
          date
          from
          until
        }
        isAvailabilityFlexible
        tentativeSchedule
      }
      AppointmentRequestPayments {
        id
        type
        amount
        estimateHours
        appointmentRequestId
      }
    }
  }
`;

export const GET_APPOINTMENT_REQUESTS_FILTERED_QUERY = gql`
  query GetAppointmentRequestsFiltered(
    $filter: AppointmentRequestFilter!
    $sort: AppointmentRequestSortInput
    $pageSize: Int
    $page: Int
  ) {
    getAppointmentRequestsFiltered(filter: $filter, sort: $sort, pageSize: $pageSize, page: $page) {
      appointmentRequests {
        id
        userId
        serviceRequestId
        circleRequestId
        status
        createdAt
        updatedAt
        scheduledAt
        CreatedBy {
          id
          fullName
        }
        SentTo {
          id
          fullName
        }
        ServiceRequest {
          id
          category
          status
          scheduledAt
          createdAt
          actionNeeded
          Customer {
            id
            fullName
          }
          Agent {
            userId
            deletedAt
            User {
              id
              fullName
            }
          }
          RequestedBy {
            id
            fullName
          }
          availabilities {
            date
            from
            until
          }
          isAvailabilityFlexible
        }
        AppointmentRequestPayments {
          id
          type
          amount
          estimateHours
          appointmentRequestId
        }
      }
      meta {
        currentPage
        isFirstPage
        isLastPage
        nextPage
        pageCount
        previousPage
        totalCount
      }
    }
  }
`;

export const UPDATE_APPOINTMENT_REQUEST_MUTATION = gql`
  mutation UpdateAppointmentRequest(
    $updateAppointmentRequestInput: UpdateAppointmentRequestInput!
  ) {
    updateAppointmentRequest(updateAppointmentRequestInput: $updateAppointmentRequestInput) {
      id
    }
  }
`;

export const CREATE_INTERNAL_NOTE_MUTATION = gql`
  mutation CreateInternalNote($input: CreateInternalNoteInput!) {
    createInternalNote(input: $input) {
      userId
      updatedAt
      serviceRequestId
      note
      id
      createdAt
      Users {
        id
        updatedAt
        createdAt
        fullName
      }
    }
  }
`;

export const UPDATE_INTERNAL_NOTE_MUTATION = gql`
  mutation UpdateInternalNote($input: CreateInternalNoteInput!, $updateInternalNoteId: Float!) {
    updateInternalNote(input: $input, id: $updateInternalNoteId) {
      createdAt
      id
      note
      serviceRequestId
      updatedAt
      userId
      Users {
        id
        fullName
        createdAt
        updatedAt
      }
    }
  }
`;

export const LIST_REQUEST_CATEGORIES = gql`
  query ListRequestCategories($serviceRequest: Boolean, $circleRequest: Boolean) {
    listRequestCategories(serviceRequest: $serviceRequest, circleRequest: $circleRequest) {
      id
      name
      circleRequest
      serviceRequest
      createdAt
      updatedAt
    }
  }
`;

export const CREATE_APPOINTMENT_REQUEST_PAYMENT = gql`
  mutation CreateAppointmentRequestPayment(
    $appointmentPaymentInput: CreateAppointmentPaymentInput!
  ) {
    createAppointmentRequestPayment(appointmentPaymentInput: $appointmentPaymentInput) {
      id
    }
  }
`;

export const UPDATE_APPOINTMENT_REQUEST_PAYMENT = gql`
  mutation UpdateAppointmentRequestPayment(
    $appointmentRequestPaymentId: Int!
    $appointmentPaymentInput: UpdateAppointmentPaymentInput!
  ) {
    updateAppointmentRequestPayment(
      appointmentRequestPaymentId: $appointmentRequestPaymentId
      appointmentPaymentInput: $appointmentPaymentInput
    ) {
      id
    }
  }
`;
