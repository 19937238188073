import { gql } from '@apollo/client';

export const GET_SERVICE_REQUESTS_QUERY = gql`
  query GetServiceRequests(
    $filter: ServiceRequestFilter!
    $sort: ServiceRequestSortInput
    $pageSize: Int
    $page: Int
  ) {
    getServiceRequests(filter: $filter, sort: $sort, pageSize: $pageSize, page: $page) {
      meta {
        currentPage
        totalCount
      }
      serviceRequests {
        id
        category
        status
        scheduledAt
        createdAt
        actionNeeded
        Customer {
          id
          fullName
        }
        Agent {
          userId
          deletedAt
          User {
            id
            fullName
          }
        }
        RequestedBy {
          id
          fullName
        }
        AppointmentRequests {
          id
          scheduledAt
          status
        }
      }
    }
  }
`;

export const GET_SERVICE_REQUEST_QUERY = gql`
  query GetServiceRequest($getServiceRequestId: Float!) {
    getServiceRequest(id: $getServiceRequestId) {
      Address {
        addressLine1
        addressLine2
        city
        country
        createdAt
        customerId
        isDefault
        id
        isFromAPI
        place
        updatedAt
        state
        zipCode
      }
      Agent {
        Company {
          city
          addressLine1
          createdAt
          addressLine2
          hours
          id
          name
          phoneNumber
          rating
          state
          updatedAt
          website
          zipCode
        }
        User {
          id
          fullName
          lastLogin
        }
        companyId
        rating
        serviceTypes
        deletedAt
      }
      Customer {
        addresses {
          addressLine1
          addressLine2
          city
          country
          createdAt
          customerId
          id
          isDefault
          isFromAPI
          place
          state
          updatedAt
          zipCode
        }
        birthday
        createdAt
        createdBy
        firstName
        fullName
        id
        lastName
        updatedAt
      }
      RequestedBy {
        id
        firstName
        fullName
        email
        emailVerified
        createdAt
        language
        lastLogin
        lastName
        phoneNumber
        role
        updatedAt
        webPushSubscription
        talkJsId
      }
      CreatedBy {
        id
        fullName
      }
      acceptedAt
      actionNeeded
      addressId
      agentId
      availabilities {
        date
        from
        until
      }
      category
      createdAt
      customerId
      description
      id
      isAvailabilityFlexible
      recurring
      frequency
      otherCategory
      requestType
      requestedBy
      requiresAction
      ride {
        dropoffAddress {
          addressLine1
          addressLine2
          city
          country
          isDefault
          state
          zipCode
          place
        }
        pickupAddress {
          addressLine1
          addressLine2
          city
          country
          isDefault
          state
          zipCode
          place
        }
        stops {
          addressLine1
          addressLine2
          city
          country
          isDefault
          state
          zipCode
          place
        }
        type
        waitTime
      }
      scheduledAt
      status
      tentativeSchedule
      updatedAt
      InternalNotes {
        createdAt
        id
        note
        updatedAt
        userId
        Users {
          fullName
          id
          createdAt
          updatedAt
        }
      }
      ServiceRequestPayments {
        id
        type
        amount
        stripePaymentMethodId
        stripeTransactionId
        fee
        tax
        estimateHours
        createdAt
        notes
        updatedAt
      }
      commentForBrya
      commentForCustomer
    }
  }
`;

export const GET_STRIPE_TRANSACTIONS = gql`
  query GetStripeTransactions($filter: StripeTransactionFilter!) {
    getStripeTransactions(filter: $filter) {
      transactions {
        status
        amount
        activityId
        stripeTransactionId
      }
    }
  }
`;

export const CREATE_SERVICE_REQUEST_MUTATION = gql`
  mutation CreateServiceRequest($data: CreateServiceRequestInput!) {
    createServiceRequest(data: $data) {
      id
    }
  }
`;

export const CREATE_APPOINTMENT_REQUEST_SERVICE_REQUEST_MUTATION = gql`
  mutation CreateAppointmentRequestServiceRequest($userIds: [Int!]!, $serviceRequestId: Int) {
    createAppointmentRequest(userIds: $userIds, serviceRequestId: $serviceRequestId) {
      id
      userId
      serviceRequestId
      circleRequestId
      status
      createdAt
      updatedAt
    }
  }
`;

export const UPDATE_SERVICE_REQUEST_MUTATION = gql`
  mutation UpdateServiceRequest($data: UpdateServiceRequestInput!, $updateServiceRequestId: Int!) {
    updateServiceRequest(data: $data, id: $updateServiceRequestId) {
      id
    }
  }
`;
