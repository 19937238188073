import { Card as AntdCard } from 'antd';
import styled from 'styled-components';

const Card = styled(AntdCard)`
  -webkit-box-shadow: 2px 3px 5px 1px rgba(140, 140, 140, 1);
  -moz-box-shadow: 2px 3px 5px 1px rgba(140, 140, 140, 1);
  box-shadow: 2px 3px 5px 1px rgba(140, 140, 140, 1);
  border: 1px solid #c4c4c4;
  width: auto;
`;

export default Card;
