import { useEffect, useMemo, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { GET_SERVICE_REQUEST_QUERY } from '@graphql/serviceRequests';
import { GET_CIRCLE_REQUEST_QUERY } from '@graphql/circleRequests';
import { GET_SOCIAL_EVENT_QUERY } from '@graphql/socialEvents';
import { GET_APPOINTMENT_REQUESTS_QUERY, GET_ACTIVITY_TIMELINE_QUERY } from '@graphql/activities';
import {
  AppointmentRequestStatus,
  GetAppointmentRequestsQuery,
  GetTopAgentsOutput,
  GraphqlAgent,
  GraphqlAppointmentRequest,
  UpdateAppointmentRequestInput,
  useGetAppointmentRequestsQuery,
  useGetAgentsByServiceRequestLazyQuery,
  useUpdateAppointmentRequestMutation,
} from '@graphql/generated';

import ROUTES from '@constants/routes';
import { ActivitiesTypes } from '@constants/activities';
import { renderAgentConfidenceLevel } from '@utils/agents';
import dayjs from 'dayjs';

import { Table, Typography } from 'antd';
import { ColumnsType } from 'antd/es/table';
import Link from '@components/Link';
import Modal from '@components/Modal';
import Button from '@components/Button';
import Card from '@components/Card/Card';
import AgentLink from '@components/AgentLink';
import EntityRating from '@components/Rate/EntityRating';
import GenericApolloError from '@components/GenericApolloError';
import ServiceRequestAgents from '@components/Activities/ServiceRequestAgents';
import AppointmentRequestStatusDropdown from '@components/Pros/AppointmentRequestStatusDropdown';

type AppointmentRequestsCardProps = {
  type: ActivitiesTypes;
  serviceRequestId?: number;
  circleRequestId?: number;
};

function AppointmentRequestsCard({
  type,
  serviceRequestId,
  circleRequestId,
}: AppointmentRequestsCardProps) {
  const i18n = useTranslation();
  const [getAgents, { data: agentsData }] = useGetAgentsByServiceRequestLazyQuery();
  const [agentModalIsOpen, setAgentModalIsOpen] = useState<boolean>(false);
  const {
    data: appointmentRequestsData,
    loading: appointmentRequestsLoading,
    error: appointmentRequestsError,
  } = useGetAppointmentRequestsQuery({
    variables: {
      serviceRequestId,
      circleRequestId,
    },
  });
  const [updateAppointmentRequestMutation, { loading: updateAppointmentRequestLoading }] =
    useUpdateAppointmentRequestMutation({
      refetchQueries: [
        GET_SERVICE_REQUEST_QUERY,
        GET_CIRCLE_REQUEST_QUERY,
        GET_SOCIAL_EVENT_QUERY,
        GET_APPOINTMENT_REQUESTS_QUERY,
        GET_ACTIVITY_TIMELINE_QUERY,
      ],
    });

  useEffect(() => {
    if (serviceRequestId) {
      getAgents({
        variables: { serviceRequestId: Number(serviceRequestId) },
      });
    }
  }, [serviceRequestId, getAgents]);

  const agents = useMemo(
    () => (agentsData?.getAgentsByServiceRequest.agents as GetTopAgentsOutput[]) || [],
    [agentsData],
  );

  const appointmentRequests = useMemo(() => {
    if (!appointmentRequestsData) {
      return [];
    }
    return [...appointmentRequestsData.getAppointmentRequests].sort(
      (a, b) => +a.createdAt - +b.createdAt,
    );
  }, [appointmentRequestsData]);

  const appointmentRequestColumns: ColumnsType<
    GetAppointmentRequestsQuery['getAppointmentRequests'][0]
  > = useMemo(
    () =>
      [
        {
          title: i18n.t(`manage.appointmentRequest.fields.name.${type}`) as string,
          dataIndex: 'name',
          key: 'name',
          type: ActivitiesTypes.SERVICE_REQUEST,
          render: (_: any, record: GetAppointmentRequestsQuery['getAppointmentRequests'][0]) => {
            return type === ActivitiesTypes.SERVICE_REQUEST ? (
              <AgentLink
                agent={{ ...record?.SentTo?.Agent, User: record?.SentTo } as GraphqlAgent}
              />
            ) : (
              <Link to={ROUTES.MANAGE_USER.replace(':userId', String(record?.userId))}>
                {record?.SentTo?.fullName}
              </Link>
            );
          },
        },
        {
          title: i18n.t('manage.appointmentRequest.fields.createdAt') as string,
          dataIndex: 'createdAt',
          key: 'createdAt',
          render: (_: any, record: GetAppointmentRequestsQuery['getAppointmentRequests'][0]) => {
            return dayjs(record?.createdAt).format('MM/DD/YY');
          },
        },
        {
          title: i18n.t(`manage.companies.table.columns.agentRating`) as string,
          dataIndex: 'rating',
          key: 'rating',
          render: (_: any, record: GetAppointmentRequestsQuery['getAppointmentRequests'][0]) => {
            const agent = agents.find((a) => a.userId === record.SentTo?.id);
            return agent ? (
              <EntityRating value={agent?.rating} count={agent?.ServiceRequestReviews?.length} />
            ) : null;
          },
          width: 200,
          agents: true,
        },
        {
          title: <Trans i18nKey="manage.agents.columns.confidenceLevel" />,
          render: (_: any, record: GetAppointmentRequestsQuery['getAppointmentRequests'][0]) => {
            const agent = agents.find((a) => a.userId === record.SentTo?.id);
            return renderAgentConfidenceLevel(agent as GetTopAgentsOutput);
          },
          agents: true,
        },
        {
          title: <Trans i18nKey="manage.agents.columns.proAppStatus" />,
          render: (_: any, record: GetAppointmentRequestsQuery['getAppointmentRequests'][0]) => {
            const confirmedAppointmentRequest = appointmentRequests?.find(
              (ar) => ar.status === AppointmentRequestStatus.Confirmed,
            );
            // If the SR-AR's status is declined, then it can be one of two things: "N/A" or "Unavailable"
            // This is predicated on whether someone else has a confirmed SR-AR.
            if (
              record.status === AppointmentRequestStatus.Declined &&
              confirmedAppointmentRequest &&
              confirmedAppointmentRequest?.userId !== record.userId
            ) {
              return <Trans i18nKey="manage.agents.proAppStatus.declinedNoConfirmation" />;
            }
            return <Trans i18nKey={`manage.agents.proAppStatus.${record.status}`} />;
          },
          agents: true,
        },
        {
          title: i18n.t('manage.appointmentRequest.fields.status') as string,
          dataIndex: 'status',
          key: 'status',
          render: (_: any, record: GetAppointmentRequestsQuery['getAppointmentRequests'][0]) => {
            return (
              <AppointmentRequestStatusDropdown
                appointmentRequest={record as GraphqlAppointmentRequest}
                onChange={async (updateAppointmentRequestInput: UpdateAppointmentRequestInput) =>
                  updateAppointmentRequestMutation({
                    variables: { updateAppointmentRequestInput },
                  })
                }
              />
            );
          },
        },
        {
          title: i18n.t('manage.appointmentRequest.fields.sentBy') as string,
          dataIndex: 'agent',
          key: 'agent',
          render: (_: any, record: GetAppointmentRequestsQuery['getAppointmentRequests'][0]) => {
            return (
              <Link to={ROUTES.MANAGE_USER.replace(':userId', String(record?.CreatedBy?.id))}>
                {record?.CreatedBy?.fullName}
              </Link>
            );
          },
        },
      ].filter((col) => agents?.length || !col.agents),
    [i18n, updateAppointmentRequestMutation, type, agents, appointmentRequests],
  );

  if (appointmentRequestsError) {
    return (
      <Card title={i18n.t(`manage.serviceRequest.headers.appointmentRequests.${type}`)}>
        <GenericApolloError error={appointmentRequestsError} />
      </Card>
    );
  }

  return (
    <Card
      title={
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            width: '100%',
          }}
        >
          <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
            {i18n.t(`manage.serviceRequest.headers.appointmentRequests.${type}`)}
          </div>
          {serviceRequestId && (
            <div>
              <Button type="link" onClick={() => setAgentModalIsOpen(!agentModalIsOpen)}>
                {i18n.t('manage.agents.actions.sendToAgent')}
              </Button>
            </div>
          )}
        </div>
      }
    >
      <Table
        loading={appointmentRequestsLoading || updateAppointmentRequestLoading}
        columns={appointmentRequestColumns}
        dataSource={appointmentRequests}
        pagination={false}
      />
      <Modal
        width="max-content"
        closable
        onCancel={() => setAgentModalIsOpen(!agentModalIsOpen)}
        open={agentModalIsOpen}
        title={
          <Typography.Title level={2}>
            <Trans i18nKey="forms.sendToAgent.title" />
          </Typography.Title>
        }
      >
        <ServiceRequestAgents serviceRequestId={serviceRequestId as number} />
      </Modal>
    </Card>
  );
}

AppointmentRequestsCard.defaultProps = {
  serviceRequestId: null,
  circleRequestId: null,
};

export default AppointmentRequestsCard;
