import {
  CheckCircleOutlined,
  ClockCircleOutlined,
  ExclamationCircleOutlined,
} from '@ant-design/icons';
import { Tag } from 'antd';
import { Trans } from 'react-i18next';

export enum StripeTransactionStatusEnum {
  RequiresPaymentMethod = 'requires_payment_method',
  RequiresConfirmation = 'requires_confirmation',
  RequiresAction = 'requires_action',
  RequiresCapture = 'requires_capture',
  Processing = 'processing',
  Cancelled = 'canceled',
  Success = 'succeeded',
}

const renderServiceStatus = (status: StripeTransactionStatusEnum) => {
  switch (status) {
    case StripeTransactionStatusEnum.RequiresPaymentMethod:
    case StripeTransactionStatusEnum.RequiresConfirmation:
    case StripeTransactionStatusEnum.RequiresAction:
      return (
        <Tag icon={<ExclamationCircleOutlined />} color="warning">
          <Trans i18nKey={`stripeTransactionStatus.${status}`} />
        </Tag>
      );
    case StripeTransactionStatusEnum.RequiresCapture:
      return (
        <Tag icon={<ClockCircleOutlined />} color="default">
          <Trans i18nKey={`stripeTransactionStatus.${status}`} />
        </Tag>
      );
    case StripeTransactionStatusEnum.Processing:
      return (
        <Tag icon={<ClockCircleOutlined />} color="processing">
          <Trans i18nKey={`stripeTransactionStatus.${status}`} />
        </Tag>
      );
    case StripeTransactionStatusEnum.Cancelled:
      return (
        <Tag icon={<ExclamationCircleOutlined />} color="error">
          <Trans i18nKey={`stripeTransactionStatus.${status}`} />
        </Tag>
      );
    case StripeTransactionStatusEnum.Success:
      return (
        <Tag icon={<CheckCircleOutlined />} color="success">
          <Trans i18nKey={`stripeTransactionStatus.${status}`} />
        </Tag>
      );
    default:
      // eslint-disable-next-line no-console
      console.error(
        '[StripeTransactionStatusTag][renderTransactionStatus] Unknown status: ',
        status,
      );
      return <span>{`Unknown status: ${status}`}</span>;
  }
};

export function StripeTransactionStatusTag({ status }: { status: StripeTransactionStatusEnum }) {
  return renderServiceStatus(status);
}

export default StripeTransactionStatusTag;
