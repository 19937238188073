import { Form } from 'antd';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import { LeftOutlined } from '@ant-design/icons';
import styled from 'styled-components';

import Button from '@components/Button';
import { FormItem } from '@components/Form';
import { InputEmail } from '@components/Input';
import AuthLayout from '@components/AuthLayout';
import ROUTES from '@constants/routes';
import { useForgotPasswordMutation } from '@graphql/generated';
import { useIsWebview } from '@hooks/useAuth';

const LinkContainer = styled.div`
  margin-top: 24px;
  a {
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    color: #9e9e9e;
  }

  svg {
    margin-right: 6px;
  }
`;

function ForgotPassword() {
  const i18n = useTranslation();
  const isWebview = useIsWebview();
  const LOGIN_ROUTE = isWebview ? ROUTES.LOG_IN : ROUTES.MANAGE_LOG_IN;
  const [form] = Form.useForm();
  const [disableButton, setDisableButton] = useState(true);

  const [forgotPasswordMutation, { loading, data }] = useForgotPasswordMutation();

  const onFinish = async ({ email }: { email: string }) => {
    try {
      await forgotPasswordMutation({ variables: { email } });
    } catch (e) {
      // noop
    }
  };

  const onFieldsChange = () => {
    const hasErrors = form.getFieldsError().some(({ errors }) => errors.length);
    setDisableButton(hasErrors);
  };

  if (data?.forgotPassword?.success) {
    return (
      <AuthLayout
        bryaHeaderProps={{
          title: 'resetPassword.forgotPassword.emailSent',
          subtitle: 'resetPassword.forgotPassword.cta.checkEmailInbox',
          hideSSOLogin: true,
        }}
      >
        <Link to={LOGIN_ROUTE}>
          <Button type="primary">{i18n.t('resetPassword.forgotPassword.cta.cancel')}</Button>
        </Link>
      </AuthLayout>
    );
  }

  return (
    <AuthLayout
      bryaHeaderProps={{
        title: 'resetPassword.forgotPassword.title',
        subtitle: 'resetPassword.forgotPassword.subtitle',
        hideSSOLogin: true,
      }}
    >
      <>
        <Form
          form={form}
          name="forgotPassword"
          autoComplete="off"
          onFinish={onFinish}
          onFieldsChange={onFieldsChange}
          style={{ textAlign: 'left' }}
        >
          <InputEmail
            placeholder={i18n.t('resetPassword.forgotPassword.emailInput') as string}
            label={i18n.t('resetPassword.forgotPassword.emailInput') as string}
          />
          <FormItem style={{ marginTop: '24px' }}>
            <Button htmlType="submit" loading={loading} disabled={loading || disableButton}>
              {i18n.t('resetPassword.forgotPassword.cta.submit')}
            </Button>
          </FormItem>
        </Form>
        <LinkContainer>
          <Link to={LOGIN_ROUTE}>
            <LeftOutlined />
            {i18n.t('resetPassword.forgotPassword.cta.cancel')}
          </Link>
        </LinkContainer>
      </>
    </AuthLayout>
  );
}

export default ForgotPassword;
