import { Trans } from 'react-i18next';
import {
  GraphqlInternalNote,
  CreateInternalNoteInput,
  useCreateInternalNoteMutation,
  useUpdateInternalNoteMutation,
} from '@graphql/generated';

import { Typography } from 'antd';
import Modal from '@components/Modal';
import { InternalNoteForm } from '@components/InternalNotes/InternalNoteForm';
import { GET_EMPLOYEE_INTERNAL_NOTES } from '@graphql/users';

interface EmployeeInternalNoteModalProps {
  employeeId: number;
  open: boolean;
  onCancel: () => void;
  onSuccess: () => void;
  note?: GraphqlInternalNote;
}

function EmployeeInternalNoteModal({
  employeeId,
  open,
  onCancel,
  onSuccess,
  note,
}: EmployeeInternalNoteModalProps) {
  const [createNoteMutation, { loading: createNoteLoading }] = useCreateInternalNoteMutation({
    refetchQueries: [GET_EMPLOYEE_INTERNAL_NOTES],
  });
  const [updateNoteMutation, { loading: updateNoteLoading }] = useUpdateInternalNoteMutation({
    refetchQueries: [GET_EMPLOYEE_INTERNAL_NOTES],
  });

  const submit = async (input: CreateInternalNoteInput) => {
    if (note?.id) {
      await updateNoteMutation({
        variables: {
          input,
          updateInternalNoteId: note?.id,
        },
      });
    } else {
      await createNoteMutation({
        variables: {
          input: { ...input, employeeId },
        },
      });
    }
    onSuccess();
  };

  return (
    <Modal
      width={500}
      closable
      onCancel={onCancel}
      open={open}
      title={
        <Typography.Title level={2}>
          <Trans i18nKey={`forms.internalNote.title${note?.id ? 'Edit' : 'Add'}`} />
        </Typography.Title>
      }
    >
      <InternalNoteForm
        loading={createNoteLoading || updateNoteLoading}
        initialValues={note}
        onSubmit={submit}
        submitLabel={<Trans i18nKey="forms.internalNote.submitLabel" />}
      />
    </Modal>
  );
}

EmployeeInternalNoteModal.defaultProps = {
  note: {},
};

export default EmployeeInternalNoteModal;
