import i18next, { InitOptions } from 'i18next';
import { initReactI18next } from 'react-i18next';

// language files
import translationEN from '@locales/en.json';
import translationES from '@locales/es.json';

const SUPPORTED_LANGUAGES = {
  en: {
    translation: translationEN,
    code: 'en-US',
  },
  es: {
    translation: translationES,
    code: 'es',
  },
};

// TODO: At some point, add functionality for switching languages
export const getLocale = () => {
  return (
    i18next.language || (typeof window !== 'undefined' && window.localStorage.i18nextLng) || 'en'
  );
};

export const i18nInitialize = (options: InitOptions = {}) => {
  i18next.use(initReactI18next).init({
    debug: true,
    resources: SUPPORTED_LANGUAGES,
    react: {
      useSuspense: false,
    },
    fallbackLng: 'en',
    ...options,
  });
};

export default i18next;
