import {
  CalendarOutlined,
  CheckCircleOutlined,
  ClockCircleOutlined,
  CloseCircleOutlined,
} from '@ant-design/icons';
import { AppointmentRequestStatus as AppointmentRequestStatusEnum } from '@graphql/generated';
import { Tag } from 'antd';
import { Trans } from 'react-i18next';

const renderAppointmentStatus = (status: AppointmentRequestStatusEnum) => {
  switch (status) {
    case AppointmentRequestStatusEnum.Open:
      return (
        <Tag icon={<ClockCircleOutlined />} color="default">
          <Trans i18nKey={`appointmentRequestStatus.${status}`} />
        </Tag>
      );
    case AppointmentRequestStatusEnum.Declined:
      return (
        <Tag icon={<CloseCircleOutlined />} color="error">
          <Trans i18nKey={`appointmentRequestStatus.${status}`} />
        </Tag>
      );
    case AppointmentRequestStatusEnum.Accepted:
      return (
        <Tag icon={<CheckCircleOutlined />} color="processing">
          <Trans i18nKey={`appointmentRequestStatus.${status}`} />
        </Tag>
      );
    case AppointmentRequestStatusEnum.Confirmed:
      return (
        <Tag icon={<CheckCircleOutlined />} color="success">
          <Trans i18nKey={`appointmentRequestStatus.${status}`} />
        </Tag>
      );
    case AppointmentRequestStatusEnum.Assigned:
      return (
        <Tag icon={<CalendarOutlined />} color="success">
          <Trans i18nKey={`appointmentRequestStatus.${status}`} />
        </Tag>
      );
    case AppointmentRequestStatusEnum.NotChosen:
      return (
        <Tag icon={<CloseCircleOutlined />} color="default">
          <Trans i18nKey={`appointmentRequestStatus.${status}`} />
        </Tag>
      );
    case AppointmentRequestStatusEnum.Closed:
      return (
        <Tag icon={<CloseCircleOutlined />} color="default">
          <Trans i18nKey={`appointmentRequestStatus.${status}`} />
        </Tag>
      );
    default:
      // eslint-disable-next-line no-console
      console.error(
        '[AppointmentRequestStatusTag][renderAppointmentStatus] Unknown status: ',
        status,
      );
      return <span>{`Unknown status: ${status}`}</span>;
  }
};

function AppointmentRequestStatusTag({ status }: { status: AppointmentRequestStatusEnum }) {
  return renderAppointmentStatus(status);
}

export default AppointmentRequestStatusTag;
