import { useEffect, useMemo, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import {
  GraphqlAgent,
  GraphqlCircleRequest,
  GraphqlServiceRequest,
  InputMaybe,
  PaginationMeta,
  ServiceRequestFilter,
  ServiceRequestSortInput,
  ServiceRequestsSortableFields,
  ServiceRequestStatus,
  ServiceRequestCategoriesEnum,
  SortOrder,
  useGetServiceRequestsQuery,
  useListRequestCategoriesQuery,
} from '@graphql/generated';
import { FilterValue, SorterResult } from 'antd/es/table/interface';
import { ColumnsType, TablePaginationConfig } from 'antd/es/table';
import GenericApolloError from '@components/GenericApolloError';

import { ApolloError } from '@apollo/client/errors';

import ROUTES from '@constants/routes';
import { ID_MIN_LENGTH } from '@constants/output';
import { getActivityActionNeeded } from '@utils/activities';
import { formatISOtoMMDDYYYY } from '@utils/date';

import { Space, Switch, Typography } from 'antd';
import Input from '@components/Input';
import Button from '@components/Button';
import Table from '@components/Table/Table';
import AgentLink from '@components/AgentLink';
import { DEFAULT_SEARCH_DEBOUNCE_TIME } from '@constants/input';
import { TableDateFilter } from '@components/Table/TableDateFilter';
import { ServiceRequestStatusTag } from '@components/Activities/ServiceRequestStatusTag';
import { useDateWithTimezone } from '@hooks/useDateWithTimezone';

// NOTE: For now the frontend filters by the known status that requires action.
// In the future, if this changes, we should be able to filter by requiresAction
// boolean field or actionNeeded string field. Currently the backend does not support
// that because they are resolved fields that are not stored in the database.
const STATUS_THAT_REQUIRES_ACTION = [
  ServiceRequestStatus.Pending,
  ServiceRequestStatus.ConfirmationNeeded,
];
const isFilteringByActionNeeded = (statusFilter: ServiceRequestStatus[]) => {
  return (
    statusFilter.length === STATUS_THAT_REQUIRES_ACTION.length &&
    statusFilter.every((status) => STATUS_THAT_REQUIRES_ACTION.includes(status))
  );
};

function ServiceRequests() {
  const i18n = useTranslation();
  const { formatDateAndTimeInTimezone } = useDateWithTimezone();
  const [searchServiceRequestsText, setSearchServiceRequestsText] = useState('');
  const [filters, setFilters] = useState<ServiceRequestFilter>({
    text: searchServiceRequestsText,
  });
  const [sortOptions, setSortOptions] = useState<InputMaybe<ServiceRequestSortInput>>({
    field: ServiceRequestsSortableFields.Id,
    direction: SortOrder.Desc,
  });
  const [pageSize, setPageSize] = useState(100);
  const [page, setPage] = useState(1);
  const {
    data: serviceRequestsData,
    error: serviceRequestsError,
    loading: serviceRequestsLoading,
    previousData: serviceRequestsPreviousData,
  } = useGetServiceRequestsQuery({
    variables: {
      filter: filters as ServiceRequestFilter,
      sort: sortOptions as ServiceRequestSortInput,
      page,
      pageSize,
    },
  });
  const { data: serviceRequestCategories } = useListRequestCategoriesQuery({
    variables: { serviceRequest: true },
  });

  useEffect(() => {
    const timer = setTimeout(() => {
      setFilters((previousFilters) => ({ ...previousFilters, text: searchServiceRequestsText }));
    }, DEFAULT_SEARCH_DEBOUNCE_TIME);
    return () => clearTimeout(timer);
  }, [searchServiceRequestsText]);

  const serviceRequests = useMemo(
    () =>
      ((serviceRequestsData || serviceRequestsPreviousData)?.getServiceRequests
        ?.serviceRequests as GraphqlServiceRequest[]) || [],
    [serviceRequestsData, serviceRequestsPreviousData],
  );

  const pagination = (serviceRequestsData || serviceRequestsPreviousData)?.getServiceRequests
    .meta as PaginationMeta;

  const columns: ColumnsType<GraphqlServiceRequest> = useMemo(
    () => [
      {
        title: <Trans i18nKey="manage.serviceRequests.columns.id" />,
        dataIndex: 'id',
        key: 'id',
        render: (_: any, record: GraphqlServiceRequest) => (
          <Link to={ROUTES.MANAGE_SERVICE_REQUEST.replace(':activityId', String(record.id))}>
            {String(record.id).padStart(ID_MIN_LENGTH, '0')}
          </Link>
        ),
        sorter: true,
        filteredValue: null,
      },
      {
        title: <Trans i18nKey="manage.serviceRequests.columns.requestedBy" />,
        key: 'requestedBy',
        render: (_: any, record: GraphqlServiceRequest) => (
          <Link to={ROUTES.MANAGE_USER.replace(':userId', String(record.RequestedBy.id))}>
            {record.RequestedBy.fullName}
          </Link>
        ),
        filteredValue: null,
      },
      {
        title: <Trans i18nKey="manage.serviceRequests.columns.account" />,
        key: 'account',
        render: (_: any, record: GraphqlServiceRequest) => (
          <Link to={ROUTES.MANAGE_CUSTOMER.replace(':customerId', String(record?.Customer?.id))}>
            {record?.Customer?.fullName}
          </Link>
        ),
        filteredValue: null,
      },
      {
        title: <Trans i18nKey="manage.serviceRequests.columns.agent" />,
        key: 'agentId',
        render: (_: any, record: GraphqlServiceRequest) => (
          <AgentLink agent={(record as GraphqlServiceRequest).Agent as GraphqlAgent} />
        ),
        filteredValue: null,
      },
      {
        title: <Trans i18nKey="manage.serviceRequests.columns.type" />,
        key: 'category',
        dataIndex: 'category',
        render: (_: any, record: GraphqlServiceRequest) =>
          i18n.t(`serviceTypes.${record.category}`) as string,
        sorter: true,
        filters: serviceRequestCategories?.listRequestCategories?.map((category) => ({
          text: category.name,
          value: category.id,
        })),
        filteredValue: (filters as ServiceRequestFilter)?.category,
      },
      {
        title: <Trans i18nKey="manage.serviceRequests.columns.createdAt" />,
        key: 'createdAt',
        dataIndex: 'createdAt',
        render: (_: any, record: GraphqlServiceRequest) => formatISOtoMMDDYYYY(record.createdAt),
        sorter: true,
        // eslint-disable-next-line react/no-unstable-nested-components
        filterDropdown: (props: any) => (
          <TableDateFilter
            {...props}
            onRangeSelected={(from, until) =>
              setFilters((prevFilters) => ({
                ...prevFilters,
                requestedAtFrom: from,
                requestedAtUntil: until,
              }))
            }
          />
        ),
        filtered: Boolean(
          (filters as ServiceRequestFilter).requestedAtFrom ||
            (filters as ServiceRequestFilter).requestedAtUntil,
        ),
        filteredValue: null,
      },
      {
        title: <Trans i18nKey="manage.serviceRequests.columns.status" />,
        key: 'status',
        dataIndex: 'status',
        render: (_: any, record: GraphqlServiceRequest) => (
          <ServiceRequestStatusTag status={record.status as ServiceRequestStatus} />
        ),
        sorter: true,
        filters: Object.values(ServiceRequestStatus).map((serviceRequestStatus) => {
          return {
            text: <Trans i18nKey={`serviceRequestStatus.${serviceRequestStatus}`} />,
            value: serviceRequestStatus,
          };
        }),
        filtered: Boolean(Number(filters.status?.length) > 0),
        filteredValue: filters.status,
      },
      {
        title: <Trans i18nKey="manage.serviceRequests.columns.actionNeeded" />,
        key: 'actionNeeded',
        dataIndex: 'actionNeeded',
        render: (_: any, record: GraphqlServiceRequest) =>
          getActivityActionNeeded(record as unknown as GraphqlCircleRequest),
        filteredValue: null,
      },
      {
        title: <Trans i18nKey="manage.serviceRequests.columns.scheduledAt" />,
        key: 'scheduledAt',
        dataIndex: 'scheduledAt',
        render: (_: any, record: GraphqlServiceRequest) =>
          record.scheduledAt ? formatDateAndTimeInTimezone(record.scheduledAt) : '',
        sorter: true,
        // eslint-disable-next-line react/no-unstable-nested-components
        filterDropdown: (props: any) => (
          <TableDateFilter
            {...props}
            onRangeSelected={(from, until) =>
              setFilters((prevFilters) => ({
                ...prevFilters,
                scheduledAtFrom: from,
                scheduledAtUntil: until,
              }))
            }
          />
        ),
        filtered: Boolean(filters.scheduledAtFrom || filters.scheduledAtUntil),
        filteredValue: null,
      },
    ],
    [i18n, filters, serviceRequestCategories?.listRequestCategories, formatDateAndTimeInTimezone],
  );

  const onTableChange = (
    tablePagination: TablePaginationConfig,
    tableFilters: Record<string, FilterValue | null>,
    tableSorter: SorterResult<GraphqlServiceRequest> | SorterResult<GraphqlServiceRequest>[],
  ) => {
    const uniqueSorter = tableSorter as SorterResult<GraphqlServiceRequest>;

    // Pagination
    setPage(tablePagination.current as number);
    setPageSize(tablePagination.pageSize as number);

    // Order
    if (uniqueSorter.order) {
      const direction = uniqueSorter.order === 'ascend' ? SortOrder.Asc : SortOrder.Desc;
      setSortOptions({
        field: uniqueSorter.field as ServiceRequestsSortableFields,
        direction,
      });
    } else {
      setSortOptions({
        field: ServiceRequestsSortableFields.Id,
        direction: SortOrder.Desc,
      });
    }

    // Filters
    const { status, category } = tableFilters;
    setFilters((prevFilters) => ({
      ...prevFilters,
      category: category as ServiceRequestCategoriesEnum[],
      status: status as ServiceRequestStatus[],
    }));
  };

  if (serviceRequestsError)
    return <GenericApolloError error={serviceRequestsError as ApolloError} />;

  return (
    <Space direction="vertical" size="large" style={{ width: '100%' }}>
      <Typography.Title
        level={2}
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
        }}
      >
        <Trans i18nKey="manage.serviceRequests.title" />
        <Link to={ROUTES.MANAGE_SERVICE_REQUEST_CREATE}>
          <Button>
            <Trans i18nKey="manage.serviceRequests.create.cta" />
          </Button>
        </Link>
      </Typography.Title>
      <Space>
        <Trans i18nKey="manage.serviceRequests.filterActionNeeded.label" />
        <Switch
          checkedChildren={<Trans i18nKey="manage.serviceRequests.filterActionNeeded.on" />}
          unCheckedChildren={<Trans i18nKey="manage.serviceRequests.filterActionNeeded.off" />}
          checked={isFilteringByActionNeeded((filters as ServiceRequestFilter).status || [])}
          onChange={() =>
            setFilters((prevFilters) => ({
              ...prevFilters,
              status: isFilteringByActionNeeded((filters as ServiceRequestFilter).status || [])
                ? []
                : STATUS_THAT_REQUIRES_ACTION,
            }))
          }
        />
      </Space>
      <Input
        placeholder={i18n.t('manage.serviceRequests.search') as string}
        value={searchServiceRequestsText}
        onChange={(e) => setSearchServiceRequestsText(e.target.value)}
      />
      <Table
        loading={serviceRequestsLoading}
        columns={columns}
        dataSource={(serviceRequests || []).map((activity) => ({
          ...activity,
          key: activity.id,
        }))}
        onChange={onTableChange}
        pagination={{
          pageSize,
          showSizeChanger: true,
          defaultCurrent: 1,
          current: pagination?.currentPage,
          total: pagination?.totalCount,
        }}
      />
    </Space>
  );
}

export default ServiceRequests;
