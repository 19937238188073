import { useState } from 'react';
import styled from 'styled-components';
import { Trans, UseTranslationResponse, useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import {
  CreateSocialEventInput,
  CustomerSortableFields,
  GlobalRole,
  SortOrder,
  UserSortableFields,
  useCreateSocialEventMutation,
  useGetCustomersQuery,
  useGetUsersQuery,
} from '@graphql/generated';

import { DatePicker, Form, Select, Spin, Switch, Typography, message } from 'antd';
import Modal from '@components/Modal';
import Button from '@components/Button';
import { FlexRow } from '@components/styled';
import Input, { InputTextarea } from '@components/Input';
import LocationDropdownOther from '@components/Activities/LocationDropdownOther';

import { ROUTES } from '@constants/routes';
import { useDateWithTimezone } from '@hooks/useDateWithTimezone';

const FormItem = styled(Form.Item).attrs(() => ({
  labelCol: { span: 24 },
}))`
  .ant-form-item-label {
    padding: 0px !important;
  }
`;

type GoogleAddressAPIResultProps = {
  addressLine1?: string;
  addressLine2?: string;
  city?: string;
  country?: string;
  isFromAPI?: boolean;
  place?: string;
  state?: string;
  zipCode?: string;
};

const isGoogleResultValid = (
  { addressLine1, city, country, state, zipCode }: GoogleAddressAPIResultProps,
  i18n: UseTranslationResponse<'translation', undefined>,
) => {
  if ((addressLine1 || addressLine1 === '') && city && country && state && zipCode) {
    return true;
  }

  message.error(i18n.t('manage.socialEvents.error'));
  return false;
};

function CreateSocialEventModal({ onCancel }: { onCancel: () => void }) {
  const i18n = useTranslation();
  const { transformDateToTimezone } = useDateWithTimezone();
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const [googleAutocompleteResult, setGoogleAutocompleteResult] = useState<any>(null);
  const { data: customersData, loading: customersLoading } = useGetCustomersQuery({
    variables: {
      filters: { sortField: CustomerSortableFields.FullName, sortValue: SortOrder.Desc },
    },
  });
  const { data: coachesData, loading: coachesLoading } = useGetUsersQuery({
    variables: {
      filter: { roles: [GlobalRole.Admin, GlobalRole.Support, GlobalRole.Coach] },
      page: 1,
      pageSize: 100,
      sort: { field: UserSortableFields.FullName, direction: SortOrder.Asc },
    },
  });
  const [createSocialEventMutation, { loading: createSocialEventLoading }] =
    useCreateSocialEventMutation();

  if (coachesLoading || customersLoading) {
    return (
      <Modal open width={750}>
        <div style={{ padding: 329 }}>
          <Spin size="large" />
        </div>
      </Modal>
    );
  }

  return (
    <Modal
      width={750}
      closable
      onCancel={onCancel}
      open
      title={
        <Typography.Title level={2}>
          <Trans i18nKey="manage.socialEvents.new" />
        </Typography.Title>
      }
    >
      <Form
        form={form}
        initialValues={{ recurring: false }}
        onFinish={async (values) => {
          if (!isGoogleResultValid(googleAutocompleteResult, i18n)) return;

          const response = await createSocialEventMutation({
            variables: {
              data: {
                ...(values as CreateSocialEventInput),
                scheduledAt: transformDateToTimezone(values.scheduledAt),
                ...googleAutocompleteResult,
                addressId: undefined,
                isFromAPI: undefined,
              },
            },
          });
          navigate(
            ROUTES.MANAGE_SOCIAL_EVENT.replace(
              ':activityId',
              String(response?.data?.createSocialEvent?.id),
            ),
          );
        }}
      >
        <FormItem
          name="eventName"
          label={i18n.t('forms.input.eventName.label')}
          rules={[
            { required: true, message: i18n.t('forms.input.eventName.errorMessage') as string },
          ]}
        >
          <Input placeholder={String(i18n.t('forms.input.eventName.placeholder'))} />
        </FormItem>
        <FormItem
          name="scheduledAt"
          label={i18n.t('forms.input.scheduledAt.label')}
          rules={[
            { required: true, message: i18n.t('forms.input.scheduledAt.errorMessage') as string },
          ]}
        >
          <DatePicker
            format="MMMM DD, YYYY @ h:mma"
            showTime={{ format: 'h:mma' }}
            style={{ width: '100%' }}
          />
        </FormItem>
        <FlexRow style={{ justifyContent: 'flex-start' }}>
          <Form.Item name="recurring" label={i18n.t('forms.input.recurring.label')}>
            <Switch />
          </Form.Item>
          <FormItem dependencies={['recurring']} noStyle>
            {({ getFieldValue }) =>
              getFieldValue('recurring') ? (
                <FormItem
                  name="frequency"
                  label={i18n.t('forms.input.frequency.label')}
                  style={{ marginLeft: 20 }}
                  rules={[
                    {
                      required: true,
                      message: i18n.t('forms.input.frequency.errorMessage') as string,
                    },
                  ]}
                >
                  <Input placeholder={String(i18n.t('forms.input.frequency.placeholder'))} />
                </FormItem>
              ) : null
            }
          </FormItem>
        </FlexRow>
        <FormItem
          name="coachesIds"
          label={i18n.t('forms.input.bryaRepsIds.label')}
          rules={[
            { required: true, message: i18n.t('forms.input.bryaRepsIds.errorMessage') as string },
          ]}
        >
          <Select
            mode="multiple"
            placeholder={String(i18n.t('forms.input.bryaRepsIds.placeholder'))}
            optionFilterProp="label"
            options={coachesData?.getUsers?.users?.map((user) => ({
              label: user?.fullName,
              value: user?.id,
            }))}
          />
        </FormItem>
        <FormItem
          name="participantsIds"
          label={i18n.t('forms.input.participantsIds.label')}
          rules={[
            {
              required: true,
              message: i18n.t('forms.input.participantsIds.errorMessage') as string,
            },
          ]}
        >
          <Select
            mode="multiple"
            placeholder={String(i18n.t('forms.input.participantsIds.placeholder'))}
            optionFilterProp="label"
            options={customersData?.getCustomers?.map((customer) => ({
              label: customer?.fullName,
              value: customer?.id,
            }))}
          />
        </FormItem>
        <FormItem
          name="addressId"
          label={i18n.t('forms.input.addressId.label')}
          rules={[
            { required: true, message: i18n.t('forms.input.addressId.errorMessage') as string },
          ]}
        >
          <LocationDropdownOther
            placeholder={i18n.t('forms.input.addressId.placeholder')}
            addresses={[]}
            onChange={setGoogleAutocompleteResult}
          />
        </FormItem>
        <FormItem
          name="description"
          label={i18n.t('forms.input.description.label')}
          rules={[
            { required: true, message: i18n.t('forms.input.description.errorMessage') as string },
          ]}
        >
          <InputTextarea placeholder={String(i18n.t('forms.input.description.placeholder'))} />
        </FormItem>
        <FormItem>
          <Button type="primary" htmlType="submit" loading={createSocialEventLoading}>
            <Trans i18nKey="manage.socialEvents.create" />
          </Button>
        </FormItem>
      </Form>
    </Modal>
  );
}

export default CreateSocialEventModal;
