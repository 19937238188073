import { gql } from '@apollo/client';

export const GET_COMPANIES_QUERY = gql`
  query GetCompanies($sort: CompanySortInput, $filter: CompanyFilter, $pageSize: Int, $page: Int) {
    getCompanies(sort: $sort, filter: $filter, pageSize: $pageSize, page: $page) {
      companies {
        Agents {
          userId
          rating
          internalRating
          serviceTypes
          User {
            id
            fullName
          }
        }
        id
        rating
        phoneNumber
        addressLine1
        addressLine2
        city
        state
        zipCode
        name
      }
      meta {
        currentPage
        totalCount
      }
    }
  }
`;

export const CREATE_COMPANY_MUTATION = gql`
  mutation CreateCompany($input: CreateCompanyInput!) {
    createCompany(input: $input) {
      id
    }
  }
`;

export const GET_AGENTS_QUERY = gql`
  query GetAgents($sort: AgentsSort, $filter: AgentsFilter, $pageSize: Int, $page: Int) {
    getAgents(sort: $sort, filter: $filter, pageSize: $pageSize, page: $page) {
      meta {
        currentPage
        isFirstPage
        isLastPage
        previousPage
        nextPage
        pageCount
        totalCount
      }
      agents {
        userId
        rating
        internalRating
        serviceTypes
        preferedContactMethods
        role
        Company {
          id
          name
          phoneNumber
          website
          addressLine1
          addressLine2
          city
          state
          zipCode
          rating
          createdAt
          updatedAt
        }
        User {
          id
          email
          firstName
          lastName
          fullName
          phoneNumber
          createdAt
          updatedAt
        }
      }
    }
  }
`;

export const GET_AGENTS_FOR_SERVICE_REQUEST_QUERY = gql`
  query GetAgentsByServiceRequest($serviceRequestId: Int!) {
    getAgentsByServiceRequest(serviceRequestId: $serviceRequestId) {
      agents {
        userId
        rating
        internalRating
        serviceTypes
        Company {
          id
          name
          phoneNumber
          website
          addressLine1
          addressLine2
          city
          state
          zipCode
          rating
          createdAt
          updatedAt
        }
        User {
          id
          email
          fullName
          phoneNumber
          createdAt
          updatedAt
        }
        ServiceRequestReviews {
          rating
        }
        customerFavorite
        trustedFavorite
        trustedReviewed
        appointmentRequestSent
      }
    }
  }
`;

export const GET_AGENT_QUERY = gql`
  query GetAgent($agentId: Int!) {
    getAgent(id: $agentId) {
      userId
      rating
      internalRating
      serviceTypes
      companyId
      preferedContactMethods
      role
      Company {
        id
        name
      }
      User {
        id
        firstName
        lastName
        email
        fullName
        phoneNumber
        createdAt
        updatedAt
        disabled
        emailVerified
        lastLogin
        bio
        profilePicture
      }
    }
  }
`;

export const GET_AGENT_REVIEWS = gql`
  query GetAgentReviews($agentId: Int!, $pageSize: Int, $page: Int, $sort: ReviewSortInput) {
    getAgentReviews(agentId: $agentId, pageSize: $pageSize, page: $page, sort: $sort) {
      reviews {
        comment
        rating
        serviceRequestId
        reviewedBy
        createdAt
        updatedAt
        ReviewedBy {
          fullName
          id
        }
      }
      meta {
        currentPage
        totalCount
      }
    }
  }
`;

export const GET_COMPANY_DETAIL_QUERY = gql`
  query GetCompany($companyId: Float!) {
    getCompany(id: $companyId) {
      id
      name
      phoneNumber
      hours
      website
      addressLine1
      addressLine2
      city
      state
      zipCode
      rating
      createdAt
      updatedAt
      serviceZipCodes
      Agents {
        userId
        rating
        internalRating
        serviceTypes
        companyId
        preferedContactMethods
        User {
          id
          email
          firstName
          lastName
          fullName
          phoneNumber
          language
          talkJsId
          disabled
          emailVerified
          createdAt
          updatedAt
        }
      }
      StripeConnectAccounts {
        stripeAccountId
      }
    }
  }
`;

export const UPDATE_COMPANY_DETAIL_MUTATION = gql`
  mutation UpdateCompany($input: UpdateCompanyInput!, $companyId: Float!) {
    updateCompany(data: $input, id: $companyId) {
      id
    }
  }
`;

export const CREATE_AGENT_MUTATION = gql`
  mutation CreateAgent($input: CreateAgentInput!) {
    createAgent(createAgentInput: $input) {
      userId
    }
  }
`;

export const UPDATE_AGENT_MUTATION = gql`
  mutation UpdateAgent($input: UpdateAgentInput!, $agentId: Int!) {
    updateAgent(updateAgentInput: $input, id: $agentId) {
      userId
    }
  }
`;

export const DELETE_AGENT_MUTATION = gql`
  mutation DeleteAgent($deleteAgentId: Int!) {
    deleteAgent(id: $deleteAgentId) {
      success
    }
  }
`;

export const GET_STRIPE_CONNECT_ACCOUNT = gql`
  query GetStripeConnectAccount {
    getStripeConnectAccount {
      status
    }
  }
`;

export const CREATE_STRIPE_CONNECT_ACCOUNT = gql`
  mutation CreateConnectAccountOrLoginLink {
    createConnectAccountOrLoginLink
  }
`;

export const CONTINUE_STRIPE_CONNECT_ACCOUNT_ONBOARDING = gql`
  query ContinueConnectAccountOnboarding {
    continueConnectAccountOnboarding
  }
`;
