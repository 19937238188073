import { Trans } from 'react-i18next';

import { GraphqlServiceRequest, GraphqlCircleRequest } from '@graphql/generated';

import { ServiceRequestStatusOptions, CircleRequestStatusOptions } from '@constants/input';
import { getActivityActionNeeded } from '@utils/activities';

import { Select } from 'antd';
import RecordDetailCard from '@components/Card/RecordDetailCard';

type ActivityStatusCardProps = {
  isServiceRequest?: boolean | undefined;
  activity: GraphqlServiceRequest | GraphqlCircleRequest;
  onEdit: (values: any) => void;
};

function ActivityStatusCard({ isServiceRequest, activity, onEdit }: ActivityStatusCardProps) {
  return (
    <RecordDetailCard
      title={<Trans i18nKey="manage.serviceRequest.headers.status" />}
      editable={false}
      onEdit={onEdit}
      cardProps={{
        style: { minWidth: '350px' },
      }}
      fields={[
        {
          name: 'status',
          label: <Trans i18nKey="manage.serviceRequest.fields.status" />,
          value: activity?.status,
          render: () => <Trans i18nKey={`serviceRequestStatus.${activity?.status}`} />,
          editable: true,
          formElement: (
            <Select
              style={{ minWidth: 200 }}
              options={Object.values(
                isServiceRequest ? ServiceRequestStatusOptions : CircleRequestStatusOptions,
              ).map((status) => ({
                label: <Trans i18nKey={`serviceRequestStatus.${status}`} />,
                value: status,
              }))}
            />
          ),
        },
        {
          name: 'actionNeeded',
          label: <Trans i18nKey="manage.serviceRequest.fields.actionNeeded" />,
          value: getActivityActionNeeded(activity as GraphqlCircleRequest),
        },
      ]}
    />
  );
}

ActivityStatusCard.defaultProps = {
  isServiceRequest: undefined,
};

export default ActivityStatusCard;
