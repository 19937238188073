import { useEffect, useMemo, useRef } from 'react';

// Executes callback when the element that gets the returned ref enters in the viewport
export function useCallbackWhenVisible(callback: Function) {
  const ref = useRef(null);
  const observer = useMemo(
    () =>
      new IntersectionObserver(
        (entries) => {
          const [entry] = entries;
          if (entry.isIntersecting) {
            callback();
          }
        },
        { threshold: 0.5 },
      ),
    [callback],
  );

  const currentRef = ref.current;
  useEffect(() => {
    if (currentRef) {
      observer.observe(currentRef);
    }

    return () => (currentRef ? observer.unobserve(currentRef) : undefined);
  }, [currentRef, observer]);

  return ref;
}
