import styled from 'styled-components';
import { Modal as AntModal, ModalProps as AntModalProps } from 'antd';
import { ButtonProps } from 'antd/lib/button';

import Button from '@components/Button';

const StyledModal = styled(AntModal)<AntModalProps>`
  & > .ant-modal-content {
    border-radius: 25px;
    padding: 30px;
  }
`;

export type ModalProps = AntModalProps & {
  onConfirm?: () => void;
  confirmText?: string;
  confirmButtonProps?: ButtonProps;
  confirmLinkWrapper?: (children: JSX.Element) => JSX.Element;
  onCancel?: () => void;
  cancelText?: string;
  cancelButtonProps?: ButtonProps;
  cancelLinkWrapper?: (children: JSX.Element) => JSX.Element;
  showCancelButton?: boolean;
  danger?: boolean;
};

function Modal({
  children,
  onConfirm,
  confirmText = 'OK',
  confirmButtonProps,
  confirmLinkWrapper,
  onCancel,
  cancelText = 'Cancel',
  cancelButtonProps,
  cancelLinkWrapper,
  showCancelButton = true,
  danger = false,
  ...modalProps
}: ModalProps) {
  const footerButtons = [];

  if (onConfirm || confirmButtonProps) {
    const confirmButton = (
      <Button
        danger={danger}
        key="modal-confirm-button"
        htmlType="submit"
        onClick={onConfirm}
        style={{ marginTop: '30px' }}
        {...confirmButtonProps}
      >
        {confirmText}
      </Button>
    );

    footerButtons.push(confirmLinkWrapper ? confirmLinkWrapper(confirmButton) : confirmButton);
  }

  if (onCancel && showCancelButton) {
    const closeButton = (
      <Button
        key="modal-cancel-button"
        type="ghost"
        onClick={onCancel}
        style={{ marginTop: '20px', marginInlineStart: 0 }}
        {...cancelButtonProps}
      >
        {cancelText}
      </Button>
    );

    footerButtons.push(cancelLinkWrapper ? cancelLinkWrapper(closeButton) : closeButton);
  }

  return (
    <StyledModal
      closable={false}
      {...modalProps}
      onOk={onConfirm}
      onCancel={onCancel}
      footer={footerButtons}
    >
      {children}
    </StyledModal>
  );
}

Modal.defaultProps = {
  onConfirm: undefined,
  confirmText: undefined,
  confirmButtonProps: undefined,
  confirmLinkWrapper: undefined,
  onCancel: undefined,
  cancelText: undefined,
  cancelButtonProps: undefined,
  cancelLinkWrapper: undefined,
  showCancelButton: undefined,
  danger: false,
};

export default Modal;
