import { InputMaybe } from '@graphql/generated';
import { DatePicker } from 'antd';
import { FilterDropdownProps } from 'antd/es/table/interface';

type DateFilterProps = FilterDropdownProps & {
  onRangeSelected: (from?: InputMaybe<Date>, until?: InputMaybe<Date>) => void;
};

export function TableDateFilter({ confirm, onRangeSelected }: DateFilterProps) {
  return (
    <DatePicker.RangePicker
      autoFocus
      allowClear
      onChange={(range) => {
        const from = range?.[0]?.startOf('day').toDate() || undefined;
        const until = range?.[1]?.endOf('day').toDate() || undefined;

        confirm({ closeDropdown: true });
        onRangeSelected(from, until);
      }}
    />
  );
}
