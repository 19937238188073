import ImgCrop from 'antd-img-crop';
import { CloudUploadOutlined } from '@ant-design/icons';
import { COOKIES_KEYS } from '@constants/cookies';
import { Avatar as BaseAvatar, Spin, Upload } from 'antd';
import { useCookies } from 'react-cookie';
import styled from 'styled-components';
import { useState } from 'react';
import { GraphqlUser } from '@graphql/generated';

const Centered = styled.div`
  padding: 16px;
  display: flex !important;
  width: 100%;
  justify-content: center;
`;

const Content = styled.div`
  -webkit-box-shadow: 2px 3px 5px 1px rgba(140, 140, 140, 1);
  -moz-box-shadow: 2px 3px 5px 1px rgba(140, 140, 140, 1);
  box-shadow: 2px 3px 5px 1px rgba(140, 140, 140, 1);
  border: 2px solid #555;
  width: auto;
  border-radius: 50%;
  position: relative;
  overflow: hidden;
`;

const Avatar = styled(BaseAvatar)<{ withUpload: boolean | undefined; uploading: boolean }>`
  border: unset;
  opacity: ${({ uploading }) => (uploading ? 0.2 : 1)};

  ${Content}:hover & {
    opacity: ${({ withUpload }) => (withUpload ? 0.2 : 1)};
  }
`;

const Overlay = styled.div<{ uploading: boolean }>`
  position: absolute;
  display: ${({ uploading }) => (uploading ? 'flex' : 'none')};
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 2;
  cursor: pointer;

  ${Content}:hover & {
    display: flex;
  }
`;

interface ProfilePictureProps {
  src: string;
  user: Partial<GraphqlUser>;
  alt?: string;
  size?: number;
  withUpload?: boolean;
  onUploadSuccess?: () => void;
  onUploadError?: (m: string) => void;
}

export function ProfilePicture({
  src,
  user,
  alt,
  size,
  withUpload,
  onUploadSuccess,
  onUploadError,
}: ProfilePictureProps) {
  const [cookies] = useCookies([COOKIES_KEYS.ACCESS_TOKEN]);
  const accessToken = cookies[COOKIES_KEYS.ACCESS_TOKEN];
  const initials = String(user?.firstName)[0] + String(user?.lastName)[0];
  const [uploading, setUploading] = useState(false);

  return (
    <Centered>
      <Content>
        <Avatar alt={alt} src={src} size={size} withUpload={withUpload} uploading={uploading}>
          {src ? null : <div style={{ fontSize: '80px' }}>{initials}</div>}
        </Avatar>
        {withUpload ? (
          <ImgCrop rotationSlider>
            <Upload
              onChange={({ file }) => {
                if (file.status === 'done') {
                  onUploadSuccess?.();
                  setUploading(false);
                }
                if (file.status === 'uploading') {
                  setUploading(true);
                }
                if (file.status === 'error') {
                  onUploadError?.(file?.response?.message || '');
                  setUploading(false);
                }
              }}
              action={`${process.env.REACT_APP_API_URI}/file/upload-profile-picture`}
              headers={{ Authorization: accessToken }}
              accept="image/*"
              showUploadList={false}
              data={{ userId: user?.id }}
            >
              <Overlay uploading={uploading}>
                {uploading ? (
                  <Spin size="large" />
                ) : (
                  <CloudUploadOutlined style={{ fontSize: '50px' }} />
                )}
              </Overlay>
            </Upload>
          </ImgCrop>
        ) : null}
      </Content>
    </Centered>
  );
}

ProfilePicture.defaultProps = {
  alt: 'Profile Picture',
  size: 200,
  withUpload: false,
  onUploadSuccess: () => {},
  onUploadError: () => '',
};
