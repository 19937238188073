import { useMemo, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import styled from 'styled-components';

import {
  GraphqlNotification,
  GraphqlUser,
  useGetNotificationsByUserIdQuery,
  useGetUserNotificationSettingsByUserIdQuery,
} from '@graphql/generated';
import { formatISOtoMMDDYYYY, formatISOtoMMDDYYYYandTime } from '@utils/date';

import { Tooltip as TooltipANTD } from 'antd';
import { ColumnsType } from 'antd/es/table';
import {
  BellOutlined,
  CloseOutlined as CloseOutlinedANTD,
  MailOutlined,
  CheckOutlined,
} from '@ant-design/icons';
import { FlexRow } from '@components/styled';
import { Card } from '@components/Card';
import Table from '@components/Table/Table';
import CreateNotificationModal from '@components/Users/CreateNotificationModal';

const Tooltip = styled(TooltipANTD)`
  svg {
    fill: ${({ theme }) => theme.colors.backgroundCTA};
  }
`;

const CloseOutlined = styled(CloseOutlinedANTD)`
  svg {
    fill: ${({ theme }) => theme.colors.disabled};
  }
`;

function UserNotifications({ user }: { user: GraphqlUser }) {
  const i18n = useTranslation();
  const [showCreateNotificationModal, setShowCreateNotificationModal] = useState(false);
  const { data: notificationsData, loading: notificationsLoading } =
    useGetNotificationsByUserIdQuery({
      variables: { userId: user.id, filter: {} },
    });
  const { data: notificationSettingsData } = useGetUserNotificationSettingsByUserIdQuery({
    variables: {
      userId: user.id,
      notificationType: 'general',
    },
  });

  const notifications = useMemo(
    () => notificationsData?.getNotificationsByUserId?.notifications || [],
    [notificationsData],
  );

  const columns: ColumnsType<any> = useMemo(
    () => [
      {
        title: <Trans i18nKey="manage.users.detail.notifications.columns.createdAt" />,
        dataIndex: 'createdAt',
        key: 'createdAt',
        render: (_: any, record: any) => formatISOtoMMDDYYYY(record.createdAt),
      },
      {
        title: <Trans i18nKey="manage.users.detail.notifications.columns.text" />,
        dataIndex: 'text',
        key: 'text',
      },
      {
        title: <Trans i18nKey="manage.users.detail.notifications.columns.sentAt" />,
        key: 'sentAt',
        width: 80,
        minWidth: 80,
        render: (_: any, record: GraphqlNotification) => (
          <FlexRow>
            <Tooltip
              title={i18n.t('manage.users.detail.notifications.tooltips.email', {
                timestamp: formatISOtoMMDDYYYYandTime(record?.emailSentAt) || 'Not sent',
                interpolation: { escapeValue: false },
              })}
            >
              {record?.emailSentAt ? <MailOutlined /> : <CloseOutlined />}
            </Tooltip>
            {/* This has been commented out as we don't have a sms feature implemented for v1
            {/* <Tooltip
              title={i18n.t('manage.users.detail.notifications.tooltips.sms', {
                timestamp: formatISOtoMMDDYYYYandTime(record?.smsSentAt) || 'Not sent',
                interpolation: { escapeValue: false },
              })}
            >
              {record?.smsSentAt ? <MessageOutlined /> : <CloseOutlined />}
            </Tooltip> */}
            <Tooltip
              title={i18n.t('manage.users.detail.notifications.tooltips.push', {
                timestamp: formatISOtoMMDDYYYYandTime(record?.pushSentAt) || 'Not sent',
                interpolation: { escapeValue: false },
              })}
            >
              {record?.pushSentAt ? <BellOutlined /> : <CloseOutlined />}
            </Tooltip>
          </FlexRow>
        ),
      },
    ],
    [i18n],
  );

  return (
    <Card
      title={<Trans i18nKey="manage.users.detail.notifications.title" />}
      loading={notificationsLoading}
      style={{ minWidth: '350px', maxWidth: '700px' }}
      extra={
        <button type="button" onClick={() => setShowCreateNotificationModal(true)}>
          <Trans i18nKey="manage.users.detail.notifications.new" />
        </button>
      }
    >
      <FlexRow style={{ padding: '8px 4px' }}>
        <FlexRow style={{ marginRight: 4 }}>
          <Trans i18nKey="manage.users.detail.notifications.userSettings" />:
        </FlexRow>
        <FlexRow style={{ gap: 6, alignItems: 'center', marginRight: 8 }}>
          <Trans i18nKey="manage.users.detail.notifications.emailNotifications" />:{' '}
          {notificationSettingsData?.getUserNotificationSettingsByUserId.email ? (
            <CheckOutlined />
          ) : (
            <CloseOutlined />
          )}
        </FlexRow>
        <FlexRow style={{ gap: 6, alignItems: 'center', marginRight: 8 }}>
          <Trans i18nKey="manage.users.detail.notifications.pushNotifications" />:{' '}
          {notificationSettingsData?.getUserNotificationSettingsByUserId.push ? (
            <CheckOutlined />
          ) : (
            <CloseOutlined />
          )}
        </FlexRow>
        {/* This has been commented out as we don't have a sms feature implemented for v1
        {/* <FlexRow style={{ gap: 6, alignItems: 'center' }}>
          <Trans i18nKey="manage.users.detail.notifications.smsNotifications" />:{' '}
          {notificationSettingsData?.getUserNotificationSettingsByUserId.sms ? (
            <CheckOutlined />
          ) : (
            <CloseOutlined />
          )}
        </FlexRow> */}
      </FlexRow>
      <Table
        columns={columns}
        dataSource={(notifications || []).map((customer) => ({ ...customer, key: customer.id }))}
        pagination={false}
      />
      {showCreateNotificationModal && (
        <CreateNotificationModal
          user={user}
          onCancel={() => setShowCreateNotificationModal(false)}
        />
      )}
    </Card>
  );
}

export default UserNotifications;
