import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { GetCustomerOutput, useGetCustomersQuery } from '@graphql/generated';

import { useDebounce } from '@hooks/useDebounce';
import { useUser } from '@hooks/appContext/useUser';

import { DEFAULT_SEARCH_DEBOUNCE_TIME } from '@constants/input';
import getCustomersQueryVariables from '@utils/getCustomersQueryVariables';

import { Form, Select, Spin } from 'antd';
import { CenterRow } from '@components/styled';

type CustomerSearchProps = {
  label?: string | null;
  rules?: any;
  onChange: (val: GetCustomerOutput) => void;
  selectedCustomer?: GetCustomerOutput | null;
};

function CustomerSearch({ label, rules, onChange, selectedCustomer }: CustomerSearchProps) {
  const i18n = useTranslation();
  const [search, setSearch] = useState<string>('');
  const debouncedSearch = useDebounce(search, DEFAULT_SEARCH_DEBOUNCE_TIME);
  const loggedUser = useUser();
  const { data, loading } = useGetCustomersQuery({
    variables: {
      filters: getCustomersQueryVariables(loggedUser, debouncedSearch),
    },
  });

  const options = useMemo(() => {
    return (data?.getCustomers || []).map((customer) => ({
      label: customer.fullName,
      value: customer.id,
    }));
  }, [data]);

  return (
    <Form.Item
      name="customerId"
      label={label}
      rules={rules}
      labelCol={label ? { span: 24 } : undefined}
    >
      <Select
        showSearch
        filterOption={false}
        onSearch={setSearch}
        onChange={(id: number) =>
          onChange(
            (data?.getCustomers || []).find((customer) => customer?.id === id) as GetCustomerOutput,
          )
        }
        placeholder={i18n.t('manage.customers.searchUser')}
        notFoundContent={
          loading ? (
            <CenterRow>
              <Spin size="small" />
            </CenterRow>
          ) : null
        }
        options={options}
        value={selectedCustomer?.id}
        style={{ width: '100%', minWidth: 200 }}
      />
    </Form.Item>
  );
}

CustomerSearch.defaultProps = {
  label: null,
  rules: null,
  selectedCustomer: null,
};

export default CustomerSearch;
