import { Card } from '@components/Card';
import Table from '@components/Table/Table';
import ROUTES from '@constants/routes';
import {
  GetCustomerOutput,
  GlobalRole,
  SortOrder,
  useGetCustomersQuery,
  CustomerSortableFields,
} from '@graphql/generated';
import { formatISOtoMMDDYYYY } from '@utils/date';
import { ColumnsType } from 'antd/es/table';
import { useMemo } from 'react';
import { Trans } from 'react-i18next';
import { Link } from 'react-router-dom';

function UserAccounts({ userId }: { userId: number }) {
  const { data: userCustomersQuery, loading: isFetchingCustomers } = useGetCustomersQuery({
    variables: {
      filters: { userId, sortField: CustomerSortableFields.CreatedAt, sortValue: SortOrder.Desc },
    },
  });
  const customers = userCustomersQuery?.getCustomers;
  const columns: ColumnsType<any> = useMemo(
    () => [
      {
        title: <Trans i18nKey="manage.users.detail.accounts.columns.fullName" />,
        dataIndex: 'fullName',
        key: 'fullName',
        render: (_: any, record: GetCustomerOutput) => (
          <Link to={ROUTES.MANAGE_CUSTOMER.replace(':customerId', String(record.id))}>
            {record.fullName}
          </Link>
        ),
      },
      {
        title: <Trans i18nKey="manage.users.detail.accounts.columns.role" />,
        dataIndex: 'role',
        key: 'role',
        render: (_: any, record: GetCustomerOutput) => {
          const currentUser = record.users?.find((u: any) => u.user.id === userId);
          return <Trans i18nKey={`roles.${currentUser?.role}`} />;
        },
      },
      {
        title: <Trans i18nKey="manage.users.detail.accounts.columns.coach" />,
        key: 'coach',
        render: (_: any, record: GetCustomerOutput) => {
          const coach = record.users?.find((user) => user?.role === GlobalRole.Coach);
          return coach ? coach.user?.fullName : 'N/A';
        },
      },
      {
        title: <Trans i18nKey="manage.users.detail.accounts.columns.createdAt" />,
        dataIndex: 'createdAt',
        key: 'createdAt',
        render: (_: any, record: any) => formatISOtoMMDDYYYY(record.createdAt),
      },
    ],
    [userId],
  );

  return (
    <Card
      title={<Trans i18nKey="manage.users.detail.accounts.title" />}
      loading={isFetchingCustomers}
      style={{ minWidth: '350px', maxWidth: '700px' }}
    >
      <Table
        columns={columns}
        dataSource={(customers || []).map((customer) => ({ ...customer, key: customer.id }))}
        pagination={false}
      />
    </Card>
  );
}

export default UserAccounts;
