import { useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Trans, useTranslation } from 'react-i18next';
import dayjs from 'dayjs';

import { GET_SOCIAL_EVENT_QUERY } from '@graphql/socialEvents';
import { GET_APPOINTMENT_REQUESTS_QUERY, GET_ACTIVITY_TIMELINE_QUERY } from '@graphql/activities';
import {
  ContactRequestStatus,
  CustomerSortableFields,
  GlobalRole,
  GraphqlAddresses,
  GraphqlContactRequest,
  GraphqlParticipants,
  GraphqlInternalNote,
  GraphqlSocialEvent,
  SocialEventStatus,
  SortOrder,
  useGetSocialEventQuery,
  useGetActivityTimelineQuery,
  useGetCustomersQuery,
  useGetUsersQuery,
  UserSortableFields,
  useUpdateSocialEventMutation,
  useUpdateContactRequestMutation,
} from '@graphql/generated';
import { ApolloError } from '@apollo/client';

import ROUTES from '@constants/routes';
import { truncate } from '@utils/string';
import { formatAddressBlock } from '@utils/formatters/address';
import { useDateWithTimezone } from '@hooks/useDateWithTimezone';

import { DatePicker, Select, Table, Timeline, Typography } from 'antd';
import { ColumnsType } from 'antd/es/table';
import Masonry, { ResponsiveMasonry } from 'react-responsive-masonry';
import Link from '@components/Link';
import Modal from '@components/Modal';
import Card from '@components/Card/Card';
import Loading from '@components/Loading';
import Input, { InputTextarea } from '@components/Input';
import GenericApolloError from '@components/GenericApolloError';
import RecordDetailCard from '@components/Card/RecordDetailCard';
import AddEditNoteModal from '@components/Activities/AddEditNoteModal';
import ServiceRequestAgents from '@components/Activities/ServiceRequestAgents';
import LocationDropdownOther from '@components/Activities/LocationDropdownOther';

const ALL_CONTACT_REQUEST_STATUSES = Object.values(ContactRequestStatus);

type ExtendedContactRequest = GraphqlContactRequest & {
  Participant: GraphqlParticipants;
  key: number;
};

function SocialEvent() {
  const i18n = useTranslation();
  const { formatDateAndTimeInTimezone, transformToTimezone } = useDateWithTimezone();
  const [agentModalIsOpen, setAgentModalIsOpen] = useState<boolean>(false);
  const [noteModalIsOpen, setNoteModalIsOpen] = useState<boolean | GraphqlInternalNote>(false);

  const { activityId } = useParams<{ activityId: string }>();
  const {
    data: socialEventData,
    loading: socialEventLoading,
    error: socialEventError,
  } = useGetSocialEventQuery({
    variables: { socialEventId: parseInt(activityId as string, 10) },
  });
  const { data: customersData } = useGetCustomersQuery({
    variables: {
      filters: { sortField: CustomerSortableFields.FullName, sortValue: SortOrder.Desc },
    },
  });
  const { data: coachesData } = useGetUsersQuery({
    variables: {
      filter: { roles: [GlobalRole.Admin, GlobalRole.Support, GlobalRole.Coach] },
      page: 1,
      pageSize: 100,
      sort: { field: UserSortableFields.FullName, direction: SortOrder.Asc },
    },
  });
  const {
    data: activityTimelineData,
    loading: activityTimelineLoading,
    error: activityTimelineError,
  } = useGetActivityTimelineQuery({
    variables: {
      orderIn: 'asc' as SortOrder,
      activityId: parseInt(activityId as string, 10),
    },
  });
  const [updateSocialEventMutation] = useUpdateSocialEventMutation({
    refetchQueries: [
      GET_SOCIAL_EVENT_QUERY,
      GET_APPOINTMENT_REQUESTS_QUERY,
      GET_ACTIVITY_TIMELINE_QUERY,
    ],
  });
  const [updateContactRequestMutation] = useUpdateContactRequestMutation({
    refetchQueries: [GET_SOCIAL_EVENT_QUERY],
  });

  const socialEvent = useMemo(() => {
    return socialEventData?.getSocialEvent || ({} as GraphqlSocialEvent);
  }, [socialEventData]);

  const timelineItems = useMemo(() => {
    return activityTimelineData?.getTimeline?.map((timelineItem) => ({
      label: `${dayjs(timelineItem.createdAt).format('MM/DD/YY')} (${dayjs(
        timelineItem.createdAt,
      ).fromNow()})`,
      children: <div>{timelineItem.description}</div>,
    }));
  }, [activityTimelineData]);

  const hasContactRequests = useMemo(() => {
    return socialEvent?.Participants?.map((participant) => participant?.ContactRequests).flat()
      .length;
  }, [socialEvent]);

  const columns: ColumnsType<ExtendedContactRequest> = useMemo(
    () => [
      {
        title: i18n.t('manage.socialEvent.columns.requestedBy') as string,
        key: 'requestedBy',
        render: (_: any, record) => (
          <Link
            to={ROUTES.MANAGE_CUSTOMER.replace(
              ':customerId',
              String(record?.Participant?.Customer?.id),
            )}
          >
            {record?.Participant?.Customer?.fullName}
          </Link>
        ),
      },
      {
        title: i18n.t('manage.socialEvent.columns.createdAt') as string,
        dataIndex: 'createdAt',
        key: 'createdAt',
        width: 115,
        render: (_: any, record) => (
          <div style={{ textAlign: 'right' }}>
            <div>{formatDateAndTimeInTimezone(record?.createdAt, 'MMMM Do, YYYY')}</div>
            <div>{formatDateAndTimeInTimezone(record?.createdAt, '@ h:mma z')}</div>
          </div>
        ),
      },
      {
        title: i18n.t('manage.socialEvent.columns.userMessage') as string,
        key: 'userMessage',
        render: (_: any, record) => truncate(record?.userMessage),
      },
      {
        title: i18n.t('manage.socialEvent.columns.bryaInternalNotes') as string,
        key: 'bryaInternalNotes',
        render: (_: any, record) => truncate(record?.bryaInternalNotes),
      },
      {
        title: i18n.t('manage.socialEvent.columns.status') as string,
        key: 'status',
        render: (_: any, record) => i18n.t(`socialEventStatus.${record?.status}`),
      },
    ],
    [i18n, formatDateAndTimeInTimezone],
  );

  function renderExpandedRow(record: any) {
    return (
      <RecordDetailCard
        editable
        onEdit={async (variables: any) => {
          await updateContactRequestMutation({ variables });
        }}
        cardProps={{
          style: { minWidth: '350px' },
        }}
        fields={[
          {
            name: 'requestedBy',
            label: <Trans i18nKey="manage.socialEvent.columns.requestedBy" />,
            value: record?.Participant?.Customer?.id,
            render: () => (
              <Link
                to={ROUTES.MANAGE_CUSTOMER.replace(
                  ':customerId',
                  String(record?.Participant?.Customer?.id),
                )}
              >
                {record?.Participant?.Customer?.fullName}
              </Link>
            ),
          },
          {
            name: 'updateContactRequestInput.userMessage',
            label: <Trans i18nKey="manage.socialEvent.columns.userMessage" />,
            value: record?.userMessage,
            editable: true,
            formElement: <InputTextarea style={{ minWidth: 600 }} autoSize />,
          },
          {
            name: 'updateContactRequestInput.bryaInternalNotes',
            label: <Trans i18nKey="manage.socialEvent.columns.bryaInternalNotes" />,
            value: record?.bryaInternalNotes || '',
            render: () => record?.bryaInternalNotes || i18n.t('fallbacks.N/A'),
            editable: true,
            formElement: <InputTextarea style={{ minWidth: 600 }} autoSize />,
          },
          {
            name: 'updateContactRequestInput.status',
            label: <Trans i18nKey="manage.socialEvent.columns.status" />,
            value: record?.status,
            render: () => i18n.t(`socialEventStatus.${record?.status}`),
            editable: true,
            formElement: (
              <Select
                style={{ minWidth: 600 }}
                options={ALL_CONTACT_REQUEST_STATUSES.map((status) => ({
                  value: status,
                  label: i18n.t(`socialEventStatus.${status}`),
                }))}
              />
            ),
          },
          {
            name: 'contactRequestId',
            label: '',
            hidden: true,
            editable: true,
            value: record?.id,
          },
        ]}
      />
    );
  }

  if (socialEventLoading || activityTimelineLoading) {
    return <Loading />;
  }

  if (socialEventError || activityTimelineError) {
    return (
      <GenericApolloError error={(socialEventError || activityTimelineError) as ApolloError} />
    );
  }

  return (
    <div style={{ maxWidth: 1800, margin: '0 auto' }}>
      <Typography.Title
        level={2}
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
        }}
      >
        <Trans
          i18nKey="manage.socialEvent.headers.id"
          values={{ id: String(socialEvent?.id).padStart(5, '0') }}
        />
      </Typography.Title>
      <ResponsiveMasonry columnsCountBreakPoints={{ 350: 1, 1000: 2, 1750: 3 }}>
        <Masonry gutter="20px">
          <RecordDetailCard
            title={
              <>
                <Trans i18nKey="manage.socialEvent.headers.name" />
                <span>{socialEvent?.eventName}</span>
              </>
            }
            editable
            onEdit={async (values) => {
              await updateSocialEventMutation({
                variables: {
                  socialEventId: socialEvent.id,
                  data: {
                    status: values?.status?.toLowerCase(),
                    description: values?.description,
                    scheduledAt: dayjs(values.scheduledAt).toDate(),
                    recurring: values?.recurring,
                    frequency: values?.frequency,
                    SocialEvent: {
                      update: {
                        place: values?.location?.place || '',
                        addressLine1: values?.location?.addressLine1,
                        addressLine2: values?.location?.addressLine2,
                        city: values?.location?.city,
                        state: values?.location?.state,
                        zipCode: values?.location?.zipCode,
                        country: values?.location?.country,
                      },
                    },
                  },
                },
              });
            }}
            cardProps={{
              style: { minWidth: '350px' },
            }}
            fields={[
              {
                name: 'requestedBy',
                label: <Trans i18nKey="manage.serviceRequest.fields.createdBy" />,
                value: (
                  <Link
                    to={ROUTES.MANAGE_CUSTOMER.replace(
                      ':customerId',
                      String(socialEvent?.RequestedBy?.id),
                    )}
                  >
                    {socialEvent?.RequestedBy?.fullName}
                  </Link>
                ),
              },
              {
                name: 'status',
                label: <Trans i18nKey="manage.socialEvent.fields.status" />,
                value: i18n.t(`serviceRequestStatus.${socialEvent?.status}`) as string,
                render: () => <Trans i18nKey={`serviceRequestStatus.${socialEvent?.status}`} />,
                editable: true,
                formElement: (
                  <Select style={{ minWidth: 200 }}>
                    {Object.values(SocialEventStatus).map((status) => (
                      <Select.Option
                        value={status}
                        disabled={status === SocialEventStatus.Completed}
                      >
                        {String(i18n.t(`serviceRequestStatus.${status}`))}
                      </Select.Option>
                    ))}
                  </Select>
                ),
              },
              {
                name: 'description',
                label: <Trans i18nKey="manage.serviceRequest.fields.description" />,
                value: socialEvent?.description,
                editable: true,
                formElement: <InputTextarea rows={6} style={{ width: 400 }} />,
              },
              {
                name: 'location',
                label: <Trans i18nKey="manage.serviceRequest.fields.location" />,
                value: socialEvent as unknown as GraphqlAddresses,
                render: () => formatAddressBlock(socialEvent as unknown as GraphqlAddresses),
                editable: true,
                formElement: (
                  <LocationDropdownOther
                    containerStyle={{ width: 400 }}
                    addresses={[]}
                    placeholder=""
                    onChange={() => {}}
                  />
                ),
              },
              {
                name: 'scheduledAt',
                label: <Trans i18nKey="manage.serviceRequest.fields.serviceDate" />,
                value: transformToTimezone(socialEvent?.scheduledAt),
                render: () =>
                  socialEvent?.scheduledAt ? (
                    <div style={{ textAlign: 'right' }}>
                      <div>
                        {formatDateAndTimeInTimezone(socialEvent.scheduledAt, 'MMMM Do, YYYY')}
                      </div>
                      <div>{formatDateAndTimeInTimezone(socialEvent.scheduledAt, '@ h:mma z')}</div>
                    </div>
                  ) : (
                    'N/A'
                  ),
                editable: true,
                formElement: (
                  <DatePicker use12Hours showTime changeOnBlur format="MMMM DD, YYYY @ h:mma z" />
                ),
              },
              {
                name: 'recurring',
                label: <Trans i18nKey="manage.serviceRequest.fields.recurring" />,
                value: socialEvent?.recurring,
                render: () =>
                  socialEvent?.recurring ? socialEvent?.frequency : i18n.t('boolean.false'),
                editable: true,
                isBoolean: true,
              },
              {
                name: 'frequency',
                label: <Trans i18nKey="manage.serviceRequest.fields.frequency" />,
                value: socialEvent?.frequency,
                editable: true,
                onlyRenderEdit: true,
                render: () => null,
                formElement: <Input />,
              },
            ]}
          />

          <RecordDetailCard
            title={<Trans i18nKey="manage.socialEvent.headers.participants" />}
            cardProps={{
              style: { minWidth: '350px' },
            }}
            editable
            onEdit={async (values) => {
              await updateSocialEventMutation({
                variables: {
                  socialEventId: socialEvent.id,
                  data: values,
                },
              });
            }}
            fields={[
              {
                name: 'columns',
                label: <Trans i18nKey="manage.customer.associatedUsers.columns.name" />,
                value: (
                  <strong>
                    <Trans i18nKey="manage.customer.associatedUsers.columns.rsvp" />
                  </strong>
                ),
                isColumn: true,
              },
              {
                name: 'participantsIds',
                label: <Trans i18nKey="manage.socialEvent.actions.add/remove" />,
                value: socialEvent?.Participants?.map((participant) => participant?.Customer?.id),
                editable: true,
                onlyRenderEdit: true,
                formElement: (
                  <Select
                    mode="multiple"
                    style={{ minWidth: 400 }}
                    optionFilterProp="label"
                    options={customersData?.getCustomers?.map((customer) => ({
                      label: customer?.fullName,
                      value: customer?.id,
                    }))}
                  />
                ),
              },
              ...(socialEvent?.Participants?.map(({ Customer, status }) => ({
                name: 'participants',
                noRenderEdit: true,
                label: (
                  <Link to={ROUTES.MANAGE_CUSTOMER.replace(':customerId', String(Customer?.id))}>
                    {Customer?.fullName}
                  </Link>
                ),
                value: <Trans i18nKey={`serviceRequestStatus.${status}`} />,
              })) || []),
              {
                name: 'bryaRep',
                label: <Trans i18nKey="manage.customer.associatedUsers.columns.bryaRep" />,
                value: <div />,
                isColumn: true,
              },
              {
                name: 'coachesIds',
                label: <Trans i18nKey="manage.socialEvent.actions.add/remove" />,
                value: socialEvent?.Coaches?.map((coach) => coach?.Coach?.id),
                editable: true,
                onlyRenderEdit: true,
                formElement: (
                  <Select
                    mode="multiple"
                    style={{ minWidth: 400 }}
                    optionFilterProp="label"
                    options={coachesData?.getUsers?.users?.map((coach) => ({
                      label: coach?.fullName,
                      value: coach?.id,
                    }))}
                  />
                ),
              },
              ...(socialEvent?.Coaches?.map(({ Coach }) => ({
                name: 'bryaReps',
                noRenderEdit: true,
                label: (
                  <Link to={ROUTES.MANAGE_USER.replace(':userId', String(Coach?.id))}>
                    {Coach?.fullName}
                  </Link>
                ),
                value: <Trans i18nKey="socialEventStatus.hosting" />,
              })) || []),
              {
                name: 'divider',
                isColumn: true,
                label: <div />,
                value: <div style={{ margin: 10 }} />,
              },
              {
                name: 'total',
                label: <Trans i18nKey="manage.socialEvent.headers.totalUsers" />,
                value: socialEvent?.Participants?.length || 0,
              },
            ]}
          />

          {!!timelineItems?.length && (
            <Card title={i18n.t('manage.serviceRequest.headers.timeline')}>
              <div>
                <Timeline mode="left" items={timelineItems} />
              </div>
            </Card>
          )}
        </Masonry>
      </ResponsiveMasonry>
      {!!hasContactRequests && (
        <Card
          title={i18n.t('manage.socialEvent.headers.contactRequests')}
          style={{ marginTop: 20 }}
        >
          <div>
            <Table
              columns={columns}
              dataSource={socialEvent?.Participants.map((participant) =>
                participant.ContactRequests.map(
                  (contactRequest) =>
                    ({
                      ...contactRequest,
                      Participant: participant,
                      key: contactRequest.id,
                    } as ExtendedContactRequest),
                ),
              ).flat()}
              expandable={{
                expandedRowRender: renderExpandedRow,
              }}
              pagination={false}
            />
          </div>
        </Card>
      )}
      <Modal
        width="max-content"
        closable
        onCancel={() => setAgentModalIsOpen(!agentModalIsOpen)}
        open={agentModalIsOpen}
        title={
          <Typography.Title level={2}>
            <Trans i18nKey="forms.sendToAgent.title" />
          </Typography.Title>
        }
      >
        <ServiceRequestAgents serviceRequestId={socialEvent?.id as number} />
      </Modal>
      {!!noteModalIsOpen && (
        <AddEditNoteModal
          onCancel={() => setNoteModalIsOpen(!noteModalIsOpen)}
          note={noteModalIsOpen as GraphqlInternalNote}
        />
      )}
    </div>
  );
}

export default SocialEvent;
