import { GraphqlServiceRequestPayment } from '@graphql/generated';

export function renderCurrency(amount: number): string {
  return new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(amount);
}

export function calculateServiceRequestPaymentTotal(
  serviceRequestPayment: GraphqlServiceRequestPayment,
): number {
  return (
    (serviceRequestPayment?.amount || 0) +
    (serviceRequestPayment?.tax || 0) +
    (serviceRequestPayment?.fee || 0)
  );
}

export function renderServiceRequestPaymentTotal(
  serviceRequestPayment: GraphqlServiceRequestPayment,
): string {
  return renderCurrency(calculateServiceRequestPaymentTotal(serviceRequestPayment));
}
