import { Row, Table, Typography } from 'antd';
import axios from 'axios';
import { useEffect, useMemo, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import styled from 'styled-components';
import Button from '@components/Button';
import { DownloadOutlined } from '@ant-design/icons';
import { FilterValue, SorterResult, TablePaginationConfig } from 'antd/es/table/interface';
import { ColumnsType } from 'antd/es/table';
import {
  GraphqlUser,
  UserSortInput,
  InputMaybe,
  UserSortableFields,
  SortOrder,
  PaginationMeta,
  useGetLeadsQuery,
  UserFilter,
} from '@graphql/generated';
import Input from '@components/Input';
import { formatISOtoMMDDYYYY } from '@utils/date';

import { useCookies } from 'react-cookie';
import { COOKIES_KEYS } from '@constants/cookies';
import { DEFAULT_PAGE_SIZE, PAGE_SIZES } from '@components/Table/Table';
import { DEFAULT_SEARCH_DEBOUNCE_TIME } from '@constants/input';

const StyledTable: typeof Table = styled(Table)`
  a {
    line-height: inherit;
  }
`;

type LeadsProps = {
  searchDebounceTime?: number;
};

function Leads({ searchDebounceTime = DEFAULT_SEARCH_DEBOUNCE_TIME }: LeadsProps) {
  const i18n = useTranslation();
  const [searchUserText, setSearchUserText] = useState('');
  const [filters, setFilters] = useState<UserFilter>({
    text: searchUserText,
    noRole: true,
  });
  const [sortOptions, setSortOptions] = useState<InputMaybe<UserSortInput>>();
  const [pageSize, setPageSize] = useState(DEFAULT_PAGE_SIZE);
  const [page, setPage] = useState(1);
  const {
    data: getLeadsQuery,
    previousData,
    loading,
  } = useGetLeadsQuery({
    variables: { filter: filters, sort: sortOptions, page, pageSize },
  });
  const leads = ((getLeadsQuery || previousData)?.getUsers.users as GraphqlUser[]) || [];
  const pagination = ((getLeadsQuery || previousData)?.getUsers.meta as PaginationMeta) || {};
  const [cookies] = useCookies([COOKIES_KEYS.ACCESS_TOKEN]);
  const accessToken = cookies[COOKIES_KEYS.ACCESS_TOKEN];

  useEffect(() => {
    const timer = setTimeout(() => {
      setFilters((previousFilters) => ({ ...previousFilters, text: searchUserText }));
    }, searchDebounceTime);
    return () => clearTimeout(timer);
  }, [searchUserText, searchDebounceTime]);

  const columns: ColumnsType<GraphqlUser> = useMemo(
    () => [
      {
        title: i18n.t('manage.managementUsers.table.columns.fullName') as string,
        dataIndex: 'fullName',
        key: 'fullName',
        sorter: true,
      },
      {
        title: i18n.t('manage.managementUsers.table.columns.email') as string,
        dataIndex: 'email',
        key: 'email',
        sorter: true,
      },
      {
        title: i18n.t('manage.managementUsers.table.columns.createdAt') as string,
        dataIndex: 'createdAt',
        key: 'createdAt',
        sorter: true,
        render: (_: any, record: any) => formatISOtoMMDDYYYY(record.createdAt),
      },
      {
        title: i18n.t('manage.managementUsers.table.columns.lastLogin') as string,
        dataIndex: 'lastLogin',
        key: 'lastLogin',
        render: (_: any, record: any) => formatISOtoMMDDYYYY(record.lastLogin),
      },
    ],
    [i18n],
  );

  const onTableChange = (
    tablePagination: TablePaginationConfig,
    tableFilters: Record<string, FilterValue | null>,
    tableSorter: SorterResult<GraphqlUser> | SorterResult<GraphqlUser>[],
  ) => {
    const uniqueSorter = tableSorter as SorterResult<GraphqlUser>;

    // Pagination
    setPage(tablePagination.current as number);
    setPageSize(tablePagination.pageSize as number);

    // Order
    if (uniqueSorter.order) {
      const direction = uniqueSorter.order === 'ascend' ? 'asc' : 'desc';
      setSortOptions({
        field: uniqueSorter.field as UserSortableFields,
        direction: direction as SortOrder,
      });
    } else {
      setSortOptions(undefined);
    }
  };

  const downloadCSVLeads = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URI}/csv/leads`, {
        responseType: 'blob',
        headers: {
          Authorization: accessToken,
        },
      });
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'export-leads.csv');
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(`Csv Error:`, e);
    }
  };
  return (
    <>
      <Row justify="space-between" align="middle" style={{ marginBottom: '20px' }}>
        <Typography.Title level={2}>
          <Trans i18nKey="manage.leads.title" />
        </Typography.Title>
        <div>
          <Button type="primary" onClick={downloadCSVLeads}>
            <DownloadOutlined style={{ marginRight: '10px' }} />
            <Trans i18nKey="manage.leads.downloadCSV" />
          </Button>
        </div>
      </Row>
      <Row style={{ marginBottom: '20px' }}>
        <Input
          placeholder={i18n.t('manage.leads.search') as string}
          value={searchUserText}
          onChange={(e) => setSearchUserText(e.target.value)}
        />
      </Row>
      <StyledTable
        loading={loading}
        columns={columns}
        dataSource={leads.map((lead) => ({ ...lead, key: lead.id }))}
        onChange={onTableChange}
        pagination={{
          pageSize,
          pageSizeOptions: PAGE_SIZES,
          showSizeChanger: true,
          defaultCurrent: 1,
          current: pagination?.currentPage,
          total: pagination?.totalCount,
        }}
      />
    </>
  );
}

Leads.defaultProps = {
  searchDebounceTime: DEFAULT_SEARCH_DEBOUNCE_TIME,
};

export default Leads;
