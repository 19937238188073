import Link from '@components/Link';
import { Trans, useTranslation } from 'react-i18next';
import { GraphqlAgent } from '@graphql/generated';

import ROUTES from '@constants/routes';

import { Tooltip, Typography } from 'antd';

export function AgentLink({ agent }: { agent: GraphqlAgent }) {
  const i18n = useTranslation();

  if (!agent) {
    return <Trans i18nKey="fallbacks.N/A" />;
  }

  if (agent?.deletedAt) {
    return (
      <Tooltip title={i18n.t('fallbacks.deleted', { model: 'agent' })}>
        <Typography.Text style={{ textDecoration: 'line-through' }}>
          {agent?.User?.fullName}
        </Typography.Text>
      </Tooltip>
    );
  }

  return (
    <Link to={ROUTES.MANAGE_AGENT.replace(':agentId', String(agent?.User?.id))}>
      {agent?.User?.fullName}
    </Link>
  );
}

export default AgentLink;
