import { useEffect, useMemo, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import {
  GraphqlAgent,
  GraphqlServiceRequest,
  InputMaybe,
  PaginationMeta,
  ServiceRequestCategoriesEnum,
  ServiceRequestFilter,
  ServiceRequestSortInput,
  ServiceRequestsSortableFields,
  ServiceRequestStatus,
  SortOrder,
  useGetServiceRequestsQuery,
  useListRequestCategoriesQuery,
} from '@graphql/generated';

import ROUTES from '@constants/routes';
import { DEFAULT_SEARCH_DEBOUNCE_TIME } from '@constants/input';
import { formatISOtoMMDDYYYY } from '@utils/date';

import { CardProps, Row } from 'antd';
import { ColumnsType, TablePaginationConfig } from 'antd/es/table';
import { FilterValue, SorterResult } from 'antd/es/table/interface';
import Input from '@components/Input';
import { Card } from '@components/Card';
import Table from '@components/Table/Table';
import AgentLink from '@components/AgentLink';
import GenericApolloError from '@components/GenericApolloError';
import { TableDateFilter } from '@components/Table/TableDateFilter';
import { ServiceRequestStatusTag } from '@components/Activities/ServiceRequestStatusTag';
import { useDateWithTimezone } from '@hooks/useDateWithTimezone';

const SERVICE_REQUEST_ID_MIN_LENGHT = 5;

function AgentsServiceRequests({
  agentIds,
  cardProps,
  showPageSizeChanger,
  defaultPageSize,
}: {
  agentIds: number[] | undefined;
  cardProps?: CardProps;
  defaultPageSize?: number;
  showPageSizeChanger?: boolean;
}) {
  const i18n = useTranslation();
  const { formatDateAndTimeInTimezone } = useDateWithTimezone();
  const [searchServiceRequestsText, setSearchServiceRequestsText] = useState('');
  const [filters, setFilters] = useState<ServiceRequestFilter>({
    agentId: agentIds,
  });
  const [sortOptions, setSortOptions] = useState<InputMaybe<ServiceRequestSortInput>>();
  const [pageSize, setPageSize] = useState(defaultPageSize);
  const [page, setPage] = useState(1);
  const {
    data: serviceRequestsQuery,
    error,
    loading: loadingServiceRequests,
    previousData,
  } = useGetServiceRequestsQuery({
    variables: { filter: filters, sort: sortOptions, page, pageSize },
  });
  const { data: serviceRequestCategories } = useListRequestCategoriesQuery({
    variables: { serviceRequest: true },
  });

  useEffect(() => {
    const timer = setTimeout(() => {
      setFilters((previousFilters) => ({ ...previousFilters, text: searchServiceRequestsText }));
    }, DEFAULT_SEARCH_DEBOUNCE_TIME);
    return () => clearTimeout(timer);
  }, [searchServiceRequestsText]);

  const serviceRequests = useMemo(
    () =>
      ((serviceRequestsQuery || previousData)?.getServiceRequests
        .serviceRequests as GraphqlServiceRequest[]) || [],
    [serviceRequestsQuery, previousData],
  );

  const pagination =
    ((serviceRequestsQuery || previousData)?.getServiceRequests.meta as PaginationMeta) || {};

  const columns: ColumnsType<GraphqlServiceRequest> = useMemo(
    () => [
      {
        title: <Trans i18nKey="manage.serviceRequests.columns.id" />,
        dataIndex: 'id',
        key: 'id',
        render: (_: any, record: GraphqlServiceRequest) => (
          <Link to={ROUTES.MANAGE_SERVICE_REQUEST.replace(':activityId', String(record.id))}>
            {String(record.id).padStart(SERVICE_REQUEST_ID_MIN_LENGHT, '0')}
          </Link>
        ),
        sorter: true,
      },
      {
        title: <Trans i18nKey="manage.serviceRequests.columns.requestedBy" />,
        key: 'requestedBy',
        render: (_: any, record: GraphqlServiceRequest) => (
          <Link to={ROUTES.MANAGE_USER.replace(':userId', String(record.RequestedBy.id))}>
            {record.RequestedBy.fullName}
          </Link>
        ),
      },
      {
        title: <Trans i18nKey="manage.serviceRequests.columns.account" />,
        key: 'account',
        render: (_: any, record: GraphqlServiceRequest) => (
          <Link to={ROUTES.MANAGE_CUSTOMER.replace(':customerId', String(record.Customer.id))}>
            {record.Customer.fullName}
          </Link>
        ),
      },
      {
        title: <Trans i18nKey="manage.serviceRequests.columns.agent" />,
        key: 'agentId',
        render: (_: any, record: GraphqlServiceRequest) => (
          <AgentLink agent={record?.Agent as GraphqlAgent} />
        ),
      },
      {
        title: <Trans i18nKey="manage.serviceRequests.columns.type" />,
        key: 'category',
        dataIndex: 'category',
        render: (_: any, record: GraphqlServiceRequest) =>
          i18n.t(`serviceTypes.${record.category}`) as string,
        sorter: true,
        filters: serviceRequestCategories?.listRequestCategories?.map((category) => ({
          text: category.name,
          value: category.id,
        })),
      },
      {
        title: <Trans i18nKey="manage.serviceRequests.columns.createdAt" />,
        key: 'createdAt',
        dataIndex: 'createdAt',
        render: (_: any, record: GraphqlServiceRequest) => formatISOtoMMDDYYYY(record.createdAt),
        sorter: true,
        // eslint-disable-next-line react/no-unstable-nested-components
        filterDropdown: (props) => (
          <TableDateFilter
            {...props}
            onRangeSelected={(from, until) =>
              setFilters((prevFilters) => ({
                ...prevFilters,
                requestedAtFrom: from,
                requestedAtUntil: until,
              }))
            }
          />
        ),
        filtered: Boolean(filters.requestedAtFrom || filters.requestedAtUntil),
      },
      {
        title: <Trans i18nKey="manage.serviceRequests.columns.status" />,
        key: 'status',
        dataIndex: 'status',
        render: (_: any, record: GraphqlServiceRequest) => (
          <ServiceRequestStatusTag status={record.status} />
        ),
        sorter: true,
        filters: Object.values(ServiceRequestStatus).map((serviceRequestStatus) => {
          return {
            text: <Trans i18nKey={`serviceRequestStatus.${serviceRequestStatus}`} />,
            value: serviceRequestStatus,
          };
        }),
      },
      {
        title: <Trans i18nKey="manage.serviceRequests.columns.actionNeeded" />,
        key: 'status',
        dataIndex: 'status',
        render: (_: any, record: GraphqlServiceRequest) => record.actionNeeded,
      },
      {
        title: <Trans i18nKey="manage.serviceRequests.columns.scheduledAt" />,
        key: 'scheduledAt',
        dataIndex: 'scheduledAt',
        render: (_: any, record: GraphqlServiceRequest) =>
          record.scheduledAt ? formatDateAndTimeInTimezone(record.scheduledAt) : '',
        sorter: true,
        // eslint-disable-next-line react/no-unstable-nested-components
        filterDropdown: (props) => (
          <TableDateFilter
            {...props}
            onRangeSelected={(from, until) =>
              setFilters((prevFilters) => ({
                ...prevFilters,
                scheduledAtFrom: from,
                scheduledAtUntil: until,
              }))
            }
          />
        ),
        filtered: Boolean(filters.scheduledAtFrom || filters.scheduledAtUntil),
      },
    ],
    [i18n, filters, serviceRequestCategories?.listRequestCategories, formatDateAndTimeInTimezone],
  );

  const onTableChange = (
    tablePagination: TablePaginationConfig,
    tableFilters: Record<string, FilterValue | null>,
    tableSorter: SorterResult<GraphqlServiceRequest> | SorterResult<GraphqlServiceRequest>[],
  ) => {
    const uniqueSorter = tableSorter as SorterResult<GraphqlServiceRequest>;

    // Pagination
    setPage(tablePagination.current as number);
    setPageSize(tablePagination.pageSize as number);

    // Order
    if (uniqueSorter.order) {
      const direction = uniqueSorter.order === 'ascend' ? SortOrder.Asc : SortOrder.Desc;
      setSortOptions({
        field: uniqueSorter.field as ServiceRequestsSortableFields,
        direction,
      });
    } else {
      setSortOptions(undefined);
    }

    // Filters
    const { status, category } = tableFilters;
    setFilters((prevFilters) => ({
      ...prevFilters,
      category: category as ServiceRequestCategoriesEnum[],
      status: status as ServiceRequestStatus[],
    }));
  };

  if (error) return <GenericApolloError error={error} />;

  return (
    <Card title={<Trans i18nKey="manage.serviceRequests.title" />} {...cardProps}>
      <Row style={{ marginBottom: '20px' }}>
        <Input
          placeholder={i18n.t('manage.serviceRequests.search') as string}
          value={searchServiceRequestsText}
          onChange={(e) => setSearchServiceRequestsText(e.target.value)}
        />
      </Row>
      <Table
        loading={loadingServiceRequests}
        columns={columns}
        dataSource={(serviceRequests || []).map((serviceRequest) => ({
          ...serviceRequest,
          key: serviceRequest.id,
        }))}
        style={{ overflowX: 'auto' }}
        onChange={onTableChange}
        pagination={{
          pageSize,
          showSizeChanger: showPageSizeChanger,
          defaultCurrent: 1,
          current: pagination?.currentPage,
          total: pagination?.totalCount,
        }}
      />
    </Card>
  );
}

AgentsServiceRequests.defaultProps = {
  cardProps: {},
  showPageSizeChanger: false,
  defaultPageSize: 100,
};

export default AgentsServiceRequests;
