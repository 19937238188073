import { PlusOutlined } from '@ant-design/icons';
import Button from '@components/Button';
import GenericApolloError from '@components/GenericApolloError';
import Table from '@components/Table/Table';
import { TableDateFilter } from '@components/Table/TableDateFilter';
import ROUTES from '@constants/routes';
import {
  ChatTypeOptions,
  Conversation,
  ConversationsSortableFields,
  GetConversationsFilter,
  GetConversationsSort,
  GlobalRole,
  InputMaybe,
  SortOrder,
  useGetConversationsQuery,
} from '@graphql/generated';
import { useCallbackWhenVisible } from '@hooks/useCallbackWhenVisible';
import { formatISOtoMMDDYYYY, formatISOtoMMDDYYYYandTime } from '@utils/date';
import { Row, Space, Spin, Typography } from 'antd';
import { ColumnsType, TablePaginationConfig } from 'antd/es/table';
import { FilterValue, SorterResult } from 'antd/es/table/interface';
import dayjs from 'dayjs';
import { Fragment, useEffect, useMemo, useState } from 'react';
import { Trans } from 'react-i18next';
import { Link } from 'react-router-dom';
import CreateCommunityChatModal from './components/CreateCommunityChatModal';

// Src: https://talkjs.com/docs/Reference/REST_API/Getting_Started/Introduction/#limits
const MAX_PAGE_SIZE = 20;

const ALL_CHAT_TYPES = Object.values(ChatTypeOptions);

function Community() {
  const [createChatModalOpen, setCreateChatModalOpen] = useState(false);
  const [fetchingMore, setFetchingMore] = useState(false);
  const [noMoreData, setNoMoreData] = useState(false);
  const [filters, setFilters] = useState<InputMaybe<GetConversationsFilter>>({
    custom: { chatTypes: ALL_CHAT_TYPES },
  });
  const [sortOptions, setSortOptions] = useState<InputMaybe<GetConversationsSort>>();
  const [conversations, setConversations] = useState<Conversation[]>([]);
  const {
    data: getConversationsQuery,
    error,
    loading,
    previousData,
    refetch,
    fetchMore,
  } = useGetConversationsQuery({
    variables: {
      pageSize: MAX_PAGE_SIZE,
      sort: sortOptions,
      filter: filters,
    },
  });

  const conversationsFromQuery = useMemo(
    () =>
      ((getConversationsQuery || previousData)?.getConversations.conversations as Conversation[]) ||
      [],
    [getConversationsQuery, previousData],
  );

  useEffect(() => {
    if (conversationsFromQuery) {
      // NOTE: if the query was re-executed we need to discard the previous data
      setConversations(conversationsFromQuery);
      setNoMoreData(conversationsFromQuery.length === 0);
    }
  }, [conversationsFromQuery]);

  const columns: ColumnsType<Conversation> = useMemo(
    () => [
      {
        title: <Trans i18nKey="manage.community.chatsTable.columns.chatName" />,
        dataIndex: 'subject',
        fixed: 'left',
        width: 300,
        ellipsis: true,
        render: (_: any, record: Conversation) => (
          <Link
            to={ROUTES.MANAGE_COMMUNITY_CONVERSATION_DETAIL.replace(':conversationId', record.id)}
          >
            {record.subject}
          </Link>
        ),
      },
      {
        title: <Trans i18nKey="manage.community.chatsTable.columns.chatType" />,
        dataIndex: 'chatType',
        filters: Object.values(ChatTypeOptions).map((chatType) => {
          return {
            text: <Trans i18nKey={`chatTypeOptions.${chatType}`} />,
            value: chatType,
          };
        }),
        render: (_: any, record: Conversation) =>
          record.custom.chatType ? (
            <Trans i18nKey={`chatTypeOptions.${record.custom.chatType}`} />
          ) : null,
      },
      {
        title: <Trans i18nKey="manage.community.chatsTable.columns.members" />,
        dataIndex: 'role',
        filters: [GlobalRole.FamilyPartner, GlobalRole.SeniorMember].map((role) => {
          return {
            text: <Trans i18nKey={`roles.plural.${role}`} />,
            value: role,
          };
        }),
        filterMultiple: false,
        render: (_: any, record: Conversation) =>
          record.custom.role ? <Trans i18nKey={`roles.plural.${record.custom.role}`} /> : null,
        width: 100,
        ellipsis: true,
      },
      {
        title: <Trans i18nKey="manage.community.chatsTable.columns.coaches" />,
        ellipsis: true,
        render: (_: any, record: Conversation) => {
          const coaches =
            record?.Participants?.filter((participant) => participant.role === GlobalRole.Coach) ||
            [];
          return coaches.map((coach, idx) => (
            <Fragment key={coach.id}>
              <Link key={coach.id} to={ROUTES.MANAGE_USER.replace(':userId', String(coach.id))}>
                {coach.fullName}
              </Link>
              {idx < coaches.length - 1 && ', '}
            </Fragment>
          ));
        },
      },
      {
        title: <Trans i18nKey="manage.community.chatsTable.columns.numberOfParticipants" />,
        render: (_: any, record: Conversation) => record.participants.length,
      },
      {
        title: <Trans i18nKey="manage.community.chatsTable.columns.city" />,
        dataIndex: ['custom', 'city'],
        width: 100,
        ellipsis: true,
      },
      {
        title: <Trans i18nKey="manage.community.chatsTable.columns.zipCode" />,
        dataIndex: ['custom', 'zipCode'],
        width: 100,
        ellipsis: true,
      },
      {
        title: <Trans i18nKey="manage.community.chatsTable.columns.createdBy" />,
        render: (_: any, record: Conversation) =>
          record.CreatedBy ? (
            <Link to={ROUTES.MANAGE_USER.replace(':userId', String(record.CreatedBy.id))}>
              {record.CreatedBy.fullName}
            </Link>
          ) : null,
        width: 200,
        ellipsis: true,
      },
      {
        title: <Trans i18nKey="manage.community.chatsTable.columns.dateCreated" />,
        dataIndex: 'createdAt',
        sorter: true,
        render: (_: any, record: Conversation) =>
          formatISOtoMMDDYYYY(dayjs(record.createdAt).toISOString()),
      },
      {
        title: <Trans i18nKey="manage.community.chatsTable.columns.lastActivity" />,
        dataIndex: 'lastActivity',
        sorter: true,
        render: (_: any, record: Conversation) =>
          record.lastMessage?.createdAt
            ? formatISOtoMMDDYYYYandTime(dayjs(record.lastMessage.createdAt).toISOString())
            : null,
        // eslint-disable-next-line react/no-unstable-nested-components
        filterDropdown: (props) => (
          <TableDateFilter
            {...props}
            onRangeSelected={(from, until) =>
              setFilters((prevFilters) => ({
                ...prevFilters,
                lastMessageAfter: from,
                lastMessageBefore: until,
              }))
            }
          />
        ),
        filtered: Boolean(filters?.lastMessageAfter || filters?.lastMessageBefore),
        width: 100,
        ellipsis: true,
      },
    ],
    [filters],
  );

  const ref = useCallbackWhenVisible(async () => {
    // We handle pagination as infinite scroll due to TalkJS uses cursor based pagination
    if (!loading && !fetchingMore && !noMoreData) {
      try {
        setFetchingMore(true);
        const { data } = await fetchMore({
          variables: {
            startingAfter: conversations[conversations.length - 1].id,
          },
        });
        setConversations((prevConversations) => [
          ...prevConversations,
          ...data.getConversations.conversations,
        ]);

        if (data.getConversations.conversations.length < MAX_PAGE_SIZE) {
          setNoMoreData(true);
        }
      } finally {
        setFetchingMore(false);
      }
    }
  });

  const onTableChange = (
    tablePagination: TablePaginationConfig,
    tableFilters: Record<string, FilterValue | null>,
    tableSorter: SorterResult<Conversation> | SorterResult<Conversation>[],
  ) => {
    // Order
    const uniqueSorter = tableSorter as SorterResult<Conversation>;
    if (uniqueSorter.order) {
      const direction = uniqueSorter.order === 'ascend' ? SortOrder.Asc : SortOrder.Desc;
      setSortOptions({
        field: uniqueSorter.field as ConversationsSortableFields,
        direction: direction.toUpperCase(),
      });
    } else {
      setSortOptions(undefined);
    }

    // Filters
    const { chatType, role } = tableFilters;
    setFilters((prevFilters) => ({
      ...prevFilters,
      custom: {
        ...prevFilters?.custom,
        chatTypes: chatType?.length
          ? (chatType as ChatTypeOptions[])
          : prevFilters?.custom?.chatTypes,
        role: role ? (role[0] as GlobalRole) : undefined,
      },
    }));
  };

  if (error) return <GenericApolloError error={error} />;

  return (
    <>
      <Space direction="vertical" size="large" style={{ width: '100%' }}>
        <Typography.Title
          level={2}
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
          }}
        >
          <Trans i18nKey="manage.community.title" />
          <Space size="small">
            <Button
              type="primary"
              onClick={() => setCreateChatModalOpen(true)}
              style={{ width: 'auto' }}
            >
              <PlusOutlined />
              &nbsp;
              <Trans i18nKey="manage.community.createChat" />
            </Button>
          </Space>
        </Typography.Title>
        <Table
          scroll={{ x: 'auto' }}
          loading={loading}
          columns={columns}
          dataSource={conversations.map((conversation) => ({
            ...conversation,
            key: conversation.id,
          }))}
          onChange={onTableChange}
          pagination={false}
        />
        <div ref={ref} />
        {fetchingMore ? (
          <Row style={{ width: '100%' }} justify="center">
            <Spin />
          </Row>
        ) : null}
      </Space>
      <CreateCommunityChatModal
        open={createChatModalOpen}
        onCancel={() => setCreateChatModalOpen(false)}
        onSuccessfulCreateChat={() => {
          refetch();
          setCreateChatModalOpen(false);
        }}
      />
    </>
  );
}

export default Community;
