import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { ServerError } from '@apollo/client';
import { Link, useLoaderData } from 'react-router-dom';
import { LeftOutlined } from '@ant-design/icons';
import { useCallback, useState } from 'react';
import { HttpStatusCode } from 'axios';

import useAuth, { useIsWebview } from '@hooks/useAuth';
import { Form, FormItem } from '@components/Form';
import Input, { InputNewPassword } from '@components/Input';
import Button from '@components/Button';
import AuthLayout from '@components/AuthLayout';
import { ResetPasswordLoaderData } from '@providers/routerProvider';
import ROUTES from '@constants/routes';
import { useResetPasswordMutation } from '@graphql/generated';
import { Typography } from 'antd';

const LinkContainer = styled.div`
  margin-top: 24px;
  a {
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    color: #9e9e9e;
  }

  svg {
    margin-right: 6px;
  }
`;

const Subtitle = styled.div`
  color: ${({ theme }) => theme.colors.foregroundTertiary};
`;

const NeedHelp = styled.div`
  font-weight: ${({ theme }) => theme.fontWeights.bold};
`;

export interface ResetPasswordFormValues {
  password: string;
  confirmPassword: string;
}

function ResetPassword() {
  const i18n = useTranslation();
  const isWebview = useIsWebview();
  const LOGIN_ROUTE = isWebview ? ROUTES.LOG_IN : ROUTES.MANAGE_LOG_IN;
  const FORGOT_PASSWORD_ROUTE = isWebview ? ROUTES.FORGOT_PASSWORD : ROUTES.MANAGE_FORGOT_PASSWORD;
  const { resetPasswordToken } = useLoaderData() as ResetPasswordLoaderData;
  const [resetPassword, { loading, error, called }] = useResetPasswordMutation();
  const [form] = Form.useForm();
  const [formHasError, setFormHasError] = useState<boolean>(true);
  const { setAccessToken } = useAuth();

  const onFinish = useCallback(
    async (values: ResetPasswordFormValues) => {
      const { password } = values;
      try {
        const { data } = await resetPassword({ variables: { resetPasswordToken, password } });
        if (data) {
          if (!isWebview) {
            // We only automatically login the user again if it's a management user,
            // normal users need to click the button to go back to the Brya App
            setAccessToken(data.resetPassword.accessToken);
          }
        }
      } catch (e) {
        // noop
      }
    },
    [resetPassword, resetPasswordToken, setAccessToken, isWebview],
  );

  const onFieldsChange = () => {
    const hasErrors = form.getFieldsError().some(({ errors }) => errors.length);
    setFormHasError(hasErrors);
  };

  if (error && (error.networkError as ServerError)?.statusCode === HttpStatusCode.Unauthorized) {
    return (
      <AuthLayout
        bryaHeaderProps={{
          title: 'resetPassword.invalidToken',
          subtitle: 'resetPassword.invalidTokenDescription',
          subtitleProps: {
            components: {
              linkReference: <Link to={FORGOT_PASSWORD_ROUTE} style={{ fontSize: '14px' }} />,
            },
          },
          hideSSOLogin: true,
        }}
      />
    );
  }

  if (called && isWebview) {
    return (
      <AuthLayout
        bryaHeaderProps={{
          hideSSOLogin: true,
        }}
      >
        <div style={{ textAlign: 'left' }}>
          <Typography.Title>{i18n.t('resetPassword.passwordUpdated')}</Typography.Title>
          <Subtitle>{i18n.t('resetPassword.description')}</Subtitle>
          <NeedHelp>{i18n.t('signUpVerifyEmail.help.needHelp')}</NeedHelp>
          <div>{i18n.t('signUpVerifyEmail.help.contact')}</div>
          <a href="mailto:support@brya.com">support@brya.com</a>
        </div>
      </AuthLayout>
    );
  }

  return (
    <AuthLayout
      bryaHeaderProps={{
        hideSSOLogin: true,
        title: 'resetPassword.title',
      }}
    >
      <Form
        name="resetPassword"
        autoComplete="off"
        onFinish={onFinish}
        form={form}
        onFieldsChange={onFieldsChange}
        style={{ marginTop: '25px' }}
      >
        {/* NOTE: hidden field for accessibility, resolves browser warning */}
        <FormItem name="username" hidden>
          <Input name="username" autoComplete="username" />
        </FormItem>

        <InputNewPassword
          passwordLabel={i18n.t('forms.input.newPassword.placeholder') as string}
          placeholder={i18n.t('forms.input.newPassword.placeholder') as string}
          withConfirmPassword
          confirmPasswordPlaceholder={
            i18n.t('forms.input.confirmNewPassword.placeholder') as string
          }
        />

        <FormItem>
          <Button loading={loading} htmlType="submit" disabled={formHasError || loading}>
            {i18n.t('resetPassword.cta.submit')}
          </Button>
        </FormItem>
        <FormItem>
          <LinkContainer>
            <Link to={LOGIN_ROUTE}>
              <LeftOutlined />
              {i18n.t('resetPassword.cta.cancel')}
            </Link>
          </LinkContainer>
        </FormItem>
      </Form>
    </AuthLayout>
  );
}

export default ResetPassword;
