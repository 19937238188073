import { useCallback, useMemo } from 'react';
import { useCookies } from 'react-cookie';
import { useLocation, useNavigate } from 'react-router-dom';

import ROUTES from '@constants/routes';
import { parseJWT } from '@utils/parseJWT';
import { COOKIES_KEYS } from '@constants/cookies';
import {
  GlobalRole,
  GraphqlUser,
  ManagementRole,
  useRefreshAccessTokenMutation,
} from '@graphql/generated';
import { FEATURE_FLAGS } from '@constants/featureFlags';
import { useSetUser } from './appContext/useSetUser';
import { useIsFeatureEnabled } from './useIsFeatureEnabled';

export interface AuthUser {
  id: number;
  email?: string | null;
  isEmailVerified?: boolean | null;
  role?: GlobalRole | null;
  customerId?: number | null;
  firstName?: string | null;
  lastName?: string | null;
  language?: string | null;
  talkJsId?: string | null;
}

export const useIsWebview = () => {
  const location = useLocation();
  const path = location.pathname;
  return useMemo(() => !path.includes(ROUTES.MANAGE), [path]);
};

export const useLogout = () => {
  const isWebview = useIsWebview();
  const [, , removeCookie] = useCookies([COOKIES_KEYS.ACCESS_TOKEN]);
  const setUser = useSetUser();
  const navigate = useNavigate();

  return useCallback(() => {
    removeCookie(COOKIES_KEYS.ACCESS_TOKEN, { path: '/' });
    setUser(null);

    // NOTE: probably the logout will not be called in webview mode
    navigate(isWebview ? ROUTES.LOG_IN : ROUTES.MANAGE_LOG_IN);
  }, [isWebview, navigate, setUser, removeCookie]);
};

export const useAuth = () => {
  const [, setCookie, removeCookie] = useCookies([COOKIES_KEYS.ACCESS_TOKEN]);
  const setUser = useSetUser();
  const navigate = useNavigate();
  const authId = localStorage.getItem('authId');
  const verifyEmailEnabled = useIsFeatureEnabled(FEATURE_FLAGS.CREATE_AGENT_VERIFY_EMAIL);

  const setAccessToken = (accessToken: string) => {
    const user = parseJWT(accessToken) as AuthUser;
    setUser(user as GraphqlUser);

    let route = ROUTES.LANDING;

    if (Object.values(ManagementRole).includes(user.role as unknown as ManagementRole)) {
      route = ROUTES.MANAGE;
    } else if (user.role === GlobalRole.AdminAgent) {
      if (!authId) {
        route = ROUTES.PROS_STRIPE_ACCOUNT;
      }

      if (verifyEmailEnabled && !user.isEmailVerified && !authId) {
        route = ROUTES.SIGN_UP_VERIFY_EMAIL;
      }
    }

    const navigateTo = route;

    setCookie(COOKIES_KEYS.ACCESS_TOKEN, accessToken, { path: '/' });
    navigate(navigateTo);
  };

  const unsetAccessToken = () => {
    removeCookie(COOKIES_KEYS.ACCESS_TOKEN, { path: '/' });
    navigate(ROUTES.LOG_IN);
  };

  return {
    setAccessToken,
    unsetAccessToken,
  };
};

export const useRefreshAuth = () => {
  const [refreshAccessToken] = useRefreshAccessTokenMutation();
  const { setAccessToken } = useAuth();

  return useCallback(async () => {
    const { data } = await refreshAccessToken();

    if (data?.refreshAccessToken?.accessToken) {
      setAccessToken(data.refreshAccessToken.accessToken);
    }
  }, [refreshAccessToken, setAccessToken]);
};

export default useAuth;
