// base
import ReactDOM from 'react-dom';
import { getLocale, i18nInitialize } from '@locales/i18n';
import { CookiesProvider } from 'react-cookie';

// Providers
import ApolloProvider from '@providers/apolloProvider';
import AppContextProvider from '@providers/appContextProvider';
import ThemeProvider from '@providers/themeProvider';
import MessageProvider from '@providers/messageProvider';
import RouterProvider from '@providers/routerProvider';

// Constants
import './index.css';

// Dayjs
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import minMax from 'dayjs/plugin/minMax';

dayjs.extend(relativeTime);
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(advancedFormat);
dayjs.extend(minMax);

i18nInitialize();

function App() {
  const locale = getLocale();

  return (
    <CookiesProvider>
      <ApolloProvider>
        <AppContextProvider locale={locale}>
          <ThemeProvider>
            <MessageProvider>
              <RouterProvider />
            </MessageProvider>
          </ThemeProvider>
        </AppContextProvider>
      </ApolloProvider>
    </CookiesProvider>
  );
}

// app
ReactDOM.render(<App />, document.getElementById('root'));
