import Button from '@components/Button';
import { Form, FormItem } from '@components/Form';
import { InputTextarea } from '@components/Input';
import { CreateInternalNoteInput, GraphqlInternalNote } from '@graphql/generated';
import { ReactNode, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

interface InternalNoteProps {
  loading: boolean;
  initialValues: GraphqlInternalNote | undefined;
  submitLabel: ReactNode;
  onSubmit: (values: CreateInternalNoteInput) => Promise<void>;
}
export function InternalNoteForm({
  loading,
  initialValues,
  submitLabel,
  onSubmit,
}: InternalNoteProps) {
  const [form] = Form.useForm();
  const i18n = useTranslation();

  useEffect(() => {
    form.resetFields();
  }, [form, initialValues]);

  return (
    <Form
      name="internal-note-form"
      autoComplete="off"
      form={form}
      onFinish={async (values) => {
        await onSubmit(values);
        form.resetFields();
      }}
      initialValues={initialValues}
    >
      <FormItem
        name="note"
        rules={[{ required: true, message: i18n.t('forms.input.note.errorMessage') as string }]}
      >
        <InputTextarea
          name="note"
          rows={6}
          placeholder={i18n.t('forms.input.note.placeholder') as string}
        />
      </FormItem>
      <FormItem style={{ marginTop: '30px' }}>
        <Button loading={loading} htmlType="submit">
          {submitLabel}
        </Button>
      </FormItem>
    </Form>
  );
}
