import { PlusOutlined } from '@ant-design/icons';
import Button, { ButtonLink } from '@components/Button';
import { Card } from '@components/Card';
import Modal from '@components/Modal';
import Table from '@components/Table/Table';
import ROUTES from '@constants/routes';
import {
  ChatTypeOptions,
  GraphqlUser as FullGraphqlUser,
  GlobalRole,
  InputMaybe,
  useJoinUserToConversationMutation,
  useRemoveParticipantMutation,
} from '@graphql/generated';
import { CardProps, Row, Space, Typography, message } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { useMemo, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { GET_CONVERSATION, GET_CONVERSATIONS } from '@graphql/chat';
import { AddParticipantToConversationForm } from './AddParticipantToConversationForm';

type RemoveParticipantModal = {
  open: boolean;
  title?: string;
  children?: string;
  confirmText?: string;
  cancelText?: string;
  onConfirm?: () => void;
};

type GraphqlUser = Partial<FullGraphqlUser>;

interface ConversationParticipantsProps {
  conversationId: string;
  participants: GraphqlUser[];
  loading: boolean;
  chatType: InputMaybe<ChatTypeOptions> | undefined;
  chatAllowedRole: InputMaybe<GlobalRole> | undefined;
  cardProps?: CardProps;
}

export function ConversationParticipants({
  conversationId,
  participants,
  loading,
  chatType,
  chatAllowedRole,
  cardProps,
}: ConversationParticipantsProps) {
  const i18n = useTranslation();
  const [removeParticipantModal, setRemoveParticipantModal] = useState<RemoveParticipantModal>({
    open: false,
  });
  const [addParticipantModalIsOpen, setAddParticipantModalIsOpen] = useState(false);
  const [joinUserToConversation, { loading: joiningUser }] = useJoinUserToConversationMutation({
    refetchQueries: [GET_CONVERSATION, GET_CONVERSATIONS],
  });

  const [removeParticipant, { loading: removingParticipant }] = useRemoveParticipantMutation({
    refetchQueries: [GET_CONVERSATION, GET_CONVERSATIONS],
  });

  const allowedRoles = useMemo(() => {
    return [
      GlobalRole.Coach,
      chatAllowedRole ||
        (chatType === ChatTypeOptions.Social ? GlobalRole.SeniorMember : GlobalRole.FamilyPartner),
    ];
  }, [chatAllowedRole, chatType]);

  const columns: ColumnsType<GraphqlUser> = useMemo(
    () => [
      {
        title: <Trans i18nKey="manage.conversationDetail.participants.columns.user" />,
        render: (_: any, record: GraphqlUser) => (
          <Link to={ROUTES.MANAGE_USER.replace(':userId', String(record.id))}>
            {record.fullName}
          </Link>
        ),
      },
      {
        title: <Trans i18nKey="manage.conversationDetail.participants.columns.role" />,
        render: (_: any, record: GraphqlUser) => <Trans i18nKey={`roles.${record.role}`} />,
      },
      {
        title: <Trans i18nKey="manage.conversationDetail.participants.columns.email" />,
        dataIndex: 'email',
      },
      {
        title: <Trans i18nKey="manage.conversationDetail.participants.columns.actions" />,
        render: (_: any, record: GraphqlUser) => (
          <ButtonLink
            onClick={() => {
              setRemoveParticipantModal({
                open: true,
                title: i18n.t(
                  'manage.conversationDetail.participants.removeParticipantModal.title',
                  { fullName: record.fullName },
                ) as string,
                children: i18n.t(
                  'manage.conversationDetail.participants.removeParticipantModal.description',
                  { fullName: record.fullName },
                ) as string,
                confirmText: i18n.t(
                  'manage.conversationDetail.participants.removeParticipantModal.confirm',
                ) as string,
                cancelText: i18n.t(
                  'manage.conversationDetail.participants.removeParticipantModal.cancel',
                ) as string,
                onConfirm: async () => {
                  setRemoveParticipantModal({ open: false });
                  await removeParticipant({
                    variables: {
                      conversationId,
                      talkJsId: String(record.talkJsId),
                    },
                  });
                  message.info(
                    <Trans
                      i18nKey="manage.conversationDetail.participants.removeParticipantModal.successMessage"
                      values={{ fullName: record.fullName }}
                    />,
                  );
                },
              });
            }}
          >
            <Trans i18nKey="manage.conversationDetail.participants.actions.remove" />
          </ButtonLink>
        ),
      },
    ],
    [i18n, conversationId, removeParticipant],
  );
  return (
    <Card
      title={
        <Row justify="space-between" align="middle" style={{ padding: '20px 0' }}>
          <Typography.Title level={4} style={{ margin: 0 }}>
            <Trans i18nKey="manage.conversationDetail.participants.title" />
          </Typography.Title>
          <div>
            <Button type="primary" onClick={() => setAddParticipantModalIsOpen(true)}>
              <Space size="small">
                <PlusOutlined />{' '}
                <Trans i18nKey="manage.conversationDetail.participants.actions.add" />
              </Space>
            </Button>
          </div>
        </Row>
      }
      {...cardProps}
    >
      <Table
        loading={loading || removingParticipant}
        columns={columns}
        dataSource={participants.map((p) => ({ ...p, key: p.id }))}
        pagination={false}
      />
      <Modal
        width={400}
        onCancel={() => setRemoveParticipantModal({ open: false })}
        confirmLoading={removingParticipant}
        {...removeParticipantModal}
      />
      <Modal
        width={400}
        open={addParticipantModalIsOpen}
        title={<Trans i18nKey="manage.conversationDetail.participants.addParticipantModal.title" />}
        cancelText={
          i18n.t('manage.conversationDetail.participants.addParticipantModal.cancel') as string
        }
        onCancel={() => setAddParticipantModalIsOpen(false)}
      >
        <AddParticipantToConversationForm
          allowedRoles={allowedRoles}
          loading={joiningUser}
          currentParticipants={participants}
          onSubmit={async (values) => {
            try {
              await joinUserToConversation({
                variables: {
                  conversationId,
                  userId: values.chatParticipant.id,
                },
              });
              setAddParticipantModalIsOpen(false);
              message.success(
                i18n.t('forms.addParticipantToConversation.successMessage' as string, {
                  fullName: values.chatParticipant.fullName,
                }),
              );
              return await Promise.resolve();
            } catch (err) {
              return Promise.reject(err);
            }
          }}
          submitLabel={
            i18n.t('manage.conversationDetail.participants.addParticipantModal.confirm') as string
          }
        />
      </Modal>
    </Card>
  );
}

ConversationParticipants.defaultProps = {
  cardProps: {},
};
