import { useMemo, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import {
  GraphqlSocialEvent,
  InputMaybe,
  PaginationMeta,
  SocialEventStatus,
  SocialEventFilter,
  SocialEventSortInput,
  SocialEventSortableFields,
  SortOrder,
  ServiceRequestStatus,
  useGetSocialEventsQuery,
} from '@graphql/generated';
import { FilterValue, SorterResult } from 'antd/es/table/interface';
import { ColumnsType, TablePaginationConfig } from 'antd/es/table';
import GenericApolloError from '@components/GenericApolloError';
import { ApolloError } from '@apollo/client/errors';

import ROUTES from '@constants/routes';
import { ID_MIN_LENGTH } from '@constants/output';

import { Space, Typography } from 'antd';
import Input from '@components/Input';
import Button from '@components/Button';
import Table from '@components/Table/Table';
import { TableDateFilter } from '@components/Table/TableDateFilter';
import CreateSocialEventModal from '@components/Activities/CreateSocialEventModal';
import { useDateWithTimezone } from '@hooks/useDateWithTimezone';

function SocialEvents() {
  const i18n = useTranslation();
  const { formatDateAndTimeInTimezone } = useDateWithTimezone();
  const [filters, setFilters] = useState<SocialEventFilter>({});
  const [sortOptions, setSortOptions] = useState<InputMaybe<SocialEventSortInput>>({
    field: SocialEventSortableFields.Id,
    direction: SortOrder.Desc,
  });
  const [pageSize, setPageSize] = useState(100);
  const [page, setPage] = useState(1);
  const [showCreateSocialEventModal, setShowCreateSocialEventModal] = useState<boolean>(false);
  const {
    data: socialEventsData,
    error: socialEventsError,
    loading: socialEventsLoading,
    previousData: socialEventsPreviousData,
  } = useGetSocialEventsQuery({
    variables: {
      filter: filters,
      sort: sortOptions as SocialEventSortInput,
      page,
      pageSize,
    },
  });

  const socialEvents = useMemo(
    () =>
      ((socialEventsData || socialEventsPreviousData)?.getSocialEvents
        ?.socialEvents as unknown as GraphqlSocialEvent[]) || [],
    [socialEventsData, socialEventsPreviousData],
  );

  const pagination = (socialEventsData || socialEventsPreviousData)?.getSocialEvents
    .meta as PaginationMeta;

  const columns: ColumnsType<GraphqlSocialEvent> = useMemo(
    () => [
      {
        title: <Trans i18nKey="manage.socialEvents.columns.id" />,
        dataIndex: 'id',
        key: 'id',
        render: (_: any, record: GraphqlSocialEvent) => (
          <Link to={ROUTES.MANAGE_SOCIAL_EVENT.replace(':activityId', String(record.id))}>
            {String(record.id).padStart(ID_MIN_LENGTH, '0')}
          </Link>
        ),
        sorter: true,
        filteredValue: null,
      },
      {
        title: <Trans i18nKey="manage.socialEvents.columns.eventName" />,
        render: (_: any, record: GraphqlSocialEvent) =>
          (record as unknown as GraphqlSocialEvent)?.eventName,
        // eslint-disable-next-line react/no-unstable-nested-components
        filterDropdown: (props: any) => (
          <Space>
            <Input
              {...props}
              placeholder={i18n.t('manage.socialEvents.search.eventName')}
              onChange={(e) =>
                setFilters((prevFilters) => ({
                  ...prevFilters,
                  eventName: e?.target?.value,
                }))
              }
            />
          </Space>
        ),
        filtered: Boolean(filters.eventName),
        filteredValue: null,
      },
      {
        title: <Trans i18nKey="manage.socialEvents.columns.coaches" />,
        render: (_: any, record: GraphqlSocialEvent) => (
          <div>
            {record?.Coaches?.map((coach, i) => (
              <Link
                to={ROUTES.MANAGE_USER.replace(':userId', String(coach?.Coach?.id))}
                key={coach?.Coach?.id}
              >
                {coach?.Coach?.fullName}
                {i === (record?.Coaches?.length || 0) - 1 ? '' : ', '}
              </Link>
            ))}
          </div>
        ),
        filteredValue: null,
      },
      {
        title: <Trans i18nKey="manage.socialEvents.columns.yeses" />,
        render: (_: any, record: GraphqlSocialEvent) =>
          record?.Participants?.filter(
            (participant) => participant.status === ServiceRequestStatus.Confirmed,
          )?.length,
        filteredValue: null,
      },
      {
        title: <Trans i18nKey="manage.socialEvents.columns.nos" />,
        render: (_: any, record: GraphqlSocialEvent) =>
          record?.Participants?.filter(
            (participant) => participant.status === ServiceRequestStatus.Cancelled,
          )?.length,
        filteredValue: null,
      },
      {
        title: <Trans i18nKey="manage.socialEvents.columns.pending" />,
        render: (_: any, record: GraphqlSocialEvent) =>
          record?.Participants?.filter(
            (participant) => participant.status === ServiceRequestStatus.ConfirmationNeeded,
          )?.length,
        filteredValue: null,
      },
      {
        title: <Trans i18nKey="manage.socialEvents.columns.scheduledAt" />,
        key: 'scheduledAt',
        dataIndex: 'scheduledAt',
        render: (_: any, record: GraphqlSocialEvent) =>
          record.scheduledAt ? formatDateAndTimeInTimezone(record.scheduledAt) : '',
        sorter: true,
        // eslint-disable-next-line react/no-unstable-nested-components
        filterDropdown: (props: any) => (
          <TableDateFilter
            {...props}
            onRangeSelected={(from, until) =>
              setFilters((prevFilters) => ({
                ...prevFilters,
                scheduledAtFrom: from,
                scheduledAtUntil: until,
              }))
            }
          />
        ),
        filtered: Boolean(filters.scheduledAtFrom || filters.scheduledAtUntil),
        filteredValue: null,
      },
      {
        title: <Trans i18nKey="manage.socialEvents.columns.city" />,
        render: (_: any, record: GraphqlSocialEvent) => record?.city,
        // eslint-disable-next-line react/no-unstable-nested-components
        filterDropdown: (props: any) => (
          <Space>
            <Input
              {...props}
              placeholder={i18n.t('manage.socialEvents.search.city')}
              onChange={(e) =>
                setFilters((prevFilters) => ({
                  ...prevFilters,
                  city: e?.target?.value,
                }))
              }
            />
          </Space>
        ),
        filtered: Boolean(filters.city),
        filteredValue: null,
      },
      {
        title: <Trans i18nKey="manage.socialEvents.columns.zipCode" />,
        render: (_: any, record: GraphqlSocialEvent) => record?.zipCode,
        // eslint-disable-next-line react/no-unstable-nested-components
        filterDropdown: (props: any) => (
          <Space>
            <Input
              {...props}
              placeholder={i18n.t('manage.socialEvents.search.zipCode')}
              onChange={(e) =>
                setFilters((prevFilters) => ({
                  ...prevFilters,
                  zipCode: e?.target?.value,
                }))
              }
            />
          </Space>
        ),
        filtered: Boolean(filters.zipCode),
        filteredValue: null,
      },
      {
        title: <Trans i18nKey="manage.socialEvents.columns.location" />,
        render: (_: any, record: GraphqlSocialEvent) => (
          <div>
            {record?.place && <Typography.Text strong>{record?.place}</Typography.Text>}
            <div>{record?.addressLine1}</div>
            {record?.addressLine2 && <div>{record?.addressLine2}</div>}
          </div>
        ),
        filteredValue: null,
      },
    ],
    [i18n, filters, formatDateAndTimeInTimezone],
  );

  const onTableChange = (
    tablePagination: TablePaginationConfig,
    tableFilters: Record<string, FilterValue | null>,
    tableSorter: SorterResult<GraphqlSocialEvent> | SorterResult<GraphqlSocialEvent>[],
  ) => {
    const uniqueSorter = tableSorter as SorterResult<GraphqlSocialEvent>;

    // Pagination
    setPage(tablePagination.current as number);
    setPageSize(tablePagination.pageSize as number);

    // Order
    if (uniqueSorter.order) {
      const direction = uniqueSorter.order === 'ascend' ? SortOrder.Asc : SortOrder.Desc;
      setSortOptions({
        field: uniqueSorter.field as SocialEventSortableFields,
        direction,
      });
    } else {
      setSortOptions({
        field: SocialEventSortableFields.Id,
        direction: SortOrder.Desc,
      });
    }

    // Filters
    const { status } = tableFilters;
    setFilters((prevFilters) => ({
      ...prevFilters,
      status: status as SocialEventStatus[],
    }));
  };

  if (socialEventsError) return <GenericApolloError error={socialEventsError as ApolloError} />;

  return (
    <Space direction="vertical" size="large" style={{ width: '100%' }}>
      <Typography.Title
        level={2}
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
        }}
      >
        <Trans i18nKey="manage.socialEvents.title" />
        <Space>
          <Button onClick={() => setShowCreateSocialEventModal(true)}>
            <Trans i18nKey="manage.socialEvents.new" />
          </Button>
        </Space>
      </Typography.Title>
      <Table
        loading={socialEventsLoading}
        columns={columns}
        dataSource={(socialEvents || []).map((activity) => ({
          ...activity,
          key: activity.id,
        }))}
        onChange={onTableChange}
        pagination={{
          pageSize,
          showSizeChanger: true,
          defaultCurrent: 1,
          current: pagination?.currentPage,
          total: pagination?.totalCount,
        }}
      />
      {showCreateSocialEventModal && (
        <CreateSocialEventModal onCancel={() => setShowCreateSocialEventModal(false)} />
      )}
    </Space>
  );
}

export default SocialEvents;
