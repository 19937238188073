import { GlobalRole, ManagementRole } from '@graphql/generated';
import { useUser } from '@hooks/appContext/useUser';

type ProtectedComponentProps = {
  allowedRoles?: (GlobalRole | ManagementRole | undefined | null)[];
  children: JSX.Element;
  fallback: JSX.Element | null;
};

export function ProtectedComponent({
  allowedRoles = [],
  children,
  fallback,
}: ProtectedComponentProps) {
  const user = useUser();
  const hasPermision = user && (allowedRoles.includes(user.role) || allowedRoles.length === 0);

  return hasPermision ? children : fallback;
}

ProtectedComponent.defaultProps = {
  allowedRoles: [],
  fallback: null,
};
