import { useMemo, useState } from 'react';
import { Trans } from 'react-i18next';
import { Link } from 'react-router-dom';

import {
  GraphqlCoachVisit,
  InputMaybe,
  PaginationMeta,
  CoachVisitFilter,
  CoachVisitSortInput,
  CoachVisitsSortableFields,
  ServiceRequestStatus,
  SocialEventStatus,
  SortOrder,
  useGetCoachVisitsQuery,
} from '@graphql/generated';
import { FilterValue, SorterResult } from 'antd/es/table/interface';
import { ColumnsType, TablePaginationConfig } from 'antd/es/table';
import GenericApolloError from '@components/GenericApolloError';
import { ApolloError } from '@apollo/client/errors';

import ROUTES from '@constants/routes';
import { ID_MIN_LENGTH } from '@constants/output';

import { Space, Typography } from 'antd';
import Button from '@components/Button';
import Table from '@components/Table/Table';
import { TableDateFilter } from '@components/Table/TableDateFilter';
import CreateCoachVisitModal from '@components/Activities/CreateCoachVisitModal';
import { ServiceRequestStatusTag } from '@components/Activities/ServiceRequestStatusTag';
import { useDateWithTimezone } from '@hooks/useDateWithTimezone';

function CoachVisits() {
  const [filters, setFilters] = useState<CoachVisitFilter>({});
  const [sortOptions, setSortOptions] = useState<InputMaybe<CoachVisitSortInput>>({
    field: CoachVisitsSortableFields.Id,
    direction: SortOrder.Desc,
  });
  const [pageSize, setPageSize] = useState(100);
  const [page, setPage] = useState(1);
  const [showCreateCoachVisitModal, setShowCreateCoachVisitModal] = useState<boolean>(false);
  const { formatDateAndTimeInTimezone } = useDateWithTimezone();
  const {
    data: coachVisitsData,
    error: coachVisitsError,
    loading: coachVisitsLoading,
    previousData: coachVisitsPreviousData,
  } = useGetCoachVisitsQuery({
    variables: {
      filter: filters as CoachVisitFilter,
      sort: sortOptions as CoachVisitSortInput,
      page,
      pageSize,
    },
  });

  const coachVisits = useMemo(
    () =>
      ((coachVisitsData || coachVisitsPreviousData)?.getCoachVisits
        ?.coachVisits as unknown as GraphqlCoachVisit[]) || [],
    [coachVisitsData, coachVisitsPreviousData],
  );

  const pagination = (coachVisitsData || coachVisitsPreviousData)?.getCoachVisits
    .meta as PaginationMeta;

  const columns: ColumnsType<GraphqlCoachVisit> = useMemo(
    () => [
      {
        title: <Trans i18nKey="manage.coachVisits.columns.id" />,
        dataIndex: 'id',
        key: 'id',
        render: (_: any, record: GraphqlCoachVisit) => (
          <Link to={ROUTES.MANAGE_COACH_VISIT.replace(':activityId', String(record.id))}>
            {String(record.id).padStart(ID_MIN_LENGTH, '0')}
          </Link>
        ),
        sorter: true,
        filteredValue: null,
      },
      {
        title: <Trans i18nKey="manage.coachVisits.columns.account" />,
        dataIndex: 'requestedBy',
        key: 'requestedBy',
        render: (_: any, record: GraphqlCoachVisit) => (
          <Link to={ROUTES.MANAGE_CUSTOMER.replace(':customerId', String(record?.Customer?.id))}>
            {record?.Customer?.fullName}
          </Link>
        ),
        sorter: true,
        filteredValue: null,
      },
      {
        title: <Trans i18nKey="manage.coachVisits.columns.coach" />,
        dataIndex: 'coachId',
        key: 'coachId',
        render: (_: any, record: GraphqlCoachVisit) => (
          <Link to={ROUTES.MANAGE_USER.replace(':userId', String(record?.Coach?.id))}>
            {record?.Coach?.fullName}
          </Link>
        ),
        sorter: false,
        filteredValue: null,
      },
      {
        title: <Trans i18nKey="manage.coachVisits.columns.status" />,
        key: 'status',
        dataIndex: 'status',
        render: (_: any, record: GraphqlCoachVisit) => (
          <ServiceRequestStatusTag status={record.status as unknown as ServiceRequestStatus} />
        ),
        sorter: true,
        filters: Object.values(SocialEventStatus).map((socialEventStatus) => {
          return {
            text: <Trans i18nKey={`serviceRequestStatus.${socialEventStatus}`} />,
            value: socialEventStatus,
          };
        }),
        filtered: Boolean(Number(filters.status?.length) > 0),
        filteredValue: filters.status,
      },
      {
        title: <Trans i18nKey="manage.coachVisits.columns.scheduledAt" />,
        key: 'scheduledAtFrom',
        dataIndex: 'scheduledAt',
        render: (_: any, record: GraphqlCoachVisit) =>
          record.scheduledAt ? formatDateAndTimeInTimezone(record.scheduledAt) : '',
        sorter: true,
        // eslint-disable-next-line react/no-unstable-nested-components
        filterDropdown: (props: any) => (
          <TableDateFilter
            {...props}
            onRangeSelected={(from, until) =>
              setFilters((prevFilters) => ({
                ...prevFilters,
                scheduledAtFrom: from,
                scheduledAtUntil: until,
              }))
            }
          />
        ),
        filtered: Boolean(filters.scheduledAtFrom || filters.scheduledAtUntil),
        filteredValue: null,
      },
    ],
    [filters, formatDateAndTimeInTimezone],
  );

  const onTableChange = (
    tablePagination: TablePaginationConfig,
    tableFilters: Record<string, FilterValue | null>,
    tableSorter: SorterResult<GraphqlCoachVisit> | SorterResult<GraphqlCoachVisit>[],
  ) => {
    const uniqueSorter = tableSorter as SorterResult<GraphqlCoachVisit>;

    // Pagination
    setPage(tablePagination.current as number);
    setPageSize(tablePagination.pageSize as number);

    // Order
    if (uniqueSorter.order) {
      const direction = uniqueSorter.order === 'ascend' ? SortOrder.Asc : SortOrder.Desc;
      setSortOptions({
        field: uniqueSorter.field as CoachVisitsSortableFields,
        direction,
      });
    } else {
      setSortOptions({
        field: CoachVisitsSortableFields.Id,
        direction: SortOrder.Desc,
      });
    }

    // Filters
    setFilters((prevFilters) => ({
      ...prevFilters,
      ...tableFilters,
    }));
  };

  if (coachVisitsError) return <GenericApolloError error={coachVisitsError as ApolloError} />;

  return (
    <Space direction="vertical" size="large" style={{ width: '100%' }}>
      <Typography.Title
        level={2}
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
        }}
      >
        <Trans i18nKey="manage.coachVisits.title" />
        <Space>
          <Button onClick={() => setShowCreateCoachVisitModal(true)}>
            <Trans i18nKey="manage.coachVisits.new" />
          </Button>
        </Space>
      </Typography.Title>
      <Table
        loading={coachVisitsLoading}
        columns={columns}
        dataSource={(coachVisits || []).map((activity) => ({
          ...activity,
          key: activity.id,
        }))}
        onChange={onTableChange}
        pagination={{
          pageSize,
          showSizeChanger: true,
          defaultCurrent: 1,
          current: pagination?.currentPage,
          total: pagination?.totalCount,
        }}
      />
      {showCreateCoachVisitModal && (
        <CreateCoachVisitModal onCancel={() => setShowCreateCoachVisitModal(false)} />
      )}
    </Space>
  );
}

export default CoachVisits;
