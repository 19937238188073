import { message } from 'antd';

export function MessageProvider({ children }: { children: JSX.Element }) {
  const [, messageContextHolder] = message.useMessage();

  return (
    <>
      {messageContextHolder}
      {children}
    </>
  );
}

export default MessageProvider;
